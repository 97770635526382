import React from 'react'
import CustomerComplainListComponent from './CustomerComplainComponents/CustomerComplainListComponent'

const PendingComplainList = () => {
  return (
    <div>
            <CustomerComplainListComponent 
            apiEndpoint={'/ccd/api/pending-customer-complain-list/'}
            pageTitle={'Pending Customer Complain List'}
        />
    </div>
  )
}

export default PendingComplainList



