import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import { useNavigate } from 'react-router-dom';

const AssignedSatatusChangeComponent = ({data,id,fetchDetailsData,statusUpdateURL,redirectURL,title}) => {
    const navigate = useNavigate()
    const [statusForm, setStatusForm] = useState({
        assigned_status: '',
    })

    const statusOption = [
        {value:'Job Assigned',label:'Job Assigned'},
        {value:'Job Started',label:'Job Started'},
        {value:'Job Completed',label:'Job Completed'},
    ]


    useEffect(() => {
        setStatusForm(data)
    }, [data]);


    const handleStatusChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
            setStatusForm(prevState => ({ ...prevState, assigned_status: selectedOption.value }));
            console.log('selectedOption',selectedOption)
        } else {
            setStatusForm(prevState => ({ ...prevState, assigned_status: null }));
        }
        };
    



    const handleStatusChangeSubmit = async (e)=>{
        e.preventDefault()
        try {
            const response = await authAxiosWithBearer.put(`${baseURL}/${statusUpdateURL}/${id}/`,statusForm)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Updated');
                navigate(`/${redirectURL}`)
                fetchDetailsData()
              }
        } catch (error) {
            ShowToast('error', 'Something is wrong');
        }
    }


  return (
    <div>

        <form className='border border-[#E74A3B] shadow-lg rounded-[8px] pb-3' onSubmit={handleStatusChangeSubmit}>
                <FormPageTitle title={title} />
                <div className='flex justify-between md:mx-5'> 
                    <div  className='my-4 w-3/4'>
                        <SelectInputWC
                            name='assigned_status'
                            id='assigned_status'
                            label='Status*'
                            placeholder='select status'
                            className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                            value={statusOption?.find((option)=> option?.value === statusForm?.assigned_status)}
                            onChange={handleStatusChange}
                            options={statusOption}
                            isRequired={true}
                        />
                    </div>
                    
                    <>
                        {data?.assigned_status !== 'Job Completed'? 
                        <div className='my-auto w-1/4 flex justify-end'>
                            <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Change</button>      
                        </div>
                        :
                        <div className='my-auto w-1/4 flex justify-end'>
                            <button type='submit' disabled className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Completed</button>      
                        </div>
                        }
                    </>

                </div>
        </form>

    </div>
  )
}

export default AssignedSatatusChangeComponent