import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../Components/Form/SelectInput'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';

const OnlineQuery = ({
    setTicketFormData,
    ticketFormData,
    handleTicketInputChange,
    setAutoSelectOnlineQuery,
    oqProductBrandOptions,
    selectedDqProductBrand,
    }) => {

    const [customerInterestOptions, setCustomerInterestOptions] = useState([])
    const [selectedCustomerInterest, setSelectedCustomerInterest] = useState(null);
    const [selectedOqProductBrand, setSelectedOqProductBrand] = useState(null);
    const [oqSourceInfoOptions, setOqSourceInfoOptions] = useState([])
    const [selectedOqSourceInfo, setSelectedOqSourceInfo] = useState(null);

    // fetchCustomerInterestData
    useEffect(() => {
        const fetchCustomerInterestData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-customer-interest/`);
            // console.log(response)
            setCustomerInterestOptions( response.data.map((data) => ({
                value: data.id,
                label: data.name,
            })));
    
            } catch (error) {
                console.log(error)
            }
        };
        fetchCustomerInterestData();
    }, []);

    // fetchOqSourceInfoData
    useEffect(() => {
        const fetchOqSourceInfoData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-sourse-info/`);
                setOqSourceInfoOptions( response.data.map((data) => ({
                value: data.id,
                label: data.name,
            })));
            } catch (error) {
                console.log(error)
            }
        };
        fetchOqSourceInfoData();
        }, []);

    const handleCustomerInterestChange = (selectedOption) => {
        const customerID = selectedOption ? selectedOption.value : null;
        const productBrandId = selectedOqProductBrand ? selectedOqProductBrand.value : null;
        const OqSourceInfoId = selectedOqSourceInfo ? selectedOqSourceInfo.value : null;
  
        setAutoSelectOnlineQuery(customerID !== null || productBrandId !== null || OqSourceInfoId !== null);
      
        setSelectedCustomerInterest(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          customer_interest: customerID,
        }));
    };

    const handleOqProductBrandChange = (selectedOption) => {
        const productBrandId = selectedOption ? selectedOption.value : null;
        const customerID = selectedCustomerInterest ? selectedCustomerInterest.value : null;
        const OqSourceInfoId = selectedOqSourceInfo ? selectedOqSourceInfo.value : null;
  
        setAutoSelectOnlineQuery(customerID !== null || productBrandId !== null || OqSourceInfoId !== null);
  
        setSelectedOqProductBrand(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          oq_product_brand: productBrandId,
        }));
      };
      
    const handleOqSourceInfoChange = (selectedOption) => {
    const OqSourceInfoId = selectedOption ? selectedOption.value : null;
    const customerID = selectedCustomerInterest ? selectedCustomerInterest.value : null;
    const productBrandId = selectedDqProductBrand ? selectedDqProductBrand.value : null;

    setAutoSelectOnlineQuery(customerID !== null || productBrandId !== null || OqSourceInfoId !== null);
    
    setSelectedOqSourceInfo(selectedOption);
    setTicketFormData((prevFormData) => ({
        ...prevFormData,
        oq_source_info: OqSourceInfoId,
    }));
    };

  return (
    <div>
        <FormPageTitle title='Online Query'   /> 
        <div className="grid md:grid-cols-2 lg:grid-cols-3">
            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='customer_interest'
                    id='customer_interest'
                    label='Customer Interest'
                    className={`w-[99%]`}
                    value={selectedCustomerInterest}
                    onChange={handleCustomerInterestChange}
                    options={customerInterestOptions}
                    placeholder={`Select customer`}
                    isRequired={false}
                />
            </div>
            <div  className='my-4 md:mx-auto'>
                <SelectInput
                name='oq_product_brand'
                id='oq_product_brand'
                label='Product Brand'
                className={`w-[99%]`}
                value={selectedOqProductBrand}
                onChange={handleOqProductBrandChange}
                options={oqProductBrandOptions}
                isRequired={false}
                />
            </div>
            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='oq_source_info'
                    id='oq_source_info'
                    label='Source Info'
                    className={`w-[99%]`}
                    value={selectedOqSourceInfo}
                    onChange={handleOqSourceInfoChange}
                    options={oqSourceInfoOptions}
                    isRequired={false}
                />
            </div>
        </div> 
    </div>
  )
}

export default OnlineQuery