import React from 'react'
import ATListComponentForCompletedDeliveryResource from './ATForCompletedDeliveryResourceComponents/ATListComponentForCompletedDeliveryResource'

const CompletedATListForCompletedDeliveryResource = () => {
  return (
    <div>
        <ATListComponentForCompletedDeliveryResource 
        title={'Completed Assigned Technician List (CDR)'}
        apiEndPoint={'/in_house/api/completed-delivery-resource/completed-assigned-technician-list/'} 
        detailsURL={'completed-delivery-resource/assignd-technician/details'}
        detailsURLPermission={'8.1.2'}
        />
    </div>
  )
}

export default CompletedATListForCompletedDeliveryResource