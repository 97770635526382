import React from 'react'
import WorkorderFormCom from './WoComponents/WorkorderFormCom'

function AddNewWorkorder() {


  return (
    <>
        <WorkorderFormCom title={'WorkorderAdd'} />
    </>
  )
}

export default AddNewWorkorder