import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import AssignedTechnicianPageTitle from './ATComponent/AssignedTechnicianPageTitle';
import { authAxios, authAxiosWithBearer, authAxiosWithBearerWithContentTypeXwwwformUrlencoded, baseURL } from '../../../baseURL';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TechnicianListForAddPage from './ATComponent/TechnicianListForAddPage';
import './ATComponent/CSS/TechnicianAssignModal.css';
import { ImCancelCircle } from "react-icons/im";
import SelectInput from '../../../Components/Form/SelectInput';
import TextareaInput from '../../../Components/Form/TextareaInput';
import ShowToast from '../../../Components/ShowToast';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import { AiFillDelete } from 'react-icons/ai';
import SalesOrderDetailsComponent from '../../../Components/SalesOrderDetailsComponent';
import InstallationTicketWiseAssignedTechnicianList from './ATComponent/InstallationTicketWiseAssignedTechnicianList';
import ServiceTicketWiseAssignedTechnicianList from './ATComponent/ServiceTicketWiseAssignedTechnicianList';
import VendorListDataForAddPage from './ATComponent/VendorListDataForAddPage';
import { formatDateTime } from '../../../Components/formatDateTime';
import IjcrDetailsComponents from '../TicketDetailsComponents/IjcrDetailsComponents';
import ScbccDetailsComponents from '../TicketDetailsComponents/ScbccDetailsComponents';
import TicketBasicDetailsComponet from '../TicketDetailsComponents/TicketBasicDetailsComponet';
import SalesInvoiceDetailsModalComponent from './ATComponent/SalesInvoiceDetailsModalComponent';
import ATForScbcc from './ATComponent/ATForScbcc';
import ATForIjcr from './ATComponent/ATForIjcr';

function AssignedTechnicianAdd() {
  const { ticket_no } = useParams();
  const jwtToken = localStorage.getItem('token');
  const [ticketData, setTicketData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [technicianId, setTechnicianId] = useState(null);
  const [modalSubmitLoading,setModalSubmitLoading] = useState(false)
  
 

    const PriorityOption = [
        { value: 'High', label: 'High' },
        { value: 'Normal', label: 'Normal' },
        { value: 'Low', label: 'Low' },
    ];


    // fetchTicketData
    const fetchTicketData = async () => {
        try {
        const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-single-ticket/${ticket_no}/`);
        console.log(response.data);
        setTicketData(response.data);
        } catch (error) {
        console.log(error);
        }
    };

    useEffect(() => {
        fetchTicketData();
    }, [jwtToken, ticket_no]);


    const openModal = (id) => {
        setTechnicianId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setTechnicianId(null);
        setIsModalOpen(false);
    };



    const [invoiceDetailsData, setInvoiceDetailsData ]  = useState(null)
    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [selectedInvoiceID, setSelectedInvoiceID] = useState(null);

    // Function to open the modal and set the selected ticket_no
    const openInvoicedModal = (invoiceNo) => {
        setInvoiceModalOpen(true);
        setSelectedInvoiceID(invoiceNo);
    };

    // Function to close the modal
    const closeInvoiceModal = () => {
        setInvoiceModalOpen(false);
        setSelectedInvoiceID(null);
    };



    const [refreshData, setRefreshData] = useState(false);


    const refreshAssignedDataList = () => {
        setRefreshData((prev) => !prev);
    };


    const onUpdateTicketData = async () => {
        await fetchTicketData();
        refreshAssignedDataList();
    };


  return (
    <div>
        <AssignedTechnicianPageTitle title='ASSIGN TECHNICIAN'  />

        <TicketBasicDetailsComponet ticketData={ticketData} />

        <div className="ticket-data-card bg-[#fff] shadow-lg px-3 py-6 my-4">
            <FormPageTitle title='Ticket Categories Information' />
            <Tabs>
                <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 tb-list bg-[#fff]  rounded-[5px]'>
                {ticketData?.hvac && ticketData.hvac.length > 0 && (
                    <Tab className='text-center my-auto py-2 hover:cursor-pointer  hover:bg-[#EB5757] hover:text-[#fff] rounded-[5px] mx-1'>HVAC</Tab>
                )}
                {ticketData?.insatllation_bill && ticketData.insatllation_bill.length > 0 &&(
                    <Tab className='text-center my-auto py-2 hover:cursor-pointer  hover:bg-[#EB5757] hover:text-[#fff] rounded-[5px] mx-1'>Insatllation</Tab>
                )}
                {ticketData?.service_bill && ticketData.service_bill.length > 0 &&(
                    <Tab className='text-center my-auto py-2 hover:cursor-pointer  hover:bg-[#EB5757] hover:text-[#fff] rounded-[5px] mx-1'>Service</Tab>
                )}

                </TabList>


                {ticketData?.insatllation_bill && ticketData.insatllation_bill.length > 0 &&(
                <TabPanel className='text-[15px]'>
                
                <IjcrDetailsComponents ijcrTicketData={ticketData?.insatllation_bill[0]} openInvoicedModal={openInvoicedModal} />

                <InstallationTicketWiseAssignedTechnicianList 
                    ticket_id={ticket_no}
                    refreshData={refreshData}
                    onUpdateTicketData={onUpdateTicketData}
                  />
                
                <TechnicianListForAddPage 
                    onClick={(technicianId) => {openModal(technicianId);}} 
                    insatllaTionticketData={ticketData}
                    refreshData={refreshData}
                    onUpdateTicketData={onUpdateTicketData}
                />


                <VendorListDataForAddPage 
                    onClick={(technicianId) => {openModal(technicianId);}} 
                    insatllaTionticketData={ticketData}
                    refreshData={refreshData}
                    onUpdateTicketData={onUpdateTicketData}
                />
                
                <ATForIjcr 
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    PriorityOption={PriorityOption}
                    setModalSubmitLoading={setModalSubmitLoading}
                    modalSubmitLoading={modalSubmitLoading}
                    fetchTicketData={fetchTicketData}
                    refreshAssignedDataList={refreshAssignedDataList}
                    technicianId={technicianId}
                    ticketData={ticketData}
                />

                </TabPanel>
                )}


                {ticketData?.service_bill && ticketData.service_bill.length > 0 &&(
                <TabPanel className='text-[15px]'>

                <ScbccDetailsComponents 
                    scbccTicketData={ticketData?.service_bill[0]} 
                    openInvoicedModal={openInvoicedModal}
                />

                <ServiceTicketWiseAssignedTechnicianList
                    ticket_id={ticket_no}
                    refreshData={refreshData}
                    onUpdateTicketData={onUpdateTicketData}
                />

                <TechnicianListForAddPage 
                    onClick={(technicianId) => {openModal(technicianId);}}
                    serviceTicketData={ticketData} 
                    refreshData={refreshData}
                    onUpdateTicketData={onUpdateTicketData}
                />

                <VendorListDataForAddPage 
                    onClick={(technicianId) => {openModal(technicianId);}}
                    serviceTicketData={ticketData} 
                    refreshData={refreshData}
                    onUpdateTicketData={onUpdateTicketData}
                />
                
                <ATForScbcc 
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    PriorityOption={PriorityOption}
                    setModalSubmitLoading={setModalSubmitLoading}
                    modalSubmitLoading={modalSubmitLoading}
                    fetchTicketData={fetchTicketData}
                    refreshAssignedDataList={refreshAssignedDataList}
                    technicianId={technicianId}
                    ticketData={ticketData}
                    setInvoiceDetailsData={setInvoiceDetailsData}
                />
                
                </TabPanel>
                )}
                
            </Tabs>
        </div>


        <SalesInvoiceDetailsModalComponent 
            invoiceDetailsData={invoiceDetailsData}
            setInvoiceDetailsData={setInvoiceDetailsData}
            isInvoiceModalOpen={isInvoiceModalOpen}
            selectedInvoiceID={selectedInvoiceID}
            closeInvoiceModal={closeInvoiceModal}

        />
    </div>
  ) 
}

export default AssignedTechnicianAdd