import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import ShowToast from '../../../../Components/ShowToast';

function ItemFormHandleComponent() {
  const initialFormData = {
    item_name: '',
    item_sales_price:'',
    item_sales_price_for_ho:'',
    specification: '',
    brand: '',
    model: '',
    origin: '',
    size: '',
    parts_no: '',
    status: true,
    item_code: '',
    category: null,
    sub_category: null,
    uom: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const jwtToken = localStorage.getItem('token')
  const [categoriesOption, setCategoriesOption] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [subCategoriesOption, setSubCategoriesOption] = useState(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [uomOption, setUomOption]=useState(null);
  const [selectedUOM, setSelectedUOM]= useState(null);


  // fetchCategoriesData
  useEffect(()=>{
    const fetchCategoriesData = async () =>{
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-categories-list/`)
        setCategoriesOption(response?.data?.results?.map((data)=>({
          value: data.id,
          label:data.name
        })))
      } catch (error) {
        console.log(error)
      }
    };fetchCategoriesData()
  },[])

  // fetchSubCategoriesData and fetchINVUoMData
  useEffect(()=>{
    const fetchSubCategoriesData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-sub-categories-list/`);
        setSubCategoriesOption(response?.data?.results?.map((data) => ({
          value: data.id,
          label: data.name,
          category: data.category,
        })));
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubCategoriesData();

    const fetchINVUoMData = async () =>{
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-uom-list/`)
        setUomOption(response?.data?.results?.map((data)=>({
          value : data.id,
          label : data.name
        })))
      } catch (error) {
        console.log(error)
      }
    };fetchINVUoMData();
  },[])



  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    let inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;
  
    // Conditionally limit the number of digits (integer + decimal parts)
    if (['item_sales_price','item_sales_price_for_ho'].includes(name)) {
      const parts = value.split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1] || '';
  
      // If the total number of digits exceeds 20, show an error message
      if (integerPart.length > 18) {
        ShowToast('error', `Ensure that there are no more than 18 digits`);
        return;
      }
  
      // If decimal part exists, limit it to 2 decimal places
      if (parts.length === 2) {
        inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
  
        // If more than 6 digits after the decimal point, show a separate error message
        if (decimalPart.length > 2) {
          ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
        }
      }
    }
  
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };
  


  const handleCategoriesChange = (selectedOption) =>{
    const cate_id = selectedOption ? selectedOption.value : null;
    setSelectedCategory(selectedOption);
    setFormData((prevFormData)=>({
      ...prevFormData,
      category: cate_id,
    }));
    setSelectedSubCategory(null)
  };


  const handleSubCategoryChange = (selectedOption) =>{
    const sub_cate_id = selectedOption ? selectedOption.value : null;
    setSelectedSubCategory(selectedOption);
    setFormData((prevFormData)=>({
      ...prevFormData,
      sub_category : sub_cate_id
    }))
  }

  const handUOMChange =(selectedOption) =>{
    const uom_id = selectedOption ? selectedOption.value : null;
    setSelectedUOM(selectedOption);
    setFormData((prevFormData)=>({
      ...prevFormData,
      uom : uom_id
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const response = authAxiosWithBearer.post(`${baseURL}/inventory/api/item-master/`,formData)
      if(response){
        ShowToast('success' , 'Successfully add')
        setFormData(initialFormData);
        setSelectedCategory(null)
        setSelectedSubCategory(null)
        setSelectedUOM(null)
      }
    } catch (error) {
      console.log(error)
    }
  };

  return {
    jwtToken,
    formData,
    setFormData,
    categoriesOption,
    setCategoriesOption,
    selectedCategory,
    setSelectedCategory,
    subCategoriesOption,
    setSubCategoriesOption,
    selectedSubCategory,
    setSelectedSubCategory,
    uomOption,
    setUomOption,
    selectedUOM,
    setSelectedUOM,
    handleInputChange,
    handleCategoriesChange,
    handleSubCategoryChange,
    handUOMChange,
    handleSubmit,
  }
}

export default ItemFormHandleComponent