import React from 'react'

const SRItemComponent = ({itemDatas,title}) => {
  return (
    <div className='overflow-x-auto overflow-y-auto p-2'>
        <div className='py-1 text-[20px] font-semibold text-[#000000]'>{title}</div>
        <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> UOM  </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Issue Status </th>

                </tr>

            </thead>
            <tbody className='text-center'>

                {itemDatas?.map((item, index) => (
                    <tr >
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{++index}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                            name : {item?.item_name} <br />
                            size : {item?.item_size} <br />
                            specification :{item?.item_specification} <br />
                            brand : {item?.item_brand}
                        </td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.uom_text}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.quantity}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                            {item.item_issue_status === 'Issued' ?
                                <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div>
                                : item.item_issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div>
                                    : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>

        </table>
    </div>
  )
}

export default SRItemComponent