import React, { useEffect, useState } from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import InputFiled from '../../../../Components/Form/InputFiled'
import DateInput from '../../../../Components/Form/DateInput'
import Checkbox from '../../../../Components/Form/Checkbox'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL'
import SelectInput from '../../../../Components/Form/SelectInput'

const TelesalesCRm = ({
    setTicketFormData,
    ticketFormData,
    handleTicketInputChange,
    tsProductBrandOptions,
    tsProductCategoryOption,
    }) => {

    const [selectedTsProductBrand, setSelectedTsProductBrand] = useState(null);
    const [selectedTsProductCategory, setSelectedTsProductCategory] = useState(null)
    const [tsCustomerComplainOption, setTsCustomerComplainOption] =useState([]);
    const [selectedTsCustomerComplain, setSelectedTsCustomerComplain] = useState(null)

    const prospectsOptions = [
        { value: '1', label: 'Very-High' },
        { value: '2', label: 'High' },
        { value: '3', label: 'Average' },
        { value: '4', label: 'Maybe' },
      ];
  

    // fetchCustomerComplainData
    useEffect(() => {
        const fetchCustomerComplainData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-customer-complain/`);
            // console.log(response)
            setTsCustomerComplainOption( response.data.map((data) => ({
                value: data.id,
                label: data.name,
            })));
    
            } catch (error) {
                console.log(error)
            }
        };
        fetchCustomerComplainData();
    }, []);

    const handleTsAppointmentFromDateChange = (date) => {
        setTicketFormData({
          ...ticketFormData,
          appointment_date: date,
        });
      };

      const handleTsFollowUpFromDateChange = (date) => {
        setTicketFormData({
          ...ticketFormData,
          follow_up_date: date,
        });
      };

      const handleTsProductBrandChange = (selectedOption) => {
        const tsProductBrandId = selectedOption ? selectedOption.value : null;
        setSelectedTsProductBrand(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          t_product_brand : tsProductBrandId,
        }));
      };

      const handleTsProductCategoryChange = (selectedOption) => {
        const tsProductCateID = selectedOption ? selectedOption.value : null;
  
        setSelectedTsProductCategory(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          sale_new_product  : tsProductCateID,
        }));
      };

      const handleTsCustomerComplainChange = (selectedOption) => {
        const tsCustomerComplainID = selectedOption ? selectedOption.value : null;
        setSelectedTsCustomerComplain(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          customer_complain : tsCustomerComplainID,
        }));
      };

      const handleProspectsChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, sales_prospect: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, sales_prospect: null }));
        }
      };
      

  return (
    <div className='bg-[#fff] shadow-xl p-2'>
        <FormPageTitle title='Telesales CRM'   />
        <div className="grid grid-cols-2 lg:grid-cols-3">
            <div  className='my-4 md:mx-auto'>
                <InputFiled type='text'  
                    className={`w-[99%]`}
                    name='item_in_household' id='item_in_household'
                    value={ticketFormData.item_in_household}
                    onChange={handleTicketInputChange}
                    label='Household' isRequired={false} 
                    placeholder='Enter item in household'
                />
            </div>

            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='sales_prospect'
                    className={`w-[99%]`}
                    id='sales_prospect'
                    label='Sales Prospect'
                    value={prospectsOptions.find((option) => option?.value === ticketFormData.sales_prospect)}
                    onChange={handleProspectsChange}
                    options={prospectsOptions}
                    isRequired={false}
                    />
            </div>


            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='customer_complain'
                    id='customer_complain'
                    className={`w-[99%]`}
                    label='Customer Complain'
                    value={selectedTsCustomerComplain}
                    onChange={handleTsCustomerComplainChange}
                    options={tsCustomerComplainOption}
                    isRequired={false}
                />
            </div>

            <div  className='my-4 md:mx-auto'>
                <SelectInput
                name='sale_new_product'
                className={`w-[99%]`}
                id='sale_new_product'
                label='Product Category'
                value={selectedTsProductCategory}
                onChange={handleTsProductCategoryChange}
                options={tsProductCategoryOption}
                isRequired={false}
                />
            </div>

            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='t_product_brand'
                    id='t_product_brand'
                    className={`w-[99%]`}
                    label='Product Brand'
                    value={selectedTsProductBrand}
                    onChange={handleTsProductBrandChange}
                    options={tsProductBrandOptions}
                    isRequired={false}
                />
            </div>

            <div  className='my-4 md:mx-auto'>
                <InputFiled type='text'  
                    className={`w-[99%]`}
                    name='color' id='color'
                    value={ticketFormData.color}
                    onChange={handleTicketInputChange}
                    label='Color' isRequired={true} 
                    placeholder='Enter item in color'
                />
            </div>

            <div className='my-4 md:mx-auto'>
                <DateInput value={ticketFormData.follow_up_date} 
                    onChange={handleTsFollowUpFromDateChange} 
                    dateFormat='yyyy-MM-dd' id='follow_up_date'
                    label='Follow Up Date' isRequired={false} 
                    placeholder='Select Follow Up Date'  
                    className={`w-[99%]`}
                />
            </div>

            <div className='my-4 md:mx-auto'>
                <DateInput value={ticketFormData.appointment_date} 
                    onChange={handleTsAppointmentFromDateChange} 
                    dateFormat='yyyy-MM-dd' id='appointment_date'
                    label='Appointment Date' isRequired={false} 
                    placeholder='Select appointment date'  
                    className={`w-[99%]`}
                />
            </div>

            <div  className='my-4 border border-[#9d9c9c] md:ml-3 px-2 w-[93%]'>
                <Checkbox type='checkbox'  
                    checked={ticketFormData.is_discount} 
                    onChange={handleTicketInputChange} 
                    name='is_discount' id='is_discount' label='Is discount' 
                    isRequired={false} placeholder='is_discount'
                    className={`w-[99%]`}
                />
            </div>

            <div  className='my-4 border border-[#9d9c9c] md:ml-3 px-2 w-[93%]'>
                <Checkbox type='checkbox'  
                    checked={ticketFormData.new_purchase_interest} 
                    onChange={handleTicketInputChange} 
                    name='new_purchase_interest' id='new_purchase_interest' label='New purchase interest' 
                    isRequired={false} placeholder='new purchase interest'
                    className={`w-[99%]`}
                />
            </div>
        </div>
    </div>
  )
}

export default TelesalesCRm