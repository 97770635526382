import React, { useEffect, useState } from 'react'
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { useNavigate } from 'react-router-dom';

const HOInvoiceCreateHandleComponent = ({
  scbccWpTicketNo,
  installationTicketNo,
  detailsData,
  srIssuedData 
}) => {
    const [formData,setFormData] = useState({
        ticket : null,
        installation : null,
        service : null,
        basic_charge : '',
        basic_charge_quantity:'',
        additional_charge : '',
        total_payable_amount : '',
    })
    const navigate = useNavigate();


  const handleInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      let inputValue;

      if (type === 'checkbox') {
          inputValue = checked;
      } else if (type === 'file') {
          inputValue = files[0];
      } else {
          inputValue = value;

          if (parseFloat(value) < 0) {
            ShowToast('error', `Negative values are not allowed for ${name}.`);
            return;
          }

          if (['basic_charge','additional_charge','total_payable_amount'].includes(name)) {
              const parts = value.split('.');
              const integerPart = parts[0];
              const decimalPart = parts[1] || '';

              // If the total number of digits exceeds 20, show an error message
              if (integerPart.length > 14) {
                  ShowToast( 'error', `Ensure that there are no more than 14 digits`);
                  return;
              }

              // If decimal part exists, limit it to 6 decimal places
              if (parts.length === 2) {
                  inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;

                  // If more than 6 digits after the decimal point, show a separate error message
                  if (decimalPart.length > 2) {
                      ShowToast('error',`More than 2 digits after the decimal point for ${name}.`);
                  }
              }
          }
      }

      setFormData({
          ...formData,
          [name]: inputValue
      });
  };


  useEffect(() => {
    const basicCharge = parseFloat(formData.basic_charge || 0);
    const basicChargeQuantity = parseFloat(formData.basic_charge_quantity || 0);
    const additionalCharge = parseFloat(formData.additional_charge || 0);
    const totalAmount = (basicCharge * basicChargeQuantity) + additionalCharge;
    setFormData(prevState => ({ ...prevState, total_payable_amount: totalAmount }));
  }, [formData.basic_charge, formData.basic_charge_quantity, formData.additional_charge]);




  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('detailsData.ticket',detailsData.ticket)
    try {
      const UpdateFormData = {
        ...formData,
        ticket  : detailsData.ticket !== null? detailsData.ticket : null,
        basic_charge_quantity:formData.basic_charge_quantity === ''? 0 : formData.basic_charge_quantity,
        installation : installationTicketNo? installationTicketNo : null,
        service : scbccWpTicketNo? scbccWpTicketNo : null,
      }
      const response = await authAxiosWithBearer.post(`${baseURL}/billing/api/ho-invoice-create/`, UpdateFormData);

      if (response) {
        console.log(response);
        ShowToast('success', 'Successfully Submit');
        if(scbccWpTicketNo){
          navigate('/scbcc-wp-ticket-list-for-billing');
        }
        if(installationTicketNo){
          navigate('/installations-ticket-list-for-billing');
        }
      }
    } catch (error) {
      console.log(error);
      if(error.response.data.error){
        ShowToast('error', `${error.response.data.error}`);
      }else{
        ShowToast('error', 'Something is wrong');
      }
    }
  };
    

  return {
    formData,
    setFormData,
    handleInputChange,
    handleSubmit,
  }
}

export default HOInvoiceCreateHandleComponent