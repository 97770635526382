
import axios from "axios";
import { useEffect } from "react";


export const baseURL = 'https://api.crm.e-bit.biz'
// export const baseURL = 'http://10.20.0.133:8000'
// export const baseURL = 'http://127.0.0.1:8000'

// export const baseURL = 'http://202.191.126.106:8080'


export const authAxios = axios.create({
  baseURL: baseURL,
  headers: {
    Accept:'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

authAxios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



export const authAxiosWithBearer = axios.create({
  baseURL: baseURL,
});
authAxiosWithBearer.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export const authAxiosWithBearerWithContentTypeXwwwformUrlencoded = axios.create({
  baseURL: baseURL,
  headers: {
    Accept:'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});
authAxiosWithBearerWithContentTypeXwwwformUrlencoded.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);