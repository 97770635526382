import React, { useEffect, useState } from 'react'

const GoodsCheckTotalParstBill = ({feedbackData}) => {
    const [totalPrice, setTotalPrice] = useState(null);
    
    useEffect(() => {
      // Calculate total price when detailsData changes
      const calculateTotalPrice = () => {
        if (feedbackData && feedbackData.gctfeedbackpartsdetails_set && feedbackData.gctfeedbackpartsdetails_set.length > 0) {
          const total = feedbackData.gctfeedbackpartsdetails_set.reduce((acc, item) => {
            // Assuming item has properties wo_item_qty and price
            const itemTotal = item?.quantity && item?.item_sales_price
            ? (parseFloat(item.quantity) * parseFloat(item.item_sales_price)): 0; 
            return acc + itemTotal;
          }, 0);
  
          const finalTotal = total;
  
          // Update the state with the calculated total price
          setTotalPrice(finalTotal.toFixed(2));
        } else {
          // If detailsData or billing_invoice is empty or null, set total price to null
          setTotalPrice(null);
        }
      };
  
      calculateTotalPrice();
    }, [feedbackData]);

  return (
    <div>
        {totalPrice && totalPrice}
    </div>
  )
}

export default GoodsCheckTotalParstBill