import React from 'react'
import '../assest/CSS/AddFormPage.css'

function PageTitleComponent({title}) {
  return (
    <div>
    <div className="add-form-page-header mb-5 shadow-lg ">
        <div className="add-form-page-title my-auto py-2">
          <h1 className='uppercase'>{title}</h1>
        </div>
      </div>
    </div>
  )
}

export default PageTitleComponent