import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { formatDateTime } from '../../../../Components/formatDateTime';
import SRIssuedItemComponent from '../../../Inventory/InventoryComponent/SRIssuedItemComponent';

const AssignedWiseSrIssuedList = ({ data }) => {

  const dataSource = data?.map((data,index) => ({
    key: index,
    id:data?.id,
    issue_no: data?.issue_no,
    issued: data?.issued === true ? <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div> : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>,
    created_at: data?.created_at,
    created_by_text: data?.created_by_employee_id,
    created_by_phone:data?.created_by_phone,
    srissueditem_set: data?.srissueditem_set
  }));


  const columns = [
    {
      title: 'Issue No',
      dataIndex: 'issue_no',
      key: 'issue_no',
    },
    {
      title: 'Issued Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        const formattedDateTime = formatDateTime(created_at);
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },
    {
      title: 'Issued By',
      dataIndex: 'created_by_text',
      key: 'created_by_text',
    },
    {
      title: 'Issued By(Phone)',
      dataIndex: 'created_by_phone',
      key: 'created_by_phone',
    },
  ];


  return (
    <>
    {data?.length > 0 && (
      <div className="border border-[#E74A3B] shadow-lg rounded-[8px]">
        <div className='information-head mb-1 pl-5 py-1'>
              <div>SR Issued List</div>
        </div>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <SRIssuedItemComponent itemDatas={record?.srissueditem_set} title={'Issued Item Information'} />
              </div>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={dataSource}
        />
      </div>
    )}
    </>
      
  );
};

export default AssignedWiseSrIssuedList;
