import React, { useEffect, useState } from 'react'
import InputFiled from '../../../../Components/Form/InputFiled'
import ShowToast from '../../../../Components/ShowToast'
import {authAxiosWithBearer, baseURL } from '../../../../baseURL'
import SelectInput from '../../../../Components/Form/SelectInput'
import DateInput from '../../../../Components/Form/DateInput'
import Checkbox from '../../../../Components/Form/Checkbox'
import { format, parseISO } from "date-fns";
import SectionTitle from './SectionTitle'
import './CSS/ticketPage.css'
import { AiOutlineSearch } from 'react-icons/ai'
import CustomerAddComponent from '../../Customer/CustomerComponents/CustomerAddComponent'
import CustomerPrevHistory from './CustomerPrevHistory'



function CustomerInformationIDailer({ onPhoneSearchResult,phone_no,invCustomerPhoneNo,setInvCustomerPhoneNo }) {
    const [phoneInput, setPhoneInput] = useState('');
    const [phoneSearchResult, setPhoneSearchResult] = useState('');
    const [phoneSearchResultCustomerId, setPhoneSearchResultCustomerId] = useState('');
    const [currentPhoto,setCurrentPhoto] = useState([])

    useEffect(()=>{
      setPhoneInput(invCustomerPhoneNo)
      // if(invCustomerPhoneNo){
      //   setPhoneSearchResult(invCustomerPhoneNo)
      // }
    },[invCustomerPhoneNo])


    const handleCustomerDataReset = () => {
      setPhoneInput('')
      setPhoneSearchResult('')
      setInvCustomerPhoneNo(null)
      onPhoneSearchResult(null)
    };
    
    const handlePhoneInputChange = (event) => {
      setPhoneInput(event.target.value);
    };
    
    useEffect(() => {
      setPhoneInput(phone_no); 
    }, [phone_no]);
    
    const handlePhoneSearchClick = () => {
      console.log('phoneInput value:', phoneInput);
      setPhoneSearchResult(phoneInput);
    };

    
      const {
          customerFormData,
          setCustomerFormData,
          setSelectedDistrict,
          setSelectedUpazila,
          setSelectedCustomer,
          setCustomerErrorMessage,
          setCustomerAddLoding,
          customerAddLoding,
          customerErrorMessage,
          handleCustomerInputChange,
          handleCustomerSearchInputChange,
          handleGenderChange,
          handleDistrictChange,
          handleUpazilaChange,
          handleCustomerChange,
          handleFromDateChange,
          genderOptions,
          selectedCustomer,
          customerOptions,
          selectedDistrict,
          districtOptions,
          selectedUpazila,
          upazilaOptions,
      } = CustomerAddComponent();

    
      useEffect(() => {
        const fetchSingleCustomerData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/customer-with-no/${phoneSearchResult}/`);
            console.log(response.data)
            setPhoneSearchResultCustomerId(response.data.id)
            onPhoneSearchResult(response.data.id)
            setSelectedDistrict(response.data.district_id?{ 
              value: response.data.district_id,
              label: response.data.district_name,
              }:null);

            setSelectedUpazila(response.data.upazila?{ 
              value: response.data.upazila,
              label: response.data.upazila_name,
              }:null);

            setSelectedCustomer(response.data.ref_customer?{ 
              value: response.data.ref_customer,
              label: response.data.ref_customer_name,
              }:null);

              const {
                name,
                age,
                profession,
                phone_no,
                email,
                address ,
                post_code,
                status,
              } = response.data;

            setCurrentPhoto(response.data.photo)

            setCustomerFormData({
              name,
              age,
              profession,
              phone_no,
              email,
              address ,
              post_code,
              status,
              date_of_birth: response.data.date_of_birth ? parseISO(response.data.date_of_birth) : null,
              gender: response?.data?.gender? parseInt(response.data.gender): null
            });

            } catch (error) {   
              const resetForm = () => {
                setPhoneSearchResult('');
                setPhoneSearchResultCustomerId('');
                setCurrentPhoto([]);

                setSelectedDistrict(null);
                setSelectedUpazila(null);
                setSelectedCustomer(null);

                setCustomerFormData({
                    name: '',
                    age: '',
                    profession: '',
                    phone_no: '',
                    email: '',
                    address: '',
                    post_code: '',
                    status: '',
                    date_of_birth: null,
                    gender: 0,
                });
              };
              resetForm()
              if(phoneSearchResult !== '' && error.message === "Request failed with status code 404"){
                ShowToast('info', 'This phone number does not match any user');
              }
              console.log(error)
            }
        };
        fetchSingleCustomerData();
      }, [phoneSearchResult]);


      
      const handleCustomerSubmit = async (e) => {
        e.preventDefault();
        try {
          setCustomerAddLoding(true);
  
          const formattedDOB =
          customerFormData.date_of_birth ? format(customerFormData.date_of_birth, 'yyyy-MM-dd') : null;
    
  
          const formDataWithFormattedDates = {
            ...customerFormData,
            date_of_birth: formattedDOB,
            gender:customerFormData.gender || '',
            phone_no:phoneInput
          };
          const response = await authAxiosWithBearer.post(`${baseURL}/ccd/api/customer/`, formDataWithFormattedDates);
          
          if (response) {
            console.log(response);
            // navigate('/customer-list');
            setPhoneSearchResultCustomerId(response.data.custom_user_data.id)
            onPhoneSearchResult(response.data.custom_user_data.id);
            ShowToast('success', 'Successfully Add');
          }
        } catch (error) {
            if(error.response.data){
              ShowToast('error', `${error.response.data.phone_no}`);
              setCustomerErrorMessage(`${error.response.data.phone_no}`)
            }
            console.log(error);
        } finally {
          setCustomerAddLoding(false);
        }
      };
     
      const handleCustomerEditSubmit = async (e) => {
        e.preventDefault();
        try {
          setCustomerAddLoding(true);

          const formattedDOB =
          customerFormData.date_of_birth ? format(customerFormData.date_of_birth, 'yyyy-MM-dd') : null;

          const formDataWithFormattedDates = {
            ...customerFormData,
            date_of_birth: formattedDOB,
            gender:customerFormData.gender || '',
            phone_no:phoneInput
          };
          const response = await authAxiosWithBearer.put(`${baseURL}/ccd/api/customer/${phoneSearchResultCustomerId}/`, formDataWithFormattedDates);
          
          if (response) {
            // console.log(response.data.id);
            // navigate('/customer-list');
            onPhoneSearchResult(response.data.id);
            ShowToast('success', 'Successfully Updated');
            
          }
        } catch (error) {
            if(error.response.data){
              ShowToast('error', `${error.response.data.phone_no}`);
              setCustomerErrorMessage(`${error.response.data.phone_no}`)
            }
            console.log(error);
        } finally {
          setCustomerAddLoding(false);
        }
      };

      const handleCustomerSubmitForm = phoneSearchResultCustomerId? handleCustomerEditSubmit : handleCustomerSubmit;


  return (
    <div>

      <div>
        <div className="flex justify-between ticket-page-header mb-5">
          <div className="page-title my-auto ml-5">
            <h1 className="uppercase my-1">Ticket Create</h1>
          </div>
          <div className="page-search-and-add-new-button md:flex my-auto mr-5">
            <div className="page-search mr-2">
              <div className="relative">
                <input
                  className="page-search-input w-[150px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                  type="text"
                  name="search"
                  required
                  value={phoneInput}
                  onChange={handlePhoneInputChange}
                  placeholder="Phone Number"
                />
                <div className="text-xl absolute top-[5px] right-3">
                  <button className="page-search-button" type="button" onClick={handlePhoneSearchClick}>
                    <AiOutlineSearch className="text-[#fff] mx-auto" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
        <SectionTitle title='Customer Information' />
          <form onSubmit={handleCustomerSubmitForm} className='mt-5'>
            <div className="grid grid-cols-2 lg:grid-cols-3">
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.name} onChange={handleCustomerInputChange} name='name' id='name'  label='Name *' isRequired={true} placeholder='Enter customer name'  />
              </div>

              <div className='my-4'>
                  <InputFiled type='text' 
                    // value={phoneInput}
                    // onChange={handlePhoneInputChange}
                    value={customerFormData.phone_no} 
                    onChange={handleCustomerInputChange}
                    name='phone_no' id='phone_no' label='Phone Number *' 
                    isRequired={true} placeholder='Enter phone number'
                  />

                  {customerErrorMessage && (
                        <p className='text-red-500'>{customerErrorMessage}</p>
                      )}
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.age} onChange={handleCustomerInputChange} name='age' id='age' label='Age' isRequired={false} placeholder='Enter customer age'  />
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.profession} onChange={handleCustomerInputChange} name='profession' id='profession' label='Profession' isRequired={false} placeholder='Enter customer profession'  />
              </div>
              <div className='my-4'>
                  <DateInput value={customerFormData.date_of_birth} onChange={handleFromDateChange} dateFormat='yyyy-MM-dd' id='date_of_birth' label='Date Of Birth' isRequired={false} placeholder='Enter customer DOB'  />
              </div>

                {/* <div className='my-4'> 
                    <InputFile type='file' className='py-1' onChange={handleCustomerInputChange} name='photo' id='photo' label='Photo' isRequired={false} placeholder='Select photo'/>
                    {currentPhoto && (<img src={`${baseURL}/static${currentPhoto}`} className='w-[30px]' />)}
                </div> */}

              <div className="my-4">
                  <SelectInput
                      id="gender"
                      name="gender"
                      label='Gender'
                      value={genderOptions.find((option) => option?.value === customerFormData.gender)}
                      onChange={handleGenderChange}
                      options={genderOptions}
                  />
              </div>
              <div className='my-4'>  
              <SelectInput
                      name='ref_customer'  
                      id='ref_customer'  
                      label='Ref customer'
                      value={selectedCustomer}
                      onChange={handleCustomerChange}
                      onInputChange = {handleCustomerSearchInputChange}
                      options={customerOptions}
                      isRequired={false} 
                  />
              </div>
              
              <div className='my-4'>
                  <InputFiled type='email' value={customerFormData.email} onChange={handleCustomerInputChange} name='email' id='email' label='Email' isRequired={false} placeholder='Enter email'  />
              </div>

              <div className='my-4'>  
              <SelectInput
                      name='district'  
                      id='district'  
                      label='District'
                      value={selectedDistrict}
                      onChange={handleDistrictChange}
                      options={districtOptions}
                      isRequired={false} 
                  />
              </div>
              <div className='my-4'>  
              <SelectInput
                      name='upazila'  
                      id='upazila'  
                      label='Upazila'
                      value={selectedUpazila}
                      onChange={handleUpazilaChange}
                      options={upazilaOptions}
                      isRequired={false} 
                  />
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.post_code} onChange={handleCustomerInputChange} name='post_code' id='post_code' label='Zip/Post Code' isRequired={false} placeholder='Enter zip/post code'/>
              </div>

              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.address} onChange={handleCustomerInputChange} name='address' id='address' label='Address' isRequired={false} placeholder='Enter full address'/>
              </div>

              <div className=" my-auto ml-10">
                    <Checkbox type='checkbox'  
                    checked={customerFormData.status} 
                    onChange={handleCustomerInputChange} 
                    name='status' id='status' label='Status' 
                    isRequired={false} placeholder='status'
                    />
              </div>

              <div className="my-auto flex gap-x-2">
                  <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>{customerAddLoding ? 'Submitting...' : 'Submit'}</button>
                  <p onClick={handleCustomerDataReset} className='bg-[#d8be2a] text-[#000] rounded-[5px] px-4 py-1'>Reset</p>
              </div>

            </div>
          </form>
      </div>

      {phoneSearchResultCustomerId && ( 
        <CustomerPrevHistory 
          phoneSearchResultCustomerId={phoneSearchResultCustomerId}
          phoneSearchResult={phoneSearchResult}
        />
      )}
    </div>
  )
}

export default CustomerInformationIDailer