import React from 'react'
import { formatDateTime } from '../../../Components/formatDateTime'

const IjcrTicketBasicInformationForDetailsComponent = ({detailsData}) => {
  return (
    <div>
            {/* 1st content */}
            <div className='bg-[#FDECEC] my-1'>
                <div className='py-2 text-[20px] font-semibold text-[#E74A3B] pl-10'>TICKET DETAILS</div>
            </div>

            {/* 2nd */}

            <div className='border border-[#EB5757] mx-1 my-2'>

                <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                    <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                        <div className='bg-[#EB5757] '>

                            <div className='flex justify-center items-center 8 sm:py-14  md:py-14 lg:py-10'>
                                <div className='text-[#FFFFFF] text-[16px] font-semibold '>
                                    <div className='text-center'>TICKET NO</div>
                                    <div className='text-center'> = {detailsData?.ticket_no}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                            <div className='mx-1 sm:mx-0'>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Customer</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.customer_name}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Customer Phone</div>
                                        <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{detailsData?.customer_phone}</div>
                                    </div>
                                </div>

                                <div>
                                    <div className='flex '>
                                        <div className='w-[35%] sm:w-[30%] md:w-[50%] lg:w-[35%]'>Invoice No</div>
                                        <div className='w-[8%] sm:w-[5%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[65%] md:w-[42%] lg:w-[49%]'>{detailsData?.ib_invoice_no}</div>

                                    </div>
                                </div>

                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Product Model</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{detailsData?.model_no}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Product Quantity</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{detailsData?.quantity}</div>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd */}

                            <div className='mx-1 sm:mx-0'>
                                <div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Date</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Basic Charge Title</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.ib_basic_charge_title}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Basic Charge Amount</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.ib_basic_charge_amount} TK</div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
    </div>
  )
}

export default IjcrTicketBasicInformationForDetailsComponent