import React from 'react'
import SectionTitle from './TicketComponents/SectionTitle'
import './TicketComponents/CSS/ticketPage.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormPageTitle from '../../../Components/Form/FormPageTitle'
import CustomerInformation from './TicketComponents/CustomerInformation'
import CallDisposition from './TicketCategoryComponent/CallDisposition'
import TelesalesCRm from './TicketCategoryComponent/TelesalesCRm'
import NonPersonalCRM from './TicketCategoryComponent/NonPersonalCRM'
import OnlineSpecific from './TicketCategoryComponent/OnlineSpecific'
import OnlineQuery from './TicketCategoryComponent/OnlineQuery'
import DealershipQuery from './TicketCategoryComponent/DealershipQuery'
import HirePurchase from './TicketCategoryComponent/HirePurchase'
import CorporatePurchase from './TicketCategoryComponent/CorporatePurchase'
import HVAC from './TicketCategoryComponent/HVAC'
import IJCR from './TicketCategoryComponent/IJCR'
import SCBCC from './TicketCategoryComponent/SCBCC'
import TicketCreateComponent from './TicketComponents/TicketCreateComponent'
import OutBoundCRM from './TicketCategoryComponent/OutBoundCRM'



function TicketCreate() {

const     {
  autoSelectOnlineQuery,
  setAutoSelectOnlineQuery,
  autoSelectDealershipQuery,
  setAutoSelectDealershipQuery,
  autoSelectHirePurchase,
  setAutoSelectHirePurchase,
  autoCorporatePurchase,
  setAutoSelectCorporatePurchase,
  autoHVAC,
  setAutoSelectHVAC,
  autoIJCR,
  setAutoSelectIJCR,
  autoSCBCC,
  setAutoSCBCC,
  autoTelesales,
  setAutoTelesales,
  autoHappyCall,
  setAutoHappyCall,
  autoNonPersonalCRM,
  setAutoNonPersonalCRM,
  autoOnlineSpecific,
  setAutoOnlineSpecific,
  autInboundCRM,
  setAutInboundCRM,
  setAutoSelectOutboundCRM,
  autoSelectOutboundCRM,
  dqProductBrandOptions,
  setdqProductBrandOptions,
  oqProductBrandOptions,
  setOqProductBrandOptions,
  hpProductCategoryOption,
  setHpProductCategoryOption,
  cpProductCategoryOption,
  setCpProductCategoryOption,
  ibProductCategoryOption,
  setIbProductCategoryOption,
  ibShowroomOption,
  setIbShowroomOption,
  sbProductBrandOptions,
  setSbProductBrandOptions,
  ibProductBrandOptions,
  setIbProductBrandOptions,
  obProductBrandOptions,
  sbProductCategoryOption,
  setSbProductCategoryOption,
  tsProductBrandOptions,
  setTsProductBrandOptions,
  tsProductCategoryOption,
  setTsProductCategoryOption,
  showroomOption,
  setShowroomOption,
  ticketCreateLoding,
  setTicketCreateLoding,
  invCustomerPhoneNo,
  setInvCustomerPhoneNo,
  ticketFormData,
  setTicketFormData,
  handlePhoneSearchResult,
  ratingOption,
  YesOrNoOption,
  handleTicketInputChange,
  handleTicketSubmit,
}=TicketCreateComponent()

  return (
    <div className='mb-[100px]'>

          <CustomerInformation 
            invCustomerPhoneNo={invCustomerPhoneNo}
            setInvCustomerPhoneNo={setInvCustomerPhoneNo}
            onPhoneSearchResult={handlePhoneSearchResult} 
          />
          
            <div className="service-category">
              <div className="parent-category">
                  <form onSubmit={handleTicketSubmit}>
                  <div className=''>
                    <div className='bg-[#fff] shadow-xl pb-3'>
                        <SectionTitle title='Campaign Category ' />
                    </div>
                    
                    <Tabs>

                      <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list bg-[#fff] shadow-xl p-3' >
                        <Tab>
                          <input type="checkbox"
                            name="inboundCRM"
                            id="inboundCRM"
                            checked={autInboundCRM}
                            className='tabs-checkbox'
                            />
                          Inbound CRM</Tab>
                        <Tab>
                          <input type="checkbox"
                            name="outbound"
                            id="outbound"
                            checked={autoSelectOutboundCRM}
                            className='tabs-checkbox'
                            />
                          OutBound CRM
                        </Tab>
                        <Tab>
                          <input type="checkbox"
                            name="telesales"
                            id="telesales"
                            checked={autoTelesales}
                            className='tabs-checkbox'
                            />
                            Telesales CRM
                        </Tab>
                        <Tab>
                          <input type="checkbox"
                            name="happy_call"
                            id="happy_call"
                            checked={autoHappyCall}
                            className='tabs-checkbox'
                            />
                          Happy Call</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="child-category bg-[#fff] shadow-md p-2">
                        <FormPageTitle title='InBound CRM' />
                          <Tabs>
                              <TabList className='grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 tb-list'>
                                <Tab> 
                                  <input type="checkbox" className='tabs-checkbox' name="general_query" id="general_query" />
                                  General Query
                                </Tab>
                                <Tab>
                                    <input type="checkbox"
                                    name="online_query"
                                    id="online_query"
                                    checked={autoSelectOnlineQuery}
                                    className='tabs-checkbox'
                                    />
                                    Online Query
                                </Tab>
                                <Tab>
                                <input
                                      type="checkbox"
                                      name="dealership_query"
                                      id="dealership_query"
                                      checked={autoSelectDealershipQuery}
                                      className='tabs-checkbox'
                                    />
                                    Dealership Query 
                                </Tab>
                                <Tab>
                                  <input 
                                  type="checkbox"
                                  name="hire_purchase" id="hire_purchase" 
                                  checked={autoSelectHirePurchase}
                                  className='tabs-checkbox'
                                  />
                                  Hire Purchase 
                                </Tab>
                                <Tab> 
                                  <input type="checkbox" 
                                    name="corporate_purchase" id="corporate_purchase"
                                    checked={autoCorporatePurchase}
                                    className='tabs-checkbox'
                                  />
                                  Corporate Purchase
                                </Tab>
                                <Tab> 
                                    <input type="checkbox" name="HVAC" id="HVAC"
                                    checked={autoHVAC}
                                    className='tabs-checkbox'
                                    />
                                    HVAC
                                </Tab>

                                <Tab>
                                  <input type="checkbox" name="IJCR" id="IJCR"
                                    checked={autoIJCR}
                                    className='tabs-checkbox'
                                    />
                                    IJCR 
                                  </Tab>
                                <Tab> 
                                  <input type="checkbox"  name="SCBCC" id="SCBCC"
                                  checked={autoSCBCC}
                                  className='tabs-checkbox'
                                  />
                                  SCBCC
                                </Tab>
                              </TabList>

                              <TabPanel>
                                <FormPageTitle title='General Query'   />
                              </TabPanel>

                              <TabPanel>
                                  <OnlineQuery 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      setAutoSelectOnlineQuery={setAutoSelectOnlineQuery}
                                      oqProductBrandOptions={oqProductBrandOptions}
                                  />                   
                              </TabPanel>

                              <TabPanel>
                                  <DealershipQuery 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      setAutoSelectOnlineQuery={setAutoSelectOnlineQuery}
                                      dqProductBrandOptions = {dqProductBrandOptions}

                                  />
                              </TabPanel>

                              <TabPanel>
                                  <HirePurchase 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      hpProductCategoryOption={hpProductCategoryOption}
                                  />
                              </TabPanel>

                              <TabPanel>
                                  <CorporatePurchase 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      cpProductCategoryOption={cpProductCategoryOption}
                                  />
                              </TabPanel>

                              <TabPanel>
                                  <HVAC 
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                  />
                              </TabPanel>

                              <TabPanel>
                                  <IJCR 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    setInvCustomerPhoneNo={setInvCustomerPhoneNo}
                                    ibProductCategoryOption={ibProductCategoryOption}
                                    ibProductBrandOptions={ibProductBrandOptions}
                                    ibShowroomOption={ibShowroomOption}
                                    autoIJCR={autoIJCR}
                                  />
                              </TabPanel>

                              <TabPanel>
                                  <SCBCC 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      sbProductCategoryOption={sbProductCategoryOption}
                                      sbProductBrandOptions={sbProductBrandOptions}
                                      showroomOption={showroomOption}
                                      setInvCustomerPhoneNo={setInvCustomerPhoneNo}
                                      autoSCBCC={autoSCBCC}
                                  />
                              </TabPanel>
                              
                            </Tabs>
                        </div>
                      </TabPanel>

                      <TabPanel>
                          <div className="child-category bg-[#fff] shadow-xl p-2 ">
                            <Tabs>
                                <TabPanel>
                                   <OutBoundCRM 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      obProductBrandOptions={obProductBrandOptions}
                                   />
                                </TabPanel>
                              </Tabs>
                          </div>
                      </TabPanel>


                      <TabPanel>                    
                          <TelesalesCRm 
                              setTicketFormData = {setTicketFormData}
                              ticketFormData={ticketFormData}
                              tsProductBrandOptions={tsProductBrandOptions}
                              tsProductCategoryOption={tsProductCategoryOption}
                              handleTicketInputChange={handleTicketInputChange}
                          />
                      </TabPanel>


                      <TabPanel>
                          <div className="child-category bg-[#fff] shadow-xl p-2 ">
                            <Tabs>
                                <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list'>
                                  <Tab>
                                  <input type="checkbox"
                                    name="non_personal_CRM"
                                    id="non_personal_CRM"
                                    checked={autoNonPersonalCRM}
                                    className='tabs-checkbox'
                                    />
                                    Non Personal CRM</Tab>
                                  <Tab>
                                  <input type="checkbox"
                                    name="online_specific"
                                    id="online_specific"
                                    checked={autoOnlineSpecific}
                                    className='tabs-checkbox'
                                    />
                                    Online Specific</Tab>
                                </TabList>

                                <TabPanel>
                                    <NonPersonalCRM 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      YesOrNoOption={YesOrNoOption}
                                      ratingOption={ratingOption}
                                    />
                                </TabPanel>

                                <TabPanel>

                                    <OnlineSpecific 
                                      setTicketFormData = {setTicketFormData}
                                      ticketFormData={ticketFormData}
                                      handleTicketInputChange={handleTicketInputChange}
                                      YesOrNoOption={YesOrNoOption}
                                      ratingOption={ratingOption}                                 
                                    />

                                </TabPanel>

                              </Tabs>
                          </div>
                      </TabPanel>

                    </Tabs>

                    </div>

                        <CallDisposition 
                                setTicketFormData = {setTicketFormData}
                                ticketFormData={ticketFormData}
                                YesOrNoOption={YesOrNoOption}
                                handleTicketInputChange={handleTicketInputChange}
                        />
                        

                        <button type='submit' className='bg-[#FA6669]
                         text-[#fff] rounded-[5px] px-10 py-2 my-2'>{ticketCreateLoding ? 'Submitting...' : 'Submit'}</button>
                  </form>
                </div>
            </div>
    </div>
  )
}

export default TicketCreate


