import React, { useState } from 'react'
import ATForCompletedDeliveryResourceModal from './ATForCompletedDeliveryResourceModal';

const ATForCompletedDeliveryResourceModalButton = ({transport_assign,technician_id,fetchData,fetchDeatilsData,detailsData}) => {
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };



  return (
    <div>
        <div className='flex'>

        {detailsData?.assignedtechnicianforcompleteddeliveryresource_set?.length > 0?
            <button
            disabled
            className='bg-[#FA6669] rounded-[4px] px-[16px] py-[5px] text-[#fff]'
            >
              Assign
          </button>
          :
          <button
              onClick={() => openModal()}
              className='bg-[#FA6669] rounded-[4px] px-[16px] py-[5px] text-[#fff]'
              >
                Assign
          </button>
          }
        </div>

          <ATForCompletedDeliveryResourceModal
                isOpen={isModalOpen}
                onClose={closeModal}
                setModalOpen={setModalOpen}
                transport_assign ={transport_assign}
                technician_id = {technician_id}
                fetchData={fetchData}
                fetchDeatilsData={fetchDeatilsData}
            />
    </div>
  )
}

export default ATForCompletedDeliveryResourceModalButton