import React, { useEffect, useState } from 'react'
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import SelectInput from '../../../../Components/Form/SelectInput';
import TextareaInput from '../../../../Components/Form/TextareaInput';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import { AiFillDelete } from 'react-icons/ai';

const ATForIjcr = ({
    isModalOpen,
    closeModal,
    PriorityOption,
    modalSubmitLoading,
    setModalSubmitLoading,
    fetchTicketData,
    refreshAssignedDataList,
    technicianId,
    ticketData,
    setInvoiceDetailsData,
}) => {

    const [requiredMessage,setRequiredMessage]=useState('')
    const [IJCRItemOptions, setIJCRItemOptions] = useState([]);
    const [selectedIJCRItem, setSelectedIJCRItem] = useState([]);

    const [packageOptions, setPackageOptions] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);

    const [technicianAssignedFormIJCRFom,setTechnicianAssignedFormIJCRFom] = useState({
        technician: null,
        installation: null,
        ticket: null,
        priority:null,
        location:'',
        remarks:'',
        packageassignedtotechnician_set: [
            {
            item: null,
            package: null,
            package_quantity: ''
            }
        ]
    })


    const resetTechnicianAssignedForm = () => {
        setTechnicianAssignedFormIJCRFom({
            technician: null,
            installation: null,
            ticket: null,
            priority: null,
            location: '',
            remarks: '',
            packageassignedtotechnician_set: [
                {
                    item: null,
                    package: null,
                    package_quantity: ''
                }
            ]
        });
    };
    

    //   fetchInvoiceDetailsData
    useEffect(() => {
    const fetchInvoiceDetailsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details-with-invoice/${ticketData?.insatllation_bill[0]?.ib_invoice_no}/`);


            const filteredItemData = response.data?.sales_product_information_set?.filter((data) => {
                const itemData = data.product_information.id;
                return !selectedIJCRItem.some(
                (selected) => selected.value === itemData
                );
            });
    
            const options = filteredItemData.map((data) => ({
                value: data.product_information.id,
                label: `${data.product_information.name}`,
            }));
            setIJCRItemOptions(options);

          console.log('fetchInvoiceDetailsData',response.data)
          setInvoiceDetailsData(response.data)
        } catch (error) {
            console.log(error);
        }
    };
    fetchInvoiceDetailsData();
    }, [ticketData?.insatllation_bill[0]?.ib_invoice_no,selectedIJCRItem]);


    const [packageSearchInputValue,setPackageSearchInputValue] = useState()

    useEffect(() => {
    authAxiosWithBearer.get(`${baseURL}/inventory/api/active-item-package-list-for-ijcr/?search=${packageSearchInputValue}`)
        .then(response => {
            setPackageOptions(response.data?.results?.map(data => ({
                value: data.id,
                label: `${data.package_name}`
                })));

            console.log(response.data.results)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [packageSearchInputValue]);

    const handlePackageSearchInputChange = (value) => {
    setPackageSearchInputValue(value);
    };

      

    const handleIJCRItemChange = (selectedOption, index) => {
        const updatedFormData = { ...technicianAssignedFormIJCRFom };
    
        updatedFormData.packageassignedtotechnician_set[index] = {
            ...updatedFormData.packageassignedtotechnician_set[index],
            item: selectedOption?.value,
        };
    
        const updatedSelectedIJCRItem = [...selectedIJCRItem];
        updatedSelectedIJCRItem[index] = selectedOption || null;
    
        setSelectedIJCRItem(updatedSelectedIJCRItem);
        setTechnicianAssignedFormIJCRFom(updatedFormData);
    };



    const handleIJCRPrirotyChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
            setTechnicianAssignedFormIJCRFom(prevState => ({ ...prevState, priority: selectedOption.value }));
        } else {
            setTechnicianAssignedFormIJCRFom(prevState => ({ ...prevState, priority: null }));
        }
      };
      

    const handlePackageChange = (selectedOption, index) => {
        const updatedFormData = { ...technicianAssignedFormIJCRFom };
    
        updatedFormData.packageassignedtotechnician_set[index] = {
            ...updatedFormData.packageassignedtotechnician_set[index],
            package: selectedOption?.value,
        };
    
        const updatedSelectedPackage = [...selectedPackage];
        updatedSelectedPackage[index] = selectedOption || null;
    
        setSelectedPackage(updatedSelectedPackage);
        setTechnicianAssignedFormIJCRFom(updatedFormData);
    };
        

    const handleTechnicianAssignedForIJCRInputChange = (e, index, type) => {
        const updatedData = { ...technicianAssignedFormIJCRFom };
        const { name } = e.target;
        let value = e.target.value;  
        
        if (type === 'packageassignedtotechnician_set') {
            setRequiredMessage('');
            updatedData.packageassignedtotechnician_set[index][name] = value;
        } else {
            updatedData[name] = value;
        }
        
        setTechnicianAssignedFormIJCRFom(updatedData);
    };
    
  
    const areAllFieldsFilled = () => {
        return (
            technicianAssignedFormIJCRFom.packageassignedtotechnician_set &&
            technicianAssignedFormIJCRFom.packageassignedtotechnician_set.every((item) => {
                return item?.item !== null && item?.package !== null && item?.package_quantity !== '';
            })
        );
    };
  
    
    const handleAddMoreItem = () => {
        if (areAllFieldsFilled()) {
            setTechnicianAssignedFormIJCRFom((prevState) => ({
                ...prevState,
                packageassignedtotechnician_set: [
                    ...prevState.packageassignedtotechnician_set,
                    {
                        item: null,
                        package: null,
                        package_quantity: '',
                    },
                ],
            }));
            setRequiredMessage('');
        } else {
            setRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
  

    const handleRemoveItem = (index) => {
        const packageassignedtotechnician_set = [...technicianAssignedFormIJCRFom.packageassignedtotechnician_set];
        setRequiredMessage('');
    
        // Remove the item from the array
        const removedItem = packageassignedtotechnician_set.splice(index, 1)[0];
    
        // Remove the corresponding items from selectedItem arrays
        const updatedSelectedIJCRItem = selectedIJCRItem.filter((item, i) => i !== index);
        const updatedSelectedPackage = selectedPackage.filter((item, i) => i !== index);
    
        setTechnicianAssignedFormIJCRFom({
            ...technicianAssignedFormIJCRFom,
            packageassignedtotechnician_set,
        });
    
        setSelectedIJCRItem(updatedSelectedIJCRItem);
        setSelectedPackage(updatedSelectedPackage)
    };
  

    const handleInsatllationModalFormSubmit = async (e) => {
        e.preventDefault();
        try {
        setModalSubmitLoading(true);
    
        if (isModalOpen && technicianId !== null) {
            const updatedFormData = {
            ...technicianAssignedFormIJCRFom,
            technician: technicianId,
            installation: ticketData?.insatllation_bill[0]?.id,
            ticket: ticketData?.id,
            };
    
            console.log('Form Data:', updatedFormData);
    
            const response = await authAxiosWithBearer.post(
            `${baseURL}/ccd/api/assign-technician-with-package/`,updatedFormData);
    
            ShowToast('success', 'Successfully Submit');
            closeModal();
            await fetchTicketData();
            refreshAssignedDataList();
            setModalSubmitLoading(false);
            resetTechnicianAssignedForm();
            setSelectedIJCRItem([]);
            setSelectedPackage([]);
        
        }
        } catch (error) {
        setModalSubmitLoading(false);
        if(error.response.data.message){
            ShowToast('error',`${error.response.data.message}`)
        }
        console.error(error);
        }
    };

  return (
    <div>
    <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Insatllation Modal"
        className="modal"
        overlayClassName="overlay" 

        >
        <div className='mb-2 w-[360px] md:w-[700px]'>
            <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
                <h1 className='font-semibold'>Technician Assign For Installation </h1>
                <button className='text-[#000]' onClick={closeModal}><ImCancelCircle /></button>
            </div>
            
            <div className="mx-auto my-10 overflow-y-auto max-h-[400px]">
                <form onSubmit={handleInsatllationModalFormSubmit} className='px-2'>
                
                    <div className='my-10'>
                        <SelectInput
                        name='priority'
                        id='priority'
                        label='Priority'
                        className={`w-[99%] md:w-[99%] lg:w-[99%]`}
                        value={PriorityOption.find((option) => option?.value === technicianAssignedFormIJCRFom.priority)}
                        onChange={handleIJCRPrirotyChange}
                        options={PriorityOption}
                        isRequired={false}
                        />
                    </div>

                    <div  className='mt-10 mb-5'>                                
                        <TextareaInput type='text' 
                        className='h-[70.535px] w-[99%] md:w-[99%] lg:w-[99%]'
                        name='remarks' id='remarks'
                        value={technicianAssignedFormIJCRFom.remarks}
                        onChange={handleTechnicianAssignedForIJCRInputChange}
                        label='Remarks' isRequired={false}
                        placeholder='Enter Remarks here'/>
                    </div>

                    <div className="mx-2 my-5">
                        <h1 className='text-[15px] font-semibold' >Package Item Information</h1> <hr className='my-2' />
                    </div>

                    {technicianAssignedFormIJCRFom.packageassignedtotechnician_set?.map((packageItem, index) => (
                    <div className="flex gap-x-2 border-b">                               
                        <div className="my-4">
                            <SelectInputWC
                                label='Item*'
                                id={`item_${index}`}
                                name={`packageassignedtotechnician_set[${index}]?.item`}
                                value={selectedIJCRItem[index]}
                                onChange={(selectedOption) => handleIJCRItemChange(selectedOption, index)}
                                options={IJCRItemOptions}
                                placeholder='Select item'
                                isRequired={true}
                                isClearable={true}
                                className={`appearance-none border-[1px] md:w-[200px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                            />
                        </div>

                        <div className="my-4">
                            <SelectInputWC
                                label='Package*'
                                id={`package_${index}`}
                                name={`packageassignedtotechnician_set[${index}]?.package`}
                                value={selectedPackage[index]}
                                onChange={(selectedOption) => handlePackageChange(selectedOption, index)}
                                onInputChange={handlePackageSearchInputChange}
                                options={packageOptions}
                                placeholder='Select package'
                                isRequired={true}
                                isClearable={true}
                                className={`appearance-none border-[1px] md:w-[200px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                            />
                        </div>

                        <div className='my-4'>
                            <InputFiledWC type='number' 
                            name='package_quantity' label='Quantity*' 
                            id={`package_quantity_${index}`}
                            value={packageItem.package_quantity}
                            onChange={(e) => handleTechnicianAssignedForIJCRInputChange(e, index, 'packageassignedtotechnician_set')}
                            isRequired={true} 
                            placeholder='Enter quantity'
                            className='pl-3 py-4 appearance-none border-[1px] border-[#9d9c9c] h-[40.535px] w-[180px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            />
                        </div>

                        <div className='md:mt-10 mx-10 mb-2'>
                            {index !== 0 && (
                                <button
                                type="button"
                                onClick={() => handleRemoveItem(index)}
                                className="text-red-500"
                                >
                                <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>
                            )}
                        </div>

                    </div>
                    ))}

                    {requiredMessage && (
                        <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span> 
                    )}
                    <div className="flex justify-between my-2">

                        <button 
                            className='text-[#FA6669] text-[12px] hover:text-[#ff1600] font-semibold rounded-[5px] px-2 py-1 border hover:shadow'
                            type="button"
                            onClick={() => {
                                console.log('Add new item Button Clicked');
                                handleAddMoreItem();
                            }}
                            >ADD MORE
                        </button>
                        
                        <div>
                            {modalSubmitLoading?
                            <button disabled="disabled" className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-1'>Submiting...</button>
                            :
                            <button type="submit" className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-1'>Assign</button>
                            }
                        </div>

                    </div>



                </form>
            </div>
        </div>
    
    </Modal>

    </div>
  )
}

export default ATForIjcr