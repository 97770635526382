import React, { useState } from 'react'
import { formatDateTime } from '../../../Components/formatDateTime';
import PackageIssuedDetailsModal from '../../../Components/PackageIssuedDetailsModal';

const TechnicianWiseAssignedPackageListComponents = ({ticketData}) => {
    const [isPackageIssuedModalOpen, setPackageIssuedModalOpen] = useState(false);
    const [selectedPackageIssuedNo, setSelectedPackageIssuedNo] = useState(null);
  
    const openPackageIssuedModal = (packageIssuedNo) => {
        setPackageIssuedModalOpen(true);
        setSelectedPackageIssuedNo(packageIssuedNo);
    };
  
    const closePackageIssuedModal = () => {
        setPackageIssuedModalOpen(false);
        setSelectedPackageIssuedNo(null);
    };

  return (
    <div>
        {ticketData?.packageassignedtotechnician_set?.length > 0 && (
            <div className='bg-[#fff] border border-[#E74A3B] shadow-lg rounded-[8px] mb-5'>
                <div className='information-head my-1 px-10 py-2 mx-2'>
                    <div>Package Information</div>
                </div>

                {ticketData?.packageassignedtotechnician_set?.map((item)=>(
                    <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-4 border-b pb-2'>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Product</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{item?.invoice_item_name}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Package</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{item?.package_name}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Package Qty</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{item?.package_quantity}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>SR Issue</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>
                                    {item.sr_issued === true ?
                                    <div className='text-green-500 font-bold px-1 text-end'>Issued</div>
                                    :
                                    <div className='text-red-500 font-bold  px-1 text-end'>Raised</div>
                                    }
                            </p>
                        </div>
                    </p>
                    {item.sr_issued === true && ( 
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Issued Date</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>
                                {formatDateTime(item?.isssued_date)}
                            </p>
                        </div>
                    </p>
                    )}
                    {item.sr_issued === true && ( 
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Issued No</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>
                                {item.issue_no}
                                <button
                                    onClick={() => openPackageIssuedModal(item.issue_no)}
                                    className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                                    >
                                    Details
                                </button>
                            </p>
                        </div>
                    </p>
                    )}
                    </div>
                ))}


                {/* PackageIssuedDetailsModal start */}
                <PackageIssuedDetailsModal
                    isOpen={isPackageIssuedModalOpen}
                    onClose={closePackageIssuedModal}
                    packageIssuedNo={selectedPackageIssuedNo}
                />
                {/* PackageIssuedDetailsModal end */}

            </div> 
        )}
    </div>
  )
}

export default TechnicianWiseAssignedPackageListComponents