import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import { useRef } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import GRnDetailsComponent from './GRnComponents/GRnDetailsComponent';

const GRnDetails = () => {
    const {id} = useParams()

    const componentPDF = useRef();
    const pdfGeneretor = useReactToPrint({
      content: () => componentPDF.current,
      documentTitle: "Esquire-CRM",
      pageStyle: `
        @page landscape {
          size: A4 landscape;
          margin: 20mm 10mm;
        }
        @page portrait {
          size: A4 portrait;
          margin: 20mm 10mm;
        }
        @page {
          margin: 4mm;
        }
        body {
          margin: 0;
        }
      `,
      contentStyle: `
        @page landscape {
          transform: rotate(0deg) translate(0, 0);
        }
        @page portrait {
          transform: rotate(90deg) translate(0, 0);
        }
      `
    });

    
    const [grnData, setGRnData] = useState([])

    useEffect(()=>{
        const fetchGRnData =async ()=>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/grn-details/${id}/`)
                console.log(response.data)
                setGRnData(response.data)
            } catch (error) {
                
            }
        };fetchGRnData();
    },[id])

  return (
    <div>

          <GRnDetailsComponent grnData={grnData} pdfGeneretor={pdfGeneretor} />

          {/* <GRnPrintView grnData={grnData} componentPDF={componentPDF} /> */}


    </div>
  )
}

export default GRnDetails