// UserList.jsx

import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import SupplierListComponent from './SupplierComponents/SupplierListComponent';

function SupplierList({user}) {
  const navigate = useNavigate();
  const [currentPage,setCurrentPage] =useState(1)
  const [sortOrder, setSortOrder] = useState({});
  
  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      sortOrder: sortOrder['name'],
    },
    {
        title: 'Address',
        dataIndex: ['address_1', 'address_2'], // Merge both address fields
        key: 'address',
        render: (text, record) => `${record.address_1}, ${record.address_2}`, // Render both addresses together
        sorter: (a, b) => {
          // Customize sorting logic based on your requirements
          const addressA = `${a.address_1}, ${a.address_2}`.toLowerCase();
          const addressB = `${b.address_1}, ${b.address_2}`.toLowerCase();
          return addressA.localeCompare(addressB);
        },
        // Ensure you have defined sortOrder for address column specifically
        sortOrder: sortOrder['address'], // You may need to update sortOrder key according to your implementation
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '3',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortOrder: sortOrder['email'],
      responsive: ['md']
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: (a, b) => a?.phone?.localeCompare(b?.phone),
      sortOrder: sortOrder['phone'],
      responsive: ['md']
    },

    {
        title: 'Contact Person',
        dataIndex: 'contact_person',
        sorter: (a, b) => a?.contact_person?.localeCompare(b?.contact_person),
        sortOrder: sortOrder['contact_person'],
        responsive: ['md']
    },

    {
        title: 'C.Person No',
        dataIndex: 'contact_person_no',
        sorter: (a, b) => a?.contact_person_no?.localeCompare(b?.contact_person_no),
        sortOrder: sortOrder['contact_person_no'],
        responsive: ['md']
    },
  
  ];

  const addNewURL = user?.menu_permissions?.includes("3.12.1")? '/supplier-add':''
  const dataEditURL = user?.menu_permissions?.includes("3.12.3")? '/supplier-update':''

  return (
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
      <SupplierListComponent 
        apiEndpoint="inventory/api/supplier-list/" 
        columnsConfig={columns}
        title="Supplier List"
        SearchKey1='name'
        SearchKey2='email'
        SearchKey3='phone'
        SearchKey4='address_1'
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        addNewURL={addNewURL}
        dataEditURL={dataEditURL}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SupplierList);