import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../baseURL'
import AssignedTicketDetailsComponent from '../Technician/TechnicianComponent/AssignedTicketDetailsComponent'
import AssignedTransportDetailsComponent from '../InHouse/InHouseComponents/AssignedTransportDetailsComponent'
import AssignedTransportStatusChangeForm from './TransportUserComponent/AssignedTransportStatusChangeForm'

const UserWiseAssignedTransportDetails = () => {
    const {id} = useParams()
    const [data, setData]= useState(null)
    const [ticketData, setTicketData] = useState(null);

    const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/user-wise-assigned-transport-details/${id}/`);
            console.log('ticket',response.data);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
   

    // fetchDeatilsData
    useEffect(() => {
        fetchDeatilsData();
    }, [id]);

    
    // fetchAssignedTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${data?.assigned_technician}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [data]);
    
  return (
    <div>

            <div className="user-details-page-title mb-5 shadow">
                <div className="my-auto py-2">
                    <h1 className='uppercase'>Assigned Transport Details</h1>
                </div>
            </div>

            <AssignedTransportStatusChangeForm 
            data={data} id={id} 
            fetchDeatilsData={fetchDeatilsData} 
            url = {`user-wise-assigned-transport-status-change`}
            />

            {/* assigned transport details start  */}
                <AssignedTransportDetailsComponent  data={data} />
            {/* assigned transport details end  */}
        
            {/* assigned ticket details start  */}
                <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}

    </div>
  )
}

export default UserWiseAssignedTransportDetails