import { format } from 'date-fns';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';

const HOBillingFromHangleComponent = ({setModalOpen,fetchData}) => {
    const [formData,setFormData] = useState({
        from_date : null,
        to_date : null
    })
    const navigate = useNavigate();

    const handleFromDateChange = (date) => {
        setFormData({
          ...formData,
          from_date: date,
        });
      };

      const handleToDateChange = (date) => {
        setFormData({
          ...formData,
          to_date: date,
        });
      };


      const handleSubmit = async (e) => {
        e.preventDefault();
        try {


          const UpdateFormData = {
            ...formData,
            from_date  : formData.from_date ? format(formData.from_date, 'yyyy-MM-dd') : null,
            to_date  : formData.to_date ? format(formData.to_date, 'yyyy-MM-dd') : null,
          }
          const response = await authAxiosWithBearer.post(`${baseURL}/billing/api/ho-billing-create/`, UpdateFormData);
    
          if (response) {
            console.log(response);
            ShowToast('success', 'Successfully Submit');
            navigate('/ho-billing-list');
            setModalOpen(false)
            fetchData()
          }
        } catch (error) {
          console.log(error);
          if(error.response.data.error){
            ShowToast('error', `${error.response.data.error}`);
          }else{
            ShowToast('error', 'Something is wrong');
          }
        }
      };

  return (
    {
        formData,
        setFormData,
        handleFromDateChange,
        handleToDateChange,
        handleSubmit,
    }
  )
}

export default HOBillingFromHangleComponent