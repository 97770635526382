import React from 'react'
import GeneralSRDetailsCom from './GeneralSRComponents/GeneralSRDetailsCom'
import { useParams } from 'react-router-dom'
import useFetchDetailsData from '../../../hooks/useFetchDetailsData'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import SrIdWiseIssuedListCom from '../InventoryComponent/SrIdWiseIssuedListCom'

const UserWiseGSRDetails = () => {
    const {id} = useParams()

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/user-wise-general-sr-details', {id});
  
    if(loading){
        return <div><LoadingSpinner /> </div>
    }

  return (
    <div>
    <div className="req-detail shadow-xl pb-5 border mx-4 bg-[#fff]">

    <div className="user-details-page-title mb-5 shadow flex justify-between  px-5 py-2">
          <div className="my-auto py-2">
            <h1 className='uppercase'>Requisition Details</h1>
          </div>
        </div>

            <GeneralSRDetailsCom detailsData={detailsData}/>
    </div>


    <SrIdWiseIssuedListCom apiEndPoint={`/inventory/api/user-wise-general-sr-issue/?general_sr_id=${id}`} />

</div>
  )
}

export default UserWiseGSRDetails