import React, { useEffect, useState } from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import SelectInput from '../../../../Components/Form/SelectInput'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import InputFiled from '../../../../Components/Form/InputFiled'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL'

const OutBoundCRM = ({
    setTicketFormData,
    ticketFormData,
    handleTicketInputChange,
    obProductBrandOptions,
}) => {
    const [obDepartmnetoption,setObDepartmentOption] = useState(null)
    const [selectedObDepartmnet,setSelectedObDepartment] = useState(null)

    const [obQueryTypeoption,setObQueryTypeOption] = useState(null)
    const [selectedObQueryType,setSelectedObQueryType] = useState(null)

    const [obOptionToOption,setObOptionToOption] = useState(null)
    const [selectedObOptionTo,setSelectedObOptionTo] = useState(null)
    const [selectedObProductBrand, setSelectedObProductBrand] = useState(null);

    useEffect(()=>{
        const fetchOBDepartmentData = async () =>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/outbound-department-list/`)
                console.log(response.data)
                setObDepartmentOption(response.data.map((data)=>({
                    value:data.id,
                    label:data.name
                })))
            } catch (error) {
                console.log(error)
            }
        };
        const fetchObQueryTypeData = async () =>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/outbound-query-type-list/`)
                console.log(response.data)
                setObQueryTypeOption(response.data.map((data)=>({
                    value:data.id,
                    label:data.name
                })))
            } catch (error) {
                console.log(error)
            }
        };
        const fetchObOptionTOData = async () =>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/outbound-optionto-list/`)
                console.log(response.data)
                setObOptionToOption(response.data.map((data)=>({
                    value:data.id,
                    label:data.name
                })))
            } catch (error) {
                console.log(error)
            }
        };

        fetchOBDepartmentData();
        fetchObQueryTypeData();
        fetchObOptionTOData();
    },[])


    

    const handleObProductBrandChange = (selectedOption) => {
        const productBrandId = selectedOption ? selectedOption.value : null;
        setSelectedObProductBrand(selectedOption);
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ob_product_brand: productBrandId,
          }));
      };

    const handleObDepartmentChange = (selectedOption) => {
        const ID = selectedOption ? selectedOption.value : null;
        setSelectedObDepartment(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          ob_department : ID,
        }));
      };

    const handleObQueryTypeChange = (selectedOption) => {
        const ID = selectedOption ? selectedOption.value : null;
        setSelectedObQueryType(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          ob_query_type : ID,
        }));
      };

    const handleObOptionToChange = (selectedOption) => {
        const ID = selectedOption ? selectedOption.value : null;
        setSelectedObOptionTo(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          ob_option_to : ID,
        }));
      };


  return (
    <div>
        <FormPageTitle title='OutBound CRM'   />
        <div className="grid grid-cols-2 lg:grid-cols-3">
            <div className='my-4 md:mx-auto'>
                  <SelectInput
                      name='dq_product_brand'
                      id='dq_product_brand'
                      label='Product Brand'
                      className={`w-full`}
                      value={selectedObProductBrand}
                      onChange={handleObProductBrandChange}
                      options={obProductBrandOptions}
                      isRequired={false}
                      isClearable={true}
                  />
              </div>
            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='ob_option_to'
                    id='ob_option_to'
                    label='Option To'
                    className={`w-[99%]`}
                    value={selectedObOptionTo}
                    onChange={handleObOptionToChange}
                    options={obOptionToOption}
                    isRequired={false}
                    isClearable={true}
                />
            </div>
            <div  className='my-4 md:mx-auto'>
                <InputFiled type='text'  
                    className={`w-[99%]`}
                    name='ob_staff_name' 
                    id='ob_staff_name' 
                    label='Staff Name'
                    value={ticketFormData.ob_staff_name}
                    onChange={handleTicketInputChange}
                    isRequired={false} 
                    placeholder='Enter Staff Name'
                />
            </div>
            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    className={`w-[99%]`}
                    name='ob_department'
                    id='ob_department'
                    label='Department'
                    value={selectedObDepartmnet}
                    onChange={handleObDepartmentChange}
                    options={obDepartmnetoption}
                    isRequired={false}
                    isClearable={true}
                />
            </div>

            <div  className='my-4 md:mx-auto'>
                <SelectInput
                    name='ob_query_type'
                    id='ob_query_type'
                    className={`w-[99%]`}
                    label='Query Type'
                    value={selectedObQueryType}
                    onChange={handleObQueryTypeChange}
                    options={obQueryTypeoption}
                    isRequired={false}
                    isClearable={true}
                />
            </div>
        </div>
    </div>
  )
}

export default OutBoundCRM