import React, { useState } from 'react'
import HOBillingCreateModal from './HOBillingCreateModal';

const HOBillingCreateModalButton = ({fetchData}) => {
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };



  return (
    <div>
        <div className='flex justify-center'>
              <button
                  onClick={() => openModal()}
                  className='bg-[#FA6669] rounded-[4px] px-[5px] py-[5px] text-[#fff] ml-4'
                  >
                  Create Bill For HO
              </button>
        </div>

          <HOBillingCreateModal
                isOpen={isModalOpen}
                onClose={closeModal}
                fetchData={fetchData}
                setModalOpen={setModalOpen}
            />
    </div>
  )
}

export default HOBillingCreateModalButton