import React from 'react'
import { formatDateTime } from '../../../Components/formatDateTime'
import TextCopyButton from '../../../Components/TextCopyButton'

const ShowroomReqDetailsComponent = ({detailsData}) => {
  return (
    <div className='mx-2'>
        <div className="user-details-page-title mb-5 shadow">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Showroom Ticket Request Details</h1>
            </div>
        </div>

        <div className="information-section my-5 px-1 py-6 ">
            <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[130px] gap-y-6">
                
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Requested ID
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.requested_id}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Customer Name
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.customer_name}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Phone
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.customer_phone}
                        
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Address
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.customer_address}
                    </div> 
                </div>


                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Invoice No
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.invoice_no}

                        {detailsData?.invoice_no && ( 
                            <TextCopyButton text={detailsData?.invoice_no} />
                        )}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Service Sategory
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.service_category}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Product Name
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.product_name}
                    </div> 
                </div>
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Product Code
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.product_code}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Warranty Status
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.warranty_status === true? "Warranty" : "Expired"}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Requested Date
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.created_at && formatDateTime(detailsData?.created_at)}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Basic Charge
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.basic_charge}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Payment Status
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.pay_status === true? "Paid" : "Unpaid"}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Transaction No
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.transaction_no}
                    </div> 
                </div>


                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Requested Status
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.requested_status === true? "Ticket Created" : "Pending"}
                    </div> 
                </div>

                {detailsData?.ticket_no && (
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Ticket No
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.ticket_no}
                        
                        {detailsData?.ticket_no && ( 
                            <TextCopyButton text={detailsData?.ticket_no} />
                        )}
                    </div> 
                </div>
                )}

                {detailsData?.ticket_created_at && (
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Ticket Created At
                    </div>
                    <div className='w-[60%] text-end'>
                    {detailsData?.ticket_created_at && formatDateTime(detailsData?.ticket_created_at)}
                    </div> 
                </div>
                )}

            </div>


            <div className='flex flex-wrap border-b mx-2 md:mx-5'>
                    <div className='w-[20%]'>
                    Remarks
                    </div>
                    <div className='w-[80%] text-end'>
                        {detailsData?.remarks}
                        {detailsData?.remarks && ( 
                            <TextCopyButton text={detailsData?.remarks} />
                        )}
                    </div> 
            </div>
        </div>
    </div>
  )
}

export default ShowroomReqDetailsComponent