import React from 'react'

const CustomSubmitButton = ({isLoading}) => {
  return (
    <>
        {isLoading ? (
            <button disabled className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>
                Submitting...
            </button>
        ) : (
            <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>
                Submit
            </button>
        )}
    </>
  )
}

export default CustomSubmitButton

