import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { parseISO } from 'date-fns';
import ShowToast from '../../../../Components/ShowToast';

const InvoiceWiseSCBCcDataGet = ({setTicketFormData,setInvCustomerPhoneNo}) => {

  const [inputInvoiceValueForSCBCC, setInputInvoiceValueForSCBCC] = useState('');
  const [invoiceNoGetForSCBCC, setInvoiceNoGetForSCBCC] = useState(null)

  const [selectedModelNoForSCBCC, setSelectedModelNoForSCBCC] = useState(null)
  const [modelNoForSCBCCOption, setModelNoForSCBCCOption] =useState([]);

  const [selectedShowroomForSCBCC, setSelectedShowroomForSCBCC] = useState(null)
  const [showroomForSCBCCOption, setShowroomForSCBCCOption] =useState([]);

  const [selectedSbProductBrandForSCBCC, setSelectedSbProductBrandForSCBCC] = useState(null)
  const [sbProductBrandOptionForSCBCC, setSbProductBrandOptionForSCBCC] =useState([]);

  const [selectedSbProductCategoryForSCBCC, setSelectedSbProductCategoryForSCBCC] = useState(null)
  const [sbProductCategoryOptionForSCBCC, setSbProductCategoryOptionForSCBCC] =useState([]);


  const handleInvoiceGetForSCBCC = (e) => {
    e.preventDefault()
    setInvoiceNoGetForSCBCC(inputInvoiceValueForSCBCC);
    setSelectedModelNoForSCBCC(null)

    setTicketFormData((prevFormData) => ({
      ...prevFormData,
      sb_invoice_no : inputInvoiceValueForSCBCC,
    }));
    
  };

  const handleInvoiceResetForSCBCC = (e) => {
    e.preventDefault()
    setInvoiceNoGetForSCBCC(null);
  };


  useEffect(() => {
    const fetchInvoiceDetailsData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details-with-invoice/${invoiceNoGetForSCBCC}/`);

        setModelNoForSCBCCOption(response.data?.sales_product_information_set?.map((data) => ({
          value: data.product_information.model,
          label: data.product_information.model,
        })));

        setSbProductBrandOptionForSCBCC(response.data?.sales_product_information_set?.map((data) => ({
          value: data.product_information.product_brand,
          label: data.product_information.product_brand_name,
        })));

        setSbProductCategoryOptionForSCBCC(response.data?.sales_product_information_set?.map((data) => ({
          value: data.product_information.product_category_id,
          label: data.product_information.product_category_name,
        })));

        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          purchase_date  : response.data.invoice_date ? parseISO(response.data.invoice_date) : null
        }));


        const showroomInfo = response.data?.showroom_info;
        if (showroomInfo) {
          setShowroomForSCBCCOption([
            {
              value: showroomInfo.id,
              label: showroomInfo.address_1,
            }
          ]);
        }

        setInvCustomerPhoneNo(response.data?.customer_info?.phone_no)

      } catch (error) {
        console.log(error);
        if (invoiceNoGetForSCBCC && error.response && error.response.status === 404) {
          ShowToast('error', 'Data not found');
        }

        }
    };

    if (invoiceNoGetForSCBCC) {
      fetchInvoiceDetailsData();
    }
  }, [invoiceNoGetForSCBCC]);


  const handlemodelNoChangeForSCBCC = (selectedOption) => {
    const model_name = selectedOption ? selectedOption.value : '';

    setSelectedModelNoForSCBCC(selectedOption);
    setTicketFormData((prevFormData) => ({
      ...prevFormData,
      model_name : model_name,
    }));
  };

  const handleShowroomChangeForSCBCC = (selectedOption) => {
    const showroomId = selectedOption ? selectedOption.value : null;
    setSelectedShowroomForSCBCC(selectedOption);
    setTicketFormData((prevFormData) => ({
      ...prevFormData,
      showroom: showroomId,
    }));
  };

  const handleSbProductBrandChangeForSCBCC = (selectedOption) => {
    const sbProductBrandId = selectedOption ? selectedOption.value : null;
    setSelectedSbProductBrandForSCBCC(selectedOption);
    setTicketFormData((prevFormData) => ({
      ...prevFormData,
      sb_product_brand: sbProductBrandId,
    }));
  };

  const handleSbProductCategoryChangeForSCBCC = (selectedOption) => {
    const sbProductCateID = selectedOption ? selectedOption.value : null;

    setSelectedSbProductCategoryForSCBCC(selectedOption);
    setTicketFormData((prevFormData) => ({
      ...prevFormData,
      sb_product_category  : sbProductCateID,
    }));
  };

  return {
    inputInvoiceValueForSCBCC,
    setInputInvoiceValueForSCBCC,
    invoiceNoGetForSCBCC,
    setInvoiceNoGetForSCBCC,
    selectedModelNoForSCBCC,
    setSelectedModelNoForSCBCC,
    modelNoForSCBCCOption,
    setModelNoForSCBCCOption,
    selectedShowroomForSCBCC,
    setSelectedShowroomForSCBCC,
    showroomForSCBCCOption,
    setShowroomForSCBCCOption,
    selectedSbProductBrandForSCBCC,
    setSelectedSbProductBrandForSCBCC,
    sbProductBrandOptionForSCBCC,
    setSbProductBrandOptionForSCBCC,
    selectedSbProductCategoryForSCBCC,
    setSelectedSbProductCategoryForSCBCC,
    sbProductCategoryOptionForSCBCC,
    setSbProductCategoryOptionForSCBCC,
    handleInvoiceGetForSCBCC,
    handleInvoiceResetForSCBCC,
    handlemodelNoChangeForSCBCC,
    handleShowroomChangeForSCBCC,
    handleSbProductBrandChangeForSCBCC,
    handleSbProductCategoryChangeForSCBCC,
  }
}

export default InvoiceWiseSCBCcDataGet