import React, { useState, useEffect } from 'react';
import { Table, Pagination, Select, Input } from 'antd';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetchListData from '../../../../hooks/useFetchListData';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';
import CustomPaginator from '../../../../Components/CustomPaginator';
import { formatDateTime } from '../../../../Components/formatDateTime';


const CustomerComplainListComponent = ({ user,pageTitle,apiEndpoint }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [dataStatus, setDataStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndpoint}`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, dataStatus, endDate,startDate,apiEndpoint]);

  const handleSearch = (value) => {
    setSearchText(value);
  };


  const dataSource = data?.map((item, index) => ({
    key: index+1,
    id: item.id,
    complain_id: item.complain_id,
    name: item.name,
    phone_no: item.phone_no,
    invoice_no: item.invoice_no,
    created_at: item.created_at,
    complaint_status:item.complaint_status
  }));

  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Complain ID',
      dataIndex: 'complain_id',
      key: 'complain_id',
    },
    {
      title: 'Customer name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_no',
      key: 'phone_no',
    },
    {
        title: 'Phone',
        dataIndex: 'phone_no',
        key: 'phone_no',
      },
      {
        title: 'Invoice',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: '7',
        render: (created_at) => {
          const formattedDateTime = formatDateTime(created_at);
          return <span>{formattedDateTime}</span>;
        },
        responsive: ['md']
      },
    {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className="flex gap-2">
            {user?.menu_permissions?.includes('2.5.2') && (
              <button onClick={() => navigate(`/customer-complain-details/${record.id}`)} className="rounded">
                <AiFillEye className="text-[#3030d8] text-xl" />
              </button>
            )}
            {user?.menu_permissions?.includes('2.1.1') && record?.complaint_status === 'Pending' && (
                <button className='bg-[#EB5757] text-[#fff] p-1 rounded' onClick={() => navigate(`/ticket-create-for-customer-complain/${record.id}`)}>
                  Ticket Create
                </button>
              )}
          </div>
        ),
      },
  ];


  return (
    <>
    <div className='container-fluid grid grid-cols-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>{pageTitle}</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>

            {user?.primary_role == 8 && (
            <div className="add-new-button px-2 ml-1">
                  <Link to={`/new-ticket-request`}>Add Request</Link>
            </div>
            )}
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4 justify-between md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>


      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
        <>
        <div className='shadow-lg overflow-x-auto'>
          <Table 
              columns={columns}
              dataSource={dataSource} 
              pagination={false}
          />
          <div className='my-5'>
                <CustomPaginator 
                    setCurrentPage={setCurrentPage} 
                    currentPage={currentPage}
                    totalItems={totalItems}
                />
          </div>
        </div>
       </>
      )} 
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CustomerComplainListComponent);
