import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ShowToast from '../../../../../Components/ShowToast'
import { authAxiosWithBearer, baseURL } from '../../../../../baseURL'

const GSRIssueFormHandleCom = ({detailsData}) => {

    const [reqData, setReqData] = useState(null)
    const navigate = useNavigate()
    const [isLoading,setIsLoading]=useState(false)
    const [requisionItemOptions, setRequisionItemOptions] = useState([]);
    const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
    const [requisionIssueRequiredMessage,setRequisionIssueRequiredMessage]=useState('')

    const [requisionIssueFormData, setRequisionIssueFormData] = useState({
        general_sr: null,
        srissueditem_set: [
          {
            gsr_item: null,
            item_id: null,
            item:null,
            issue_qty:'',
            max_issue_qty:'',
            remarks:'',
          },
        ],
    });

    useEffect(()=>{
        const options = detailsData.generalsritem_set
        .filter((data)=>data?.available_issue_quantity > 0)
        .map((data) => ({
            value: data.id,
            label: `${data.item_name} | ${data.item_specification} | ${data.item_size} | ${data.item_model}`,
            item: data.item,
            uom: data.uom_text,
            sr_quantity:data.quantity,
            max_issue_qty:data.available_issue_quantity,
            item_stock_available_qty:data.item_stock_available_qty
        }));
        setRequisionItemOptions(options);
    },[detailsData])

    console.log('detailsData',detailsData)
    console.log('requisionItemOptions',requisionItemOptions)


    const handleRequisionItemChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionIssueFormData };
        
        // Reset issue_qty  when the item selection changes
        updatedFormData.srissueditem_set[index] = {
            gsr_item: selectedOption?.value || null,
            item_id: selectedOption?.item || null,
            item: selectedOption?.item || null,
            issue_qty: selectedOption?.sr_quantity || '',
            uom: selectedOption?.uom || '',
            max_issue_qty: selectedOption?.max_issue_qty || '',
            item_stock_available_qty: selectedOption?.item_stock_available_qty || '',
        };
        
        const updatedSelectedRequisionItem = [...selectedRequisionItem];
        updatedSelectedRequisionItem[index] = selectedOption || null;
        
        setSelectedRequisionItem(updatedSelectedRequisionItem);
        setRequisionIssueFormData(updatedFormData);
    };
        

        
    const handleRequisionIssueInputChange = (e, index, type) => {
        const updatedData = { ...requisionIssueFormData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'srissueditem_set' && name === 'issue_qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }
      
        if (type === 'srissueditem_set') {
          setRequisionIssueRequiredMessage('');
          updatedData.srissueditem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setRequisionIssueFormData(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            requisionIssueFormData.srissueditem_set &&
            requisionIssueFormData.srissueditem_set.every((item) => {
                return item?.gsr_item !== null && item?.issue_qty !== '';
            })
        );
    };
    
      
    const handleAddRequisionIssueItem = () => {
        if (areAllFieldsFilled()) {
            setRequisionIssueFormData((prevState) => ({
                ...prevState,
                srissueditem_set: [
                    ...prevState.srissueditem_set,
                    {
                        gsr_item: null,
                        issue_qty:'',
                    },
                ],
            }));
            setRequisionIssueRequiredMessage('');
        } else {
            setRequisionIssueRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    
    const handleRemoveRequisionIssueItem = (index) => {
      // Create a copy of the damagepartsitem_set array
      const srissueditem_set = [...requisionIssueFormData.srissueditem_set];
    
      const lastIndex = srissueditem_set.length - 1;
  
      const lastItemIsNull = srissueditem_set[lastIndex] && srissueditem_set[lastIndex].gsr_item === null;
      
  
      if (index === lastIndex) {
        srissueditem_set.splice(lastIndex, 1);
      } 
      else if (index !== lastIndex && lastItemIsNull) {
        ShowToast('error','Cannot delete this item because last item is null.');
        return;
      }
      else if (index !== lastIndex && lastItemIsNull === false) {
        srissueditem_set.splice(index, 1);
      }
    
      const updatedSelectedItem = selectedRequisionItem.filter((_, i) => i !== index);
    
      setRequisionIssueRequiredMessage('');
    
      setRequisionIssueFormData({
        ...requisionIssueFormData,
        srissueditem_set,
      });
      setSelectedRequisionItem(updatedSelectedItem);
    };
    

    const handleRequisionIssueSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
          // Check if any issue quantity exceeds the maximum quantity for each requisition detail
          const issueQtySumMap = new Map();
      
          // Iterate through srissueditem_set to calculate the sum for each requisition detail ID
          requisionIssueFormData.srissueditem_set.forEach((item) => {
            const issueQty = parseFloat(item.issue_qty);
            const maxIssueQty = parseFloat(item.max_issue_qty);
      
            // If the sum already exists, add the current issue quantity to it; otherwise, set the current issue quantity
            const currentSum = issueQtySumMap.get(item.gsr_item) || 0;
            issueQtySumMap.set(item.gsr_item, currentSum + issueQty);
          });
      
          // Check if any sum exceeds the max_issue_qty for the corresponding requisition detail ID
          const isExceedingMaxQuantity = requisionIssueFormData.srissueditem_set.some((item) => {
            const sum = issueQtySumMap.get(item.gsr_item) || 0;
            return sum > parseFloat(item.max_issue_qty);
          });
      
          if (isExceedingMaxQuantity) {
            ShowToast('error', 'Total issue quantity cannot exceed the maximum quantity for any item.');
            return;
          }
      
          const updatedFormData = { 
            ...requisionIssueFormData, 
            general_sr: detailsData?.id,
          };
      
          const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/sr-issued/`, updatedFormData);
      
          if (response) {
            console.log(response);
            ShowToast('success', 'Successfully Submit');
            navigate('/general-sr-list');
          }
        } catch (error) {
          console.log(error);
          if(error.response.data.error){
            ShowToast('error', `${error.response.data.error}`)

          }else{
            ShowToast('error', 'Something is wrong')
          }
        }finally{
          setIsLoading(false)
        }
    };
     

  return {
    requisionItemOptions,
    setRequisionItemOptions,
    selectedRequisionItem,
    setSelectedRequisionItem,
    requisionIssueRequiredMessage,
    setRequisionIssueRequiredMessage,
    requisionIssueFormData,
    setRequisionIssueFormData,
    handleRequisionItemChange,
    handleRequisionIssueInputChange,
    handleAddRequisionIssueItem,
    handleRemoveRequisionIssueItem,
    handleRequisionIssueSubmit,
    isLoading
  }
}

export default GSRIssueFormHandleCom