import React from 'react';
import GSRListCom from './GeneralSRComponents/GSRListCom';

const UserWiseGSRList = () => {

  return (
    <>
      <GSRListCom 
        apiEndpoint={`/inventory/api/user-wise-general-sr/`}
        detailsURL={`/user-wise-general-sr-details/`}
        detailsPermissionCode={'3.14.2'}
      />
    </>
  );
};


export default UserWiseGSRList;