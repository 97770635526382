import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent'
import AssignedTechForGoodsCheckDetailsComponent from '../InHouseComponents/AssignedTechForGoodsCheckDetailsComponent'
import GoodsCheckedTechnicianFeedbackDetailsomponent from '../../Technician/TechnicianComponent/GoodsCheckedTechnicianFeedbackDetailsomponent'
import { useSelector } from 'react-redux'
import ATForServicingDetailsComponent from '../InHouseComponents/ATForServicingDetailsComponent'
import SrIdWiseIssuedListCom from '../../Inventory/InventoryComponent/SrIdWiseIssuedListCom'

const ATForServicingDetails = () => {
    const {id} = useParams()
    const [data, setData]= useState(null)
    const [ticketData, setTicketData] = useState(null);
    const user = useSelector(state => state.auth.user);
    const [goodsCheckAssignData,setGoodsCheckAssignData] = useState(null)
    // fetchDeatilsData
    const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/technician-assign-for-servicing-details/${id}/`);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
        };

    useEffect(() => {
        fetchDeatilsData();
    }, [id]);

    
    // fetchAssignedTicketData
    const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${data?.ticket_id}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };

    useEffect(() => {
        fetchTicketData();
    }, [data]);


        // fetchDeatilsData
        const fetchGoodsCheckAssignedDeatilsData = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/assigned-technician-for-transported-goods-check-details/${data?.technician_assign_for_goods_check_id}/`);
                setGoodsCheckAssignData(response.data);
            } catch (error) {
                console.log(error);
            }
            };
    
        useEffect(() => {
            fetchGoodsCheckAssignedDeatilsData();
        }, [data]);

    
  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Details</h1>
            </div>
        </div>

            <AssignedTicketDetailsComponent  ticketData={ticketData} />

            <AssignedTechForGoodsCheckDetailsComponent  data={goodsCheckAssignData} />

            {goodsCheckAssignData?.goodscheckedtechnicianfeedback_set?.length > 0 && (
                <GoodsCheckedTechnicianFeedbackDetailsomponent 
                    feedbackData={goodsCheckAssignData?.goodscheckedtechnicianfeedback_set[0]}
                    data={data}
                />
            )}

            <ATForServicingDetailsComponent  data={data} />

            <SrIdWiseIssuedListCom
                apiEndPoint={`/inventory/api/sr-issued/?in_house_technician_sr_id=${id}`}
            />

    </div>
  )
}

export default ATForServicingDetails