import React from 'react'
import InputFiled from '../../../Components/Form/InputFiled'
import SelectInput from '../../../Components/Form/SelectInput'
import TextareaInput from '../../../Components/Form/TextareaInput'
import Checkbox from '../../../Components/Form/Checkbox';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import ItemFormHandleComponent from './ItemComponents/ItemFormHandleComponent';

function ItemAdd() {
    const {
      formData,
      setFormData,
      categoriesOption,
      setCategoriesOption,
      selectedCategory,
      setSelectedCategory,
      subCategoriesOption,
      setSubCategoriesOption,
      selectedSubCategory,
      setSelectedSubCategory,
      uomOption,
      setUomOption,
      selectedUOM,
      setSelectedUOM,
      handleInputChange,
      handleCategoriesChange,
      handleSubCategoryChange,
      handUOMChange,
      handleSubmit,
    } = ItemFormHandleComponent()

    const filteredSubCategories = subCategoriesOption?.filter((subCategory) => subCategory?.category === selectedCategory?.value);

  return (
    <div>
      <FormPageTitle title='Item Add Form' FormPageRightSideButtonURL='' LinkName='' />
      <div className='p-3 mx-2 shadow-lg'>
          <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 lg:grid-cols-3">

              <div className='my-4'>
                  <InputFiled type='text' 
                    className={`w-full`}
                    onChange={handleInputChange} 
                    value={formData.item_name} 
                    name='item_name' id='item_name'  label='Item Name*' 
                    isRequired={true} 
                    placeholder='Enter item name'  
                    />
              </div>
              <div className='my-4'>
                  <InputFiled type='text' 
                    className={`w-full`}
                    onChange={handleInputChange} 
                    value={formData.item_code} 
                    name='item_code' id='item_code'  label='Item Code*' 
                    isRequired={true} 
                    placeholder='Enter item code'  
                    />
              </div>

              <div className='my-4'>
                    <InputFiled 
                    type='number' 
                    value={formData.item_sales_price} 
                    onChange={handleInputChange} 
                    className={`w-full`}
                    name='item_sales_price' id='item_sales_price'  label='Sales Price*' 
                    isRequired={true} 
                    placeholder='Enter item sales price'  
                    />
                </div>

                <div className='my-4'>
                    <InputFiled 
                    type='number' 
                    value={formData.item_sales_price_for_ho} 
                    onChange={handleInputChange} 
                    className={`w-full`}
                    name='item_sales_price_for_ho' id='item_sales_price_for_ho'  label='Sales Price (HO)*' 
                    isRequired={true} 
                    placeholder='item sales price for ho'  
                    />
                </div>

              <div className="my-4">
                    <SelectInput
                        id="category"
                        className={`w-full`}
                        name="category"
                        label='Category*'
                        placeholder='Select Category'
                        value={selectedCategory}
                        onChange={handleCategoriesChange}
                        options={categoriesOption}
                        isRequired={true}
                    />
                </div>

                <div className="my-4">
                    <SelectInput
                        className={`w-full`}
                        id="sub_category"
                        name="sub_category"
                        label='Sub-Category*'
                        value={selectedSubCategory}
                        placeholder='Select Sub Category'
                        onChange={handleSubCategoryChange}
                        options={filteredSubCategories}
                        isRequired={true}
                    />
                </div>

                <div className='my-4'>
                    <InputFiled 
                    type='text' 
                    value={formData.brand} 
                    onChange={handleInputChange} 
                    className={`w-full`}
                    name='brand' id='brand'  label='Brand*' 
                    isRequired={true} 
                    placeholder='Enter Unit of Measurement'  
                    />
                </div>
                <div className="my-4">
                    <SelectInput
                        id="uom"
                        name="uom"
                        className={`w-full`}
                        label='UOM*'
                        value={selectedUOM}
                        placeholder='Select uom'
                        onChange={handUOMChange}
                        options={uomOption}
                    />
                </div>
                
                <div className='my-4'>
                    <InputFiled 
                    type='text' 
                    name='model' 
                    id='model'  
                    label='Model*' 
                    className={`w-full`}
                    onChange={handleInputChange} 
                    value={formData.model}
                    isRequired={true} 
                    placeholder='Enter Model'  
                    />
              </div>
              <div className='my-4'>
                    <InputFiled 
                    type='text' 
                    value={formData.origin} 
                    className={`w-full`}
                    onChange={handleInputChange} 
                    name='origin' id='origin'  label='Origin*' 
                    isRequired={true} 
                    placeholder='Enter Origin'  
                    />
              </div>
              <div className='my-4'>
                    <InputFiled 
                    type='text' 
                    value={formData.size} 
                    className={`w-full`}
                    onChange={handleInputChange} 
                    name='size' id='size'  label='Size*' 
                    isRequired={true} 
                    placeholder='Enter Size'  
                    />
              </div>

              <div className='my-4'>
                    <InputFiled type='text' 
                    value={formData.parts_no} 
                    className={`w-full`}
                    onChange={handleInputChange} 
                    name='parts_no' id='parts_no'  label='Parts No*' 
                    isRequired={true} 
                    placeholder='Enter Parts No'  
                    />
              </div>
              
              <div className="my-4">
                <TextareaInput 
                    label='Specification' 
                    name='specification' 
                    id='specification'
                    className='h-[41.535px] w-full' 
                    placeholder={`Enter Specification`} 
                    value={formData.specification}
                    onChange={handleInputChange}
                />
              </div>
              <div className='my-4 md:mx-10'>
                  <Checkbox type='checkbox'  checked={formData.status} onChange={handleInputChange} name='status' id='status' label='Status' isRequired={false} placeholder='status'
                  />
              </div>

                <div className=" my-4">
                  <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2'>Submit</button>
                </div>
              </div>

            </form>
      </div>
    </div>
  )
}

export default ItemAdd