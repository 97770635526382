import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'
import TotalPayableAmountInWord from './TotalPayableAmountInWord'
import BillingInvoiceApprovedStatusChange from '../BillingInvoiceApprovedStatusChange'

const HOInvoiceBasicInfoComponent = ({detailsData,id}) => {
  return (
    <div>
            {/* 1st content */}
            <div className='bg-[#FDECEC] my-1 flex justify-between'>
                <div className='py-2 text-[20px] font-semibold text-[#E74A3B] pl-10'>INVOICE DETAILS</div>
            
                {detailsData?.invoice_approved_satatus === false &&(
                <div className='py-2'>
                    <BillingInvoiceApprovedStatusChange id={id} ActionURL={`billing/api/ho-invoice-approved-status-change`} redirectURL={'pending-ho-invoice-list'}  />
                </div>
                )}
            </div>

            {/* 2nd */}

            <div className='border border-[#EB5757] mx-1 my-2'>

                <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                    <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                        <div className='bg-[#EB5757] '>

                            <div className='flex justify-center items-center 8 sm:py-14  md:py-14 lg:py-10'>
                                <div className='text-[#FFFFFF] text-[16px] font-semibold '>
                                    <div className='text-center uppercase'>Bill Invoice No</div>
                                    <div className='text-center'>{detailsData?.bill_invoice}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                            <div className='mx-1 sm:mx-0'>
                            <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Ticket</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.ticket_no}</div>
                                        </div>
                                    </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Customer Name</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.customer_name}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Customer Phone</div>
                                        <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{detailsData?.customer_phone}</div>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd */}

                            <div className='mx-1 sm:mx-0'>
                                <div>

                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Invoice Created At</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Product Model</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{detailsData?.ib_product_model? detailsData?.ib_product_model : detailsData?.sb_product_model? detailsData?.sb_product_model : ''}</div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className='overflow-x-auto overflow-y-auto p-2'>
        
        <div className='py-1 text-[20px] font-semibold text-[#000000]'>Billing Information</div>
        
        <table className='table-auto border-2 border-[#ED6C6C] border-collapse min-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Title</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Amount</th>

                </tr>

            </thead>



            <tbody className='text-center'>

                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Charge</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.basic_charge} TK</td>
                </tr>
                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Charge Quantity</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.basic_charge_quantity} Pis</td>
                </tr>
                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Additional Charge</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.additional_charge} TK</td>
                </tr>

                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Total Amount</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.total_payable_amount} TK</td>
                </tr>

                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>AMOUNT IN WORD</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'> <TotalPayableAmountInWord total_payable_amount={detailsData?.total_payable_amount} /></td>
                </tr>

                


            </tbody>

        </table>
    </div>
    </div>
  )
}

export default HOInvoiceBasicInfoComponent