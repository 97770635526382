import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import SalesOrderDetailsComponent from '../../../Components/SalesOrderDetailsComponent';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';

const SalesDetails = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    
    const [invoiceDetailsData, setInvoiceDetailsData] = useState(null);
    const {invoice_no} = useParams()

    useEffect(() => {
      const fetchInvoiceDetailsData = async () => {
        try {
          const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details-with-invoice/${invoice_no}/`);
          setInvoiceDetailsData(response.data);
        } catch (error) {
          console.log(error);
          if (invoice_no && error.response && error.response.status === 404) {
            ShowToast('error', 'Data not found');
          }
          }
      };
        fetchInvoiceDetailsData();

    }, [invoice_no]);


  return (
    <div>
        <SalesOrderDetailsComponent salesData={invoiceDetailsData} />
    </div>
  )
}

export default SalesDetails