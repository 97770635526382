import React, { useState, useEffect } from 'react';

function WoTotalPriceWAdChargeWinW({ workorderData }) {
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalPriceInWords, setTotalPriceInWords] = useState(null);

  // Function to convert a number into words
  const convertToWords = (num) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convertThreeDigits = (num) => {
      let result = '';
      const hundreds = Math.floor(num / 100);
      const remainder = num % 100;

      if (hundreds > 0) {
        result += units[hundreds] + ' Hundred';
      }

      if (remainder > 0) {
        if (result !== '') {
          result += ' and ';
        }

        if (remainder < 10) {
          result += units[remainder];
        } else if (remainder < 20) {
          result += teens[remainder - 11];
        } else {
          const tensDigit = Math.floor(remainder / 10);
          const unitsDigit = remainder % 10;
          result += tens[tensDigit];
          if (unitsDigit > 0) {
            result += ' ' + units[unitsDigit];
          }
        }
      }

      return result;
    };

    const convertToWordsThousands = (num) => {
      const thousands = Math.floor(num / 1000);
      const remainder = num % 1000;
      let result = '';

      if (thousands > 0) {
        result += convertThreeDigits(thousands) + ' Thousand';
      }

      if (remainder > 0) {
        if (result !== '') {
          result += ' ';
        }

        result += convertThreeDigits(remainder);
      }

      return result;
    };

    const integerPart = Math.floor(num);
    const decimalPart = Math.round((num - integerPart) * 100); // Multiplying by 100 to get paisa

    let result = convertToWordsThousands(integerPart);

    if (result !== '') {
      result += ' Taka';
    }

    if (decimalPart > 0) {
      result += ' and ' + convertThreeDigits(decimalPart) + ' Paisa';
    } else {
      result += '';
    }

    return result !== '' ? result : 'Zero Taka';
  };

  useEffect(() => {
    // Calculate total price when workorderData changes
    const calculateTotalPrice = () => {
      if (workorderData && workorderData.workorderitem_set && workorderData.workorderitem_set.length > 0) {
        const total = workorderData.workorderitem_set.reduce((acc, item) => {
          const quantity = parseFloat(item?.wo_item_qty) || 0;
          const price = parseFloat(item?.price) || 0;
          const discount = parseFloat(item?.discount_price) || 0;
          const discountType = item?.discount_type;

          let itemTotal = quantity * price;

          // Apply discount based on discount_type
          if (discountType === 'percentage') {
            itemTotal -= (itemTotal * (discount / 100));
          } else if (discountType === 'fixed') {
            itemTotal -= discount;
          }

          return acc + itemTotal;
        }, 0);

        const additionalDiscount = workorderData.additional_discount ? parseFloat(workorderData.additional_discount) : 0;
        const transportCharge = workorderData.transport_charge ? parseFloat(workorderData.transport_charge) : 0;
        const otherCharge = workorderData.other_charge ? parseFloat(workorderData.other_charge) : 0;

        // const finalTotal = (total + transportCharge + otherCharge) - additionalDiscount;
        const finalTotal = total;

        // Convert total price to words
        setTotalPriceInWords(convertToWords(finalTotal));

        // Update the state with the calculated total price
        setTotalPrice(finalTotal.toFixed(2));
      } else {
        // If workorderData or workorderitem_set is empty or null, set total price and total price in words to null
        setTotalPrice(null);
        setTotalPriceInWords(null);
      }
    };

    calculateTotalPrice();
  }, [workorderData]);

  return (
      <>{totalPriceInWords !== null ? totalPriceInWords : 'N/A'}</>
  );
}

export default WoTotalPriceWAdChargeWinW;
