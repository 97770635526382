import React, { useEffect, useState } from 'react'
import FormPageTitle from '../../Components/Form/FormPageTitle'
import AddEditFormInputFiled from './ShowroomTicketRequestComponent/AddEditFormInputFiled'
import AddEditFormInputHandle from './ShowroomTicketRequestComponent/AddEditFormInputHandle';
import { useParams } from 'react-router-dom';
import ShowToast from '../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../baseURL';
import LoadingSpinner from '../../Components/LoadingSpinner';

const TicketRequestUpdate = () => {
  const {id} = useParams()
  const [loading,setLoading]= useState(true)

  const {
    formData,
    setFormData,
    serviceCategoryOption,
    handleServiceCategoryChange,
    handleInputChange,
    navigate,
  } = AddEditFormInputHandle({id});


  useEffect(()=>{
    const fetchDetailsData = async ()=>{
      try {
        const response = await authAxiosWithBearer(`${baseURL}/ccd/api/showroom-request-ticket-details-for-showroom/${id}/`)
        setFormData(response.data)
      } catch (error) {
        if(error.response?.data?.errors){
          ShowToast('error', `${error.response?.data?.errors}`);
        }else if(error.response?.data?.message){
          ShowToast('error', `${error.response?.data?.message}`);
        }else{
          ShowToast('error', `${error?.message}`);
        }
      }
      finally{
        setLoading(false)
      }
    };
    fetchDetailsData();
  },[id])


  const handleUpdateFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await authAxiosWithBearer.put(`${baseURL}/ccd/api/showroom-request-ticket-update/${id}/`, formData);
      console.log(response.data)
      if(response.data){
        navigate('/pending-requested-ticket-list-for-showroom')
        ShowToast('success', 'Successfully Updated')       
      }else{
        ShowToast('error', 'Something is Wrong Try Again')
      }
    } catch (error) {
      if(error.response?.data?.errors){
        ShowToast('error', `${error.response?.data?.errors}`);
      }else if(error.response?.data?.message){
        ShowToast('error', `${error.response?.data?.message}`);
      }else{
        ShowToast('error', `${error?.message}`);
      }
    }
  };


  if (loading) {
    return <div><LoadingSpinner /></div>;
  }

  return (
    <div className='container grid grid-cols-1 mx-auto bg-[#fff]'>
        <FormPageTitle title='Ticket Request Update Form' FormPageRightSideButtonURL='/pending-requested-ticket-list-for-showroom' LinkName='Pending List' />

        <form onSubmit={handleUpdateFormSubmit} className='shadow pt-1 px-5 md:p-4'>
          <AddEditFormInputFiled 
            formData = {formData}
            setFormData = {setFormData}
            handleInputChange = {handleInputChange}
            loading = {loading}
            serviceCategoryOption ={serviceCategoryOption}
            handleServiceCategoryChange ={handleServiceCategoryChange}
          />
        </form>

    </div>
  )
}

export default TicketRequestUpdate