import React from 'react'
import InHouseTechnicianSrIssueFormHandle from './InHouseTechnicianSrIssueFormHandle';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC';
import { AiFillDelete } from 'react-icons/ai';
import CustomSubmitButton from '../../../../Components/Form/CustomSubmitButton';

const InHouseTechnicianSrIssueForm = ({id}) => {
    const {
        requisionIssueFormData,
        requisionItemOptions,
        selectedRequisionItem,
        selectedRequisionStock,
        requisionStockOptions,
        requisionIssueRequiredMessage,
        handleRequisionItemChange,
        handleRequisionIssueInputChange,
        handleAddRequisionIssueItem,
        handleRemoveRequisionIssueItem,
        handleRequisionIssueSubmit,
        isLoading,
      }=InHouseTechnicianSrIssueFormHandle({id})

  return (
    <div>
        {requisionItemOptions.length > 0 && ( 
        <div className="req-issue-form shadow-xl pb-5  mx-2 my-10 bg-[#fff] border border-[#EB5757] overflow-x-auto">
            <div className='information-head  px-10 mb-5 py-2'>
                <div>SR Issue Form</div>
            </div>
            <form onSubmit={handleRequisionIssueSubmit}>
                {requisionIssueFormData.srissueditem_set?.map((requisionItems, index) => (
                <div className='add-more-item-form border border-[#F35B5B]'>
                <div className="item-add-form-head border-b bg-[#dbdbdb] flex justify-between py-2">
                <div className='mx-5 my-auto'>
                    <h1 className=''>Item{index + 1}</h1>
                </div>
                <div className='my-auto mx-5'>
                    {requisionIssueFormData?.srissueditem_set?.length > 1 && (
                        <button
                        type="button"
                        onClick={() => handleRemoveRequisionIssueItem(index)}
                        className="text-red-500"
                        >
                        <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600  border border-[#F35B5B]" />
                        </button>
                    )}
                </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3  mx-4 mt-2 gap-x-4 gap-y-2 border-b">              
                <div className="my-4">
                    <SelectInputWC
                        className={`appearance-none border-[1px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        label='Item*'
                        id={`in_house_technician_sr_item_${index}`}
                        name={`srissueditem_set[${index}]?.in_house_technician_sr_item`}
                        value={selectedRequisionItem[index]}
                        onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                        options={requisionItemOptions.filter(
                            (option) =>
                                !selectedRequisionItem.some(
                                    (selectedItem) => selectedItem && selectedItem.value === option.value
                                )
                        )}
                        placeholder='Select item'
                        isRequired={true}
                        isClearable={true}
                    />
                </div>
                
                <div className='my-4'>
                    <TextareaInputWC
                        label='UOM' 
                        name='uom' 
                        id='uom'
                        placeholder={`UOM`} 
                        value={requisionItems.uom}
                        readOnly={true}
                        // onChange={(e) => handleRequisionIssueInputChange(e, index, 'srissueditem_set')}
                        className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]' 
                    />
                </div>


                <div className='my-4'>
                    <InputFiledWC
                        type='number'
                        name='stock_available_qty'
                        label='C.Stock'
                        value={requisionItems?.stock_available_qty}
                        readOnly={true}
                        placeholder='Current Stock qty'
                        className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>

                <div className='my-4'>
                    <InputFiledWC type='number' 
                    name='issue_qty' label='Issue Q*' 
                    id={`issue_qty_${index}`}
                    value={requisionItems.issue_qty}
                    onChange={(e) => handleRequisionIssueInputChange(e, index, 'srissueditem_set')}
                    isRequired={true}
                    max={requisionItems?.max_issue_qty}
                    min='0'
                    placeholder='Enter issue qty'
                    className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                    {requisionItems?.max_issue_qty && ( 
                    <p className='text-[11px] text-[#F35B5B]'> available qty : {requisionItems?.max_issue_qty}</p>
                    )}
                </div>
                <div className='my-4'>
                    <TextareaInputWC
                        label='Remarks' 
                        name='remarks' 
                        id='remarks'
                        placeholder={`Enter remarks`} 
                        value={requisionItems.remarks}
                        onChange={(e) => handleRequisionIssueInputChange(e, index, 'srissueditem_set')}
                        className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[207%] focus:outline-none focus:bg-white focus:border-[#F35B5B]' 
                    />
                </div>
                </div>
                </div>
                ))}

                {requisionIssueRequiredMessage && (
                    <span className='text-red-500 mx-4'>{requisionIssueRequiredMessage} <br /> </span> 
                )}
                <div className="flex justify-between mx-4 my-2">

                    <button 
                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                        type="button"
                        onClick={() => {
                            console.log('Add new item Button Clicked');
                            handleAddRequisionIssueItem();
                        }}
                        >ADD MORE</button>
                        
                        <CustomSubmitButton isLoading={isLoading} />
                </div>
            </form>
        </div>
        )} 

    </div>
  )
}

export default InHouseTechnicianSrIssueForm