import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';

const TransportAssignForDeliveryFromHangleComponent = ({setModalOpen,assigned_technicianid,transport_user_id,fetchData,fetchTicketData,goodsCheckTechnicianFeedback_id}) => {
    const [formData,setFormData] = useState({
      goodsCheckTechnicianFeedback_id:null,
      assigned_technician : null,
      delivery_transport_user : null,
      vehicle : null,
      priority: '',
      remarks: '',
    })
    const navigate = useNavigate();
    const [vehicleOptions, setVehicleOptions] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [vehicleSearchInputValue,setVehicleSearchInputValue] = useState('')

    const priorityOption = [
      { value: 'High', label: 'High' },
      { value: 'Normal', label: 'Normal' },
      { value: 'Low', label: 'Low' },
    ];

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
    };

  useEffect(() => {
      authAxiosWithBearer.get(`${baseURL}/api/vehicle/?search=${vehicleSearchInputValue}`)
          .then(response => {
              setVehicleOptions(response.data.results.map(vehicle => ({
                  value: vehicle.id,
                  label: `${vehicle.vehicle_name} | ${vehicle.vehicle_number} | ${vehicle.vehicle_type}`
              })));

              console.log(response.data.results)
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
  }, [vehicleSearchInputValue]);

  const handleVehicleSearchInputChange = (value) => {
    setVehicleSearchInputValue(value);
  };

  const handleVehicleChange = (selectedOption) => {
    const v_id = selectedOption ? selectedOption.value : ''; 
    setSelectedVehicle(selectedOption);
    setFormData({
      ...formData,
      vehicle: v_id,
    });
  };

  const handlePriorityOptionChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
      setFormData(prevState => ({ ...prevState, priority: selectedOption.value }));
      console.log('selectedOption',selectedOption)
    } else {
      setFormData(prevState => ({ ...prevState, priority: null }));
    }
  };


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {

        console.log('goodsCheckTechnicianFeedback_id hd',goodsCheckTechnicianFeedback_id)
        const UpdateFormData = {
          ...formData,
            assigned_technician:assigned_technicianid,
            delivery_transport_user : transport_user_id,
            goodsCheckTechnicianFeedback_id:goodsCheckTechnicianFeedback_id,
        }
        const response = await authAxiosWithBearer.post(`${baseURL}/in_house/api/transport-assign-for-delivery/`, UpdateFormData);
  
        if (response) {
          console.log(response);
          fetchData()
          fetchTicketData()
          ShowToast('success', 'Successfully Submit');
          setModalOpen(false)
          
        }
      } catch (error) {
        console.log(error);
        if(error.response.data.error){
          ShowToast('error', `${error.response.data.error}`);
        }else{
          ShowToast('error', 'Something is wrong');
        }
      }
    };

  return (
    {
        formData,
        setFormData,
        vehicleOptions,
        selectedVehicle,
        priorityOption,
        handleInputChange,
        handleVehicleSearchInputChange,
        handleVehicleChange,
        handlePriorityOptionChange,
        handleSubmit,
    }
  )
}

export default TransportAssignForDeliveryFromHangleComponent