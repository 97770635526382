import React from 'react'
import DateInput from '../../../../Components/Form/DateInput'
import TransportAssignFromHangleComponent from './TransportAssignFromHangleComponent'
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC'
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC'

const TransportAssignFormComponent = ({setModalOpen,assigned_technicianid,transport_user_id,fetchData,fetchTicketData}) => {
    const {
        formData,
        setFormData,
        vehicleOptions,
        selectedVehicle,
        priorityOption,
        handleInputChange,
        handleVehicleSearchInputChange,
        handleVehicleChange,
        handlePriorityOptionChange,
        handleSubmit,
    } = TransportAssignFromHangleComponent({setModalOpen,assigned_technicianid,transport_user_id,fetchData,fetchTicketData})

  return (
      <div className='mt-[50px]'>
          <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-1 gap-2 ">
                  <div className='my-4'>
                      <SelectInputWC
                          name='vehicle'
                          id='vehicle'
                          label='Vehicle'
                          className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                          value={selectedVehicle}
                          onChange={handleVehicleChange}
                          onInputChange={handleVehicleSearchInputChange}
                          options={vehicleOptions}
                          isRequired={false}
                      />
                  </div>
                  <div className='my-4'>
                      <SelectInputWC
                          name='priority'
                          id='priority'
                          label='Priority'
                          className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                          value={priorityOption.find((option) => option?.value === formData.priority)}
                          onChange={handlePriorityOptionChange}
                          options={priorityOption}
                          isRequired={false}
                      />
                  </div>

                  <div className='my-4'>
                      <TextareaInputWC type='text'
                          className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                          name='remarks' id='remarks'
                          value={formData.remarks}
                          onChange={handleInputChange}
                          label='Remarks' isRequired={false}
                          placeholder='Enter Remarks here' />
                  </div>
              </div>

              <div className=" mt-4 bottom-0 flex justify-center">
                  <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2 w-[330px]'>Assign</button>
              </div>
          </form>
      </div>
  )
}

export default TransportAssignFormComponent