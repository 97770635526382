import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'
import TotalBill from './TotalBill'
import TotalBillWinW from './TotalBillWinW'
import Logo from '../../../../assest/Images/logo.png';
import ServiceCatWiseInvoiceList from './ServiceCatWiseInvoiceList';
import InstallationCatWiseInvoiceList from './InstallationCatWiseInvoiceList';

const HOBillingDetailsPrintViewComponent = ({ detailsData, componentPDF,serviceData,installationData }) => {

    return (
        <div className="hidden">
            <div
                ref={componentPDF}
                style={{ width: "99%", height: "100%" }}
                className="flex flex-col mx-auto "
            >
                <div className='border border-[#EB5757] sm:mx-3 my-3' >
                    <div className="flex justify-between mx-1">
                        <div className="logo-container">
                            {/* Main Logo */}
                            <img className='logo h-[140px]' src={Logo} alt="Esquire Company Logo" />
                        </div>
                        <div>
                            <div className='text-[20px] font-semibold'>Esquire Customer Care Ltd</div>
                            <div className='address'>
                                <p>260/A, Tejgaon I/A Nabisco,</p>
                                <p>Tejgaon, Dhaka-1208</p>
                                <p>Tel: 09610001010</p>
                                <p>Email: <a href="mailto:info_ccd@esquirebd.com">info_ccd@esquirebd.com</a></p>
                            </div>
                        </div>
                    </div>

                    {/* 1st content */}
                    <div className='bg-[#FDECEC] my-2 mx-2'>
                        <div className='py-2 text-[#E74A3B] text-[25px] font-semibold pl-1 text-center'>HO Bill DETAILS</div>
                    </div>

                    {/* 2nd */}

                    <div className='border border-[#EB5757] rounded-[10px] mx-2 my-2 p-1'>
                        <div className='font-mono'>
                            <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 mx-1'>
                                <div className='mx-1 sm:mx-0'>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>From Date</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.from_date}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>To Date</div>
                                            <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{detailsData?.to_date}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* 2nd */}

                                <div className='mx-1 sm:mx-0'>
                                    <div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Created At</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at ? formatDateTime(detailsData?.created_at) : ''}</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Created By</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_by_text}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                    {/* 3rd */}

                    {/* <div className='flex sm:flex-row flex-col'>
                        <div className='mx-1 my-2'>

                            <div className='overflow-x-auto overflow-y-auto'>

                                <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

                                    <thead>

                                        <tr className='bg-[#EEEEEE]'>

                                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>
                                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Invoice No</th>

                                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Charge</th>

                                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Charge Quantity </th>

                                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> Additional Charge (BDT)  </th>

                                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> TOTAL (BDT) </th>

                                        </tr>

                                    </thead>



                                    <tbody className='text-center'>

                                        {detailsData?.billing_invoice?.map((bill, index) => (
                                            <tr >
                                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{index + 1}</td>
                                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.bill_invoice}</td>
                                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.basic_charge}</td>
                                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.basic_charge_quantity}</td>
                                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.additional_charge}</td>
                                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.total_payable_amount}</td>

                                            </tr>
                                        ))}


                                        <tr>
                                            <td colSpan="5" className='border-2 border-[#ED6C6C] w-[400px] py-3'>TOTAL AMOUNT</td>
                                            <td colSpan="1" className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                                                <TotalBill detailsData={detailsData} />
                                            </td>
                                        </tr>
                                       
                                        <tr>
                                            <td colSpan="6" className='border-2 border-[#ED6C6C] w-[400px] py-3 '>AMOUNT IN WORD -
                                                <TotalBillWinW detailsData={detailsData} />
                                            </td>
                                        </tr>

                                    </tbody>

                                </table>

                            </div>


                        </div>


                    </div> */}

                    <div className="information-section my-5 p-1 mx-2">
                        <div className='information-head px-1 py-1'>
                            <div className='capitalize'>SCBCC Invoice</div>
                        </div>
                        <ServiceCatWiseInvoiceList serviceData={serviceData} />
                    </div>

                    <div className="information-section my-5 p-1 mx-2">
                        <div className='information-head my-1 px-1 py-1'>
                            <div className='capitalize'>IJCR Invoice</div>
                        </div>
                        <InstallationCatWiseInvoiceList installationData={installationData} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HOBillingDetailsPrintViewComponent