
import React from 'react'
import { useParams } from 'react-router-dom';
import useFetchDetailsData from '../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../Components/LoadingSpinner';
import ShowroomReqDetailsComponent from './ShowroomTicketRequestComponent/ShowroomReqDetailsComponent';


const ShowroomRequestDetails = () => {
    const {id} = useParams()
    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('ccd/api/showroom-requested-details-for-ccd', {id});
  
    if(loading){
        return <div><LoadingSpinner /> </div>
    }

    return (
        <ShowroomReqDetailsComponent detailsData={detailsData} />
    )
}

export default ShowroomRequestDetails