import React from 'react'
import FormPageTitle from '../../Components/Form/FormPageTitle'
import AddEditFormInputFiled from './ShowroomTicketRequestComponent/AddEditFormInputFiled'
import AddEditFormInputHandle from './ShowroomTicketRequestComponent/AddEditFormInputHandle';

const NewTicketRequestAdd = () => {
  const {
    formData,
    setFormData,
    serviceCategoryOption,
    handleServiceCategoryChange,
    handleInputChange,
    loading,
    setLoading,
    handleFormSubmit,
  } = AddEditFormInputHandle();

  return (
    <div className='container grid grid-cols-1 mx-auto bg-[#fff]'>
        <FormPageTitle title='Ticket Request Form' FormPageRightSideButtonURL='/pending-requested-ticket-list-for-showroom' LinkName='Pending List' />

        <form onSubmit={handleFormSubmit} className='shadow pt-1 px-5 md:p-4'>
          <AddEditFormInputFiled 
            formData = {formData}
            setFormData = {setFormData}
            handleInputChange = {handleInputChange}
            loading = {loading}
            serviceCategoryOption ={serviceCategoryOption}
            handleServiceCategoryChange ={handleServiceCategoryChange}
          />
        </form>

    </div>
  )
}

export default NewTicketRequestAdd