import React, { useState } from 'react'
import TransportAssignForDeliveryModal from './TransportAssignForDeliveryModal';


const TransportAssignForDeliveryModalButton = ({assigned_technicianid,transport_user_id,fetchData,fetchTicketData,goodsCheckTechnicianFeedback_id}) => {
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };


  return (
    <div>
        <div className='flex'>
              <button
                  onClick={() => openModal()}
                  className='bg-[#FA6669] rounded-[4px] px-[16px] py-[5px] text-[#fff]'
                  >
                    Assign
              </button>
        </div>

          <TransportAssignForDeliveryModal
                isOpen={isModalOpen}
                onClose={closeModal}
                setModalOpen={setModalOpen}
                assigned_technicianid ={assigned_technicianid}
                transport_user_id = {transport_user_id}
                fetchData={fetchData}
                fetchTicketData={fetchTicketData}
                goodsCheckTechnicianFeedback_id={goodsCheckTechnicianFeedback_id}
            />
    </div>
  )
}

export default TransportAssignForDeliveryModalButton