import React, { useEffect, useState } from 'react'
import TextCopyButton from '../../../../Components/TextCopyButton'
import SectionTitle from './SectionTitle';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import Modal from 'react-modal';
import { ImCancelCircle } from 'react-icons/im'
import TicketViewWithPrint from '../../../../Components/TicketViewWithPrint'
import SalesOrderDetailsComponent from '../../../../Components/SalesOrderDetailsComponent'
import { formatDate } from '../../../../Components/formatDate';

const CustomerPrevHistory = ({phoneSearchResultCustomerId,phoneSearchResult}) => {

        const [customerPreviousServiceData,setCustomerPreviousServiceData] =useState('')
        const [customerPreviousPurchasedData,setCustomerPreviousPurchasedData] =useState('')
        const [ticketData, setTicketData] = useState(null);
        const [purchasedDetailsData, setPurchasedDetailsData] = useState(null);
        const [isPurchasedModalOpen, setPurchasedModalOpen] = useState(false);
        const [selectedPurchasedID, setSelectedPurchasedID] = useState(null);

          // fetchPreviousServiceHistoryData
          useEffect(() => {
            const fetchPreviousServiceHistoryData = async () => {
                try {
                const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-customer-wise-ticket-history/${phoneSearchResultCustomerId}/`);
                setCustomerPreviousServiceData(response.data)
                console.log('fetchPreviousServiceHistoryData',response.data)
    
                } catch (error) {   
                  console.log(error)
                }
            };
            fetchPreviousServiceHistoryData();
          }, [phoneSearchResultCustomerId]);
    
    
          const [isServiceModalOpen, setServiceModalOpen] = useState(false);
          const [selectedTicketNo, setSelectedTicketNo] = useState(null);
        
          // Function to open the modal and set the selected ticket_no
          const openServiceModal = (ticketNo) => {
            setServiceModalOpen(true);
            setSelectedTicketNo(ticketNo);
          };
        
          // Function to close the modal
          const closeServiceModal = () => {
            setServiceModalOpen(false);
            setSelectedTicketNo(null);
          };
    
          // fetchTicketData
          useEffect(() => {
            const fetchTicketData = async () => {
              try {
                const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-single-ticket/${selectedTicketNo}/`);
                console.log(response.data);
                setTicketData(response.data);
              } catch (error) {
                console.log(error);
              }
            };
            fetchTicketData();
          }, [selectedTicketNo]);
    
          
          // fetchPreviousPurchasedHistoryData
          useEffect(() => {
            const fetchPreviousPurchasedHistoryData = async () => {
                try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/customer-wise-sales-information-list/${phoneSearchResult}/`);
                setCustomerPreviousPurchasedData(response.data.results)
                // console.log('fetchPreviousPurchasedHistoryData',response.data.results)
    
                } catch (error) {   
                  console.log(error)
                }
            };
            fetchPreviousPurchasedHistoryData();
          }, [phoneSearchResult]);
    
    

        
          // Function to open the modal and set the selected ticket_no
          const openPurchasedModal = (purchaseID) => {
            setPurchasedModalOpen(true);
            setSelectedPurchasedID(purchaseID);
          };
        
          // Function to close the modal
          const closePurchasedModal = () => {
            setPurchasedModalOpen(false);
            setSelectedPurchasedID(null);
          };
    
    
        // fetchPreviousPurchasedDetails
        useEffect(() => {
        const fetchPreviousPurchasedDetails = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details/${selectedPurchasedID}/`);

            console.log(response.data);
            setPurchasedDetailsData(response.data);
            } catch (error) {
            console.log(error);
            }
        };
        fetchPreviousPurchasedDetails();
        }, [selectedPurchasedID]);
    
  return (
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
    <SectionTitle title='History' />
      <div className="customer-history mb-10 mt-3">
        <div className="flex flex-wrap">

          <div className="md:w-1/2">
              <h2>Purchase History</h2>
              <div className="history-table md:max-w-[477px] my-3">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th>Invoice No</th>
                      <th>Purchased Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerPreviousPurchasedData && customerPreviousPurchasedData?.map((data)=>(
                    <tr key={data.id}>
                      <td className='pl-[0px]'>
                        <span className='bg-[#FCEAEA] rounded-[4px] px-[10px] py-[5px]'>
                        {data?.invoice_no}
                        {data?.invoice_no && ( 
                              <TextCopyButton text={data?.invoice_no} />
                          )}
                        </span>
                      </td>
                      <td>{data?.invoice_date}</td>
                      <td className='text-end'>
                          <button
                          onClick={() => openPurchasedModal(data.id)}
                          className='bg-[#FA6669] rounded-[4px] px-[10px] py-[6px] text-[#fff]'
                          >
                          Details
                        </button>
                        </td>
                    </tr>
                    ))}

                  </tbody>
                </table>
              </div>
          </div>
          
          <div className="md:w-1/2">
              <h2>Service History</h2>
              <div className="history-table md:max-w-[477px] my-3">
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th>Ticket No</th>
                      <th>Services</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerPreviousServiceData && customerPreviousServiceData?.map((data, key)=>(
                      <tr key={data.id}>
                      <td className='pl-[0px]'><span className='bg-[#FCEAEA] rounded-[4px] px-[10px] py-[5px]'>{data.ticket_no}</span></td>
                      <td>{data.category}</td>
                      <td>{data.created_at?formatDate(data.created_at):''}</td>
                      <td className='text-end'>
                      <button
                          onClick={() => openServiceModal(data.id)}
                          className='bg-[#FA6669] rounded-[4px] px-[10px] py-[6px] text-[#fff]'
                        >
                          Details
                        </button>
                        </td>
                    </tr>
                    ))}

                  </tbody>
                </table>
              </div>
          </div>

        </div>
      </div>


      <Modal
      isOpen={isServiceModalOpen}
      onRequestClose={closeServiceModal}
      contentLabel="Ticket Modal"
      className="modal"
      overlayClassName="overlay" 

    >
    <div className='mb-2 w-[360px] md:w-[650px] lg:w-[1000px] overflow-y-auto h-[500px]'>
        <div className='flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px]   mb-10 shadow-xl'>
            <h1 className='font-semibold'>Ticket Details</h1>
            <button className='text-[#000]' onClick={closeServiceModal}><ImCancelCircle /></button>
        </div>
        

        <TicketViewWithPrint ticketData={ticketData} />

        
    </div>
    
      </Modal>

      
      <Modal
      isOpen={isPurchasedModalOpen}
      onRequestClose={closePurchasedModal}
      contentLabel="Purchased Details Modal"
      className="modal"
      overlayClassName="overlay" 

    >
    <div className='mb-2 w-[360px] md:w-[650px] lg:w-[1200px] overflow-y-auto h-[500px]'>
        <div className='flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] fixed top-0 left-0 right-0 z-50  mb-5 shadow-lg'>
            <h1 className='font-semibold'>Purchased Details </h1>
            <button className='text-[#000]' onClick={closePurchasedModal}><ImCancelCircle /></button>
        </div>
        
        <SalesOrderDetailsComponent salesData={purchasedDetailsData} />
    </div>
    
    </Modal>

    </div>
  )
}

export default CustomerPrevHistory