import React from 'react'
import TotalIHSIssueItemPrice from './TotalIHSIssueItemPrice'
import TotalIHSIssueItemPriceInWord from './TotalIHSIssueItemPriceInWord'

const TicketWiseIHSIssuedItemList = ({inHouseServicingIssuedItem}) => {
    let serialNumber = 0
  return (
    <div className='overflow-x-auto overflow-y-auto p-2'>
        
    <div className='py-1 text-[20px] font-semibold text-[#000000]'>SR Issued Items (In House Servicing)</div>
    
    <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

<thead>

    <tr className='bg-[#EEEEEE]'>

        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>

        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>

        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>

        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> PRICE (BDT)  </th>

        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> TOTAL (BDT) </th>

        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

    </tr>

</thead>



<tbody className='text-center'>
    {inHouseServicingIssuedItem?.map((item,index)=>(
        <tr >
            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{++serialNumber}</td>
            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                name : {item?.item_name} <br />
                size : {item?.item_size} <br />
                specification :{item?.item_specification} <br />
                brand : {item?.item_brand}
            </td>
            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.issue_qty} ({item?.item_uom})</td>
            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.item_sales_price}</td>
            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{parseFloat(item?.issue_qty * item?.item_sales_price).toFixed(2)}</td>
            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
        </tr>
    ))}



    <tr>
        <td colSpan="4" className='border-2 border-[#ED6C6C] w-[400px] py-3'>TOTAL AMOUNT</td>
        <td colSpan="2" className='border-2 border-[#ED6C6C] w-[400px] py-3'><TotalIHSIssueItemPrice srIssuedData={inHouseServicingIssuedItem} /></td>
    </tr>

    <tr>
        <td colSpan="8" className='border-2 border-[#ED6C6C] w-[400px] py-3 '>AMOUNT IN WORD - <TotalIHSIssueItemPriceInWord srIssuedData={inHouseServicingIssuedItem} />  </td>
    </tr>

</tbody>

</table>
</div>
  )
}

export default TicketWiseIHSIssuedItemList