import React, { useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import { authAxiosWithBearer, baseURL } from '../baseURL';
import PackageIssuedDetailsComponent from './PackageIssuedDetailsComponent';

function PackageIssuedDetailsModal({ isOpen, onClose, packageIssuedNo }) {
  const [packageIssuedDetailsData, setPackageIssuedDetailsData] = useState(null);

  
  useEffect(() => {
    const fetchInvoiceDetailsData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/user-wise-package-sr-issued/${packageIssuedNo}`);
        setPackageIssuedDetailsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (isOpen) {
      fetchInvoiceDetailsData();
    }
  }, [isOpen, packageIssuedNo]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Package Issued Details Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className='mb-2 w-[360px] md:w-[650px] lg:w-[1200px] overflow-y-auto h-[500px]'>
        <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
          <h1 className='font-semibold'>ISSUED DETAILS </h1>
          <button className='text-[#000]' onClick={onClose}><ImCancelCircle /></button>
        </div>
        
        <div className="mt-10">
            <PackageIssuedDetailsComponent detailsData={packageIssuedDetailsData} />
        </div>
      </div>
    </Modal>
  );
}

export default PackageIssuedDetailsModal;