import React from 'react';
import { formatDateTime } from '../../../../Components/formatDateTime';
import GRnItemComponentForDetails from './GRnItemComponentForDetails';

const GRnDetailsComponent = ({pdfGeneretor,grnData}) => {

    return (
        <>
            <div>
                <div className='border border-[#EB5757] sm:mx-3 my-3' >

                    {/* 1st content */}
                    <div className='bg-[#FDECEC] my-1'>
                        <div className='py-2 text-[#E74A3B] pl-10'>GRN DETAILS</div>
                    </div>

                    {/* 2nd */}

                    <div className='border border-[#EB5757] mx-1 my-2'>

                        <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                            <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                                <div className='bg-[#EB5757] '>

                                    <div className='flex justify-center items-center 8 sm:py-14  md:py-14 lg:py-10'>
                                        <div className='text-[#FFFFFF] text-[16px] '>
                                            <div className='text-center'>GRN NO</div>
                                            <div className='text-center'> => {grnData?.grn_no}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                                <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                                    <div className='mx-1 sm:mx-0'>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>GRn By</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{grnData?.grn_by_text}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Challan No</div>
                                                <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{grnData?.challan_no}</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Challan Date</div>
                                                <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{grnData?.challan_date}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 2nd */}

                                    <div className='mx-1 sm:mx-0'>
                                        <div>
                                            <div>
                                                <div className='flex'>
                                                    <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Date</div>
                                                    <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                    <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{grnData?.grn_at?formatDateTime(grnData?.grn_at):''}</div>
                                                </div>
                                            </div>



                                            <div>
                                                <div className='flex'>
                                                    <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Wo No </div>
                                                    <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                    <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>
                                                        {grnData?.grnitem_set?.length > 0 && (
                                                            <>
                                                                {grnData.grnitem_set.map((woNO, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {index > 0 && ','} {woNO?.wo_no}
                                                                    </React.Fragment>
                                                                ))}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='flex '>
                                                    <div className='w-[35%] sm:w-[30%] md:w-[50%] lg:w-[35%]'>Store</div>
                                                    <div className='w-[8%] sm:w-[5%] md:w-[8%] lg:w-[16%]'>-</div>
                                                    <div className='w-[57%] sm:w-[65%] md:w-[42%] lg:w-[49%]'>{grnData?.store_text}</div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>


                    {/* 3rd */}

                    <div className='flex sm:flex-row flex-col'>
                        <div className='mx-1 my-2'>

                            <GRnItemComponentForDetails grnData={grnData} />

                        </div>
                    </div>

                    {/* 4th  */}

                    {/* <div className='flex justify-center my-3 '>
                        <button className='bg-[#FA6669] text-[#FFF] px-6 py-2 rounded-md' onClick={pdfGeneretor}>Print</button>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default GRnDetailsComponent;