import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import '../../../../assest/CSS/AddFormPage.css'

function AssignedTechnicianPageTitle({title}) {
  return (
    <div className="flex justify-between page-header mb-5">
    <div className="page-title my-auto ml-5">
      <h1 className='uppercase'>{title}</h1>
    </div>
  </div>
  )
}

export default AssignedTechnicianPageTitle