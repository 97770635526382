import React, { useState } from 'react'
import SRItemComponent from './InventoryComponent/SRItemComponent';
import SrToPrFormCom from './InventoryComponent/SrToPrComponents/SrToPrFormCom';
import { formatDateTime } from '../../Components/formatDateTime';
import SRIssueFormCom from './InventoryComponent/SRIssueComponent/SRIssueFormCom';
import useFetchDetailsData from '../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SrIdWiseIssuedListCom from './InventoryComponent/SrIdWiseIssuedListCom';

function SrDetails() {

    const {id} = useParams()
    const user = useSelector(state => state.auth.user)

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/inv-requisition', {id});
  

    // #sr to pr 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [srID, setSrID] = useState('');

    const openSrToPrModal = (id) => {
        setSrID(id);
        setIsModalOpen(true);
      };
    
    if(loading){
        return <div><LoadingSpinner /> </div>
    }  
    

  return (
    <div>
        <div className="req-detail shadow-xl pb-5 mx-4 bg-[#fff] border border-[#EB5757]">
            <div className='information-head flex justify-between  px-5 mb-5 py-2'>
                <div className='uppercase text-[20px]'>Requisition Details</div>
                <div>
                    {user?.menu_permissions?.includes("3.9.1") && detailsData.issue_status !== 'Issued' && (
                    <button
                        onClick={() => openSrToPrModal(detailsData.id)}
                        className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                        >
                        SR TO PR
                    </button>
                    )}
                </div>
            </div>


            <h1 className='border-b pb-2 mx-4 mb-3'>Requisition Basic Information</h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-3  mx-4 my-2 gap-x-16 gap-y-2"> 

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Req Code
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.req_code}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Ticket
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.ticket_text}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Status
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] flex justify-end'>
                        {detailsData?.issue_status === 'Issued' ? <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div> 
                        : detailsData?.issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div> 
                        : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>}
                        <p></p>
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        SR From
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.sr_from}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                     Requested By
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.created_by_text}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Requested Date
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.created_at?formatDateTime(detailsData?.created_at):''}
                    </div> 
                </div>

            </div>


            <SRItemComponent itemDatas={detailsData?.invreqdetail_set} title={'Requisition Item Information'} />
            
        </div>

            
        <SRIssueFormCom requisionItemDatas={detailsData?.invreqdetail_set} srID={id} ticket={detailsData?.ticket_text}/>

        <SrIdWiseIssuedListCom apiEndPoint={`/inventory/api/sr-issued/?diagnosis_sr_id=${id}`} />


        {/* now not use */}
        {/* <SrToPr isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} srID={srID} /> */}
        
        <SrToPrFormCom isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} srToPrItems={detailsData?.invreqdetail_set} srID={id} />

    </div>
  )
}



  
export default SrDetails;