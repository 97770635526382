import React, { useEffect, useState } from 'react'

const WoTotalPriceWAdCharge  = ({workorderData}) => {
    const [totalPrice, setTotalPrice] = useState(null);
    
    useEffect(() => {
      // Calculate total price when workorderData changes
      const calculateTotalPrice = () => {
        if (workorderData && workorderData.workorderitem_set && workorderData.workorderitem_set.length > 0) {
          const total = workorderData.workorderitem_set.reduce((acc, item) => {
            const quantity = parseFloat(item?.wo_item_qty) || 0;
            const price = parseFloat(item?.price) || 0;
            const discount = parseFloat(item?.discount_price) || 0;
            const discountType = item?.discount_type;
  
            let itemTotal = quantity * price;
  
            // Apply discount based on discount_type
            if (discountType === 'percentage') {
              itemTotal -= (itemTotal * (discount / 100));
            } else if (discountType === 'fixed') {
              itemTotal -= discount;
            }
  
            return acc + itemTotal;
          }, 0);
  
          // Adding additional charges and subtracting discounts
          const additionalDiscount = workorderData.additional_discount ? parseFloat(workorderData.additional_discount) : 0;
          const transportCharge = workorderData.transport_charge ? parseFloat(workorderData.transport_charge) : 0;
          const otherCharge = workorderData.other_charge ? parseFloat(workorderData.other_charge) : 0;
  
          const finalTotal = (total + transportCharge + otherCharge) - additionalDiscount;
  
          // Update the state with the calculated total price
          setTotalPrice(finalTotal.toFixed(2));
        } else {
          // If workorderData or workorderitem_set is empty or null, set total price to null
          setTotalPrice(null);
        }
      };
  
      calculateTotalPrice();
    }, [workorderData]);

  return (
    <div>
        {totalPrice && totalPrice}
    </div>
  )
}

export default WoTotalPriceWAdCharge 