import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import { formatDateTime } from '../../../Components/formatDateTime'
import PrItemComponent from '../InventoryComponent/PrItemComponent'

function SrToPrDetails() {
    const {id} = useParams()
    const [srToPrData, setSrToPrData] = useState(null)

    useEffect(()=>{
    const fetchSinglesrToPrData = async () =>{
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sr-to-pr/${id}/`)
            setSrToPrData(response.data)
        } catch (error) {
            console.log(error)
        }
    };fetchSinglesrToPrData();
    },[id])


    
  return (
    <div>
        <div className="req-detail shadow-xl pb-5 mx-4 bg-[#fff] border border-[#EB5757]">
            <div className='information-head flex justify-between  px-5 mb-5 py-2'>
                <div className='uppercase text-[20px]'>PR Details</div>
            </div>

            <h1 className='mx-2 py-1 text-[20px] font-semibold text-[#000000]'>Basic Information</h1>
            <div className="grid md:grid-cols-2  mx-2 my-2 gap-x-16 gap-y-2"> 

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        PR NO
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {srToPrData?.pr_no}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        PR By
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {srToPrData?.pr_by_text}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Status
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {srToPrData?.is_workorder === true?'Ordered':'Pending'}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Requested Date
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {srToPrData?.pr_at && formatDateTime(srToPrData?.pr_at)}
                    </div> 
                </div>

            </div>

            <PrItemComponent itemDatas={srToPrData?.srtopritem_set} title={'PR Item Information'} />



        </div>
    </div>
  )
}

export default SrToPrDetails