import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomPaginator from '../../../../Components/CustomPaginator';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';
import useFetchListData from '../../../../hooks/useFetchListData';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { formatDateTime } from '../../../../Components/formatDateTime';


const ATWorkingActivitiesLogListCom = ({user,apiEndpoint}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [dataStatus, setDataStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndpoint}`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, dataStatus, endDate,startDate]);

  const handleSearch = (value) => {
    setSearchText(value);
  };


  const dataSource = data?.flatMap((data,index) =>({
    key: 1 + index++,
    id:data?.id,
    manpower:data?.manpower,
    remarks:data?.remarks,
    created_by_text: data?.created_by_text,
    created_at: data?.created_at,

  }))


  const columns = [
    {
      title: 'SN',
      dataIndex: 'key',
      key: 'key',
    },
    {
    title: 'Created By',
    dataIndex: 'created_by_text',
    key: 'created_by_text',
    },
    {
    title: 'Technician',
    dataIndex: 'created_by_text',
    key: 'created_by_text',
    },
    {
    title: 'Manpower',
    dataIndex: 'manpower',
    key: 'manpower',
    },
    {
    title: 'Remarks',
    dataIndex: 'remarks',
    key: 'remarks',
    },
    
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: '7',
      render: (created_at) => {
        const formattedDateTime = formatDateTime(created_at);
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },

  ];






  return (
    <>
    <div className='container-fluid grid grid-cols-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>Working Activities Log List</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>

            {user?.primary_role == 8 && (
            <div className="add-new-button px-2 ml-1">
                  <Link to={`/new-ticket-request`}>Add Request</Link>
            </div>
            )}
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4 justify-between md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>


      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
        <>
        <div className='shadow-lg overflow-x-auto'>
          <Table 
              columns={columns}
              dataSource={dataSource} 
              pagination={false}
          />
          <div className='my-5'>
              <CustomPaginator 
                  setCurrentPage={setCurrentPage} 
                  currentPage={currentPage}
                  totalItems={totalItems}
              />
          </div>
        </div>
       </>
      )} 
    </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ATWorkingActivitiesLogListCom);