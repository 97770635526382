import React from 'react'
import FormPageTitle from '../../../Components/Form/FormPageTitle'
import SupplierAddEditComponent from './SupplierComponents/SupplierAddEditComponent'
import InputFiled from '../../../Components/Form/InputFiled'
import SelectInput from '../../../Components/Form/SelectInput'

const SupplierAdd = () => {

    const {
        loading,
        formData,
        handleInputChange,
        supplierTypeOption,
        handleSupplierTypeChange,
        handleSubmit,
        }=SupplierAddEditComponent()

  return (
    <div>
        <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
        <FormPageTitle title='Supplier Add Form' FormPageRightSideButtonURL='/supplier-list' LinkName='Back To List Page' />
        
            <form onSubmit={handleSubmit}>
             <div className="grid grid-cols-2 lg:grid-cols-3">

                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.name} 
                        onChange={handleInputChange} 
                        name='name' id='name'  
                        label='Name*' 
                        isRequired={true} 
                        placeholder='Enter supplier name'  
                    />
                </div>
                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.address_1} 
                        onChange={handleInputChange} 
                        name='address_1' id='address_1'  
                        label='Address*' 
                        isRequired={true} 
                        placeholder='Enter supplier address'  
                    />
                </div>
                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.phone} 
                        onChange={handleInputChange} 
                        name='phone' id='phone'  
                        label='Phone*' 
                        isRequired={true} 
                        placeholder='Enter supplier phone'  
                    />
                </div>
                <div className='my-4'>
                    <InputFiled 
                        type='email' 
                        value={formData.email} 
                        onChange={handleInputChange} 
                        name='email' id='email'  
                        label='Email' 
                        isRequired={false} 
                        placeholder='Enter supplier email'  
                    />
                </div>

                <div  className='my-4'>
                    <SelectInput
                        name='type'
                        id='type'
                        label='Supplier Type'
                        placeholder='select supplier type'
                        className={`w-full`}
                        value={supplierTypeOption.find((option)=> option?.value === formData.type)}
                        onChange={handleSupplierTypeChange}
                        options={supplierTypeOption}
                        isRequired={false}
                    />
                </div>

                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.vat_reg} 
                        onChange={handleInputChange} 
                        name='vat_reg' id='vat_reg'  
                        label='Vat Reg' 
                        isRequired={false} 
                        placeholder='Enter supplier vat reg'  
                    />
                </div>

                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.license_no} 
                        onChange={handleInputChange} 
                        name='license_no' id='license_no'  
                        label='License No' 
                        isRequired={false} 
                        placeholder='Enter supplier license no'  
                    />
                </div>

                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.contact_person} 
                        onChange={handleInputChange} 
                        name='contact_person' id='contact_person'  
                        label='Contact Person' 
                        isRequired={false} 
                        placeholder='Enter contact person name'  
                    />
                </div>

                <div className='my-4'>
                    <InputFiled 
                        type='text' 
                        value={formData.contact_person_no} 
                        onChange={handleInputChange} 
                        name='contact_person_no' id='contact_person_no'  
                        label='C.Person Phone' 
                        isRequired={false} 
                        placeholder='Enter contact person phone'  
                    />
                </div>
                
                <div className="my-auto">
                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>{loading ? 'Submitting...' : 'Submit'}</button>
                </div>

             </div>



            </form>
        
    </div>
    </div>
  )
}

export default SupplierAdd