// TicketList.jsx
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import PendingComplainList from './PendingComplainList';
import CompletedComplainList from './CompletedComplainList';



function CustomerComplain({user}) {

  return (
    <div className="parent-category">
      <Tabs>
        <TabList className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 tb-list bg-[#fff] shadow-xl pt-6 px-3 text-center rounded-[5px]'>
          {user?.menu_permissions?.includes("2.5.6") && (
            <Tab>PENDING COMPLAIN LIST</Tab>
          )}
          {user?.menu_permissions?.includes("2.5.6") && (
            <Tab>COMPLATED COMPLAIN LIST</Tab>
          )}
          </TabList>

        {user?.menu_permissions?.includes("2.1.6") && (
        <TabPanel>
            <PendingComplainList />
        </TabPanel>
        )}
        {user?.menu_permissions?.includes("2.3.6") && (
        <TabPanel>
          <CompletedComplainList />
        </TabPanel>
        )}
      </Tabs>
    </div>

  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CustomerComplain);