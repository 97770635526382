import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ItemListComponet from './ItemComponents/ItemListComponet';


function ItemList({user}) {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState({});
  const [currentPage,setCurrentPage] =useState(1)
  
  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Name/Size',
      key: 'item_and_size',
      render: (text, record) => (
        <span>
          {record.item_name}/{record.size}
        </span>
      ),
      sorter: (a, b) => a?.item_name.localeCompare(b?.item_name),
      sortOrder: sortOrder['item_name'],
    },
    {
      title: 'Sales_Price',
      dataIndex: 'item_sales_price',
      key: '2',
    },
    {
      title: 'Code',
      dataIndex: 'item_code',
      key: '3',
      sorter: (a, b) => a?.item_code.localeCompare(b?.item_code),
      sortOrder: sortOrder['item_code'],
    },
    {
      title: 'Category',
      dataIndex: 'category_text',
      key: '4',
      sorter: (a, b) => a?.category_text?.localeCompare(b?.category_text),
      sortOrder: sortOrder['category_text'],
      responsive: ['md'],
    },
    {
      title: 'S_Category',
      dataIndex: 'sub_category_text',
      key: '5',
      sorter: (a, b) => a?.sub_category_text?.localeCompare(b?.sub_category_text),
      sortOrder: sortOrder['sub_category_text'],
      responsive: ['md'],
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: '6',
      sorter: (a, b) => a?.brand?.localeCompare(b?.brand),
      sortOrder: sortOrder['brand'],
      responsive: ['md'],
    },
    {
      title: 'Stock',
      dataIndex: 'stock_available_qty',
      key: '7',
      sorter: (a, b) => a?.stock_available_qty?.localeCompare(b?.stock_available_qty),
      sortOrder: sortOrder['stock_available_qty'],
      responsive: ['md'],
    },
  ];


  const addNewURL = user?.menu_permissions?.includes("3.2.1")? '':''
  const deleteURL = user?.menu_permissions?.includes("3.2.4")? '':''
  const detailsURL = user?.menu_permissions?.includes("3.2.2")? '/item-details':''
  const dataEditURL = user?.menu_permissions?.includes("3.2.3")? '/item-master/edit/':''

  console.log('addNewURL',addNewURL)

  return (
    <div className='bg-[#fff] rounded-[5px]'>
      <ItemListComponet
        apiEndpoint="inventory/api/item-master"
        columnsConfig={columns}
        title="Item List"
        SearchKey1='category_text'
        SearchKey2='item_name'
        SearchKey4='item_code'
        SearchKey3='sub_category_text'
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        addNewURL={``}
        dataEditURL={dataEditURL}
        deleteURL ={deleteURL}
        detailsURL={detailsURL}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        listExcelExportApiEndPoint={'/inventory/api/item-master-export/'}
      />
    </div>
  );
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ItemList);