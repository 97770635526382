import React from 'react'
import VehicleFormComponent from './VehicleComponents/VehicleFormComponent'
import VehicleFormHandleComponent from './VehicleComponents/VehicleFormHandleComponent'
import LoadingSpinner from '../../Components/LoadingSpinner'

const VehicleAdd = () => {
    const {
        loading,
        handleSubmit,
        formData,
        handleInputChange,
        vehicleTypeOption,
        handleVehicleTypeChange,
    } = VehicleFormHandleComponent()
  return (
    <div>
        <form onSubmit={handleSubmit}>
            <VehicleFormComponent   formData = {formData}
                                    handleInputChange= {handleInputChange}
                                    vehicleTypeOption={vehicleTypeOption}
                                    handleVehicleTypeChange={handleVehicleTypeChange} />
            <div className="my-auto">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>{loading ? <LoadingSpinner /> : 'Submit'}</button>
            </div>
        </form>
    </div>
  )
}

export default VehicleAdd