import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import ShowToast from '../../../../Components/ShowToast';
import { format } from 'date-fns';



function GRnForm() {
    const location = useLocation();
    const selectedWoIds = location.state?.selectedIds || [];
    const [woNoOptions, setWoNoOptions] = useState([])
    const [selectedWoNo, setSelectedWoNo] = useState(null);
    const [woDetailsData, setWoDetailsData] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedStore, setSelectedStore] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [singleItemTotalPrices, setSingleItemTotalPrices] = useState({});
    const [overallTotal, setOverallTotal] = useState(0);
    const [qtyErrorMessages, setQtyErrorMessages] = useState({});
    const [woSearchInputValue,setWoSearchInputValue] = useState(null)

    const navigate = useNavigate()

    const [grnBasicFormData,setGRnBasicFormData] = useState({
      store:null,
      challan_no:'',
      challan_date:null,
      note:''
    })


    const handleInvoiceDateChange = (date) => {
      setGRnBasicFormData({
        ...grnBasicFormData,
        challan_date: date,
      });
    };

    const handlegrnBasicFormDataInputChange = (e) => {
      const { name } = e.target;
      let value = e.target.value;
      setGRnBasicFormData({
        ...grnBasicFormData,
        [name]: value,
      });
    };



    useEffect(() => {
        const fetchSelectedWoData = async () => {
            try {
            const woData = selectedWoIds?.map((woID, index) => ({
                value: woID.id,
                label: woID.wo_no,
            }));
            setSelectedWoNo(woData);

            } catch (error) {
            console.log(error);
            }
        };
        
        fetchSelectedWoData();
    }, []);


    useEffect(() => {
      if (woSearchInputValue?.length >= 0) {
        authAxiosWithBearer.get(`${baseURL}/inventory/api/wo-list-for-grn/?search=${woSearchInputValue}`)
            .then(response => {
                setWoNoOptions(response.data.results.map(data => ({
                  value: data.id,
                  label: data.wo_no
                })));

                console.log(response.data.results)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [woSearchInputValue]);

    const handleWoSearchInputChange = (value) => {
      setWoSearchInputValue(value);
    };


    const handleWoNoChange = (selectedOptions) => {
        setSelectedWoNo(selectedOptions);
    };

    const handleStoreChange = (selectedStore) =>{
        setSelectedStore(selectedStore);
        setGRnBasicFormData({
          ...grnBasicFormData,
          store: selectedStore? selectedStore.value : null,
        });
      }

      
    // fetchwoDetailsData
    const fetchWoDetailsData = async () => {
        try {
            const datas = selectedWoNo?.map(async (data) => {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/wo-details-for-grn/${data.value}/`);
                return response.data;
            });

            const results = await Promise.all(datas);
            const objectResults = results.filter(value => typeof value === 'object');
            setWoDetailsData(objectResults)
    
        } catch (error) {
            console.log(error);
        }
    };
    
    useEffect(() => {
        fetchWoDetailsData();
    }, [selectedWoNo]);



    const handleHeaderCheckboxChange = () => {
        const allItems = woDetailsData.flatMap((woData) => woData?.workorderitem_set || []);
        const allItemIDs = allItems.map((item) => item.id);
        const newSelectedItems = selectAll ? [] : allItemIDs;

        setSelectedItems(newSelectedItems);
      };
    

    const handleWoCheckboxChange = (woIndex) => {
        const woData = woDetailsData[woIndex];
        const woItemIDs = woData?.workorderitem_set?.map((item) => item.id) || [];
    
        // Check if all prItem checkboxes are selected
        const allWoItemsSelected = woItemIDs.length === selectedItems.filter((id) => woItemIDs.includes(id)).length;
    
        let newSelectedItems;

        console.log('allWoItemsSelected',allWoItemsSelected)
    
        if (!allWoItemsSelected) {
            // If all prItem checkboxes are not selected, merge item IDs
            newSelectedItems = [...selectedItems, ...woItemIDs];
        } else {
            // If all prItem checkboxes are selected, remove its item IDs
            newSelectedItems = selectedItems.filter((id) => !woItemIDs.includes(id));
        }

        console.log('newSelectedItems',newSelectedItems)
    
        setSelectedItems(newSelectedItems);
    };
    
    

    const handleItemCheckboxChange = (woIndex, itemIndex) => {
        const itemID = woDetailsData[woIndex]?.workorderitem_set?.[itemIndex]?.id;
        const newSelectedItems = selectedItems.includes(itemID)
            ? selectedItems.filter((id) => id !== itemID)
            : [...selectedItems, itemID];

        setSelectedItems(newSelectedItems);
    };


    const selectAll = selectedItems.length === woDetailsData.flatMap((woData) => woData.workorderitem_set).length;



    const handleInputChange = (itemData, fieldName, value) => {
      if (fieldName === 'rcv_qty') {
        const woQty = parseFloat(value || 0);
        const availableQty = itemData?.available_rcv_qty || 0;
    
        if (woQty > availableQty) {
          setQtyErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${fieldName}_${itemData?.id}`]: 'Quantity exceeds available quantity.',
          }));
        } else {
          setQtyErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${fieldName}_${itemData?.id}`]: '',
          }));
        }
      }
    
      const numericFields = ['rcv_qty', 'add_qty'];
    
      if (numericFields.includes(fieldName)) {
        const parts = value.split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1] || '';
    
        // If the total number of digits exceeds 14, show an error message
        if (integerPart.length > 14) {
          ShowToast('error', `Ensure that there are no more than 14 digits for ${fieldName}`);
          return;
        }
    
        // If decimal part exists, limit it to 6 decimal places
        if (parts.length === 2) {
          value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    
          // If more than 6 digits after the decimal point, show a separate error message
          if (decimalPart.length > 2) {
            ShowToast('error', `More than 2 digits after the decimal point for ${fieldName}.`);
          }
        }
      }
    
      setInputValues((prevValues) => ({
        ...prevValues,
        [`${fieldName}_${itemData?.id}`]: value,
      }));
    };

    
    const handleFromDateChange = (itemData, fieldName, date) => {
      setInputValues((prevValues) => ({
        ...prevValues,
        [`${fieldName}_${itemData?.id}`]: date,
      }));
    };

      
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // Check if there are any error messages for quantity fields
        const hasQtyErrors = Object.values(qtyErrorMessages).some((error) => error !== '');

        // If there are errors, show a message and return without submitting
        if (hasQtyErrors) {
            ShowToast('error', 'Please fix quantity errors before submitting.');
            return;
        }
    
        const grnFormData = {

            // Basic Information
            store: grnBasicFormData.store,
            challan_no: grnBasicFormData.challan_no,
            challan_date: grnBasicFormData.challan_date ? format(grnBasicFormData.challan_date, 'yyyy-MM-dd') : null,
            note: grnBasicFormData.note,

              grnitem_set: woDetailsData.reduce((accumulator, woData, woIndex) => {
                const items = woData.workorderitem_set.map((itemData, itemIndex) => {
                const rcv_qty = inputValues[`rcv_qty_${itemData.id}`];
                const add_qty = inputValues[`add_qty_${itemData.id}`];
                const lot_no = inputValues[`lot_no_${itemData.id}`];
                const exp_date = inputValues[`exp_date_${itemData.id}`];
                const remarks = inputValues[`remarks_${itemData.id}`];
        
                const itemID = itemData.id;
                const isSelected = selectedItems.includes(itemID);
                const isItemRequired = isSelected && rcv_qty !== '' && lot_no !== '';
        
                if (isItemRequired) {
                    return {
                    wo_item: itemID,
                    rcv_qty: rcv_qty,
                    add_qty: add_qty,
                    lot_no: lot_no? lot_no: '',
                    exp_date:exp_date? format(exp_date, 'yyyy-MM-dd') : null,
                    remarks: remarks? remarks: '',
                    };
                } else {
                    console.log(`Item unselected: PR: ${woData.wo_no}, Item: ${itemData.item_name}`);
                    return null; // Exclude unselected items from the array
                }
                });
        
                return accumulator.concat(items.filter((item) => item !== null));
            }, []),
        };

    
        try {
          if (selectedWoNo.length > 0 && selectedItems.length > 0) {
            if (
              grnFormData.grnitem_set.length > 0 ) {
              const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/grn-add/`, grnFormData);
              console.log(response.data)
              if(response.data.grn_no){
                navigate('/grn-list')
                ShowToast('success', 'Successfully Submit')       
              }else{
                navigate('/workorder-list')
                ShowToast('error', 'Something is Wrong Try Again')
              }
              
            } else {
              ShowToast('info', 'Please fill out required fields');
            }
          } else {
            ShowToast('info', 'Please select any item');
          }
        } catch (error) {
          if(error.response?.data?.errors){
            ShowToast('error', `${error.response?.data?.errors}`);
          }else if(error.response?.data?.message){
            ShowToast('error', `${error.response?.data?.message}`);
          }else{
            ShowToast('error', `${error?.message}`);
          }
        }
      };
      


  return {
    location,
    selectedWoIds,
    woNoOptions,
    setWoNoOptions,

    selectedWoNo,
    setSelectedWoNo,

    woDetailsData,
    setWoDetailsData,

    selectedItems,
    setSelectedItems,

    selectedStore,
    setSelectedStore,

    inputValues,
    setInputValues,

    qtyErrorMessages,
    setQtyErrorMessages,

    grnBasicFormData,
    setGRnBasicFormData,

    navigate,
    handleWoNoChange,
    handleStoreChange,
    handleHeaderCheckboxChange,
    handleWoCheckboxChange,
    handleItemCheckboxChange,
    selectAll,
    handleInputChange,
    handleFromDateChange,
    handlegrnBasicFormDataInputChange,
    handleInvoiceDateChange,
    handleFormSubmit,
    handleWoSearchInputChange,
    
  }
}

export default GRnForm