import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent'
import AssignedTransportDetailsComponent from '../InHouseComponents/AssignedTransportDetailsComponent'
import AssignedTransportProductReceivedStatusChange from '../InHouseComponents/AssignedTransportProductReceivedStatusChange'
import TechnicianList from '../InHouseComponents/TechnicianList'

const TransportedGoodsReceivedDetails = () => {
    const {id} = useParams()
    const [detailsData, setDetailsData]= useState(null)
    const [ticketData, setTicketData] = useState(null);

    // fetchDeatilsData
    const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/assigned-transport-details/${id}/`);
            console.log(response.data);
            setDetailsData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
    useEffect(() => {
        fetchDeatilsData();
    }, [id]);

    
    // fetchAssignedTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${detailsData?.assigned_technician}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [detailsData]);
    
  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Transported Goods Received Details</h1>
            </div>
        </div>


            {/* assigned transport details start  */}
                <AssignedTransportDetailsComponent  data={detailsData} />
            {/* assigned transport details end  */}
        
            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}

            {/* transport list start  */}
            <TechnicianList detailsData={detailsData} fetchDeatilsData={fetchDeatilsData} />
            {/* transport list end  */}
            

    </div>
  )
}

export default TransportedGoodsReceivedDetails