import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../baseURL';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../Components/formatDateTime';
import LoadingSpinner from '../../Components/LoadingSpinner';
import DateRangeFiltering from '../../Components/DateRangeFiltering';
import { useSelector } from 'react-redux';
import { IoArrowUndoSharp } from "react-icons/io5";


const IssuedList = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user)
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sr-issued/`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
  
        setTotalItems(count);
        setData(results);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate, currentPage, searchText]);


  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    issue_no: data?.issue_no,
    issued_by_text: data?.created_by_employee_id,
    issued_to_text: data?.issued_to_text,
    issued_at: data?.created_at,
    return_status:data?.return_status,
    srissueditem_set: data.srissueditem_set
  }))


  const handleSearch = (value) => {
    setSearchText(value);
  };


  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'ISSUED NO',
      dataIndex: 'issue_no',
      key: '1',
    },
    {
      title: 'ISSUED DATE',
      dataIndex: 'issued_at',
      key: '3',
      render: (issued_at) => {
        const formattedDateTime = issued_at? formatDateTime(issued_at) :'';
        return <span>{formattedDateTime}</span>;
      },
    },
    {
      title: 'ISSUED BY',
      dataIndex: 'issued_by_text',
      key: '4',
      responsive: ['md']
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>
          {user?.menu_permissions?.includes("3.4.2") &&(
            <button onClick={() => navigate(`/sr-issued-details/${record.id}`)} className='rounded'>
              <AiFillEye className='text-[#3232ff] text-xl' />
            </button>
          )}
            {user?.menu_permissions?.includes("3.17.1") && record?.return_status === true && (
              <button 
                onClick={() => navigate(`/sr-issued-return-add/${record.id}`)} 
                className="rounded   text-[#000000]"
              >
                <IoArrowUndoSharp className='text-xl' title='issue return' />
              </button>
            )}
        </div>
      ),
    },
  ];


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className='page-header mb-[80px] md:mb-5'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto ml-5'>
            <h1 className='uppercase'>ISSUED LIST</h1>
          </div>
          <div className='page-search-and-add-new-button md:flex my-auto mr-5'>
            <div className='page-date-range my-auto hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            <div className='page-search ml-2'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='page-header px-5 py-5 md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
      </div>
      </div>

      <div className='shadow-lg pb-3'>
      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
      </div>
    </>
  );
};

export default IssuedList;
