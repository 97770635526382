import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomerDataListComponent from './CustomerComponents/CustomerDataListComponent';


function CustomerList() {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);

  const [sortOrder, setSortOrder] = useState({});

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      sortOrder: sortOrder['name'],
      render: (text) => (text?.length > 50 ? `${text.substring(0, 40)}...` : text),
    },
    {
      title: 'Phone no',
      dataIndex: 'phone_no',
      key: '2',
      sorter: (a, b) => a?.phone_no?.localeCompare(b?.phone_no),
      sortOrder: sortOrder['phone_no'],
      render: (text) => (text?.length > 50 ? `${text.substring(0, 40)}...` : text),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: '3',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortOrder: sortOrder['email'],
      responsive: ['md'],
      render: (text) => (text?.length > 50 ? `${text.substring(0, 40)}...` : text),
    },
    {
      title: 'address',
      dataIndex: 'address',
      key: '4',
      sorter: (a, b) => a?.address?.localeCompare(b?.email),
      sortOrder: sortOrder['address'],
      responsive: ['md'],
      render: (text) => (text?.length > 50 ? `${text.substring(0, 40)}...` : text),
    },
  ];


  const addNewURL = user?.menu_permissions?.includes("2.2.1")? '/customer-add':''
  // const deleteURL = user?.menu_permissions?.includes("2.2.4")? '/customer-delete':''
  const dataEditURL = user?.menu_permissions?.includes("2.2.3")? '/customer-update':''

  return (
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
      <CustomerDataListComponent
        apiEndpoint="/ccd/api/customer/"
        columnsConfig={columns}
        title="Customers List"
        SearchKey1='name'
        SearchKey2='email'
        SearchKey3='phone_no'
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        expandableKey1='email'
        expandableKey2='address'
        addNewURL={addNewURL}
        dataEditURL={dataEditURL}
      />
    </div>
  );
}



export default CustomerList;