import React from 'react';
import useFetchDetailsData from '../../../../hooks/useFetchDetailsData';
import GSRIssueDetailsCom from '../GeneralSRComponents/GSRIssueCom/GSRIssueDetailsCom';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { useParams } from 'react-router-dom';


const GeneralSRIssuedDetails = () => {
  const {id} = useParams()

  const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/general-sr-issue', {id});

  if(loading){
      return <div><LoadingSpinner /> </div>
  }
  return (
    <>
      <GSRIssueDetailsCom detailsData={detailsData} />
    </>
  );
};


export default GeneralSRIssuedDetails;