import React from 'react'
import DamagePartsFormCom from './DamagePartsComponents/DamagePartsFromComponets/DamagePartsFormCom'

const DamagePartsAdd = () => {
  return (
    <>
        <DamagePartsFormCom title={'Damage Part Add'} />
    </>
  )
}

export default DamagePartsAdd