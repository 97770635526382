import React from 'react';
import WoTotalPrice from './WoTotalPrice';
import WoTotalPriceWAdChargeWinW from './WoTotalPriceWAdChargeWinW';

const TableWorkorderDetails = ({workorderData}) => {
    return (
        <>
            {/* className='px-4 sm:px-14 mt-7 sm:text-[10px] lg:text-[15px] overflow-x-auto  ' */}
            <div className='overflow-x-auto overflow-y-auto'>

                <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

                    <thead>

                        <tr className='bg-[#EEEEEE]'>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Order no</th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> PRICE (BDT)  </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> TOTAL (BDT) </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>DISCOUNT (BDT)  </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>FINAL  (BDT) </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

                        </tr>

                    </thead>



                    <tbody className='text-center'>

                        {workorderData?.workorderitem_set?.map((item,index)=>(
                            <tr >
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{index+1}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                                    name : {item?.item_name} <br />
                                    size : {item?.item_size} <br />
                                    specification :{item?.item_specification} <br />
                                    brand : {item?.item_brand}
                                </td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.wo_item_qty} ({item?.pr_uom_name? item.pr_uom_name : item?.item_uom_name})</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.price}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{parseFloat(item?.wo_item_qty * item?.price).toFixed(2)}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.discount_price}({item?.discount_type})</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                                    {(() => {
                                        const quantity = parseFloat(item?.wo_item_qty) || 0;
                                        const price = parseFloat(item?.price) || 0;
                                        const discount = parseFloat(item?.discount_price) || 0;
                                        const discountType = item?.discount_type;

                                        let itemTotal = quantity * price;

                                        // Apply discount based on discount_type
                                        if (discountType === 'percentage') {
                                        itemTotal -= (itemTotal * (discount / 100));
                                        } else if (discountType === 'fixed') {
                                        itemTotal -= discount;
                                        }

                                        return itemTotal.toFixed(2);
                                    })()}
                                </td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                            </tr>
                        ))}


                        <tr>
                            <td colSpan="6" className='border-2 border-[#ED6C6C] w-[400px] py-3'>TOTAL AMOUNT</td>
                            <td colSpan="2" className='border-2 border-[#ED6C6C] w-[400px] py-3'><WoTotalPrice workorderData={workorderData} /></td>
                        </tr>
                        {/* eighteen thousand four hundred twenty five taka only */}
                        <tr>
                            <td colSpan="8" className='border-2 border-[#ED6C6C] w-[400px] py-3 '>AMOUNT IN WORD - <WoTotalPriceWAdChargeWinW workorderData={workorderData} />  </td>
                        </tr>

                    </tbody>

                </table>



            </div>
        </>
    );
};

export default TableWorkorderDetails;