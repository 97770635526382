import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authAxiosWithBearer,baseURL } from '../../baseURL';
import '../../assest/CSS/ReactSelectCustomize.css'
import RequisionSubmitedDataList from './TechnicianComponent/RequisionSubmitedDataList';
import TechnicianSRFormComponent from './TechnicianComponent/TechnicianSRFormComponent';
import TechnicianAssignedStatusChangeForm from './TechnicianComponent/TechnicianAssignedStatusChangeForm';
import TechnicianWiseAssignedPackageListComponents from './TechnicianComponent/TechnicianWiseAssignedPackageListComponents';
import AssignedTicketDetailsComponent from './TechnicianComponent/AssignedTicketDetailsComponent';
import { MdAddTask } from 'react-icons/md';


function TechnicianWiseTicketDetails() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [ticketData, setTicketData] = useState(null);
    const {id} = useParams()
    const navigate = useNavigate()


    // fetchAssignedTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${id}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [id]);



    


    return (
        <div className='my-5 mx-2'>
            <div className="user-details-page-title mb-5 shadow flex justify-between">
                <div className="my-auto py-2">
                    <h1 className='uppercase'>Ticket Details</h1>
                </div>
                <div className="my-auto py-2">
                    <button title='Details' onClick={() => navigate(`/technician-task-report-add/${id}`)} className='rounded flex gap-x-1 bg-[#F35B5B] p-2'>
                       <span className='my-auto'><MdAddTask className='text-[#ffffff] text-xl' /></span>
                        <span className='my-auto uppercase text-white'>Task Report</span>
                    </button>
                </div>
            </div>

            {/* status change form start */}
                <TechnicianAssignedStatusChangeForm id={id} ticketData={ticketData} />
            {/* status chnage form end  */}


            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}


            {/* assigned package start */}

            <TechnicianWiseAssignedPackageListComponents ticketData={ticketData} />
            {/* assigned package end */}


            {!ticketData?.hvac && (
             <div>
                
                    <RequisionSubmitedDataList assigned_id={id} />

                
                {/* sr form start  */}
                    <TechnicianSRFormComponent ticketData={ticketData} id={id}/>
                {/* sr form end  */}
                
            </div>
            )}


        </div>
    )
}

export default TechnicianWiseTicketDetails