import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { authAxiosWithBearer, baseURL } from '../../baseURL';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { formatDateTime } from '../../Components/formatDateTime';


const VehicleList = ({user}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] =  ('');
  const [data, setData] = useState(null);
  const [dataStatus, setDataStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await authAxiosWithBearer.get(`${baseURL}/api/vehicle/`, {
        params: {
          page: currentPage,  
          search: searchText,
          status: dataStatus !== 'all' ? dataStatus : '',    
        },
      });

      const { results, count } = response.data;

      setTotalItems(count);
      setData(results);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [currentPage, searchText,dataStatus]);




  const dataSource = data?.flatMap((data,index) =>({
    key: index,
    id:data?.id,
    vehicle_name: data?.vehicle_name,
    vehicle_number: data?.vehicle_number,
    vehicle_type: data?.vehicle_type,
    vehicle_active_status: (
      <div className="flex items-center">
        <input
          type="checkbox"
          id={`toggle-${data.id}`}
          className="hidden"
          checked={data.vehicle_active_status}
          onChange={() => handleToggleDataStatus(data.id, data.package_name, data.vehicle_active_status)}
        />
        <label htmlFor={`toggle-${data.id}`} className="cursor-pointer">
          <div
            className={`w-12   rounded-full p-1 transition duration-300 ease-in-out ${
              data.vehicle_active_status === true ? 'bg-green-600' : 'bg-red-600'
            }`}
          >
            <div
              className={`bg-white w-5 h-5 rounded-full shadow-md transform ${
                data.vehicle_active_status ? 'translate-x-full' : 'translate-x-0'
              } transition duration-300 ease-in-out`}
            ></div>
          </div>
        </label>
      </div>
    ),
    created_by_text: data?.created_by_text,
    created_at: data?.created_at,
  }))


  const handleSearch = (value) => {
    setSearchText(value);
  };


  const handleStatusChange = (value) => {
    setDataStatus(value);
  };


  const handleToggleDataStatus = async (id,vehicle_name, currentStatus) => {
    try {
      await authAxiosWithBearer.put(`${baseURL}/api/vehicle-status-update/${id}/`, {
        vehicle_name: vehicle_name,
        vehicle_active_status: !currentStatus,
      });
      fetchData();
    } catch (error) {
      console.error('Error toggling status:', error);
    }
  };


  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicle_name',
      key: 'vehicle_name',
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'vehicle_number',
      key: 'vehicle_number',
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicle_type',
      key: 'vehicle_type',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by_text',
      key: 'created_by_text',
      responsive: ['md']
    },
    ...(user?.menu_permissions?.includes("1.2.3")
      ? [
          {
            title: 'Status',
            dataIndex: 'vehicle_active_status',
            key: 'vehicle_active_status',
          },
        ]
      : []
    ),
    ...(user?.menu_permissions?.includes("3.2.3")
      ? [
        {
          title: 'ACTIONS',
          dataIndex: 'actions',
          render: (_, record) => (
            <div className='flex gap-2'>
              {user?.menu_permissions?.includes("3.7.3") && (
                  <button onClick={() => navigate(`/vehicle-update/${record.id}`)} className='rounded'>
                  <AiFillEdit className='text-[#6F6F7E] text-xl' />
                </button>
              )}
            </div>
          ),
        },
        ]
      : []
    ),

  ];


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };



  return (
    <div className='grid grid-cols-1 mt-5'>
      <div className='page-header mb-[5px] md:mb-5'>
        <div className="md:flex justify-between py-5">

          <div className='page-title my-auto mx-2 mb-2 flex justify-between md:justify-start'>
            <h1 className='uppercase text-[5px]'>Vehicle LIST</h1>
            {user?.menu_permissions?.includes("3.7.1") && (
            <div className="add-new-button px-2 md:hidden">
              <Link className='text-[10px]' to={`/vehicle-add`}>Add New</Link>
            </div>
            )}
          </div>

          <div className='page-search-and-add-new-button flex justify-between my-auto px-2 md:px-0 md:mr-5 '>
           <div className='status-filter mx-2 hidden md:flex border rounded-[5px]'>
                <select
                  value={dataStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  className='border-[#D9D9D9] text-[#74747B] font-bold'
                >
                  <option value='all'>All</option>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </select>
              </div>

            <div className='page-search '>
              <div className='relative'>
                <input
                  className='page-search-input w-[220px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>

            < div className='status-filter md:hidden border rounded-[5px]'>
                <select
                  value={dataStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  className='border-[#D9D9D9] text-[#74747B] font-bold mt-1 w-[100px]'
                >
                  <option value='all'>All</option>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </select>
              </div>
              {user?.menu_permissions?.includes("3.7.1") && (
              <div className="add-new-button px-2 hidden md:flex">
                <Link className='text-[10px]' to={`/vehicle-add`}>Add New</Link>
              </div>
              )}
          </div>
        </div>
      </div>

      <div className='shadow-lg w-[100%] overflow-y-auto pb-3'>
      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <div className='overflow-x-auto'>
          <Table 
            columns={columns}
            pagination={false}
            dataSource={dataSource} 
            
          />
        </div>

        <div className='my-5'>
              <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={10}
              onChange={handlePaginationChange}
              showSizeChanger={false}
            />
        </div>
        </>
      )}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(VehicleList);