import React from 'react'
import { formatDateTime } from '../../../../../Components/formatDateTime'

const GSRIssueDetailsCom = ({detailsData}) => {
  return (
    <div className='border border-[#EB5757]'>
              <div className="user-details-page-title mb-5 shadow flex justify-between  px-5 py-2">
          <div className="my-auto py-2">
            <h1 className='uppercase'>GSR Issued Details</h1>
          </div>
        </div>

        <div className='border border-[#EB5757] mx-1 my-2'>

            <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                    <div className='bg-[#EB5757] '>

                        <div className='flex justify-center items-center 8 sm:py-5  md:py-5 lg:py-5'>
                            <div className='text-[#FFFFFF] text-[16px] font-semibold '>
                                <div className='text-center'>ISSUED NO</div>
                                <div className='text-center'> = {detailsData?.issue_no}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                        <div className='mx-1 sm:mx-0'>
                            <div>
                                <div className='flex'>
                                    <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Req No</div>
                                    <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                    <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.gsr_no}</div>
                                </div>
                            </div>
                            <div>
                                <div className='flex '>
                                    <div className='w-[35%] sm:w-[30%] md:w-[50%] lg:w-[35%]'>Issued By</div>
                                    <div className='w-[8%] sm:w-[5%] md:w-[8%] lg:w-[16%]'>-</div>
                                    <div className='w-[57%] sm:w-[65%] md:w-[42%] lg:w-[49%]'>{detailsData?.created_by_text}</div>

                                </div>
                            </div>

                        </div>

                        {/* 2nd */}

                        <div className='mx-1 sm:mx-0'>
                            <div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Issued Date</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </div>
        </div>

        <div className='overflow-x-auto overflow-y-auto p-2'>
        
        <div className='py-1 text-[20px] font-semibold text-[#000000]'>GSR Issue Item Information</div>
        
        <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> UOM  </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

                </tr>

            </thead>
            <tbody className='text-center'>

            {detailsData?.generalsrissueitem_set?.map((item,index)=>(
                <tr >
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{++index}</td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                        name : {item?.item_name} <br />
                        size : {item?.item_size} <br />
                        specification :{item?.item_specification} <br />
                        brand : {item?.item_brand}
                    </td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.uom_text}</td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.issue_qty}</td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                </tr>
            ))}
            </tbody>

        </table>
    </div>
    </div>
  )
}

export default GSRIssueDetailsCom