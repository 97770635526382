import React, { useState } from 'react'
import GeneralSRDetailsCom from './GeneralSRComponents/GeneralSRDetailsCom'
import { useParams } from 'react-router-dom'
import useFetchDetailsData from '../../../hooks/useFetchDetailsData'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import GSRIssueFromCom from './GeneralSRComponents/GSRIssueCom/GSRIssueFromCom'
import { useSelector } from 'react-redux'
import SrToPrFormCom from '../InventoryComponent/SrToPrComponents/SrToPrFormCom'
import SrIdWiseIssuedListCom from '../InventoryComponent/SrIdWiseIssuedListCom'

const GeneralSRDetails = () => {
    const {id} = useParams()
    const user = useSelector(state => state.auth.user)

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/general-sr-details', {id});
  

    // #sr to pr 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [srID, setSrID] = useState('');

    const openSrToPrModal = (id) => {
        setSrID(id);
        setIsModalOpen(true);
      };


      if(loading){
        return <div><LoadingSpinner /> </div>
    }


  return (
<div>
  <div className="req-detail shadow-xl pb-5 border mx-4 bg-[#fff]">
        <div className="user-details-page-title mb-5 shadow flex justify-between  px-5 py-2">
            <div className="my-auto py-2">
              <h1 className='uppercase'>Requisition Details</h1>
            </div>
            <div>

                {user?.menu_permissions?.includes("3.9.1") && detailsData?.issue_status !== 'Issued' && (
                <button
                    onClick={() => openSrToPrModal(id)}
                    className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                    >
                    SR TO PR
                </button>
                )}
            </div>

        </div>

          <GeneralSRDetailsCom detailsData={detailsData}/>

          <GSRIssueFromCom detailsData={detailsData} />

          <SrToPrFormCom isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} srToPrItems={detailsData?.generalsritem_set} GsrID={id} />

    </div>

    <SrIdWiseIssuedListCom apiEndPoint={`/inventory/api/sr-issued/?general_sr_id=${id}`} />


</div>
  )
}

export default GeneralSRDetails