import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';

const ATForServicingFromHangleComponent = ({setModalOpen,gctfeedback_id,technician_id,fetchData,fetchDeatilsData}) => {
    const [formData,setFormData] = useState({
      gctfeedback : null,
      technician : null,
      priority: '',
      remarks: '',
    })
    const navigate = useNavigate();

    const priorityOption = [
      { value: 'High', label: 'High' },
      { value: 'Normal', label: 'Normal' },
      { value: 'Low', label: 'Low' },
    ];

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
    };



  const handlePriorityOptionChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
      setFormData(prevState => ({ ...prevState, priority: selectedOption.value }));
      console.log('selectedOption',selectedOption)
    } else {
      setFormData(prevState => ({ ...prevState, priority: null }));
    }
  };


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {


        const UpdateFormData = {
          ...formData,
            gctfeedback:gctfeedback_id,
            technician : technician_id,
        }
        const response = await authAxiosWithBearer.post(`${baseURL}/in_house/api/technician-assign-for-servicing/`, UpdateFormData);
  
        if (response) {
          console.log(response);
          fetchData()
          fetchDeatilsData()
          ShowToast('success', 'Successfully Submit');
          setModalOpen(false)
          
        }
      } catch (error) {
        console.log(error);
        if(error?.response?.data?.error){
          ShowToast('error', `${error?.response?.data?.error}`);
        }else{
          ShowToast('error', 'Something is wrong');
        }
      }
    };

  return (
    {
        formData,
        setFormData,
        priorityOption,
        handleInputChange,
        handlePriorityOptionChange,
        handleSubmit,
    }
  )
}

export default ATForServicingFromHangleComponent