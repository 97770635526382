import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import ShowToast from '../../../../Components/ShowToast';
import { useNavigate } from 'react-router-dom';

const GeneralSRCreateHandleCom = () => {
    const navigate = useNavigate()
    // requision
    const [requisionRequiredMessage,setRequisionRequiredMessage]=useState('')

    const [requisionItemCategoriesOptions, setRequisionItemCategoriesOptions] = useState([]);
    const [selectedRequisionItemCategories, setSelectedRequisionItemCategories] = useState([]);

    const [requisionItemOptions, setRequisionItemOptions] = useState([]);
    const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
    const [itemSearchInputValue, setItemSearchInputValue] = useState(null);

    const [requisionFormData, setRequisionFormData] = useState({
        generalsritem_set: [
          {
            item_category: null,
            item: null,
            quantity: '',
            remarks:'',
            uom_value:null,
          },
        ],
      });

    
    // fetchItemCategoryData
    useEffect(() => {
        const fetchItemCategoriesData = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-sub-categories-list/`);
                console.log('fetchItemCategoriesData',response.data.results);
                const options = response.data.results?.map((cate) => ({
                    value:  cate.id,
                    label: `${cate?.name}`,
                }));
                setRequisionItemCategoriesOptions(options);

            } catch (error) {
                console.log(error);
            }
        };

        fetchItemCategoriesData();
    }, []);




    useEffect(() => {
        authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
            .then(response => {
            const filteredOptions = response.data.results
            .filter(item => selectedRequisionItemCategories.some(category => category.value === item.sub_category))
            .map(item => ({
                value: item.id,
                label: `${item?.item_name} | ${item?.size} | ${item.specification} | ${item.brand} | | ${item.model}`,
                uom_value: item.uom_text
            }));

            console.log('filteredOptions',filteredOptions)    
            setRequisionItemOptions(filteredOptions);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [itemSearchInputValue,selectedRequisionItemCategories]);

    
    const handleItemSearchInputChange = (value) => {
    setItemSearchInputValue(value);
    };



    const handleRequisionItemCategoryChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionFormData };
        updatedFormData.generalsritem_set[index].item_category = selectedOption?.value || null;
    
        const updatedSelectedRequisionItemCategory = [...selectedRequisionItemCategories];
        updatedSelectedRequisionItemCategory[index] = selectedOption || null;
    
        setSelectedRequisionItemCategories(updatedSelectedRequisionItemCategory);
        setRequisionFormData(updatedFormData);
    };
    

    const handleRequisionItemChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionFormData };
        updatedFormData.generalsritem_set[index].item = selectedOption?.value || null;
        updatedFormData.generalsritem_set[index].uom_value = selectedOption?.uom_value || null;
    
        const updatedSelectedRequisionItem = [...selectedRequisionItem];
        updatedSelectedRequisionItem[index] = selectedOption || null;
    
        setSelectedRequisionItem(updatedSelectedRequisionItem);
        setRequisionFormData(updatedFormData);
    };


    const handleRequisionInputChange = (e, index, type) => {
        const updatedData = { ...requisionFormData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'generalsritem_set' && name === 'quantity') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }
      
        if (type === 'generalsritem_set') {
          setRequisionRequiredMessage('');
          updatedData.generalsritem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setRequisionFormData(updatedData);
    };
      
    
      const areAllFieldsFilled = () => {
        return (
            requisionFormData.generalsritem_set &&
            requisionFormData.generalsritem_set.every((item) => {
                return item?.item !== null && item?.quantity !== '';
            })
        );
    };
    
      
    const handleAddRequisionItem = () => {
        if (areAllFieldsFilled()) {
            setRequisionFormData((prevState) => ({
                ...prevState,
                generalsritem_set: [
                    ...prevState.generalsritem_set,
                    {
                        item: null,
                        quantity: '',
                    },
                ],
            }));
            setRequisionRequiredMessage('');
        } else {
            setRequisionRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    
    
    
    // const handleRemoveRequisionItem = (index) => {
    // const generalsritem_set = [...requisionFormData.generalsritem_set];
    // setRequisionRequiredMessage('');
    // generalsritem_set.splice(index, 1);
    // setRequisionFormData({
    //     ...requisionFormData,
    //     generalsritem_set,
    // });
    // };

    const handleRemoveRequisionItem = (index) => {
        const generalsritem_set = [...requisionFormData.generalsritem_set];
        setRequisionRequiredMessage('');
    
        // Remove the item from the array
        const removedItem = generalsritem_set.splice(index, 1)[0];
    
        // Remove the corresponding items from selectedRequisionItem and selectedRequisionStock arrays
        const updatedSelectedRequisionItem = selectedRequisionItem.filter((item, i) => i !== index);
        const updatedSelectedRequisionItemCategory = selectedRequisionItemCategories.filter((item, i) => i !== index);
    
        setRequisionFormData({
            ...requisionFormData,
            generalsritem_set,
        });
    
        setSelectedRequisionItem(updatedSelectedRequisionItem);
        setSelectedRequisionItemCategories(updatedSelectedRequisionItemCategory)
    };
    


    const handleRequisionSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/general-sr/`, requisionFormData);
    
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Submit');
                navigate('/user-wise-general-sr-list/');
            }
        } catch (error) {
            console.log(error);
        }
    };

  return {
    navigate,
    requisionRequiredMessage,
    requisionItemCategoriesOptions,
    selectedRequisionItemCategories,
    requisionItemOptions,
    selectedRequisionItem,
    itemSearchInputValue,
    requisionFormData,
    handleRequisionItemCategoryChange,
    handleRequisionItemChange,
    handleItemSearchInputChange,
    handleRequisionInputChange,
    areAllFieldsFilled,
    handleAddRequisionItem,
    handleRemoveRequisionItem,
    handleRequisionSubmit,

  }
}

export default GeneralSRCreateHandleCom