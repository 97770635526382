import React from 'react';
import './CSS/TicketCreatePageTitle.css';
import { AiOutlinePhone, AiOutlineSearch } from 'react-icons/ai';

function TicketCreatePageTitle({ title,value, onChangeSearch, onChangePhoneNo, onSearchClick, onPhoneSearchClick }) {
  return (
    <div>
      <div className="flex justify-between ticket-page-header mb-5">
        <div className="page-title my-auto ml-5">
          <h1 className="uppercase my-1">{title}</h1>
        </div>
        <div className="page-search-and-add-new-button md:flex my-auto mr-5">
          {/* <div className="page-search mr-2 my-1 md:my-0">
            <div className="relative">
              <input
                className="page-search-input w-[150px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                onChange={onChangeSearch}
                placeholder="Search here"
              />
              <div className="text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="button" onClick={onSearchClick}>
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div> */}
          <div className="page-search mr-2">
            <div className="relative">
              <input
                className="page-search-input w-[150px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                value={value}
                onChange={onChangePhoneNo}
                placeholder="Phone Number"
              />
              <div className="text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="button" onClick={onPhoneSearchClick}>
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketCreatePageTitle;
