import React from 'react'
import VehicleFormHandleComponent from './VehicleFormHandleComponent'
import FormPageTitle from '../../../Components/Form/FormPageTitle'
import InputFiled from '../../../Components/Form/InputFiled'
import SelectInput from '../../../Components/Form/SelectInput'
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC'
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC'


const VehicleFormComponent = (
    {formData,
    handleInputChange,
    vehicleTypeOption,
    handleVehicleTypeChange}) => {

  return (
    <div>
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
        <FormPageTitle title='Vehicle Add Form' FormPageRightSideButtonURL='/vehicle-list' LinkName='Back To List Page' />
    
        
         <div className="grid md:grid-cols-3 gap-x-4">

            <div className='my-4'>
                <InputFiledWC
                    type='text' 
                    value={formData?.vehicle_name} 
                    onChange={handleInputChange} 
                    name='vehicle_name' id='vehicle_name'  
                    label='Vehicle Name*' 
                    isRequired={true} 
                    placeholder='Enter vehicle name'  
                    className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>
            <div className='my-4'>
                <InputFiledWC 
                    type='text' 
                    value={formData?.vehicle_number} 
                    onChange={handleInputChange} 
                    name='vehicle_number' id='vehicle_number'  
                    label='Vehicle Number*' 
                    isRequired={true} 
                    placeholder='Enter vehicle number'  
                    className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>


            <div  className='my-4'>
                <SelectInputWC
                    name='vehicle_type'
                    id='vehicle_type'
                    label='Vehicle Type*'
                    placeholder='select vehicle_type'
                    className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                    value={vehicleTypeOption?.find((option)=> option?.value === formData?.vehicle_type)}
                    onChange={handleVehicleTypeChange}
                    options={vehicleTypeOption}
                    isRequired={true}
                />
            </div>
         </div>
    
</div>
</div>
  )
}

export default VehicleFormComponent