import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { authAxiosWithBearer, baseURL } from '../../../../../baseURL';
import ShowToast from '../../../../../Components/ShowToast';


const ATWorkingActivitiesLogFormHandleCom = ({assigned_technician_id,setModalOpen}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        assigned_technician:null,
        manpower : '',
        remarks : '',
      });

    console.log('assigned_technician_id',assigned_technician_id)


    const handleInputChange = (e) => {
      const { name, value } = e.target;

      let inputValue = value;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: inputValue,
      }));
    };
    
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const updateFormData = {
          ...formData,
          assigned_technician: assigned_technician_id
        };
        const response = await authAxiosWithBearer.post(`${baseURL}/ccd/api/assigned-technician-working-activities-logs/`, updateFormData);
        if (response) {
          console.log(response);
          // navigate(`/assigned-technician-add/${assigned_technician_id}`);
          ShowToast('success', 'Successfully Add');
          setModalOpen(false)
        }
      } catch (error) {
        console.log(error);
        if(error.response.data.vehicle_number){
          ShowToast('error', `${error.response.data.vehicle_number}`);
        }else{
          ShowToast('error', 'Something is wrong');
        }
      } finally {
        setLoading(false);
      }
    };


  return (
    {
        navigate,
        loading,
        setLoading,
        formData,
        setFormData,
        handleInputChange,
        handleSubmit,
    }
  )
}

export default ATWorkingActivitiesLogFormHandleCom