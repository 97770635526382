import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../baseURL';
import AssignedTicketViewWithPrint from '../../../Components/AssignedTicketViewWithPrint';

function TicketDetailsWithAssignedTechnician() {
    const [ticketData, setTicketData] = useState(null);
    const {ticket} = useParams()

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    // fetchTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-single-ticket/${ticket}/`);
            console.log(response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [ticket]);

                
  return (
    <div>
        <AssignedTicketViewWithPrint ticketData={ticketData} />
    </div>
  )
}

export default TicketDetailsWithAssignedTechnician