import React, { useEffect, useState } from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import InputFiled from '../../../../Components/Form/InputFiled'
import SelectInput from '../../../../Components/Form/SelectInput'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import DateInput from '../../../../Components/Form/DateInput'
import Checkbox from '../../../../Components/Form/Checkbox'
import InvoiceWiseSCBCcDataGet from '../TicketComponents/InvoiceWiseSCBCcDataGet'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL'

const SCBCC = ({
    setTicketFormData,
    ticketFormData,
    handleTicketInputChange,
    setInvCustomerPhoneNo,
    sbProductCategoryOption,
    sbProductBrandOptions,
    showroomOption,
    autoSCBCC,
 }) => {

    const [selectedSbProductCategory, setSelectedSbProductCategory] = useState(null)
    const [selectedSbProductBrand, setSelectedSbProductBrand] = useState(null);
    const [selectedShowroom, setSelectedShowroom] = useState(null)
    const [basicChargeOption, setBasicChargeOption] = useState(null);
    const [selectedBasicChage, setSelectedBasicChage] = useState(null)

    useEffect(() => {
        const fetchTicketBasicChargeData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/scbcc-ticket-basic-charge/`);
            // console.log(response)
            setBasicChargeOption( response.data.map((data) => ({
                value: data.id,
                label: `${data.title} | BD ${data.amount}`,
                title: data.title,
                amount: data.amount,
            })));      
            } catch (error) {
                console.log(error)
            }
        };
        fetchTicketBasicChargeData();
      }, []);


      const handleBasicChargeChange = (selectedOption) => {
        const sb_basic_charge_title = selectedOption ? selectedOption.title : null;
        const sb_basic_charge_amount = selectedOption ? selectedOption.amount : null;
        
        setSelectedBasicChage(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          sb_basic_charge_title : sb_basic_charge_title,
          sb_basic_charge_amount : sb_basic_charge_amount,
        }));
      };


    const handleSbProductCategoryChange = (selectedOption) => {
        const sbProductCateID = selectedOption ? selectedOption.value : null;
  
        setSelectedSbProductCategory(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          sb_product_category  : sbProductCateID,
        }));
      };
    

    const handleShowroomChange = (selectedOption) => {
        const showroomId = selectedOption ? selectedOption.value : null;
        setSelectedShowroom(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          showroom: showroomId,
        }));
      };


    const handleSbFromDateChange = (date) => {
        setTicketFormData({
          ...ticketFormData,
          purchase_date: date,
        });
      };

      const handleWarrantyExpDateChange = (date) => {
        setTicketFormData({
          ...ticketFormData,
          warranty_exp_date: date,
        });
      };


    const handleSbProductBrandChange = (selectedOption) => {
        const sbProductBrandId = selectedOption ? selectedOption.value : null;
        setSelectedSbProductBrand(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          sb_product_brand: sbProductBrandId,
        }));
      };

    const {
        setInputInvoiceValueForSCBCC,
        invoiceNoGetForSCBCC,
        selectedModelNoForSCBCC,
        modelNoForSCBCCOption,
        selectedShowroomForSCBCC,
        showroomForSCBCCOption,
        selectedSbProductBrandForSCBCC,
        sbProductBrandOptionForSCBCC,
        selectedSbProductCategoryForSCBCC,
        sbProductCategoryOptionForSCBCC,
        handleInvoiceGetForSCBCC,
        handleInvoiceResetForSCBCC,
        handlemodelNoChangeForSCBCC,
        handleShowroomChangeForSCBCC,
        handleSbProductBrandChangeForSCBCC,
        handleSbProductCategoryChangeForSCBCC,
    } = InvoiceWiseSCBCcDataGet({setTicketFormData,setInvCustomerPhoneNo})
  return (
    <div>
        <FormPageTitle title='SCBCC'   />

        {/* invoice wise data get for SCBC start */}
        <form  className='my-4'>
        <div className="flex border">
            <div  className='mb-3 mt-7 mx-2'>
                <InputFiled type='text'
                className={`w-[280px]`} 
                label='Seacrh Invoice' isRequired={true}
                onChange={(e) => setInputInvoiceValueForSCBCC(e.target.value)}
                placeholder='Enter search invoice'/>
            </div>
            <div className='mb-3 mt-7 mx-2'>
            <button onClick={handleInvoiceGetForSCBCC} className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-2'>
                Get Data
            </button>

            <button onClick={handleInvoiceResetForSCBCC} className='bg-[#eec50d] text-[#000] rounded-[5px] px-5 py-2 ml-2'>
                Reset
            </button>
            </div>
        </div>
        </form>
        {/* invoice wise data get for SCBC end */}

        <div className="grid grid-cols-2 lg:grid-cols-3">
        <div  className='my-4 md:mx-auto'>
            <InputFiled type='text'
            className={`w-[99%]`}  
            name='sb_invoice_no' id='sb_invoice_no'
            value={ticketFormData.sb_invoice_no}
            onChange={handleTicketInputChange}
            label='Invoice*' 
            isRequired={autoSCBCC === true? true: false}
            placeholder='Enter invoice number'/>
        </div>

        {invoiceNoGetForSCBCC?
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            name='showroom'
            id='showroom'
            className={`w-[99%]`}
            label='Showroom/Sale Point'
            value={selectedShowroomForSCBCC}
            onChange={handleShowroomChangeForSCBCC}
            options={showroomForSCBCCOption}
            isRequired={false}
            />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
        <SelectInput
            name='showroom'
            id='showroom'
            className={`w-[99%]`}
            label='Showroom/Sale Point'
            value={selectedShowroom}
            onChange={handleShowroomChange}
            options={showroomOption}
            isRequired={false}
        />
        </div>
        }

        <div  className='my-4 md:mx-auto'>
            <InputFiled type='text'  
            className={`w-[99%]`}
            name='dealer_name' id='dealer_name' 
            value={ticketFormData.dealer_name}
            onChange={handleTicketInputChange}
            label='Dealer Name' isRequired={false} 
            placeholder='Enter dealer name'
            />
        </div>

        {/* brand  */}
        {invoiceNoGetForSCBCC?
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            name='sb_product_brand'
            id='sb_product_brand'
            label='Product Brand*'
            className={`w-[99%]`}
            value={selectedSbProductBrandForSCBCC}
            onChange={handleSbProductBrandChangeForSCBCC}
            options={sbProductBrandOptionForSCBCC}
            isRequired={autoSCBCC === true? true: false}
            />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
        <SelectInput
            name='sb_product_brand'
            id='sb_product_brand'
            label='Product Brand*'
            className={`w-[99%]`}
            value={selectedSbProductBrand}
            onChange={handleSbProductBrandChange}
            options={sbProductBrandOptions}
            isRequired={autoSCBCC === true? true: false}
        />
        </div>
        }

        {/* category  */}

        {invoiceNoGetForSCBCC?
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            name='sb_product_category'
            id='sb_product_category'
            label='Product Category*'
            className={`w-[99%]`}
            value={selectedSbProductCategoryForSCBCC}
            onChange={handleSbProductCategoryChangeForSCBCC}
            options={sbProductCategoryOptionForSCBCC}
            isRequired={autoSCBCC === true? true: false}
            />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
        <SelectInput
            name='sb_product_category'
            id='sb_product_category'
            label='Product Category*'
            className={`w-[99%]`}
            value={selectedSbProductCategory}
            onChange={handleSbProductCategoryChange}
            options={sbProductCategoryOption}
            isRequired={autoSCBCC === true? true: false}
        />
        </div>
        }

        {/* model  */}
        {invoiceNoGetForSCBCC?
            <div  className='my-4 md:mx-auto'>
            <SelectInput
            className={`w-[99%]`}
            name='model_name'
            id='model_name'
            label='Model Number *'
            value={selectedModelNoForSCBCC}
            onChange={handlemodelNoChangeForSCBCC}
            options={modelNoForSCBCCOption}
            isRequired={autoSCBCC === true? true: false}
            placeholder={`Select Model`}
            />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
            <InputFiled type='text'  
            className={`w-[99%]`}
            name='model_name' id='model_name'
            value={ticketFormData.model_name}
            onChange={handleTicketInputChange} 
            label='Model Number*' 
            isRequired={autoSCBCC === true? true: false}
            placeholder='Enter model number'
            />
        </div>
        }

        <div  className='my-4 md:mx-auto'>
            <TextareaInput type='text'
            className='w-[99%] h-[41.535px]'  
            name='other_details' id='other_details' 
            value={ticketFormData.other_details}
            onChange={handleTicketInputChange} 
            label='Complaint Details*' 
            isRequired={autoSCBCC === true? true: false}
            placeholder='Enter complaint details'
            />
        </div>
        
        <div  className='my-4 md:mx-auto'>
            <TextareaInput type='text'
            className='w-[99%] h-[41.535px]'  
            name='service_address' id='service_address' 
            value={ticketFormData.service_address}
            onChange={handleTicketInputChange} 
            label='Service Address*' 
            isRequired={autoSCBCC === true? true: false} 
            placeholder='Enter service address'
            />
        </div>

        {invoiceNoGetForSCBCC?
        <div className='my-4 md:mx-auto'>
            <DateInput value={ticketFormData.purchase_date} 
            onChange={handleSbFromDateChange} 
            dateFormat='yyyy-MM-dd' id='purchase_date'
            label='Purchase Date*' 
            isRequired={autoSCBCC === true? true: false}
            placeholder='Select purchase date'
            className={`w-[99%]`}  
            />
        </div>
        :
        <div className='my-4 md:mx-auto'>
        <DateInput value={ticketFormData.purchase_date} 
            onChange={handleSbFromDateChange} 
            dateFormat='yyyy-MM-dd' id='purchase_date'
            label='Purchase Date*' 
            isRequired={autoSCBCC === true? true: false}
            placeholder='Select purchase date'
            className={`w-[99%]`}  
        />
        </div>
        }

        <div  className='my-4 md:mx-auto'>
            <SelectInput
                name='ib_basic_charge'
                id='ib_basic_charge'
                label='Basic Charge*'
                placeholder='basic charge..'
                className={`w-full`}
                value={selectedBasicChage}
                onChange={handleBasicChargeChange}
                options={basicChargeOption}
                isRequired={autoSCBCC === true? true: false}
            />
        </div>

        <div className='my-4 md:mx-auto'>
            <DateInput value={ticketFormData.warranty_exp_date} 
                onChange={handleWarrantyExpDateChange} 
                dateFormat='yyyy-MM-dd' id='warranty_exp_date'
                label='Warranty Exp Date*' 
                isRequired={autoSCBCC === true? true: false}
                placeholder='warranty exp date'
                className={`w-[99%]`}  
            />
        </div>

        <div  className='my-4 border border-[#9d9c9c] rounded-[3px] md:ml-3 px-2 w-[93%] h-[39.535px]'>
            <Checkbox type='checkbox'  
                checked={ticketFormData.warranty_status} 
                onChange={handleTicketInputChange} 
                name='warranty_status' id='warranty_status' label='Warranty Status*' 
                isRequired={autoSCBCC === true? true: false}
                placeholder='warranty_status'
                className={`w-[99%]`}
            />
        </div>

        
        <div  className='my-4 border border-[#9d9c9c] rounded-[3px] md:ml-3 px-2 w-[93%] h-[39.535px]'>
            <Checkbox type='checkbox'  
                checked={ticketFormData.is_dealer} 
                onChange={handleTicketInputChange} 
                name='is_dealer' id='is_dealer' label='Is Dealer' 
                isRequired={false} placeholder='is_dealer'
                className={`w-[99%]`}
            />
        </div>

        </div>
    </div>
  )
}

export default SCBCC