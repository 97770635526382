import React from 'react'
import './../assest/CSS/SalesOrderDetailsComponent.css'
import { Table } from 'antd';
import { formatDate } from './formatDate';
import TextCopyButton from './TextCopyButton';

function SalesOrderDetailsComponent({salesData}) {

    const columns = [
        {
          title: 'Product',
          dataIndex: 'product_name',
          key: 'product_name',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            responsive: ['lg'], 
        },
        {
          title: 'Sales Qty',
          dataIndex: 'sales_qty',
          key: 'sales_qty',
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            responsive: ['lg'],
        },

        {
          title: 'Sales Rate',
          dataIndex: 'sales_rate',
          key: 'sales_rate',
        },

        {
            title: 'Discount Percentage',
            dataIndex: 'discount_percentage',
            key: 'discount_percentage',
            responsive: ['lg'],
          },

        {
          title: 'Discount Amount',
          dataIndex: 'discount_amount',
          key: 'discount_amount',
          responsive: ['md'],
        },
        {
          title: 'Sales Amount',
          dataIndex: 'sales_amount',
          key: 'sales_amount',
          responsive: ['md'],
        },

    ];

    const dataSource = salesData?.sales_product_information_set?.map((productInfo) => ({
        key: productInfo.id,
        product_name: productInfo?.product_information?.name,
        model: productInfo?.product_information?.model,
        product_brand_name: productInfo?.product_information?.product_brand_name,
        product_category_name: productInfo?.product_information?.product_category_name,
        product_sub_category_name: productInfo?.product_information?.product_sub_category_name,
        color: productInfo?.product_information?.color,
        uom: productInfo?.product_information?.uom,
        item_code: productInfo?.product_information?.item_code,
        origin: productInfo?.product_information?.origin,
        garranty_card_no: productInfo?.garranty_card_no,
        specification: productInfo?.product_information?.specification,
        product_serial: productInfo?.product_serial,
        sales_qty: productInfo?.sales_qty,
        sales_rate: productInfo?.sales_rate,
        discount_amount: productInfo?.discount_amount,
        discount_percentage: productInfo?.discount_percentage,
        sales_amount: productInfo?.sales_amount,

      }));



  return (
    <div>
        <div>
            <div className="shadow-lg mb-5 pb-5">
                <div className="add-form-page-header mb-5 mt-10">
                    <div className="add-form-page-title my-auto">
                        <h1 className='uppercase'>Basic Information</h1>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-36 gap-y-2 mx-2">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Invoice No</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>
                            {salesData?.invoice_no}

                            {salesData?.invoice_no && ( 
                                <TextCopyButton text={salesData?.invoice_no} />
                            )}
                        </p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Invoice Date</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>
                            {salesData?.invoice_date && (
                            <>{formatDate(salesData?.invoice_date)}</>
                            )}       
                        </p>
                            
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Invoice Due Date</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>
                        {salesData?.invoice_due_date && (
                            <>{formatDate(salesData?.invoice_due_date)}</>
                        )}   
                        </p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Invoice Type</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.invoice_type}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Sales Type</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.sales_type_text}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Sales Executive</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.sales_executive}</p>
                    </div>
                </div>
            </div>

            <div className="shadow-lg mb-5 pb-5">
                <div className="add-form-page-header mb-5">
                    <div className="add-form-page-title my-auto">
                        <h1 className='uppercase'>Product Information</h1>
                    </div>
                </div>
                
                <div className="mx-2">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => (
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-36 gap-y-2 mx-2'>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Name</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.product_name}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Model</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.model}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Brand</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.product_brand_name}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Category</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.product_category_name}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>P.Sub Category</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.product_sub_category_name}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product color</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.color}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Code</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>
                                        {record?.item_code}
                                            {record?.item_code && ( 
                                                <TextCopyButton text={record?.item_code} />
                                            )}
                                        </p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Origin</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.origin}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Product Serial</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.product_serial}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Garranty Card No</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>
                                        {record?.garranty_card_no}

                                        {record?.garranty_card_no && ( 
                                            <TextCopyButton text={record?.garranty_card_no} />
                                        )}
                                        
                                    </p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Sales Qty</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.sales_qty}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Sales Qty</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.sales_qty}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>UOM</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.uom}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Sales Rate</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.sales_rate}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Discount Amount</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.discount_amount}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Discount Percentage</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.discount_percentage}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Sales_amount</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.sales_amount}</p>
                                </div>
                            </p>
                            <p className="expanded-row-content">
                                <div className='flex flex-wrap'>
                                    <p className='w-[40%] md:w-[35%]'>Specification</p>
                                    <p className='w-[5%] md:w-[5%] '>-</p>
                                    <p className='w-[55%] md:w-[60%] text-end'>{record?.specification}</p>
                                </div>
                            </p>
                        </div>
                        ),
                        rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
                    }}
                    pagination={false}
                    />
                </div>
            </div>

            <div className="shadow-lg mb-5 pb-5">
                <div className="add-form-page-header mb-5">
                    <div className="add-form-page-title my-auto">
                        <h1 className='uppercase'>Delivery Information</h1>
                    </div>
                </div>

                <div className="mx-2">
                    <h1 className='text-[15px] font-semibold' >Customer Information</h1> <hr className='my-2' />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-36 gap-y-2 mx-2">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Name</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.name}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Phone No</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.phone_no}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Alt Mobile</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.customer_alt_mobile}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Email</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.email}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Type</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.customer_type_text}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Division</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.customer_division_name}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer District</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.customer_district_name}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Customer Address</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.customer_info?.address}</p>
                    </div>
                </div>

                <div className="mx-2 my-5">
                    <h1 className='text-[15px] font-semibold' >Shipping Information</h1> <hr className='my-2' />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-36 gap-y-2 mx-2">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Contact Person</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.contact_person_name}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Contact Mobile</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.contact_person_mobile}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Contact Alt Mobile</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.contact_person_alt_mobile}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Division</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.delivery_division_name}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>District</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.delivery_district_name}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Address</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.delivery_address}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Delivery PS</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.delivery_ps}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Delivery PO</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.delivery_po}</p>
                    </div>
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[30%]'>Postal Code</p>
                        <p className='w-[5%] md:w-[5%] text-end'>-</p>
                        <p className='w-[55%] md:w-[65%] text-end'>{salesData?.postal_code}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default SalesOrderDetailsComponent