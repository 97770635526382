import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { formatDateTime } from '../../../Components/formatDateTime';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import DateRangeFiltering from '../../../Components/DateRangeFiltering';

const ApprovedSrList = ({user}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/approved-inv-requisition`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
  
        setTotalItems(count);
        setData(results);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate, currentPage, searchText]);



  const dataSource = data?.flatMap((data,index) =>({
    key: index,
    id:data?.id,
    reqCode: data?.req_code,
    issued: data.issue_status === 'Issued' ? <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div> : data.issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div> : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>,
    created_by_text: data?.created_by_text,
    created_at: data?.created_at,
    ticket_text:data?.ticket_text
  }))


  const handleSearch = (value) => {
    setSearchText(value);
  };


  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket_text',
      key: 'ticket_text',
    },
    {
      title: 'REQ CODE',
      dataIndex: 'reqCode',
      key: 'reqCode',
    },
    {
      title: 'ISSUED STATUS',
      dataIndex: 'issued',
      key: 'issued',
      responsive: ['md']
    },
    {
      title: 'REQUESTED DATE',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        const formattedDateTime = formatDateTime(created_at);
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },
    {
      title: 'REQUESTED BY',
      dataIndex: 'created_by_text',
      key: 'created_by_text',
      responsive: ['md']
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>
          {user?.menu_permissions?.includes("3.3.2") && (
            <button onClick={() => navigate(`/sr-details/${record.id}`)} className='rounded'>
              <AiFillEye className='text-[#6F6F7E] text-xl' />
            </button>
          )}
        </div>
      ),
    },
  ];

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className='page-header mb-[10px] md:mb-5'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto ml-5'>
            <h1 className='uppercase'>Approved  SR List</h1>
          </div>
          <div className='page-search-and-add-new-button md:flex my-auto mr-5'>
            <div className='page-date-range my-auto hidden md:flex'>
              <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            <div className='page-search ml-2'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='page-header px-5 py-5 md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
      </div>
      </div>

      <div className='shadow-lg pb-3'>
      {isLoading ? (
        <p><LoadingSpinner /></p>
        ) : (
        <>
        <Table 
         columns={columns}
         dataSource={dataSource} 
         pagination={false}
         />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
        )}
      </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ApprovedSrList);