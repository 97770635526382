// Sidebars.js
import React, { useState } from 'react';
import { Sidebar, Menu } from 'react-pro-sidebar';
import { FaPlay, FaHome, FaUser, FaCompressArrowsAlt, FaPeopleArrows, FaFileInvoiceDollar } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomSubMenu from './SidebarMenuDesign/CustomSubMenu';
import CustomSubMenuItem from './SidebarMenuDesign/CustomSubMenuItem';
import CustomMenu from './SidebarMenuDesign/CustomMenu';
import { connect } from 'react-redux';
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdCreate, MdEngineering, MdHandshake, MdRequestPage, MdRequestQuote,MdChalet, MdOutlineContactPhone } from 'react-icons/md';
import { IoStorefront } from "react-icons/io5";
import { FaTruckPickup } from "react-icons/fa6";
import { BsHouseGearFill } from "react-icons/bs";

const Sidebars = ({ toggleMenuVisibility,isAuthenticated,user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(true);

  const handleMenuClick = (path) => {
    navigate(path);
  };


  if (!isAuthenticated) {
    return null;
  }
  
  return (
    <div className="flex ">
      {/* Sidebar Section */}
      <div
        className="bg-white mt-[35px] md:mt-[5px] z-40 lg:z-0 text-gray-800 shadow-md fixed h-[100vh] border-[1px]"
      >
        <Sidebar className='overflow-y-auto max-h-[90%]'>
          <Menu>
            <CustomMenu
              label="Dashboard"
              icon={<FaHome size={18} /> }
              onClick={() => handleMenuClick('/home')}
              path="/home"
              currentPath={location.pathname}
            />

          {/* general start  */}
          {user?.menu_permissions?.some(permission => ["1.2.6",].includes(permission)) && (
            <CustomSubMenu label="General" icon={<FaCompressArrowsAlt />}>
              <CustomSubMenuItem
                label="Vehicle List"
                icon={<FaPlay size={10} />}
                onClick={() => handleMenuClick('/vehicle-list')}
                path="/vehicle-list"
                currentPath={location.pathname}
              />
            </CustomSubMenu>
          )}
            {/* general end  */}

          {/* user start */}
          {user?.menu_permissions?.some(permission => ["1.1.6", "1.1.3"].includes(permission)) && (
            <CustomSubMenu label="User" icon={<FaUser />}>
             {user?.menu_permissions?.includes("1.1.6") && (
                  <CustomSubMenuItem
                    label="User List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-list')}
                    path="/user-list"
                    currentPath={location.pathname}
                  />
                )}
              {user?.menu_permissions?.includes("1.1.3") && (
                <CustomSubMenuItem
                    label="User Permission"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-permission')}
                    path="/user-permission"
                    currentPath={location.pathname}
                  />
              )}
              </CustomSubMenu>
            )}
            {/* user end */}

            {/* CRM start */}
            {user?.menu_permissions?.some(permission => ["2.2.6", "2.1.6","2.5.6"].includes(permission)) && (
              <CustomSubMenu label="CCD" icon={<RiCustomerService2Fill />}> 
                  {user?.menu_permissions?.includes("2.2.6") && (
                    <CustomSubMenuItem
                      label="Customer"
                      icon={<FaPlay size={10} />}
                      onClick={() => handleMenuClick('/customer-list')}
                      path="/customer-list"
                      currentPath={location.pathname}
                    />
                  )}
                  {user?.menu_permissions?.includes("2.1.6") && (
                  <CustomSubMenuItem
                      label="Ticket"
                      icon={<FaPlay size={10} />}
                      onClick={() => handleMenuClick('/ticket-list')}
                      path="/ticket-list"
                      currentPath={location.pathname}
                    />
                  )}
                  {user?.menu_permissions?.includes("2.1.6") && (
                  <CustomSubMenuItem
                      label="Showroom Requests"
                      icon={<FaPlay size={10} />}
                      onClick={() => handleMenuClick('/showroom-requests-list')}
                      path="/showroom-requests-list"
                      currentPath={location.pathname}
                    />
                  )}
                  {user?.menu_permissions?.includes("2.5.6") && (
                  <CustomSubMenuItem
                      label="Customer Complain"
                      icon={<FaPlay size={10} />}
                      onClick={() => handleMenuClick('/customer-complain')}
                      path="/customer-complain"
                      currentPath={location.pathname}
                    />
                  )}
              </CustomSubMenu>
            )}
            {/* CRM end */}

            {/* inventory start  */}
              {user?.menu_permissions?.some(permission => ["3.2.6","3.7.6","3.8.6","3.3.6","3.5.6","3.3.5",'3.9.6',"3.4.6","3.6.6",'3.12.6','3.13.6','3.14.6','3.15.6',"3.16.6","3.17.6"].includes(permission)) && (
                <CustomSubMenu label="Inventory" icon={<IoStorefront />}> 

                {user?.menu_permissions?.includes("3.13.6") && (
                  <CustomSubMenuItem
                    label="Sales List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/sales-list')}
                    path="/sales-list"
                    currentPath={location.pathname}
                  />
                )}
                  
                {user?.menu_permissions?.includes("3.12.6") && (
                  <CustomSubMenuItem
                    label="Supplier"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/supplier-list')}
                    path="/item-master"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("3.2.6") && (
                  <CustomSubMenuItem
                    label="Item Master"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/item-master')}
                    path="/item-master"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("3.8.6") && (
                  <CustomSubMenuItem
                    label="Item Stock"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/item-stock-list')}
                    path="/item-stock-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("3.7.6") && (
                  <CustomSubMenuItem
                    label="Package List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/item-package-list')}
                    path="/item-package-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.some(permission => ["3.3.6","3.5.6"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="SR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/sr')}
                    path="/sr"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.some(permission => ["3.14.6","3.14.1"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="UW General SR"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/general-sr')}
                    path="/general-sr"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.some(permission => ["3.3.5"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="Pending SR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/pending-req')}
                    path="/pending-req"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.some(permission => ["3.4.6","3.6.6"].includes(permission)) && (
                    <CustomSubMenuItem
                      label="Issued List"
                      icon={<FaPlay size={10} />}
                      onClick={() => handleMenuClick('/issued-list')}
                      path="/issued-list"
                      currentPath={location.pathname}
                    />
                  )}

                {user?.menu_permissions?.some(permission => ["3.17.6"].includes(permission)) && (
                    <CustomSubMenuItem
                      label="Issued Return List"
                      icon={<FaPlay size={10} />}
                      onClick={() => handleMenuClick('/issued-return-list')}
                      path="/issued-return-list"
                      currentPath={location.pathname}
                    />
                  )}

                {user?.menu_permissions?.some(permission => ["3.9.6"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="PR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/sr-to-pr-list')}
                    path="/sr-to-pr-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.some(permission => ["3.10.6"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="Workorder List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/workorder-list')}
                    path="/workorder-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.some(permission => ["3.11.6"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="GRn List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/grn-list')}
                    path="/grn-list"
                    currentPath={location.pathname}
                  />
                )}
                
                {user?.menu_permissions?.some(permission => ["3.15.6"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="Damage Parts List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/damage-parts-list')}
                    path="/damage-parts-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.some(permission => ["3.16.6"].includes(permission)) && (
                  <CustomSubMenuItem
                    label="UW Damage Parts"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-wise-damage-parts-list')}
                    path="/user-wise-damage-parts-list"
                    currentPath={location.pathname}
                  />
                )}
 
                </CustomSubMenu>
              )}
            {/* inventory end */}

            {/* technician wise ticket  */}
            {user?.menu_permissions?.some(permission => ["4.1.6"].includes(permission)) && (
              <CustomSubMenu label="Technician" icon={<MdEngineering />}> 
                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Ticket List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/technician-wise-ticket-list')}
                    path="/technician-wise-ticket-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Goods Check List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/technician-wise-assigned-goods-check-list')}
                    path="/technician-wise-assigned-goods-check-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Goods Check List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/technician-wise-completed-assigned-goods-check-list')}
                    path="/technician-wise-completed-assigned-goods-check-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned Servicing List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/technician-wise-assigned-servicing-list')}
                    path="/technician-wise-assigned-servicing-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Servicing List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/technician-wise-completed-servicing-list')}
                    path="/technician-wise-completed-servicing-list"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned CDR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/technician-wise-assigned-technician-for-completed-delivery-resource')}
                    path="/technician-wise-assigned-technician-for-completed-delivery-resource"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("4.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Assigned CDR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/completed-delivery-resource/technician-wise-completed-assigned-technician-list')}
                    path="/completed-delivery-resource/technician-wise-completed-assigned-technician-list"
                    currentPath={location.pathname}
                  />
                )}

              </CustomSubMenu>
            )}
            {/* technician wise ticket end */}


            {/* fdo  */}
            {user?.menu_permissions?.some(permission => ["6.1.6","6.2.6","6.3.6"].includes(permission)) && (
              <CustomSubMenu label="FDO" icon={<MdOutlineContactPhone />}> 
                {user?.menu_permissions?.includes("6.1.6") && (
                  <CustomSubMenuItem
                    label="IHSR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/in-house-service-request-list')}
                    path="/in-house-service-request-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("6.3.6") && (
                  <CustomSubMenuItem
                    label="Servicing Disgree List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/servicing-disagree-list-for-fdo')}
                    path="/servicing-disagree-list-for-fdo"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("6.3.6") && (
                  <CustomSubMenuItem
                    label="Completed Servicing List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/completed-servicing-list-for-fdo')}
                    path="/completed-servicing-list-for-fdo"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("6.2.6") && (
                  <CustomSubMenuItem
                    label="Assigned Transport List (Receive)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/assigned-transport-list')}
                    path="/assigned-transport-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("6.2.6") && (
                  <CustomSubMenuItem
                    label="Assigned Transport List (Delivery)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/assigned-transport-for-delivery-list')}
                    path="/assigned-transport-for-delivery-list"
                    currentPath={location.pathname}
                  />
                )}

              </CustomSubMenu>
            )}
            {/* fdo end */}


            {/* in-house  */}
            {user?.menu_permissions?.some(permission => ["8.1.6"].includes(permission)) && (
              <CustomSubMenu label="In House" icon={<BsHouseGearFill />}> 

              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Transported Goods Received List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/transported-goods-received-list')}
                    path="/transported-goods-received-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned Technician List (Goods Check)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/assigned-technician-for-goods-check-list')}
                    path="/assigned-technician-for-goods-check-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Goods Checked List(WPE)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/wpe-completed-assigned-technician-for-goods-check-list')}
                    path="/wpe-completed-assigned-technician-for-goods-check-list"
                    currentPath={location.pathname}
                  />
                )}
              
                {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Goods Checked List(WP)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/wp-completed-assigned-technician-for-goods-check-list')}
                    path="/wp-completed-assigned-technician-for-goods-check-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Servicing Agree List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/servicing-agree-list')}
                    path="/servicing-agree-list"
                    currentPath={location.pathname}
                  />
                )}
              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Servicing Disgree List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/servicing-disagree-list')}
                    path="/servicing-disagree-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned Technician List (Servicing)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/assigned-technician-for-servicing-list')}
                    path="/assigned-technician-for-servicing-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Delivery Assigned Transport List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/completed-delivery-assigned-transport-list')}
                    path="/completed-delivery-assigned-transport-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned Technician List (CDR)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/assignd-technician-list-for-completed-delivery-resource')}
                    path="/assignd-technician-list-for-completed-delivery-resource"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("8.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Assigned Technician List (CDR)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/completed-delivery-resource/complated-assigned-technician-list')}
                    path="/completed-delivery-resource/complated-assigned-technician-list"
                    currentPath={location.pathname}
                  />
                )}


              </CustomSubMenu>
            )}
            {/* in-house end */}

            {/* transport user start */}
            {user?.menu_permissions?.some(permission => ["7.1.6"].includes(permission)) && (
              <CustomSubMenu label="Transport User" icon={<FaTruckPickup />}> 
                {user?.menu_permissions?.includes("7.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned List (Receive)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-wise-assigned-transport-list')}
                    path="/user-wise-assigned-transport-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("7.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned List (Delivery)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-wise-assigned-transport-list-for-delivery')}
                    path="/user-wise-assigned-transport-list-for-delivery"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("7.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Assigned List (Receive)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-wise-completed-assigned-transport-list')}
                    path="/user-wise-completed-assigned-transport-list"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("7.1.6") && (
                  <CustomSubMenuItem
                    label="Completed Assigned List (Delivery)"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/user-wise-completed-assigned-transport-list-for-delivery')}
                    path="/user-wise-completed-assigned-transport-list-for-delivery"
                    currentPath={location.pathname}
                  />
                )}

              </CustomSubMenu>
            )}
            {/* transport user end */}


            {/* billing  */}
            {user?.menu_permissions?.some(permission => ["5.1.6","5.2.6","5.3.6"].includes(permission)) && (
              <CustomSubMenu label="Billing" icon={<FaFileInvoiceDollar/>}> 
                {user?.menu_permissions?.includes("5.1.6") && (
                  <CustomSubMenuItem
                    label="Installations Ticket"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/installations-ticket-list-for-billing')}
                    path="/installations-ticket-list-for-billing"
                    currentPath={location.pathname}
                  />
                )}
                {user?.menu_permissions?.includes("5.1.6") && (
                  <CustomSubMenuItem
                    label="Re-Installations Ticket"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/re-installations-ticket-list-for-billing')}
                    path="/re-installations-ticket-list-for-billing"
                    currentPath={location.pathname}
                  />
                )}
              {user?.menu_permissions?.includes("5.1.6") && (
                  <CustomSubMenuItem
                    label="SCBCC WP Ticket"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/scbcc-wp-ticket-list-for-billing')}
                    path="/scbcc-wp-ticket-list-for-billing"
                    currentPath={location.pathname}
                  />
                )}

                {user?.menu_permissions?.includes("5.1.6") && (
                  <CustomSubMenuItem
                    label="SCBCC WP Expire Ticket"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/scbcc-wp-expire-ticket-list-for-billing')}
                    path="/scbcc-wp-expire-ticket-list-for-billing"
                    currentPath={location.pathname}
                  />
                  )}

                


              
              {user?.menu_permissions?.includes("5.2.6") && (
                  <CustomSubMenuItem
                    label="Approved Customer Invoice List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/approved-customer-invoice-list')}
                    path="/approved-customer-invoice-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("5.2.6") && (
                  <CustomSubMenuItem
                    label="Pending Customer Invoice List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/pending-customer-invoice-list')}
                    path="/pending-customer-invoice-list"
                    currentPath={location.pathname}
                  />
                )}


                {user?.menu_permissions?.includes("5.1.6") && (
                  <CustomSubMenuItem
                    label="Assigned Vendor List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/assigned-vendor-list-for-billing')}
                    path="/assigned-vendor-list-for-billing"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("5.2.6") && (
                  <CustomSubMenuItem
                    label="Approved Vendor Invoice List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/approved-vendor-invoice-list')}
                    path="/approved-vendor-invoice-list"
                    currentPath={location.pathname}
                  />
                )}

               {user?.menu_permissions?.includes("5.2.6") && (
                  <CustomSubMenuItem
                    label="Pending Vendor Invoice List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/pending-vendor-invoice-list')}
                    path="/pending-vendor-invoice-list"
                    currentPath={location.pathname}
                  />
                )}


              {user?.menu_permissions?.includes("5.2.6") && (
                  <CustomSubMenuItem
                    label="Approved HO Invoice List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/approved-ho-invoice-list')}
                    path="/approved-ho-invoice-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("5.2.6") && (
                  <CustomSubMenuItem
                    label="Pending HO Invoice List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/pending-ho-invoice-list')}
                    path="/pending-ho-invoice-list"
                    currentPath={location.pathname}
                  />
                )}


              {user?.menu_permissions?.includes("5.3.6") && (
                  <CustomSubMenuItem
                    label="Customer MR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/customer-mr-list')}
                    path="/customer-mr-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("5.3.6") && (
                  <CustomSubMenuItem
                    label="Vendor MR List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/vendor-mr-list')}
                    path="/vendor-mr-list"
                    currentPath={location.pathname}
                  />
                )}

              {user?.menu_permissions?.includes("5.3.6") && (
                  <CustomSubMenuItem
                    label="HO Billing List"
                    icon={<FaPlay size={10} />}
                    onClick={() => handleMenuClick('/ho-billing-list')}
                    path="/ho-billing-list"
                    currentPath={location.pathname}
                  />
                )}




              </CustomSubMenu>
            )}
            {/* billing */}



            {/* showroom agent  */}
            {user?.primary_role === 8 && (
            <CustomSubMenu label="Ticket Request" icon={<MdHandshake />}> 
                <CustomSubMenuItem
                  label="Add New Request"
                  icon={<FaPlay size={10} />}
                  onClick={() => handleMenuClick('/new-ticket-request')}
                  path="/new-ticket-request"
                  currentPath={location.pathname}
                />
                <CustomSubMenuItem
                  label="Pending List"
                  icon={<FaPlay size={10} />}
                  onClick={() => handleMenuClick('/pending-requested-ticket-list-for-showroom')}
                  path="/pending-requested-ticket-list-for-showroom"
                  currentPath={location.pathname}
                />
                <CustomSubMenuItem
                  label="Completed List"
                  icon={<FaPlay size={10} />}
                  onClick={() => handleMenuClick('/created-requested-ticket-list-for-showroom')}
                  path="/created-requested-ticket-list-for-showroom"
                  currentPath={location.pathname}
                />
            </CustomSubMenu>
            )}
            {/* showroom agent */}

          </Menu>
        </Sidebar>
      </div>

      {/* Content Area */}
      <div className="flex-1 overflow-y-auto p-[5px] z-20">
        {/* Your content goes here */}

        
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(Sidebars);
