import React, { useState } from 'react'
import CustomerInvoiceCreateModal from './CustomerInvoiceCreateModal';

const CustomerInvoiceCreateModalButton = ({
  detailsData,
  srIssuedData,
  scbccWpExpireTicketNo,
  reInstallationTicketNo,
  installationTicketNo,
  modalPageTitle,
}) => {
    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [selectedScbccWpExpireTicketNo, setSelectedScbccWpExpireTicketNo] = useState(null);
    const [selectedreInstallationTicketNo, setSelectedreInstallationTicketNo] = useState(null);
    const [selectedInstallationTicketNo, setSelectedInstallationTicketNo] = useState(null);
  
    console.log('scbccWpExpireTicketNo',scbccWpExpireTicketNo)
    console.log('reInstallationTicketNo',reInstallationTicketNo)
    console.log('selectedScbccWpExpireTicketNo',selectedScbccWpExpireTicketNo)
    console.log('selectedreInstallationTicketNo',selectedreInstallationTicketNo)

    const openInvoiceModal = (ticketNo) => {
      setInvoiceModalOpen(true);
      if(scbccWpExpireTicketNo){
        setSelectedScbccWpExpireTicketNo(ticketNo);
      }
      if(reInstallationTicketNo){
        setSelectedreInstallationTicketNo(ticketNo)
      }
      if(installationTicketNo){
        setSelectedInstallationTicketNo(ticketNo)
      }
    };
  
    const closeInvoiceModal = () => {
      setInvoiceModalOpen(false);
      setSelectedScbccWpExpireTicketNo(null)
      setSelectedreInstallationTicketNo(null)
    };



  return (
    <div>
        <div className='flex justify-center my-3 '>
              <button
                  onClick={() => openInvoiceModal(
                    scbccWpExpireTicketNo?scbccWpExpireTicketNo:reInstallationTicketNo? reInstallationTicketNo : installationTicketNo? installationTicketNo : null
                  )}
                  className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                  >
                  Create Invoice For Customer
              </button>
        </div>

          <CustomerInvoiceCreateModal
                isOpen={isInvoiceModalOpen}
                onClose={closeInvoiceModal}
                scbccWpExpireTicketNo={selectedScbccWpExpireTicketNo}
                reInstallationTicketNo={selectedreInstallationTicketNo}
                installationTicketNo={selectedInstallationTicketNo}
                modalPageTitle={modalPageTitle}
                detailsData={detailsData}
                srIssuedData={srIssuedData}
            />
    </div>
  )
}

export default CustomerInvoiceCreateModalButton