import React, { useEffect } from 'react'
import SectionTitle from './TicketComponents/SectionTitle'
import './TicketComponents/CSS/ticketPage.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormPageTitle from '../../../Components/Form/FormPageTitle'
import CustomerInformation from './TicketComponents/CustomerInformation'
import CallDisposition from './TicketCategoryComponent/CallDisposition'
import TelesalesCRm from './TicketCategoryComponent/TelesalesCRm'
import NonPersonalCRM from './TicketCategoryComponent/NonPersonalCRM'
import OnlineSpecific from './TicketCategoryComponent/OnlineSpecific'
import OnlineQuery from './TicketCategoryComponent/OnlineQuery'
import DealershipQuery from './TicketCategoryComponent/DealershipQuery'
import HirePurchase from './TicketCategoryComponent/HirePurchase'
import CorporatePurchase from './TicketCategoryComponent/CorporatePurchase'
import HVAC from './TicketCategoryComponent/HVAC'
import IJCR from './TicketCategoryComponent/IJCR'
import SCBCC from './TicketCategoryComponent/SCBCC'
import TicketCreateComponent from './TicketComponents/TicketCreateComponent'
import OutBoundCRM from './TicketCategoryComponent/OutBoundCRM'
import ShowToast from '../../../Components/ShowToast';
import { format } from 'date-fns';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import useFetchDetailsData from '../../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import CustomerComplainDetailsComponent from '../CustomerComplain/CustomerComplainComponents/CustomerComplainDetailsComponent';


function TicketCreateForCustomerComplain() {

const     {
  navigate,
  user,
  autoSelectOnlineQuery,
  setAutoSelectOnlineQuery,
  autoSelectDealershipQuery,
  setAutoSelectDealershipQuery,
  autoSelectHirePurchase,
  setAutoSelectHirePurchase,
  autoCorporatePurchase,
  setAutoSelectCorporatePurchase,
  autoHVAC,
  setAutoSelectHVAC,
  autoIJCR,
  setAutoSelectIJCR,
  autoSCBCC,
  setAutoSCBCC,
  autoTelesales,
  setAutoTelesales,
  autoHappyCall,
  setAutoHappyCall,
  autoNonPersonalCRM,
  setAutoNonPersonalCRM,
  autoOnlineSpecific,
  setAutoOnlineSpecific,
  autInboundCRM,
  setAutInboundCRM,
  setAutoSelectOutboundCRM,
  autoSelectOutboundCRM,
  dqProductBrandOptions,
  setdqProductBrandOptions,
  oqProductBrandOptions,
  setOqProductBrandOptions,
  hpProductCategoryOption,
  setHpProductCategoryOption,
  cpProductCategoryOption,
  setCpProductCategoryOption,
  ibProductCategoryOption,
  setIbProductCategoryOption,
  ibShowroomOption,
  setIbShowroomOption,
  sbProductBrandOptions,
  setSbProductBrandOptions,
  ibProductBrandOptions,
  setIbProductBrandOptions,
  obProductBrandOptions,
  sbProductCategoryOption,
  setSbProductCategoryOption,
  tsProductBrandOptions,
  setTsProductBrandOptions,
  tsProductCategoryOption,
  setTsProductCategoryOption,
  showroomOption,
  setShowroomOption,
  ticketCreateLoding,
  setTicketCreateLoding,
  invCustomerPhoneNo,
  setInvCustomerPhoneNo,
  ticketFormData,
  setTicketFormData,
  handlePhoneSearchResult,
  ratingOption,
  YesOrNoOption,
  handleTicketInputChange,
}=TicketCreateComponent()


const {id} = useParams()
const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('ccd/api/customer-complain-details', {id});

useEffect(()=>{
  setInvCustomerPhoneNo(detailsData?.phone_no)
 },[detailsData])

const handleTicketSubmit = async (e) => {
  e.preventDefault();

  if (!ticketFormData.exist_customer) {
    ShowToast('info', 'Please Enter Customer Informations');
    return; // Stop form submission
  }

  // ijcr required filed check
  if (
    autoIJCR === true && 
    !ticketFormData.ib_invoice_no &&         
    !ticketFormData.ib_product_category &&    
    !ticketFormData.model_no &&            
    !ticketFormData.quantity &&            
    !ticketFormData.ib_basic_charge_title &&
    !ticketFormData.ib_basic_charge_amount &&
    !ticketFormData.ib_invoice_date &&
    !ticketFormData.ib_product_brand &&
    !ticketFormData.requested_type 
  ) {
    ShowToast('error', 'Please Enter All IJCR Required Fileds');
    return;
  }

  if (
    autoSCBCC === true && 
    !ticketFormData.sb_invoice_no &&     
    !ticketFormData.sb_product_category &&   
    !ticketFormData.sb_product_brand &&      
    !ticketFormData.model_name &&      
    !ticketFormData.other_details &&
    !ticketFormData.service_address &&              
    !ticketFormData.purchase_date &&
    !ticketFormData.sb_basic_charge_title &&
    !ticketFormData.sb_basic_charge_amount &&
    !ticketFormData.warranty_exp_date &&
    ticketFormData.warranty_status === false
  ) {
    ShowToast('error', 'Please Enter All SCBCC Required Fileds');
    return;
  }


  setTicketCreateLoding(true)
  try {
    const formattedPurchaseDate = 
      ticketFormData.purchase_date ? format(ticketFormData.purchase_date, 'yyyy-MM-dd') : null;
    const formattedAppointmentDate = 
      ticketFormData.appointment_date ? format(ticketFormData.appointment_date, 'yyyy-MM-dd') : null;
    const formattedFollowuUpDate = 
      ticketFormData.follow_up_date ? format(ticketFormData.follow_up_date, 'yyyy-MM-dd') : null;
    const formattedIbInvoiceDate = 
      ticketFormData.ib_invoice_date ? format(ticketFormData.ib_invoice_date, 'yyyy-MM-dd') : null;

    const formattedSbWarrantyExpDate = 
      ticketFormData.warranty_exp_date ? format(ticketFormData.warranty_exp_date, 'yyyy-MM-dd') : null;

    const formWithDate = {
      ...ticketFormData,
      purchase_date: formattedPurchaseDate,
      appointment_date: formattedAppointmentDate,
      follow_up_date: formattedFollowuUpDate,
      ib_invoice_date: formattedIbInvoiceDate,
      warranty_exp_date:formattedSbWarrantyExpDate,
      aproximate_quantity:ticketFormData.aproximate_quantity === '' ? null :ticketFormData.aproximate_quantity,
      quantity:ticketFormData.quantity === '' ? null :ticketFormData.quantity,
      capacity:ticketFormData.capacity === '' ? null :ticketFormData.capacity,
      call_type:autoOnlineSpecific === true? 2 : autoNonPersonalCRM === true? 1 : autoNonPersonalCRM === true && autoOnlineSpecific === true? 1 : '',
      customer_complain_id:id,
    };

    const response = await authAxiosWithBearer.post(`${baseURL}/ccd/api/ticket/`,formWithDate);

    if (response) {
      // console.log(response);
      if(user?.primary_role === 7){
        navigate('/success-response')
      }else{
        navigate('/customer-complain');
      }
      ShowToast('success', 'Successfully Add');
    }
  } catch (error) {
    if (error) {
      ShowToast('error', `${error}`);
      setTicketCreateLoding(false)
    } else {
      console.error('Error submitting ticket:', error);
    }
  } finally {
    // Additional cleanup or actions if needed
  }
};


  if(loading){
    return <div><LoadingSpinner /> </div>
  }

  return (
    <div className='mb-[100px]'>

          <CustomerInformation 
            invCustomerPhoneNo={invCustomerPhoneNo}
            setInvCustomerPhoneNo={setInvCustomerPhoneNo}
            onPhoneSearchResult={handlePhoneSearchResult} 
          />

          <CustomerComplainDetailsComponent detailsData={detailsData} />
          

          <div className="service-category">
            <div className="parent-category">
                <form onSubmit={handleTicketSubmit}>
                <div className=''>
                  <div className='bg-[#fff] shadow-xl pb-3'>
                      <SectionTitle title='Campaign Category ' />
                  </div>
                  
                  <Tabs>

                    <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list bg-[#fff] shadow-xl p-3' >
                      <Tab>
                        <input type="checkbox"
                          name="inboundCRM"
                          id="inboundCRM"
                          checked={autInboundCRM}
                          className='tabs-checkbox'
                          />
                        Inbound CRM</Tab>
                      <Tab>
                        <input type="checkbox"
                          name="outbound"
                          id="outbound"
                          checked={autoSelectOutboundCRM}
                          className='tabs-checkbox'
                          />
                        OutBound CRM
                      </Tab>
                      <Tab>
                        <input type="checkbox"
                          name="telesales"
                          id="telesales"
                          checked={autoTelesales}
                          className='tabs-checkbox'
                          />
                          Telesales CRM
                      </Tab>
                      <Tab>
                        <input type="checkbox"
                          name="happy_call"
                          id="happy_call"
                          checked={autoHappyCall}
                          className='tabs-checkbox'
                          />
                        Happy Call</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="child-category bg-[#fff] shadow-md p-2">
                      <FormPageTitle title='InBound CRM' />
                        <Tabs>
                            <TabList className='grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 tb-list'>
                              <Tab> 
                                <input type="checkbox" className='tabs-checkbox' name="general_query" id="general_query" />
                                General Query
                              </Tab>
                              <Tab>
                                  <input type="checkbox"
                                  name="online_query"
                                  id="online_query"
                                  checked={autoSelectOnlineQuery}
                                  className='tabs-checkbox'
                                  />
                                  Online Query
                              </Tab>
                              <Tab>
                              <input
                                    type="checkbox"
                                    name="dealership_query"
                                    id="dealership_query"
                                    checked={autoSelectDealershipQuery}
                                    className='tabs-checkbox'
                                  />
                                  Dealership Query 
                              </Tab>
                              <Tab>
                                <input 
                                type="checkbox"
                                name="hire_purchase" id="hire_purchase" 
                                checked={autoSelectHirePurchase}
                                className='tabs-checkbox'
                                />
                                Hire Purchase 
                              </Tab>
                              <Tab> 
                                <input type="checkbox" 
                                  name="corporate_purchase" id="corporate_purchase"
                                  checked={autoCorporatePurchase}
                                  className='tabs-checkbox'
                                />
                                Corporate Purchase
                              </Tab>
                              <Tab> 
                                  <input type="checkbox" name="HVAC" id="HVAC"
                                  checked={autoHVAC}
                                  className='tabs-checkbox'
                                  />
                                  HVAC
                              </Tab>

                              <Tab>
                                <input type="checkbox" name="IJCR" id="IJCR"
                                  checked={autoIJCR}
                                  className='tabs-checkbox'
                                  />
                                  IJCR 
                                </Tab>
                              <Tab> 
                                <input type="checkbox"  name="SCBCC" id="SCBCC"
                                checked={autoSCBCC}
                                className='tabs-checkbox'
                                />
                                SCBCC
                              </Tab>
                            </TabList>

                            <TabPanel>
                              <FormPageTitle title='General Query'   />
                            </TabPanel>

                            <TabPanel>
                                <OnlineQuery 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    setAutoSelectOnlineQuery={setAutoSelectOnlineQuery}
                                    oqProductBrandOptions={oqProductBrandOptions}
                                />                   
                            </TabPanel>

                            <TabPanel>
                                <DealershipQuery 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    setAutoSelectOnlineQuery={setAutoSelectOnlineQuery}
                                    dqProductBrandOptions = {dqProductBrandOptions}

                                />
                            </TabPanel>

                            <TabPanel>
                                <HirePurchase 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    hpProductCategoryOption={hpProductCategoryOption}
                                />
                            </TabPanel>

                            <TabPanel>
                                <CorporatePurchase 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    cpProductCategoryOption={cpProductCategoryOption}
                                />
                            </TabPanel>

                            <TabPanel>
                                <HVAC 
                                  ticketFormData={ticketFormData}
                                  handleTicketInputChange={handleTicketInputChange}
                                />
                            </TabPanel>

                            <TabPanel>
                                <IJCR 
                                  setTicketFormData = {setTicketFormData}
                                  ticketFormData={ticketFormData}
                                  handleTicketInputChange={handleTicketInputChange}
                                  setInvCustomerPhoneNo={setInvCustomerPhoneNo}
                                  ibProductCategoryOption={ibProductCategoryOption}
                                  ibProductBrandOptions={ibProductBrandOptions}
                                  ibShowroomOption={ibShowroomOption}
                                  autoIJCR={autoIJCR}
                                />
                            </TabPanel>

                            <TabPanel>
                                <SCBCC 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    sbProductCategoryOption={sbProductCategoryOption}
                                    sbProductBrandOptions={sbProductBrandOptions}
                                    showroomOption={showroomOption}
                                    setInvCustomerPhoneNo={setInvCustomerPhoneNo}
                                    autoSCBCC={autoSCBCC}
                                />
                            </TabPanel>
                            
                          </Tabs>
                      </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="child-category bg-[#fff] shadow-xl p-2 ">
                          <Tabs>
                              <TabPanel>
                                  <OutBoundCRM 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    obProductBrandOptions={obProductBrandOptions}
                                  />
                              </TabPanel>
                            </Tabs>
                        </div>
                    </TabPanel>


                    <TabPanel>                    
                        <TelesalesCRm 
                            setTicketFormData = {setTicketFormData}
                            ticketFormData={ticketFormData}
                            tsProductBrandOptions={tsProductBrandOptions}
                            tsProductCategoryOption={tsProductCategoryOption}
                            handleTicketInputChange={handleTicketInputChange}
                        />
                    </TabPanel>


                    <TabPanel>
                        <div className="child-category bg-[#fff] shadow-xl p-2 ">
                          <Tabs>
                              <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list'>
                                <Tab>
                                <input type="checkbox"
                                  name="non_personal_CRM"
                                  id="non_personal_CRM"
                                  checked={autoNonPersonalCRM}
                                  className='tabs-checkbox'
                                  />
                                  Non Personal CRM</Tab>
                                <Tab>
                                <input type="checkbox"
                                  name="online_specific"
                                  id="online_specific"
                                  checked={autoOnlineSpecific}
                                  className='tabs-checkbox'
                                  />
                                  Online Specific</Tab>
                              </TabList>

                              <TabPanel>
                                  <NonPersonalCRM 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    YesOrNoOption={YesOrNoOption}
                                    ratingOption={ratingOption}
                                  />
                              </TabPanel>

                              <TabPanel>

                                  <OnlineSpecific 
                                    setTicketFormData = {setTicketFormData}
                                    ticketFormData={ticketFormData}
                                    handleTicketInputChange={handleTicketInputChange}
                                    YesOrNoOption={YesOrNoOption}
                                    ratingOption={ratingOption}                                 
                                  />

                              </TabPanel>

                            </Tabs>
                        </div>
                    </TabPanel>

                  </Tabs>

                  </div>

                      <CallDisposition 
                              setTicketFormData = {setTicketFormData}
                              ticketFormData={ticketFormData}
                              YesOrNoOption={YesOrNoOption}
                              handleTicketInputChange={handleTicketInputChange}
                      />
                      

                      <button type='submit' className='bg-[#FA6669]
                        text-[#fff] rounded-[5px] px-10 py-2 my-2'>{ticketCreateLoding ? 'Submitting...' : 'Submit'}</button>
                </form>
              </div>
          </div>
    </div>
  )
}

export default TicketCreateForCustomerComplain


