import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';

const VehicleFormHandleComponent = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        vehicle_number : '',
        vehicle_name : '',
        Vehicle_Type : '',
        vehicle_type : '',
      });

      const vehicleTypeOption = [
        { value: '2 Wheeler', label: '2 Wheeler' },
        { value: '3 Wheeler', label: '3 Wheeler' },
        { value: '4 Wheeler', label: '4 Wheeler' },
    ];

    const handleInputChange = (e) => {
      const { name, value } = e.target;

      let inputValue = value;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: inputValue,
      }));
    };
    
    
    const handleVehicleTypeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setFormData(prevState => ({ ...prevState, vehicle_type: selectedOption.value }));
          console.log('selectedOption',selectedOption)
        } else {
          setFormData(prevState => ({ ...prevState, vehicle_type: null }));
        }
      };


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const response = await authAxiosWithBearer.post(`${baseURL}/api/vehicle/`, formData);
        if (response) {
          console.log(response);
          navigate('/vehicle-list');
          ShowToast('success', 'Successfully Add');
        }
      } catch (error) {
        console.log(error);
        if(error.response.data.vehicle_number){
          ShowToast('error', `${error.response.data.vehicle_number}`);
        }else{
          ShowToast('error', 'Something is wrong');
        }
      } finally {
        setLoading(false);
      }
    };


  return (
    {
        navigate,
        loading,
        setLoading,
        formData,
        setFormData,
        handleInputChange,
        vehicleTypeOption,
        handleVehicleTypeChange,
        handleSubmit,
    }
  )
}

export default VehicleFormHandleComponent