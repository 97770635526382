import React from 'react'
import TotalPackagePrice from './TotalPackagePrice'
import TotalPackagePriceInWord from './TotalPackagePriceInWord'

const TicketWiseAssignedPackageInfoForDetailsComponent = ({packageData}) => {
  return (
    <div className='overflow-x-auto overflow-y-auto p-2'>
        
        <div className='py-1 text-[20px] font-semibold text-[#000000]'>Assigned Package List</div>
        
        <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Item</th>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Package </th>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> PRICE (BDT)  </th>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> TOTAL (BDT) </th>

                </tr>

            </thead>



            <tbody className='text-center'>

                {packageData?.map((item,index)=>(
                    <>
                        <tr >
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{index+1}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.item_name} </td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.package_name} </td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.package_quantity}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.package_price}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{parseFloat(item?.package_quantity * item?.package_price).toFixed(2)}</td>
                        </tr>
                    </>
                ))}



                <tr>
                    <td colSpan="5" className='border-2 border-[#ED6C6C] w-[400px] py-3'>TOTAL AMOUNT</td>
                    <td colSpan="2" className='border-2 border-[#ED6C6C] w-[400px] py-3'><TotalPackagePrice packageData={packageData} /></td>
                </tr>
                {/* eighteen thousand four hundred twenty five taka only */}
                <tr>
                    <td colSpan="8" className='border-2 border-[#ED6C6C] w-[400px] py-3 '>AMOUNT IN WORD - <TotalPackagePriceInWord packageData={packageData} />  </td>
                </tr>

            </tbody>

        </table>
    </div>
  )
}

export default TicketWiseAssignedPackageInfoForDetailsComponent