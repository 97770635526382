import React from 'react'
import HOBillingFromHangleComponent from './HOBillingFromHangleComponent'
import DateInput from '../../../../Components/Form/DateInput'

const HOBillingFormComponent = ({setModalOpen,fetchData}) => {
    const {
        formData,
        setFormData,
        handleFromDateChange,
        handleToDateChange,
        handleSubmit,
    } = HOBillingFromHangleComponent({setModalOpen,fetchData})

  return (
    <div className='mt-[50px]'>
    <form onSubmit={handleSubmit}>
        <div className="grid md:grid-cols-1 gap-2 ">
            <div className='my-4 mx-auto'>
                <DateInput value={formData.from_date} 
                onChange={handleFromDateChange} 
                dateFormat='yyyy-MM-dd' id='from_date'
                label='From Date' isRequired={false} 
                placeholder='Select from date'
                className={`w-[350px]`}  
                />
            </div>
            <div className='my-4 mx-auto'>
                <DateInput value={formData.to_date} 
                onChange={handleToDateChange} 
                dateFormat='yyyy-MM-dd' id='to_date'
                label='To Date' isRequired={false} 
                placeholder='Select to date'
                className={`w-[350px]`}  
                />
            </div>
        </div>

        <div className=" mt-4 bottom-0 flex justify-center">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2 w-[330px]'>Create</button>
            </div>
    </form>
</div>
  )
}

export default HOBillingFormComponent