import React from 'react'
import RequestedTicketListComponent from './ShowroomTicketRequestComponent/RequestedTicketListComponent'

const CreatedRequestedTicketListForShowroom = () => {
  return (
    <div>
        <RequestedTicketListComponent 
            apiEndpoint={'ccd/api/created-requested-ticket-list-for-showroom/'}
            pageTitle={'Completed Requests List'}
            editUrl={``}
        />
    </div>
  )
}

export default CreatedRequestedTicketListForShowroom