import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import DateRangeFiltering from '../../../Components/DateRangeFiltering';

const ItemPackageList = ({user}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ijcrPackageData, setIjcrPackageData] = useState(null);
  const [packageStatus, setPackageStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchIJCRPackageData = async () => {
    try {
      const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/item-packages/`, {
        params: {
          page: currentPage,
          start_date: startDate,
          end_date: endDate,     
          search: searchText    
        },
      });

      const { results, count } = response.data;

      setTotalItems(count);
      setIjcrPackageData(results);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchIJCRPackageData();
  }, [startDate, endDate, currentPage, searchText]);




  const dataSource = ijcrPackageData?.flatMap((data,index) =>({
    key: index,
    id:data?.id,
    package_name: data?.package_name,
    package_category_name: data?.package_category_name,
    package_price: data?.package_price,
    item_category_name: data?.item_category_name,
    item_sub_category_name: data?.item_sub_category_name,
    package_active_status: (
      <div className="flex items-center">
        <input
          type="checkbox"
          id={`toggle-${data.id}`}
          className="hidden"
          checked={data.package_active_status}
          onChange={() => handleTogglePackageStatus(data.id, data.package_name, data.package_active_status)}
        />
        <label htmlFor={`toggle-${data.id}`} className="cursor-pointer">
          <div
            className={`w-12   rounded-full p-1 transition duration-300 ease-in-out ${
              data.package_active_status === true ? 'bg-green-600' : 'bg-red-600'
            }`}
          >
            <div
              className={`bg-white w-5 h-5 rounded-full shadow-md transform ${
                data.package_active_status ? 'translate-x-full' : 'translate-x-0'
              } transition duration-300 ease-in-out`}
            ></div>
          </div>
        </label>
      </div>
    ),
    created_by_text: data?.created_by_text,
    created_at: data?.created_at,
    itempackageparts_set: data?.itempackageparts_set
  }))


  const handleSearch = (value) => {
    setSearchText(value);
  };



  const handleStatusChange = (value) => {
    setPackageStatus(value);
  };


  const handleTogglePackageStatus = async (packageId,package_name, currentStatus) => {
    try {
      await authAxiosWithBearer.put(`${baseURL}/inventory/api/item-packages-status/${packageId}/`, {
        package_name: package_name,
        package_active_status: !currentStatus,
      });
      fetchIJCRPackageData();
    } catch (error) {
      console.error('Error toggling package status:', error);
    }
  };

  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      key: 'package_name',
    },
    {
      title: 'Package Category',
      dataIndex: 'package_category_name',
      key: 'package_category_name',
      responsive: ['md']
    },
    {
      title: 'Package Price',
      dataIndex: 'package_price',
      key: 'package_price',
      responsive: ['md']
    },
    {
      title: 'Created By',
      dataIndex: 'created_by_text',
      key: 'created_by_text',
      responsive: ['md']
    },
    ...(user?.menu_permissions?.includes("3.7.3")
      ? [
          {
            title: 'Package Status',
            dataIndex: 'package_active_status',
            key: 'package_active_status',
          },
        ]
      : []
    ),
    ...(user?.menu_permissions?.includes("3.7.3")
      ? [
        {
          title: 'ACTIONS',
          dataIndex: 'actions',
          render: (_, record) => (
            <div className='flex gap-2'>
              {user?.menu_permissions?.includes("3.7.3") && (
                  <button onClick={() => navigate(`/item-package-update/${record.id}`)} className='rounded'>
                  <AiFillEdit className='text-[#6F6F7E] text-xl' />
                </button>
              )}
            </div>
          ),
        },
        ]
      : []
    ),

  ];


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = format(date, 'dd-M-yyyy');

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;

    return `${formattedDate} | ${formattedTime}`;
  };

  return (
    <>
      <div className='page-header mb-[80px] md:mb-5'>
        <div className="md:flex justify-between py-5">
          <div className='page-title my-auto ml-5 flex justify-between md:justify-start'>
            <h1 className='uppercase text-[5px]'>PACKAGE LIST</h1>

            <div className='status-filter mx-2  md:hidden border rounded-[10px]'>
                <select
                  value={packageStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  className='border-[#D9D9D9] text-[#74747B] font-bold'
                >
                  <option value='all'>All</option>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </select>
              </div>
          </div>

          <div className='px-5 py-5 md:hidden'>
            <DateRangeFiltering  setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
          </div>

          <div className='page-search-and-add-new-button flex justify-between my-auto px-3 md:px-0 md:mr-5 '>
           
           <div className='status-filter mx-2 hidden md:flex border rounded-[5px]'>
                <select
                  value={packageStatus}
                  onChange={(e) => handleStatusChange(e.target.value)}
                  className='border-[#D9D9D9] text-[#74747B] font-bold'
                >
                  <option value='all'>All</option>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                </select>
              </div>

            <div className='page-date-range my-auto hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>

            <div className='page-search mx-2'>
              <div className='relative'>
                <input
                  className='page-search-input w-[220px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
            {user?.menu_permissions?.includes("3.7.1") && (
            <div className="add-new-button px-2">
              <Link className='text-[10px]' to={`/item-package-add`}>Add New</Link>
            </div>
            )}
          </div>
        </div>
      </div>

      <div className='shadow-lg w-[100%] overflow-y-auto pb-3'>
      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
          columns={columns}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <div className='shadow-lg p-4 border'>
                <div className="mx-2">
                    <h1 className='text-[15px] font-semibold' >Package Basic Information</h1> <hr className='my-2' />
                </div>
                <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-4'>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Package Name</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.package_name}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Package Price</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.package_price}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Package Category</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.package_category_name}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Created By</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.created_by_text}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Item Category</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.item_category_name}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Item Sub Category</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.item_sub_category_name}</p>
                    </div>
                </p>

                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Created Date</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{formatDateTime(record?.created_at)}</p>
                    </div>
                </p>


                </div>


                <div className="mx-2">
                    <h1 className='text-[15px] font-semibold' >Package Item Information</h1> <hr className='my-2' />
                </div>
                {record.itempackageparts_set?.map((item)=>(
                <div className='grid md:grid-cols-3 mx-2 my-2 gap-x-48 gap-y-2 border-b pb-2'>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Item Name</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item?.item_name}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Item UOM</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item?.uom}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Quantity</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item.quantity}</p>
                    </div>
                </p>
                </div>
                ))}

            </div>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={dataSource} 
          
         />

        <div className='my-5'>
              <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={10}
              onChange={handlePaginationChange}
              showSizeChanger={false}
            />
        </div>
        </>
      )}
      </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ItemPackageList);