import React from 'react'
import GSRIssueFormHandleCom from './GSRIssueFormHandleCom';
import SelectInputWC from '../../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../../../Components/FormWithoutClass/TextareaInputWC';
import { AiFillDelete } from 'react-icons/ai';
import CustomSubmitButton from '../../../../../Components/Form/CustomSubmitButton';

const GSRIssueFromCom = ({detailsData}) => {
    const {
        requisionItemOptions,
        setRequisionItemOptions,
        selectedRequisionItem,
        setSelectedRequisionItem,
        requisionIssueRequiredMessage,
        setRequisionIssueRequiredMessage,
        requisionIssueFormData,
        setRequisionIssueFormData,
        handleRequisionItemChange,
        handleRequisionIssueInputChange,
        handleAddRequisionIssueItem,
        handleRemoveRequisionIssueItem,
        handleRequisionIssueSubmit,
        isLoading
      }= GSRIssueFormHandleCom({detailsData})

      console.log('requisionItemOptions',requisionItemOptions)
  return (
    <div className='container-fluid grid grid-cols-1 border border-[#EB5757] mt-2'>
      {requisionItemOptions.length > 0 && ( 
        <div className="req-issue-form pb-5 border mb-10 bg-[#fff]">
            <div className='information-head  px-5 mb-5 py-2'>
                <div>Issue Form</div>
            </div>
            <form onSubmit={handleRequisionIssueSubmit} className='overflow-x-auto'>
                {requisionIssueFormData?.srissueditem_set?.map((requisionItems, index) => (
                
                <div className='add-more-item-form border border-[#F35B5B]'>
                    <div className="item-add-form-head border-b bg-[#dbdbdb] flex justify-between py-2">
                        <div className='mx-5 my-auto'>
                            <h1 className=''>Item{index + 1}</h1>
                        </div>
                        <div className='my-auto mx-5'>
                            {requisionIssueFormData?.srissueditem_set?.length > 1 && (
                                <button
                                type="button"
                                onClick={() => handleRemoveRequisionIssueItem(index)}
                                className="text-red-500"
                                >
                                <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600  border border-[#F35B5B]" />
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='grid grid-cols-2 lg:grid-cols-3 mt-2 mx-2 gap-y-2 gap-x-2  px-1'>              
                <div className="my-4">
                    <SelectInputWC
                        className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        label='Item*'
                        id={`gsr_item_${index}`}
                        name={`srissueditem_set[${index}]?.gsr_item`}
                        value={selectedRequisionItem[index]}
                        onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                        options={requisionItemOptions.filter(
                            (option) =>
                                !selectedRequisionItem.some(
                                    (selectedItem) => selectedItem && selectedItem.value === option.value
                                )
                        )}
                        placeholder='Select item'
                        isRequired={true}
                        isClearable={true}
                       
                    />
                </div>

                <div className='my-4'>
                    <InputFiledWC
                        type='text'
                        name='uom'
                        label='UOM*'
                        value={requisionItems?.uom}
                        readOnly={true}
                        placeholder='uom'
                        className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>

                <div className='my-4'>
                    <InputFiledWC
                        type='number'
                        name='item_stock_available_qty'
                        label='C.Stock'
                        value={requisionItems?.item_stock_available_qty}
                        readOnly={true}
                        placeholder='Current Stock qty'
                        className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>

                <div className='my-4'>
                    <InputFiledWC type='number' 
                    name='issue_qty' label='IssueQ*' 
                    id={`issue_qty_${index}`}
                    value={requisionItems.issue_qty}
                    onChange={(e) => handleRequisionIssueInputChange(e, index, 'srissueditem_set')}
                    isRequired={true}
                    // max={requisionItems?.max_issue_qty}
                    // min='0'
                    placeholder='Enter issue qty'
                    className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                    {requisionItems?.max_issue_qty && ( 
                    <p className='text-[11px] text-[#F35B5B] w-[150px]'> Ava.Q: {requisionItems?.max_issue_qty}</p>
                    )}
                </div>
                <div className='my-4'>
                    <TextareaInputWC
                        className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px]  w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]' 
                        label='Remarks' 
                        name='remarks' 
                        id='remarks'
                        placeholder={`Enter remarks`} 
                        value={requisionItems.remarks}
                        onChange={(e) => handleRequisionIssueInputChange(e, index, 'srissueditem_set')}
                    />
                </div>
                    </div>
                </div>
                ))}

                {requisionIssueRequiredMessage && (
                    <span className='text-red-500 mx-4'>{requisionIssueRequiredMessage} <br /> </span> 
                )}
                <div className="flex justify-between mx-4 my-10">

                    <button 
                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                        type="button"
                        onClick={() => {
                            console.log('Add new item Button Clicked');
                            handleAddRequisionIssueItem();
                        }}
                        >ADD MORE</button>
                    
                    <CustomSubmitButton isLoading={isLoading} />
                </div>
            </form>
        </div>
        )}
    </div>
  )
}

export default GSRIssueFromCom