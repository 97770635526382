import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../../baseURL';
import useFetchDetailsData from '../../../../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';

const SRIssuedReturnFormHandleCom = ({issued_id}) => {
    const navigate = useNavigate()
    const id = issued_id
    const [itemOptions, setItemOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [requiredMessage,setRequiredMessage]=useState('')
    const [formValidationErrors, setFormValidationErrors] = useState({});

    const [formData, setFormData] = useState({
        sr_issued_no: null,
        srissuedreturnitem_set: [
          {
            sr_issued_return_item: null,
            item_id: null,
            issue_return_qty:'',
            max_issued_return_qty:'',
            remarks:'',
          },
        ],
    });


    const validateFormData = () => {
      const formValidationErrors = {};
      // Validate damagepartsitem_set fields
      formData.srissuedreturnitem_set.forEach((item, index) => {
        if (!item.sr_issued_return_item) {
          formValidationErrors[`sr_issued_return_item_${index}`] = "Item is required.";
        }
        if (!item.issue_return_qty || item.issue_return_qty <= 0) {
          formValidationErrors[`issue_return_qty_${index}`] = "Quantity is required and should be greater than 0.";
        }
      });
    
      return formValidationErrors;
    };

    

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/sr-issued', {id});
  
    useEffect(()=>{
        const options = detailsData?.srissueditem_set
        ?.filter((data)=>data?.max_issued_return_qty > 0)
        ?.map((data) => ({
            value: data.id,
            label: `${data?.item_name} | ${data?.item_size} | ${data.item_brand}`,
            uom: data.uom_text,
            item_id: data.item,
            quantity:data.quantity,
            max_issued_return_qty:data.max_issued_return_qty,
        }));
        setItemOptions(options);
    },[detailsData])
    

    const handleItemChange = (selectedOption, index) => {
        const updatedFormData = { ...formData };
        
        // Reset issue_return_qty and stock when the item selection changes
        updatedFormData.srissuedreturnitem_set[index] = {
            sr_issued_return_item: selectedOption?.value || null,
            item_id: selectedOption?.item_id || null,
            issue_return_qty: selectedOption?.max_issued_return_qty || '',
            uom: selectedOption?.uom || '',
            max_issued_return_qty: selectedOption?.max_issued_return_qty || '',
        };
        
        const updatedSelectedItem = [...selectedItem];
        updatedSelectedItem[index] = selectedOption || null;
        
        setSelectedItem(updatedSelectedItem);
        setFormData(updatedFormData);

      // Clear the error for the item field being updated
      setFormValidationErrors((prevErrors) => ({
        ...prevErrors,
        [`sr_issued_return_item_${index}`]: '', 
      }));
    };
        

    const handleInputChange = (e, index, type) => {
        const updatedData = { ...formData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'srissuedreturnitem_set' && name === 'issue_return_qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }

        // Remove the error message for this field
        setFormValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if (type === 'srissuedreturnitem_set' && name === 'issue_return_qty') {
              delete newErrors[`issue_return_qty_${index}`];
          } else {
              delete newErrors[name];
          }
          return newErrors;
        });

      
        if (type === 'srissuedreturnitem_set') {
          setRequiredMessage('');
          updatedData.srissuedreturnitem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setFormData(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            formData.srissuedreturnitem_set &&
            formData.srissuedreturnitem_set.every((item) => {
                return item?.sr_issued_return_item !== null && item?.issue_return_qty !== '';
            })
        );
    };
    
      
    const handleAddItem = () => {
        if (areAllFieldsFilled()) {
            setFormData((prevState) => ({
                ...prevState,
                srissuedreturnitem_set: [
                    ...prevState.srissuedreturnitem_set,
                    {
                        sr_issued_return_item: null,
                        issue_return_qty:'',
                    },
                ],
            }));
            setRequiredMessage('');
        } else {
            setRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    


    const handleRemoveItem = (index) => {
      // Create a copy of the damagepartsitem_set array
      const srissuedreturnitem_set = [...formData.srissuedreturnitem_set];
    
      const lastIndex = srissuedreturnitem_set.length - 1;
  
      const lastItemIsNull = srissuedreturnitem_set[lastIndex] && srissuedreturnitem_set[lastIndex].sr_issued_return_item === null;
      
  
      if (index === lastIndex) {
        srissuedreturnitem_set.splice(lastIndex, 1);
      } 
      else if (index !== lastIndex && lastItemIsNull) {
        ShowToast('error','Cannot delete this item because last item is null.');
        return;
      }
      else if (index !== lastIndex && lastItemIsNull === false) {
        srissuedreturnitem_set.splice(index, 1);
      }
    
      const updatedSelectedItem = selectedItem.filter((_, i) => i !== index);
    
      setRequiredMessage('');
    
      setFormData({
        ...formData,
        srissuedreturnitem_set,
      });
      setSelectedItem(updatedSelectedItem);
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateFormData();
        setFormValidationErrors(validationErrors);
      
        if (Object.keys(validationErrors).length > 0) {
          console.log("validationErrors", validationErrors);
          return;
        }

        try {
          const updatedFormData = {
            ...formData,
            sr_issued_no: issued_id,
          };
      
          const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/sr-issued-return/`, updatedFormData);
      
          if (response) {
            ShowToast('success', 'Successfully Submit');
            navigate('/issued-list');
          }
        } catch (error) {
          console.log(error);
          if(error.response.data.error){
            ShowToast('error', `${error.response.data.error}`);
          }else{
            ShowToast('error', 'Something is wrong');
          }
        }
      };


    if(loading){
        return <div><LoadingSpinner /> </div>
    }
      
  return {
    itemOptions,
    setItemOptions,
    selectedItem,
    setSelectedItem,
    requiredMessage,
    setRequiredMessage,
    formData,
    setFormData,
    handleItemChange,
    handleInputChange,
    handleAddItem,
    handleRemoveItem,
    formValidationErrors,
    handleSubmit,
  }
}

export default SRIssuedReturnFormHandleCom