// TicketList.jsx
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import AllShowroomPendingRequestedTicketList from './AllShowroomPendingRequestedTicketList';
import AllShowroomCreatedRequestedTicketList from './AllShowroomCreatedRequestedTicketList';

function ShowroomRequestsList({user}) { 
  return (
    <div className="parent-category">
      <Tabs>
        <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list bg-[#fff] shadow-xl p-3 rounded-[5px]'>
            {user?.menu_permissions?.includes("2.1.1") && (
              <Tab>Pending List</Tab>
            )}
            {user?.menu_permissions?.includes("2.1.1") && (
              <Tab>Completed List</Tab>
            )}
          </TabList>


        {user?.menu_permissions?.includes("2.1.1") && (
        <TabPanel>
            <AllShowroomPendingRequestedTicketList />
        </TabPanel>
        )}
        
        {user?.menu_permissions?.includes("2.1.1") && (
        <TabPanel>
          <AllShowroomCreatedRequestedTicketList />
        </TabPanel>
        )}
      </Tabs>
    </div>

  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ShowroomRequestsList);