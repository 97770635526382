import React, { useEffect, useState } from 'react'
import ListPageHead from './TechnicianComponent/ListPageHead'
import './TechnicianComponent/CSS/ListPageHead.css'
import { Link } from 'react-router-dom'
import { authAxios, authAxiosWithBearer, baseURL } from '../../baseURL'
import { format } from "date-fns";
import useFetchListData from '../../hooks/useFetchListData'
import { formatDateTime } from '../../Components/formatDateTime'
import DateRangeFiltering from '../../Components/DateRangeFiltering'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { AiOutlineSearch } from 'react-icons/ai'
import CustomPaginator from '../../Components/CustomPaginator'
import ListPageHeadWithDateRange from '../../Components/PageHeadComponents/ListPageHeadWithDateRange'

function TechnicianWiseTicketList() {

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`/technician/api/get-technician-wise-ticket-list/`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, endDate,startDate]);



  return (

    <div className='container-fluid grid grid-cols-1 mx-1'>

        <ListPageHeadWithDateRange 
                pageTitle = {'Assigned Ticket List'}
                setEndDate = {setEndDate}
                endDate = {endDate}
                setStartDate = {setStartDate}
                startDate = {startDate}
                setSearchText = {setSearchText}
                // addNewURL = {}
                // addNewUrlPermissionCode = {}
          />
 
        {data?.map((data,index)=>(

        <div className='technician-ticket-card md:flex md:justify-between p-1 mx-2 shadow-lg my-2' key={data.id}>
          
          <div className='w-[100%] md:w-[15%] task'>  
            <div className='flex justify-between md:inline p-2'>
                <div className='md:mt-3 text-center my-auto'>
                    <p className='task-text'>Task {index+1}</p>
                </div>
                <div className=' md:mx-auto status p-1 md:mt-2'>
                    {data?.ticket_status_text}
                </div> 
            </div>       
              
          </div>
          <div className="md:w-[45%] my-3 ml-3 ttc-information">
              <h1 className='mb-2'>TICKET ID - {data?.ticket_no}</h1>
              <p>Customer name     -  {data?.customer_name} </p>
              <p>Customer Address  -  {data?.customer_address}</p>
              <p>Service Category  -   {data?.category}</p>
          </div>
          <div className="md:w-[40%] my-3 ml-3 ttc-information">
                <div className='md:text-end'>
                  <h1 className='mb-2'>Phone no- {data?.customer_phone}</h1>
                  <p>Priority  -  {data?.priority}</p>
                  <p>Assigned Date - {data?.created_at?formatDateTime(data?.created_at):''}</p>
                  <p>Assigned Status - <span className='text-[#e20808] font-semibold'>{data?.sub_status_text}</span></p>

                </div>
                <div className='md:text-end mt-5'>
                    <Link className='ttc-job-start-btn p-2' to={`/technician-wise-ticket-details/${data?.id}`}>View Details</Link>
                </div>
          </div>
        </div>
        ))}


        <div className='my-5'>
              <CustomPaginator 
                  setCurrentPage={setCurrentPage} 
                  currentPage={currentPage}
                  totalItems={totalItems}
              />
          </div>

    </div>
  )
}

export default TechnicianWiseTicketList