import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Checkbox, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { AiFillDelete, AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatDateTime } from '../../../Components/formatDateTime';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import DateRangeFiltering from '../../../Components/DateRangeFiltering';

const SrToPrList = ({user}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsId,setSelectedRowsId] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sr-to-pr/`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
  
        setTotalItems(count);
        setData(results);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate, currentPage, searchText]);

  const dataSource = data?.flatMap((data,index) =>({
    key: index+1,
    id:data?.id,
    prNo: data?.pr_no,
    is_workorder: data.workorder_status === 'Ordered' ? <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Ordered</div> : data.workorder_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div> : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Pending</div>,
    pr_at: data?.pr_at,
    pr_by: data?.pr_by_text,
    is_workorder_status : data.workorder_status
  }))


  const handleSearch = (value) => {
    setSearchText(value);
  };


  const handleDelete = async (itemId) => {
    const result = await Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await authAxiosWithBearer.delete(`${baseURL}/inventory/api/sr-to-pr/${itemId}/`);
        const updatedData = data.filter((item) => item.id !== itemId);
        setData(updatedData);
        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } catch (error) {
        console.log(error);
        Swal.fire('Error', 'An error occurred while deleting the item.', 'error');
      }
    }
  };
  
  
  const handleGoToWorkorderPage = () => {
    navigate(`/pr-to-workorder`, { state: { selectedIds: selectedRowsId } });
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'PR NO',
      dataIndex: 'prNo',
      key: 'prNo',
    },
    {
      title: 'WORK ORDER',
      dataIndex: 'is_workorder',
      key: 'is_workorder',
    },
    {
      title: 'PR BY',
      dataIndex: 'pr_by',
      key: 'pr_by',
      responsive: ['md']
    },
    {
      title: 'PR DATE',
      dataIndex: 'pr_at',
      key: 'pr_at',
      render: (created_at) => {
        return created_at && formatDateTime(created_at);
      },
      responsive: ['md']
    },

    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>
          {user?.menu_permissions?.includes("3.9.2") && (
            <button onClick={() => navigate(`/sr-to-pr-details/${record.id}`)} className='rounded'>
              <AiFillEye className='text-[#6F6F7E] text-xl' />
            </button>
          )}
          {record.is_workorder_status === false && ( 
          <> 
            {user?.menu_permissions?.includes("3.9.4") && (
              <button  onClick={() => handleDelete(record.id)} className='rounded'>
                <AiFillDelete className='text-[#ff4343] text-xl' />
              </button>
            )}
          </>
          )}
        </div>
      ),
    },
  ];



  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>PR LIST</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='page-date-range my-auto hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
            {user?.menu_permissions?.includes("3.9.1") && (
            <div className="add-new-button px-2 ml-1">
                  <Link to={`/direct-pr`}>Add DPR</Link>
            </div>
            )}
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4 md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>

      {user?.menu_permissions?.includes("3.10.1") && (
      <div className="workorder-button mb-2">
            <button className='text-[#F35B5B] border border-[#F35B5B] p-1 font-semibold hover:bg-[#F35B5B] hover:text-[#fff]' onClick={handleGoToWorkorderPage}>Workorder</button>
      </div>
      )}
      <div className='shadow-lg pb-3 overflow-x-auto'>
      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
         columns={columns}
         dataSource={dataSource} 
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => {
              setSelectedRows(selectedRowKeys);
              const selectedIds = selectedRowKeys.map((key) => {
                const selectedRow = dataSource.find((row) => row.key === key);
                return selectedRow ? { id: selectedRow.id, prNo: selectedRow.prNo } : null;
              });
              setSelectedRowsId(selectedIds);
            },
            // hideSelectAll: filteredData?.some((row) => row.is_workorder_status === true),
            getCheckboxProps: (record) => ({
              disabled: record.is_workorder_status === true,
            }),
          }}
         pagination={false}
         />
          <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
      </div>

    </div>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SrToPrList);