// TicketList.jsx
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PendingTicketList from './TicketComponents/PendingTicketList';
import ATechnicianList from './TicketComponents/ATechnicianList';
import './TicketComponents/CSS/ticketPage.css'
import { connect } from 'react-redux';
import CloseTicketList from './TicketComponents/CloseTicketList';


function TicketList({user}) {

  return (
    <div className="parent-category mt-[50px] mb:mt-[0px]">
      <Tabs>
        <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 text-center tb-list bg-[#fff] shadow-xl p-3  rounded-[5px]'>
          {user?.menu_permissions?.includes("2.1.6") && (
            <Tab>TICKET LIST</Tab>
          )}
          {user?.menu_permissions?.includes("2.3.6") && (
            <Tab>ASSIGNED</Tab>
          )}
          {user?.menu_permissions?.includes("2.4.6") && (
            <Tab>CLOSE TICKET</Tab>
          )}
          </TabList>

        {user?.menu_permissions?.includes("2.1.6") && (
        <TabPanel>
            <PendingTicketList />
        </TabPanel>
        )}
        {user?.menu_permissions?.includes("2.3.6") && (
        <TabPanel>
          <ATechnicianList />
        </TabPanel>
        )}
        {user?.menu_permissions?.includes("2.4.6") && (
          <TabPanel>
            <CloseTicketList />
          </TabPanel>
        )}
      </Tabs>
    </div>

  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TicketList);