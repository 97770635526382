import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../Components/Form/SelectInput'
import SectionTitle from './../TicketComponents/SectionTitle'
import './../TicketComponents/CSS/ticketPage.css'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL'



function CallDisposition({
  setTicketFormData,
  ticketFormData,
  YesOrNoOption,
  handleTicketInputChange,
}) {

  const [smsFormateOption, setSmsFormateOption] =useState([]);
  const [selectedSmsFormate, setSelectedSmsFormate] = useState(null)
  const [callRemarksOption, setCallRemarksOption] =useState([]);
  const [selectedCallRemarks, setSelectedCallRemarks] = useState(null)
  const [ticketOption, setTicketOption] =useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [ticketSearchInputValue, setTicketSearchInputValue] = useState(null)

  


      // fetchSmsFormateData
      useEffect(() => {
        const fetchSmsFormateData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-sms-formate/`);
            // console.log(response)
            setSmsFormateOption( response.data.map((data) => ({
                value: data.id,
                label: data.name,
                message: data.massage,
            })));
  
            } catch (error) {
                console.log(error)
            }
        };
        fetchSmsFormateData();
      }, []);


    // fetchCallRemarksData
    useEffect(() => {
      const fetchCallRemarksData = async () => {
          try {
          const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-call-remarks/`);
          // console.log(response)
          setCallRemarksOption( response.data.map((data) => ({
              value: data.id,
              label: data.name,
          })));

          } catch (error) {
              console.log(error)
          }
      };
      fetchCallRemarksData();
    }, []);


    useEffect(() => {
      if (ticketSearchInputValue?.length >= 3) {
        authAxiosWithBearer.get(`${baseURL}/ccd/api/ticket/?search=${ticketSearchInputValue}`)
            .then(response => {
              setTicketOption(response.data.results.map(data => ({
                    value: data.id,
                    label: data.ticket_no
                })));

                console.log(response.data.results)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [ticketSearchInputValue]);

    const handleTicketSearchInputChange = (value) => {
      setTicketSearchInputValue(value);
    };


    const handleTicketChange = (selectedOption) => {
      const related_ticked_id = selectedOption ? selectedOption.value : null;
      setSelectedTicket(selectedOption);
      setTicketFormData((prevFormData) => ({
        ...prevFormData,
        related_ticked: related_ticked_id,
      }));
    };


    const handleCallRemarksChange = (selectedOption) => {
      const call_remarks_id = selectedOption ? selectedOption.value : null;
      setSelectedCallRemarks(selectedOption);
      setTicketFormData((prevFormData) => ({
        ...prevFormData,
        call_remarks: call_remarks_id,
      }));
    };

    const handleSmsFormateChange = (selectedOption) => {
      const sms_formate_id = selectedOption ? selectedOption.value : null;
      const sms_formate_massage = selectedOption ? selectedOption.message : null;
      setSelectedSmsFormate(selectedOption);
      setTicketFormData((prevFormData) => ({
        ...prevFormData,
        sms_formate: sms_formate_id,
        sms_body:sms_formate_massage
      }));
    };

    const handleSendSMSChange = (selectedOption) => {
      if (selectedOption && selectedOption.value !== null) {
        setTicketFormData(prevState => ({ ...prevState, sms_sent: selectedOption.value }));
      } else {
        setTicketFormData(prevState => ({ ...prevState, sms_sent: false }));
      }
    };


  return (
    <div className='bg-[#fff] shadow-xl p-2'>
    <SectionTitle title='Call Disposition'  />
      <div className="mt-5">
          <div className="grid grid-cols-2 lg:grid-cols-3">
              <div  className='my-4 md:mx-auto'>
                  <SelectInput
                    name='call_remarks'
                    id='call_remarks'
                    label='Call Remarks*'
                    className={`w-[99%]`}
                    value={selectedCallRemarks}
                    onChange={handleCallRemarksChange}
                    options={callRemarksOption}
                    isRequired={true}
                  />
              </div>

              <div  className='my-4 md:mx-auto'>
                  <SelectInput
                    name='related_ticked'
                    id='related_ticked'
                    label='Related Ticket'
                    className={`w-[99%]`}
                    value={selectedTicket}
                    onChange={handleTicketChange}
                    onInputChange = {handleTicketSearchInputChange}
                    options={ticketOption}
                    isRequired={false}
                  />
              </div>

              <div  className='my-4 md:mx-auto'>
                  <SelectInput
                    name='sms_sent'
                    id='sms_sent'
                    label='Send SMS*'
                    className={`w-[99%]`}
                    value={YesOrNoOption.find((option)=> option?.value === ticketFormData.sms_sent)}
                    onChange={handleSendSMSChange}
                    options={YesOrNoOption}
                    isRequired={true}
                  />
              </div>

              <div  className='my-4 md:mx-auto'>                                
                  <TextareaInput type='text' 
                    className='h-[41.535px] w-[99%]'
                    name='remarks' id='remarks'
                    value={ticketFormData.remarks}
                    onChange={handleTicketInputChange}
                    label='Agent Remarks*' 
                    isRequired={true}
                    placeholder='Enter Agent Remarks here'/>
              </div>

              <div  className='my-4 md:mx-auto'>
                  <SelectInput
                    name='sms_formate'
                    id='sms_formate'
                    label='Masking*'
                    className={`w-[99%]`}
                    value={selectedSmsFormate}
                    onChange={handleSmsFormateChange}
                    options={smsFormateOption}
                    isRequired={true}
                  />
              </div>

              <div className="my-4 md:mx-auto">
                  <TextareaInput label='Message*'
                  name={`sms_body`}
                  id={`sms_body`}
                  value={ticketFormData.sms_body}
                  onChange={handleTicketInputChange}
                  className='h-[41.535px] w-[99%]' 
                  placeholder={`Enter Message for customer`} 
                  isRequired={true}
                  />
                  
              </div>

              <input name='exist_customer' id='exist_customer' 
                  type="number" hidden
                  value={ticketFormData.exist_customer}
                  onChange={handleTicketInputChange}
                />
        </div>
      </div>
  </div>
  )
}

export default CallDisposition