import React from 'react'
import ATListComponentForCompletedDeliveryResource from './ATForCompletedDeliveryResourceComponents/ATListComponentForCompletedDeliveryResource'

const ATListForCompletedDeliveryResource = () => {
  return (
    <div>
        <ATListComponentForCompletedDeliveryResource 
        title={'Assigned Technician List (CDR)'}
        apiEndPoint={'/in_house/api/assignd-technician-list-for-completed-delivery-resource/'} 
        detailsURL={'completed-delivery-resource/assignd-technician/details'}
        detailsURLPermission={'8.1.2'}
        />
    </div>
  )
}

export default ATListForCompletedDeliveryResource