import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns';
import ShowToast from '../../../Components/ShowToast';
import { AiFillDelete } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../Components/FormWithoutClass/TextareaInputWC';

function PackageSrToPr({user,isModalOpen ,setIsModalOpen ,srID}) {

    const navigate = useNavigate()

    const [requisionItemOptions, setRequisionItemOptions] = useState([]);
    const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
    const [requisionIssueRequiredMessage,setRequisionIssueRequiredMessage]=useState('')
    const [isLoading, setIsLoading] = useState(false)

      const closeModal = () => {
        setIsModalOpen(false);
      };



    console.log('requisionItemOptions',requisionItemOptions)

    const [requisionPrFormData, setRequisionPrFormData] = useState({
        package_sr_no: null,
        srtopritem_set: [
          {
            pr_item: null,
            pr_item_qty:'',
            max_issue_qty:'',
            remarks:'',
          },
        ],
      });
      

      
    const fetchSrData = async () => {
        try {
            setIsLoading(true);
    
            const responsePackage = await authAxiosWithBearer.get(`${baseURL}/ccd/api/package-sr-details/${srID}/`);
    
            const srToPrResponse = await authAxiosWithBearer.get(`${baseURL}/inventory/api/package_srtopr_filtering/${srID}/`);
            const srToPrUniqueItemsMap = new Map();
    
            srToPrResponse.data.forEach((entry) => {
                entry.srtopritem_set.forEach((item) => {
                    const itemId = item.pr_item;
    
                    if (srToPrUniqueItemsMap.has(itemId)) {
                        srToPrUniqueItemsMap.set(itemId, srToPrUniqueItemsMap.get(itemId) + parseFloat(item.pr_item_qty));
                    } else {
                        srToPrUniqueItemsMap.set(itemId, parseFloat(item.pr_item_qty));
                    }
                });
            });
    
            const processedSrToPrItems = Array.from(srToPrUniqueItemsMap, ([pr_item, pr_item_qty]) => ({
                pr_item,
                pr_item_qty: pr_item_qty,
            }));
    
            console.log('processedSrToPrItems', processedSrToPrItems);
    
            const uniqueItems = {};
    
            const requests = responsePackage.data.packageassignedtotechnician_set.map(async (data) => {
                const package_quantity = data?.package_quantity;
                console.log(package_quantity);
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/item-packages/${data.package}/`);
                const itemParts = response.data.itempackageparts_set;
    
                itemParts.forEach((data) => {
                    const itemName = data.item_name;
    
                    if (!uniqueItems[itemName]) {
                        uniqueItems[itemName] = {
                            value: data.item,
                            label: itemName,
                            pr_item_qty: 0,
                            max_issue_qty: 0,
                            uom_value : data.uom
                        };
                    }
    
                    uniqueItems[itemName].pr_item_qty += parseFloat(data.quantity * package_quantity);
                });
            });
    
            // Wait for all the asynchronous calls inside the map to complete
            await Promise.all(requests);

    
            const updatedUniqueItems = Object.values(uniqueItems)
            .map((item) => {
                const processedItem = processedSrToPrItems.find((processedItem) => processedItem.pr_item === item.value);
                item.max_issue_qty = processedItem ? item.pr_item_qty - processedItem.pr_item_qty : item.pr_item_qty;
                return item;
            })
            .filter((item) => item.max_issue_qty > 0);

    
            setRequisionItemOptions(updatedUniqueItems);
            console.log('updatedUniqueItems', updatedUniqueItems);
    
            const initialInvReqDetails = updatedUniqueItems
            .map((option) => ({
                pr_item: option.value,
                pr_item_qty: option.pr_item_qty,
                max_issue_qty: option.max_issue_qty,
                uom_value:option.uom_value,
            }));
    
            console.log('initialInvReqDetails', initialInvReqDetails);
    
            setRequisionPrFormData((prevData) => ({
                ...prevData,
                srtopritem_set: initialInvReqDetails,
            }));
    
            setSelectedRequisionItem(updatedUniqueItems);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        if (srID) {
            fetchSrData();
        }
    }, [srID]);
    
    
    


          

    const handleRequisionItemChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionPrFormData };
        
        updatedFormData.srtopritem_set[index] = {
            pr_item: selectedOption?.value || null,
            max_issue_qty: selectedOption?.max_issue_qty || null,
            pr_item_qty: selectedOption?.pr_item_qty || null,
            uom_value: selectedOption?.uom_value || null,
        };
        
        const updatedSelectedRequisionItem = [...selectedRequisionItem];
        updatedSelectedRequisionItem[index] = selectedOption || null;
        
        setSelectedRequisionItem(updatedSelectedRequisionItem);

        setRequisionPrFormData(updatedFormData);
        };
        

    const handleRequisionPrInputChange = (e, index, type) => {
        const updatedData = { ...requisionPrFormData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'srtopritem_set' && name === 'pr_item_qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }
      
        if (type === 'srtopritem_set') {
          setRequisionIssueRequiredMessage('');
          updatedData.srtopritem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setRequisionPrFormData(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            requisionPrFormData.srtopritem_set &&
            requisionPrFormData.srtopritem_set.every((item) => {
                return item?.pr_item !== null && item?.pr_item_qty !== '';
            })
        );
    };
    
      
    const handleAddRequisionPrItem = () => {
        if (areAllFieldsFilled()) {
            setRequisionPrFormData((prevState) => ({
                ...prevState,
                srtopritem_set: [
                    ...prevState.srtopritem_set,
                    {
                        pr_item: null,
                        pr_item_qty:'',
                    },
                ],
            }));
            setRequisionIssueRequiredMessage('');
        } else {
            setRequisionIssueRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    

    const handleRemoveRequisionPrItem = (index) => {
        const srtopritem_set = [...requisionPrFormData.srtopritem_set];
        setRequisionIssueRequiredMessage('');
    
        // Remove the item from the array
        const removedItem = srtopritem_set.splice(index, 1)[0];
    
        // Remove the corresponding items from selectedRequisionItem and selectedRequisionStock arrays
        const updatedSelectedRequisionItem = selectedRequisionItem.filter((item, i) => i !== index);

        setRequisionPrFormData({
            ...requisionPrFormData,
            srtopritem_set,
        });
    
        setSelectedRequisionItem(updatedSelectedRequisionItem);
    };
    

    const handleRequisionSrToPrSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const upRequisionPrFormData = {
                ...requisionPrFormData,
                package_sr_no: srID
            }
            const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/sr-to-pr/`, upRequisionPrFormData);
            
            console.log('requisionPrFormData',requisionPrFormData)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Submit');
                navigate('/package-sr-list');
            }
        } catch (error) {
            console.log(error);
            ShowToast('error', 'Something is wrong');
        }
    };
    


  return (
    <div>
          <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Insatllation Modal"
              className="modal"
              overlayClassName="overlay"
          >
              <div className='mb-2 w-[360px] md:w-[767px] lg:w-[850px]'>
                  <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
                      <h1 className='font-semibold'>SR TO PR </h1>
                      <button className='text-[#000]' onClick={closeModal}><ImCancelCircle /></button>
                  </div>


                    {isLoading?
                        <p className='mt-10 mb-8'>Loading...</p>
                        :
                        <>
                        {requisionItemOptions.length > 0?
                        <div className="mx-auto my-10 overflow-y-auto max-h-[400px]">
                            <form onSubmit={handleRequisionSrToPrSubmit}>
                                {requisionPrFormData.srtopritem_set?.map((requisionItems, index) => (

                                    <div className="flex  mx-4 mt-2 gap-x-4 gap-y-2 border-b">
                                        <div className="my-4">
                                            <SelectInputWC
                                                label='Item*'
                                                id={`item_${index}`}
                                                name={`srtopritem_set[${index}]?.pr_item`}
                                                value={selectedRequisionItem[index]}
                                                onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                                                options={requisionItemOptions.filter(
                                                    (option) =>
                                                        !selectedRequisionItem.some(
                                                            (selectedItem) => selectedItem && selectedItem.value === option.value
                                                        )
                                                )}
                                                placeholder='Select item'
                                                isRequired={true}
                                                isClearable={true}
                                                className={`appearance-none border-[1px] w-[170px] md:w-[200px] lg:w-[220px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                            />
                                        </div>


                                        <div className='my-4'>
                                            <InputFiledWC type='text'
                                                name='uom_value' label='UOM*'
                                                id={`uom_value${index}`}
                                                value={requisionItems.uom_value}
                                                // onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                                readOnly={true}
                                                placeholder='Enter item qty'
                                                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                            />
                                        </div>

                                        <div className='my-4'>
                                            <InputFiledWC type='number'
                                                name='pr_item_qty' label='Item Qty*'
                                                id={`pr_item_qty${index}`}
                                                value={requisionItems.pr_item_qty}
                                                onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                                isRequired={true}
                                                placeholder='Enter item qty'
                                                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                                max={requisionItems?.max_issue_qty}
                                                min='0'
                                            />
                                        </div>
                                        <div className='my-4'>
                                            <InputFiledWC type='number'
                                                name='pr_item_qty' label='Available Qty*'
                                                id={`pr_item_qty${index}`}
                                                value={requisionItems.max_issue_qty}
                                                onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                                isRequired={true}
                                                placeholder='Enter item qty'
                                                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                                readOnly={true}
                                            />

                                        </div>

                                        <div className='my-4'>
                                            <TextareaInputWC
                                                label='Remarks'
                                                name='remarks'
                                                id='remarks'
                                                placeholder={`Enter remarks`}
                                                value={requisionItems.remarks}
                                                onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                                className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                            />
                                        </div>

                                        <div className='mt-10 mx-10 mb-2'>
                                            {index !== 0 && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveRequisionPrItem(index)}
                                                    className="text-red-500"
                                                >
                                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                ))}

                                {requisionIssueRequiredMessage && (
                                    <span className='text-red-500 mx-4'>{requisionIssueRequiredMessage} <br /> </span>
                                )}
                                <div className="flex justify-between mx-4 my-2">

                                    <button
                                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                                        type="button"
                                        onClick={() => {
                                            console.log('Add new item Button Clicked');
                                            handleAddRequisionPrItem();
                                        }}
                                    >ADD MORE</button>
                                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="mx-auto my-10 overflow-y-auto max-h-[400px]">
                            <p className='text-[20px] text-center text-[#EB5757] mt-6 uppercase font-semibold'>No Availavle Item For SR to Pr</p>
                        </div>
                        }
                    </>

                   }
              </div>

          </Modal>
    </div>
  )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(PackageSrToPr);