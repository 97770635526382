import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import { useReactToPrint } from 'react-to-print';
import VendorInvoiceBasicInfoComponent from '../BillingComponents/VendorInvoiceDetailsComponent/VendorInvoiceBasicInfoComponent';
import VendorInvoicePrintView from '../BillingComponents/VendorInvoiceDetailsComponent/VendorInvoicePrintView';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';

const VendorInvoiceDetails = () => {
  const {id} = useParams()
  const user = useSelector(state => state.auth.user);
  const [detailsData, setDetailsData] = useState(null);
  const [loading,setLoading]= useState(true)

  const fetchDetailsData = async () => {
    try {
      const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/vendor-invoice-details/${id}/`);
      setDetailsData(response.data);
    } catch (error) {
      console.log(error);
      if (id && error.response && error.response.status === 404) {
        ShowToast('error', 'Data not found');
      }
    }
    finally{
      setLoading(false)
    }
    
  };

  useEffect(() => {
      fetchDetailsData();
  }, [id]);


  const componentPDF = useRef();
  const pdfGeneretor = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Esquire-CRM",
    pageStyle: `
      @page landscape {
        size: A4 landscape;
        margin: 20mm 10mm;
      }
      @page portrait {
        size: A4 portrait;
        margin: 20mm 10mm;
      }
      @page {
        margin: 4mm;
      }
      body {
        margin: 0;
      }
    `,
    contentStyle: `
      @page landscape {
        transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
        transform: rotate(90deg) translate(0, 0);
      }
    `
  });



  if (loading) {
    return <div><LoadingSpinner /></div>;
  }

  return (
    <div>
      <div className='border border-[#EB5757] sm:mx-3 my-3' >
          <VendorInvoiceBasicInfoComponent detailsData={detailsData} id={id} fetchDetailsData={fetchDetailsData} />
          <div className='flex justify-center my-3 '>
              <button 
              className='bg-[#FA6669] text-[#FFF] px-6 py-2 rounded-md' 
                onClick={pdfGeneretor}
              >Print</button>
          </div>
      </div>

      <VendorInvoicePrintView detailsData={detailsData} componentPDF={componentPDF} />


    </div>
  )
}

export default VendorInvoiceDetails