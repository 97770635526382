import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'
import TotalPayableAmountInWord from './TotalPayableAmountInWord'
import BillingInvoiceApprovedStatusChange from '../BillingInvoiceApprovedStatusChange'
import MrCreateModalButton from '../MrCreateForm/MrCreateModalButton'
import { useSelector } from 'react-redux'

const VendorInvoiceBasicInfoComponent = ({detailsData,id,fetchDetailsData}) => {
    const user = useSelector(state => state.auth.user)
  return (
    <div>
            {/* 1st content */}
            <div className='bg-[#FDECEC] my-1 flex justify-between'>
                <div className='py-2 text-[20px] font-semibold text-[#E74A3B] pl-10'>INVOICE DETAILS</div>
        
                <div className='py-2 flex'>
                        <div>

                            {user?.menu_permissions?.includes("5.3.2") && detailsData?.due_amount !== '0.00' &&(
                             <MrCreateModalButton id={id} detailsData={detailsData} fetchDetailsData={fetchDetailsData} ActionURL={`billing/api/vendor-mr-create`} redirectURL={`vendor-invoice-details`} />
                            )}
                        </div>
                        <div>
                            {user?.menu_permissions?.includes("5.2.5") && detailsData?.invoice_approved_satatus === false &&(
                                
                                <BillingInvoiceApprovedStatusChange id={id} ActionURL={`billing/api/vendor-invoice-approved-status-change`} redirectURL={'pending-vendor-invoice-list'}  />
                            )}
                        </div>
                </div>
            </div>



            {/* 2nd */}

            <div className='border border-[#EB5757] mx-1 my-2'>

                <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                    <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                        <div className='bg-[#EB5757] '>

                            <div className='flex justify-center items-center 8 sm:py-14  md:py-14 lg:py-10'>
                                <div className='text-[#FFFFFF] text-[16px] font-semibold '>
                                    <div className='text-center uppercase'>Bill Invoice No</div>
                                    <div className='text-center'>{detailsData?.bill_invoice}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                            <div className='mx-1 sm:mx-0'>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>VendorID</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.vendor_id}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Vendor</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.vendor_name}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Vendor Phone</div>
                                        <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{detailsData?.vendor_phone}</div>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd */}

                            <div className='mx-1 sm:mx-0'>
                                <div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Ticket</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.ticket_no}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Invoice Created At</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Payment Status</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.payment_status === "Paid" ? 
                                                    <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Paid</div> 
                                                    : detailsData?.payment_status === "Unpaid" ?
                                                     <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Unpaid</div>
                                                    :
                                                    <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div>
                                                    }
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className='overflow-x-auto overflow-y-auto p-2'>
        
        <div className='py-1 text-[20px] font-semibold text-[#000000]'>Billing Information</div>
        
        <table className='table-auto border-2 border-[#ED6C6C] border-collapse min-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Title</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Amount</th>

                </tr>

            </thead>



            <tbody className='text-center'>

                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Amount</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.basic_amount} TK</td>
                </tr>
                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Amount Quantity</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.basic_amount_quantity} Pis</td>
                </tr>
                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Additional Amount</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.additional_amount} TK</td>
                </tr>

                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>Total Amount</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'>{detailsData?.total_payable_amount} TK</td>
                </tr>

                <tr>
                    <td  className='border-2 border-[#ED6C6C] w-[400px] py-3'>AMOUNT IN WORD</td>
                    <td  className='border-2 border-[#ED6C6C] text-right w-[400px] py-3'> <TotalPayableAmountInWord total_payable_amount={detailsData?.total_payable_amount} /></td>
                </tr>

                


            </tbody>

        </table>
    </div>
    </div>
  )
}

export default VendorInvoiceBasicInfoComponent