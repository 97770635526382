import React, { useState, useMemo, useCallback } from 'react';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';

const CustomSubMenu = ({ label, icon, children }) => {
  const [isActive, setActive] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);

  const toggleCollapse = useCallback(() => {
    setCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const handleMouseEnter = useCallback((event) => {
    if (!isCollapsed) return;

    const menuItem = event.currentTarget;
    if (!menuItem) return;

    menuItem.style.backgroundColor = '#F35B5B';
    menuItem.style.color = '#fff';

    const submenuIcon = menuItem.querySelector('.submenu-icon');
    if (submenuIcon) submenuIcon.style.color = '#fff';
  }, [isCollapsed]);

  const handleMouseLeave = useCallback((event) => {
    if (!isCollapsed) return;

    const menuItem = event.currentTarget;
    if (!menuItem) return;

    menuItem.style.backgroundColor = '#fff';
    menuItem.style.color = '#6F6F7E';

    const submenuIcon = menuItem.querySelector('.submenu-icon');
    if (submenuIcon) submenuIcon.style.color = '#6F6F7E';
  }, [isCollapsed]);

  const handleItemClick = useCallback(() => {
    setActive(!isActive);
  }, [isActive]);

  const submenuTransitionClass = useMemo(() => {
    return isActive ? 'submenu-transition' : '';
  }, [isActive]);

  return (
    <div>
      <div
        className='my-4 mx-5'
        onClick={toggleCollapse}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px',
          backgroundColor: '#fff',
          color: '#6F6F7E',
          transition: 'background-color 0.5s ease-in-out, color 0.5s ease-in-out',
          borderRadius: '8px',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {icon && React.cloneElement(icon, { className: 'submenu-icon', size: 18, color: '#6F6F7E', style: { marginRight: '8px' } })}
          <span style={{ fontWeight: '500' }}>{label}</span>
        </span>
        <span>
          {isCollapsed ? (
            <FaPlusCircle size={14} className="fa-plus-circle" />
          ) : (
            <FaMinusCircle size={14} className="fa-minus-circle" />
          )}
        </span>
      </div>
      <CSSTransition
      in={!isCollapsed}
      timeout={{
        appear: 500,
        enter: 200,
        exit: 200,
      }}
      classNames={submenuTransitionClass}
      unmountOnExit
    >
      <div style={{ paddingLeft: '16px' }} onClick={handleItemClick}>
        {children}
      </div>
    </CSSTransition>
    </div>
  );
};

export default CustomSubMenu;
