import React, { useEffect, useState } from 'react';
import ShowToast from '../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { useNavigate } from 'react-router-dom';

function ItemPackageFormComponent() {
  const navigate = useNavigate();
  const [requiredMessage, setRequiredMessage] = useState('');

  const [categoriesOption, setCategoriesOption] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategoriesOption, setSubCategoriesOption] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [packageCategoriesOption, setPackageCategoriesOption] = useState(null);
  const [selectedPackageCategory, setSelectedPackageCategory] = useState(null);

  const [itemOptions, setItemOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const [itemSearchInputValue, setItemSearchInputValue] = useState(null);

  const [packageForm, setPackageForm] = useState({
    package_name: '',
    package_category: null,
    package_price: '',
    item_category: null,
    item_sub_category: null,
    itempackageparts_set: [
      {
        item: null,
        quantity: '',
        item_unit_price: '',
        total_item_price: '',
      },
    ],
  });

  useEffect(() => {
    const fetchPackageCategoriesData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/item-packages-categories/`);
        setPackageCategoriesOption(
          response?.data?.results?.map((data) => ({
            value: data.id,
            label: data.package_category_name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchPackageCategoriesData();
  }, []);

  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-categories-list/`);
        setCategoriesOption(
          response?.data?.results?.map((data) => ({
            value: data.id,
            label: data.name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategoriesData();
  }, []);

  useEffect(() => {
    const fetchSubCategoriesData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-sub-categories-list/`);
        setSubCategoriesOption(
          response?.data?.results?.map((data) => ({
            value: data.id,
            label: data.name,
            category: data.category,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubCategoriesData();
  }, []);

  const handlePackageCategoriesChange = (selectedOption) => {
    const cate_id = selectedOption ? selectedOption.value : null;
    setSelectedPackageCategory(selectedOption);
    setPackageForm((prevFormData) => ({
      ...prevFormData,
      package_category: cate_id,
    }));
  };

  const handleCategoriesChange = (selectedOption) => {
    const cate_id = selectedOption ? selectedOption.value : null;
    setSelectedCategory(selectedOption);
    setPackageForm((prevFormData) => ({
      ...prevFormData,
      item_category: cate_id,
    }));
    setSelectedSubCategory(null);
  };

  const handleSubCategoryChange = (selectedOption) => {
    const sub_cate_id = selectedOption ? selectedOption.value : null;
    setSelectedSubCategory(selectedOption);
    setPackageForm((prevFormData) => ({
      ...prevFormData,
      item_sub_category: sub_cate_id,
    }));
  };

  const filteredSubCategories = subCategoriesOption?.filter((subCategory) => subCategory?.category === selectedCategory?.value);



  useEffect(() => {
      authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
          .then(response => {

                const filteredItemOptions = response.data.results.map((data) => {
                  const isSelected = selectedItem.find((selected) => selected.value === data.id);
        
                  return {
                    value: data.id,
                    label: `${data.item_name} | ${data.size}`,
                    unitPrice: data.item_sales_price,
                    uom: data.uom_text,
                    isAlreadySelected: isSelected,
                  };
                });
                setItemOptions(filteredItemOptions);

              console.log(response.data.results)
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
  }, [itemSearchInputValue,selectedItem]);
  


  const handleItemSearchInputChange = (value) => {
    setItemSearchInputValue(value);
  };


  const handleItemChange = async (selectedOption, index) => {
    const updatedFormData = { ...packageForm };
  
    updatedFormData.itempackageparts_set[index] = {
      item: selectedOption?.value || null,
      quantity: '',
      item_unit_price: selectedOption?.unitPrice || '',
      uom: selectedOption?.uom || '',
      total_item_price: '', // Initialize total_item_price to an empty string
    };
  
    const updatedSelectedItem = [...selectedItem];
    updatedSelectedItem[index] = selectedOption || null;
  
    setSelectedItem(updatedSelectedItem);
    setPackageForm(updatedFormData);
  
    // Calculate the total immediately after setting the selected item
    calculateTotalItemUnitPrice(updatedFormData.itempackageparts_set);
  };
  

  const validateInput = (value, name) => {
    const parts = value.split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';
  
    // If the total number of digits in the integer part exceeds 18, show an error message
    if (integerPart.length > 18) {
      ShowToast('error', `Ensure that there are no more than 18 digits for ${name}`);
      return;
    }
  
    // If decimal part exists, limit it to 2 decimal places
    if (parts.length === 2) {
      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
  
      // If more than 2 digits after the decimal point, show a separate error message
      if (decimalPart.length > 2) {
        ShowToast('error', `More than 2 digits after the decimal point for ${name}`);
      }
    }
  
    return value;
  };
  

  const handlePackagePriceInputChange = (e) => {
    const { value } = e.target;
  
    const validatedValue = validateInput(value, 'Package Price');

    if (validatedValue !== undefined) {
      setPackageForm((prevFormData) => ({
        ...prevFormData,
        package_price: validatedValue,
      }));
    }
  };
   

  const handleInputChange = (e, index, type) => {
    const updatedData = { ...packageForm };
    const { name } = e.target;
    let value = e.target.value;

    if (name === 'total_item_price') {

      const parts = value.split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1] || '';
    
      // If the total number of digits in the integer part exceeds 18, show an error message
      if (integerPart.length > 18) {
        ShowToast('error', `Ensure that there are no more than 18 digits for ${name}`);
        return;
      }
    
      // If decimal part exists, limit it to 2 decimal places
      if (parts.length === 2) {
        value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    
        // If more than 2 digits after the decimal point, show a separate error message
        if (decimalPart.length > 2) {
          ShowToast('error', `More than 2 digits after the decimal point for ${name}`);
        }
      }
    
  
      // Recalculate package_price based on the modified total_item_price
      const totalItemPrices = updatedData.itempackageparts_set.map((part) =>
        part.total_item_price ? parseFloat(part.total_item_price) : 0
      );
  
      const newPackagePrice = totalItemPrices.reduce((total, price) => total + price, 0);
      updatedData.package_price = newPackagePrice.toFixed(2);
    }
  
    if (name === 'quantity') {

      const parts = value.split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1] || '';
    
      // If the total number of digits in the integer part exceeds 18, show an error message
      if (integerPart.length > 18) {
        ShowToast('error', `Ensure that there are no more than 18 digits for ${name}`);
        return;
      }
    
      // If decimal part exists, limit it to 2 decimal places
      if (parts.length === 2) {
        value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    
        // If more than 2 digits after the decimal point, show a separate error message
        if (decimalPart.length > 2) {
          ShowToast('error', `More than 2 digits after the decimal point for ${name}`);
        }
      }
    
  
      // Recalculate total_item_price based on the modified quantity
      const quantity = parseFloat(value);
      const unitPrice = parseFloat(updatedData.itempackageparts_set[index].item_unit_price || 0);
      const totalItemPrice = unitPrice * quantity;
  
      updatedData.itempackageparts_set[index].total_item_price = totalItemPrice.toFixed(2);
    }
  
    if (name === 'package_price' || name === 'item_unit_price') {
      // ... (existing code for digit validation, etc.)

      const parts = value.split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1] || '';
    
      // If the total number of digits in the integer part exceeds 18, show an error message
      if (integerPart.length > 18) {
        ShowToast('error', `Ensure that there are no more than 18 digits for ${name}`);
        return;
      }
    
      // If decimal part exists, limit it to 2 decimal places
      if (parts.length === 2) {
        value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    
        // If more than 2 digits after the decimal point, show a separate error message
        if (decimalPart.length > 2) {
          ShowToast('error', `More than 2 digits after the decimal point for ${name}`);
        }
      }
    
  
      // Calculate total_item_price if both item_unit_price and quantity are available
      if (name === 'item_unit_price' && updatedData.itempackageparts_set[index].quantity !== '') {
        const unitPrice = parseFloat(value);
        const quantity = parseFloat(updatedData.itempackageparts_set[index].quantity);
        const totalItemPrice = unitPrice * quantity;
        updatedData.itempackageparts_set[index].total_item_price = totalItemPrice.toFixed(2);
      } else if (name === 'quantity' && updatedData.itempackageparts_set[index].item_unit_price !== '') {
        const quantity = parseFloat(value);
        const unitPrice = parseFloat(updatedData.itempackageparts_set[index].item_unit_price);
        const totalItemPrice = unitPrice * quantity;
        updatedData.itempackageparts_set[index].total_item_price = totalItemPrice.toFixed(2);
      }
    }
  
    if (type === 'itempackageparts_set') {
      setRequiredMessage('');
      updatedData.itempackageparts_set[index][name] = value;
    } else {
      updatedData[name] = value;
    }
  
    setPackageForm(updatedData);
    calculateTotalItemUnitPrice(updatedData.itempackageparts_set);
  };
  

  const calculateTotalItemUnitPrice = (itemPackageParts) => {
    const totalItemPrice = itemPackageParts.reduce((total, part) => {
      return total + (part.total_item_price ? parseFloat(part.total_item_price) : 0);
    }, 0);

    setPackageForm((prevFormData) => ({
      ...prevFormData,
      package_price: totalItemPrice.toFixed(2),
    }));
  };


  const areAllFieldsFilled = () => {
    return (
      packageForm.itempackageparts_set &&
      packageForm.itempackageparts_set.every((item) => {
        return item?.item !== null && item?.quantity !== '' && item?.item_unit_price !== '';
      })
    );
  };

  const handleAddMoreItem = () => {
    if (areAllFieldsFilled()) {
      setPackageForm((prevState) => ({
        ...prevState,
        itempackageparts_set: [
          ...prevState.itempackageparts_set,
          {
            item: null,
            quantity: '',
            item_unit_price: '',
            total_item_price: '',
          },
        ],
      }));
      setRequiredMessage('');
    } else {
      setRequiredMessage('Please fill all fields in the existing field before adding a new one.');
    }
  };

  const handleRemoveItem = (index) => {
    const itempackageparts_set = [...packageForm.itempackageparts_set];
    setRequiredMessage('');
  
    const removedItem = itempackageparts_set.splice(index, 1)[0];
  
    const updatedSelectedRequisionItem = selectedItem.filter((item, i) => i !== index);
  
    setPackageForm({
      ...packageForm,
      itempackageparts_set,
    });
  
    setSelectedItem(updatedSelectedRequisionItem);
  
    calculateTotalItemUnitPrice(itempackageparts_set);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/item-packages/`, packageForm);

      if (response) {
        console.log(response);
        ShowToast('success', 'Successfully Submit');
        navigate('/item-package-list');
      }
    } catch (error) {
      console.log(error);
      ShowToast('error', 'Something is wrong');
    }
  };

  return {
    navigate,
    requiredMessage,
    categoriesOption,
    setCategoriesOption,
    selectedCategory,
    setSelectedCategory,
    subCategoriesOption,
    setSubCategoriesOption,
    selectedSubCategory,
    setSelectedSubCategory,
    packageCategoriesOption,
    setPackageCategoriesOption,
    selectedPackageCategory,
    setSelectedPackageCategory,
    itemOptions,
    setItemOptions,
    selectedItem,
    setSelectedItem,
    packageForm,
    setPackageForm,
    handleCategoriesChange,
    handleSubCategoryChange,
    handlePackageCategoriesChange,
    filteredSubCategories,
    handlePackagePriceInputChange,
    handleItemChange,
    handleInputChange,
    areAllFieldsFilled,
    handleAddMoreItem,
    handleRemoveItem,
    handleSubmit,
    handleItemSearchInputChange
  };
}

export default ItemPackageFormComponent;
