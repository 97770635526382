import React from 'react'
import SelectInputWC from '../../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../../../Components/FormWithoutClass/TextareaInputWC';
import { AiFillDelete } from 'react-icons/ai';
import SRIssuedReturnFormHandleCom from './SRIssuedReturnFormHandleCom';
import FormPageTitle from '../../../../../Components/Form/FormPageTitle';

const SRIssuedReturnFormCom = ({issued_id,title}) => {
    const 
        {
            itemOptions,
            setItemOptions,
            selectedItem,
            setSelectedItem,
            requiredMessage,
            setRequiredMessage,
            formData,
            setFormData,
            handleItemChange,
            handleInputChange,
            handleAddItem,
            handleRemoveItem,
            formValidationErrors,
            handleSubmit,
          }=SRIssuedReturnFormHandleCom({issued_id})
    
  return (
    <>
    {itemOptions?.length > 0?
        <div className="req-issue-form shadow-xl pb-5 border mx-4 my-10 bg-[#fff] overflow-x-auto">
            <FormPageTitle title={title} FormPageRightSideButtonURL='/sr-issued-list' LinkName='Close' />

            <form onSubmit={handleSubmit}>
                {formData.srissuedreturnitem_set?.map((requisionItems, index) => (

                <div className='add-more-item-form border border-[#F35B5B]'>
                    <div className="item-add-form-head border-b bg-[#dbdbdb] flex justify-between py-2">
                    <div className='mx-5 my-auto'>
                        <h1 className=''>Item{index + 1}</h1>
                    </div>
                    <div className='my-auto mx-5'>
                        {formData?.srissuedreturnitem_set?.length > 1 && (
                            <button
                            type="button"
                            onClick={() => handleRemoveItem(index)}
                            className="text-red-500"
                            >
                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600  border border-[#F35B5B]" />
                            </button>
                        )}
                    </div>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2  mx-4 mt-2 gap-x-1 gap-y-2 border-b">              
                    <div className="my-4">
                        <SelectInputWC
                            className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                            label='Item*'
                            id={`sr_issued_return_item_${index}`}
                            name={`srissuedreturnitem_set[${index}]?.sr_issued_return_item`}
                            value={selectedItem[index]}
                            onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                            options={itemOptions}
                            placeholder='Select item'
                            // isRequired={true}
                            isClearable={true}
                        />
                        {formValidationErrors[`sr_issued_return_item_${index}`] && <span className='text-blue-900 font-[Inter]'>{formValidationErrors[`sr_issued_return_item_${index}`]}</span>}

                    </div>

                    <div className='my-4'>
                        <InputFiledWC
                            className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            type='text'
                            name='uom'
                            label='UOM*'
                            value={requisionItems?.uom}
                            readOnly={true}
                            placeholder='uom'
                        />
                    </div>

                    <div className='my-4'>
                        <InputFiledWC 
                        className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%]  focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        type='text' 
                        name='issue_return_qty' label='Issue Return Q*' 
                        id={`issue_return_qty_${index}`}
                        value={requisionItems.issue_return_qty}
                        onChange={(e) => handleInputChange(e, index, 'srissuedreturnitem_set')}
                        // isRequired={true}
                        max={requisionItems?.max_issue_return_qty}
                        placeholder='Enter issued return qty'
                        />
                        {formValidationErrors[`issue_return_qty_${index}`] && <span className='text-blue-900 font-[Inter]'>{formValidationErrors[`issue_return_qty_${index}`]}</span>}

                        {requisionItems?.max_issue_return_qty && ( 
                        <p className='text-[11px] text-[#F35B5B]'> available qty : {requisionItems?.max_issue_return_qty}</p>
                        )}
                    </div>
                    <div className='md:my-4'>
                        <TextareaInputWC
                            className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]' 
                            label='Remarks' 
                            name='remarks' 
                            id={`remarks_${index}`}
                            placeholder={`Enter remarks`} 
                            value={requisionItems.remarks}
                            onChange={(e) => handleInputChange(e, index, 'srissuedreturnitem_set')}
                        />
                    </div>

                    </div>
                </div>
                ))}

                {requiredMessage && (
                    <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span> 
                )}
                <div className="flex justify-between mx-4 my-2">

                    <button 
                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                        type="button"
                        onClick={() => {
                            console.log('Add new item Button Clicked');
                            handleAddItem();
                        }}
                        >ADD MORE</button>
                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                </div>
            </form>
        </div>
    :
    <div className='text-[30px] text-[#db2525] my-5 text-center h-[80vh]'>
        No items available
    </div>
    }
    </>
  )
}

export default SRIssuedReturnFormCom