import React, { useEffect, useState } from 'react'
import useFetchListData from '../../../../hooks/useFetchListData';
import { formatDateTime } from '../../../../Components/formatDateTime';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import * as XLSX from 'xlsx';
import CustomPaginator from '../../../../Components/CustomPaginator';
import ListPageHeadWithDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithDateRange';

const StockLogListComponent = ({listApiEndPoint,listExcelExportApiEndPoint}) => {

    const [searchText, setSearchText] = useState('');
    const [dataStatus, setDataStatus] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [excelExportLoading,setExcelExportLoading]=useState(false)
  
    const params = {
      page: currentPage,
      search: searchText,
      start_date: startDate,
      end_date: endDate,     
    };
  
    const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${listApiEndPoint}`, params);
  
    useEffect(() => {
      fetchData();
    }, [currentPage, searchText, dataStatus, endDate, startDate]);


    const handleExportToExcel = async () => {
        try {
        setExcelExportLoading(true)
        const response = await authAxiosWithBearer.get(`${baseURL}${listExcelExportApiEndPoint}`, {
          params: {
            start_date: startDate,
            end_date: endDate,     
            search: searchText,
          },
        });    

        console.log('response.data',response.data)

        const exportData = response.data?.map((item,index) => ({
            SN : index + 1,
            Created_at : item?.created_at,
            Reference : item?.reference_text,
            Reference_No : item?.reference_no,
            UOM: item?.uom_text,
            Quantity : item?.quantity,
            Created_by: item?.created_by_text,
        }));
    
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'exported_item_stock_log_list_data.xlsx');
      } catch (error) {
        console.log(error)  
      }
      finally{
        setExcelExportLoading(false)
      }
    };


  return (
    <div className='grid grid-cols-1'>
            {data?.map((data,index) =>(
                <>
                {index === 1 && (
                <div className='border border-[#EB5757] my-3' key={index + 1} >
                {/* 1st content */}
                <div className='bg-[#FDECEC] my-1'>
                    <div className='py-2 text-[#E74A3B] pl-2 text-[20px] font-semibold uppercase'>item DETAILS</div>
                </div>
    
                {/* 2nd */}
    
                <div className='border border-[#EB5757] mx-1 my-2'>
    
                    <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>
    
                        <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>
    
                            <div className='bg-[#EB5757] m-1'>
    
                                <div className='flex sm:justify-center sm:items-center px-2 sm:py-20  '>
                                    <div className='text-[#FFFFFF] text-[16px] '>
                                        <div className='sm:text-center'>{data.item_name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                            <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                                <div className='mx-1 sm:mx-0'>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Current Stock</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_stock_available_qty}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>DM Parts Return Stock</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_damage_parts_return_stock_qty}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Item Code</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_code}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Category</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_category_name}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Sub Category</div>
                                            <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{data?.item_sub_category_name}</div>
                                        </div>
                                    </div>
    
                                    <div>
                                        <div className='flex '>
                                            <div className='w-[35%] sm:w-[30%] md:w-[50%] lg:w-[35%]'>Brand</div>
                                            <div className='w-[8%] sm:w-[5%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[65%] md:w-[42%] lg:w-[49%]'>{data?.item_brand}</div>
                                        </div>
                                    </div>
    
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Model</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{data?.item_model}</div>
                                        </div>
                                    </div>
                                </div>
    
                                {/* 2nd */}
    
                                <div className='mx-1 sm:mx-0'>
                                    <div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Sales Price (Customer)</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_sales_price}</div>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Sales Price (HO)</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_sales_price_for_ho}</div>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>UOM</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>
                                                    {data?.item_uom_text}
                                                </div>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Specification</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>
                                                    {data?.item_specification}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Size</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_size}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Parts No</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{data?.item_parts_no}</div>
                                            </div>
                                        </div>
                                        <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Origin</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{data?.item_origin}</div>
                                        </div>
                                    </div>
    
                                    </div>
    
                                </div>
    
                            </div>
    
    
                        </div>
                    </div>
                </div>
    
    
                </div>
                )}
                </>
            ))}




          <ListPageHeadWithDateRange 
                pageTitle = {'Stock Log List'}
                setEndDate = {setEndDate}
                endDate = {endDate}
                setStartDate = {setStartDate}
                startDate = {startDate}
                setSearchText = {setSearchText}
                // addNewURL = {'item-stock-add'}
                // addNewUrlPermissionCode = {'3.8.1'}
                handleExportToExcel={handleExportToExcel}
          />
      
        <div className='overflow-x-auto'>
            {isLoading &&(<LoadingSpinner />)}
            {excelExportLoading &&(<LoadingSpinner />)}
            
            <table className='mx-auto table-auto border-1 border-[#ED6C6C]  max-w-[100%] text-[9px] sm:text-[12px]'>
                <thead>

                    <tr className='bg-[#EEEEEE]'>

                        <th className='border border-[#ED6C6C] w-[100px] py-3'>SN</th>
                        <th className='border border-[#ED6C6C] min-w-[100px] md:min-w-[150px] py-3'>Date</th>
                        <th className='border border-[#ED6C6C] min-w-[120px] py-3'>Reference</th>
                        <th className='border border-[#ED6C6C] min-w-[230px] py-3'>Reference no</th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>UOM </th>
                        <th className='border border-[#ED6C6C] w-[150px] py-3'>Quantity</th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Created By </th>

                    </tr>

                </thead>
                {data?.length > 0?
                    <tbody className='text-center'>
                        {data?.map((item,index)=>(
                            <tr key={index + 1}>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{(currentPage - 1) * 10 + index + 1}</td>
                                <td className='border border-[#ED6C6C] min-w-[100px] md:min-w-[150px] py-3'>{item?.created_at? formatDateTime(item?.created_at):''}</td>
                                <td className='border border-[#ED6C6C] min-w-[120px] py-3'>{item?.reference_text} </td>
                                <td className='border border-[#ED6C6C] min-w-[230px] py-3'>{item?.reference_no} </td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.uom_text}</td>
                                <td className='border border-[#ED6C6C] w-[150px] py-3'>{item?.quantity}</td>
                                <td className='border border-[#ED6C6C] w-[200px] py-3'>{item?.created_by_text}</td>
                            </tr>
                        ))}
                    </tbody>
                :
                    <tbody>
                        <tr>
                            <td colSpan="10" className='text-center h-[200px]'>Data Not Found</td>
                        </tr>
                    </tbody>
                }
            </table>

            <div className='my-5'>
            <CustomPaginator 
                setCurrentPage={setCurrentPage} 
                currentPage={currentPage}
                totalItems={totalItems}
            />
        </div>
        </div>

    </div>
  )
}

export default StockLogListComponent