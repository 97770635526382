// TicketList.jsx
import React, { useState } from 'react';
import { format } from 'date-fns';
import {useNavigate } from 'react-router-dom';
import CommonDataListTableForAT from './CommonDataListTableForAT';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { formatDateTime } from '../../../../Components/formatDateTime';

function ATechnicianList() {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState({});
  const [statusFiltering, setStatusFiltering] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      sorter: (a, b) => a?.ticket_no.localeCompare(b?.ticket_no),
      sortOrder: sortOrder['ticket_no'],
    },

    // {
    //   title: 'Customer Name',
    //   dataIndex: 'customer_name',
    //   key: 'customer_name',
    //   sorter: (a, b) => a?.customer_name.localeCompare(b?.customer_name),
    //   sortOrder: sortOrder['customer_name'],
    //   responsive:['lg']
    // },

    {
      title: 'C.Phone',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
      sorter: (a, b) => a?.customer_phone.localeCompare(b?.customer_phone),
      sortOrder: sortOrder['customer_phone'],
      responsive:['md']
     
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => {
        const categoryA = (a.category && a.category.length > 0) ? a.category.join(', ') : '';
        const categoryB = (b.category && b.category.length > 0) ? b.category.join(', ') : '';
        return categoryA.localeCompare(categoryB);
      },
      sortOrder: sortOrder['category'],
      render: (category) => (category && category.length > 0 ? category.join(', ') : 'N/A'),
      responsive: ['md']
    },
    {
      title: 'Status',
      dataIndex: 'status_text',
      key: 'status_text',
      filters: [
        {
          text: 'Open',
          value: 'Open',
        },
        {
          text: 'In Progress',
          value: 'In Progress',
        },
        {
          text: 'Pending',
          value: 'Pending',
        },
        {
          text: 'Close',
          value: 'Close',
        },
        {
          text: 'Complete',
          value: 'Complete',
        },
      ],
      onFilter: (value, record) => {
        setStatusFiltering(value);
        return record.status_text.startsWith(value);
      },
      filterSearch: true,
      filterMultiple: false,
    },
    {
      title: 'S.Status',
      dataIndex: 'sub_status_text',
      key: 'sub_status_text',
      responsive: ['md'],
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        const formattedDateTime = created_at && formatDateTime(created_at);
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },

    {
      title: 'Technician Status',
      dataIndex: 'assigned_technicians',
      key: 'assigned_technicians',
      render: (assigned_technicians) => {
        if (assigned_technicians && assigned_technicians.length > 0) {
          return (
            <div>
              {assigned_technicians.map((tech, index) => {
                const statusText = tech.sub_status_text
                  ? `${tech.technician_employee_id} (${tech.sub_status_text})`
                  : `${tech.technician_employee_id} (Job Not Start)`;
                return <div className='my-2 border-b min-w-[250px]' key={index}>{`${index + 1}. ${statusText}`}</div>;
              })}
            </div>
          );
        }
        return 'N/A';
      },
      responsive: ['md']
    },

  ];

  return (
    
    <div className='bg-[#fff] shadow-lg p-3 my-4 rounded-[5px]'>
      <CommonDataListTableForAT 
        apiEndpoint="/ccd/api/ticket-wise-assigned-data-list/" 
        columnsConfig={columns}
        title="Assigned Ticket List"
        SearchKey1='ticket_no'
        SearchKey2='status_text'
        SearchKey3='customer_phone'
        SearchKey4='category'
        SearchKey5='sub_status_text'
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        dataEditURL='/'
        SingleTicketViewURL='/ticket-deatils-with-technician'
        statusFiltering={statusFiltering}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default ATechnicianList;
