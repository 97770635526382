import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';

function PackageSRFormHandleCom({packageID,packageQty,packageAssignedToTechnicianID}) {
    const navigate = useNavigate()
    const [requisionItemOptions, setRequisionItemOptions] = useState([]);
    const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
    const [requisionIssueRequiredMessage,setRequisionIssueRequiredMessage]=useState('')
    const [isLoading,setIsLoading] = useState(false)


    const [requisionIssueFormData, setRequisionIssueFormData] = useState({
        package_sr: null,
        srissueditem_set: [
          {
            item: null,
            item_id: null,
            item_unit_sales_price: null,
            issue_qty:'',
            max_issue_qty:'',
            stock_available_qty:''
          },
        ],
      });


      useEffect(() => {
        const fetchPackageData = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/item-packages/${packageID}/`);
                console.log('response', response.data);


                const options = response.data.itempackageparts_set.map((data) => ({
                    value: data.item,
                    label: `${data?.item_name} | ${data?.item_size} | ${data.item_brand}`, 
                    item: data.item,
                    item_id: data.item,
                    uom: data.uom,
                    item_unit_sales_price: data.item_unit_price,
                    quantity: data.quantity,
                    stock_available_qty:data.item_stock_available_qty,
                }));
                setRequisionItemOptions(options);
    
                // Auto-select all items for srissueditem_set
                const initialInvReqDetails = options.map((option) => ({
                    item: option.value,
                    item_id: option.item_id,
                    uom: option.uom,
                    item_unit_sales_price:option.item_unit_sales_price,
                    issue_qty: parseFloat(option.quantity) * parseFloat(packageQty),
                    max_issue_qty:parseFloat(option.quantity) * parseFloat(packageQty),
                    stock_available_qty:option.stock_available_qty,

                }));
    
                setRequisionIssueFormData((prevData) => ({
                    ...prevData,
                    srissueditem_set: initialInvReqDetails,
                }));
                setSelectedRequisionItem(options);
            } catch (error) {
                console.error(error);
            }
        };
    
        if (packageID,packageQty) {
            fetchPackageData();
        }
    }, [packageID,packageQty]);
    




    const handleRequisionItemChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionIssueFormData };
        
        // Reset issue_qty and stock when the item selection changes
        updatedFormData.srissueditem_set[index] = {
            item: selectedOption?.value || null,
            item_id: selectedOption?.id || null,
            uom: selectedOption?.uom || null,
            item_unit_sales_price: selectedOption?.item_unit_sales_price || null,
            max_issue_qty: selectedOption?.quantity || null,
            issue_qty: '',
            stock_available_qty: selectedOption?.stock_available_qty || '',
        };
        
        const updatedSelectedRequisionItem = [...selectedRequisionItem];
        updatedSelectedRequisionItem[index] = selectedOption || null;
        

        setSelectedRequisionItem(updatedSelectedRequisionItem);
        setRequisionIssueFormData(updatedFormData);
        };
        


    const handleRequisionIssueInputChange = (e, index, type) => {
        const updatedData = { ...requisionIssueFormData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'srissueditem_set' && name === 'issue_qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 6 digits after the decimal point for ${name}.`);
            }
          }
        }
      
        if (type === 'srissueditem_set') {
          setRequisionIssueRequiredMessage('');
          updatedData.srissueditem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setRequisionIssueFormData(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            requisionIssueFormData.srissueditem_set &&
            requisionIssueFormData.srissueditem_set.every((item) => {
                return item?.item !== null && item?.issue_qty !== '';
            })
        );
    };
    
      
    const handleAddRequisionIssueItem = () => {
        if (areAllFieldsFilled()) {
            setRequisionIssueFormData((prevState) => ({
                ...prevState,
                srissueditem_set: [
                    ...prevState.srissueditem_set,
                    {
                        item: null,
                        issue_qty:'',
                    },
                ],
            }));
            setRequisionIssueRequiredMessage('');
        } else {
            setRequisionIssueRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    

    const handleRemoveRequisionIssueItem = (index) => {
      // Create a copy of the damagepartsitem_set array
      const srissueditem_set = [...requisionIssueFormData.srissueditem_set];
    
      const lastIndex = srissueditem_set.length - 1;
  
      const lastItemIsNull = srissueditem_set[lastIndex] && srissueditem_set[lastIndex].item === null;
      
  
      if (index === lastIndex) {
        srissueditem_set.splice(lastIndex, 1);
      } 
      else if (index !== lastIndex && lastItemIsNull) {
        ShowToast('error','Cannot delete this item because last item is null.');
        return;
      }
      else if (index !== lastIndex && lastItemIsNull === false) {
        srissueditem_set.splice(index, 1);
      }
    
      const updatedSelectedItem = selectedRequisionItem.filter((_, i) => i !== index);
    
      setRequisionIssueRequiredMessage('');
    
      setRequisionIssueFormData({
        ...requisionIssueFormData,
        srissueditem_set,
      });
      setSelectedRequisionItem(updatedSelectedItem);
    };

    

    const handleRequisionIssueSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const updatedFormData = { 
                ...requisionIssueFormData, 
                package_sr: packageAssignedToTechnicianID,
            };
    
            console.log('updatedFormData',updatedFormData);
            console.log('packageAssignedToTechnicianID',packageAssignedToTechnicianID)
    
            const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/sr-issued/`, updatedFormData);
    
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Submit');
                navigate('/package-sr-list');
            }
        } catch (error) {
            console.log(error);
            if(error.response.data.error){
                ShowToast('error', `${error.response.data.error}`);
              }else{
                ShowToast('error', 'Something is wrong');
              }
        }
        finally{
          setIsLoading(false)
        }
    };
    

  return {
    requisionIssueFormData,
    setRequisionIssueFormData,
    handleRequisionItemChange,
    handleRequisionIssueInputChange,
    handleAddRequisionIssueItem,
    handleRemoveRequisionIssueItem,
    handleRequisionIssueSubmit,
    requisionItemOptions,
    setRequisionItemOptions,
    selectedRequisionItem,
    setSelectedRequisionItem,
    requisionIssueRequiredMessage,
    setRequisionIssueRequiredMessage,
    isLoading,
  }
}

export default PackageSRFormHandleCom