import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import TicketIssuedSrInfoForDetailsComponent from '../BillingComponents/TicketIssuedSrInfoForDetailsComponent';
import ScbccTicketBasicInformationForDetailsComponent from '../BillingComponents/ScbccTicketBasicInformationForDetailsComponent';
import CustomerInvoiceCreateModalButton from '../BillingComponents/CustomerInvoiceCreateModalButton';
import { useSelector } from 'react-redux';
import TicketWiseIHSAssaignedTransportList from '../BillingComponents/InHouseServicingBill/TicketWiseIHSAssaignedTransportList';
import TicketWiseIHSIssuedItemList from '../BillingComponents/InHouseServicingBill/TicketWiseIHSIssuedItemList';
import LoadingSpinner from '../../../Components/LoadingSpinner';

const ScbccWpExpireTickeDetailsForBilling = () => {
  const {id} = useParams()
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [srIssuedData, setSrIssuedData] = useState(null);
  const [detailsData, setDetailsData] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [assignedTransportData,setAssignedTransportData] = useState(null)
  const [inHouseServicingIssuedItem,setInHouseServicingIssuedItem] = useState(null)


  useEffect(() => {
    const fetchDetailsData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/scbcc-ticket-details-for-billing/${id}/`);
        setDetailsData(response.data);
      } catch (error) {
        console.error(error);
        if (id && error.response?.status === 404) {
          ShowToast('error', 'Data not found');
        }
      } 
      finally {
        setLoading(false);
      }
    };

    const fetchSrIssuedListData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/scbcc-ticket-wise-sr-issued-list-for-billing/?service_bill_id=${id}`);
        setSrIssuedData(response.data.results);
      } catch (error) {
        console.error(error);
        if (id && error.response?.status === 404) {
          ShowToast('error', 'Data not found');
        }
      }
      finally {
        setLoading(false);
      }
    };

    fetchDetailsData();
    fetchSrIssuedListData();
  }, [id]);

  useEffect(() => {
    if (!detailsData?.ticket_no) return;

    const fetchAdditionalData = async () => {
      try {
        const transportResponse = await authAxiosWithBearer.get(`${baseURL}/billing/api/ticket-wise-in-house-servicing-transport-list-for-billing/?filter_query=${detailsData.ticket_no}`);
        setAssignedTransportData(transportResponse.data.results);

        const issuedItemResponse = await authAxiosWithBearer.get(`${baseURL}/billing/api/ticket-wise-in-house-servicing-issued-item-list-for-billing/?filter_query=${detailsData.ticket_no}`);
        setInHouseServicingIssuedItem(issuedItemResponse.data.results);
      } catch (error) {
        console.error(error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchAdditionalData();
  }, [detailsData]);


if (loading) {
  return <div><LoadingSpinner /></div>;
}

  return (
    <div>

      <div className='border border-[#EB5757] sm:mx-3 my-3'>
        <ScbccTicketBasicInformationForDetailsComponent detailsData={detailsData} />

        {srIssuedData?.length > 0 && <TicketIssuedSrInfoForDetailsComponent srIssuedData={srIssuedData} />}
        {inHouseServicingIssuedItem?.length > 0 && <TicketWiseIHSIssuedItemList inHouseServicingIssuedItem={inHouseServicingIssuedItem} />}
        {assignedTransportData?.length > 0 && <TicketWiseIHSAssaignedTransportList assignedTransportData={assignedTransportData} />}

        {user?.menu_permissions?.includes("5.2.1") && (
          detailsData?.sb_customer_billing_invoice ?
            <div className='bg-[#FA6669] px-[5px] py-[1px] text-[#fff] text-center'>Customer Bill Invoice No : {detailsData.sb_customer_billing_invoice}</div>
            :
            <CustomerInvoiceCreateModalButton
              detailsData={detailsData}
              srIssuedData={srIssuedData}
              scbccWpExpireTicketNo={id}
              modalPageTitle='SCBCC TICKET INVOICE CREATE FORM'
            />
        )}
      </div>

    </div>
  );
};

export default ScbccWpExpireTickeDetailsForBilling