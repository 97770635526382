import React from 'react';
import GSRIssuedListCom from '../GeneralSRComponents/GSRIssueCom/GSRIssuedListCom';


const UserWiseGeneralSRIssuedList = () => {

  return (
    <>
      <GSRIssuedListCom
        apiEndpoint={`/inventory/api/user-wise-general-sr-issue/`}
        detailsURL={`/user-wise-general-sr-issued-details/`}
        detailsPermissionCode={"3.14.6"}
      />
    </>
  );
};


export default UserWiseGeneralSRIssuedList;