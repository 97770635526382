import React from 'react'

const TicketWiseIHSAssaignedTransportList = ({assignedTransportData}) => {
    let serialNumber = 0
  return (
    <div className='overflow-x-auto overflow-y-auto p-2'>
        
    <div className='py-1 text-[20px] font-semibold text-[#000000]'>Assigned Transport (In House Servicing)</div>
    
    <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

        <thead>

            <tr className='bg-[#EEEEEE]'>

                <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>

                <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Transport User</th>

                <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Transport User Phone </th>

                <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> Vehicle Type  </th>

                <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

            </tr>

        </thead>



        <tbody className='text-center'>

            {assignedTransportData?.map((data,index)=>(

                <tr key={index}>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{++serialNumber}</td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                       ID: {data?.transport_user_employee_id} <br />
                       Name :{data?.transport_user_name} <br />
                    </td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{data?.transport_user_phone}</td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                        Name : {data?.vehicle_name} <br />
                        Number : {data?.vehicle_number} <br />
                        Type :{data?.vehicle_type}
                        </td>
                    <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{data?.remarks}</td>
                </tr>


            ))}

        </tbody>

    </table>
</div>
  )
}

export default TicketWiseIHSAssaignedTransportList