import { Pagination } from 'antd'
import React from 'react'

const CustomPaginator = ({setCurrentPage,currentPage,totalItems}) => {

    const handlePaginationChange = (page) => {
        setCurrentPage(page);
    };

  return (
    <>
      {totalItems > 10 && (
      <Pagination
      current={currentPage}
      total={totalItems}
      pageSize={10}
      onChange={handlePaginationChange}
      showSizeChanger={false}
    />
    )}
  </>
  )
}

export default CustomPaginator