import React from 'react'
import DamagePartsListCom from './DamagePartsComponents/DamagePartsListCom'

const DamagePartsList = () => {
  return (
    <>
      <DamagePartsListCom 
        apiEndpoint={`/inventory/api/damage-parts/`}
        detailsURL={`/damage-parts-details/`}
        detailsPermissionCode={"3.15.2"}
        updateURL={`/damage-parts-update/`}
        updatePermissionCode={"3.15.3"}
      />
    </>
  )
}

export default DamagePartsList