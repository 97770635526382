import React, { useEffect, useState } from 'react'
import TechnicianTaskReportFormCom from './TechnicianTaskReportComponents/TechnicianTaskReportFormCom'
import { useParams } from 'react-router-dom'
import TechnicianTaskReportListCom from './TechnicianTaskReportComponents/TechnicianTaskReportListCom'

const TechnicianTaskReportAdd = () => {
    const {id} = useParams()
    const [dataUpdated, setDataUpdated] = useState(false)

    const handleDataPost = () => {
        setDataUpdated(!dataUpdated)
    }

    useEffect(() => {
        if (dataUpdated) {
            // Set a timeout to reset dataUpdated to false after 5 seconds
            const timer = setTimeout(() => {
                setDataUpdated(false);
            }, 2000);

            // Clean up the timeout if the component unmounts or dataUpdated changes
            return () => clearTimeout(timer);
        }
    }, [dataUpdated]);


  return (
    <div>
            <TechnicianTaskReportFormCom 
                assigned_technician_id={id}
                onDataPost={handleDataPost} 
            />

            <TechnicianTaskReportListCom 
                apiEndpoint={`/technician/api/technician-task-report/?paramsQuery=${id}`}
                dataUpdated={dataUpdated}
            />
    </div>
  )
}

export default TechnicianTaskReportAdd