import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent'
import GoodsCheckedTechnicianFeedbackDetailsomponent from '../../Technician/TechnicianComponent/GoodsCheckedTechnicianFeedbackDetailsomponent'
import { useSelector } from 'react-redux'
import ATForServicingDetailsComponent from '../../InHouse/InHouseComponents/ATForServicingDetailsComponent'
import AssignedTechForGoodsCheckDetailsComponent from '../../InHouse/InHouseComponents/AssignedTechForGoodsCheckDetailsComponent'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import AssignedWiseSrIssuedList from './Components/AssignedWiseSrIssuedList'
import ServicingStatusChangeForm from './Components/ServicingStatusChangeForm'

const TechnicianWiseATForServicingDetails = () => {
    const {id} = useParams()
    const [data, setData]= useState(null)
    const [ticketData, setTicketData] = useState(null);
    const user = useSelector(state => state.auth.user);
    const [goodsCheckAssignData,setGoodsCheckAssignData] = useState(null)
    const [assignedWiseSrIssuedData,setAssignedWiseSrIssuedData]= useState(null)
    const [isLoading, setIsLoading]=useState(false)
    // fetchDeatilsData

    const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/user-wise-technician-assigned-for-servicing-details/${id}/`);
            setData(response?.data?.assigned_data);
            setGoodsCheckAssignData(response?.data?.goods_check_data)
            setAssignedWiseSrIssuedData(response?.data?.sr_issued_data)
            setIsLoading(true)
            console.log('response',response)
        } catch (error) {
            console.log(error);
        }
        finally{
            setIsLoading(false)
        }
        };


    useEffect(() => {

        fetchDeatilsData();
    }, [id]);

    
    // fetchAssignedTicketData
    const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${data?.ticket_id}/`);
            setTicketData(response.data);
            setIsLoading(true)
        } catch (error) {
            console.log(error);
        }
        finally{
            setIsLoading(false)
        }
        };

    useEffect(() => {
        fetchTicketData();
    }, [data]);


  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Details</h1>
            </div>
        </div>

            {isLoading?
            <>
                <LoadingSpinner />
            </>
            :
            <>
                <ServicingStatusChangeForm data={data} id={id} />

                <AssignedTicketDetailsComponent  ticketData={ticketData} />

                <AssignedTechForGoodsCheckDetailsComponent  data={goodsCheckAssignData} />

                {goodsCheckAssignData?.goodscheckedtechnicianfeedback_set?.length > 0 && (
                    <GoodsCheckedTechnicianFeedbackDetailsomponent 
                        feedbackData={goodsCheckAssignData?.goodscheckedtechnicianfeedback_set[0]}
                        data={data}
                    />
                )}

                <ATForServicingDetailsComponent  data={data} />

                {!assignedWiseSrIssuedData?.error && (
                    <AssignedWiseSrIssuedList data={assignedWiseSrIssuedData} />
                )}
                

            </>
            }
    </div>
  )
}

export default TechnicianWiseATForServicingDetails