import React, { useEffect } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import ShowToast from '../../../../Components/ShowToast';
import SalesOrderDetailsComponent from '../../../../Components/SalesOrderDetailsComponent';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';

const SalesInvoiceDetailsModalComponent = ({
    invoiceDetailsData,
    setInvoiceDetailsData,
    isInvoiceModalOpen,
    selectedInvoiceID,
    closeInvoiceModal,
}) => {
    //   fetchInvoiceDetails
    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details-with-invoice/${selectedInvoiceID}/`);
    
                console.log('fetchInvoiceDetailsData',response.data)
                setInvoiceDetailsData(response.data)
            } catch (error) {
                console.log(error);
                if (selectedInvoiceID && error.response && error.response.status === 404) {
                    ShowToast('error', 'Data not found');
                }
            }
        };
        fetchInvoiceDetails();
        }, [selectedInvoiceID]);
        
  return (
    <div>
  {/* invoiceDetails start */}
    <Modal
        isOpen={isInvoiceModalOpen}
        onRequestClose={closeInvoiceModal}
        contentLabel="Purchased Details Modal"
        className="modal"
        overlayClassName="overlay" 

        >
        <div className='mb-2 w-[360px] md:w-[650px] lg:w-[1200px] overflow-y-auto h-[500px]'>
            <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
                <h1 className='font-semibold'>Purchased Details </h1>
                <button className='text-[#000]' onClick={closeInvoiceModal}><ImCancelCircle /></button>
            </div>
            
            <SalesOrderDetailsComponent salesData={invoiceDetailsData} />
        </div>
        
        </Modal>
        {/* invoiceDetails end */}
    </div>
  )
}

export default SalesInvoiceDetailsModalComponent