import React, { useEffect, useState } from 'react'

const TotalSrIssueItemPrice = ({srIssuedData}) => {
  const [totalPrice, setTotalPrice] = useState(null);
    
  useEffect(() => {
    const calculateTotalPrice = () => {
      let total = 0;

      // Iterate over each issuance request
      srIssuedData.forEach((issue) => {
        // Iterate over each issuance detail in invreqissuedetails_set
        issue.invreqissuedetails_set.forEach((item) => {
          // Ensure item has properties issue_qty and item_sales_price before calculation
          const issueQty = parseFloat(item.issue_qty);
          const itemSalesPrice = parseFloat(item.item_sales_price);
          if (!isNaN(issueQty) && !isNaN(itemSalesPrice)) {
            total += issueQty * itemSalesPrice;
          }
        });
      });

      setTotalPrice(total.toFixed(2));
    };

    calculateTotalPrice();
  }, [srIssuedData]);


  console.log('srIssuedData',srIssuedData)
  console.log('totalPrice',totalPrice)


return (
  <div>
      {totalPrice && totalPrice}
  </div>
)
}

export default TotalSrIssueItemPrice