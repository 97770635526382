import React from 'react'
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import VendorInvoiceCreateHandleComponent from './VendorInvoiceCreateHandleComponent';

function VendorInvoiceCreateFormComponent({
    assignedID
}) {
    const {
        formData,
        setFormData,
        handleInputChange,
        handleSubmit,
    } = VendorInvoiceCreateHandleComponent({
        assignedID
    });



    return (
        <div className='mt-[50px]'>
            <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-1 gap-2">

                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='basic_amount' id='basic_amount' label='Basic Amount*'
                            isRequired={true} 
                            placeholder='basic amount'
                            value={formData.basic_amount}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        />
                    </div>

                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='basic_amount_quantity' id='basic_amount_quantity' label='Basic Amount Quantity*'
                        isRequired={true} 
                        placeholder='basic amount quantity'
                        value={formData.basic_amount_quantity}
                        onChange={handleInputChange}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>

                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='additional_amount' id='additional_amount' label='Additional Amount'
                        isRequired={false} 
                        placeholder='additional amount'
                        value={formData.additional_amount}
                        onChange={handleInputChange}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='total_payable_amount' id='total_payable_amount' label='Total Payable Amount'
                            isRequired={false} 
                            placeholder='Discount Amount'
                            value={formData.total_payable_amount}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className=" mt-4 bottom-0">
                        <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2 w-[100%]'>Create</button>
                    </div>
            </form>
        </div>
    )
}

export default VendorInvoiceCreateFormComponent