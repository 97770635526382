import React from 'react'
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../Components/FormWithoutClass/TextareaInputWC';
import FormPageTitle from '../../../Components/Form/FormPageTitle'
import GRnForm from './GRnComponents/GRnForm';
import DateInputWC from '../../../Components/FormWithoutClass/DateInputWC';
import ReactDatePicker from 'react-datepicker';
import StoreSelect from '../../../Components/SelectOptionComponents/StoreSelect';

function AddNewGRN() {
    const {
        location,
        selectedWoIds,
        woNoOptions,
        setWoNoOptions,
        selectedWoNo,
        setSelectedWoNo,
        woDetailsData,
        setWoDetailsData,
        selectedItems,
        setSelectedItems,
        selectedStore,
        setSelectedStore,
        inputValues,
        setInputValues,
        grnBasicFormData,
        setGRnBasicFormData,
        qtyErrorMessages,
        setQtyErrorMessages,
        navigate,
        handleWoNoChange,
        handleStoreChange,
        handleHeaderCheckboxChange,
        handleWoCheckboxChange,
        handleItemCheckboxChange,
        selectAll,
        handleInputChange,
        handleFromDateChange,
        handlegrnBasicFormDataInputChange,
        handleInvoiceDateChange,
        handleFormSubmit,
        handleWoSearchInputChange,
      } = GRnForm();

  return (
    <div className='container grid grid-cols-1 mx-auto bg-[#fff]'>
        <FormPageTitle title='grn Form' FormPageRightSideButtonURL='/grn-list' LinkName='Close' />


        <form className='shadow-lg pb-5' onSubmit={handleFormSubmit}>
            <div className="pr-item-data overflow-x-scroll max-w-[99%] mx-5 mb-5">
            
            <h1 className='border-b pb-2 mb-3 text-[18px]'>Basic Information</h1>
            <div className="flex gap-x-8">
                <div className='my-4'>  
                    <StoreSelect
                        name='store'
                        id='store'
                        label='Store*'
                        value={selectedStore}
                        isRequired={true}
                        className={`appearance-none border-[1px] w-[388.33px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        onChange={handleStoreChange}
                    />
                </div>

                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='challan_no' id='challan_no' label='Invoice/Challan No*'
                        isRequired={true} 
                        placeholder='Enter invoice no'
                        value={grnBasicFormData.challan_no}
                        onChange={handlegrnBasicFormDataInputChange}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[388.33px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                
                <div className='my-4'>
                    <DateInputWC type='text' 
                        name='challan_date' id='challan_date' label='Invoice/Challan Date*'
                        isRequired={true} 
                        placeholder='Enter invoice date' 
                        className='pl-8 py-5 text-[13px] appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[388.33px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        value={grnBasicFormData.challan_date}
                        onChange={handleInvoiceDateChange}
                        dateFormat='yyyy-MM-dd' 
                    />
                </div>
            </div>

            <h1 className='border-b pb-2 mb-6 text-[18px]'>Item Information</h1>
            <div className='my-4'>  
                <SelectInputWC
                    name='wo_no' 
                    id='wo_no'  
                    label='WO Number'
                    value={selectedWoNo}
                    onChange={handleWoNoChange}
                    onInputChange={handleWoSearchInputChange}
                    options={woNoOptions.filter(
                        (option) =>
                            !selectedWoNo.some(
                                (selectedItem) => selectedItem && parseInt(selectedItem.value, 10) === option.value
                            )
                    )}
                    isRequired={false}
                    isMulti={true}
                    isClearable={true}
                    className={`appearance-none border-[1px] w-[1230px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                />
            </div>
            
            <table className='table-auto w-[100%]'>
            <thead>
                <tr>
                    <th className="text-left pl-2 font-semibold">
                        <input
                            type="checkbox"
                            id="headerCheckbox"
                            checked={selectAll}
                            onChange={handleHeaderCheckboxChange}
                            className="accent-[#fd3e3e] mr-2"
                        />Select All
                    </th>
                    <th className="text-left pl-2 font-semibold">Item</th>
                    <th className="text-left pl-2 font-semibold">Specification</th>
                    <th className="text-left pl-2 font-semibold">Ordered QTY</th>
                    <th className="text-left pl-2 font-semibold">Already Rcv.</th>
                    <th className="text-left pl-2 font-semibold">Rcv.QTY</th>
                    <th className="text-left pl-2 font-semibold">Add.QTY</th>
                    <th className="text-left pl-2 font-semibold">Lot No</th>
                    <th className="text-left pl-2 font-semibold">Ex.Date</th>
                    <th className="text-left pl-2 font-semibold">Remarks</th>
                </tr>
            </thead>

            <tbody>
                {woDetailsData?.map((woData,woIndex)=>(
                <>
                    <div className='flex mx-2 mt-3 mb-2'>
                    <input
                            type="checkbox"
                            id={`prCheckbox_${woIndex}`}
                            checked={woData?.workorderitem_set?.length > 0 && 
                                    woData.workorderitem_set.every((item) => selectedItems.includes(item.id))}
                            onChange={() => handleWoCheckboxChange(woIndex)}
                            className="accent-[#fd3e3e]"
                        />
                        <p className='ml-2 text-[#fd3e3e] font-semibold'>{woData?.wo_no}</p>
                    </div>
                    {woData?.workorderitem_set?.map((itemData,itemIndex)=>(

                    <tr>
                        <td className='w-[150px]'>
                            <input
                            type="checkbox"
                            id={`itemCheckbox__${itemData.id}`}
                            checked={selectedItems.includes(itemData.id)}
                            onChange={() => handleItemCheckboxChange(woIndex, itemIndex)}
                            className="accent-[#fd3e3e]"
                            />
                        </td>
                        <td className='min-w-[150px]'>{itemData?.item_name} {itemData?.id}</td>
                        <td className='min-w-[150px]'>{itemData?.item_specification}</td>
                        <td className='min-w-[150px]'>{itemData?.pr_uom_name?<>{itemData?.wo_item_qty} ({itemData?.pr_uom_name}) </> :<> {itemData?.wo_item_qty} ({itemData?.item_uom_name}) </>}</td>
                        <td className='min-w-[120px]'>{parseFloat(itemData?.wo_item_qty) - itemData?.available_rcv_qty}</td>
                        <td className='min-w-[100px]'>
                        
                        <input type='text'
                                name='rcv_qty'
                                id={`rcv_qty_${itemData?.id}`}
                                required={selectedItems.some((number) => number === itemData.id)}
                                placeholder='rcv. qty'
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                                value={inputValues[`rcv_qty_${itemData?.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'rcv_qty', e.target.value);
                                }}
                                max={itemData.available_rcv_qty}
                                min='0.1'
                            />
                            {qtyErrorMessages[`rcv_qty_${itemData?.id}`] && (
                                <div className="text-red-500 text-[11px]">
                                    {qtyErrorMessages[`rcv_qty_${itemData?.id}`]}
                                </div>
                            )}
                            {selectedItems.includes(itemData.id) && !inputValues[`rcv_qty_${itemData?.id}`] && (
                                <div className="text-red-500 text-[11px]">Required</div>
                            )}
                        </td>
                        <td className='min-w-[100px]'>
                            <input type='text'
                                name='add_qty'
                                id={`add_qty_${itemData?.id}`}
                                placeholder='add. qty'
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                                value={inputValues[`add_qty_${itemData?.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'add_qty', e.target.value);
                                }}
                                min='0.1'
                            />

                        </td>
                        <td className='min-w-[100px]'>
                        <input type='text'
                                name='lot_no'
                                id={`lot_no_${itemData?.id}`}
                                required={false}
                                placeholder='lot no'
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                                value={inputValues[`lot_no_${itemData?.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'lot_no', e.target.value);
                                }}
                           />
                        </td>
                        <td className='min-w-[100px]'>
                        <ReactDatePicker
                            name={`exp_date_${itemData.id}`}
                            className='border-b border-[#aaaaaa] w-[100px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                            selected={inputValues[`exp_date_${itemData.id}`]}
                            id={`exp_date_${itemData.id}`}
                            placeholderText='Select Date'
                            onChange={(date) => {
                                handleFromDateChange(itemData, 'exp_date', date);
                            }}
                            dateFormat='yyyy-MM-dd'
                        />
                        </td>
                        <td className='min-w-[100px]'>
                            <input type='text'
                                name='remarks' label='Remarks'
                                id={`remarks_${itemData.id}`}
                                placeholder='remarks'
                                value={inputValues[`remarks_${itemData.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'remarks', e.target.value);
                                }}
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                            />
                        </td>
                    </tr>
                    ))}
                    
                </>
                ))}
            </tbody>


            </table>


            <h1 className='border-b pb-2 my-3 text-[18px]'>Additional Information</h1>
            <div className="flex gap-x-8">
                <div className='my-4'>
                    <TextareaInputWC type='text' 
                        name='note' id='note' label='Note'
                        placeholder='Enter note'
                        value={grnBasicFormData.note}
                        onChange={handlegrnBasicFormDataInputChange} 
                        className='pl-3 py-[9px] h-[100px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[1220px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
            </div>


            </div>
            <div className="mx-5 text-right">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
            </div>
        </form>


    </div>
  )
}

export default AddNewGRN