import React, { useEffect, useState } from 'react';
import { AiFillDelete, AiFillEdit, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { authAxios, authAxiosWithBearer } from '../../../../baseURL';
import LoadingSpinner from '../../../../Components/LoadingSpinner'


function CustomerDataListComponent({
  apiEndpoint,
  columnsConfig,
  title,
  SearchKey1,
  SearchKey2,
  SearchKey3,
  SearchKey4,
  sortOrder,
  setSortOrder,
  expandableKey1,
  expandableKey2,
  addNewURL,
  dataEditURL,
  deleteURL
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const jwtToken = localStorage.getItem('token')
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${apiEndpoint}`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
        const resultsData = results.map((item) => ({ ...item, key: item.id }));
  
        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate, apiEndpoint, currentPage, searchText]);




  const handleSearch = (value) => {
    setSearchText(value);
  };


  // const handleDelete = async (itemId) => {
  //   const result = await Swal.fire({
  //     title: 'Confirm Delete',
  //     text: 'Are you sure you want to delete this item?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#d33',
  //     cancelButtonColor: '#3085d6',
  //     confirmButtonText: 'Yes, delete it!',
  //   });

  //   if (result.isConfirmed) {
  //     try {
  //       await authAxios.delete(`${apiEndpoint}${itemId}/`,{
  //         headers:{
  //           Authorization : `${jwtToken}`
  //         }
  //       });
  //       const updatedData = data.filter((item) => item.id !== itemId);
  //       setData(updatedData);
  //       Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
  //     } catch (error) {
  //       console.log(error);
  //       Swal.fire('Error', 'An error occurred while deleting the item.', 'error');
  //     }
  //   }
  // };


  const filteredData = data?.filter(
    (item) =>
      item[SearchKey1]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey2]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey3]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey4]?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };
  
  return (
    <>
      <div className="flex justify-between page-header mb-5">
        <div className="page-title my-auto ml-5">
          <h1 className='uppercase'>{title}</h1>
        </div>
        <div className="page-search-and-add-new-button flex my-auto mr-5">
          <div className="page-search mr-2">
            <div className="relative">
              <input
                className="page-search-input w-[150px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search here"
              />
              <div className="text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="submit">
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div>

          {addNewURL !== '' &&
          <div className="add-new-button px-2">
             <Link to={addNewURL}>Add New</Link>
          </div>
          }
          
        </div>
      </div>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
      <Table
        columns={[
          ...columnsConfig.map((column) => ({
            ...column,
            sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
          })),

          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
              <div className='flex gap-2'>

                {dataEditURL !== '' &&
                <button onClick={() => navigate(`${dataEditURL}/${record.id}`)} className='rounded'>
                  <AiFillEdit className='text-[#6F6F7E] text-xl' />
                </button>
                }

                {/* {deleteURL !== '' &&
                <button className='rounded ml-2' onClick={() => handleDelete(record.id)}>
                  <AiFillDelete className='text-xl text-[#F35B5B]' />
                </button>
                } */}

              </div>
            ),
          },
        ]}
        
        dataSource={filteredData}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          setSortOrder({
            columnKey: sorter.field,
            order: sorter.order,
          });
        }}
      />
      <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
    </>
  );
}




export default CustomerDataListComponent;

