import React from 'react'
import DamagePartsFormCom from './DamagePartsComponents/DamagePartsFromComponets/DamagePartsFormCom'
import { useParams } from 'react-router-dom'

const DamagePartsUpdate = () => {
  const {id} = useParams()
  return (
    <>
        <DamagePartsFormCom title={'Damage Part Update'} id= {id} />
    </>
  )
}

export default DamagePartsUpdate