import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { useNavigate, useParams } from 'react-router-dom';
import ShowToast from '../../../Components/ShowToast';
import SelectInput from '@mui/material/Select/SelectInput';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import { AiFillDelete } from 'react-icons/ai';
import TextareaInput from '../../../Components/Form/TextareaInput';


function AssignedTechnicianForIJCRUpdate() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [requiredMessage,setRequiredMessage]=useState('')
    const [invoiceDetailsData, setInvoiceDetailsData ]  = useState(null)
    const [IJCRItemOptions, setIJCRItemOptions] = useState([]);
    const [selectedIJCRItem, setSelectedIJCRItem] = useState([]);
    const [packageOptions, setPackageOptions] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState([]);
    const [submitLoading,setSubmitLoading] = useState(false)
    const [assignedData, setAssignedData] = useState([])
  
    const [technicianAssignedForm,setTechnicianAssignedForm] = useState({
      technician: null,
      installation: null,
      ticket: null,
      priority:null,
      location:'',
      remarks:'',
      packageassignedtotechnician_set: [
          {
            item: null,
            package: null,
            package_quantity: ''
          }
      ]
    })
  

    const PriorityOption = [
        { value: 'High', label: 'High' },
        { value: 'Normal', label: 'Normal' },
        { value: 'Low', label: 'Low' },
    ];

    useEffect(()=>{
        const fetchAssignedData = async ()=>{
              try {
                const response = await authAxiosWithBearer.get( `${baseURL}/ccd/api/assign-technician-with-package/${id}/`) 
                    setAssignedData(response.data)
                    setTechnicianAssignedForm(response.data)

                    const selectedItems = response.data.packageassignedtotechnician_set.map((items) => ({
                        value: items.item,
                        label: items.invoice_item_name,
                      }));
                      setSelectedIJCRItem(selectedItems);

                      const selectedPackages = response.data.packageassignedtotechnician_set.map((items) => ({
                        value: items.package,
                        label: items.package_name,
                      }));
                      setSelectedPackage(selectedPackages);

              } catch (error) {
                    console.log(error)
                    
              }
        };fetchAssignedData()
  },[])

    useEffect(() => {
        const fetchInvoiceDetailsData = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details-with-invoice/${assignedData?.installation_ticket_invoice_no}/`);
    
    
                const filteredItemData = response.data?.sales_product_information_set?.filter((data) => {
                    const itemData = data.product_information.id;
                    return !selectedIJCRItem.some(
                    (selected) => selected.value === itemData
                    );
                });
        
                const options = filteredItemData.map((data) => ({
                    value: data.product_information.id,
                    label: `${data.product_information.name}`,
                }));
                setIJCRItemOptions(options);
    
                console.log('fetchInvoiceDetailsData',response.data)
                setInvoiceDetailsData(response.data)
            } catch (error) {
                console.log(error);
            }
        };
        fetchInvoiceDetailsData();
    }, [assignedData.installation_ticket_invoice_no,selectedIJCRItem]);


    const [packageSearchInputValue,setPackageSearchInputValue] = useState()

    useEffect(() => {
      authAxiosWithBearer.get(`${baseURL}/inventory/api/active-item-package-list-for-ijcr/?search=${packageSearchInputValue}`)
          .then(response => {
              setPackageOptions(response.data?.results?.map(data => ({
                  value: data.id,
                  label: `${data.package_name}`
                })));

              console.log(response.data.results)
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
    }, [packageSearchInputValue]);

    const handlePackageSearchInputChange = (value) => {
      setPackageSearchInputValue(value);
    };
    
      

  
    const handleIJCRItemChange = (selectedOption, index) => {
          const updatedFormData = { ...technicianAssignedForm };
      
          updatedFormData.packageassignedtotechnician_set[index] = {
              ...updatedFormData.packageassignedtotechnician_set[index],
              item: selectedOption?.value,
          };
      
          const updatedSelectedIJCRItem = [...selectedIJCRItem];
          updatedSelectedIJCRItem[index] = selectedOption || null;
      
          setSelectedIJCRItem(updatedSelectedIJCRItem);
          setTechnicianAssignedForm(updatedFormData);
    };
  
    const handleIJCRPrirotyChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
            setTechnicianAssignedForm(prevState => ({ ...prevState, priority: selectedOption.value }));
        } else {
            setTechnicianAssignedForm(prevState => ({ ...prevState, priority: null }));
        }
    };
        
  
    const handlePackageChange = (selectedOption, index) => {
        const updatedFormData = { ...technicianAssignedForm };
    
        updatedFormData.packageassignedtotechnician_set[index] = {
            ...updatedFormData.packageassignedtotechnician_set[index],
            package: selectedOption?.value,
        };
    
        const updatedSelectedPackage = [...selectedPackage];
        updatedSelectedPackage[index] = selectedOption || null;
    
        setSelectedPackage(updatedSelectedPackage);
        setTechnicianAssignedForm(updatedFormData);
    };
          
  
    const handleTechnicianAssignedInputChange = (e, index, type) => {
        const updatedData = { ...technicianAssignedForm };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'packageassignedtotechnician_set') {
          setRequiredMessage('');
          updatedData.packageassignedtotechnician_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setTechnicianAssignedForm(updatedData);
    };
      
    
    const areAllFieldsFilled = () => {
        return (
          technicianAssignedForm.packageassignedtotechnician_set &&
          technicianAssignedForm.packageassignedtotechnician_set.every((item) => {
                return item?.item !== null && item?.package !== null && item?.package_quantity !== '';
            })
        );
    };
    
      
    const handleAddMoreItem = () => {
        if (areAllFieldsFilled()) {
          setTechnicianAssignedForm((prevState) => ({
                ...prevState,
                packageassignedtotechnician_set: [
                    ...prevState.packageassignedtotechnician_set,
                    {
                        item: null,
                        package: null,
                        package_quantity: '',
                    },
                ],
            }));
            setRequiredMessage('');
        } else {
            setRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    
  
    const handleRemoveItem = (index) => {
        const packageassignedtotechnician_set = [...technicianAssignedForm.packageassignedtotechnician_set];
        setRequiredMessage('');
    
        // Remove the item from the array
        const removedItem = packageassignedtotechnician_set.splice(index, 1)[0];
    
        // Remove the corresponding items from selectedItem arrays
        const updatedSelectedIJCRItem = selectedIJCRItem.filter((item, i) => i !== index);
        const updatedSelectedPackage = selectedPackage.filter((item, i) => i !== index);
    
        setTechnicianAssignedForm({
            ...technicianAssignedForm,
            packageassignedtotechnician_set,
        });
    
        setSelectedIJCRItem(updatedSelectedIJCRItem);
        setSelectedPackage(updatedSelectedPackage)
    };
  
    const handleUpdateFormSubmit = async (e) => {
      e.preventDefault();
      try {
        setSubmitLoading(true);

        const response = await authAxiosWithBearer.put(
        `${baseURL}/ccd/api/assign-technician-with-package/${id}/`,technicianAssignedForm);

        ShowToast('success', 'Successfully Submit');
        setSubmitLoading(false);
        navigate(`/assigned-technician-add/${assignedData.ticket}`)

      } catch (error) {
        setSubmitLoading(false);
        if(error.response.data.message){
            ShowToast('error',`${error.response.data.message}`)
          }
        console.error(error);
      }
    };


  return (
    <div>
        <div className='mb-5 w-[360px] md:w-[700px]'>
            <div className="mx-auto my-10 pb-5 overflow-y-auto shadow-lg bg-[#fff]">
                <div className='flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow'>
                    <h1 className='font-semibold'>Assigned Information Update</h1>
                </div>
                <form onSubmit={handleUpdateFormSubmit} className='px-2'>
                    <div className='my-10'>
                            <SelectInputWC
                            name='priority'
                            id='priority'
                            label='Priority'
                            className={`w-[99%] md:w-[99%] lg:w-[99%]`}
                            value={PriorityOption.find((option) => option?.value === technicianAssignedForm.priority)}
                            onChange={handleIJCRPrirotyChange}
                            options={PriorityOption}
                            isRequired={false}
                            />
                        </div>

                        <div  className='mt-10 mb-5'>                                
                            <TextareaInput type='text' 
                            className='h-[70.535px] w-[99%] md:w-[99%] lg:w-[99%]'
                            name='remarks' id='remarks'
                            value={technicianAssignedForm.remarks}
                            onChange={handleTechnicianAssignedInputChange}
                            label='Remarks' isRequired={false}
                            placeholder='Enter Remarks here'/>
                        </div>

                        <div className="mx-2 my-5">
                            <h1 className='text-[15px] font-semibold' >Package Item Information</h1> <hr className='my-2' />
                        </div>

                        {technicianAssignedForm.packageassignedtotechnician_set?.map((packageItem, index) => (
                        <div className="flex gap-x-2 border-b">                               
                            <div className="my-4">
                                <SelectInputWC
                                    label='Item*'
                                    id={`item_${index}`}
                                    name={`packageassignedtotechnician_set[${index}]?.item`}
                                    value={selectedIJCRItem[index]}
                                    onChange={(selectedOption) => handleIJCRItemChange(selectedOption, index)}
                                    options={IJCRItemOptions}
                                    placeholder='Select item'
                                    isRequired={true}
                                    isClearable={true}
                                    className={`appearance-none border-[1px] md:w-[200px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                />
                            </div>

                            <div className="my-4">
                                <SelectInputWC
                                    label='Package*'
                                    id={`package_${index}`}
                                    name={`packageassignedtotechnician_set[${index}]?.package`}
                                    value={selectedPackage[index]}
                                    onChange={(selectedOption) => handlePackageChange(selectedOption, index)}
                                    onInputChange={handlePackageSearchInputChange}
                                    options={packageOptions}
                                    placeholder='Select package'
                                    isRequired={true}
                                    isClearable={true}
                                    className={`appearance-none border-[1px] md:w-[200px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                />
                            </div>

                            <div className='my-4'>
                                <InputFiledWC type='number' 
                                name='package_quantity' label='Quantity*' 
                                id={`package_quantity_${index}`}
                                value={packageItem.package_quantity}
                                onChange={(e) => handleTechnicianAssignedInputChange(e, index, 'packageassignedtotechnician_set')}
                                isRequired={true} 
                                placeholder='Enter quantity'
                                className='pl-3 py-4 appearance-none border-[1px] border-[#9d9c9c] h-[40.535px] w-[180px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                />
                            </div>

                            <div className='md:mt-10 mx-10 mb-2'>
                                {index !== 0 && (
                                    <button
                                    type="button"
                                    onClick={() => handleRemoveItem(index)}
                                    className="text-red-500"
                                    >
                                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                    </button>
                                )}
                            </div>

                        </div>
                        ))}

                        {requiredMessage && (
                            <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span> 
                        )}
                        <div className="flex justify-between my-2">

                            <button 
                                className='text-[#FA6669] text-[12px] hover:text-[#ff1600] font-semibold rounded-[5px] px-2 py-1 border hover:shadow'
                                type="button"
                                onClick={() => {
                                    console.log('Add new item Button Clicked');
                                    handleAddMoreItem();
                                }}
                                >ADD MORE
                            </button>
                            
                            <div>
                                {submitLoading?
                                <button disabled="disabled" className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-1'>Updating...</button>
                                :
                                <button type="submit" className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-1'>Update</button>
                                }
                            </div>

                        </div>

                </form>
            </div>
        </div>
    </div>
  )
}

export default AssignedTechnicianForIJCRUpdate