import React, { useEffect, useState } from 'react';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { AiFillDelete, AiFillEdit, AiFillEye, AiFillFileExcel, AiFillPlusSquare, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Table, DatePicker, Pagination } from 'antd';
import Swal from 'sweetalert2';
import { BsFillPeopleFill } from "react-icons/bs";
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import ShowToast from '../../../../Components/ShowToast';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';

function CommonPendingTicketList({
  apiEndpoint,
  columnsConfig,
  title,
  SearchKey1,
  SearchKey2,
  SearchKey3,
  SearchKey4,
  SearchKey5,
  sortOrder,
  setSortOrder,
  expandableKey1,
  expandableKey2,
  expandableKey3,
  expandableKey4,
  dataEditURL,
  TechnicianAssaignURL,
  CreateNewTicketURL,
  SingleTicketViewURL,
  user,
  statusFiltering,
  currentPage,
  setCurrentPage,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ticketCategoris, setTicketCategoris] = useState(null)
  const [totalItems, setTotalItems] = useState(0);


 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(apiEndpoint, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText,
            ticketCategoris: ticketCategoris,
            status:statusFiltering,
          },
        });
  
        const { results, count } = response.data;
        const resultsData = results.map((item,index) => ({ ...item, key: index+1 }));
        
        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally{
        setIsLoading(false)
      }
    };
    fetchData();
  }, [startDate, endDate, apiEndpoint, currentPage, searchText,ticketCategoris,statusFiltering]);


  const handleSearch = (value) => {
    setSearchText(value);
  };


  const handleCategoryFilteringChange = (value) => {
    setTicketCategoris(value);
  };


  const filteredData = data?.filter(
    (item) => {
      const textSearchCondition =
        (item[SearchKey1] && item[SearchKey1].toLowerCase().includes(searchText.toLowerCase())) ||
        (item[SearchKey3] && item[SearchKey3].toString().toLowerCase().includes(searchText.toLowerCase())) ||
        (item[SearchKey4] && item[SearchKey4].toString().toLowerCase().includes(searchText.toLowerCase())) ||
        (item[SearchKey5] && item[SearchKey5].toString().toLowerCase().includes(searchText.toLowerCase())) ||
        (typeof item[SearchKey3] === 'string' && item[SearchKey3].toLowerCase().includes(searchText.toLowerCase())) ||
        (item.customer && item.customer.length > 0 && item.customer[0].name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.customer && item.customer.length > 0 && item.customer[0].phone_no.includes(searchText.toLowerCase())) ||
        (item[SearchKey2] && item[SearchKey2].join(', ').toLowerCase().includes(searchText.toLowerCase()));

      const dateFilterCondition =
        (!startDate || new Date(item.created_at) >= startDate) &&
        (!endDate || new Date(item.created_at) <= endDate);

      return textSearchCondition && dateFilterCondition;
    }
  );

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  
  const handleExportToExcel = async () => {
  try {
    setIsLoading(true)
    const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/ticket-export-to-excel/`, {
      params: {
        start_date: startDate,
        end_date: endDate,     
        search: searchText,
        ticketCategoris: ticketCategoris, 
        status:statusFiltering,
      },
    });


    const exportData = response.data?.map(item => ({
      TicketNo: item?.ticket_no,
      'created at': item?.created_at,
      'Call Remarks': item?.call_remarks,
      'SMS Sent': item?.sms_sent,
      categories: item?.category?.join(', ') || 'General Query',
      Customer: item.customer[0]?.name || null,
      'Customer Phone': item.customer[0]?.phone_no || null,
      'Customer Alt Phone': item.customer[0]?.customer_alt_mobile || null,
      'Customer Email': item.customer[0]?.email || null,
      'Customer Address': item.customer[0]?.address || null,
      'Customer PO': item.customer[0]?.customer_po || null,
      'Customer PS': item.customer[0]?.customer_ps || null,
      'Customer Type': item.customer[0]?.customer_type || null,
      'Customer Status': item.customer[0]?.status || null,

      'HVAC Company Name': item?.hvac[0]?.hvac_company_name || null,
      'HVAC Email': item?.hvac[0]?.hvac_email || null,
      'HVAC Address': item?.hvac[0]?.hvac_address || null,
      'HVAC Capacity': item?.hvac[0]?.capacity || null,
      'HVAC Assigned Technicians Employee Id': item?.hvac?.map(hvacItem => hvacItem?.technician?.map(tech => tech.technician_employee_id)).flat().join(', ') || null,

      'Installation Bill Invoice No': item?.insatllation_bill[0]?.ib_invoice_no || null,
      'Installation Bill Product Category': item?.insatllation_bill[0]?.ib_product_category?.name || null,
      'Installation Bill Product Model': item?.insatllation_bill[0]?.model_no || null,
      'Installation Bill Other Info': item?.insatllation_bill[0]?.other_info || null,
      'Installation Bill Quantity': item?.insatllation_bill[0]?.quantity || null,
      'Installation Assigned Technicians Employee Id': item?.insatllation_bill?.map(insatllationBillItem => insatllationBillItem?.technician?.map(tech => tech.technician_employee_id)).flat().join(', ') || null,


      'Service Bill Invoice No': item?.service_bill[0]?.sb_invoice_no || null,
      'Service Bill Product Brand': item?.service_bill[0]?.sb_product_brand?.name || null,
      'Service Bill Product Category': item?.service_bill[0]?.sb_product_category?.name || null,
      'Service Bill Dealer Name': item?.service_bill[0]?.dealer_name || null,
      'Service Bill Model Name': item?.service_bill[0]?.model_name || null,
      'Service Bill Other Details': item?.service_bill[0]?.other_details || null,
      'Service Bill Purchase Date': item?.service_bill[0]?.purchase_date || null,
      'Service Bill Service Address': item?.service_bill[0]?.service_address || null,
      'Service Bill Showroom Name': item?.service_bill[0]?.showroom_name || null,
      'Service Bill Assigned Technicians Employee Id': item?.service_bill?.map(serviceBillItem => serviceBillItem?.technician?.map(tech => tech.technician_employee_id)).flat().join(', ') || null,

      'Online Query Customer Interest': item?.online_query[0]?.customer_interest?.name || null,
      'Online Query Product Brand': item?.online_query[0]?.oq_product_brand?.name || null,
      'Online Query Source Info': item?.online_query[0]?.oq_source_info?.name || null,

      
      'Dealership Query Address': item?.dealership_query[0]?.dq_address || null,
      'Dealership Query Email': item?.dealership_query[0]?.dq_email || null,
      'Dealership Query Shop Name': item?.dealership_query[0]?.short_name || null,
      'Dealership Query Product Brand': item?.dealership_query[0]?.dq_product_brand?.name || null,


      'Hire Purchase Product Category': item?.hire_purchase[0]?.hp_product_category?.name || null,
      'Hire Purchase Professional Details': item?.hire_purchase[0]?.professional_details || null,

      'Corporate Purchase Product Category': item?.corporate_purchase[0]?.cp_product_category?.name || null,
      'Corporate Purchase Aproximate Quantity': item?.corporate_purchase[0]?.aproximate_quantity || null,
      'Corporate Purchase Company Name': item?.corporate_purchase[0]?.cp_company_name || null,


      'OutBound Product Brand': item?.outbound_data[0]?.ob_product_brand_name || null,
      'OutBound Department': item?.outbound_data[0]?.ob_department_name || null,
      'OutBound Option To': item?.outbound_data[0]?.ob_option_to_name || null,
      'OutBound Query Type': item?.outbound_data[0]?.ob_query_type_name || null,
      'OutBound Staff Name': item?.outbound_data[0]?.ob_staff_name || null,



      'TeleSalse HouseHold': item?.telesalse[0]?.item_in_household || null,
      'TeleSalse Sales Prospect': item?.telesalse[0]?.sales_prospect_text || null,
      'TeleSalse Product Category': item?.telesalse[0]?.sale_new_product_name || null,
      'TeleSalse Product Brand ': item?.telesalse[0]?.t_product_brand_name || null,
      'TeleSalse Color': item?.telesalse[0]?.color || null,
      'TeleSalse Customer Complain': item?.telesalse[0]?.customer_complain_name || null,
      'TeleSalse Appointment Date': item?.telesalse[0]?.appointment_date || null,
      'TeleSalse Follow Up Date': item?.telesalse[0]?.follow_up_date || null,
      'TeleSalse Is discount': item?.telesalse[0]?.is_discount || null,
      'TeleSalse New purchase Interest': item?.telesalse[0]?.new_purchase_interest || null,


      'HappyCall prv Purchased Product ': item?.happycall[0]?.prv_purchased_prod || null,
      'HappyCall showroom rating ': item?.happycall[0]?.showroom_rating || null,
      'HappyCall staf behave rating ': item?.happycall[0]?.staf_behave_rating || null,
      'HappyCall prod delevery rating ': item?.happycall[0]?.prod_delevery_rating || null,
      'HappyCall installation rating ': item?.happycall[0]?.installation_rating || null,
      'HappyCall installation exp ': item?.happycall[0]?.installation_exp || null,
      'HappyCall service center rating': item?.happycall[0]?.service_center_rating || null,
      'HappyCall service center exp': item?.happycall[0]?.service_center_exp || null,
      'HappyCall website rating': item?.happycall[0]?.website_rating || null,
      'HappyCall delevery corection': item?.happycall[0]?.delevery_corection || null,
      'HappyCall suggest other': item?.happycall[0]?.suggest_other || null,
      'HappyCall suggest exp': item?.happycall[0]?.suggest_exp || null,
      'HappyCall has complain': item?.happycall[0]?.has_complain || null,
      'HappyCall complain details': item?.happycall[0]?.complain_details || null,
      'HappyCall unnecessary charge': item?.happycall[0]?.unnecessary_charge || null,
      'HappyCall gift item': item?.happycall[0]?.gift_item || null,
      'HappyCall installation charge': item?.happycall[0]?.installation_charge || null,
      'HappyCall discount': item?.happycall[0]?.discount || null,
      'HappyCall accessories sale': item?.happycall[0]?.accessories_sale || null,
      'HappyCall purchase procedure rating': item?.happycall[0]?.purchase_procedure_rating || null,
      'HappyCall purchase exp': item?.happycall[0]?.purchase_exp || null,
      'HappyCall encourage fnf': item?.happycall[0]?.encourage_fnf || null,
      'HappyCall encourage fnf exp': item?.happycall[0]?.encourage_fnf_exp || null,
      'HappyCall future possibility': item?.happycall[0]?.future_possibility || null,
      // 'HappyCall overall happiness rating': item?.happycall[0]?.overall_happiness_rating || null,
      'HappyCall call type ': item?.happycall[0]?.call_type_text || null,
      'HappyCall product choise from text': item?.happycall[0]?.product_choise_from_text || null,
      'HappyCall source info text': item?.happycall[0]?.source_info_text || null,
      'HappyCall prefer payment text': item?.happycall[0]?.prefer_payment_text || null,


    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  } catch (error) {
    if(error.response.data.message){
      ShowToast('error', `${error.response.data.message}`)
    }
    console.log(error)
  }finally{
    setIsLoading(false)
  }
  };


  
  return (
    <div className='container-fluid grid grid-cols-1'>
      <div className="page-header mb-[15px] md:mb-5 ">
        <div className="flex justify-between pt-4">
          <div className="page-title my-auto ml-1 md:ml-5 flex">
            <h1 className='uppercase '>{title}</h1>
            <div className="page-export-button px-2  hover:shadow-xl ">
              {isLoading?
              <button disabled><AiFillFileExcel className='text-[#145632] text-[25px]' /></button>
              :
              <button onClick={handleExportToExcel}><AiFillFileExcel className='text-[#145632] text-[25px]' /></button>
              }
            </div>
          </div>
          <div className="page-search-and-add-new-button flex my-auto mr-1 md:mr-5">

            <div className='mx-2 hidden md:block'>
                <select
                  value={ticketCategoris}
                  onChange={(e) => handleCategoryFilteringChange(e.target.value)}
                  className='text-[#74747B] text-[14px] font-semibold border rounded-[5px] focus:outline-none py-[5px] focus:bg-white focus:border-[#F35B5B]'
                >
                  <option value=''>All Categories</option>
                  <option value="11">General Query</option>
                  <option value='6'>Insatllation Bill</option>
                  <option value='7'>Service Bill</option>
                  <option value='1'>Online Query</option>
                  <option value='4'>Corporate Purchase</option>
                  <option value='2'>Dealership Query</option>
                  <option value='5'>HVAC</option>
                  <option value='3'>Hire Purchase</option>
                  <option value='8'>Tele-Salse</option>
                  <option value='9'>Happy Call</option>
                  <option value='10'>Outbound CRM</option>
                </select>
              </div>

            <div className='page-date-range hidden md:flex my-auto mr-2'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>

            <div className="page-search mr-2 hidden md:block">
              <div className="relative">
                <input
                  className="page-search-input w-[140px] md:w-[160px] lg:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                  type="text"
                  name="search"
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search here"
                />
                <div className="text-[10px] md:text-xl absolute top-[5px] right-3">
                  <button className="page-search-button" type="submit">
                    <AiOutlineSearch className="text-[#fff] mx-auto" />
                  </button>
                </div>
              </div>
            </div>
            {user?.menu_permissions?.includes("2.1.1") && (
            <div className="add-new-button px-2">
              <Link to={CreateNewTicketURL}>Add New</Link>
            </div>
            )}
          </div>
        </div>

        <div className='mb-3 px-1 pt-4 flex justify-between md:hidden'>
          <div className='rounded-[10px] mr-1'>
                  <select
                    value={ticketCategoris}
                    onChange={(e) => handleCategoryFilteringChange(e.target.value)}
                    className='text-[#74747B] w-[160px] text-[14px] font-semibold border rounded-[5px] focus:outline-none py-1 focus:bg-white focus:border-[#F35B5B]'
                  >
                    <option value=''>All Categories</option>
                    <option value='Insatllation Bill'>Insatllation Bill</option>
                    <option value='Service Bill'>Service Bill</option>
                    <option value='Online Query'>Online Query</option>
                    <option value='Dealership Query'>Dealership Query</option>
                    <option value='HVAC'>HVAC</option>
                    <option value='Hire Purchase'>Hire Purchase</option>
                    <option value='Tele-Salse'>Tele-Salse</option>
                    <option value='Happy Call'>Happy Call</option>
                  </select>
          </div>

          <div className="page-search">
            <div className="relative">
              <input
                className="page-search-input w-[160px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search here"
              />
              <div className="text-[10px] md:text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="submit">
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className='pb-4 px-1 md:hidden'>
        <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>

      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
      <>
        <div className='overflow-x-auto'>
        <Table
          columns={[
            ...columnsConfig.map((column) => ({
              ...column,
              sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
            })),

            {
              title: 'Actions',
              dataIndex: 'actions',
              render: (_, record) => (
                <div className='flex gap-2'>

                  {user?.menu_permissions?.includes("2.3.1") && (
                  <>
                    {(record.category.some(cat => cat === "Insatllation Bill" || cat === "Service Bill")) && (
                      <button onClick={() => navigate(`${TechnicianAssaignURL}/${record.id}`)} className='rounded'>
                        <BsFillPeopleFill className='text-blue-500 text-xl' />
                      </button>
                    )}
                  </>
                  )}
                  
                  {user?.menu_permissions?.includes("2.1.3") && (
                  <button onClick={() => navigate(`${dataEditURL}/${record.id}`)} className='rounded'>
                    <AiFillEdit className='text-[#6F6F7E] text-xl' />
                  </button>
                  )}

                  {user?.menu_permissions?.includes("2.1.2") && (
                  <button onClick={() => navigate(`${SingleTicketViewURL}/${record.id}`)} className='rounded'>
                    <AiFillEye className='text-[#453eaf] text-xl' />
                  </button>
                  )}  

                  {/* <button className='rounded' onClick={() => handleDelete(record.id)}>
                    <AiFillDelete className='text-xl text-[#F35B5B]' />
                  </button> */}

                </div>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <>
            {record.customer && record.customer.length > 0 && (
              <div className='grid md:grid-cols-2 my-auto px-2 py-4 gap-x-16 gap-y-2 border-b pb-2 shadow-lg border'>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[30%]'>Customer</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.name}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[30%]'>Phone</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.phone_no}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[30%]'>Email</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.email}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[30%]'>Address</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.address}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[30%]'>Ticket Category</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[65%] text-end'>{record[expandableKey1].join(', ')}</p>
                        </div>
                    </p>
                </div>
              )}
              </>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={filteredData}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            setSortOrder({
              columnKey: sorter.field,
              order: sorter.order,
            });
          }}
        />
        </div>
      
      <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          onChange={handlePaginationChange}
          showSizeChanger={false}
        />
      </div>
  </>
      )}
    </div>
  );
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CommonPendingTicketList);