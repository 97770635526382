import React, { useEffect, useState } from 'react'
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { useNavigate } from 'react-router-dom';

const VendorInvoiceCreateHandleComponent = ({
  assignedID
}) => {
    const [formData,setFormData] = useState({
        vendor_assigned_id : null,
        basic_amount : '',
        basic_amount_quantity:'',
        additional_amount : '',
        total_payable_amount : '',
    })
    const navigate = useNavigate();


  const handleInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      let inputValue;

      if (type === 'checkbox') {
          inputValue = checked;
      } else if (type === 'file') {
          inputValue = files[0];
      } else {
          inputValue = value;

          if (parseFloat(value) < 0) {
            ShowToast('error', `Negative values are not allowed for ${name}.`);
            return;
          }

          if (['basic_amount','additional_amount','total_payable_amount'].includes(name)) {
              const parts = value.split('.');
              const integerPart = parts[0];
              const decimalPart = parts[1] || '';

              // If the total number of digits exceeds 20, show an error message
              if (integerPart.length > 14) {
                  ShowToast( 'error', `Ensure that there are no more than 14 digits`);
                  return;
              }

              // If decimal part exists, limit it to 6 decimal places
              if (parts.length === 2) {
                  inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;

                  // If more than 6 digits after the decimal point, show a separate error message
                  if (decimalPart.length > 2) {
                      ShowToast('error',`More than 2 digits after the decimal point for ${name}.`);
                  }
              }
          }
      }

      setFormData({
          ...formData,
          [name]: inputValue
      });
  };



  useEffect(() => {
    const basicAmount = parseFloat(formData.basic_amount || 0);
    const basicAmountQuantity = parseFloat(formData.basic_amount_quantity || 0);
    const additionalAmount = parseFloat(formData.additional_amount || 0);
    const totalAmount= (basicAmount * basicAmountQuantity) + additionalAmount;
    setFormData(prevState => ({ ...prevState, total_payable_amount: totalAmount }));
  }, [formData.basic_amount, formData.basic_amount_quantity, formData.additional_amount]);




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const UpdateFormData = {
        ...formData,
        vendor_assigned_id : assignedID? assignedID : null,
      }
      const response = await authAxiosWithBearer.post(`${baseURL}/billing/api/assigned-vendor-invoice-create/`, UpdateFormData);

      if (response) {
        console.log(response);
        ShowToast('success', 'Successfully Submit');
        navigate('/assigned-vendor-list-for-billing');

      }
    } catch (error) {
      console.log(error);
      if(error.response.data.error){
        ShowToast('error', `${error.response.data.error}`);
      }else{
        ShowToast('error', 'Something is wrong');
      }
    }
  };
    

  return {
    formData,
    setFormData,
    handleInputChange,
    handleSubmit,
  }
}

export default VendorInvoiceCreateHandleComponent