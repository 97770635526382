import React from 'react'
import DamagePartsDetailsCom from './DamagePartsComponents/DamagePartsDetailsCom'
import { useParams } from 'react-router-dom'

const DamagePartsDetails = () => {
  const {id} = useParams()
  return (
    <>
      <DamagePartsDetailsCom id={id} apiEndPoint={'inventory/api/damage-parts'} />
    </>
  )
}

export default DamagePartsDetails