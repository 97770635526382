import { useState, useEffect } from 'react';
import { authAxiosWithBearer, baseURL } from '../baseURL';

const useFetchDetailsData = (endpoint, { id }) => {
  const [detailsData, setDetailsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDetailsData = async () => {
    try {
        const response = await authAxiosWithBearer.get(`${baseURL}/${endpoint}/${id}/`);
        setDetailsData(response.data)
    } catch (error) {
        console.log(error)
    }
    finally{
      setLoading(false)
    }
};

useEffect(() => {
  fetchDetailsData();
}, [id]); 

  return { detailsData, loading, error, fetchDetailsData };
};

export default useFetchDetailsData;
