import React from 'react'
import SRIssuedReturnFormCom from './SRIssuedReturnComponents/SRIssuedReturnFromComponets/SRIssuedReturnFormCom'
import { useParams } from 'react-router-dom'

const SRIssuedReturnAdd = () => {
    const {id} = useParams ()
  return (
    <div><SRIssuedReturnFormCom title={'SR Issued Return Form'} issued_id={id} /></div>
  )
}

export default SRIssuedReturnAdd