import React from 'react'

const PrItemComponent = ({itemDatas,title}) => {
  return (
    <div className='overflow-x-auto overflow-y-auto p-2'>
        <div className='py-1 text-[20px] font-semibold text-[#000000]'>{title}</div>
        <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> UOM  </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>
                    <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Issue Status </th>

                </tr>

            </thead>
            <tbody className='text-center'>

                {itemDatas?.map((item, index) => (
                    <tr >
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{++index}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                            name : {item?.pr_item_name} <br />
                            size : {item?.pr_item_size} <br />
                            specification :{item?.pr_item_specification} <br />
                            brand : {item?.pr_item_brand}
                        </td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.pr_item_uom}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.pr_item_qty}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                        <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                            {item.item_workorder_status === 'Workorder' ?
                                <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Workorder</div>
                                : item.item_workorder_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div>
                                    : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Pending</div>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>

        </table>
    </div>
  )
}

export default PrItemComponent