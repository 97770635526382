import { useState, useEffect } from 'react';
import { authAxiosWithBearer, baseURL } from '../baseURL';

const useFetchListData = (endpoint, { page, search, status, start_date, end_date }) => {
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await authAxiosWithBearer.get(`${baseURL}${endpoint}`, {
        params: { page, search, status, start_date, end_date },
      });

      console.log(`${endpoint}-response`,response)
      
      const { results, count } = response.data;
      setTotalItems(count);
      setData(results);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [endpoint, page, search, status, start_date, end_date]);

  return { data, totalItems, isLoading, error, fetchData };
};

export default useFetchListData;
