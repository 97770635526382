import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../Components/formatDateTime';
import { connect } from 'react-redux';
import ShowToast from '../../../Components/ShowToast';
import DateRangeFiltering from '../../../Components/DateRangeFiltering';

const RequestedTicketListComponent = ({user,apiEndpoint,pageTitle,editUrl}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [requetedData, setRequetedData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);


  useEffect(() => {
    const fetchgRnData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/${apiEndpoint}`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
  
        setTotalItems(count);
        setRequetedData(results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchgRnData();
  }, [startDate, endDate, apiEndpoint, currentPage, searchText]);




  const dataSource = requetedData?.flatMap((data,index) =>({
    key:index+1,
    id:data?.id,
    requested_id: data?.requested_id,
    created_at  :data?.created_at,
    customer_name:data?.customer_name, 
    customer_phone: data?.customer_phone,
    invoice_no: data?.invoice_no,
    ticket_no:data?.ticket_no,
    requested_status:data?.requested_status
  }))



  const handleSearch = (value) => {
    setSearchText(value);
  };
  

  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Requested Id',
      dataIndex: 'requested_id',
      key: '1',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: '2',
    },
    {
      title: 'Customer Phone',
      dataIndex: 'customer_phone',
      key: '4',
      responsive: ['md']
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: '4',
      responsive: ['md']
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: '5',
      responsive: ['md']
    },
    {
      title: 'REQUESTED DATE',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        const formattedDateTime = formatDateTime(created_at);
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },
    {
        title: 'ACTIONS',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className='flex gap-2'>
            {user?.menu_permissions?.includes("2.1.1") && (
              <button onClick={() => navigate(`/showroom-ticket-request-details-for-ccd/${record.id}/`)} className='rounded'>
                <AiFillEye className='text-[#282bf0] text-xl' />
              </button>
            )}
            {user?.menu_permissions?.includes("2.1.1") && record?.requested_status === false && (
              <button onClick={() => navigate(`/showroom-request-wise-ticket-create/${record.id}/`)} className='bg-[#EB5757] text-[#fff] p-1 rounded'>
                 Ticket Create
              </button>
            )} 

            {user?.primary_role == 8 && (
              <button onClick={() => navigate(`/showroom-ticket-request-details/${record.id}/`)} className='rounded'>
                <AiFillEye className='text-[#282bf0] text-xl' />
              </button>
            )}
            {user?.primary_role == 8 && editUrl !== '' && (
              <button onClick={() => navigate(`${editUrl}/${record.id}/`)} className='rounded'>
                <AiFillEdit className='text-[#727272] text-xl' />
              </button>
            )}
          </div>
        ),
      },
  ];

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div className='container-fluid grid grid-cols-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>{pageTitle}</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>

            {user?.primary_role == 8 && (
            <div className="add-new-button px-2 ml-1">
                  <Link to={`/new-ticket-request`}>Add Request</Link>
            </div>
            )}
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4 justify-between md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>

      <div className='shadow-lg overflow-x-auto'>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
            <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={10}
            onChange={handlePaginationChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
export default connect(mapStateToProps)(RequestedTicketListComponent);
