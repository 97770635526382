import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';

const SrToPrFormHandleCom = ({srToPrItems,srID,GsrID,IHsrID}) => {
    const navigate = useNavigate()

    const [requisionItemOptions, setRequisionItemOptions] = useState([]);
    const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
    const [requisionIssueRequiredMessage,setRequisionIssueRequiredMessage]=useState('')


    const [requisionPrFormData, setRequisionPrFormData] = useState({
        aditional_sr_no: null,
        srtopritem_set: [
          {
            pr_item: null,
            pr_item_qty:'',
            max_issue_qty:'',
            remarks:'',
          },
        ],
      });



    useEffect(() => {

        const options = srToPrItems
        ?.filter(data => data?.sr_to_pr_available_quantity > 0)
        ?.map(data => ({
          value: data.item,
          label: data.item_name,
          uom_value: data.uom_text,
          pr_item_qty: data.quantity,
          max_issue_qty: data.sr_to_pr_available_quantity
        }));
        setRequisionItemOptions(options);

        // Auto-select all items for srtopritem_set
        const initialInvReqDetails = options?.map((option) => ({
            pr_item: option.value,
            pr_item_qty: parseFloat(option.pr_item_qty),
            uom_value: option.uom_value,
            max_issue_qty:parseFloat(option.max_issue_qty),
        }));

        console.log('initialInvReqDetails',initialInvReqDetails)

        setRequisionPrFormData((prevData) => ({
            ...prevData,
            srtopritem_set: initialInvReqDetails,
        }));
        setSelectedRequisionItem(options);

    }, [srToPrItems]);
    

          

    const handleRequisionItemChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionPrFormData };
        
        updatedFormData.srtopritem_set[index] = {
            pr_item: selectedOption?.value || null,
            max_issue_qty: selectedOption?.max_issue_qty || null,
            pr_item_qty: selectedOption?.pr_item_qty || null,
            uom_value: selectedOption?.uom_value || null,
        };
        
        const updatedSelectedRequisionItem = [...selectedRequisionItem];
        updatedSelectedRequisionItem[index] = selectedOption || null;
        
        setSelectedRequisionItem(updatedSelectedRequisionItem);

        setRequisionPrFormData(updatedFormData);
        };
        

    const handleRequisionPrInputChange = (e, index, type) => {
        const updatedData = { ...requisionPrFormData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'srtopritem_set' && name === 'pr_item_qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }
      
        if (type === 'srtopritem_set') {
          setRequisionIssueRequiredMessage('');
          updatedData.srtopritem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setRequisionPrFormData(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            requisionPrFormData.srtopritem_set &&
            requisionPrFormData.srtopritem_set.every((item) => {
                return item?.pr_item !== null && item?.pr_item_qty !== '';
            })
        );
    };
    
      
    const handleAddRequisionPrItem = () => {
        if (areAllFieldsFilled()) {
            setRequisionPrFormData((prevState) => ({
                ...prevState,
                srtopritem_set: [
                    ...prevState.srtopritem_set,
                    {
                        pr_item: null,
                        pr_item_qty:'',
                    },
                ],
            }));
            setRequisionIssueRequiredMessage('');
        } else {
            setRequisionIssueRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    

    const handleRemoveRequisionPrItem = (index) => {
        const srtopritem_set = [...requisionPrFormData.srtopritem_set];
        setRequisionIssueRequiredMessage('');
    
        // Remove the item from the array
        const removedItem = srtopritem_set.splice(index, 1)[0];
    
        // Remove the corresponding items from selectedRequisionItem and selectedRequisionStock arrays
        const updatedSelectedRequisionItem = selectedRequisionItem.filter((item, i) => i !== index);

        setRequisionPrFormData({
            ...requisionPrFormData,
            srtopritem_set,
        });
    
        setSelectedRequisionItem(updatedSelectedRequisionItem);
    };
    

    const handleRequisionIssueSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const upRequisionPrFormData = {
                ...requisionPrFormData,
                aditional_sr_no: srID,
                general_sr_no:GsrID,
                ih_sr_no:IHsrID,
            }
            const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/sr-to-pr/`, upRequisionPrFormData);
            
            console.log('requisionPrFormData',requisionPrFormData)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Submit');
                navigate('/sr');
            }
        } catch (error) {
            console.log(error);
            ShowToast('error', 'Something is wrong');
        }
    };
    
  return {
    navigate,
    requisionItemOptions,
    selectedRequisionItem,
    requisionIssueRequiredMessage,
    requisionPrFormData,
    setRequisionPrFormData,
    handleRequisionItemChange,
    handleRequisionPrInputChange,
    handleAddRequisionPrItem,
    handleRemoveRequisionPrItem,
    handleRequisionIssueSubmit,
  }
}

export default SrToPrFormHandleCom