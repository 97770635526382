import React, { useEffect, useState } from 'react'
import AddPageFormTitle from '../../../Components/Form/FormPageTitle'
import InputFiled from '../../../Components/Form/InputFiled'

import InputFile from '../../../Components/Form/InputFile'
import SelectInput from '../../../Components/Form/SelectInput'
import DateInput from '../../../Components/Form/DateInput'
import Checkbox from '../../../Components/Form/Checkbox'
import CustomerAddComponent from './CustomerComponents/CustomerAddComponent'
import TextareaInput from '../../../Components/Form/TextareaInput'



function CustomerAdd() {
  const {
        customerFormData,
        loading,
        customerErrorMessage,
        handleCustomerInputChange,
        handleGenderChange,
        handleDistrictChange,
        handleUpazilaChange,
        handleCustomerChange,
        handleFromDateChange,
        genderOptions,
        selectedCustomer,
        customerOptions,
        selectedDistrict,
        districtOptions,
        selectedUpazila,
        upazilaOptions,
        handleCustomerSubmit,
        handleCustomerSearchInputChange,
       } = CustomerAddComponent();

  return (
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
        <AddPageFormTitle title='Customer Add Form' FormPageRightSideButtonURL='/customer-list' LinkName='Close' />
          <form onSubmit={handleCustomerSubmit}>
            <div className="grid grid-cols-2 lg:grid-cols-3">
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.name} onChange={handleCustomerInputChange} name='name' id='name'  label='Name *' isRequired={true} placeholder='Enter customer name'  />
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.phone_no} onChange={handleCustomerInputChange} name='phone_no' id='phone_no' label='Phone Number *' isRequired={true} placeholder='Enter phone number'/>
                  {customerErrorMessage && (
                        <p className='text-red-500'>{customerErrorMessage}</p>
                      )}
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.age} onChange={handleCustomerInputChange} name='age' id='age' label='Age' isRequired={false} placeholder='Enter customer age'  />
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.profession} onChange={handleCustomerInputChange} name='profession' id='profession' label='Profession' isRequired={false} placeholder='Enter customer profession'  />
              </div>
              <div className='my-4'>
                  <DateInput 
                    value={customerFormData.date_of_birth}
                    onChange={handleFromDateChange} 
                    dateFormat='yyyy-MM-dd' 
                    id='date_of_birth' label='Date Of Birth'
                    isRequired={false} 
                    placeholder='Enter customer DOB'  
                />
              </div>

              <div className='my-4'> 
                    <InputFile type='file' className='py-1' onChange={handleCustomerInputChange} name='photo' id='photo' label='Photo' isRequired={false} placeholder='Select photo'/>
                </div>

              <div className="my-4">
                  <SelectInput
                      id="gender"
                      name="gender"
                      label='Gender'
                      value={genderOptions.find((option) => option.value === customerFormData.gender)}
                      onChange={handleGenderChange}
                      options={genderOptions}
                  />
              </div>
              <div className='my-4'>  
              <SelectInput
                      name='ref_customer'  
                      id='ref_customer'  
                      label='Ref customer'
                      value={selectedCustomer}
                      onChange={handleCustomerChange}
                      onInputChange = {handleCustomerSearchInputChange}
                      options={customerOptions}
                      isRequired={false} 
                  />
              </div>
              
              <div className='my-4'>
                  <InputFiled type='email' value={customerFormData.email} onChange={handleCustomerInputChange} name='email' id='email' label='Email' isRequired={false} placeholder='Enter email'  />
              </div>
        
              <div className='my-4'>  
              <SelectInput
                      name='district'  
                      id='district'  
                      label='District'
                      value={selectedDistrict}
                      onChange={handleDistrictChange}
                      options={districtOptions}
                      isRequired={false} 
                  />
              </div>
              <div className='my-4'>  
              <SelectInput
                      name='upazila'  
                      id='upazila'  
                      label='Upazila'
                      value={selectedUpazila}
                      onChange={handleUpazilaChange}
                      options={upazilaOptions}
                      isRequired={false} 
                  />
              </div>
              <div className='my-4'>
                  <InputFiled type='text' value={customerFormData.post_code} onChange={handleCustomerInputChange} name='post_code' id='post_code' label='Zip/Post Code' isRequired={false} placeholder='Enter zip/post code'/>
              </div>

              <div className='my-4'>
                  <TextareaInput className='h-[41.9px]' type='text' value={customerFormData.address} onChange={handleCustomerInputChange} name='address' id='address' label='Address' isRequired={false} placeholder='Enter full address'/>
              </div>

              <div className=" my-4">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>{loading ? 'Submitting...' : 'Submit'}</button>
              </div>
            </div>

          </form>
    </div>
  )
}

export default CustomerAdd