import React from 'react'
import useFetchDetailsData from '../../../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { formatDateTime } from '../../../../Components/formatDateTime';

const DamagePartsDetailsCom = ({id,apiEndPoint}) => {

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData(`${apiEndPoint}`, {id});
  
    if(loading){
        return <div><LoadingSpinner /> </div>
    }

  return (
    <div className="req-detail pb-5 mx-4">
    <div className="user-details-page-title mb-5 shadow flex justify-between  px-5 py-2">
        <div className="my-auto py-2">
          <h1 className='uppercase'>Damage Parts Return Details</h1>
        </div>
      </div>

        <div className='border border-[#e25050] shadow-lg'>
            <div className="mx-2">
                <h1 className='py-1 text-[18px] font-semibold text-[#000000]' >Basic Information</h1> <hr className='my-2' />
            </div>
            <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2'>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>DMP NO</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.dpr_no}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Created Date</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Source Type</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.source_type}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Created By</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.created_by_text}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Return By</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.return_by}</p>
                    </div>
                </p>
            </div>

            <div>

            <div className='py-1 mx-2 text-[18px] font-semibold text-[#000000]'>Item Information <hr /></div>

                <table className='table-auto border-1 border-[#ED6C6C] m-2  max-w-[100%] text-[9px] sm:text-[12px]'>

                    <thead>

                        <tr className='bg-[#EEEEEE]'>

                            <th className='border border-[#ED6C6C] w-[400px] py-3'>SN</th>
                            <th className='border border-[#ED6C6C] w-[400px] py-3'>Specification</th>
                            <th className='border border-[#ED6C6C] w-[400px] py-3'> UOM  </th>
                            <th className='border border-[#ED6C6C] w-[400px] py-3'>Quantity </th>
                            <th className='border border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

                        </tr>

                    </thead>
                    <tbody className='text-center'>

                    {detailsData?.damagepartsitem_set?.map((item,index)=>(
                        <tr >
                            <td className='border border-[#ED6C6C] w-[400px] py-3'>{++index}</td>
                            <td className='border border-[#ED6C6C] w-[400px] py-3'>
                                name : {item?.item_name} <br />
                                size : {item?.item_size} <br />
                                specification :{item?.item_specification} <br />
                                brand : {item?.item_brand}
                            </td>
                            <td className='border border-[#ED6C6C] w-[400px] py-3'>{item?.uom_text}</td>
                            <td className='border border-[#ED6C6C] w-[400px] py-3'>{item?.qty}</td>
                            <td className='border border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                        </tr>
                    ))}
                    </tbody>

                </table>

            </div>
        </div>
    </div>
  )
}

export default DamagePartsDetailsCom