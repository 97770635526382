import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import SrToPrFormHandleCom from './SrToPrFormHandleCom';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC';

function SrToPrFormCom({isModalOpen ,setIsModalOpen ,srToPrItems, srID,GsrID,IHsrID}) {

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const {
        navigate,
        requisionItemOptions,
        selectedRequisionItem,
        requisionIssueRequiredMessage,
        requisionPrFormData,
        setRequisionPrFormData,
        handleRequisionItemChange,
        handleRequisionPrInputChange,
        handleAddRequisionPrItem,
        handleRemoveRequisionPrItem,
        handleRequisionIssueSubmit,
        } = SrToPrFormHandleCom({srToPrItems,srID,GsrID,IHsrID})

        console.log('srToPrItems',srToPrItems)

  return (
    <div>
          <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Insatllation Modal"
              className="modal"
              overlayClassName="overlay"
          >
              <div className='mb-2 w-[360px] md:w-[767px] lg:w-[1050px]'>
                  <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
                      <h1 className='font-semibold'>SR TO PR </h1>
                      <button className='text-[#000]' onClick={closeModal}><ImCancelCircle /></button>
                  </div>

                    {requisionItemOptions?.length > 0?
                    <div className="mx-auto my-10 overflow-y-auto max-h-[400px]">
                        <form onSubmit={handleRequisionIssueSubmit}>
                            {requisionPrFormData?.srtopritem_set?.map((requisionItems, index) => (

                                <div className="flex  mx-4 mt-2 gap-x-4 gap-y-2 border-b">
                                    <div className="my-4">
                                        <SelectInputWC
                                            label='Item*'
                                            id={`item_${index}`}
                                            name={`srtopritem_set[${index}]?.pr_item`}
                                            value={selectedRequisionItem[index]}
                                            onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                                            options={requisionItemOptions.filter(
                                                (option) =>
                                                    !selectedRequisionItem.some(
                                                        (selectedItem) => selectedItem && selectedItem.value === option.value
                                                    )
                                            )}
                                            placeholder='Select item'
                                            isRequired={true}
                                            isClearable={true}
                                            className={`appearance-none border-[1px] w-[170px] md:w-[200px] lg:w-[220px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                        />
                                    </div>

                                    <div className='my-4'>
                                        <InputFiledWC type='text'
                                            name='uom_value' label='UOM*'
                                            id={`uom_value${index}`}
                                            value={requisionItems.uom_value}
                                            // onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                            isRequired={true}
                                            placeholder='uom_value'
                                            className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                        />
                                    </div>


                                    <div className='my-4'>
                                        <InputFiledWC type='number'
                                            name='pr_item_qty' label='Item Qty*'
                                            id={`pr_item_qty${index}`}
                                            value={requisionItems.pr_item_qty}
                                            onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                            isRequired={true}
                                            placeholder='Enter item qty'
                                            className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                            max={requisionItems?.max_issue_qty}
                                            min={0}
                                        />
                                    </div>
                                    <div className='my-4'>
                                        <InputFiledWC type='number'
                                            name='pr_item_qty' label='Available Qty*'
                                            id={`pr_item_qty${index}`}
                                            value={requisionItems.max_issue_qty}
                                            onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                            isRequired={true}
                                            placeholder='Enter item qty'
                                            className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className='my-4'>
                                        <TextareaInputWC
                                            label='Remarks'
                                            name='remarks'
                                            id='remarks'
                                            placeholder={`Enter remarks`}
                                            value={requisionItems.remarks}
                                            onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                            className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                        />
                                    </div>

                                    <div className='mt-10 mx-10 mb-2'>
                                        {index !== 0 && (
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveRequisionPrItem(index)}
                                                className="text-red-500"
                                            >
                                                <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                            </button>
                                        )}
                                    </div>

                                </div>
                            ))}

                            {requisionIssueRequiredMessage && (
                                <span className='text-red-500 mx-4'>{requisionIssueRequiredMessage} <br /> </span>
                            )}
                            <div className="flex justify-between mx-4 my-2">

                                <button
                                    className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                                    type="button"
                                    onClick={() => {
                                        console.log('Add new item Button Clicked');
                                        handleAddRequisionPrItem();
                                    }}
                                >ADD MORE</button>
                                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="mx-auto my-10 overflow-y-auto max-h-[400px]">
                        <p className='text-[20px] text-center text-[#EB5757] mt-6 uppercase font-semibold'>No Availavle Item For SR to Pr</p>
                    </div>
                   }
              </div>

          </Modal>
    </div>
  )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(SrToPrFormCom);