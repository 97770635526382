import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';


function SupplierAddEditComponent() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name : '',
        address_1 : '',
        email : '',
        phone : '',
        vat_reg : '',
        license_no : '',
        contact_person : '',
        contact_person_no : '',
        type : null,
      });

      const supplierTypeOption = [
        { value: 'Supplier', label: 'Supplier' },
        { value: 'Vendor', label: 'Vendor' },
    ];

    const handleInputChange = (e) => {
      const { name, value } = e.target;
    
      let inputValue = value;
    
      if (name === 'phone') {
        // Remove non-numeric characters and limit to 11 digits
        inputValue = value.replace(/\D/g, '').slice(0, 11);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: inputValue,
      }));
    };
    
    
    const handleSupplierTypeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setFormData(prevState => ({ ...prevState, type: selectedOption.value }));
          console.log('selectedOption',selectedOption)
        } else {
          setFormData(prevState => ({ ...prevState, type: null }));
        }
      };


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/supplier-add/`, formData);
        if (response) {
          console.log(response);
          navigate('/supplier-list');
          ShowToast('success', 'Successfully Add');
        }
      } catch (error) {
        console.log(error);
        ShowToast('error', 'Something is wrong');
      } finally {
        setLoading(false);
      }
    };


  return (
    {
        navigate,
        loading,
        setLoading,
        formData,
        setFormData,
        handleInputChange,
        supplierTypeOption,
        handleSupplierTypeChange,
        handleSubmit,
    }
  )
}

export default SupplierAddEditComponent;