import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import ShowToast from '../../../../Components/ShowToast';



function WorkorderFormHandleCom({id}) {
    const location = useLocation();
    const selectedPrIds = location.state?.selectedIds || [];
    const [prNoOptions, setPrNoOptions] = useState([])
    const [selectedPrNo, setSelectedPrNo] = useState(null);
    const [prDetailsData, setPrDetailsData] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [inputValues, setInputValues] = useState({});
    const [singleItemTotalPrices, setSingleItemTotalPrices] = useState({});
    const [overallTotal, setOverallTotal] = useState(0);
    const [qtyErrorMessages, setQtyErrorMessages] = useState({});
    const navigate = useNavigate()
    const [prSearchInputValue , setPrSearchInputValue] = useState(null)

    const [selectedDiscountType, setSelectedDiscountType] = useState({});

    const discountTypeOptions = [
      { value: 'percentage', label: 'Percentage' },
      { value: 'fixed', label: 'Fixed Amount' },
  ];


    useEffect(() => {
        const fetchSelectedPRData = async () => {
            try {
            const roleData = selectedPrIds?.map((roleId, index) => ({
                value: roleId.id,
                label: roleId.prNo,
            }));
            setSelectedPrNo(roleData);

            } catch (error) {
            console.log(error);
            }
        };
        
        fetchSelectedPRData();
    }, []);


    useEffect(() => {
        authAxiosWithBearer.get(`${baseURL}/inventory/api/sr-to-pr/?search=${prSearchInputValue}`)
            .then(response => {
              setPrNoOptions(response.data.results.filter((fData)=>fData.workorder_status !== 'Ordered')
                .map(data => ({
                  value: data.id,
                  label: data.pr_no
                })));

                console.log('pr data',response.data.results)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [prSearchInputValue]);


    const handlePrSearchInputChange = (value) => {
      setPrSearchInputValue(value);
    };


    const handlePrNoChange = (selectedOptions) => {
        setSelectedPrNo(selectedOptions);
    };

    const handleSupplierChange = (selectedSupplier) =>{
        setSelectedSupplier(selectedSupplier);
      }

    const handlePaymentTypeChange = (selectedPaymentType) =>{
        setSelectedPaymentType(selectedPaymentType);
      }

      
    // fetchPrDetailsData
    const fetchPrDetailsData = async () => {
        try {
            const datas = selectedPrNo?.map(async (data) => {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sr-to-pr/${data.value}/`);
                return response.data;
            });

            const results = await Promise.all(datas);
            const objectResults = results.filter(value => typeof value === 'object');
            setPrDetailsData(objectResults)
    
        } catch (error) {
            console.log('error',error);
        }
    };
    
    useEffect(() => {
        fetchPrDetailsData();
    }, [selectedPrNo]);



    const handleHeaderCheckboxChange = () => {
        const allItems = prDetailsData.flatMap((prData) => prData?.srtopritem_set || []);
        const allItemIDs = allItems.map((item) => item.id);
        const newSelectedItems = selectAll ? [] : allItemIDs;

        setSelectedItems(newSelectedItems);
      };
    

    const handlePrCheckboxChange = (prIndex) => {
        const prData = prDetailsData[prIndex];
        const prItemIDs = prData?.srtopritem_set?.map((item) => item.id) || [];
    
        // Check if all prItem checkboxes are selected
        const allPrItemsSelected = prItemIDs.length === selectedItems.filter((id) => prItemIDs.includes(id)).length;
    
        let newSelectedItems;

        console.log('allPrItemsSelected',allPrItemsSelected)
    
        if (!allPrItemsSelected) {
            // If all prItem checkboxes are not selected, merge item IDs
            newSelectedItems = [...selectedItems, ...prItemIDs];
        } else {
            // If all prItem checkboxes are selected, remove its item IDs
            newSelectedItems = selectedItems.filter((id) => !prItemIDs.includes(id));
        }

        console.log('newSelectedItems',newSelectedItems)
    
        setSelectedItems(newSelectedItems);
    };
    
    

    const handleItemCheckboxChange = (prIndex, itemIndex) => {
        const itemID = prDetailsData[prIndex]?.srtopritem_set?.[itemIndex]?.id;
        const newSelectedItems = selectedItems.includes(itemID)
            ? selectedItems.filter((id) => id !== itemID)
            : [...selectedItems, itemID];

        setSelectedItems(newSelectedItems);
    };

    const selectAll = selectedItems.length === prDetailsData.flatMap((prData) => prData.srtopritem_set).length;


    const handleyDiscountTypeChange = (itemData, selectedOption) => {
      const value = selectedOption ? selectedOption.value : '';
      setSelectedDiscountType(prevValues => {
        const updatedValues = {
          ...prevValues,
          [`discount_type_${itemData?.id}`]: value,
        };
        
        // Validate discount fields based on the updated discount type
        validateDiscountFields(itemData, value, inputValues[`discount_price_${itemData?.id}`]);
    
        return updatedValues;
      });
    
      console.log('Updated selectedDiscountType:', selectedDiscountType);
      console.log(`Discount type for item ${itemData?.id} updated to: ${value}`);
    };
    


    const validateDiscountFields = (itemData, discountType, discountPrice) => {
      if (discountType && !discountPrice) {
        setQtyErrorMessages(prevErrors => ({
          ...prevErrors,
          [`discount_price_${itemData?.id}`]: 'Required',
        }));
      } else if (!discountType && discountPrice) {
        setQtyErrorMessages(prevErrors => ({
          ...prevErrors,
          [`discount_type_${itemData?.id}`]: 'Required',
        }));
      } else {
        setQtyErrorMessages(prevErrors => ({
          ...prevErrors,
          [`discount_price_${itemData?.id}`]: '',
          [`discount_type_${itemData?.id}`]: '',
        }));
      }
    };
    

    const handleInputChange = (itemData, fieldName, value) => {
      if (fieldName === 'wo_item_qty') {
        const woQty = parseFloat(value || 0);
        // const availableQty = itemData?.wo_available_qty || 0;
        const previousQty = inputValues[`p_wo_item_qty_${itemData?.id}`]
        const availableQty = itemData?.wo_available_qty + parseFloat(previousQty?previousQty:0) || 0;
    
        if (woQty > availableQty) {
          setQtyErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${fieldName}_${itemData?.id}`]: 'Quantity exceeds available quantity.',
          }));
        } else {
          setQtyErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${fieldName}_${itemData?.id}`]: '',
          }));
        }
      }
    
      const numericFields = ['price', 'wo_item_qty', 'discount_price'];
    
      if (numericFields.includes(fieldName)) {
        const parts = value.split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1] || '';
    
        // If the total number of digits exceeds 14, show an error message
        if (integerPart.length > 14) {
          ShowToast('error', `Ensure that there are no more than 14 digits for ${fieldName}`);
          return;
        }
    
        // If decimal part exists, limit it to 6 decimal places
        if (parts.length === 2) {
          value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    
          // If more than 6 digits after the decimal point, show a separate error message
          if (decimalPart.length > 2) {
            ShowToast('error', `More than 2 digits after the decimal point for ${fieldName}.`);
          }
        }
      }

    // Conditional validation for discount fields
    if (fieldName === 'discount_price') {
      const discountType = selectedDiscountType[`discount_type_${itemData?.id}`] || '';
      validateDiscountFields(itemData, discountType, value);
    }
    
      setInputValues((prevValues) => ({
        ...prevValues,
        [`${fieldName}_${itemData?.id}`]: value,
      }));
    };


    const handleInputChangeForUpdate = (itemData, fieldName, value) => {

      if (fieldName === 'wo_item_qty') {
        const woQty = parseFloat(value || 0);
        const previousQty = inputValues[`p_wo_item_qty_${itemData?.id}`]
        const availableQty = itemData?.wo_available_qty + parseFloat(previousQty?previousQty:0) || 0;

        console.log('availableQty',availableQty)
        if (woQty > availableQty) {
          setQtyErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${fieldName}_${itemData?.id}`]: 'Quantity exceeds available quantity.',
          }));
        } else {
          setQtyErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${fieldName}_${itemData?.id}`]: '',
          }));
        }
      }
    
      const numericFields = ['price', 'wo_item_qty', 'discount_price'];
    
      if (numericFields.includes(fieldName)) {
        const parts = value.split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1] || '';
    
        // If the total number of digits exceeds 14, show an error message
        if (integerPart.length > 14) {
          ShowToast('error', `Ensure that there are no more than 14 digits for ${fieldName}`);
          return;
        }
    
        // If decimal part exists, limit it to 6 decimal places
        if (parts.length === 2) {
          value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    
          // If more than 6 digits after the decimal point, show a separate error message
          if (decimalPart.length > 2) {
            ShowToast('error', `More than 2 digits after the decimal point for ${fieldName}.`);
          }
        }
      }

      // Conditional validation for discount fields
      if (fieldName === 'discount_price' || fieldName === 'discount_type') {
        const discountType = fieldName === 'discount_type' ? value : inputValues[`discount_type_${itemData.id}`];
        const discountPrice = fieldName === 'discount_price' ? value : inputValues[`discount_price_${itemData.id}`];

        if (discountType && !discountPrice) {
            setQtyErrorMessages((prevErrors) => ({
                ...prevErrors,
                [`discount_price_${itemData.id}`]: 'required',
            }));
        } else if (!discountType && discountPrice) {
            setQtyErrorMessages((prevErrors) => ({
                ...prevErrors,
                [`discount_type_${itemData.id}`]: 'required',
            }));
        } else {
            setQtyErrorMessages((prevErrors) => ({
                ...prevErrors,
                [`discount_price_${itemData.id}`]: '',
                [`discount_type_${itemData.id}`]: '',
            }));
        }
      }
    
      setInputValues((prevValues) => ({
        ...prevValues,
        [`${fieldName}_${itemData?.id}`]: value,
      }));
    };
      
      

    useEffect(() => {
      let total = 0;
    
      prDetailsData.forEach((prData) => {
        prData?.srtopritem_set?.forEach((itemData) => {
          const qty = parseFloat(inputValues[`wo_item_qty_${itemData.id}`]) || 0;
          const price = parseFloat(inputValues[`price_${itemData.id}`]) || 0;
          const discountPrice = parseFloat(inputValues[`discount_price_${itemData.id}`]) || 0;
          // const discountType = inputValues[`discount_type_${itemData.id}`]; // Get discount type
          const discountType = selectedDiscountType[`discount_type_${itemData.id}`]
          
          console.log(discountType)

          let singleItemTotalPrice = qty * price;
    
          if (discountType === 'percentage') {
            // Calculate percentage discount
            const discountAmount = (singleItemTotalPrice * discountPrice) / 100;
            singleItemTotalPrice -= discountAmount;
          } else if (discountType === 'fixed') {
            singleItemTotalPrice -= discountPrice * qty;
          }
    
          // Ensure the total price doesn't go below zero
          singleItemTotalPrice = Math.max(singleItemTotalPrice, 0);
    
          // Update the state with the calculated single item total price
          setSingleItemTotalPrices((prevPrices) => ({
            ...prevPrices,
            [`${itemData.id}`]: singleItemTotalPrice,
          }));
    
          // Add to overall total if the item is selected
          if (selectedItems.includes(itemData.id)) {
            total += singleItemTotalPrice;
          }
        });
      });
    
      // Update the overall total state
      setOverallTotal(total);
    }, [inputValues, prDetailsData, selectedItems,selectedDiscountType]);
    

    console.log('overallTotal',overallTotal)

      
    const [termsAndConditionsOptions, setTermsAndConditionsOptions] = useState([]);
    const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState([]);

    
    const [additionalInfoForm, setAdditionalInfoForm] = useState({
    transport_charge : '',
    other_charge : '',
    additional_discount : '',
    note : '',
    terms_and_conditions: [
        {
        id: null,
        name: '',
        },
    ]
    });

    useEffect(()=>{
    const fetchTermsConditionsData = async () =>{
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/wo-terms-conditions/`)
            console.log(response.data.results)

            const options = response.data.results.map(data => ({
                value: data.id,
                label: data.name,
                name: data.name,
            }));
            setTermsAndConditionsOptions(options);
        } catch (error) {
            console.log(error)
        }
    };fetchTermsConditionsData();
    },[])
    

    const handleTermsAndConditionsChange = (selectedOption, index) => {
    const updatedFormData = { ...additionalInfoForm };
    const updatedTermsAndConditions = [...selectedTermsAndConditions];
    
    if (selectedOption) {
        updatedFormData.terms_and_conditions[index].id = selectedOption.value;
        updatedFormData.terms_and_conditions[index].name = selectedOption.name;
        updatedTermsAndConditions[index] = selectedOption;
    } else {
        updatedFormData.terms_and_conditions[index].id = null;
        updatedFormData.terms_and_conditions[index].name = '';
        updatedTermsAndConditions[index] = null;
    }
    
    setSelectedTermsAndConditions(updatedTermsAndConditions);
    setAdditionalInfoForm(updatedFormData);

    };
      

    const handleAdditionalInfoFormInputChange = (e, index, type) => {
        const updatedData = { ...additionalInfoForm };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (['transport_charge','other_charge','additional_discount'].includes(name)) {
            const parts = value.split('.');
            const integerPart = parts[0];
            const decimalPart = parts[1] || '';
      
            // If the total number of digits exceeds 20, show an error message
            if (integerPart.length > 14) {
              ShowToast( 'error', `Ensure that there are no more than 14 digits`);
              return;
            }
      
            // If decimal part exists, limit it to 6 decimal places
            if (parts.length === 2) {
                value = `${integerPart}.${decimalPart.slice(0, 6)}`;
      
              // If more than 6 digits after the decimal point, show a separate error message
              if (decimalPart.length > 6) {
                    ShowToast('error',`More than 6 digits after the decimal point for ${name}.`);
              }
            }
          }
      
        if (type === 'terms_and_conditions') {
          updatedData.terms_and_conditions[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setAdditionalInfoForm(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            additionalInfoForm.terms_and_conditions &&
            additionalInfoForm.terms_and_conditions.every((item) => {
                return item?.id !== null && item?.name !== '';
            })
        );
    };
    
      
    const handleAddMoreTermsAndCondition = () => {
        if (areAllFieldsFilled()) {
            setAdditionalInfoForm((prevState) => ({
                ...prevState,
                terms_and_conditions: [
                    ...prevState.terms_and_conditions,
                    {
                        id: null,
                        name: '',
                    },
                ],
            }));
        } else {
            ShowToast('info', 'Add Previous Filed')
        }
    };
    

    const handleRemoveMoreTermsAndCondition = (index) => {
        if (areAllFieldsFilled()) {
            const terms_and_conditions = [...additionalInfoForm.terms_and_conditions];
    
            const removedItem = terms_and_conditions.splice(index, 1)[0];
            const updatedSelectedTermsAndConditions = selectedTermsAndConditions.filter((item, i) => i !== index);
    
            setAdditionalInfoForm({
                ...additionalInfoForm,
                terms_and_conditions,
            });
    
            setSelectedTermsAndConditions(updatedSelectedTermsAndConditions);
        } else {
            const terms_and_conditions = [...additionalInfoForm.terms_and_conditions];

            if (terms_and_conditions[index]?.id === null) {
    
                const removedItem = terms_and_conditions.splice(index, 1)[0];
                const updatedSelectedTermsAndConditions = selectedTermsAndConditions.filter((item, i) => i !== index);
        
                setAdditionalInfoForm({
                    ...additionalInfoForm,
                    terms_and_conditions,
                });
        
                setSelectedTermsAndConditions(updatedSelectedTermsAndConditions);
            }else{
                ShowToast('info', 'Please Blank Filed First Remove');
            }
           
        }
    };
    
    

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // Check if there are any error messages for quantity fields
        const hasQtyErrors = Object.values(qtyErrorMessages).some((error) => error !== '');

        // If there are errors, show a message and return without submitting
        if (hasQtyErrors) {
            ShowToast('error', 'Please fix quantity errors or required filed check before submitting.');
            return;
        }
    
        const workOrderData = {
            payment_type: selectedPaymentType.value ? selectedPaymentType.value : null,
            delivery_address: deliveryAddress,
            supplier: selectedSupplier.value ? selectedSupplier.value : null,

            // Additional Information
            transport_charge: additionalInfoForm.transport_charge,
            other_charge: additionalInfoForm.other_charge,
            additional_discount: additionalInfoForm.additional_discount,
            note: additionalInfoForm.note,
            terms_and_conditions:additionalInfoForm.terms_and_conditions.filter((fdata)=> fdata.id !== null)
            .map(term => ({
            id: term.id,
            name: term.name,
            })),

            workorderitem_set: prDetailsData.reduce((accumulator, prData, prIndex) => {
                const items = prData.srtopritem_set.map((itemData, itemIndex) => {
                const woQty = inputValues[`wo_item_qty_${itemData.id}`];
                const price = inputValues[`price_${itemData.id}`];
                const discountPrice = inputValues[`discount_price_${itemData.id}`];
                const discountType = selectedDiscountType[`discount_type_${itemData.id}`];
                const remarks = inputValues[`remarks_${itemData.id}`];
        
                const itemID = itemData.id;
                const isSelected = selectedItems.includes(itemID);
                const isItemRequired = isSelected && woQty !== '' && price !== '';
                

                if (isItemRequired) {
                    return {
                    pr_item: itemID,
                    wo_item_qty: woQty,
                    price: price,
                    discount_price: discountPrice,
                    discount_type: discountType,
                    remarks: remarks,
                    };
                } else {
                    console.log(`Item unselected: PR: ${prData.pr_no}, Item: ${itemData.pr_item_name}`);
                    return null; // Exclude unselected items from the array
                }
                });
        
                return accumulator.concat(items.filter((item) => item !== null));
            }, []),
        };

    
        try {
          if (selectedPrNo.length > 0 && selectedItems.length > 0) {
            if (
              workOrderData.workorderitem_set.length > 0 &&
              workOrderData.payment_type !== null &&
              workOrderData.delivery_address !== '' &&
              workOrderData.supplier !== null
            ) {
              const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/workorder-create/`, workOrderData);

              if(response.data.wo_no){
                navigate('/workorder-list')
                ShowToast('success', 'Successfully Submit')       
              }else{
                navigate('/workorder-list')
                ShowToast('error', 'Something is Wrong Try Again')
              }
              
            } else {
              ShowToast('info', 'Please fill out required fields');
            }
          } else {
            ShowToast('info', 'Please select any item');
          }
        } catch (error) {
          if(error.response?.data?.errors){
            ShowToast('error', `${error.response?.data?.errors}`);
          }else if(error.response?.data?.message){
            ShowToast('error', `${error.response?.data?.message}`);
          }else{
            ShowToast('error', `${error?.message}`);
          }
        }
      };
      

    // update information 

    useEffect(()=>{
        const fetchWorkorderData =async ()=>{
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/workorder-details/${id}/`)
              console.log(response.data)

              const selectedPrNO = response?.data.workorderitem_set?.map((data) => ({
                  value: data.pr_id,
                  label: data.pr_no
              }));
              
              // Use a Set to store unique values
              const uniquePrNosSet = new Set(selectedPrNO.map(pr => pr.value));
              
              // Convert Set back to array
              const uniqueSelectedPrNO = Array.from(uniquePrNosSet).map(value => {
                  const matchingItem = selectedPrNO.find(item => item.value === value);
                  return matchingItem;
              });
              setSelectedPrNo(uniqueSelectedPrNO);

              


              const allItemIDs = response?.data.workorderitem_set?.map((item) => item.pr_item);
              setSelectedItems(allItemIDs);


              setSelectedSupplier(response.data?.supplier? {
                  value: response.data.supplier,
                  label: response.data?.supplier_name,
                } : null);

                setDeliveryAddress(response.data?.delivery_address?response.data?.delivery_address:'')

                
              setSelectedPaymentType(response.data?.payment_type? {
                  value: response.data.payment_type,
                  label: response.data?.payment_type,
                } : null);

                const selectedTms = response?.data.terms_and_conditions?.map((data) => ({
                  value: data.id,
                  label: data.name
                  }));
                setSelectedTermsAndConditions(selectedTms)
                setAdditionalInfoForm(response.data)



                    const initialInputValues = {}



                  response.data.workorderitem_set.forEach((itemData, itemIndex) => {
                        const qtyFieldId = `wo_item_qty_${itemData?.pr_item}`;
                        const previousQtyFieldId = `p_wo_item_qty_${itemData?.pr_item}`;
                        const priceFieldId = `price_${itemData?.pr_item}`;
                        const discountPriceFieldId = `discount_price_${itemData?.pr_item}`;
                        const remarksFiledId = `remarks_${itemData?.pr_item}`;
                        const currentWoItemID = `current_wo_item_id_${itemData?.pr_item}`;
                        const currentDiscountType = [`discount_type_${itemData?.pr_item}`] || null
                        initialInputValues[previousQtyFieldId] = itemData?.wo_item_qty || '';
                        initialInputValues[qtyFieldId] = itemData?.wo_item_qty || '';
                        initialInputValues[priceFieldId] = itemData?.price || ''; // Assuming there's a 'price' property in itemData
                        initialInputValues[discountPriceFieldId] = itemData?.discount_price || ''; // Assuming there's a 'price' property in itemData
                        initialInputValues[remarksFiledId] = itemData?.remarks || ''; // Assuming there's a 'price' property in itemData
                        initialInputValues[currentWoItemID] = itemData?.id || ''; // Assuming there's a 'price' property in itemData                    
                        setSelectedDiscountType(prev => ({
                          ...prev,
                          [currentDiscountType]: itemData?.discount_type || ''
                      }));
                      });


      
                  console.log('Response Data:', response.data);
                  console.log('Initial Input Values:', JSON.stringify(initialInputValues, null, 2));
      
                  setInputValues(initialInputValues);
  
              //   issue


            } catch (error) {
                
            }
        };fetchWorkorderData();
    },[])


    const handleEditFormSubmit = async (event) => {
      event.preventDefault();

      // Check if there are any error messages for quantity fields
      const hasQtyErrors = Object.values(qtyErrorMessages).some((error) => error !== '');

      // If there are errors, show a message and return without submitting
      if (hasQtyErrors) {
          ShowToast('error', 'Please fix quantity errors before submitting.');
          return;
      }
  
      const workOrderData = {
          payment_type: selectedPaymentType.value ? selectedPaymentType.value : null,
          delivery_address: deliveryAddress,
          supplier: selectedSupplier.value ? selectedSupplier.value : null,

          // Additional Information
          transport_charge: additionalInfoForm.transport_charge,
          other_charge: additionalInfoForm.other_charge,
          additional_discount: additionalInfoForm.additional_discount,
          note: additionalInfoForm.note,
          terms_and_conditions:additionalInfoForm.terms_and_conditions.filter((fdata)=> fdata.id !== null)
          .map(term => ({
          id: term.id,
          name: term.name,
          })),

          workorderitem_set: prDetailsData.reduce((accumulator, prData, prIndex) => {
              const items = prData.srtopritem_set.map((itemData, itemIndex) => {
              const woQty = inputValues[`wo_item_qty_${itemData.id}`];
              const price = inputValues[`price_${itemData.id}`];
              const discountPrice = inputValues[`discount_price_${itemData.id}`];
              const discountType = selectedDiscountType[`discount_type_${itemData.id}`];
              const remarks = inputValues[`remarks_${itemData.id}`];
      
              console.log('discountType edit :',discountType)

              const itemID = itemData.id;
              const isSelected = selectedItems.includes(itemID);
              const isItemRequired = isSelected && woQty !== '' && price !== '';
      
              if (isItemRequired) {
                  return {
                  id:inputValues[`current_wo_item_id_${itemData?.id}`]?inputValues[`current_wo_item_id_${itemData?.id}`]: null,
                  pr_item: itemID,
                  wo_item_qty: woQty,
                  price: price,
                  discount_price: discountPrice,
                  discount_type:discountType,
                  remarks: remarks,
                  };
              } else {
                  console.log(`Item unselected: PR: ${prData.pr_no}, Item: ${itemData.pr_item_name}`);
                  return null; // Exclude unselected items from the array
              }
              });
      
              return accumulator.concat(items.filter((item) => item !== null));
          }, []),
      };

  
      try {
        if (selectedPrNo.length > 0 && selectedItems.length > 0) {
          if (
            workOrderData.workorderitem_set.length > 0 &&
            workOrderData.payment_type !== null &&
            workOrderData.delivery_address !== '' &&
            workOrderData.supplier !== null
          ) {
            const response = await authAxiosWithBearer.put(`${baseURL}/inventory/api/workorder-update/${id}/`, workOrderData);
            console.log(response.data)
            if(response.data.wo_no){
              navigate('/workorder-list')
              ShowToast('success', 'Successfully Submit')       
            }else{
              navigate('/workorder-list')
              ShowToast('error', 'Something is Wrong Try Again')
            }
            
          } else {
            ShowToast('info', 'Please fill out required fields');
          }
        } else {
          ShowToast('info', 'Please select any item');
        }
      } catch (error) {
        if(error.response?.data?.errors){
          ShowToast('error', `${error.response?.data?.errors}`);
        }else if(error.response?.data?.message){
          ShowToast('error', `${error.response?.data?.message}`);
        }else{
          ShowToast('error', `${error?.message}`);
        }
      }
    };


  return {
    location,
    selectedPrIds,
    prNoOptions,
    setPrNoOptions,
    selectedPrNo,
    setSelectedPrNo,
    prDetailsData,
    setPrDetailsData,
    selectedItems,
    setSelectedItems,
    selectedSupplier,
    setSelectedSupplier,
    selectedPaymentType,
    setSelectedPaymentType,
    deliveryAddress,
    setDeliveryAddress,
    inputValues,
    setInputValues,
    singleItemTotalPrices,
    setSingleItemTotalPrices,
    overallTotal,
    setOverallTotal,
    qtyErrorMessages,
    setQtyErrorMessages,
    navigate,
    handlePrNoChange,
    handleSupplierChange,
    handlePaymentTypeChange,
    handleHeaderCheckboxChange,
    handlePrCheckboxChange,
    handleItemCheckboxChange,
    selectAll,
    handleInputChange,
    handleInputChangeForUpdate,
    termsAndConditionsOptions,
    setTermsAndConditionsOptions,
    selectedTermsAndConditions,
    setSelectedTermsAndConditions,
    additionalInfoForm,
    setAdditionalInfoForm,
    handleTermsAndConditionsChange,
    handleAdditionalInfoFormInputChange,
    areAllFieldsFilled,
    handleAddMoreTermsAndCondition,
    handleRemoveMoreTermsAndCondition,
    handleFormSubmit,
    handleEditFormSubmit,
    handlePrSearchInputChange,

    discountTypeOptions,
    selectedDiscountType,
    handleyDiscountTypeChange,
  }
}

export default WorkorderFormHandleCom