import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomPaginator from '../../../../Components/CustomPaginator';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';
import useFetchListData from '../../../../hooks/useFetchListData';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { formatDateTime } from '../../../../Components/formatDateTime';
import { baseURL } from '../../../../baseURL';
import ImageOpenModalButton from '../../../../Components/ImageOpenModal/ImageOpenModalButton';


const TechnicianTaskReportListCom = ({user,apiEndpoint,dataUpdated}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [dataStatus, setDataStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndpoint}`, params);

  useEffect(() => {
    console.log('dataUpdate has changed:', dataUpdated);
    fetchData();
  }, [currentPage, searchText, dataStatus, endDate, startDate, dataUpdated]);

  const handleSearch = (value) => {
    setSearchText(value);
  };


  const dataSource = data?.flatMap((data,index) =>({
    key: 1 + index++,
    id:data?.id,
    report_no:data?.report_no,
    report_descriptions:data?.report_descriptions,
    created_by_text: data?.created_by_text,
    created_at: data?.created_at,
    techniciantaskreportimage_set: data?.techniciantaskreportimage_set,

  }))


  const columns = [
    {
      title: 'SN',
      dataIndex: 'key',
      key: 'key',
    },
    {
    title: 'Created By',
    dataIndex: 'created_by_text',
    key: 'created_by_text',
    },
    {
    title: 'report_no',
    dataIndex: 'report_no',
    key: 'report_no',
    },
    {
    title: 'Report Descriptions',
    dataIndex: 'report_descriptions',
    key: 'report_descriptions',
    },
    
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: '7',
      render: (created_at) => {
        const formattedDateTime = formatDateTime(created_at);
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },

  ];





  return (
    <>
    <div className='container-fluid grid grid-cols-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>Task Report List</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4 justify-between md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>


      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
        <>
        <div className='shadow-lg overflow-x-auto'>
          <Table 
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <div>
                        {record?.techniciantaskreportimage_set?.map((data,index) =>(
                        <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 border-b pb-2'>
                            <p className="expanded-row-content">
                            <div className='flex flex-wrap'>
                                <p className='w-[40%] md:w-[40%]'>Image Title</p>
                                <p className='w-[5%] md:w-[5%] text-center'>-</p>
                                <p className='w-[55%] md:w-[55%] text-end'>{data?.image_title}</p>
                            </div>
                            </p>
                            <p className="expanded-row-content">
                            <div className='flex flex-wrap'>
                                <p className='w-[40%] md:w-[40%]'>Image</p>
                                <p className='w-[5%] md:w-[5%] text-center'>-</p>
                                <p className='w-[55%] md:w-[55%] text-end'>
                                    <ImageOpenModalButton imagePath={data?.image}/>
                                </p>
                            </div>
                            </p>
                        </div>
                        ))}

                    </div>
                  </>
                ),
                rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
              }}
              dataSource={dataSource} 
              pagination={false}
          />
          <div className='my-5'>
              <CustomPaginator 
                  setCurrentPage={setCurrentPage} 
                  currentPage={currentPage}
                  totalItems={totalItems}
              />
          </div>
        </div>
       </>
      )} 
    </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TechnicianTaskReportListCom);