import React, { useEffect, useState } from 'react';
import { Table, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import {  AiOutlineSearch } from 'react-icons/ai';
import {  useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import ATForServicingModalButton from './AssignTechnicianForServicing/ATForServicingModalButton';

const TechnicianListForServicing = ({user,detailsData,fetchDeatilsData}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = async () => {
    try {
      const response = await authAxiosWithBearer.get(`${baseURL}/api/technician-list/`, {
        params: {
          page: currentPage,   
          search: searchText    
        },
      });

      const { results, count } = response.data;

      setTotalItems(count);
      setData(results);
      console.log('results',results)
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText]);


  console.log('detailsData',detailsData)

  
  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    employee_id: data?.employee_id,
    name:data?.name, 
    phone_no:data?.phone_no, 
    district_text: data?.district_text,
    work_load_count:data?.work_load_count
  }))



  const handleSearch = (value) => {
    setSearchText(value);
  };


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
    },
    {
      title: 'employee_id',
      dataIndex: 'employee_id',
      key: '2',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_no',
      key: '3',
    },
    {
      title: 'Distict',
      dataIndex: 'district_text',
      key: '3',
    },
    {
      title: 'WorkLoad',
      dataIndex: 'work_load_count',
      key: '3',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>
          
          {user?.menu_permissions?.includes("8.1.1") &&(
          <>
          {/* {record.work_load_count < 6? 
          <> */}
          <div>
            {detailsData?.goodscheckedtechnicianfeedback_set[0]?.inhousetechnicianassignforservicing_set?.some((data) => record.id === data?.technician) ? (
              // Render this div if the user is assigned
              <div className='rounded flex text-[#ffffff] p-2 bg-[#25721c]'>
                Assigned 
              </div>
            ) : (
              // Check if any transportassign_set exists
              detailsData?.goodscheckedtechnicianfeedback_set[0]?.inhousetechnicianassignforservicing_set?.length > 0 ? (
                // Render a non-clickable button
                <button disabled
                    className='bg-[#FA6669] rounded-[4px] px-[16px] py-[5px] text-[#fff]'
                    >
                      Assign
                </button>
              ) : (
                <ATForServicingModalButton 
                  technician_id={record?.id}
                  gctfeedback_id={detailsData?.goodscheckedtechnicianfeedback_set[0]?.id} 
                  detailsData={detailsData} 
                  fetchData={fetchData}
                  fetchDeatilsData={fetchDeatilsData}
                />
              )
            )}
          </div>      
          </>
          // :<div className='rounded flex text-[#000000] p-2 bg-[#dcff3f]'>Overload {record.transport_user_work_load_count}</div>
          // }
          // </>
          )}
        </div>
      ),
    },

  ];




  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>technician list</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>

            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>
      </div>

      <div className='shadow-lg overflow-x-auto'>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(TechnicianListForServicing);
