import React from 'react'
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import MrCreateHandleComponent from './MrCreateHandleComponent';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import TextCopyButton from '../../../../Components/TextCopyButton';


function MrCreateFormComponent({
    id,
    ActionURL,
    redirectURL,
    setModalOpen,
    detailsData,
    fetchDetailsData,
}) {
    const {
        formData,
        setFormData,
        handleInputChange,
        handleSubmit,
        payTypeOption,
        handlePayTypeOptionChange,
    } = MrCreateHandleComponent({
        id,
        ActionURL,
        redirectURL,
        setModalOpen,
        fetchDetailsData,
    });



    return (
        <div className='mt-[50px]'>
            <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 gap-2">

                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='mr_person_name' id='mr_person_name' label='Person Name*'
                            isRequired={true} 
                            placeholder='person name'
                            value={formData.mr_person_name}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        />
                    </div>

                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='mr_person_contact_no' id='mr_person_contact_no' label='Person Mobile*'
                            isRequired={true} 
                            placeholder='person mobile'
                            value={formData.mr_person_contact_no}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        />
                    </div>

                    <div className='my-4 relative'>
                        <InputFiledWC type='text' 
                            name='due_amount' id='due_amount' label='Due Amount'
                            isRequired={false} 
                            placeholder='Due Amount'
                            value={detailsData?.due_amount}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            readOnly={true}
                        />
                       
                        <div className="text-xl absolute top-[10px] right-1 px-1">
                                <TextCopyButton text={detailsData?.due_amount} />
                        </div>
                    </div>


                    <div  className='my-4'>
                    <SelectInputWC
                        name='pay_type'
                        id='pay_type'
                        label='Pay Type*'
                        placeholder='select pay type'
                        className={`w-full`}
                        value={payTypeOption.find((option)=> option?.value === formData.pay_type)}
                        onChange={handlePayTypeOptionChange}
                        options={payTypeOption}
                        isRequired={true}
                    />
                    </div>

                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='pay_amount' id='pay_amount' label='Pay Amount*'
                            isRequired={true} 
                            placeholder='pay amount'
                            value={formData.pay_amount}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        />
                    </div>

                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='transaction_no' id='transaction_no' label='Transaction No'
                            isRequired={false} 
                            placeholder='transaction no'
                            value={formData.transaction_no}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        />
                    </div>
                    
                </div>
                <div className=" mt-4 bottom-0">
                        <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2 w-[100%]'>Create</button>
                    </div>
            </form>
        </div>
    )
}

export default MrCreateFormComponent