import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';

const TechnicianAssignedStatusChangeForm = ({ticketData,id}) => {

    // #status change 
    const [subStatusOption, setSubStatusOption] = useState(null);
    const [selectedSubStatus, setSelectedSubStatus] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [statusForm, setStatusForm] = useState({
        status_text: '',
        sub_status:null
    })

    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${id}/`);

            setStatusForm(prevState => ({
                ...prevState,
                status_text: response.data.status_text,
                sub_status: response.data.sub_status,
            }));

            setSelectedStatus({value: response.data.status_text})

            setSelectedSubStatus(response.data.sub_status &&({
                value: response.data.sub_status,
                label: response.data.sub_status_text,
                status:response.data.status_text
            }))

        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [id]);


    const statusOption = [
        {value:'In Progress',label:'In Progress'},
        {value:'Pending',label:'Pending'},
    ]


    useEffect(() => {
        const fetchSubStatusData = async () => {
          try {
            const response = await authAxiosWithBearer.get(`${baseURL}/api/sub-status-list/`);
            setSubStatusOption(
              response?.data?.results?.map((data) => ({
                value: data.id,
                label: data.title,
                status: data.status_text,
              }))
            );
          } catch (error) {
            console.log(error);
          }
        };
        fetchSubStatusData();
      }, []);

      

    const handleStatusChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== '') {
        setStatusForm((prevState) => ({ ...prevState, status_text: selectedOption.value }));
        setSelectedStatus(selectedOption);
        setSelectedSubStatus(null);
    } else {
        setStatusForm((prevState) => ({ ...prevState, status_text: '' }));
        setSelectedStatus(null); 
        setSelectedSubStatus(null);
    }
    };


    const handleSubStatusChange = (selectedOption) => {
        const sub_status_id = selectedOption ? selectedOption.value : null;
        setSelectedSubStatus(selectedOption);
        setStatusForm((prevFormData) => ({
          ...prevFormData,
          sub_status: sub_status_id,
        }));
      };
    
    const filteredSubStatus = subStatusOption?.filter((subStatus) => subStatus?.status === selectedStatus?.value);
      

    const handleStatusChangeSubmit = async (e)=>{
        e.preventDefault()
        try {
            const response = await authAxiosWithBearer.put(`${baseURL}/ccd/api/technician-assigned-ticket-status-change/${id}/`,statusForm)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Updated');
              }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div>

        <form className='border border-[#E74A3B] shadow-lg rounded-[8px] pb-3' onSubmit={handleStatusChangeSubmit}>
                <FormPageTitle title='Ticket Status' />
                <div className='md:flex justify-between md:mx-5'> 
                    <div className='my-7 md:my-2'>
                        <SelectInputWC
                            className ='appearance-none border-[1px] w-[100%] md:w-[270px] lg:w-[400px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            id='status'
                            label='Status'
                            name='status'
                            value={statusOption.find((option)=> option?.value === statusForm.status_text)}
                            onChange={handleStatusChange}
                            options={statusOption}
                            placeholder='Select Status*'
                            isClearable={true}
                            required={true}
                        />
                    </div>
                    <div className='mt-7 mb-3 md:my-2'>
                        <SelectInputWC
                            className ='appearance-none border-[1px] w-[100%] md:w-[270px] lg:w-[400px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            id='sub_status'
                            label='Sub Status'
                            name='sub_status'
                            value={selectedSubStatus}
                            onChange={handleSubStatusChange}
                            options={filteredSubStatus}
                            isRequired={true}
                            placeholder='Select Status*'
                            isClearable={true}
                            required={true}
                        />
                    </div>
                    {ticketData?.ticket_status_text !== 'Complete' && ticketData?.sub_status_text !== "Requested For Finish" && ticketData?.sub_status_text !== "In house service request" && (
                        <div className='my-auto'>
                            <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Change</button>      
                        </div>
                    )}
                </div>
        </form>

    </div>
  )
}

export default TechnicianAssignedStatusChangeForm