import React from 'react'
import FormPageTitle from '../../../Components/Form/FormPageTitle'
import { formatDateTime } from '../../../Components/formatDateTime'

const TicketBasicDetailsComponet = ({ticketData}) => {
  return (
      <div className="ticket-data-card bg-[#fff] shadow-lg px-3 py-6 my-4">
          <FormPageTitle title='Ticket Information' />
          <div className="grid md:grid-cols-2 px-3">

              <div className='flex flex-wrap my-3'>
                  <div className='w-[35%]'>
                      Ticket No
                  </div>
                  <div className='w-[65%] text-end pr-[5px] border'>
                      {ticketData?.ticket_no}
                  </div>
              </div>

              <div className='flex flex-wrap my-3 md:pl-[50px]'>
                  <div className='w-[35%]'>
                      Created Date
                  </div>
                  <div className='w-[65%] text-end border pr-[5px]'>
                      {ticketData?.created_at && (
                          <>{ticketData?.created_at && formatDateTime(ticketData?.created_at)}</>
                      )}
                  </div>
              </div>

              <div className='flex flex-wrap my-3'>
                  <div className='w-[35%]'>
                      Customer Name
                  </div>
                  <div className='w-[65%] text-end border pr-[5px]'>
                      {ticketData?.customer[0]?.name}
                  </div>
              </div>



              <div className='flex flex-wrap my-3 md:pl-[50px]'>
                  <div className='w-[35%]'>
                      Phone Number
                  </div>
                  <div className='w-[65%] text-end pr-[5px] border'>
                      {ticketData?.customer[0]?.phone_no}
                  </div>
              </div>

              <div className='flex flex-wrap my-3'>
                  <div className='w-[35%]'>
                      Address
                  </div>
                  <div className='w-[65%] text-end border pr-[5px]'>
                      {ticketData?.customer[0]?.address}
                  </div>
              </div>

              <div className='flex flex-wrap my-3 md:pl-[50px]'>
                  <div className='w-[35%]'>
                      Created By
                  </div>
                  <div className='w-[65%] text-end pr-[5px] border'>
                      {ticketData?.created_by}
                  </div>
              </div>

          </div>
      </div>
  )
}

export default TicketBasicDetailsComponet