// UserList.jsx

import React, { useEffect, useState } from 'react';
import TechnicianOrVendorListComponent from './TechnicianOrVendorListComponent';


function VendorListDataForAddPage(props) {
  const [sortOrder, setSortOrder] = useState({});

  const { 
    hvacTicketData,
    insatllaTionticketData,
    serviceTicketData,
    refreshData,
    onUpdateTicketData,
   } = props;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      sortOrder: sortOrder['name'],
    },
    {
      title: 'employee_id',
      dataIndex: 'employee_id',
      key: '2',
      sorter: (a, b) => a?.employee_id?.localeCompare(b?.employee_id),
      sortOrder: sortOrder['employee_id'],
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: '3',
      sorter: (a, b) => a?.phone?.localeCompare(b?.phone),
      sortOrder: sortOrder['phone'],
      responsive: ['md'],
    },
    {
      title: 'Distict',
      dataIndex: 'district_text',
      key: '3',
      sorter: (a, b) => a?.district_text?.localeCompare(b?.district_text),
      sortOrder: sortOrder['district_text'],
      responsive: ['md'],
    },
    {
      title: 'WorkLoad',
      dataIndex: 'work_load_count',
      key: '3',
      responsive: ['md'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>
          
          {/* {record.work_load_count < 6?  */}
          <>
            {hvacTicketData?.hvac && hvacTicketData.hvac.length > 0 &&(
              <div>
                  {hvacTicketData?.hvac[0]?.technician.some((data) => record.id === data.technician) ? (
                    <div className='rounded flex text-[#1f7015] p-2 bg-[#abe5a4]'>
                      Assigned
                    </div>
                  ) : (
                    <button
                      key={`assign-button-${record.id}`} // Use key prop to force React to treat it as a new element
                      className='rounded flex text-[#EB5757] p-2 bg-[#FDECEC] hover:bg-[#EB5757] hover:text-[#fff]'
                      onClick={() => props.onClick(record.id)}
                    >
                      Assign
                    </button>
                  )}
              </div>          
            )}

          
            {insatllaTionticketData?.insatllation_bill && insatllaTionticketData.insatllation_bill.length > 0 &&(
              <div>
                  {insatllaTionticketData?.insatllation_bill[0]?.technician.some((data) => record.id === data.technician) ? (
                    <div className='rounded flex text-[#1f7015] p-2 bg-[#abe5a4]'>
                        Assigned 
                    </div>
                  ) : (
                    <>
                    {insatllaTionticketData?.status_text === 'In Progress' || insatllaTionticketData?.status_text === 'Open'?
                  <button
                    key={`assign-button-${record.id}`} // Use key prop to force React to treat it as a new element
                    className='rounded flex text-[#EB5757] p-2 bg-[#FDECEC] hover:bg-[#EB5757] hover:text-[#fff]'
                    onClick={() => props.onClick(record.id)}
                  >
                    Assign
                  </button>
                  :
                    <button
                    disabled
                    className='rounded flex text-[#EB5757] p-2 bg-[#FDECEC] hover:bg-[#EB5757] hover:text-[#fff]'
                  >
                    Assign
                  </button>
                  
                    }
                  </>
                  )} 
                  
              </div>          
            )}

            {serviceTicketData?.service_bill && serviceTicketData.service_bill.length > 0 &&(
                <div>
                    {serviceTicketData?.service_bill[0]?.technician.some((data) => record.id === data.technician) ? (
                      <div className='rounded flex text-[#1f7015] p-2 bg-[#abe5a4]'>
                        Assigned
                      </div>
                    ) : (
                      <>
                      {serviceTicketData?.status_text === 'In Progress' || serviceTicketData?.status_text === 'Open'?
                    <button
                      key={`assign-button-${record.id}`} // Use key prop to force React to treat it as a new element
                      className='rounded flex text-[#EB5757] p-2 bg-[#FDECEC] hover:bg-[#EB5757] hover:text-[#fff]'
                      onClick={() => props.onClick(record.id)}
                    >
                      Assign
                    </button>
                    :
                      <button
                      disabled
                      className='rounded flex text-[#EB5757] p-2 bg-[#FDECEC] hover:bg-[#EB5757] hover:text-[#fff]'
                    >
                      Assign
                    </button>
                    
                      }
                    </>
                    )}
                </div>          
            )}
          </>

          {/* :<div className='rounded flex text-[#000000] p-2 bg-[#dcff3f]'>Overload</div>
          } */}
        </div>
      ),
    },

  ];



  return (
    <div className=''>
      <TechnicianOrVendorListComponent
        apiEndpoint="/api/vendor-list/"
        columnsConfig={columns}
        title="Vendor List"
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        refreshData={refreshData}
        onUpdateTicketData={onUpdateTicketData}
      />
    </div>
  );
}

export default VendorListDataForAddPage;

