import React, { useEffect, useState } from 'react'

const CatWiseTotalBill = ({invoiceData}) => {
    const [totalPrice, setTotalPrice] = useState(null);
    
    useEffect(() => {
      // Calculate total price when detailsData changes
      const calculateTotalPrice = () => {
        if (invoiceData && invoiceData.length > 0) {
          const total = invoiceData.reduce((acc, item) => {
            // Assuming item has properties wo_item_qty and price
            const itemTotal = item?.total_payable_amount 
            ? (parseFloat(item.total_payable_amount)): 0; 
            return acc + itemTotal;
          }, 0);
  
          const finalTotal = total;
  
          // Update the state with the calculated total price
          setTotalPrice(finalTotal.toFixed(2));
        } else {
          // If detailsData or billing_invoice is empty or null, set total price to null
          setTotalPrice(null);
        }
      };
  
      calculateTotalPrice();
    }, [invoiceData]);

  return (
    <div>
        {totalPrice && totalPrice}
    </div>
  )
}

export default CatWiseTotalBill