import React, { useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import ATFormComForCompletedDeliveryResource from './ATFormComForCompletedDeliveryResource';


function ATForCompletedDeliveryResourceModal(
  { 
    isOpen, 
    onClose,
    transport_assign,
    technician_id,
    setModalOpen,
    fetchData,
    fetchDeatilsData,
}) 
  {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Purchased Details Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className='mb-2 w-[350px] md:w-[350px] lg:w-[450px] overflow-y-auto h-[300px]'>
        <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
          <h1 className='font-semibold uppercase'>Technician Assign Form</h1>
          <button className='text-[#000]' onClick={onClose}><ImCancelCircle /></button>
        </div>

          <div>
              <ATFormComForCompletedDeliveryResource 
              transport_assign ={transport_assign}
              technician_id = {technician_id}
              setModalOpen={setModalOpen} 
              fetchData={fetchData}
              fetchDeatilsData={fetchDeatilsData}
              />
          </div>
      </div>
    </Modal>
  );
}

export default ATForCompletedDeliveryResourceModal;