import React, { useState } from 'react';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import PasswordFiledSM100 from '../../../Components/Form/PasswordFiledSM100';
import SectionTitle from '../../CRM/Ticket/TicketComponents/SectionTitle';
import ShowToast from '../../../Components/ShowToast'
import { useNavigate } from 'react-router-dom';

const PasswordChange = () => {
  const [formData, setFormData] = useState({
    old_password: '',
    password: '',
    password2: '',
  });
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setShowError('')
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await authAxiosWithBearer.post(`${baseURL}/api/change-password/`,formData);
      console.log(response.data);
      setLoading(false)
      ShowToast('success',`Your Password Successfully Changed`)
      navigate('/home')
    } catch (error) {
      console.error(error);
      setShowError(`${error.response.data.non_field_errors}`)
      ShowToast('error',`${error.response.data.non_field_errors}`)
      setLoading(false)
    }
  };



  return (
    <div className='flex justify-center '>
        <form onSubmit={handleSubmit} className='w-[100%] md:w-[370px] shadow-lg p-5 m-2'>
            
            <div className='pb-3 border-b'>
                <SectionTitle title='Password Change Form' />
                {showError && (
                  <p className='text-center text-red-600 mt-2'>{showError}</p>
                )}
            </div>

            
        <div className='my-8'>
            <PasswordFiledSM100 type='password' value={formData.old_password} 
                onChange={handleChange} name='old_password' id='old_password'
                label='Old  Password *' isRequired={true}
                placeholder='Enter password'
            />
        </div> 

        <div className='my-8'>
            <PasswordFiledSM100 type='password' value={formData.password} 
                onChange={handleChange} name='password' id='password'
                label='New password *' isRequired={true}
                placeholder='Enter password'
                />
        </div> 
        <div className='my-8'>
            <PasswordFiledSM100 type='password' value={formData.password2} 
                onChange={handleChange} name='password2' id='password2'
                label='Confirm password *' isRequired={true}
                placeholder='Enter password'
            />
        </div> 

        <div className="my-5">
        {loading? 
            <button disabled className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>
                Changing..
            </button>
            :
            <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>Change</button>
        }
        </div>

        </form>
    </div>
  );
};

export default PasswordChange;
