import React from "react";
import PageRoutes from "./Layout/PageRoutes";
import store from "./Redux/store";
import { Provider } from "react-redux";
import './assest/CSS/TableDesign.css'
import './assest/CSS/Basic.css'

const App = () => {
  return (
      <Provider store={store}>
        <div className="container mx-auto">
          <PageRoutes />
        </div>
      </Provider>
  );
};

export default App;

