import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'
import SRItemComponent from '../../InventoryComponent/SRItemComponent'

const GeneralSRDetailsCom = ({detailsData}) => {
  return (
    <div className='border border-[#EB5757]'>
        <div className='border border-[#EB5757] mx-1 my-2'>

            <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                    <div className='bg-[#EB5757] '>

                        <div className='flex justify-center items-center 8 sm:py-5  md:py-5 lg:py-5'>
                            <div className='text-[#FFFFFF] text-[16px] font-semibold '>
                                <div className='text-center'>REQ NO</div>
                                <div className='text-center'> = {detailsData?.req_code}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                        <div className='mx-1 sm:mx-0'>
                            <div>
                                <div className='flex'>
                                    <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Status</div>
                                    <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                    <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%] '>
                                        {detailsData?.issue_status === 'Issued' ? 
                                        <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div> 
                                        : detailsData?.issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div> 
                                        : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex '>
                                    <div className='w-[35%] sm:w-[30%] md:w-[50%] lg:w-[35%]'>Requested By</div>
                                    <div className='w-[8%] sm:w-[5%] md:w-[8%] lg:w-[16%]'>-</div>
                                    <div className='w-[57%] sm:w-[65%] md:w-[42%] lg:w-[49%]'>{detailsData?.created_by_text}</div>

                                </div>
                            </div>

                        </div>

                        {/* 2nd */}

                        <div className='mx-1 sm:mx-0'>
                            <div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Requested Date</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </div>
        </div>

        <SRItemComponent itemDatas={detailsData?.generalsritem_set} title={'Requisition Item Information'} />



    </div>
  )
}

export default GeneralSRDetailsCom