import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const TicketSubmitResponse = () => {
  return (
    <div>
       <p className='text-center text-[35px] text-[#F35B5B] my-10'>Successfully Submitted</p>
    </div>
  )
}

export default TicketSubmitResponse