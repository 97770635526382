import React from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import SelectInput from '../../../../Components/Form/SelectInput'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import InputFiled from '../../../../Components/Form/InputFiled'

const OnlineSpecific = ({
        setTicketFormData,
        ticketFormData,
        handleTicketInputChange,
        YesOrNoOption,
        ratingOption,
    }) => {

    const handleWebsiteRatingChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, website_rating: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, website_rating: null }));
        }
      };
  
    const handleEncourageFnfChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
        setTicketFormData(prevState => ({ ...prevState, encourage_fnf: selectedOption.value }));
    } else {
        setTicketFormData(prevState => ({ ...prevState, encourage_fnf: false }));
    }
    };
  
    const handlePurchaseProcedureRatingChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
        setTicketFormData(prevState => ({ ...prevState, purchase_procedure_rating: selectedOption.value }));
    } else {
        setTicketFormData(prevState => ({ ...prevState, purchase_procedure_rating: null }));
    }
    };

    const handleFuturePossibilityChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
        setTicketFormData(prevState => ({ ...prevState, future_possibility: selectedOption.value }));
    } else {
        setTicketFormData(prevState => ({ ...prevState, future_possibility: false }));
    }
    };
    
  return (
    <div>
        <FormPageTitle title='Online Specific'   />
        <div className="grid md:grid-cols-2 lg:grid-cols-3">
            <div  className='my-4'>
            <SelectInput
                name='website_rating'
                id='website_rating'
                label='Website Rating'
                placeholder='Rate Website'
                className={`w-full`}
                value={ratingOption.find((option)=> option?.value === ticketFormData.website_rating)}
                onChange={handleWebsiteRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Purchase Experience Sharing'
                name={`purchase_exp`}
                id={`purchase_exp`}
                value={ticketFormData.purchase_exp}
                onChange={handleTicketInputChange}
                className='w-full h-[41.535px]' 
                placeholder={`Purchase Experience Sharing`} 
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='encourage_fnf'
                id='encourage_fnf'
                label='Encourage FNF for Online Purchase'
                placeholder='Encourage FNF for Online Purchase'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.encourage_fnf)}
                onChange={handleEncourageFnfChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Website Experience Sharing'
                name={`website_exp`}
                id={`website_exp`}
                value={ticketFormData.website_exp}
                onChange={handleTicketInputChange}
                className='w-full h-[41.535px]' 
                placeholder={`Website Experience Sharing`} 
            />
            </div>

            <div  className='my-4'>
                <InputFiled className={`w-full`} type='text'                                  
                    name='password' id='password'
                    label='Issues online purchase M' isRequired={false}
                    placeholder='Enter Issues online purchase'
                />
            </div>

            <div  className='my-4'>
            <TextareaInput label="Encourage fnf exp"
                name={`encourage_fnf_exp`}
                id={`encourage_fnf_exp`}
                value={ticketFormData.encourage_fnf_exp}
                onChange={handleTicketInputChange}
                className='w-full h-[41.535px]' 
                placeholder={`Why don't you encourage FNF for online purchase`} 
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='purchase_procedure_rating'
                id='purchase_procedure_rating'
                label='Purchase Procedure Rating'
                placeholder='Purchase Procedure'
                className={`w-full`}
                value={ratingOption.find((option)=> option?.value === ticketFormData.purchase_procedure_rating)}
                onChange={handlePurchaseProcedureRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>


            <div  className='my-4'>
            <SelectInput
                name='future_possibility'
                id='future_possibility'
                label='Buy online in future?'
                placeholder='Purchase Procedure'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.future_possibility)}
                onChange={handleFuturePossibilityChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

        </div>
    </div>
  )
}

export default OnlineSpecific