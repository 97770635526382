import React from 'react'
import './../assest/CSS/SalesOrderDetailsComponent.css'
import { Table } from 'antd';
import { format } from "date-fns";
import IssuedDetailsComponent from '../Page/Inventory/InventoryComponent/IssuedDetailsComponent';

function PackageIssuedDetailsComponent({detailsData}) {


  return (
    <>
    <IssuedDetailsComponent detailsData={detailsData} />
    </>
  )
}

export default PackageIssuedDetailsComponent