import React, { useEffect, useState } from 'react'
import VehicleFormComponent from './VehicleComponents/VehicleFormComponent'
import VehicleFormHandleComponent from './VehicleComponents/VehicleFormHandleComponent'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../baseURL'
import ShowToast from '../../Components/ShowToast'

const VehicleEdit = () => {
    const {id} = useParams()
    const [loading,setLoading]= useState(true)

    const {
        navigate,
        formData,
        setFormData,
        handleInputChange,
        vehicleTypeOption,
        handleVehicleTypeChange,
    } = VehicleFormHandleComponent()


    useEffect(()=>{
        const fetchVehiclaeData = async ()=>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/api/vehicle/${id}/`)
                console.log(response.data)
                setFormData(response.data)
            } catch (error) {
                console.log(error)
            }
            finally{
              setLoading(false)
            }
        };
        fetchVehiclaeData();
    },[id])


    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          const response = await authAxiosWithBearer.put(`${baseURL}/api/vehicle/${id}/`, formData);
          if (response) {
            console.log(response);
            navigate('/vehicle-list');
            ShowToast('success', 'Successfully Update');
          }
        } catch (error) {
          console.log(error);
          if(error.response.data.vehicle_number){
            ShowToast('error', `${error.response.data.vehicle_number}`);
          }else{
            ShowToast('error', 'Something is wrong');
          }
        } finally {
          setLoading(false);
        }
      };

    if (loading) {
      return <div><LoadingSpinner /></div>;
    }
  
    
  return (
    <div>
        <form onSubmit={handleEditSubmit}>
            <VehicleFormComponent   formData = {formData}
                                    handleInputChange= {handleInputChange}
                                    vehicleTypeOption={vehicleTypeOption}
                                    handleVehicleTypeChange={handleVehicleTypeChange} />
            <div className="my-auto">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'>{loading ? <LoadingSpinner /> : 'Submit'}</button>
            </div>
        </form>
    </div>
  )
}

export default VehicleEdit