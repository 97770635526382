import React from 'react'
import InputFiled from '../../../../Components/Form/InputFiled'
import SelectInput from '../../../../Components/Form/SelectInput'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'

function NonPersonalCRM({
        setTicketFormData,
        ticketFormData,
        handleTicketInputChange,
        YesOrNoOption,
        ratingOption,
    }) {

    const sourcesOption = [
        { value: '1', label: 'Facebook' },
        { value: '2', label: 'Googole' },
        { value: '3', label: 'Youtube' },
        { value: '4', label: 'Website' },
        { value: '5', label: 'FNF' },
        { value: '6', label: 'Newspaper' },
        { value: '7', label: 'TV' },
        { value: '8', label: 'Other' },
        ];

    const paymentOption = [
        { value: '1', label: 'Cash' },
        { value: '2', label: 'Card' },
        { value: '3', label: 'Instalment' },
    ];

    const productChoiseFromOption = [
        {value:'1', label:'Showroom'},
        {value:'2', label:'Before'},
        {value:'3', label:'Instalment'},
      ]

    const handleDeleveryCorectionChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, delevery_corection: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, delevery_corection: false }));
        }
      };

    const handleAccessoriesSaleChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, accessories_sale: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, accessories_sale: false }));
        }
    };

    const handleInstallationChargeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, installation_charge: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, installation_charge: false }));
        }
      };

    const handleGiftItemChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
            setTicketFormData(prevState => ({ ...prevState, gift_item: selectedOption.value }));
        } else {
            setTicketFormData(prevState => ({ ...prevState, gift_item: false }));
        }
    };

    const handleDiscountChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, discount: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, discount: false }));
        }
      };
      
      const handlePreferPaymentChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, prefer_payment: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, prefer_payment: null }));
        }
      };
    
      const handleUnnecessaryChargeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, unnecessary_charge: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, unnecessary_charge: false }));
        }
      };

      const handleServiceCenterRatingChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, service_center_rating: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, service_center_rating: null }));
        }
      };

      const handleStafBehaveRatingChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, staf_behave_rating: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, staf_behave_rating: null }));
        }
      };
  
      const handleProductChoiseFromChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, product_choise_from: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, product_choise_from: null }));
        }
      };
  
      const handleInstallationRatingChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, installation_rating: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, installation_rating: null }));
        }
      };
  
      const handleHasComplainChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, has_complain: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, has_complain: false }));
        }
      };

      const handleProductServiceRatingChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, prod_delevery_rating: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, prod_delevery_rating: null }));
        }
      };

      const handleSourceInfoChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, source_info: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, source_info: null }));
        }
      };

      const handleShowroomRatingChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, showroom_rating: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, showroom_rating: null }));
        }
      };
  
      
      const handleSuggestOtherChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, suggest_other: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, suggest_other: false }));
        }
      };

  return (
    <div>
        <FormPageTitle title='Non Personal CRM Info'   />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        
            <div  className='my-4'>
                <InputFiled type='text'                                  
                name='prv_purchased_prod' id='prv_purchased_prod' label='Previous purchased Product' 
                onChange={handleTicketInputChange}
                value={ticketFormData.prv_purchased_prod}
                isRequired={false} placeholder='Previous Purchased Product'
                className={`w-full`}
                />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='prod_delevery_rating'
                id='prod_delevery_rating'
                label='Product Delevery Rating'
                placeholder='Select product delivery service'
                className={`w-full`}
                value={ratingOption.find((option) => option?.value === ticketFormData.prod_delevery_rating)}
                onChange={handleProductServiceRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='source_info'
                id='source_info'
                label='Source Info'
                placeholder='Select how did you came to know...'
                className={`w-full`}
                value={sourcesOption.find((option)=> option?.value === ticketFormData.source_info)}
                onChange={handleSourceInfoChange}
                options={sourcesOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='showroom_rating'
                id='showroom_rating'
                label='Showroom rating'
                placeholder='How Was Showroom'
                className={`w-full`}
                value={ratingOption.find((option) => option?.value === ticketFormData.showroom_rating)}
                onChange={handleShowroomRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Service Experience'
                className='w-full h-[41.535px]'
                name='prod_delevery_exp'
                id='prod_delevery_exp'
                value={ticketFormData.prod_delevery_exp}
                onChange={handleTicketInputChange}
                placeholder={`Previous Delivery Service Experience`} 
            />
            </div>


            <div  className='my-4'>
            <SelectInput
                name=''
                id=''
                label='Suggest Other'
                placeholder='Will you suggest any product of esq'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.suggest_other)}
                onChange={handleSuggestOtherChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='staf_behave_rating'
                id='staf_behave_rating'
                label='Staff Behavior Rating'
                placeholder='How was staff behavior'
                className={`w-full`}
                value={ratingOption.find((option)=> option?.value === ticketFormData.staf_behave_rating)}
                onChange={handleStafBehaveRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='delevery_corection'
                id='delevery_corection'
                label='Delevery Corection'
                placeholder='Correct Product Delivery'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.delevery_corection)}
                onChange={handleDeleveryCorectionChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Suggest Exp'
                className='w-full h-[41.535px]'
                name='suggest_exp'
                id='suggest_exp'
                value={ticketFormData.suggest_exp}
                onChange={handleTicketInputChange}
                placeholder={`Why you won't suggest?`} 
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='product_choise_from'
                id='product_choise_from'
                label='Product Choise From'
                placeholder='When did you choose product'
                className={`w-full`}
                value={productChoiseFromOption.find((option)=> option?.value === ticketFormData.product_choise_from)}
                onChange={handleProductChoiseFromChange}
                options={productChoiseFromOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='installation_rating'
                id='installation_rating'
                label='Installation Rating'
                placeholder='How was installation service'
                className={`w-full`}
                value={ratingOption.find((option)=> option?.value === ticketFormData.installation_rating)}
                onChange={handleInstallationRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='has_complain'
                id='has_complain'
                label='Has Complain?'
                placeholder='Do you have any compliant or sugg..'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.has_complain)}
                onChange={handleHasComplainChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='service_center_rating'
                id='service_center_rating'
                label='Service Center Rating'
                placeholder='How was the service center cus.service'
                className={`w-full`}
                value={ratingOption.find((option)=> option?.value === ticketFormData.service_center_rating)}
                onChange={handleServiceCenterRatingChange}
                options={ratingOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Installation Experience'
                name={`installation_exp`}
                id={`installation_exp`}
                value={ticketFormData.installation_exp}
                onChange={handleTicketInputChange}
                className='w-full h-[41.535px]' 
                placeholder={`Installation Service Experience`} 
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Complain Details'
                name={`complain_details`}
                id={`complain_details`}
                value={ticketFormData.complain_details}
                onChange={handleTicketInputChange}
                className='w-full h-[41.535px]' 
                placeholder={`Complaint Or Suggejon on enhancing. our service`} 
            />
            </div>

            <div  className='my-4'>
            <TextareaInput label='Service Center exp'
                name={`service_center_exp`}
                id={`service_center_exp`}
                value={ticketFormData.service_center_exp}
                onChange={handleTicketInputChange}
                className='w-full h-[41.535px]'
                placeholder={`Service center experience`} 
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='unnecessary_charge'
                id='unnecessary_charge'
                label='Unnecessary Charge'
                placeholder='Delivery person ask for any charge'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.unnecessary_charge)}
                onChange={handleUnnecessaryChargeChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='prefer_payment'
                id='prefer_payment'
                label='Prefer Payment Option'
                placeholder='Which payment option do you pefer..'
                className={`w-full`}
                value={paymentOption.find((option)=> option?.value === ticketFormData.prefer_payment)}
                onChange={handlePreferPaymentChange}
                options={paymentOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='discount'
                id='discount'
                label='Discount'
                placeholder='you get GP discount?'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.discount)}
                onChange={handleDiscountChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='gift_item'
                id='gift_item'
                label='Get a git item?'
                placeholder='you get a git item?'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.gift_item)}
                onChange={handleGiftItemChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='installation_charge'
                id='installation_charge'
                label='Installation Charge'
                placeholder='Installation team ask for any money?'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.installation_charge)}
                onChange={handleInstallationChargeChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

            <div  className='my-4'>
            <SelectInput
                name='accessories_sale'
                id='accessories_sale'
                label='Accessories Sale'
                placeholder='Showroom sell you any accessories..'
                className={`w-full`}
                value={YesOrNoOption.find((option)=> option?.value === ticketFormData.accessories_sale)}
                onChange={handleAccessoriesSaleChange}
                options={YesOrNoOption}
                isRequired={false}
            />
            </div>

        </div>  
    </div>
  )
}

export default NonPersonalCRM