import React, { useState } from 'react'
import SelectInput from '../../../../Components/Form/SelectInput'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'

function HirePurchase({
    setTicketFormData,
    ticketFormData,
    handleTicketInputChange,
    hpProductCategoryOption
}) {

    const [selectedHpProductCategory, setSelectedHpProductCategory] = useState(null)
    
    const handleHpProductCategoryChange = (selectedOption) => {
        const hpProductCateID = selectedOption ? selectedOption.value : null;
  
        setSelectedHpProductCategory(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          hp_product_category : hpProductCateID,
        }));
      };
  return (
    
    <div>
        <FormPageTitle title='Hire Purchase'   />
        <div className="grid md:grid-cols-2 lg:grid-cols-3">
        <div  className='my-4'>
                <SelectInput
                name='hp_product_category'
                id='hp_product_category'
                label='Product Category'
                className={`w-full`}
                value={selectedHpProductCategory}
                onChange={handleHpProductCategoryChange}
                options={hpProductCategoryOption}
                isRequired={false}
                />
            </div>
            <div className="my-4">
                <TextareaInput label='Professional Details'
                name='professional_details' id='professional_details'
                onChange={handleTicketInputChange}
                value={ticketFormData.professional_details}
                className='w-full h-[41.535px]' placeholder={`Enter Professional Details`} />
            </div>
        </div>
    </div>
  )
}

export default HirePurchase