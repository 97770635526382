import React from 'react';
import GSRListCom from './GeneralSRComponents/GSRListCom';

const GeneralSRList = () => {

  return (
    <>
      <GSRListCom 
        apiEndpoint={`/inventory/api/general-sr/`}
        detailsURL={`/general-sr-details/`}
        detailsPermissionCode={"3.5.6"}
      />
    </>
  );
};


export default GeneralSRList;