import React, { useEffect, useState } from 'react'
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../Components/FormWithoutClass/TextareaInputWC';
import { AiFillDelete } from 'react-icons/ai';
import ShowToast from '../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { useNavigate } from 'react-router-dom';

const GoodsCheckedTechnicianFeedbackFormComponent = ({data}) => {
        const navigate = useNavigate()
        // requision
        const [requiredMessage,setRequiredMessage]=useState('')

        const [itemCategoriesOptions, setItemCategoriesOptions] = useState([]);
        const [selectedItemCategories, setSelectedItemCategories] = useState([]);
    
        const [itemOptions, setItemOptions] = useState([]);
        const [selectedItem, setSelectedItem] = useState([]);
        const [itemSearchInputValue, setItemSearchInputValue] = useState(null);
    

        const [formData, setFormData] = useState({
            assigned_tech_for_goods_check: null,
            feedback_details: '',
            gctfeedbackpartsdetails_set: [
              {
                item_category:null,
                item: null,
                uom_text:'',
                quantity: '',
                remarks:'',
              },
            ],
          });
    
        
        // fetchItemCategoryData
        useEffect(() => {
            const fetchItemCategoriesData = async () => {
                try {
                    const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-sub-categories-list/`);
                    console.log('fetchItemCategoriesData',response.data.results);
                    const options = response.data.results?.map((cate) => ({
                        value:  cate.id,
                        label: `${cate?.name}`,
                    }));
                    setItemCategoriesOptions(options);
    
                } catch (error) {
                    console.log(error);
                }
            };
    
            fetchItemCategoriesData();
        }, []);
    
    
    
    
        useEffect(() => {
            authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
                .then(response => {
                const filteredOptions = response.data.results
                .filter(item => selectedItemCategories.some(category => category.value === item.sub_category))
                .map(data => ({
                    value: data.id,
                    label : `${data.item_name} | ${data.specification} | ${data.size} | ${data.brand} | ${data.model}`,
                    uom_text: data.uom_text
                }));
    
                console.log('filteredOptions',filteredOptions)    
                setItemOptions(filteredOptions);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }, [itemSearchInputValue,selectedItemCategories]);
    
        
        const handleItemSearchInputChange = (value) => {
        setItemSearchInputValue(value);
        };
    
    
    
        const handleItemCategoryChange = (selectedOption, index) => {
            const updatedFormData = { ...formData };
            updatedFormData.gctfeedbackpartsdetails_set[index].item_category = selectedOption?.value || null;
        
            const updatedSelectedItemCategory = [...selectedItemCategories];
            updatedSelectedItemCategory[index] = selectedOption || null;
        
            setSelectedItemCategories(updatedSelectedItemCategory);
            setFormData(updatedFormData);
        };
        
    
        const handleItemChange = (selectedOption, index) => {
            const updatedFormData = { ...formData };
            updatedFormData.gctfeedbackpartsdetails_set[index].item = selectedOption?.value || null;
            updatedFormData.gctfeedbackpartsdetails_set[index].uom_text = selectedOption?.uom_text || '';
        
            const updatedSelectedItem = [...selectedItem];
            updatedSelectedItem[index] = selectedOption || null;
        
            setSelectedItem(updatedSelectedItem);
            setFormData(updatedFormData);
        };
    
    
        const handleInputChange = (e, index, type) => {
            const updatedData = { ...formData };
            const { name } = e.target;
            let value = e.target.value;  
          
            if (type === 'gctfeedbackpartsdetails_set' && name === 'quantity') {
              const parts = value.split('.');
              const integerPart = parts[0];
              const decimalPart = parts[1] || '';
          
              if (integerPart.length > 12) {
                ShowToast('error', `Ensure that there are no more than 12 digits`);
                return;
              }
          
              if (parts.length === 2) {
                value = `${integerPart}.${decimalPart.slice(0, 2)}`;
          
                if (decimalPart.length > 2) {
                  ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
                }
              }
            }
          
            if (type === 'gctfeedbackpartsdetails_set') {
              setRequiredMessage('');
              updatedData.gctfeedbackpartsdetails_set[index][name] = value;
            } else {
              updatedData[name] = value;
            }
          
            setFormData(updatedData);
        };
          
        
          const areAllFieldsFilled = () => {
            return (
                formData.gctfeedbackpartsdetails_set &&
                formData.gctfeedbackpartsdetails_set.every((item) => {
                    return item?.item !== null && item?.quantity !== '';
                })
            );
        };
        
          
        const handleAddItem = () => {
            if (areAllFieldsFilled()) {
                setFormData((prevState) => ({
                    ...prevState,
                    gctfeedbackpartsdetails_set: [
                        ...prevState.gctfeedbackpartsdetails_set,
                        {
                            item: null,
                            quantity: '',
                        },
                    ],
                }));
                setRequiredMessage('');
            } else {
                setRequiredMessage("Please fill all fields in the existing field before adding a new one.");
            }
        };
        
        
        
        const handleRemoveItem = (index) => {
        const gctfeedbackpartsdetails_set = [...formData.gctfeedbackpartsdetails_set];
        setRequiredMessage('');
        gctfeedbackpartsdetails_set.splice(index, 1);
        setFormData({
            ...formData,
            gctfeedbackpartsdetails_set,
        });
        };
    
    
        const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                const updatedFormData = { 
                    ...formData, 
                    assigned_tech_for_goods_check: data?.id
                };
                console.log(updatedFormData);
                const response = await authAxiosWithBearer.post(`${baseURL}/in_house/api/goods-checked-tech-feedback/`, updatedFormData);
        
                if (response) {
                    console.log(response);
                    ShowToast('success', 'Successfully Submit');
                    navigate('/technician-wise-assigned-goods-check-list');
                }
            } catch (error) {
                console.log(error);
            }
        };
  return (
    <div>
        {data?.checked_status === false && (
            <div className="shadow-lg pt-1 px-2 my-5 pb-10 bg-[#fff]">
                <div className='information-head my-auto px-10 py-2'>
                    <div>DIAGNOSIS AND FEEDBACK</div>
                </div>

                <form onSubmit={handleSubmit}>

                <div className='mt-7 mb-2 mx-2'>
                    <TextareaInputWC 
                            type='text'
                            label='Feedback Details*'
                            className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            name='feedback_details'
                            id={`feedback_details`}
                            value={formData.feedback_details}
                            onChange={(e) => handleInputChange(e)}
                            isRequired={true}
                            placeholder='Enter problem details'
                            min={0}
                        />
                    </div>

                    {formData.gctfeedbackpartsdetails_set?.map((feedbackItem, index) => (
                    <div className='grid grid-cols-2 lg:grid-cols-5 my-2 mx-2 gap-y-2 gap-x-2 border px-1'>
                        
                        <div className="mt-7 mb-2">
                        <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item Category*'
                                id={`item_category_${index}`}
                                name={`gctfeedbackpartsdetails_set[${index}]?.item_category`}
                                value={selectedItemCategories[index]}
                                onChange={(selectedOption) => handleItemCategoryChange(selectedOption, index)}
                                // options={itemCategoriesOptions.filter(
                                //     (option) =>
                                //         !selectedItemCategories.some(
                                //             (selectedItem) => selectedItem && selectedItem.value === option.value
                                //         )
                                // )}
                                options={itemCategoriesOptions}
                                placeholder='Select item category'
                                isRequired={true}
                                isClearable={true}
                            />
                        </div>
                        
                        <div className="mt-7 mb-2">
                            <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item*'
                                id={`item_${index}`}
                                name={`gctfeedbackpartsdetails_set[${index}]?.item`}
                                value={selectedItem[index]}
                                onInputChange={handleItemSearchInputChange}
                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                options={itemOptions.filter(
                                    (option) =>
                                        !selectedItem.some(
                                            (selectedItem) => selectedItem && selectedItem.value === option.value
                                        )
                                )}
                                placeholder='Select item'
                                isRequired={true}
                                isClearable={true}
                            />


                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC 
                                type='text'
                                label='UOM*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='uom_text'
                                id={`uom_text_${index}`}
                                value={feedbackItem.uom_text}
                                onChange={(e) => handleInputChange(e, index, 'gctfeedbackpartsdetails_set')}
                                readOnly={true}
                                placeholder='Enter uom'
                            />
                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC 
                                type='number'
                                label='Quantity*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='quantity'
                                id={`quantity_${index}`}
                                value={feedbackItem.quantity}
                                onChange={(e) => handleInputChange(e, index, 'gctfeedbackpartsdetails_set')}
                                isRequired={true}
                                placeholder='Enter quantity'
                                min={0}
                            />
                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC 
                                type='text'
                                label='Remarks'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='remarks'
                                id={`remarks_${index}`}
                                value={formData.remarks}
                                onChange={(e) => handleInputChange(e, index, 'gctfeedbackpartsdetails_set')}
                                isRequired={false}
                                placeholder='Enter remarks'
                                min={0}
                            />
                        </div>

                        {index !== 0 && (
                        <div className='mt-9 mx-10 mb-2'>
                            
                                <button
                                type="button"
                                onClick={() => handleRemoveItem(index)}
                                className="text-red-500"
                                >
                                <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>
                        </div>
                        )}
                    </div>
                    ))}

                    {requiredMessage && (
                        <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span> 
                    )}
                    <div className="flex justify-between mx-4 my-2">

                        <button 
                            className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                            type="button"
                            onClick={() => {
                                console.log('Add new item Button Clicked');
                                handleAddItem();
                            }}
                            >ADD MORE</button>
                        <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                    </div>
                </form>

            </div>
        )}
    </div>
  )
}

export default GoodsCheckedTechnicianFeedbackFormComponent