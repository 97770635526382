import React from 'react'
import CatWiseTotalBill from './CatWiseTotalBill'
import CatWiseTotalBillWinW from './CatWiseTotalBillWinW'

const InstallationCatWiseInvoiceList = ({installationData}) => {
  return (
    <div>
        {/* 3rd */}

        <div className='flex sm:flex-row flex-col'>
            <div className='mx-1 my-2'>

            <div className='overflow-x-auto overflow-y-auto'>

            <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

                <thead>

                    <tr className='bg-[#EEEEEE]'>

                        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>
                        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Invoice No</th>

                        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Charge</th>

                        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Basic Charge Quantity </th>

                        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> Additional Charge (BDT)  </th>

                        <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> TOTAL (BDT) </th>

                    </tr>

                </thead>



                <tbody className='text-center'>

                    {installationData?.map((bill,index)=>(
                        <tr >
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{index+1}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.bill_invoice}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.basic_charge}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.basic_charge_quantity}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.additional_charge}</td>
                            <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{bill?.total_payable_amount}</td>

                        </tr>
                    ))}


                    <tr>
                        <td colSpan="5" className='border-2 border-[#ED6C6C] w-[400px] py-3'>TOTAL AMOUNT</td>
                        <td colSpan="1" className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                            <CatWiseTotalBill invoiceData={installationData} />
                        </td>
                    </tr>
                    {/* eighteen thousand four hundred twenty five taka only */}
                    <tr>
                        <td colSpan="6" className='border-2 border-[#ED6C6C] w-[400px] py-3 '>AMOUNT IN WORD - 
                            <CatWiseTotalBillWinW invoiceData={installationData} />  
                        </td>
                    </tr>

                </tbody>

            </table>

            </div>


            </div>


        </div>

        {/* 4th  */}
    </div>
  )
}

export default InstallationCatWiseInvoiceList