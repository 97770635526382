import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../baseURL';
import noImage from '../../assest/Images/no-profile.jpg'
import PageTitleComponent from '../../Components/PageTitleComponent';
import LoadingSpinner from '../../Components/LoadingSpinner';

function UserDetails() {
    const {id} = useParams()
    const [userData, setUserData] = useState(null)
    const [loading,setLoading]= useState(true)

    // fetchSingleUserData
    useEffect(() => {
        const fetchReportingData = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/api/user_list/${id}/`);
                setUserData(response.data)
            } catch (error) {
                console.log(error)
            }
            finally{
              setLoading(false)
            }
        };
        fetchReportingData();
        }, [id]); 


    if (loading) {
      return <div><LoadingSpinner /></div>;
    }
      
  return (
    <div className='user-details'>

      {/* user details  */}
      <div className="shadow-xl my-2 p-4 border">

        <div className="user-details-page-title mb-5 shadow">
          <div className="my-auto py-2">
            <h1 className='uppercase'>User Details</h1>
          </div>
        </div>

        <div className="md:flex md:flex-wrap">
          <div className="md:w-[30%] my-auto">
            <div className="user-img flex justify-center items-center">
              {userData?.photo !== null ?
                <img src={`${baseURL}/static${userData?.photo}`} alt="" className="max-w-full max-h-full" />
                :
                <img src={noImage} alt="" className="max-w-full max-h-full" />
              }
            </div>
          </div>
          <div className="md:w-[70%] my-auto px-2">
            <div className='text-center my-2 border-b-2 pb-2'>
              <p className='text-semibold text-[25px]'>{userData?.name}</p>
              <p className='text-semibold text-[15px]'>{userData?.primary_role_name}</p>
            </div>

            <div className='grid grid-cols-2 my-3 gap-x-16 gap-y-4'>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Employee ID</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.employee_id}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Email</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.email}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Phone</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.phone_no}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Reporting To</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.reporting_to_name}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Company</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.company}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>S.Company</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.secondary_company_names}</p>
              </div>

              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Department</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.department}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Is D.Head</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.is_department_head === true ? 'Yes' : 'No'}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Active Status</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'>{userData?.status === true ? 'Active' : 'Inactive'}</p>
              </div>
              <div className='flex flex-wrap'>
                <p className='w-[40%] md:w-[35%]'>Signature</p>
                <p className='w-[5%] md:w-[5%]'>:</p>
                <p className='w-[55%] md:w-[60%] text-end border-b'><img className='w-[100px]' src={`${baseURL}/static${userData?.signature}`} alt="" /></p>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* user menu permissions  */}
      <div>
        {userData?.menu_permissions_name.length > 0 && (
        <div className='shadow-lg p-4 my-5 border'>
            <div className="user-details-page-title mb-5 shadow">
              <div className="my-auto py-2">
                <h1 className='uppercase'>User Menu Permissions List</h1>
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-4'>
              {Object.entries(userData.menu_permissions_name.reduce((acc, permission) => {
                const parts = permission.split('::'); // Update this line to use '::' as the separator
                const category = parts[0].trim(); // Trim to remove extra spaces
                const subCategory = parts[1].trim(); // Trim to remove extra spaces
                // const permissionName = parts[2].trim(); // Trim to remove extra spaces
                const permissionName = parts[2].trim().split(' ').pop(); // Get the last word

                if (!acc[category]) {
                  acc[category] = {};
                }

                if (!acc[category][subCategory]) {
                  acc[category][subCategory] = [permissionName];
                } else {
                  acc[category][subCategory].push(permissionName);
                }

                return acc;
              }, {})).map(([category, subCategories]) => (
                <div key={category}>
                  <div>
                    <p className='uppercase text-[15px] font-semibold border-b-2 mb-2 pb-2'>{category} Module </p>

                    {Object.entries(subCategories).map(([subCategory, permissions]) => (
                      <div key={subCategory} className='my-2'>
                        <p className='text-[15px] font-semibold text-[#db3535] my-1'>{subCategory}</p>
                        <p>{permissions.join('  ,  ')}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      
    </div>

  )
}

export default UserDetails