import React, { useState } from 'react'
import HOInvoiceCreateModal from './HOInvoiceCreateModal';

const HOInvoiceCreateModalButton = ({
  detailsData,
  srIssuedData,
  scbccWpTicketNo,
  installationTicketNo,
  modalPageTitle,
}) => {
    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [selectedScbccWpTicketNo, setSelectedScbccWpTicketNo] = useState(null);
    const [selectedInstallationTicketNo, setSelectedInstallationTicketNo] = useState(null);
  

    const openInvoiceModal = (ticketNo) => {
      setInvoiceModalOpen(true);
      if(scbccWpTicketNo){
        setSelectedScbccWpTicketNo(ticketNo);
      }
      if(installationTicketNo){
        setSelectedInstallationTicketNo(ticketNo)
      }
    };
  
    const closeInvoiceModal = () => {
      setInvoiceModalOpen(false);
      setSelectedScbccWpTicketNo(null)
    };



  return (
    <div>
        <div className='flex justify-center my-3 '>
              <button
                  onClick={() => openInvoiceModal(
                    scbccWpTicketNo?scbccWpTicketNo : installationTicketNo? installationTicketNo : null
                  )}
                  className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                  >
                  Create Invoice For HO
              </button>
        </div>

          <HOInvoiceCreateModal
                isOpen={isInvoiceModalOpen}
                onClose={closeInvoiceModal}
                scbccWpTicketNo={selectedScbccWpTicketNo}
                installationTicketNo={selectedInstallationTicketNo}
                modalPageTitle={modalPageTitle}
                detailsData={detailsData}
                srIssuedData={srIssuedData}
            />
    </div>
  )
}

export default HOInvoiceCreateModalButton