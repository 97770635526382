import React, { useEffect, useState } from 'react';
import { AiFillDelete, AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import Swal from 'sweetalert2';
import { FaUserCheck, FaUserXmark } from "react-icons/fa6";
import { MdMobileFriendly, MdMobileOff } from "react-icons/md";
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import LoadingSpinner from '../../../../Components/LoadingSpinner';

function SupplierListComponent({
  apiEndpoint,
  columnsConfig,
  title,
  SearchKey1,
  SearchKey2,
  SearchKey3,
  SearchKey4,
  sortOrder,
  setSortOrder,
  addNewURL,
  dataEditURL,
  currentPage,
  setCurrentPage
}) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${apiEndpoint}`, {
          params: {
            page: currentPage,
            search: searchText    
          },
        });  
        const { results, count } = response.data;

        const resultsData = results.map((item) => ({ ...item, key: item.id }));
        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [apiEndpoint, currentPage, searchText]);




  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  const filteredData = data?.filter(
    (item) =>
      item[SearchKey1]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey2]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey3]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey4]?.toString().includes(searchText.toLowerCase())
  );

  
  return (
    <>
      <div className="flex justify-between page-header mb-5">
        <div className="page-title my-auto ml-5">
          <h1 className='uppercase'>{title}</h1>
        </div>
        <div className="page-search-and-add-new-button flex my-auto mr-5">
          <div className="page-search mr-2">
            <div className="relative">
              <input
                className="page-search-input w-[150px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search here"
              />
              <div className="text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="submit">
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div>

          {addNewURL !== '' &&
          <div className="add-new-button px-2">
             <Link to={addNewURL}>Add New</Link>
          </div>
          }
          
        </div>
      </div>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
      <>
        <Table
          columns={[
            ...columnsConfig.map((column) => ({
              ...column,
              sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
            })),

            {
              title: 'Actions',
              dataIndex: 'actions',
              render: (_, record) => (
                <div className='flex gap-2'>

                  {dataEditURL !== '' &&
                  <button title='edit' onClick={() => navigate(`${dataEditURL}/${record.id}`)} className='rounded'>
                    <AiFillEdit className='text-[#6F6F7E] text-xl' />
                  </button>
                  }
                </div>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <div>
                  <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 border-b pb-2'>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Phone</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.phone}</p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Email</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.email}</p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Contact Person</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.contact_person}</p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Contact Person No</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>
                              {record?.contact_person_no}
                        </p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Contact Person No</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>
                              {record?.contact_person_no}
                        </p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Vat Reg</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>
                              {record?.vat_reg}
                        </p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>License No</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>
                              {record?.license_no}
                        </p>
                      </div>
                    </p>
                    <p className="expanded-row-content">
                      <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Supplier Type</p>
                        <p className='w-[5%] md:w-[5%] text-center'>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>
                              {record?.type}
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
              </>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={filteredData}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            setSortOrder({
              columnKey: sorter.field,
              order: sorter.order,
            });
          }}
        />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
    </>
  );
}


export default SupplierListComponent;

