import React from 'react';
import Logo from '../../../../assest/Images/logo.png';
import { formatDateTime } from '../../../../Components/formatDateTime';
import PayAmountInWord from './PayAmountInWord';


const MrDetails = ({ detailsData, componentPDF }) => {
  return (
    <div className='font-sans'>
        <div className="mr-details-container relative bg-[#fff]">
        <div className="mr-view">
            <div className="flex justify-between mx-1">
            <div className="logo-container">
                {/* Main Logo */}
                <img className='logo h-[140px]' src={Logo} alt="Esquire Company Logo" />
            </div>
            <div>
                <div className='text-[20px] font-semibold'>Esquire Customer Care Ltd</div>
                <div className='address'>
                <p>260/A, Tejgaon I/A Nabisco,</p>
                <p>Tejgaon, Dhaka-1208</p>
                <p>Tel: 09610001010</p>
                <p>Email: <a href="mailto:info_ccd@esquirebd.com">info_ccd@esquirebd.com</a></p>
                </div>
            </div>
            </div>

            <h1 className='text-[20px] uppercase font-bold text-center'>money receipt</h1>

            <div className="mr-body mx-5">
                <div className="grid grid-cols-2 my-5">
                        <div className='flex justify-start'>
                            <div>MR. NO. </div>
                            <div className='ml-2 w-[60%] border-b border-[#000] text-center'>
                                {detailsData?.mr_no}
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <div>Date </div>
                            <div className='ml-2 w-[60%] border-b border-[#000] text-center'>
                                {detailsData?.created_at && (
                                    <>{formatDateTime(detailsData?.created_at)}</>
                                )}
                            </div>
                        </div>
                </div>

                <div className="grid grid-cols-2 my-5 gap-x-5">
                        <div className='flex justify-start'>
                            <div>Name </div>
                            <div className='ml-2 w-[100%] border-b border-[#000] text-center'>
                                {detailsData?.mr_person_name}
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <div>Mobile </div>
                            <div className='ml-2 w-[100%] border-b border-[#000] text-center'>
                                {detailsData?.mr_person_contact_no}
                            </div>
                        </div>
                </div>

                <div className="grid grid-cols-1 my-5">
                        <div className='flex'>
                            <div className='w-[180px] sm:w-[150px] md:w-[135px]'>Amount Of Taka </div>
                            <div className='ml-2 w-[100%] border-b border-[#000] text-center'>
                                <PayAmountInWord total_payable_amount={detailsData?.pay_amount} />
                            </div>
                        </div>
                </div>

                <div className="grid md:grid-cols-2 my-5 gap-x-5">
                        <div className='flex justify-start mb-5 md:mb-0'>
                            <div className='w-[170px] sm:w-[150px] md:w-[150px]'>Payment Type </div>
                            <div className='ml-2 w-[100%] border-b border-[#000] text-center'>
                                {detailsData?.pay_type}
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <div className='w-[170px] sm:w-[150px] md:w-[170px]'>Transaction No</div>
                            <div className='ml-2 w-[100%] border-b border-[#000] text-center'>
                                {detailsData?.transaction_no}
                            </div>
                        </div>
                </div>
                <div className="grid grid-cols-1 my-5">
                        <div className='flex'>
                            <div className='w-[100px] sm:w-[100px] md:w-[90px]'>Invoice No </div>
                            <div className='ml-2 w-[100%] border-b border-[#000] text-center'>
                                {detailsData?.invoice_no_text}
                            </div>
                        </div>
                </div>

                <div className="grid grid-cols-3 my-5 gap-x-5">
                        <div className='flex justify-start mb-5 md:mb-0'>
                            <div className='my-auto'>Taka</div>
                            <div className='ml-5 my-auto border border-[#000] w-[100%] md:w-[60%] '>
                                <p className='text-end mx-1'>{detailsData?.pay_amount}</p>
                            </div>
                        </div>
                        <div className='mb-5 md:mb-0'>
                            <div className='flex justify-center'>
                                <hr className='w-[70%] mt-5 border-1 border-[#000]' />
                            </div>
                            <div className='text-center'>Received By</div>
                        </div>
                        <div>
                            <div className='flex justify-center'>
                                <hr className='w-[70%] mt-5 border-1 border-[#000]' />
                            </div>
                            <div className='text-center'>Authorized By</div>
                        </div>
                </div>
            </div>

        </div>



        {/* Watermark covering the entire page */}
        <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center -z-0 opacity-10'>
            <img className='watermark' src={Logo} alt="Watermark" />

        </div>
        </div>
    </div>

  );
};

export default MrDetails;
