import React, { useEffect, useState } from 'react';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../baseURL';
import { AiFillDelete, AiFillEdit, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';

function StockListTable({
  apiEndpoint,
  columnsConfig,
  title,
  SearchKey1,
  SearchKey2,
  SearchKey3,
  SearchKey4,
  sortOrder,
  setSortOrder,
  addNewURL,
  dataEditURL,
  deleteURL,
  user,
  currentPage,
  setCurrentPage
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const jwtToken = localStorage.getItem('token')

  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/${apiEndpoint}/`, {
          params: {
            page: currentPage,
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
        const resultsData = results.map((item,index) => ({ ...item, key: index }));

        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, searchText]);


  const handleSearch = (value) => {
    setSearchText(value);
  };


  const handleDelete = async (itemId) => {
    const result = await Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await authAxiosWithBearer.delete(`${apiEndpoint}${itemId}/`,{
          headers:{
            Authorization : `${jwtToken}`
          }
        });
        const updatedData = data.filter((item) => item.id !== itemId);
        setData(updatedData);
        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } catch (error) {
        console.log(error);
        Swal.fire('Error', 'An error occurred while deleting the item.', 'error');
      }
    }
  };


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };
  
  return (
    <>
      <div className="flex justify-between page-header mb-5">
        <div className="page-title my-auto ml-5">
          <h1 className='uppercase'>{title}</h1>
        </div>
        <div className="page-search-and-add-new-button flex my-auto mr-5">
          <div className="page-search mr-2">
            <div className="relative">
              <input
                className="page-search-input w-[150px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search here"
              />
              <div className="text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="submit">
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div>

          {user?.menu_permissions?.includes("3.8.1") && (
            <>
              {addNewURL !== '' &&
              <div className="add-new-button px-2">
                <Link to={addNewURL}>Add New</Link>
              </div>
              }
            </>
          )}
          
        </div>
      </div>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
      <>
      <Table
        columns={[
          ...columnsConfig.map((column) => ({
            ...column,
            sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
          })),

          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
              <div className='flex gap-2'>

            {user?.menu_permissions?.includes("3.8.3") && (
                <>
                {dataEditURL !== '' &&
                <button onClick={() => navigate(`${dataEditURL}/${record.id}`)} className='rounded'>
                  <AiFillEdit className='text-[#6F6F7E] text-xl' />
                </button>
                }
                </>
              )}
              {user?.menu_permissions?.includes("3.8.4") && (
                <>
                {deleteURL !== '' &&
                <button className='rounded ml-2' onClick={() => handleDelete(record.id)}>
                  <AiFillDelete className='text-xl text-[#F35B5B]' />
                </button>
                }
                </>
              )}

              </div>
            ),
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <div className='shadow-lg p-4 border'>
            <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 '>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Item Name</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.item_name}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Brand Name</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.brand_name}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Supplier Name</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.supplier_name}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Store Master</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.store_master_text}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Rack Master</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.rack_master_text}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Bin Master</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.bin_master_text}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Block Master</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.block_master_text}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Lot</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.lot}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>UOM</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.uom_text}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Price</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.price}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Stockin Qty</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.stockin_qty}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Stockout Qty</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.stockout_qty}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Allocated Qty</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.allocated_qty}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Dam Qty</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.dam_quantity}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Reorder Lavel</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.reorder_lavel}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Ticket</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.ticket_no}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Spec Id</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.spec_id}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Workorder No</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.workorder_no}</p>
                </div>
            </p>
            <p className="expanded-row-content">
                <div className='flex flex-wrap'>
                    <p className='w-[40%] md:w-[40%]'>Remarks</p>
                    <p className='w-[5%] md:w-[5%] text-center '>-</p>
                    <p className='w-[55%] md:w-[55%] text-end'>{record?.remarks}</p>
                </div>
            </p>
            </div>
          </div>
          ),
          rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
        }}
        dataSource={data}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          setSortOrder({
            columnKey: sorter.field,
            order: sorter.order,
          });
        }}
      />
      <div className='my-5'>
        <Pagination
        current={currentPage}
        total={totalItems}
        pageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
      />
    </div>
      </>
      )}
    </>
  );
}



const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(StockListTable);