import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StockListTable from './InventoryComponent/StockListTable';
import { connect } from 'react-redux';

function StockList({user}) {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState({});
  const [currentPage,setCurrentPage]=useState(1)

  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Item Name/Size',
      key: 'item_and_size',
      render: (text, record) => (
        <span>
          {record.item_name}/{record.item_size}
        </span>
      ),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
      sortOrder: sortOrder['item_name'],
    },
    {
      title: 'UOM',
      dataIndex: 'uom_text',
      key: '2',
    },
    {
      title: 'Stockin Qty',
      dataIndex: 'stockin_qty',
      key: '3',
      sorter: (a, b) => a?.stockin_qty?.localeCompare(b?.stockin_qty),
      sortOrder: sortOrder['stockin_qty'],
    },
    {
      title: 'Stockout Qty',
      dataIndex: 'stockout_qty',
      key: '4',
    },
    {
      title: 'Damage Qty',
      dataIndex: 'dam_quantity',
      key: '5',
    },
    {
        title: 'U/Price',
        dataIndex: 'price',
        key: '6',
        sorter: (a, b) => a?.price?.localeCompare(b?.price),
        sortOrder: sortOrder['price'],
      },
      {
        title: 'Supplier',
        dataIndex: 'supplier_name',
        key: '7',
        sorter: (a, b) => a?.supplier_name?.localeCompare(b?.supplier_name),
        sortOrder: sortOrder['supplier_name'],
      },
  ];
  

  const addNewURL = user?.menu_permissions?.includes("3.2.1")? '/item-stock-add':''
  const deleteURL = user?.menu_permissions?.includes("3.2.4")? '':''
  const dataEditURL = user?.menu_permissions?.includes("3.2.3")? '/item-stock-update':''


  return (
    <div className='bg-[#fff] rounded-[5px]'>
      <StockListTable
        apiEndpoint="inventory/api/inv-stocks"
        columnsConfig={columns}
        title="Item Stock List"
        SearchKey1='supplier_name'
        SearchKey2='stockin_qty'
        SearchKey4='item_name'
        SearchKey3=''
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        addNewURL={addNewURL}
        dataEditURL={dataEditURL}
        deleteURL ={deleteURL}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(StockList);