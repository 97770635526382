import React, { useState } from 'react'
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import SelectInput from '../../../../Components/Form/SelectInput';
import TextareaInput from '../../../../Components/Form/TextareaInput';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearerWithContentTypeXwwwformUrlencoded, baseURL } from '../../../../baseURL';

const ATForScbcc = ({
    isModalOpen,
    closeModal,
    PriorityOption,
    modalSubmitLoading,
    setModalSubmitLoading,
    fetchTicketData,
    refreshAssignedDataList,
    technicianId,
    ticketData,
}) => {

    const [formData, setFormData] = useState({
        ticket:null,
        priority:null,
        hvac:null,
        installation:null,
        service:null,
        vendor:null,
        technician:null,
        location:'',
        remarks:''
    });
    
    const handleModalFormInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
    };
    
    const handlePrirotyChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
        setFormData(prevState => ({ ...prevState, priority: selectedOption.value }));
    } else {
        setFormData(prevState => ({ ...prevState, priority: null }));
    }
    };

    
    const handleServiceModalFormSubmit = async (e) => {
    e.preventDefault();
    try {
    setModalSubmitLoading(true)
        if (isModalOpen && technicianId !== null) {
        const updatedFormData = {
            ...formData,
            technician: technicianId,
            service: ticketData?.service_bill[0]?.id,
            ticket: ticketData?.id,
        };
        
        console.log('Form Data:', updatedFormData);
        
        const response = await authAxiosWithBearerWithContentTypeXwwwformUrlencoded.post(`${baseURL}/ccd/api/assign-technician/`, updatedFormData);

        ShowToast('success', 'Successfully Submit')
        closeModal();
        setFormData({
        });

        await fetchTicketData();
        setModalSubmitLoading(false)
        refreshAssignedDataList();
        // resetTechnicianAssignedForm();
        
        }
    } catch (error) {
        setModalSubmitLoading(false)
        console.error(error);
    }
    };
    
  return (
    <div>
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Service Modal"
            className="modal"
            overlayClassName="overlay"
            >

            <div className='mb-2 w-[350px] md:w-[500px] lg:w-[500px]'>
            <div className='flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px]   mb-10 shadow-xl'>
                <h1 className='font-semibold'>Technician Assign For Service </h1>
                <button className='text-[#000]' onClick={closeModal}><ImCancelCircle /></button>
            </div>
            
            <div className="mx-auto">
                <form onSubmit={handleServiceModalFormSubmit} className='px-2'>
                
                    <div  className='my-10'>
                        <SelectInput
                        name='priority'
                        id='priority'
                        label='Priority'
                        className={`w-[99%] md:w-[99%] lg:w-[99%]`}
                        value={PriorityOption.find((option) => option?.value === formData.priority)}
                        onChange={handlePrirotyChange}
                        options={PriorityOption}
                        isRequired={false}
                        />
                    </div>

                    <div  className='mt-10 mb-5'>                                
                        <TextareaInput type='text' 
                        className='h-[100.535px] w-[99%] md:w-[99%] lg:w-[99%]'
                        name='remarks' id='remarks'
                        value={formData.remarks}
                        onChange={handleModalFormInputChange}
                        label='Remarks' isRequired={false}
                        placeholder='Enter Remarks here'/>
                    </div>

                    {modalSubmitLoading?
                    <button disabled="disabled" className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-1'>Submiting...</button>
                    :
                    <button type="submit" className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-1'>Assign</button>
                    }
                </form>
            </div>
            
            </div>
        
        </Modal>
    </div>
  )
}

export default ATForScbcc