import React, { useEffect } from 'react'
import TextareaInput from '../../Components/Form/TextareaInput'
import InputFiled from '../../Components/Form/InputFiled'
import SelectInput from '../../Components/Form/SelectInput'
import FormPageTitle from '../../Components/Form/FormPageTitle'
import StockFormComponent from './InventoryComponent/StockFormComponent'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../baseURL'
import ShowToast from '../../Components/ShowToast'

function StockUpdate() {
const {id} = useParams()
const {
      handleInputChange,
      handleItemChange,
      handleSupplierChange,
      handleStoreMasterChange,
      handleRackMasterChange,
      handleBinMasterChange,
      handleBlockMasterChange,
      handleUOMChange,
      handleTicketChange,
      handleTicketSearchInputChange,
      handleSubmit,
      formData,
      setFormData,
      itemOption,
      setItemOprion,
      selectedItem,
      setSelectedItem,
      supplierOption,
      setSupplierOprion,
      selectedSupplier,
      setSelectedSupplier,
      handleSupplierSearchInputChange,
      storeMasterOption,
      setStoreMasterOprion,
      selectedStoreMaster,
      setSelectedStoreMaster,
      rackMasterOption,
      setRackMasterOprion,
      selectedRackMaster,
      setSelectedRackMaster,
      binMasterOption,
      setBinMasterOprion,
      selectedBinMaster,
      setSelectedBinMaster,
      blockMasterOption,
      setBlockMasterOprion,
      selectedBlockMaster,
      setSelectedBlockMaster,
      uomOption,
      setUOMOprion,
      selectedUOM,
      setSelectedUOM,
      ticketOption,
      setTicketOprion,
      selectedTicket,
      setSelectedTicket,
      navigate,
      setSupplierSearchInputValue,
      setTicketSearchInputValue,
      handleItemSearchInputChange,
    } = StockFormComponent()


    console.log('selectedUOM',selectedUOM)

    // fetchSingleStockData
    useEffect(()=>{
        const fetchSingleStockData = async ()=>{
              try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-stocks/${id}/`)
                
                    setSelectedSupplier(response.data.supplier ? {
                        value: response.data.supplier,
                        label: response.data.supplier_name
                      } : null);
                
                      setSelectedStoreMaster(response.data.store ? {
                        value: response.data.store,
                        label: response.data.store_master_text
                      } : null);
                
                      setSelectedUOM(response.data.uom ? {
                        value: response.data.uom,
                        label: response.data.uom_text
                      } : null);
                
                      setSelectedItem(response.data.item ? {
                        value: response.data.item,
                        label: `${response.data.item_name} | ${response.data.item_size} | ${response.data.item_brand} | ${response.data.item_model}`,
                      } : null);
                
                      if (response.data?.rack) {
                        setSelectedRackMaster({
                          value: response.data.rack,
                          label: response.data.rack_master_text
                        });
                      }
                
                      setSelectedBinMaster(response.data.bin_no ? {
                        value: response.data.bin_no,
                        label: response.data.bin_master_text
                      } : null);
                
                      setSelectedBlockMaster(response.data.block ? {
                        value: response.data.block,
                        label: response.data.block_master_text
                      } : null);
                
                      setSelectedTicket(response.data.ticket ? {
                        value: response.data.ticket,
                        label: response.data.ticket_no
                      } : null);

                setFormData(response.data)
              } catch (error) {
                    console.log(error)
              }
        };fetchSingleStockData()
      },[id])


    const handleStockUpdateSubmit = (e) =>{
        e.preventDefault();
        const { item, uom, price, stockin_qty } = formData;

        if (!item || !uom || !price || !stockin_qty) {
          ShowToast('error', 'All Required Fields need to be filled up');
          return;
        }

        console.log('formData',formData)

        try {
            const response = authAxiosWithBearer.put(`${baseURL}/inventory/api/inv-stocks/${id}/`,formData)
            console.log(response)
            if(response){
                ShowToast('success', 'Successfully Update')
                navigate('/item-stock-list')
            }
        } catch (error) {
            console.log(error)
            if(error.response.data.error){
                  ShowToast('error', `${error.response.data.error}`);
                }
                else{
                  ShowToast('error', 'Failed to Add Item');
            }
        }
    }


  return (
    <div>
    <FormPageTitle title='Item Stock Update Form' FormPageRightSideButtonURL='/item-stock-list' LinkName='Close' />
    <div className='p-3 mx-2 shadow-lg'>
        <form onSubmit={handleStockUpdateSubmit}>
            <div className="grid md:grid-cols-2 lg:grid-cols-3">

              <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="item"
                      name="item"
                      label='Item*'
                      value={selectedItem}
                      placeholder='Select item'
                      onInputChange={handleItemSearchInputChange}
                      onChange={handleItemChange}
                      options={itemOption}
                      isRequired={true}
                  />
              </div>

            <div className='my-4'>
                  <InputFiled type='text' 
                  value={formData.brand_name} 
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='brand_name' id='brand_name'  label='Brand' 
                  isRequired={false} 
                  placeholder='Enter brand name'  
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="supplier"
                      name="supplier"
                      label='Supplier'
                      value={selectedSupplier}
                      placeholder='Select Supplier'
                      onChange={handleSupplierChange}
                      onInputChange={handleSupplierSearchInputChange}
                      options={supplierOption}
                      isRequired={false}
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="store"
                      name="store"
                      label='Store'
                      value={selectedStoreMaster}
                      placeholder='Select Store'
                      onChange={handleStoreMasterChange}
                      options={storeMasterOption}
                      isRequired={false}
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="rack"
                      name="rack"
                      label='Rack'
                      value={selectedRackMaster}
                      placeholder='Select Rack'
                      onChange={handleRackMasterChange}
                      options={rackMasterOption}
                      isRequired={false}
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="bin_no"
                      name="bin_no"
                      label='Bin No'
                      value={selectedBinMaster}
                      placeholder='Select Bin No'
                      onChange={handleBinMasterChange}
                      options={binMasterOption}
                      isRequired={false}
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="block"
                      name="block"
                      label='Block'
                      value={selectedBlockMaster}
                      placeholder='Select Block'
                      onChange={handleBlockMasterChange}
                      options={blockMasterOption}
                      isRequired={false}
                  />
            </div>
            
            <div className='my-4'>
                  <InputFiled type='text' 
                  value={formData.lot} 
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='lot' id='lot'  label='Lot' 
                  isRequired={false} 
                  placeholder='Enter lot'  
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="uom"
                      name="uom"
                      label='UOM*'
                      value={selectedUOM}
                      placeholder='Select UOM'
                      onChange={handleUOMChange}
                      options={uomOption}
                      isRequired={true}
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='number' 
                  value={formData.price} 
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='price' id='price'  label='Price*' 
                  isRequired={true} 
                  placeholder='Enter price'  
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='text' 
                  value={formData.stockin_qty} 
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='stockin_qty' id='stockin_qty'  label='Stockin Qty*' 
                  isRequired={true}
                  placeholder='Enter stockin qty'  
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='number' 
                  value={formData.stockout_qty}
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='stockout_qty' id='stockout_qty'  label='Stockout Qty*' 
                  isRequired={true} 
                  placeholder='Enter stockout qty'  
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='number' 
                  value={formData.allocated_qty}
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='allocated_qty' id='allocated_qty'  label='Allocated Qty*' 
                  isRequired={true} 
                  placeholder='Enter allocated qty'  
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='number' 
                  value={formData.dam_quantity}
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='dam_quantity' id='dam_quantity'  label='Dam Qty*' 
                  isRequired={true} 
                  placeholder='Enter dam qty'  
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='number' 
                  value={formData.reorder_lavel}
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='reorder_lavel' id='reorder_lavel'  label='Reorder Lavel*' 
                  isRequired={true} 
                  placeholder='Enter reorder lavel'  
                  />
            </div>

            <div className="my-4">
                  <SelectInput
                      className={`w-full`}
                      id="ticket"
                      name="ticket"
                      label='Ticket'
                      value={selectedTicket}
                      placeholder='Select Ticket'
                      onChange={handleTicketChange}
                      onInputChange={handleTicketSearchInputChange}
                      options={ticketOption}
                      isRequired={false}
                  />
            </div>
            <div className='my-4'>
                  <InputFiled type='number'
                  max='2147483647'
                  step='0'
                  value={formData.spec_id}
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='spec_id' id='spec_id'  label='Spec Id' 
                  isRequired={false} 
                  placeholder='Enter spec id'  
                  />
            </div>

            <div className='my-4'>
                  <InputFiled type='text' 
                  value={formData.workorder_no}
                  className={`w-full`}
                  onChange={handleInputChange} 
                  name='workorder_no' id='workorder_no'  label='Workorder No' 
                  isRequired={false} 
                  placeholder='Enter workorder no'  
                  />
            </div>

            <div className="my-4">
              <TextareaInput 
                  label='Remarks' 
                  name='remarks' 
                  id='remarks'
                  className='h-[41.535px] w-full' 
                  placeholder={`Enter remarks`} 
                  value={formData.remarks}
                  onChange={handleInputChange}
              />
            </div>

              <div className=" my-4">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2'>Submit</button>
              </div>
            </div>

          </form>
    </div>
  </div>
  )
}

export default StockUpdate