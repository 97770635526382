import React, { useEffect, useState } from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import InputFiled from '../../../../Components/Form/InputFiled'
import DateInput from '../../../../Components/Form/DateInput'
import SelectInput from '../../../../Components/Form/SelectInput'
import TextareaInput from '../../../../Components/Form/TextareaInput'
import InvoiceWiseIJCrDataGet from '../TicketComponents/InvoiceWiseIJCrDataGet'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL'

const IJCR = ({
        setTicketFormData,
        ticketFormData,
        handleTicketInputChange,
        setInvCustomerPhoneNo,
        ibProductCategoryOption,
        ibProductBrandOptions,
        ibShowroomOption,
        autoIJCR,
   }) => {

    const [selectedIbProductCategory, setSelectedIbProductCategory] = useState(null)
    const [selectedIbProductBrand, setSelectedIbProductBrand] = useState(null);
    const [selectedIbShowroom, setSelectedIbShowroom] = useState(null)

    const [basicChargeOption, setBasicChargeOption] = useState(null);
    const [selectedBasicChage, setSelectedBasicChage] = useState(null)


    const IbrequestedTypeOption = [
        { value: 'Installation', label: 'Installation' },
        { value: 'Re-Installation', label: 'Re-Installation' },
      ];


    useEffect(() => {
        const fetchTicketBasicChargeData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/ijcr-ticket-basic-charge/`);
            // console.log(response)
            setBasicChargeOption( response.data.map((data) => ({
                value: data.id,
                label: `${data.title} | BD ${data.amount}`,
                title: data.title,
                amount: data.amount,
            })));      
            } catch (error) {
                console.log(error)
            }
        };
        fetchTicketBasicChargeData();
        }, []);


    const handleBasicChargeChange = (selectedOption) => {
    const ib_basic_charge_title = selectedOption ? selectedOption.title : null;
    const ib_basic_charge_amount = selectedOption ? selectedOption.amount : null;
    
    setSelectedBasicChage(selectedOption);
    setTicketFormData((prevFormData) => ({
        ...prevFormData,
        ib_basic_charge_title : ib_basic_charge_title,
        ib_basic_charge_amount : ib_basic_charge_amount,
    }));
    };



    const handleIbRequestedTypeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setTicketFormData(prevState => ({ ...prevState, requested_type: selectedOption.value }));
        } else {
          setTicketFormData(prevState => ({ ...prevState, requested_type: '' }));
        }
      };


    const handleIbProductCategoryChange = (selectedOption) => {
        const ibProductCateID = selectedOption ? selectedOption.value : null;

        setSelectedIbProductCategory(selectedOption);
        setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ib_product_category  : ibProductCateID,
        }));
    };

    const handleIbProductBrandChange = (selectedOption) => {
        const ibProductBrandId = selectedOption ? selectedOption.value : null;
        setSelectedIbProductBrand(selectedOption);
        setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ib_product_brand: ibProductBrandId,
        }));
    };

    const handleIbInvoiceFromDateChange = (date) => {
        setTicketFormData({
          ...ticketFormData,
          ib_invoice_date: date,
        });
      };


      const handleIbShowroomChange = (selectedOption) => {
        const showroomId = selectedOption ? selectedOption.value : null;
        setSelectedIbShowroom(selectedOption);
        setTicketFormData((prevFormData) => ({
          ...prevFormData,
          ib_showroom: showroomId,
        }));
      };


      const {
        setInputInvoiceValueForIJCR,
        handleInvoiceGetForIJCR,
        handleInvoiceResetForIJCR,
        selectedShowroomForIJCR,
        handleShowroomChangeForIJCR,
        showroomForIJCROption,
        invoiceNoGetForIJCR,
        selectedIbProductCategoryForIJCR,
        handleIBProductCategoryChangeForIJCR,
        handleProductBrandChangeForIJCR,
        ibProductCategoryOptionForIJCR,
        selectedModelNoForIJCR,
        handlemodelNoChangeForIJCR,
        modelNoForIJCROption,
        selectedIbProductBrandForIJCR,
        ibProductBrandOptionForIJCR,
      } = InvoiceWiseIJCrDataGet({setTicketFormData,setInvCustomerPhoneNo})

    console.log('autoIJCR',autoIJCR)

  return (
    <div>
        <FormPageTitle title='IJCR'   />

        {/* invoice wise data get for IJCR start */}
        <form  className='my-4'>
        <div className="flex border">
            <div  className='mb-3 mt-7 mx-2'>
                <InputFiled type='text'
                className={`w-[280px]`} 
                label='Seacrh Invoice' isRequired={true}
                onChange={(e) => setInputInvoiceValueForIJCR(e.target.value)}
                placeholder='Enter search invoice'/>
            </div>
            <div className='mb-3 mt-7 mx-2'>
            <button onClick={handleInvoiceGetForIJCR} className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-2'>
                Get Data
            </button>

            <button onClick={handleInvoiceResetForIJCR} className='bg-[#ddd906] text-[#000] rounded-[5px] px-5 py-2 ml-2'>
                Reset
            </button>
            </div>
        </div>
        </form>
        {/* invoice wise data get for IJCR end */}

        <div className="grid grid-cols-2 lg:grid-cols-3">

        <div  className='my-4 md:mx-auto'>
            <InputFiled type='text'
            className={`w-[99%]`} 
            name='ib_invoice_no' id='ib_invoice_no' 
            label='Invoice *' 
            isRequired={autoIJCR === true? true: false}
            value={ticketFormData.ib_invoice_no}
            onChange={handleTicketInputChange}
            placeholder='Enter invoice number'/>
        </div>

        <div className='my-4 md:mx-auto'>
            <DateInput value={ticketFormData.ib_invoice_date} 
            onChange={handleIbInvoiceFromDateChange} 
            dateFormat='yyyy-MM-dd' id='ib_invoice_date'
            label='Invoice Date *' 
            isRequired={autoIJCR === true? true: false}
            placeholder='Select invoice date'
            className={`w-[99%]`}  
            />
        </div>

        {invoiceNoGetForIJCR?
        <div  className='my-4 md:mx-auto'>
        <SelectInput
            name='ib_showroom'
            id='ib_showroom'
            className={`w-[99%]`}
            label='Showroom/Sale Point'
            value={selectedShowroomForIJCR}
            onChange={handleShowroomChangeForIJCR}
            options={showroomForIJCROption}
            isRequired={false}
        />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
        <SelectInput
            name='ib_showroom'
            id='ib_showroom'
            className={`w-[99%]`}
            label='Showroom/Sale Point'
            value={selectedIbShowroom}
            onChange={handleIbShowroomChange}
            options={ibShowroomOption}
            isRequired={false}
        />
        </div>
        }

        <div  className='my-4 md:mx-auto'>
        <InputFiled type='text'  
        className={`w-[99%]`}
        name='ib_dealer_name' id='ib_dealer_name' 
        value={ticketFormData.ib_dealer_name}
        onChange={handleTicketInputChange}
        label='Dealer Name' isRequired={false} 
        placeholder='Enter dealer name'
        />
        </div>

        {invoiceNoGetForIJCR?
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            name='ib_product_brand'
            id='ib_product_brand'
            label='Product Brand*'
            className={`w-[99%]`}
            value={selectedIbProductBrandForIJCR}
            onChange={handleProductBrandChangeForIJCR}
            options={ibProductBrandOptionForIJCR}
            isRequired={autoIJCR === true? true: false}
            />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            name='ib_product_brand'
            id='ib_product_brand'
            label='Product Brand*'
            className={`w-[99%]`}
            value={selectedIbProductBrand}
            onChange={handleIbProductBrandChange}
            options={ibProductBrandOptions}
            isRequired={autoIJCR === true? true: false}
            />
        </div>
        }

        {invoiceNoGetForIJCR?
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            className={`w-[99%]`}
            name='ib_product_category'
            id='ib_product_category'
            label='Product Category*'
            value={selectedIbProductCategoryForIJCR}
            onChange={handleIBProductCategoryChangeForIJCR}
            options={ibProductCategoryOptionForIJCR}
            isRequired={autoIJCR === true? true: false}
            placeholder={`Select Category`}
            />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
            <SelectInput
            className={`w-[99%]`}
            name='ib_product_category'
            id='ib_product_category'
            label='Product Category*'
            value={selectedIbProductCategory}
            onChange={handleIbProductCategoryChange}
            options={ibProductCategoryOption}
            isRequired={autoIJCR === true? true: false}
            placeholder={`Select Category`}
            />
        </div>
        }

        {invoiceNoGetForIJCR?
        <div  className='my-4 md:mx-auto'>
        <SelectInput
            className={`w-[99%]`}
            name='model_no'
            id='model_no'
            label='Model Number *'
            value={selectedModelNoForIJCR}
            onChange={handlemodelNoChangeForIJCR}
            options={modelNoForIJCROption}
            isRequired={autoIJCR === true? true: false}
            placeholder={`Select Model`}
        />
        </div>
        :
        <div  className='my-4 md:mx-auto'>
        <InputFiled type='text' 
            className={`w-[99%]`}
            name='model_no' id='model_no' 
            label='Model Number *' 
            isRequired={autoIJCR === true? true: false}
            value={ticketFormData.model_no}
            onChange={handleTicketInputChange}
            placeholder='Enter model number'/>
        </div>
        }

        
        <div  className='my-4 md:mx-auto'>
            <InputFiled 
            type='number'
            min='1'
            max='1000000000'
            className={`w-[99%]`}
            name='quantity' id='quantity' 
            label='Quantity *' 
            isRequired={autoIJCR === true? true: false}
            value={ticketFormData.quantity}
            onChange={handleTicketInputChange}
            placeholder='Enter quantity'/>
        </div>

        <div  className='my-4 md:mx-auto'>
            <TextareaInput type='text'  
            className={`w-[99%] h-[41.535px]`}
            name='other_info' id='other_info'
            label='Other Info' isRequired={false}
            value={ticketFormData.other_info}
            onChange={handleTicketInputChange}
            placeholder='Enter other info '/>
        </div>

        <div  className='my-4 md:mx-auto'>
            <SelectInput
                name='requested_type'
                id='requested_type'
                label='Requested Type *'
                placeholder='requested_type..'
                className={`w-full`}
                value={IbrequestedTypeOption.find((option)=> option?.value === ticketFormData.requested_type)}
                onChange={handleIbRequestedTypeChange}
                options={IbrequestedTypeOption}
                isRequired={autoIJCR === true? true: false}
            />
        </div>
        <div  className='my-4 md:mx-auto'>
            <SelectInput
                name='ib_basic_charge'
                id='ib_basic_charge'
                label='Basic Charge *'
                placeholder='basic charge..'
                className={`w-full`}
                value={selectedBasicChage}
                onChange={handleBasicChargeChange}
                options={basicChargeOption}
                isRequired={autoIJCR === true? true: false}
            />
        </div>
        </div>

    </div>
  )
}

export default IJCR