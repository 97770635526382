import React from 'react'
import GeneralSRCreateHandleCom from './GeneralSRCreateHandleCom'
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC'
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC'
import { AiFillDelete } from 'react-icons/ai'
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC'

const GeneralSRCreateFormCom = () => {
    const {
        navigate,
        requisionRequiredMessage,
        requisionItemCategoriesOptions,
        selectedRequisionItemCategories,
        requisionItemOptions,
        selectedRequisionItem,
        itemSearchInputValue,
        requisionFormData,
        handleRequisionItemCategoryChange,
        handleRequisionItemChange,
        handleRequisionInputChange,
        handleItemSearchInputChange,
        handleAddRequisionItem,
        handleRemoveRequisionItem,
        handleRequisionSubmit,
    } = GeneralSRCreateHandleCom()


    return (
        <div className="shadow-lg pt-1 px-2 my-5 pb-10 bg-[#fff]">
            <div className='information-head my-auto px-2 py-4 mb-2'>
                <div>General SR</div>
            </div>

            <form onSubmit={handleRequisionSubmit}>
                {requisionFormData.generalsritem_set?.map((requisionItems, index) => (

                <div className='add-more-item-form border border-[#F35B5B]'>
                <div className="item-add-form-head border-b bg-[#dbdbdb] flex justify-between py-2">
                <div className='mx-5 my-auto'>
                    <h1 className=''>Item{index + 1}</h1>
                </div>
                <div className='my-auto mx-5'>
                    {requisionFormData?.generalsritem_set?.length > 1 && (
                        <button
                        type="button"
                        onClick={() => handleRemoveRequisionItem(index)}
                        className="text-red-500"
                        >
                        <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600  border border-[#F35B5B]" />
                        </button>
                    )}
                </div>
                </div>

                    <div className='grid grid-cols-2 lg:grid-cols-3 my-2 mx-2 gap-y-2 gap-x-2 border px-1'>

                        <div className="mt-7 mb-2">
                            <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item Category*'
                                id={`item_category_${index}`}
                                name={`generalsritem_set[${index}]?.item_category`}
                                value={selectedRequisionItemCategories[index]}
                                onChange={(selectedOption) => handleRequisionItemCategoryChange(selectedOption, index)}
                                options={requisionItemCategoriesOptions}
                                placeholder='Item category'
                                isRequired={true}
                                isClearable={true}
                            />
                        </div>

                        <div className="mt-7 mb-2">
                            <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item*'
                                id={`item_${index}`}
                                name={`generalsritem_set[${index}]?.item`}
                                value={selectedRequisionItem[index]}
                                onInputChange={handleItemSearchInputChange}
                                onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                                options={requisionItemOptions.filter(
                                    (option) =>
                                        !selectedRequisionItem.some(
                                            (selectedItem) => selectedItem && selectedItem.value === option.value
                                        )
                                )}
                                placeholder='Select item'
                                isRequired={true}
                                isClearable={true}
                            />


                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC
                                type='text'
                                label='UOM*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='uom_value'
                                id={`uom_value_${index}`}
                                value={requisionItems.uom_value}
                                // onChange={(e) => handleRequisionInputChange(e, index, 'generalsritem_set')}
                                readOnly={true}
                                placeholder='uom'
                            />
                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC
                                type='number'
                                label='Quantity*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='quantity'
                                id={`quantity_${index}`}
                                value={requisionItems.quantity}
                                onChange={(e) => handleRequisionInputChange(e, index, 'generalsritem_set')}
                                isRequired={true}
                                placeholder='Enter quantity'
                                min={0}
                            />
                        </div>
                        <div className='mt-7 mb-2'>
                        <TextareaInputWC
                            className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[201%] focus:outline-none focus:bg-white focus:border-[#F35B5B]' 
                            label='Remarks' 
                            name='remarks' 
                            id={`remarks_${index}`}
                            placeholder={`Enter remarks`} 
                            value={requisionItems.remarks}
                            onChange={(e) => handleRequisionInputChange(e, index, 'generalsritem_set')}
                        />
                        </div>
                    </div>
                </div>
                ))}

                {requisionRequiredMessage && (
                    <span className='text-red-500 mx-4'>{requisionRequiredMessage} <br /> </span>
                )}
                <div className="flex justify-between mx-4 my-2">

                    <button
                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                        type="button"
                        onClick={() => {
                            console.log('Add new item Button Clicked');
                            handleAddRequisionItem();
                        }}
                    >ADD MORE</button>
                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                </div>
            </form>

        </div>
    )
}

export default GeneralSRCreateFormCom