
import React from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const BillingInvoiceApprovedStatusChange = (
  {
    id,
    ActionURL,
    redirectURL,

  }
) => {
  const navigate = useNavigate()

  const handleApprovalStatus = async (id) => {
    const result = await Swal.fire({
      title: 'Confirm Approved',
      text: 'Are you sure you want to Approved?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Approved It!',
    });
  
    if (result.isConfirmed) {
      try {
        const response = await authAxiosWithBearer.put(`${baseURL}/${ActionURL}/${id}/`);  
        if (response.status === 200) {
          Swal.fire('Confirm Approved!', `Successfully Approved`, 'success');
          navigate(`/${redirectURL}`)
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
        if(error.response.data.message){
          Swal.fire('Error', `${error.response.data.message}`, 'error');

        }else{
          Swal.fire('Error', 'something error', 'error');
        }
      }
    }
  };

  return (
    <div>
        <div className='mx-4 flex justify-end'>
            <button type='button' className='flex text-[#ffffff] bg-[#ff4747] hover:bg-[#db3232] rounded-[5px]  px-4 py-1 shadow' onClick={() => handleApprovalStatus(id)}>
                Approved
            </button>
        </div>

    </div>
  )
}

export default BillingInvoiceApprovedStatusChange