import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent'
import AssignedTransportDetailsComponent from '../InHouseComponents/AssignedTransportDetailsComponent'
import AssignedTransportProductReceivedStatusChange from '../InHouseComponents/AssignedTransportProductReceivedStatusChange'
import TechnicianList from '../InHouseComponents/TechnicianList'
import useFetchDetailsData from '../../../hooks/useFetchDetailsData'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import TechnicianListForCompletedDeliveryResource from './AssignedTransportForDeliveryComponents/TechnicianListForCompletedDeliveryResource'
import VendorListForCompletedDeliveryResource from './AssignedTransportForDeliveryComponents/VendorListForCompletedDeliveryResource'
import { useSelector } from 'react-redux'

const AssignedTransportForDeliveryDetails = () => {
    const {id} = useParams()
    const [ticketData, setTicketData] = useState(null);
    const user = useSelector(state => state.auth.user)


    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData(`/in_house/api/transport-assigned-details-for-delivery`, {id});
  


    
    // fetchAssignedTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${detailsData?.assigned_technician}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [detailsData]);
    


    if(loading){
        return <div><LoadingSpinner /> </div>
    }

  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Assigned Transport Details</h1>
            </div>
        </div>


            {/* assigned transport details start  */}
                <AssignedTransportDetailsComponent  data={detailsData} />
            {/* assigned transport details end  */}
        
            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}


            {user?.menu_permissions?.includes("8.1.1") &&(
                <>
                    {/* technician list start  */}
                        <TechnicianListForCompletedDeliveryResource detailsData={detailsData} fetchDeatilsData={fetchDetailsData} />
                    {/* technician list end  */}

                    {/* vendor list start  */}
                        <VendorListForCompletedDeliveryResource detailsData={detailsData} fetchDeatilsData={fetchDetailsData} />
                    {/* vendor list end  */}
                </>
            )}
    </div>
  )
}

export default AssignedTransportForDeliveryDetails