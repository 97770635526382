import React, { useState } from 'react'
import ATWorkingActivitiesLogCreateModal from './ATWorkingActivitiesLogCreateModal';
import { FaTasks } from 'react-icons/fa';

const ATWorkingActivitiesLogCreateModalButton = ({
  id,
}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedID, setSelectedID] = useState(null);

    const openModal = (id) => {
      setModalOpen(true);
      setSelectedID(id)
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setSelectedID(null)
    };



  return (
    <div>
        <div className='flex justify-center '>
              <button
                  onClick={() => openModal(id)}
                  className='bg-[#e4cd01] uppercase rounded-[4px] px-[5px] py-1 text-[#000] mx-4'
                  >
                  <FaTasks />
              </button>
        </div>

          <ATWorkingActivitiesLogCreateModal
                isOpen={isModalOpen}
                onClose={closeModal}
                assigned_technician_id={id}
                setModalOpen={setModalOpen}
            />
    </div>
  )
}

export default ATWorkingActivitiesLogCreateModalButton