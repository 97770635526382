import React from 'react'
import RequestedTicketListComponent from './ShowroomTicketRequestComponent/RequestedTicketListComponent'

const AllShowroomCreatedRequestedTicketList = () => {
  return (
    <div>
        <RequestedTicketListComponent 
            apiEndpoint={'ccd/api/all-showroom-created-requested-ticket-list/'}
            pageTitle={'Completed Requests List'}
            editUrl={``}
        />
    </div>
  )
}

export default AllShowroomCreatedRequestedTicketList