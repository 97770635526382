import React from 'react'

const SRIssuedReturnItemComponent = ({itemDatas,title}) => {

  return (
    <div>

        <div className='py-1 text-[15px] font-semibold text-[#000000]'>{title}</div>
        
        <table className='table-auto border-1 border-[#ED6C6C]  max-w-[100%] text-[9px] sm:text-[12px]'>

            <thead>

                <tr className='bg-[#EEEEEE]'>

                    <th className='border border-[#ED6C6C] w-[400px] py-3'>SN</th>
                    <th className='border border-[#ED6C6C] w-[400px] py-3'>Specification</th>
                    <th className='border border-[#ED6C6C] w-[400px] py-3'> UOM  </th>
                    <th className='border border-[#ED6C6C] w-[400px] py-3'>Return Quantity </th>
                    <th className='border border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

                </tr>

            </thead>
            <tbody className='text-center'>

            {itemDatas?.map((item,index)=>(
                <tr >
                    <td className='border border-[#ED6C6C] w-[400px] py-3'>{++index}</td>
                    <td className='border border-[#ED6C6C] w-[400px] py-3'>
                        name : {item?.item_name} <br />
                        size : {item?.item_size} <br />
                        specification :{item?.item_specification} <br />
                        brand : {item?.item_brand}
                    </td>
                    <td className='border border-[#ED6C6C] w-[400px] py-3'>{item?.uom_text}</td>
                    <td className='border border-[#ED6C6C] w-[400px] py-3'>{item?.issue_return_qty}</td>
                    <td className='border border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                </tr>
            ))}
            </tbody>

        </table>

    </div>
  )
}

export default SRIssuedReturnItemComponent