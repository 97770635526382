import React, { useState } from 'react'
import VendorInvoiceCreateModal from './VendorInvoiceCreateModal';

const VendorInvoiceCreateModalButton = ({
  assignedID
}) => {
    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [selectedAssignedID, setSelectedAssignedID] = useState(null);

    const openInvoiceModal = (assignedID) => {
      setInvoiceModalOpen(true);
      setSelectedAssignedID(assignedID)
    };
  
    const closeInvoiceModal = () => {
      setInvoiceModalOpen(false);
      setSelectedAssignedID(null)
    };



  return (
    <div>
        <div className='flex justify-center '>
              <button
                  onClick={() => openInvoiceModal(assignedID)}
                  className='bg-[#FA6669] uppercase rounded-[4px] px-[5px] py-2 text-[#fff] mx-4'
                  >
                  Create Vendor Invoice
              </button>
        </div>

          <VendorInvoiceCreateModal
                isOpen={isInvoiceModalOpen}
                onClose={closeInvoiceModal}
                assignedID={assignedID}
            />
    </div>
  )
}

export default VendorInvoiceCreateModalButton