import React, { useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import HOInvoiceCreateFormComponent from './HOInvoiceCreateFormComponent';

function HOInvoiceCreateModal(
  { 
    isOpen, 
    onClose,
    modalPageTitle,
    scbccWpTicketNo,
    installationTicketNo,
    detailsData,
    srIssuedData 
}) 
  {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Purchased Details Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className='mb-2 w-[360px] md:w-[450px] lg:w-[450px] overflow-y-auto h-[430px]'>
        <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
          <h1 className='font-semibold'>{modalPageTitle}</h1>
          <button className='text-[#000]' onClick={onClose}><ImCancelCircle /></button>
        </div>

          <div>
              <HOInvoiceCreateFormComponent 
                scbccWpTicketNo={scbccWpTicketNo}
                installationTicketNo={installationTicketNo}
                detailsData={detailsData}
                srIssuedData={srIssuedData}
              />
          </div>
      </div>
    </Modal>
  );
}

export default HOInvoiceCreateModal;