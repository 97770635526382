import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import WorkorderFormCom from './WoComponents/WorkorderFormCom';


function WorkorderUpdate() {
    
    const {id} = useParams()

  return (
    <WorkorderFormCom title={'Workworder Update'} id={id} />
  )
}

export default WorkorderUpdate