import React from 'react'
import ATListComponentForCompletedDeliveryResource from '../../InHouse/AssignedTechnicianForCompletedDeliveryResource/ATForCompletedDeliveryResourceComponents/ATListComponentForCompletedDeliveryResource'

const TechnicianWiseATListForCompletedDeliveryResource = () => {
  return (
    <div>
        <ATListComponentForCompletedDeliveryResource 
        title={'Assigned List (Completed Delivery Resource)'}
        apiEndPoint={'/in_house/api/technician-wise-assigned-technician-for-completed-delivery-resource/'} 
        detailsURL={'technician-wise/completed-delivery-resource/assignd-technician/details'}
        detailsURLPermission={'4.1.2'}
        />
    </div>
  )
}

export default TechnicianWiseATListForCompletedDeliveryResource