import React from 'react'
import DamagePartsListCom from './DamagePartsComponents/DamagePartsListCom'

const UserWiseDamagePartsList = () => {
  return (
    <>
      <DamagePartsListCom 
        apiEndpoint={`/inventory/api/user-wise-damage-parts/`}
        detailsURL={`/damage-parts-details/`}
        detailsPermissionCode={"3.15.2"}
      />
    </>
  )
}

export default UserWiseDamagePartsList