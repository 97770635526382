import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from "date-fns";
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import PackageIssuedDetailsModal from '../../../Components/PackageIssuedDetailsModal';
import RequisionSubmitedDataList from '../../Technician/TechnicianComponent/RequisionSubmitedDataList';
import InvoiceDetailsModal from '../../../Components/InvoiceDetailsModal';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import VendorInvoiceCreateModalButton from '../BillingComponents/VendorInvoiceCreateForm/VendorInvoiceCreateModalButton';
import TechnicianAssignedStatusChangeForm from '../../Technician/TechnicianComponent/TechnicianAssignedStatusChangeForm';
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent';
import TechnicianWiseAssignedPackageListComponents from '../../Technician/TechnicianComponent/TechnicianWiseAssignedPackageListComponents';


function AssignedVendorDetailsForBilling() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [ticketData, setTicketData] = useState(null);
    const {id} = useParams()
    const navigate = useNavigate()
    const [ticketID, setTicketID] = useState(null)

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
    
        const formattedDate = format(date, 'dd-M-yyyy');
    
        const hours = date.getHours();
        const minutes = date.getMinutes();
    
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
    
        const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
    
        return `${formattedDate} | ${formattedTime}`;
      };
    


    // #status change 
    const [subStatusOption, setSubStatusOption] = useState(null);
    const [selectedSubStatus, setSelectedSubStatus] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [statusForm, setStatusForm] = useState({
        status_text: '',
        sub_status:null
    })

    const statusOption = [
        {value:'In Progress',label:'In Progress'},
        {value:'Pending',label:'Pending'},
    ]


    // fetchAssignedTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${id}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
            setTicketID(response.data.ticket_id)

            setStatusForm(prevState => ({
                ...prevState,
                status_text: response.data.status_text,
                sub_status: response.data.sub_status,
            }));

            setSelectedStatus({value: response.data.status_text})

            setSelectedSubStatus(response.data.sub_status &&({
                value: response.data.sub_status,
                label: response.data.sub_status_text,
                status:response.data.status_text
            }))

        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [id]);


    useEffect(() => {
        const fetchSubStatusData = async () => {
          try {
            const response = await authAxiosWithBearer.get(`${baseURL}/api/sub-status-list/`);
            setSubStatusOption(
              response?.data?.results?.map((data) => ({
                value: data.id,
                label: data.title,
                status: data.status_text,
              }))
            );
          } catch (error) {
            console.log(error);
          }
        };
        fetchSubStatusData();
      }, []);

      

    const handleStatusChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== '') {
        setStatusForm((prevState) => ({ ...prevState, status_text: selectedOption.value }));
        setSelectedStatus(selectedOption);
        setSelectedSubStatus(null);
    } else {
        setStatusForm((prevState) => ({ ...prevState, status_text: '' }));
        setSelectedStatus(null); 
        setSelectedSubStatus(null);
    }
    };


    const handleSubStatusChange = (selectedOption) => {
        const sub_status_id = selectedOption ? selectedOption.value : null;
        setSelectedSubStatus(selectedOption);
        setStatusForm((prevFormData) => ({
          ...prevFormData,
          sub_status: sub_status_id,
        }));
      };
    
      const filteredSubStatus = subStatusOption?.filter((subStatus) => subStatus?.status === selectedStatus?.value);
      




    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [selectedInvoiceID, setSelectedInvoiceID] = useState(null);
  
    const openInvoiceModal = (invoiceNo) => {
      setInvoiceModalOpen(true);
      setSelectedInvoiceID(invoiceNo);
    };
  
    const closeInvoiceModal = () => {
      setInvoiceModalOpen(false);
      setSelectedInvoiceID(null);
    };


    const [isPackageIssuedModalOpen, setPackageIssuedModalOpen] = useState(false);
    const [selectedPackageIssuedNo, setSelectedPackageIssuedNo] = useState(null);
  
    const openPackageIssuedModal = (packageIssuedNo) => {
        setPackageIssuedModalOpen(true);
        setSelectedPackageIssuedNo(packageIssuedNo);
    };
  
    const closePackageIssuedModal = () => {
        setPackageIssuedModalOpen(false);
        setSelectedPackageIssuedNo(null);
    };



    

    return (
        <div className='my-5 mx-2'>
            <div className="user-details-page-title mb-5 shadow flex justify-between">
                <div className="my-auto py-2">
                    <h1 className='uppercase'>Ticket Details</h1>
                    
                </div>
               
                <div className="my-auto uppercase bg-[#FA6669] rounded-[4px] text-[#fff]">
                    {ticketData?.vendor_bill_invoice?
                        <div className='p-2'>Invoice No : {ticketData?.vendor_bill_invoice}</div>
                        :
                        <VendorInvoiceCreateModalButton assignedID={id} />
                    }
                </div>
            </div>

            {/* status change form start */}
            <TechnicianAssignedStatusChangeForm id={id} ticketData={ticketData} />
            {/* status chnage form end  */}


            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}


            {/* assigned package start */}

            <TechnicianWiseAssignedPackageListComponents ticketData={ticketData} />
            {/* assigned package end */}


            {!ticketData?.hvac && (
             <div>

                <RequisionSubmitedDataList assigned_id={id} />

            </div>
            )}



            

            {/* invoiceDetails start */}
            <InvoiceDetailsModal
                isOpen={isInvoiceModalOpen}
                onClose={closeInvoiceModal}
                invoiceNo={selectedInvoiceID}
            />
            {/* invoiceDetails end */}

        </div>
    )
}

export default AssignedVendorDetailsForBilling