import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { formatDateTime } from '../../../../Components/formatDateTime';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import useFetchListData from '../../../../hooks/useFetchListData';
import CustomPaginator from '../../../../Components/CustomPaginator';
import ListPageHeadWithDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithDateRange';
import { useNavigate } from 'react-router-dom';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';


const SRIssuedReturnListCom = (
{ user,
  apiEndpoint,
  detailsURL,
  detailsPermissionCode,
  updateURL,
  updatePermissionCode
}) => {

  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('');
  const [dataStatus, setDataStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndpoint}`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, dataStatus, endDate, startDate]);



  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    sr_issued_return_no:data?.sr_issued_return_no,
    created_by: data?.created_by_employee_id,
    return_by: data?.return_by,
    source_type: data?.source_type,
    created_at: data?.created_at,
    generalsrissueitem_set: data.generalsrissueitem_set
  }))



  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'sr_issued_return_no',
      dataIndex: 'sr_issued_return_no',
      key: '1',
    },

    {
      title: 'CREATED BY',
      dataIndex: 'created_by',
      key: '4',
      responsive: ['md']
    },

      {
        title: 'CREATED AT',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => {
          const formattedDateTime = created_at && formatDateTime(created_at);
          return <span>{formattedDateTime}</span>;
        },
      },

      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className='flex gap-2'>
            {user?.menu_permissions?.includes(`${detailsPermissionCode}`) && (
                <button onClick={() => navigate(`${detailsURL}${record.id}`)} className='rounded'>
                <AiFillEye className='text-[#2929ff] text-xl' />
              </button>
            )}

            {user?.menu_permissions?.includes(`${updatePermissionCode}`) && (
                <button onClick={() => navigate(`${updateURL}${record.id}`)} className='rounded'>
                <AiFillEdit className='text-[#6F6F7E] text-xl' />
              </button>
            )}
          </div>
        ),
      },
  ];




  return (
    <>
    <div className='container-fluid grid grid-cols-1 mt-2'>
        <ListPageHeadWithDateRange 
                pageTitle = {'Issued Return List'}
                setEndDate = {setEndDate}
                endDate = {endDate}
                setStartDate = {setStartDate}
                startDate = {startDate}
                setSearchText = {setSearchText}
                // addNewURL = {}
                // addNewUrlPermissionCode = {}
          />

      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
        <>
        <div className='shadow-lg overflow-x-auto'>
          <Table 
              columns={columns}
              dataSource={dataSource} 
              pagination={false}
          />
          <div className='my-5'>
              <CustomPaginator 
                  setCurrentPage={setCurrentPage} 
                  currentPage={currentPage}
                  totalItems={totalItems}
              />
          </div>
        </div>
       </>
      )} 
    </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SRIssuedReturnListCom);