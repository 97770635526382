import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import HOBillingDetailsComponent from '../BillingComponents/HOBilling/HOBillingDetailsComponent';
import HOBillingDetailsPrintViewComponent from '../BillingComponents/HOBilling/HOBillingDetailsPrintViewComponent';
import LoadingSpinner from '../../../Components/LoadingSpinner';


const HoBillingDetails = () => {
  const {id} = useParams()
  const user = useSelector(state => state.auth.user);

  const [detailsData, setDetailsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serviceData, setServiceData] = useState(false);
  const [installationData, setInstallationData] = useState(false);

  useEffect(() => {
    const fetchDetailsData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/ho-billing-details/${id}/`);
        const data = response.data;
        setDetailsData(data);

        // Check for service and installation within billing_invoice
        if (data.billing_invoice && Array.isArray(data.billing_invoice)) {
          const serviceItems = data.billing_invoice.filter(invoice => invoice.service !== null);
          const installationItems = data.billing_invoice.filter(invoice => invoice.installation !== null);

          setServiceData(serviceItems);
          setInstallationData(installationItems);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (id && error.response) {
          if (error.response.status === 404) {
            ShowToast('error', 'Data not found');
          } else {
            ShowToast('error', 'An error occurred while fetching data');
          }
        }
      }
    };

    fetchDetailsData();
  }, [id]);


  const componentPDF = useRef();
  const pdfGeneretor = useReactToPrint({
      content: () => componentPDF.current,
      documentTitle: "Esquire-CRM",
      pageStyle: `
      @page landscape {
          size: A4 landscape;
          margin: 20mm 10mm;
      }
      @page portrait {
          size: A4 portrait;
          margin: 20mm 10mm;
      }
      @page {
          margin: 4mm;
      }
      body {
          margin: 0;
      }
      `,
      contentStyle: `
      @page landscape {
          transform: rotate(0deg) translate(0, 0);
      }
      @page portrait {
          transform: rotate(90deg) translate(0, 0);
      }
      `
  });


  if (loading) {
    return <div><LoadingSpinner /></div>;
  }

  console.log('serviceData',serviceData)


  return (
    <div>
      <div className='border border-[#EB5757] sm:mx-3 my-3' >
          <HOBillingDetailsComponent 
          detailsData={detailsData} id={id}
          serviceData ={serviceData}
          installationData ={installationData}
          />
          <div className='flex justify-center my-3 '>
              <button 
              className='bg-[#FA6669] text-[#FFF] px-6 py-2 rounded-md' 
                onClick={pdfGeneretor}
              >Print</button>
          </div>
      </div>

      <HOBillingDetailsPrintViewComponent 
        detailsData={detailsData} componentPDF={componentPDF}
        serviceData ={serviceData}
        installationData ={installationData}
      />


    </div>
  )
}

export default HoBillingDetails