import React, { useEffect, useState } from 'react'
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import { AiFillDelete } from 'react-icons/ai';
import TechnicianTaskReportFormHandleCom from './TechnicianTaskReportFormHandleCom';
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC';

const TechnicianTaskReportFormCom = ({assigned_technician_id,onDataPost}) => {
    const {
        loading,
        setLoading,
        navigate,
        requiredMessage,
        setRequiredMessage,
        formData,
        setFormData,
        handleInputChange,
        handleAddItem,
        handleRemoveItem,
        handleSubmit,
      } = TechnicianTaskReportFormHandleCom({assigned_technician_id,onDataPost})

  return (
    <div>
        <div className="shadow-lg pt-1 px-2 my-5 pb-10 bg-[#fff]">
            <div className='information-head flex justify-between  px-5 mb-5 py-2'>
                <div className='uppercase text-[20px]'>Task Report</div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="grid">
                    <div className='mt-7 mb-2'>
                        <TextareaInputWC 
                            type='text'
                            label='Report Descriptions*'
                            className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            name='report_descriptions'
                            id={`report_descriptions`}
                            value={formData.report_descriptions}
                            onChange={handleInputChange}
                            placeholder='report_descriptions'
                            isRequired={true}
                        />
                    </div>
                    <div className='mt-7 mb-2'>
                        <InputFiledWC 
                            type='text'
                            label='Product Serial No*'
                            className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            name='product_serial_no'
                            id={`product_serial_no`}
                            value={formData.product_serial_no}
                            onChange={handleInputChange}
                            placeholder='product_serial_no'
                            isRequired={true}
                        />
                    </div>
                </div>

                {formData.techniciantaskreportimage_set?.map((items, index) => (
                <div className='grid grid-cols-2 lg:grid-cols-4 my-2 gap-y-2 gap-x-2 border px-1'>
                    <div className='mt-7 mb-2'>
                        <InputFiledWC 
                            type='text'
                            label='Image Title'
                            className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            name='image_title'
                            id={`image_title_${index}`}
                            value={items.image_title}
                            onChange={(e) => handleInputChange(e, index, 'techniciantaskreportimage_set')}
                            placeholder='Enter title'
                        />
                    </div>
                    <div className='mt-7 mb-2'>
                        <InputFiledWC 
                            type='file'
                            label='Image'
                            className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                            name='image'
                            id={`image_${index}`}
                            onChange={(e) => handleInputChange(e, index, 'techniciantaskreportimage_set')}
                            placeholder='Enter quantity'
                        />
                    </div>
                    {index !== 0 && (
                    <div className='mt-9 mx-10 mb-2'>
                        
                            <button
                            type="button"
                            onClick={() => handleRemoveItem(index)}
                            className="text-red-500"
                            >
                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                            </button>
                    </div>
                    )}
                </div>
                ))}

                {requiredMessage && (
                    <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span> 
                )}
                <div className="flex justify-between mx-4 my-2">

                    <button 
                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                        type="button"
                        onClick={() => {
                            console.log('Add new item Button Clicked');
                            handleAddItem();
                        }}
                        >ADD MORE</button>
                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                </div>
            </form>

        </div>
    </div>
  )
}

export default TechnicianTaskReportFormCom