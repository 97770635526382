import React from 'react'
import Logo from '../assest/Images/logo.png';
import '../assest/CSS/TicketViewWithPrint.css'
import { useReactToPrint } from "react-to-print";
import { useRef } from 'react';
import { AiFillPrinter } from 'react-icons/ai';

function TicketViewWithPrint({ticketData}) {
    const componentPDF = useRef();

    const pdfGeneretor = useReactToPrint({
      content: () => componentPDF.current,
      documentTitle: "Esquire-CRM",
      pageStyle: `
        @page landscape {
          size: A4 landscape;
          margin: 20mm 10mm;
        }
        @page portrait {
          size: A4 portrait;
          margin: 20mm 10mm;
        }
        @page {
          margin: 4mm;
        }
        body {
          margin: 0;
        }
      `,
      contentStyle: `
        @page landscape {
          transform: rotate(0deg) translate(0, 0);
        }
        @page portrait {
          transform: rotate(90deg) translate(0, 0);
        }
      `
    });
  
  return (
    <div className="ticket-view">
           <button
              onClick={pdfGeneretor}
              className="bg-blue-600 hover:bg-bule-700 text-white font-bold py-1 px-2 rounded"
            >
              <AiFillPrinter className='text-[20px]' />
            </button>

        <div>
            <div className="ticket-view-head">
                <div className="flex justify-center">
                    <img className='w-[50px] md:w-[60px] mr-2' src={Logo} alt="" />
                    <div className='title  text-[15px] md:text-[25px] my-auto'>Esquire Customer Care Ltd</div>
                </div>

                <div className="my-1 flex justify-center">
                    <p className='address text-[10px] md:text-[15px] ml-[50px]'>
                    260/A, Tejgaon I/A Nabisco, Tejgaon, Dhaka-1208
                    <br />
                    Tel- 09610001010, E-mail-info_ccd@esquirebd.com
                    </p>
                </div>
                <div className='flex justify-center my-3'>
                    <p className='issuePreview text-[10px] md:text-[15px] p-2'>Issue Preview</p>
                </div>

                <div className="information-head grid md:grid-cols-2 lg:grid-cols-3 justify-between px-4 py-1">
                    <div>Ticket ID - {ticketData?.ticket_no}</div>
                    <div>Customer Name - {ticketData?.customer[0]?.name}</div>
                    <div>Phone No- {ticketData?.customer[0]?.phone_no}</div>
                </div>

            </div>

            <div className="information-section my-5 p-1 mx-2">
                <div className='information-head my-1 px-2 md:px-5 py-1'>
                    <div>Customer Information</div>
                </div>
                <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[130px] gap-y-2">

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Customer Name
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Phone
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.phone_no}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Email
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.email}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Gender
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.gender_text}
                        </div> 
                    </div>

                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Profession
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.profession}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Ref Customer
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.ref_customer_name}
                        </div> 
                    </div>

                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          District Name
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.district_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Upazila Name
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.upazila_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Address
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.address}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Post Code
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.customer[0]?.post_code}
                        </div> 
                    </div>
                    
                </div>
            </div>

            {ticketData?.hvac && ticketData.hvac.length > 0 &&(
            <div className="information-section my-5 p-1 mx-2">
                <div className='information-head my-1 px-2 md:px-5 py-1'>
                    <div>Issue - HVAC</div>
                </div>

                <div className="flex justify-between mx-2 md:mx-5 issue-category my-4">
                  <div>Service Category- Inbound CRM </div>
                  <div>Ticket Category - HVAC </div>
                </div>

                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>HVAC Information</p>
                </div>

                <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-2">
                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Company Name
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.hvac[0]?.hvac_company_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Email
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.hvac[0]?.hvac_email}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Address
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.hvac[0]?.hvac_address}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Capacity
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.hvac[0]?.capacity}
                        </div> 
                    </div>
                    
                </div>

                {ticketData?.hvac[0]?.technician && ticketData?.hvac[0]?.technician.length > 0 &&(
                <>
                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>Assigned Technician Information</p>
                </div>
                {ticketData?.hvac[0]?.technician?.map((data, index)=>(
                        <div className="grid grid-cols-3 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-4 border-b">
            
                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                Technician Name :
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_name}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Phone :
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_phone}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Priority :
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.priority}
                                </div> 
                            </div>
                            
                        </div>
                ))}
                </>
                )}
            </div>
            )}

            {ticketData?.insatllation_bill && ticketData.insatllation_bill.length > 0 &&(
            <div className="information-section my-5 p-1 mx-2">
                <div className='information-head my-1 px-2 md:px-5 py-1'>
                    <div>Issue - IJCR</div>
                </div>

                <div className="flex justify-between mx-2 md:mx-5 issue-category my-4">
                  <div>Service Category- Inbound CRM </div>
                  <div>Ticket Category - IJCR </div>
                </div>

                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>IJCR Information</p>
                </div>

                <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-2">
                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Invoice No
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.insatllation_bill[0]?.ib_invoice_no}
                        </div> 
                    </div>
                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Invoice Date
                        </div>
                        <div className='w-[60%] text-end '>
                                {ticketData?.insatllation_bill[0]?.ib_invoice_date}
                        </div> 
                    </div>

                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Showroom
                        </div>
                        <div className='w-[60%] text-end '>
                                {ticketData?.insatllation_bill[0]?.ib_showroom_text}
                        </div> 
                    </div>

                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Dealer
                        </div>
                        <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_dealer_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Product Brand
                        </div>
                        <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_product_brand_text}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Model No
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.insatllation_bill[0]?.model_no}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Product Category
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.insatllation_bill[0]?.ib_product_category?.name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Quantity
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.insatllation_bill[0]?.quantity}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Other Info
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.insatllation_bill[0]?.other_info}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Requested Type
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.insatllation_bill[0]?.requested_type}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Basic Charge Title
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.ib_basic_charge_title}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Basic Charge Amount
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.ib_basic_charge_amount}
                        </div> 
                    </div>
                    
                </div>


                {ticketData?.insatllation_bill[0]?.technician && ticketData?.insatllation_bill[0]?.technician.length > 0 &&(
                <>
                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>Assigned Technician Information</p>
                </div>
                {ticketData?.insatllation_bill[0]?.technician?.map((data, index)=>(
                        <div className="grid md:grid-cols-3 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-2 border-b">
            
                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                 Name
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_name}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Phone
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_phone}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Priority
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.priority}
                                </div> 
                            </div>
                            
                        </div>
                ))}
                </>
                )}

            </div>
            )}

            {ticketData?.service_bill && ticketData.service_bill.length > 0 &&(
            <div className="information-section my-5 p-1 mx-2">
                <div className='information-head my-1 mx-2 md:px-5 py-1'>
                    <div>Issue - SCBCC</div>
                </div>

                <div className="flex justify-between mx-2 md:mx-5 issue-category my-4">
                  <div>Service Category- Inbound CRM </div>
                  <div>Ticket Category - SCBCC </div>
                </div>

                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>SCBCC Information</p>
                </div>

                <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-2">
                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Invoice No
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.sb_invoice_no}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Model Name
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.model_name}
                        </div> 
                    </div>


                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Product Category
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.sb_product_category?.name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Product Brand
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.sb_product_brand?.name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Showroom
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.showroom_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Dealer Name
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.dealer_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Purchase Date
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.purchase_date}
                        </div> 
                    </div>
                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Complaint Details
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.other_details}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Service Address
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.service_address}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Basic Charge Title
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.sb_basic_charge_title}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Basic Charge Amount
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.sb_basic_charge_amount}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Warranty Status
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.warranty_status}
                        </div> 
                    </div>
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Warranty Exp Date
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.service_bill[0]?.warranty_exp_date}
                        </div> 
                    </div>

                </div>


                {ticketData?.service_bill[0]?.technician && ticketData?.service_bill[0]?.technician.length > 0 &&(
                    <>
                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>Assigned Technician Information</p>
                </div>
                {ticketData?.service_bill[0]?.technician?.map((data, index)=>(
                        <div className="grid md:grid-cols-3 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-2 border-b">
            
                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                 Name
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_name}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Phone
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_phone}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Priority
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.priority}
                                </div> 
                            </div>
                            
                        </div>
                    ))}
                </>
                )}

            </div>
            )}

            <div className="information-section my-5 p-1 mx-2">
                <div className='information-head my-1 px-2 md:px-5 py-1'>
                    <div>Call Disposition</div>
                </div>


                <div className="mx-2 md:mx-5 my-5">
                    <p className='issue-section-title'>Call Disposition Information</p>
                </div>

                <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[100px] gap-y-2">
                    
                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Call Remarks
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.call_remarks_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Related Ticked
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.related_ticked}
                        </div> 
                    </div>


                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        SMS Sent
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.sms_sent === true?'Yes':'No'}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                          Remarks
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.remarks}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                        Sms Formate
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.sms_formate_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[40%]'>
                            Message
                        </div>
                        <div className='w-[60%] text-end'>
                            {ticketData?.sms_body}
                        </div> 
                    </div>

                </div>
            </div>

        </div>


        <div className="hidden">
            <div
                ref={componentPDF}
                style={{ width: "99%", height: "100%" }}
                className="flex flex-col mx-auto "
            >
                <div className="ticket-print-view-head">
                    <div className="flex justify-center">
                        <img className='w-[30px] mr-2' src={Logo} alt="" />
                        <div className='title text-[12px]  my-auto'>Esquire Customer Care ltd</div>
                    </div>
                    <div className="my-1 flex justify-center">
                        <p className='address text-[10px] ml-[50px]'>
                        260/A, Tejgaon I/A Nabisco, Tejgaon, Dhaka-1208
                        <br />
                        Tel- 09610001010, E-mail-info_ccd@esquirebd.com
                        </p>
                    </div>

                    <div className="print-information-head text-[10px] flex justify-between px-4 py-1 my-2">
                        <div>Ticket ID - {ticketData?.ticket_no}</div>
                        <div>Customer Name - {ticketData?.customer[0]?.name}</div>
                        <div>Phone No- {ticketData?.customer[0]?.phone_no}</div>
                    </div>

                </div>


                <div className="print-information-section p-1">
                    <div className='print-information-head text-[10px]  px-2 py-1'>
                        <div>Customer Information</div>
                    </div>
                    <div className="grid grid-cols-4 gap-x-16 mx-2 my-2  gap-y-1">

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                             Name
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Phone
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.phone_no}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Email
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.email}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Gender
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.gender_text}
                            </div> 
                        </div>
                  
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Profession
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.profession}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Ref Customer
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.ref_customer_name}
                            </div> 
                        </div>
                       
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            District Name
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.district_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Upazila Name
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.upazila_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Address
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.address}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Post Code
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.customer[0]?.post_code}
                            </div> 
                        </div>
                        
                    </div>
                </div>

                {ticketData?.hvac && ticketData.hvac.length > 0 &&(
                <div className="print-information-section mt-2 p-1">
                    <div className='print-information-head  px-2 py-1'>
                        <div>Issue - HVAC</div>
                    </div>

                    <div className="flex justify-between mx-2 print-issue-category my-1">
                    <div>Service Category- Inbound CRM </div>
                    <div>Ticket Category - HVAC </div>
                    </div>

                    <div className="mx-2 my-2">
                        <p className='print-issue-section-title'>HVAC Information</p>
                    </div>

                    <div className="grid grid-cols-4 mx-2 my-2 gap-x-16 gap-y-1">
                        
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Company Name
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.hvac[0]?.hvac_company_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Email
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.hvac[0]?.hvac_email}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Address
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.hvac[0]?.hvac_address}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Capacity
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.hvac[0]?.capacity}
                            </div> 
                        </div>
                        
                    </div>

                    {ticketData?.hvac[0]?.technician && ticketData?.hvac[0]?.technician.length > 0 &&(
                    <>
                    <div className="mx-10 my-5">
                        <p className='print-issue-section-title'>Assigned Technician Information</p>
                    </div>
                    {ticketData?.hvac[0]?.technician?.map((data, index)=>(
                        <div className="grid grid-cols-3 mx-10 my-4 gap-x-[100px] gap-y-4 border-b">
            
                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                Technician Name :
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_name}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Phone :
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.technician_phone}
                                </div> 
                            </div>

                            <div className='flex flex-wrap '>
                                <div className='w-[40%]'>
                                    Priority :
                                </div>
                                <div className='w-[60%] text-end'>
                                    {data?.priority}
                                </div> 
                            </div>
                            
                        </div>
                    ))}
                    </>
                    )}
                </div>
                )}

                {ticketData?.insatllation_bill && ticketData.insatllation_bill.length > 0 &&(
                <div className="print-information-section mt-2 p-1">
                    <div className='print-information-head px-2 py-1'>
                        <div>Issue - IJCR</div>
                    </div>

                    <div className="flex justify-between mx-2 print-issue-category my-1">
                    <div>Service Category- Inbound CRM </div>
                    <div>Ticket Category - IJCR </div>
                    </div>

                    <div className="mx-2 my-2">
                        <p className='print-issue-section-title'>IJCR Information</p>
                    </div>

                    <div className="grid grid-cols-3 mx-2 my-2 gap-x-16 gap-y-1">
                        
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Invoice No
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_invoice_no}
                            </div> 
                        </div>

                        <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Invoice Date
                        </div>
                        <div className='w-[60%] text-end '>
                                {ticketData?.insatllation_bill[0]?.ib_invoice_date}
                        </div> 
                    </div>

                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Showroom
                        </div>
                        <div className='w-[60%] text-end '>
                                {ticketData?.insatllation_bill[0]?.ib_showroom_text}
                        </div> 
                    </div>

                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Dealer
                        </div>
                        <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_dealer_name}
                        </div> 
                    </div>

                    <div className='flex flex-wrap my-3'>
                        <div className='w-[40%]'>
                        Product Brand
                        </div>
                        <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_product_brand_text}
                        </div> 
                    </div>


                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Model No
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.model_no}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Product Category
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_product_category?.name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Quantity
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.quantity}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Other Info
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.other_info}
                            </div> 
                        </div>
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Requested Type
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.requested_type}
                            </div> 
                        </div>
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Basic Charge title
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_basic_charge_title}
                            </div> 
                        </div>
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Basic Charge Amount
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.insatllation_bill[0]?.ib_basic_charge_amount}
                            </div> 
                        </div>
                        
                    </div>


                    {ticketData?.insatllation_bill[0]?.technician && ticketData?.insatllation_bill[0]?.technician.length > 0 &&(
                    <>
                    <div className="mx-2 my-3">
                        <p className='print-issue-section-title'>Assigned Technician Information</p>
                    </div>
                    {ticketData?.insatllation_bill[0]?.technician?.map((data, index)=>(
                            <div className="grid grid-cols-3 mx-2 my-2 gap-x-16 gap-y-1 border-b">
                
                                <div className='flex flex-wrap '>
                                    <div className='w-[40%]'>
                                    Technician Name :
                                    </div>
                                    <div className='w-[50%] text-end'>
                                        {data?.technician_name}
                                    </div> 
                                </div>

                                <div className='flex flex-wrap '>
                                    <div className='w-[40%]'>
                                        Phone :
                                    </div>
                                    <div className='w-[60%] text-end'>
                                        {data?.technician_phone}
                                    </div> 
                                </div>

                                <div className='flex flex-wrap '>
                                    <div className='w-[40%]'>
                                        Priority :
                                    </div>
                                    <div className='w-[60%] text-end'>
                                        {data?.priority}
                                    </div> 
                                </div>
                                
                            </div>
                    ))}
                    </>
                    )}

                </div>
                )}

                {ticketData?.service_bill && ticketData.service_bill.length > 0 &&(
                <div className="print-information-section mt-2 p-1">
                    <div className='print-information-head px-2 py-1'>
                        <div>Issue - SCBCC</div>
                    </div>

                    <div className="flex justify-between mx-2 print-issue-category my-1">
                    <div>Service Category- Inbound CRM </div>
                    <div>Ticket Category - SCBCC </div>
                    </div>

                    <div className="mx-2 my-2">
                        <p className='print-issue-section-title'>SCBCC Information</p>
                    </div>

                    <div className="grid grid-cols-3 mx-2 my-2 gap-x-16 gap-y-1">
                        
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Invoice No
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.sb_invoice_no}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Model Name
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.model_name}
                            </div> 
                        </div>


                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Product Category
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.sb_product_category?.name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Product Brand
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.sb_product_brand?.name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Showroom
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.showroom_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Dealer Name
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.dealer_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Purchase Date
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.purchase_date}
                            </div> 
                        </div>
                        
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Complaint
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.other_details}
                            </div> 
                        </div>
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Service Address
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.service_address}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Basic charge title
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.sb_basic_charge_title}
                            </div> 
                        </div>
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Basic charge Amount
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.sb_basic_charge_amount}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Warranty Status
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.warranty_status}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Warranty Exp Date
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.service_bill[0]?.warranty_exp_date}
                            </div> 
                        </div>

                    </div>


                    {ticketData?.service_bill[0]?.technician && ticketData?.service_bill[0]?.technician.length > 0 &&(
                        <>
                    <div className="mx-2 mt-2">
                        <p className='print-issue-section-title'>Assigned Technician Information</p>
                    </div>
                    {ticketData?.service_bill[0]?.technician?.map((data, index)=>(
                            <div className="grid grid-cols-3 mx-2 my-2 gap-x-16 gap-y-1 border-b">
                
                                <div className='flex flex-wrap '>
                                    <div className='w-[40%]'>
                                    Technician Name :
                                    </div>
                                    <div className='w-[60%] text-end'>
                                        {data?.technician_name}
                                    </div> 
                                </div>

                                <div className='flex flex-wrap '>
                                    <div className='w-[40%]'>
                                        Phone :
                                    </div>
                                    <div className='w-[60%] text-end'>
                                        {data?.technician_phone}
                                    </div> 
                                </div>

                                <div className='flex flex-wrap '>
                                    <div className='w-[40%]'>
                                        Priority :
                                    </div>
                                    <div className='w-[60%] text-end'>
                                        {data?.priority}
                                    </div> 
                                </div>
                                
                            </div>
                        ))}
                        </>
                    )}

                </div>
                )}

                <div className="print-information-section my-2 p-1">
                    <div className='print-information-head px-2 py-1'>
                        <div>Call Disposition</div>
                    </div>


                    <div className="mx-2 my-2">
                        <p className='print-issue-section-title'>Call Disposition Information</p>
                    </div>

                    <div className="grid grid-cols-3 mx-2 my-2 gap-x-16 gap-y-1">
                        
                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Call Remarks
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.call_remarks_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Related Ticked
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.related_ticked}
                            </div> 
                        </div>


                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            SMS Sent
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.sms_sent === true?'Yes':'No'}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Remarks
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.remarks}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                            Sms Formate
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.sms_formate_name}
                            </div> 
                        </div>

                        <div className='flex flex-wrap '>
                            <div className='w-[40%]'>
                                Message
                            </div>
                            <div className='w-[60%] text-end'>
                                {ticketData?.sms_body}
                            </div> 
                        </div>

                    </div>
                </div>

            </div>
        </div>
  </div>
  )
}
export default TicketViewWithPrint