import React from 'react'
import ATWorkingActivitiesLogListCom from './ATWorkingActivitiesLogComponents/ATWorkingActivitiesLogListCom'
import { useParams } from 'react-router-dom'

const ATWiseWorkingActivitiesLogList = () => {
    const {id} = useParams()
  return (
    <>
    <ATWorkingActivitiesLogListCom 
      apiEndpoint={`/ccd/api/assigned-technician-working-activities-logs/?parms_query=${id}`}
    />
  </>
  )
}

export default ATWiseWorkingActivitiesLogList