import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import ShowToast from '../../../Components/ShowToast'

const AddEditFormInputHandle = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [formData,setFormData] = useState({
    service_category : null,
    customer_name : '',
    customer_phone : '',
    customer_address : '',
    invoice_no : '',
    product_name : '',
    product_code : '',
    basic_charge : '',
    pay_status : false,
    transaction_no : '',
    warranty_status : false,
    remarks : '',
    requested_status:false,
    ticket_no:'',
  })

  const serviceCategoryOption = [
    {value:'Installation',  label:'Installation'},
    {value:'Re-Installation', label:'Re-Installation'},
    {value:'Servicing', label:'Servicing'},
  ]



  const handleInputChange = (e)=>{
    const {name,value,type,checked} = e.target;
    let inputValue = value

    if (type === 'checkbox') {
      inputValue = checked;
    }

    setFormData((prevFormData)=>({
      ...prevFormData,
      [name] : inputValue
    }))
  }

  const handleServiceCategoryChange = (selectedOption) =>{
    if (selectedOption && selectedOption.value !== null){
      setFormData(prevState => ({...prevState, service_category:selectedOption.value}));
    }else{
      setFormData(prevState => ({...prevState, service_category: null}));
    }
  }


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await authAxiosWithBearer.post(`${baseURL}/ccd/api/showroom-request-ticket/`, formData);
      console.log(response.data)
      if(response.data){
        navigate('/pending-requested-ticket-list-for-showroom')
        ShowToast('success', 'Successfully Submit')       
      }else{
        ShowToast('error', 'Something is Wrong Try Again')
      }
    } catch (error) {
      if(error.response?.data?.errors){
        ShowToast('error', `${error.response?.data?.errors}`);
      }else if(error.response?.data?.message){
        ShowToast('error', `${error.response?.data?.message}`);
      }else{
        ShowToast('error', `${error?.message}`);
      }
    }
  };
  


  return {
      formData,
      setFormData,
      serviceCategoryOption,
      handleServiceCategoryChange,
      handleInputChange,
      loading,
      setLoading,
      handleFormSubmit,
      navigate
    }
}

export default AddEditFormInputHandle