import React from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import InputFiled from '../../../../Components/Form/InputFiled'
import TextareaInput from '../../../../Components/Form/TextareaInput'

const HVAC = ({
    ticketFormData,
    handleTicketInputChange,
  }) => {
  return (
      <div>
          <FormPageTitle title='HVAC' />
          <div className="grid md:grid-cols-2 lg:grid-cols-3">
              <div className='my-4'>
                  <InputFiled className={`w-full`} type='text'
                      name='hvac_company_name' id='hvac_company_name' label='Company/Party Name'
                      isRequired={false} placeholder='Enter Company/Party Name'
                      value={ticketFormData.hvac_company_name}
                      onChange={handleTicketInputChange}
                  />
              </div>
              <div className="my-4">
                  <TextareaInput label='Company Addess'
                      name='hvac_address' id='hvac_address'
                      className='h-[41.535px] w-full'
                      placeholder={`Enter Company Addess`}
                      value={ticketFormData.hvac_address}
                      onChange={handleTicketInputChange}
                  />
              </div>

              <div className='my-4'>
                  <InputFiled className={`w-full`} type='text'
                      name='hvac_email'
                      id='hvac_email' label='Email'
                      value={ticketFormData.hvac_email}
                      onChange={handleTicketInputChange}
                      isRequired={false} placeholder='Enter Company Contact Email' />
              </div>
              <div className='my-4'>
                  <InputFiled className={`w-full`}
                      type='number'
                      min='1'
                      max='1000000000'
                      name='capacity' id='capacity' label='Capacity'
                      value={ticketFormData.capacity}
                      onChange={handleTicketInputChange}
                      isRequired={false} placeholder='Enter Approximate Capacity '
                  />
              </div>
          </div>
      </div>
  )
}

export default HVAC