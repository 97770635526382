import React, { useEffect, useState } from 'react'
import useFetchListData from '../../../../hooks/useFetchListData';
import { formatDateTime } from '../../../../Components/formatDateTime';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { AiFillFileExcel, AiOutlineSearch } from 'react-icons/ai';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import * as XLSX from 'xlsx';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';
import CustomPaginator from '../../../../Components/CustomPaginator';
import ListPageHeadWithDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithDateRange';

const ItemWiseStockReportListComponent = ({item_id}) => {

    const [searchText, setSearchText] = useState('');
    const [dataStatus, setDataStatus] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [excelExportLoading,setExcelExportLoading]=useState(false)
  
    const params = {
      page: currentPage,
      search: searchText,
      start_date: startDate,
      end_date: endDate,     
    };
  
    const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`/inventory/api/item-wise-stock-list/${item_id}/`, params);
  
    useEffect(() => {
      fetchData();
    }, [currentPage, searchText, dataStatus, endDate, startDate]);


    const handleExportToExcel = async () => {
        try {

        setExcelExportLoading(true)
        const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/item-wise-stock-list-export/${item_id}/`, {
          params: {
            start_date: startDate,
            end_date: endDate,     
            search: searchText,
          },
        });

        console.log('response.data.results',response.data)
    
        const exportData = response.data?.map((item,index) => ({
          SN: (currentPage - 1) * 10 + index + 1,
          'Created At': item?.created_at,
          Lot : item?.lot,
          Supplier : item?.supplier_name,
          Store : item?.store_master_text,
          Store : item?.store_master_text,
          Price:item?.price,
          UOM:item?.uom_text,
          'Stockin Qty':item?.stockin_qty,
          'Stockout Qty':item?.stockout_qty,
          'Damage Qty':item?.dam_quantity,
          'Available Qty':item?.available_qty,
          Remarks:item?.remarks,

        }));
    
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'exported_item_stock_list_data.xlsx');
      } catch (error) {
        console.log(error)  
      }
      finally{
        setExcelExportLoading(false)
      }
      };


  return (
    <div className='grid grid-cols-1'>

          <ListPageHeadWithDateRange 
                pageTitle = {'Stock List'}
                setEndDate = {setEndDate}
                endDate = {endDate}
                setStartDate = {setStartDate}
                startDate = {startDate}
                setSearchText = {setSearchText}
                addNewURL = {'item-stock-add'}
                addNewUrlPermissionCode = {'3.8.1'}
                handleExportToExcel={handleExportToExcel}
          />
      
        <div className='overflow-x-auto'>
            {isLoading &&(<LoadingSpinner />)}
            {excelExportLoading &&(<LoadingSpinner />)}
            
            <table className='table-auto border-1 border-[#ED6C6C]  max-w-[100%] text-[9px] sm:text-[12px]'>
                <thead>

                    <tr className='bg-[#EEEEEE]'>

                        <th className='border border-[#ED6C6C] w-[100px] py-3'>SN</th>
                        <th className='border border-[#ED6C6C] min-w-[100px] md:min-w-[150px] py-3'>Date</th>
                        <th className='border border-[#ED6C6C] min-w-[200px] py-3'>Basic Info</th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Price </th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>UOM </th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Stockout Qty </th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Stockout Qty </th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Damage Qty </th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Available Qty </th>
                        <th className='border border-[#ED6C6C] w-[100px] py-3'>Remarks </th>

                    </tr>

                </thead>
                {data?.length > 0?
                    <tbody className='text-center'>
                        {data?.map((item,index)=>(
                            <tr key={index}>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{(currentPage - 1) * 10 + index + 1}</td>
                                <td className='border border-[#ED6C6C] min-w-[100px] md:min-w-[150px] py-3'>{item?.created_at? formatDateTime(item?.created_at):''}</td>
                                <td className='border border-[#ED6C6C] min-w-[200px] py-3'>
                                    Lot : {item?.lot} <br />
                                    Supplier : {item?.supplier_name} <br />
                                    Store : {item?.store_master_text} <br />
                                </td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.price}</td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.uom_text}</td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.stockin_qty}</td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.stockout_qty}</td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.dam_quantity}</td>
                                <td className='border border-[#ED6C6C] w-[100px] py-3'>{item?.available_qty}</td>
                                <td className='border border-[#ED6C6C] w-[200px] py-3'>{item?.remarks}</td>
                            </tr>
                        ))}
                    </tbody>
                :
                    <tbody>
                        <tr>
                            <td colSpan="10" className='text-center h-[200px]'>Data Not Found</td>
                        </tr>
                    </tbody>
                }
            </table>

            <div className='my-5'>
            <CustomPaginator 
                setCurrentPage={setCurrentPage} 
                currentPage={currentPage}
                totalItems={totalItems}
            />
        </div>
        </div>

    </div>
  )
}

export default ItemWiseStockReportListComponent