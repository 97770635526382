import React, { useEffect, useState } from 'react'
import ATDetailsComponentForCompletedDeliveryResource from './ATForCompletedDeliveryResourceComponents/ATDetailsComponentForCompletedDeliveryResource';
import { useParams } from 'react-router-dom';
import useFetchDetailsData from '../../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';

const ATDetailsForCompletedDeliveryResource = () => {
  const {id} = useParams()
  const [ticketDataLoading,setTicketDataLoading] = useState(false)
  const [ticketData,setTicketData] = useState(null)

  const { detailsData, loading, error } = useFetchDetailsData(`in_house/api/assignd-technician-details-for-completed-delivery-resource`, {id});
  

    // fetchAssignedTicketData
    const fetchTicketData = async () => {
      setTicketDataLoading(true)
      try {
          const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${detailsData?.ticket_id}/`);
          // console.log('ticket',response.data);
          setTicketData(response.data);
      } catch (error) {
          console.log(error);
      }finally{
        setTicketDataLoading(false)
      }
      };

  useEffect(() => {
      fetchTicketData();
  }, [detailsData]);


  if(loading || ticketDataLoading){
    return <div><LoadingSpinner /> </div>
  }


  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Details</h1>
            </div>
        </div>

        <ATDetailsComponentForCompletedDeliveryResource data={detailsData}/>

        {/* assigned ticket details start  */}
          <AssignedTicketDetailsComponent  ticketData={ticketData} />
        {/* assigned ticket details end  */}


    </div>
  )
}

export default ATDetailsForCompletedDeliveryResource