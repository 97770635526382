import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../baseURL';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../Components/formatDateTime';
import { connect } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner';
import DateRangeFiltering from '../../Components/DateRangeFiltering';

const UserWiseAssignedTransportList = ({user}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/user-wise-assigned-transport-list/`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
  
        setTotalItems(count);
        setData(results);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate, currentPage, searchText]);


  
  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    ticket_no: data?.ticket_no,
    transport_user_employee_id:data?.transport_user_employee_id, 
    transport_user_name:data?.transport_user_name,
    transport_user_phone: data?.transport_user_phone,
    transport_assign_status: data?.transport_assign_status,
    product_received_status: data?.product_received_status,
    created_at: data?.created_at,
  }))



  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  
  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: '1',
    },
    {
        title: 'Transport User',
        dataIndex: 'transport_user_employee_id',
        key: '2',
    },
    {
        title: 'Transport User Name',
        dataIndex: 'transport_user_name',
        key: '3',
    },
    {
        title: 'Transport User Phone',
        dataIndex: 'transport_user_phone',
        key: '4',
        responsive: ['md']
    },
    {
      title: 'Status',
      dataIndex: 'transport_assign_status',
      key: '5',
      render: (text, record) => {
        const statusClass = record.transport_assign_status === 'Job Assigned' 
          ? 'bg-red-500' 
          : record.transport_assign_status === 'Job Started' 
          ? 'bg-blue-500' 
          : record.transport_assign_status === 'Job Completed' 
          ? 'bg-green-500' 
          : 'bg-transparent';
  
        return (
          <div className={`px-2 py-1 w-[136px] rounded text-white ${statusClass}`}>
              <div>{record.transport_assign_status} </div>
              {record.product_received_status === true &&(
              <div className='text-black'>
                  Product Received
              </div>
              )}
          </div>
        );
      },
    },
    {
      title: 'Assigned At',
      dataIndex: 'created_at',
      key: '5',
      render: (created_at) => {
        const formattedDateTime = created_at? formatDateTime(created_at):'';
        return <span>{formattedDateTime}</span>;
      },
    },
    {
        title: 'ACTIONS',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className='flex gap-2'>
            {user?.menu_permissions?.includes("7.1.2") && (
              <button onClick={() => navigate(`/user-wise-assigned-transport-details/${record.id}/`)} className='rounded'>
                <AiFillEye className='text-[#6969f5] text-xl' />
              </button>
            )}
          </div>
        ),
      },
  ];


  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>assigned Transport list</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='page-date-range my-auto hidden md:flex'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4 md:hidden'>
          <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>

      <div className='shadow-lg overflow-x-auto'>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(UserWiseAssignedTransportList);
