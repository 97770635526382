import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent'
import AssignedTechForGoodsCheckDetailsComponent from '../InHouseComponents/AssignedTechForGoodsCheckDetailsComponent'
import GoodsCheckedTechnicianFeedbackDetailsomponent from '../../Technician/TechnicianComponent/GoodsCheckedTechnicianFeedbackDetailsomponent'
import CustomerServicingFeedbackStatusChangeForm from '../InHouseComponents/CustomerServicingFeedbackStatusChangeForm'
import { useSelector } from 'react-redux'
import DeliveryTransportUserList from '../InHouseComponents/DeliveryTransportUserList'

const ServicingDisagreeDetails = () => {
    const {id} = useParams()
    const [data, setData]= useState(null)
    const [ticketData, setTicketData] = useState(null);
    const user = useSelector(state => state.auth.user);

    // fetchDeatilsData
    const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/assigned-technician-for-transported-goods-check-details/${id}/`);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
        };

    useEffect(() => {
        fetchDeatilsData();
    }, [id]);

    
    // fetchAssignedTicketData
    const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${data?.ticket_id}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };

    useEffect(() => {
        fetchTicketData();
    }, [data]);

    
  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Details</h1>
            </div>
        </div>


            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}


            {data?.goodscheckedtechnicianfeedback_set?.length > 0 && (
                <GoodsCheckedTechnicianFeedbackDetailsomponent feedbackData={data?.goodscheckedtechnicianfeedback_set[0]}/>
            )}

            {/* transport list start  */}
                <DeliveryTransportUserList 
                    detailsData={ticketData}
                    fetchDeatilsData={fetchDeatilsData} 
                    fetchTicketData={fetchTicketData}
                    goodsCheckTechnicianFeedback_id ={data?.goodscheckedtechnicianfeedback_set[0].id}
                />
            {/* transport list end  */}

    </div>
  )
}

export default ServicingDisagreeDetails