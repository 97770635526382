// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { logout, checkAuthenticated, load_user } from '../Redux/actions/auth';
import Sidebars from './Sidebars';
import Home from '../Page/Home';
import TopBar from './Topbar';
import Login from '../Page/Authentication/Login/Login';
import ProtectedRoutes from './ProtectedRoutes';
import { connect } from 'react-redux';
import UserPermissionAdd from '../Page/User/UserPermissionAdd';
import UserList from '../Page/User/UserList';
import UserAdd from '../Page/User/UserAdd';
import UserEdit from '../Page/User/UserEdit';
import CustomerList from '../Page/CRM/Customer/CustomerList';
import CustomerAdd from '../Page/CRM/Customer/CustomerAdd';
import CustomerEdit from '../Page/CRM/Customer/CustomerEdit';
import TicketList from '../Page/CRM/Ticket/TicketList';
import TicketCreate from '../Page/CRM/Ticket/TicketCreate';

// Import necessary components from react-transition-group
import { CSSTransition } from 'react-transition-group';
import AssignedTechnicianAdd from '../Page/CRM/AssignedTechnician/AssignedTechnicianAdd';
import TciketDetails from '../Page/CRM/Ticket/TciketDetails';
import TicketDetailsWithAssignedTechnician from '../Page/CRM/Ticket/TicketDetailsWithAssignedTechnician';
import PageNotFound from '../Page/PageNotFound';
import TechnicianWiseTicketList from '../Page/Technician/TechnicianWiseTicketList';
import TechnicianWiseTicketDetails from '../Page/Technician/TechnicianWiseTicketDetails';
import ItemMaster from '../Page/Inventory/ItemMaster';

import StockList from '../Page/Inventory/StockList';
import StockAdd from '../Page/Inventory/StockAdd';
import StockUpdate from '../Page/Inventory/StockUpdate';
import SrDetails from '../Page/Inventory/SrDetails';
import IssuedList from '../Page/Inventory/IssuedList';
import TicketCreateIDailer from '../Page/CRM/Ticket/TicketCreateIDailer';
import UserDetails from '../Page/User/UserDetails';
import PasswordChange from '../Page/Authentication/PasswordChange/PasswordChange';
import ItemPackageList from '../Page/Inventory/ItemPackage/ItemPackageList';
import ItemPackageAdd from '../Page/Inventory/ItemPackage/ItemPackageAdd';
import ItemPackageEdit from '../Page/Inventory/ItemPackage/ItemPackageEdit';
import AssignedTechnicianForIJCRUpdate from '../Page/CRM/AssignedTechnician/AssignedTechnicianForIJCRUpdate';
import TicketStatusChange from '../Page/CRM/Ticket/TicketStatusChange';
import CloseTicketStatusChange from '../Page/CRM/Ticket/CloseTicketStatusChange';
import PackageSrList from '../Page/Inventory/PackageSrList';
import PackageSrDetails from '../Page/Inventory/PackageSrDetails';
import SR from '../Page/Inventory/SR';
import PendingSr from '../Page/Inventory/AdditionalReqWaitForApporve/PendingSr';
import PendingSrDetails from '../Page/Inventory/AdditionalReqWaitForApporve/PendingSrDetails';
import SrToPrList from '../Page/Inventory/SrToPr/SrToPrList';
import SrToPrDetails from '../Page/Inventory/SrToPr/SrToPrDetails';
import DPR from '../Page/Inventory/SrToPr/DPR';
import AddNewWorkorder from '../Page/Inventory/Workorder/AddNewWorkorder';
import WorkorderList from '../Page/Inventory/Workorder/WorkorderList';
import WorkorderDetails from '../Page/Inventory/Workorder/WorkorderDetails';
import WorkorderUpdate from '../Page/Inventory/Workorder/WorkorderUpdate';
import AddNewGRN from '../Page/Inventory/GRN/AddNewGRN';
import GRnList from '../Page/Inventory/GRN/GRnList';
import TicketSubmitResponse from '../Page/CRM/Ticket/TicketSubmitResponse';
import SupplierList from '../Page/Inventory/Supplier/SupplierList';
import SupplierAdd from '../Page/Inventory/Supplier/SupplierAdd';
import SupplierUpdate from '../Page/Inventory/Supplier/SupplierUpdate';
import GRnDetails from '../Page/Inventory/GRN/GRnDetails';
import NewTicketRequestAdd from '../Page/ShowroomTicketRequest/NewTicketRequestAdd';
import PendingRequestedTicketListForShowroom from '../Page/ShowroomTicketRequest/PendingRequestedTicketListForShowroom';
import CreatedRequestedTicketListForShowroom from '../Page/ShowroomTicketRequest/CreatedRequestedTicketListForShowroom';
import TicketRequestUpdate from '../Page/ShowroomTicketRequest/TicketRequestUpdate'; 
import AllShowroomPendingRequestedTicketList from '../Page/ShowroomTicketRequest/AllShowroomPendingRequestedTicketList';
import AllShowroomCreatedRequestedTicketList from '../Page/ShowroomTicketRequest/AllShowroomCreatedRequestedTicketList';
import ShowroomRequestsList from '../Page/ShowroomTicketRequest/ShowroomRequestsList';
import TicketRequestUpdateForCCD from '../Page/ShowroomTicketRequest/TicketRequestUpdateForCCD';
import ApprovedSrList from '../Page/Inventory/AdditionalReqWaitForApporve/ApprovedSrList';
import ReInstallationTicketListForBilling from '../Page/Billing/IJCR-Ticket/ReInstallationTicketListForBilling';
import InstallationTicketListForBilling from '../Page/Billing/IJCR-Ticket/InstallationTicketListForBilling';
import ScbccWpTicketListForBilling from '../Page/Billing/SCBCC-Ticket/ScbccWpTicketListForBilling';
import ScbccWpExpireTicketListForBilling from '../Page/Billing/SCBCC-Ticket/ScbccWpExpireTicketListForBilling';
import InstallationTickeDetailsForBilling from '../Page/Billing/IJCR-Ticket/InstallationTickeDetailsForBilling';
import ReInstallationTickeDetailsForBilling from '../Page/Billing/IJCR-Ticket/ReInstallationTickeDetailsForBilling';
import ScbccWpTickeDetailsForBilling from '../Page/Billing/SCBCC-Ticket/ScbccWpTickeDetailsForBilling';
import ScbccWpExpireTickeDetailsForBilling from '../Page/Billing/SCBCC-Ticket/ScbccWpExpireTickeDetailsForBilling';
import ApprovedCustomerInvoiceList from '../Page/Billing/CustomerInvoice/ApprovedCustomerInvoiceList';
import PendingCustomerInvoiceList from '../Page/Billing/CustomerInvoice/PendingCustomerInvoiceList';
import CustomerInvoiceDetails from '../Page/Billing/CustomerInvoice/CustomerInvoiceDetails';
import AssignedVendorListForBilling from '../Page/Billing/VendorInvoice/AssignedVendorListForBilling';
import AssignedVendorDetailsForBilling from '../Page/Billing/VendorInvoice/AssignedVendorDetailsForBilling';
import ApprovedVendorInvoiceList from '../Page/Billing/VendorInvoice/ApprovedVendorInvoiceList';
import PendingVendorInvoiceList from '../Page/Billing/VendorInvoice/PendingVendorInvoiceList';
import VendorInvoiceDetails from '../Page/Billing/VendorInvoice/VendorInvoiceDetails';
import PendingHOInvoiceList from '../Page/Billing/HOInvoice/PendingHOInvoiceList';
import ApprovedHOInvoiceList from '../Page/Billing/HOInvoice/ApprovedHOInvoiceList';
import HOInvoiceDetails from '../Page/Billing/HOInvoice/HOInvoiceDetails';
import CustomerMrList from '../Page/Billing/MoneyReceipt/CustomerMrList';
import VendorMrList from '../Page/Billing/MoneyReceipt/VendorMrList';
import CustomerMrDetails from '../Page/Billing/MoneyReceipt/CustomerMrDetails';
import VendorMrDetails from '../Page/Billing/MoneyReceipt/VendorMrDetails';
import HoBillingList from '../Page/Billing/HOInvoice/HoBillingList';
import HoBillingDetails from '../Page/Billing/HOInvoice/HoBillingDetails';
import SalesList from '../Page/Inventory/Sales/SalesList';
import SalesDetails from '../Page/Inventory/Sales/SalesDetails';
import InHouseServiceRequestList from '../Page/InHouse/InHouseServiceRequest/InHouseServiceRequestList';
import InHouseServiceRequestDetails from '../Page/InHouse/InHouseServiceRequest/InHouseServiceRequestDetails';
import VehicleList from '../Page/Vehicle/VehicleList';
import VehicleAdd from '../Page/Vehicle/VehicleAdd';
import VehicleEdit from '../Page/Vehicle/VehicleEdit';
import AssignedTransportList from '../Page/InHouse/AssignedTransport/AssignedTransportList';
import UserWiseAssignedTransportList from '../Page/TransportUser/UserWiseAssignedTransportList';
import AssignedTransportDetails from '../Page/InHouse/AssignedTransport/AssignedTransportDetails';
import UserWiseAssignedTransportDetails from '../Page/TransportUser/UserWiseAssignedTransportDetails';
import UserWiseCompletedAssignedTransportList from '../Page/TransportUser/UserWiseCompletedAssignedTransportList';
import TransportedGoodsReceivedList from '../Page/InHouse/AssignedTransport/TransportedGoodsReceivedList';
import TransportedGoodsReceivedDetails from '../Page/InHouse/AssignedTransport/TransportedGoodsReceivedDetails';
import AssignedTechnicianForGoodsCheckList from '../Page/InHouse/AssignedTechnicianForGoodsCheck/AssignedTechnicianForGoodsCheckList';
import TechnicianWiseCompletedAssignedGoodsCheckList from '../Page/Technician/TechnicianWiseAssignedGoodsCheck/TechnicianWiseCompletedAssignedGoodsCheckList';
import TechnicianWiseAssignedGoodsCheckList from '../Page/Technician/TechnicianWiseAssignedGoodsCheck/TechnicianWiseAssignedGoodsCheckList';
import AssignedTechnicianForGoodsCheckDetails from '../Page/InHouse/AssignedTechnicianForGoodsCheck/AssignedTechnicianForGoodsCheckDetails';
import TechnicianWiseAssignedGoodsCheckDetails from '../Page/Technician/TechnicianWiseAssignedGoodsCheck/TechnicianWiseAssignedGoodsCheckDetails';
import WpCompletedAssignedTechnicianForGoodsCheckList from '../Page/InHouse/AssignedTechnicianForGoodsCheck/WpCompletedAssignedTechnicianForGoodsCheckList';
import WpeCompletedAssignedTechnicianForGoodsCheckList from '../Page/InHouse/AssignedTechnicianForGoodsCheck/WpeCompletedAssignedTechnicianForGoodsCheckList';
import ServicingAgreeList from '../Page/InHouse/CustomerFeedback/ServicingAgreeList';
import ServicingDisagreeList from '../Page/InHouse/CustomerFeedback/ServicingDisagreeList';
import AssignedTransportForDeliveryList from '../Page/InHouse/AssignedTransportForDelivery/AssignedTransportForDeliveryList';
import ServicingDisagreeDetails from '../Page/InHouse/CustomerFeedback/ServicingDisagreeDetails';
import UserWiseAssignedTransportListForDelivery from '../Page/TransportUser/UserWiseAssignedTransportListForDelivery';
import UserWiseAssignedTransportDetailsForDelivery from '../Page/TransportUser/UserWiseAssignedTransportDetailsForDelivery';
import UserWiseCompletedAssignedTransportListForDelivery from '../Page/TransportUser/UserWiseCompletedAssignedTransportListForDelivery';
import AssignedTransportForDeliveryDetails from '../Page/InHouse/AssignedTransportForDelivery/AssignedTransportForDeliveryDetails';
import ServicingAgreeDetails from '../Page/InHouse/CustomerFeedback/ServicingAgreeDetails';
import ATForServicingList from '../Page/InHouse/AssignedTechnicianForServicing/ATForServicingList';
import ATForServicingDetails from '../Page/InHouse/AssignedTechnicianForServicing/ATForServicingDetails';
import TechnicianWiseATForServicingList from '../Page/Technician/TechnicianWiseAssignedTechnicianForServicing/TechnicianWiseATForServicingList';
import TechnicianWiseATForServicingDetails from '../Page/Technician/TechnicianWiseAssignedTechnicianForServicing/TechnicianWiseATForServicingDetails';
import InHouseTechnicianSrDetails from '../Page/Inventory/InHouseTechnicianSR/InHouseTechnicianSrDetails';
import TechnicianWiseCompletedServicingList from '../Page/Technician/TechnicianWiseAssignedTechnicianForServicing/TechnicianWiseCompletedServicingList';
import CompletedServicingList from '../Page/InHouse/AssignedTechnicianForServicing/CompletedServicingList';
import CompletedServicingDetails from '../Page/InHouse/AssignedTechnicianForServicing/CompletedServicingDetails';
import CustomerComplain from '../Page/CRM/CustomerComplain/CustomerComplain';
import CustomerComplainDetails from '../Page/CRM/CustomerComplain/CustomerComplainDetails';
import TicketCreateForCustomerComplain from '../Page/CRM/Ticket/TicketCreateForCustomerComplain';
import ShowroomRequestDetails from '../Page/ShowroomTicketRequest/ShowroomRequestDetails';
import TicketCreateForShowroomReq from '../Page/CRM/Ticket/TicketCreateForShowroomReq';
import ShowroomWiseRequestDetails from '../Page/ShowroomTicketRequest/ShowroomWiseRequestDetails';
import GeneralSR from '../Page/Inventory/GeneralSR/GeneralSR';
import UserWiseGSRList from '../Page/Inventory/GeneralSR/UserWiseGSRList';
import UserWiseGSRDetails from '../Page/Inventory/GeneralSR/UserWiseGSRDetails';
import GeneralSRDetails from '../Page/Inventory/GeneralSR/GeneralSRDetails';
import GeneralSRList from '../Page/Inventory/GeneralSR/GeneralSRList';
import GeneralSRIssuedDetails from '../Page/Inventory/GeneralSR/GSRIssud/GeneralSRIssuedDetails';
import UserWiseGeneralSRIssuedDetails from '../Page/Inventory/GeneralSR/GSRIssud/UserWiseGeneralSRIssuedDetails';
import IssuedDetails from '../Page/Inventory/IssuedDetails';
import PackageIssuedDetails from '../Page/Inventory/PackageIssuedDetails';
import ATWiseWorkingActivitiesLogList from '../Page/CRM/ATWorkingActivitiesLog/ATWiseWorkingActivitiesLogList';
import TechnicianTaskReportAdd from '../Page/Technician/TechnicianTaskReport/TechnicianTaskReportAdd';
import TechnicianTaskReportListForCro from '../Page/Technician/TechnicianTaskReport/TechnicianTaskReportListForCro';
import InHouseTechnicianSrList from '../Page/Inventory/InHouseTechnicianSR/InHouseTechnicianSrList';
import DamagePartsList from '../Page/Inventory/DamageParts/DamagePartsList';
import DamagePartsDetails from '../Page/Inventory/DamageParts/DamagePartsDetails';
import DamagePartsAdd from '../Page/Inventory/DamageParts/DamagePartsAdd';
import DamagePartsUpdate from '../Page/Inventory/DamageParts/DamagePartsUpdate';
import UserWiseDamagePartsList from '../Page/Inventory/DamageParts/UserWiseDamagePartsList';
import SRIssuedReturnAdd from '../Page/Inventory/SRIssuedReturn/SRIssuedReturnAdd';
import SRIssuedReturnList from '../Page/Inventory/SRIssuedReturn/SRIssuedReturnList';
import SRIssuedReturnDetails from '../Page/Inventory/SRIssuedReturn/SRIssuedReturnDetails';
import ItemEdit from '../Page/Inventory/Item/ItemEdit';
import ItemDetails from '../Page/Inventory/Item/ItemDetails';
import ItemWiseStockLogList from '../Page/Inventory/StockLog/ItemWiseStockLogList';
import CompletedDeliveryAssignedTransportList from '../Page/InHouse/AssignedTransportForDelivery/CompletedDeliveryAssignedTransportList';
import ATListForCompletedDeliveryResource from '../Page/InHouse/AssignedTechnicianForCompletedDeliveryResource/ATListForCompletedDeliveryResource';
import ATDetailsForCompletedDeliveryResource from '../Page/InHouse/AssignedTechnicianForCompletedDeliveryResource/ATDetailsForCompletedDeliveryResource';
import TechnicianWiseATListForCompletedDeliveryResource from '../Page/Technician/TechnicianWiseATForCompletedDeliveryResource/TechnicianWiseATListForCompletedDeliveryResource';
import TechnicianWiseATDetailsForCompletedDeliveryResource from '../Page/Technician/TechnicianWiseATForCompletedDeliveryResource/TechnicianWiseATDetailsForCompletedDeliveryResource';
import TechnicianWiseCompletedATListForCompletedDeliveryResource from '../Page/Technician/TechnicianWiseATForCompletedDeliveryResource/TechnicianWiseCompletedATListForCompletedDeliveryResource';
import CompletedATListForCompletedDeliveryResource from '../Page/InHouse/AssignedTechnicianForCompletedDeliveryResource/CompletedATListForCompletedDeliveryResource';



const PageRoutes = ({ user, isAuthenticated, load_user, checkAuthenticated }) => {
  
  useEffect(() => {
    load_user();
  }, [load_user]);

  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated]);

  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleMenuVisibility = () => {
    if (window.innerWidth >= 992) {
      setMenuVisible(!menuVisible);
    } else {
      setMenuVisible(!menuVisible);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setMenuVisible(window.innerWidth >= 992);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(user?.primary_role)

  return (
    <>
      <Router>
        <div className="with-top-and-sidebar">
          {isAuthenticated && (
            <TopBar toggleMenuVisibility={toggleMenuVisibility} />
          )}
          <div className="flex mt-[30px] md:mt-14">

          {isAuthenticated && user?.primary_role !== 7 && (
            <CSSTransition
              in={menuVisible}
              timeout={700} // Adjust the duration of the transition
              classNames="sidebar"
              unmountOnExit
            >
              <Sidebars toggleMenuVisibility={toggleMenuVisibility} />
            </CSSTransition>
            )}
            
            {user?.primary_role !== 7? 
            <div className={`flex-1 py-4 transition-all duration-1000 ${menuVisible ? 'lg:ml-64 ml-[-1px] z-[-1] lg:z-[0]' : 'ml-0'}`}>
              
              <Routes>
                
                  {user?.primary_role !== 8?
                    <Route path="/home" element={<ProtectedRoutes> <Home /></ProtectedRoutes>} />
                  :
                  <Route path="/home" element={<ProtectedRoutes> <NewTicketRequestAdd /></ProtectedRoutes>} />
                  }
                <Route path="/password-change" element={<ProtectedRoutes><PasswordChange /></ProtectedRoutes>} />

                <Route path="/user-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.1.6")?<UserList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.1.2")?<UserDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.1.3")?<UserEdit />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-add" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.1.1")? <UserAdd />:<PageNotFound />}</ProtectedRoutes>} />
        
                <Route path="/user-permission" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.1.3")?<UserPermissionAdd />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/vehicle-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.2.6")?<VehicleList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/vehicle-add" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.2.1")?<VehicleAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/vehicle-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("1.2.3")?<VehicleEdit />:<PageNotFound />}</ProtectedRoutes>} />
                


                <Route path="/customer-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.2.6")?<CustomerList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/customer-add" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.2.1")?<CustomerAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/customer-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.2.3")?<CustomerEdit />:<PageNotFound />}</ProtectedRoutes>} />


                <Route path="/ticket-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.6")?<TicketList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ticket-create" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<TicketCreate />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ticket-create/:phone_no" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<TicketCreateIDailer />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ticket-deatils/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.2")?<TciketDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ticket-status-change/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.3")?<TicketStatusChange />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/close-ticket-status-change/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.3")?<CloseTicketStatusChange />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/success-response" element={<ProtectedRoutes> {user?.menu_permissions?.includes("2.1.1")?<TicketSubmitResponse />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/sales-list" element={<ProtectedRoutes> {user?.menu_permissions?.includes("3.13.6")?<SalesList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/sales-details/:invoice_no" element={<ProtectedRoutes> {user?.menu_permissions?.includes("3.13.2")?<SalesDetails />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/ticket-deatils-with-technician/:ticket" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.3.2")?<TicketDetailsWithAssignedTechnician />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-technician-add/:ticket_no" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.3.1")?<AssignedTechnicianAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-technician-for-ijcr-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.3.3")?<AssignedTechnicianForIJCRUpdate />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/at-wise-working-activities-log-list/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.3.2")?<ATWiseWorkingActivitiesLogList />:<PageNotFound />}</ProtectedRoutes>} />


                <Route path="/supplier-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.12.6")?<SupplierList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/supplier-add" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.12.1")?<SupplierAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/supplier-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.12.3")?<SupplierUpdate />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/item-master" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.2.6")?<ItemMaster />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.2.2")?<ItemDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-master/edit/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.2.3")?<ItemEdit />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-wise-stock-log/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.2.6")?<ItemWiseStockLogList />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/item-stock-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.8.6")?<StockList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-stock-add" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.8.1")?<StockAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-stock-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.8.3")?<StockUpdate />:<PageNotFound />}</ProtectedRoutes>} />
                
                
                <Route path="/sr" element={<ProtectedRoutes><SR /></ProtectedRoutes>} />
                <Route path="/pending-req" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.5")?<PendingSr />:<PageNotFound />}</ProtectedRoutes>} />

                <Route path="/sr-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.6")?<ApprovedSrList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/sr-details/:id" element={<ProtectedRoutes><SrDetails /></ProtectedRoutes>} />
                <Route path="/pending-sr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.5")?<PendingSrDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/in-house-technician-sr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.2")?<InHouseTechnicianSrDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/issued-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.4.6")?<IssuedList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/sr-issued-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.4.2")?<IssuedDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/package-sr-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.6")?<PackageSrList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/package-sr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.2")?<PackageSrDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/package-sr-issued-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.2")?<PackageIssuedDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/sr-to-pr-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.9.6")?<SrToPrList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/sr-to-pr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.9.2")?<SrToPrDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/direct-pr" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.9.1")?<DPR />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/pr-to-workorder" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.10.1")?<AddNewWorkorder />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/workorder-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.10.3")?<WorkorderUpdate />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/workorder-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.10.6")?<WorkorderList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/workorder-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.10.2")?<WorkorderDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/workorder-to-grn" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.11.1")?<AddNewGRN />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/grn-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.11.6")?<GRnList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/grn-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.11.2")?<GRnDetails />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/item-package-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.7.6")?<ItemPackageList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-package-add" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.7.1")?<ItemPackageAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/item-package-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.7.3")?<ItemPackageEdit />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/technician-wise-ticket-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseTicketList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-wise-ticket-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.2")?<TechnicianWiseTicketDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/technician-task-report-add/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.2")?<TechnicianTaskReportAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-task-report-list-for-cro/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.3.6")?<TechnicianTaskReportListForCro />:<PageNotFound />}</ProtectedRoutes>} />
                
                
                <Route path="/new-ticket-request" element={<ProtectedRoutes>{user?.primary_role === 8 ?<NewTicketRequestAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/pending-requested-ticket-list-for-showroom" element={<ProtectedRoutes>{user?.primary_role === 8 ?<PendingRequestedTicketListForShowroom />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/created-requested-ticket-list-for-showroom" element={<ProtectedRoutes>{user?.primary_role === 8 ?<CreatedRequestedTicketListForShowroom />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/requested-ticket-update/:id" element={<ProtectedRoutes>{user?.primary_role === 8 ?<TicketRequestUpdate />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/showroom-ticket-request-details/:id" element={<ProtectedRoutes>{user?.primary_role === 8 ?<ShowroomWiseRequestDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/showroom-requests-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<ShowroomRequestsList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/all-showroom-pending-requested-ticket-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<AllShowroomPendingRequestedTicketList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/all-showroom-created-requested-ticket-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<AllShowroomCreatedRequestedTicketList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/requested-ticket-update-for-ccd/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<TicketRequestUpdateForCCD />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/showroom-ticket-request-details-for-ccd/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<ShowroomRequestDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/showroom-request-wise-ticket-create/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<TicketCreateForShowroomReq />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/installations-ticket-list-for-billing" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.6")?<InstallationTicketListForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/installations-ticket-details-for-billing/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.2")?<InstallationTickeDetailsForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/re-installations-ticket-list-for-billing" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.6")?<ReInstallationTicketListForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/re-installations-ticket-details-for-billing/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.2")?<ReInstallationTickeDetailsForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/scbcc-wp-expire-ticket-details-for-billing/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.2")?<ScbccWpExpireTickeDetailsForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/scbcc-wp-ticket-details-for-billing/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.2")?<ScbccWpTickeDetailsForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/scbcc-wp-ticket-list-for-billing" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.6")?<ScbccWpTicketListForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/scbcc-wp-expire-ticket-list-for-billing" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.6")?<ScbccWpExpireTicketListForBilling />:<PageNotFound />}</ProtectedRoutes>} />

                
                <Route path="/approved-customer-invoice-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<ApprovedCustomerInvoiceList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/pending-customer-invoice-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<PendingCustomerInvoiceList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/customer-invoice-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<CustomerInvoiceDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/customer-mr-list/" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<CustomerMrList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/customer-mr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<CustomerMrDetails />:<PageNotFound />}</ProtectedRoutes>} />

                <Route path="/assigned-vendor-list-for-billing" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.6")?<AssignedVendorListForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-vendor-details-for-billing/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.1.2")?<AssignedVendorDetailsForBilling />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/approved-vendor-invoice-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<ApprovedVendorInvoiceList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/pending-vendor-invoice-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<PendingVendorInvoiceList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/vendor-invoice-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<VendorInvoiceDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/vendor-mr-list/" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<VendorMrList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/vendor-mr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<VendorMrDetails />:<PageNotFound />}</ProtectedRoutes>} />


                <Route path="/pending-ho-invoice-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<PendingHOInvoiceList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/approved-ho-invoice-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.6")?<ApprovedHOInvoiceList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ho-invoice-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.2.2")?<HOInvoiceDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ho-billing-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.3.6")?<HoBillingList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ho-billing-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("5.3.2")?<HoBillingDetails />:<PageNotFound />}</ProtectedRoutes>} />


                <Route path="/in-house-service-request-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.1.6")?<InHouseServiceRequestList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/in-house-service-request-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.1.2")?<InHouseServiceRequestDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-transport-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.2.6")?<AssignedTransportList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-transport-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.2.2")?<AssignedTransportDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/in-house-sr-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("3.3.6")?<InHouseTechnicianSrList />:<PageNotFound />}</ProtectedRoutes>} />


                <Route path="/assigned-transport-for-delivery-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.2.6")?<AssignedTransportForDeliveryList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-transport-for-delivery-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.2.2")?<AssignedTransportForDeliveryDetails />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/transported-goods-received-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<TransportedGoodsReceivedList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/completed-delivery-assigned-transport-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<CompletedDeliveryAssignedTransportList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/transported-goods-received-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.2")?<TransportedGoodsReceivedDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                
                <Route path="/assigned-technician-for-goods-check-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<AssignedTechnicianForGoodsCheckList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-technician-for-goods-check-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.2")?<AssignedTechnicianForGoodsCheckDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/wpe-completed-assigned-technician-for-goods-check-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<WpeCompletedAssignedTechnicianForGoodsCheckList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/wp-completed-assigned-technician-for-goods-check-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<WpCompletedAssignedTechnicianForGoodsCheckList />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/technician-wise-completed-assigned-goods-check-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseCompletedAssignedGoodsCheckList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-wise-assigned-goods-check-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseAssignedGoodsCheckList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-wise-assigned-goods-check-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.2")?<TechnicianWiseAssignedGoodsCheckDetails />:<PageNotFound />}</ProtectedRoutes>} />

                <Route path="/servicing-agree-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<ServicingAgreeList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/servicing-disagree-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<ServicingDisagreeList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/servicing-disagree-list-for-fdo" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.3.6")?<ServicingDisagreeList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/servicing-disagree-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["6.3.2","8.1.2"].includes(permission))?<ServicingDisagreeDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/servicing-agree-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["8.1.2"].includes(permission))?<ServicingAgreeDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/completed-servicing-list-for-fdo" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.3.6")?<CompletedServicingList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/completed-servicing-details-for-fdo/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("6.3.2")?<CompletedServicingDetails />:<PageNotFound />}</ProtectedRoutes>} />

                <Route path="/assigned-technician-for-servicing-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<ATForServicingList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/assigned-technician-for-servicing-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.2")?<ATForServicingDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                
                <Route path="/assignd-technician-list-for-completed-delivery-resource" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<ATListForCompletedDeliveryResource />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/completed-delivery-resource/complated-assigned-technician-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.6")?<CompletedATListForCompletedDeliveryResource />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/completed-delivery-resource/assignd-technician/details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("8.1.2")?<ATDetailsForCompletedDeliveryResource />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/technician-wise-assigned-technician-for-completed-delivery-resource" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseATListForCompletedDeliveryResource />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-wise/completed-delivery-resource/assignd-technician/details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.2")?<TechnicianWiseATDetailsForCompletedDeliveryResource />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/completed-delivery-resource/technician-wise-completed-assigned-technician-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseCompletedATListForCompletedDeliveryResource />:<PageNotFound />}</ProtectedRoutes>} />
                
                
                <Route path="/technician-wise-assigned-servicing-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseATForServicingList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-wise-assigned-servicing-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.2")?<TechnicianWiseATForServicingDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/technician-wise-completed-servicing-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("4.1.6")?<TechnicianWiseCompletedServicingList />:<PageNotFound />}</ProtectedRoutes>} />


                <Route path="/user-wise-assigned-transport-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("7.1.6")?<UserWiseAssignedTransportList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-assigned-transport-list-for-delivery" element={<ProtectedRoutes>{user?.menu_permissions?.includes("7.1.6")?<UserWiseAssignedTransportListForDelivery />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-completed-assigned-transport-list" element={<ProtectedRoutes>{user?.menu_permissions?.includes("7.1.6")?<UserWiseCompletedAssignedTransportList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-completed-assigned-transport-list-for-delivery" element={<ProtectedRoutes>{user?.menu_permissions?.includes("7.1.6")?<UserWiseCompletedAssignedTransportListForDelivery />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-assigned-transport-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("7.1.2")?<UserWiseAssignedTransportDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-assigned-transport-details-for-delivery/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("7.1.2")?<UserWiseAssignedTransportDetailsForDelivery />:<PageNotFound />}</ProtectedRoutes>} />
                

                <Route path="/customer-complain" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.5.6")?<CustomerComplain />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/customer-complain-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.5.2")?<CustomerComplainDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/ticket-create-for-customer-complain/:id" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<TicketCreateForCustomerComplain />:<PageNotFound />}</ProtectedRoutes>} />

                
                <Route path="/general-sr/" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.14.1","3.14.6"].includes(permission))?<GeneralSR />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/general-sr-list/" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.3.6"].includes(permission))?<GeneralSRList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-general-sr-list/" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.14.6"].includes(permission))?<UserWiseGSRList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/general-sr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.3.6"].includes(permission))?<GeneralSRDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-general-sr-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.14.6"].includes(permission))?<UserWiseGSRDetails />:<PageNotFound />}</ProtectedRoutes>} />
                
                <Route path="/general-sr-issued-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.3.6"].includes(permission))?<GeneralSRIssuedDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-general-sr-issued-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.14.6"].includes(permission))?<UserWiseGeneralSRIssuedDetails />:<PageNotFound />}</ProtectedRoutes>} />

                <Route path="/damage-parts-list" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.15.6"].includes(permission))?<DamagePartsList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/damage-parts-add" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.15.1"].includes(permission))?<DamagePartsAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/damage-parts-update/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.15.3"].includes(permission))?<DamagePartsUpdate />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/damage-parts-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.15.2"].includes(permission))?<DamagePartsDetails />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/user-wise-damage-parts-list" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.16.6"].includes(permission))?<UserWiseDamagePartsList />:<PageNotFound />}</ProtectedRoutes>} />

                <Route path="/sr-issued-return-add/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.17.1"].includes(permission))?<SRIssuedReturnAdd />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/issued-return-list" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.17.6"].includes(permission))?<SRIssuedReturnList />:<PageNotFound />}</ProtectedRoutes>} />
                <Route path="/issued-return-details/:id" element={<ProtectedRoutes>{user?.menu_permissions?.some(permission => ["3.17.2"].includes(permission))?<SRIssuedReturnDetails />:<PageNotFound />}</ProtectedRoutes>} />

                {isAuthenticated && (
                <Route path="*" element={<PageNotFound />} />
                )}

              </Routes>

            </div>
            :
            <div className='mx-auto w-[95%]'>
              <Routes>
                  <Route path="/home" element={<ProtectedRoutes> {user?.menu_permissions?.includes("2.1.1")?<TicketCreate />:<PageNotFound />}</ProtectedRoutes>} />
                  <Route path="/success-response" element={<ProtectedRoutes> {user?.menu_permissions?.includes("2.1.1")?<TicketSubmitResponse />:<Login />}</ProtectedRoutes>} />
                  <Route path="/ticket-create/:phone_no" element={<ProtectedRoutes>{user?.menu_permissions?.includes("2.1.1")?<TicketCreateIDailer />:<PageNotFound />}</ProtectedRoutes>} />
              </Routes>
            </div>
            }
          </div>
          
          {isAuthenticated && (
            <div className="footer text-center bottom-0 w-full py-2">
              Copyright © 2023 Esquire Technology Ltd.
            </div>
            )}
        </div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          {!isAuthenticated && (
            <Route path="*" element={<PageNotFound />} />
          )}
        </Routes>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { load_user, checkAuthenticated })(PageRoutes);
