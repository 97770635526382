import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDateTime } from '../../../Components/formatDateTime';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import Swal from 'sweetalert2';
import SRItemComponent from '../InventoryComponent/SRItemComponent';

function PendingSrDetails() {
    const {id} = useParams()
    const [reqData, setReqData] = useState(null) 
    const navigate = useNavigate()

    useEffect(()=>{
    const fetchSingleReqData = async () =>{
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-requisition/${id}/`)
            setReqData(response.data)
        } catch (error) {
            console.log(error)
        }
    };fetchSingleReqData();
    },[id])


    const handleApprovalStatus = async (userId) => {
        const result = await Swal.fire({
          title: 'Confirm Approved',
          text: 'Are you sure you want to Approved?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, Approved It!',
        });
      
        if (result.isConfirmed) {
          try {
            const response = await authAxiosWithBearer.put(`${baseURL}/inventory/api/update-requisition-approval-status/${id}/`);  
            if (response.status === 200) {
              Swal.fire('Confirm Approved!', `Successfully Approved`, 'success');
              navigate('/pending-req')
              console.log(response.data);
            }
          } catch (error) {
            console.error(error);
            Swal.fire('Error', 'something error', 'error');
          }
        }
      };


  return (
    <div>
        <div className="req-detail shadow-xl pb-5 border mx-4 bg-[#fff]">
            <div className='information-head  px-10 mb-5 py-2 flex justify-between'>
                <div>Requisition Details</div>
                {reqData?.approved_status === false && (
                    <div className='mx-4'>
                        <button type='button' className='flex text-[#ffffff] bg-[#ff4747] hover:bg-[#db3232] rounded-[5px]  px-4 py-1 shadow' onClick={() => handleApprovalStatus(id)}>
                            Approved 
                        </button>
                    </div>
                    )}
            </div>

            <h1 className='border-b pb-2 mx-4 mb-3'>Requisition Basic Information</h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-3  mx-4 my-2 gap-x-16 gap-y-2"> 

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Req Code
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {reqData?.req_code}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Ticket
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {reqData?.ticket_text}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Status
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {reqData?.issued === true?'Issued':'Raised'}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        SR From
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {reqData?.sr_from}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                     Requested By
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {reqData?.created_by_text}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Requested Date
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {reqData?.created_at && ( 
                            <> {formatDateTime(reqData?.created_at)}</>
                        )}
                    </div> 
                </div>

            </div>


            <div className="mx-4 mt-5">
                <SRItemComponent itemDatas={reqData?.invreqdetail_set} title={'Requisition Item Information'} />
            </div>
        </div>


    </div>
  )
}

export default PendingSrDetails