import React from 'react'
import ATListComponentForCompletedDeliveryResource from '../../InHouse/AssignedTechnicianForCompletedDeliveryResource/ATForCompletedDeliveryResourceComponents/ATListComponentForCompletedDeliveryResource'

const TechnicianWiseCompletedATListForCompletedDeliveryResource = () => {
  return (
    <div>
        <ATListComponentForCompletedDeliveryResource 
        title={'Completed Assigned List (Completed Delivery Resource)'}
        apiEndPoint={'/in_house/api/completed-delivery-resource/technician-wise/completed-assigned-technician-list/'} 
        detailsURL={'technician-wise/completed-delivery-resource/assignd-technician/details'}
        detailsURLPermission={'4.1.2'}
        />
    </div>
  )
}

export default TechnicianWiseCompletedATListForCompletedDeliveryResource