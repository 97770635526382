// TicketList.jsx
import React, { useState } from 'react';
import { format } from 'date-fns';
import {useNavigate } from 'react-router-dom';
import CommonPendingTicketList from './CommonPendingTicketList';
import { formatDateTime } from '../../../../Components/formatDateTime';


function PendingTicketList() {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState({});
  const [statusFiltering, setStatusFiltering] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket_no',
      key: '1',
      sorter: (a, b) => a?.ticket_no.localeCompare(b?.ticket_no),
      sortOrder: sortOrder['ticket_no'],
    },
    // {
    //   title: 'Customer',
    //   dataIndex: 'customer',  // Assuming customer data is stored under the 'customer' key
    //   key: '2',
    //   sorter: (a, b) => {
    //     const nameA = a?.customer && a.customer.length > 0 ? a.customer[0].name : '';
    //     const nameB = b?.customer && b.customer.length > 0 ? b.customer[0].name : '';
    //     return nameA.localeCompare(nameB);
    //   },
    //   sortOrder: sortOrder['customer'],
    //   render: (customer) => (customer && customer.length > 0 ? customer[0].name : 'N/A'),
    //   responsive: ['lg']
    // },
    

    {
      title: 'C.Phone',
      dataIndex: 'customer',  // Assuming customer data is stored under the 'customer' key
      key: '3',
      sorter: (a, b) => {
        const nameA = a?.customer && a.customer.length > 0 ? a.customer[0].phone_no : '';
        const nameB = b?.customer && b.customer.length > 0 ? b.customer[0].phone_no : '';
        return nameA.localeCompare(nameB);
      },
      sortOrder: sortOrder['customer'],
      render: (customer) => (customer && customer.length > 0 ? customer[0].phone_no : 'N/A'),
      responsive:['md']
    },
  
    {
      title: 'Category',
      dataIndex: 'category',
      key: '4',
      sorter: (a, b) => {
        const categoryA = (a.category && a.category.length > 0) ? a.category.join(', ') : '';
        const categoryB = (b.category && b.category.length > 0) ? b.category.join(', ') : '';
        return categoryA.localeCompare(categoryB);
      },
      sortOrder: sortOrder['category'],
      render: (category) => (category && category.length > 0 ? category.join(', ') : 'General Query'),
      responsive: ['md']
    },
      {
        title: 'Status',
        dataIndex: 'status_text',
        key: '5',
        filters: [
          {
            text: 'Open',
            value: 'Open',
          },
          {
            text: 'In Progress',
            value: 'In Progress',
          },
          {
            text: 'Pending',
            value: 'Pending',
          },
          {
            text: 'Close',
            value: 'Close',
          },
          {
            text: 'Complete',
            value: 'Complete',
          },
        ],
        onFilter: (value, record) => {
          setStatusFiltering(value);
          return record.status_text.startsWith(value);
        },
        filterSearch: true,
        filterMultiple: false,
      },
      {
        title: 'S.Status',
        dataIndex: 'sub_status_text',
        key: '6',
        responsive: ['md']
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: '7',
        render: (created_at) => {
          const formattedDateTime = formatDateTime(created_at);
          return <span>{formattedDateTime}</span>;
        },
        responsive: ['md']
      },
      {
        title: 'Technician Status',
        dataIndex: 'assigned_technicians',
        key: 'assigned_technicians',
        render: (assigned_technicians) => {
          if (assigned_technicians && assigned_technicians.length > 0) {
            return (
              <div>
                {assigned_technicians.map((tech, index) => {
                  const statusText = tech.sub_status_text
                    ? `${tech.technician_employee_id} (${tech.sub_status_text})`
                    : `${tech.technician_employee_id} (Job Not Start)`;
                  return <div className='my-2 border-b min-w-[250px]' key={index}>{`${index + 1}. ${statusText}`}</div>;
                })}
              </div>
            );
          }
          return 'N/A';
        },
        responsive: ['md']
      },
  ];




  return (
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px]'>
      <CommonPendingTicketList 
        apiEndpoint="/ccd/api/ticket/" 
        columnsConfig={columns}
        title="Ticket List"
        SearchKey1='ticket_no'
        SearchKey2='category'
        SearchKey3='customer'
        SearchKey4='status_text'
        SearchKey5='sub_status_text'
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        expandableKey1='category'
        expandableKey2=''
        expandableKey3=''
        expandableKey4=''
        dataEditURL='/ticket-status-change'
        CreateNewTicketURL='/ticket-create'
        TechnicianAssaignURL='/assigned-technician-add'
        SingleTicketViewURL='/ticket-deatils'
        statusFiltering={statusFiltering}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default PendingTicketList;
