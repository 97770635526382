import React from 'react'
import SRIssuedItemComponent from './InventoryComponent/SRIssuedItemComponent'
import useFetchDetailsData from '../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { formatDateTime } from '../../Components/formatDateTime';
import IssuedDetailsComponent from './InventoryComponent/IssuedDetailsComponent';

const IssuedDetails = () => {
    const {id} = useParams()
    console.log(id)

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/sr-issued', {id});
  
    if(loading){
        return <div><LoadingSpinner /> </div>
    }

  return (
    <IssuedDetailsComponent detailsData={detailsData} />
  )
}

export default IssuedDetails