import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ShowToast from '../../../../Components/ShowToast'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL'

const TechnicianTaskReportFormHandleCom = ({assigned_technician_id,onDataPost}) => {
    const navigate = useNavigate()
    const [requiredMessage,setRequiredMessage]=useState('')
    const [loading,setLoading]=useState(false)

      const [formData, setFormData] = useState({
        assigned_technician : null,
        report_descriptions : '',
        product_serial_no : '',
        techniciantaskreportimage_set: [
          {
            image_title : '',
            image : null,
          },
        ],
    });



      const handleInputChange = (e, index, type) => {
        const updatedData = { ...formData };
        const { name } = e.target;
        let value = e.target.value;

        if (type === 'techniciantaskreportimage_set') {
            setRequiredMessage('');
            if (name === 'image') {
                value = e.target.files[0];
            }
            updatedData.techniciantaskreportimage_set[index][name] = value;
        } else {
            updatedData[name] = value;
        }
        setFormData(updatedData);
    };

    const handleFileChange = (event, index) => {
        const file = event.target.files[0];
        const updatedImages = formData.techniciantaskreportimage_set.map((imageData, i) => {
          if (i === index) {
            return { ...imageData, image: file };
          }
          return imageData;
        });
        
        setFormData({ ...formData, techniciantaskreportimage_set: updatedImages });
      };


    
      const areAllFieldsFilled = () => {
        return (
            formData.techniciantaskreportimage_set &&
            formData.techniciantaskreportimage_set.every((item) => {
                return item?.image_title !== '' && item?.image !== null;
            })
        );
    };
    
      
    const handleAddItem = () => {
        if (areAllFieldsFilled()) {
            setFormData((prevState) => ({
                ...prevState,
                techniciantaskreportimage_set: [
                    ...prevState.techniciantaskreportimage_set,
                    {
                        image_title: '',
                        image: null,
                    },
                ],
            }));
            setRequiredMessage('');
        } else {
            setRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    
    
    
    const handleRemoveItem = (index) => {
    const techniciantaskreportimage_set = [...formData.techniciantaskreportimage_set];
    setRequiredMessage('');
    techniciantaskreportimage_set.splice(index, 1);
    setFormData({
        ...formData,
        techniciantaskreportimage_set,
    });
    };


    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          const updateFormData = {
            ...formData,
            assigned_technician: assigned_technician_id
          };
      
          console.log('updateFormData', updateFormData);
          
          const response = await authAxiosWithBearer.post(`${baseURL}/technician/api/technician-task-report/`, updateFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          
          if (response) {

            setFormData({
                assigned_technician: null,
                report_descriptions: '',
                product_serial_no: '',
                techniciantaskreportimage_set: [
                    {
                        image_title: '',
                        image: null,
                    },
                ],
            });
          }
          console.log(response);
          onDataPost()
          navigate(`/technician-task-report-add/${assigned_technician_id}/`);
          ShowToast('success', 'Successfully Add');
        } catch (error) {
          console.log(error);
          if(error?.response?.data){
            ShowToast('error', `${error?.response?.data}`);
          }else{
            ShowToast('error', 'Something is wrong');
          }
        } finally {
          setLoading(false);
        }
      };

  return {
    loading,
    setLoading,
    navigate,
    requiredMessage,
    setRequiredMessage,
    formData,
    setFormData,
    handleInputChange,
    handleFileChange,
    handleAddItem,
    handleRemoveItem,
    handleSubmit,
  }
}

export default TechnicianTaskReportFormHandleCom