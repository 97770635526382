import React from 'react'
import CustomerComplainListComponent from './CustomerComplainComponents/CustomerComplainListComponent'

const CompletedComplainList = () => {
  return (
    <div>
      <CustomerComplainListComponent 
          apiEndpoint={'/ccd/api/completed-customer-complain-list/'}
          pageTitle={'Pending Customer Complain List'}
        />
      </div>
  )
}

export default CompletedComplainList

