import React, { useEffect, useState } from 'react';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { AiFillDelete, AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { TbReportSearch } from "react-icons/tb";
import ListPageHeadWithoutDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithoutDateRange';
import * as XLSX from 'xlsx';

function ItemListComponet({
  apiEndpoint,
  columnsConfig,
  title,
  sortOrder,
  setSortOrder,
  addNewURL,
  dataEditURL,
  deleteURL,
  detailsURL,
  currentPage,
  setCurrentPage,
  listExcelExportApiEndPoint
}) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [excelExportLoading,setExcelExportLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/${apiEndpoint}/`, {
          params: {
            page: currentPage,
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
        const resultsData = results.map((item,index) => ({ ...item, key: index }));
  
        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currentPage, searchText]);


  const handleDelete = async (itemId) => {
    const result = await Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {

        await authAxiosWithBearer.delete(`${apiEndpoint}${itemId}/`);

        const updatedData = data.filter((item) => item.id !== itemId);
        setData(updatedData);
        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } catch (error) {
        console.log(error);
        Swal.fire('Error', 'An error occurred while deleting the item.', 'error');
      }
    }
  };


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };



  const handleExportToExcel = async () => {
      try {
      setExcelExportLoading(true)
      const response = await authAxiosWithBearer.get(`${baseURL}${listExcelExportApiEndPoint}`, {
        params: { 
          search: searchText,
        },
      });    

      console.log('response.data',response.data)

      const exportData = response.data?.map((item,index) => ({
          sn : index + 1,
          item_name : item?.item_name,
          item_code : item?.item_code,
          category : item?.category_text,
          sub_category_text : item?.sub_category_text,
          brand : item?.brand,
          model : item?.model,
          size : item?.size,
          specification : item?.specification,
          parts_no : item?.parts_no,
          origin : item?.origin,
          uom: item?.uom_text,
          stock_available_qty: item?.stock_available_qty,
          damage_parts_return_stock_qty: item?.damage_parts_return_stock_qty,
          uom: item?.uom_text,
          uom: item?.uom_text,
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'exported_item_stock_log_list_data.xlsx');
    } catch (error) {
      console.log(error)  
    }
    finally{
      setExcelExportLoading(false)
    }
  };



  
  return (
    <div className='grid grid-cols-1'>

          <ListPageHeadWithoutDateRange
                pageTitle = {title}
                setSearchText = {setSearchText}
                addNewURL = {`${addNewURL}`}
                addNewUrlPermissionCode = {'3.2.1'}
                handleExportToExcel={listExcelExportApiEndPoint && handleExportToExcel }
          />


      {isLoading &&(<LoadingSpinner />)}
      {excelExportLoading &&(<LoadingSpinner />)}
        <div className='overflow-x-auto'>
        <Table
                columns={[
                  ...columnsConfig.map((column) => ({
                    ...column,
                    sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
                  })),

                  {
                    title: 'Actions',
                    dataIndex: 'actions',
                    render: (_, record) => (
                      <div className='flex gap-2'>

                        {dataEditURL !== '' &&
                        <button onClick={() => navigate(`${dataEditURL}${record.id}`)} className='rounded'>
                          <AiFillEdit className='text-[#6F6F7E] text-xl' />
                        </button>
                        }

                      {detailsURL !== '' &&
                          <button onClick={() => navigate(`${detailsURL}/${record.id}`)} className='rounded'>
                            <AiFillEye className='text-[#1b1bff] text-xl' />
                          </button>
                        }
                        {detailsURL !== '' &&

                          <button onClick={() => navigate(`/item-wise-stock-log/${record.id}`)} className='rounded'>
                            <TbReportSearch className='text-[#177d9c] text-xl' title='Stock log report' />
                          </button>
                        }

                        {deleteURL !== '' &&
                        <button className='rounded ml-2' onClick={() => handleDelete(record.id)}>
                          <AiFillDelete className='text-xl text-[#F35B5B]' />
                        </button>
                        }

                      </div>
                    ),
                  },
                ]}
                expandable={{
                  expandedRowRender: (record) => (
                    <div className='shadow-lg p-4 border'>
                    <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 '>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Item Name</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.item_name}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Item Code</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.item_code}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Model</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.model}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>UOM</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.uom_text}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Available Stock</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.stock_available_qty}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Category</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.category_text}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Sub-Category</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.sub_category_text}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Brand</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.brand}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Origin</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.origin}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Parts Number</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.parts_no}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Size</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.size}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Status</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.status == true? `True`:'False'}</p>
                        </div>
                    </p>
                    </div>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[15%]'>Specification</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[80%] text-end'>{record?.specification}</p>
                        </div>
                    </p>
                  </div>
                  ),
                  rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
                }}
                dataSource={data}
                pagination={false}
                onChange={(pagination, filters, sorter) => {
                  setSortOrder({
                    columnKey: sorter.field,
                    order: sorter.order,
                  });
                }}
              />
        </div>
      <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}




export default ItemListComponet;

