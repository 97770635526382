import React from 'react'
import StockLogListComponent from './StockLogComponents/StockLogListComponent'
import { useParams } from 'react-router-dom'

const ItemWiseStockLogList = () => {
    const {id} = useParams()
  return (
    <div>
        <StockLogListComponent 
            listExcelExportApiEndPoint={`/inventory/api/item-wise-stock-log-list-export/${id}/`}
            listApiEndPoint={`/inventory/api/item-wise-stock-log-list/${id}/`}
        />
    </div>
  )
}

export default ItemWiseStockLogList