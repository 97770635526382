import React from 'react'
import SRIssuedItemComponent from './SRIssuedItemComponent'
import { formatDateTime } from '../../../Components/formatDateTime'

const IssuedDetailsComponent = ({detailsData}) => {
  return (
    <div className="req-detail shadow-xl pb-5 mx-4 bg-[#fff] border border-[#e25050]">
    <div className="user-details-page-title mb-5 shadow flex justify-between  px-5 py-2">
        <div className="my-auto py-2">
          <h1 className='uppercase'>SR Issued Details</h1>
        </div>
      </div>

        <div className='mx-2 border border-[#e25050]'>
            <div className="mx-2">
                <h1 className='py-1 text-[20px] font-semibold text-[#000000]' >Issued Basic Information</h1> <hr className='my-2' />
            </div>
            <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 '>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Issued No</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.issue_no}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Issued Date</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Issued By</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{detailsData?.created_by_employee_id}</p>
                    </div>
                </p>


            </div>

            <div className="mx-2">
                <h1 className='text-[15px] font-semibold' ></h1> <hr className='my-2' />
            </div>

            <SRIssuedItemComponent itemDatas={detailsData?.srissueditem_set} title={'Issued Item Information'} />

        </div>
    </div>
  )
}

export default IssuedDetailsComponent