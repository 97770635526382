import React from 'react'
import LoadingSpinner from '../../../Components/LoadingSpinner';
import useFetchDetailsData from '../../../hooks/useFetchDetailsData';
import SRIssuedReturnDetailsCom from './SRIssuedReturnComponents/SRIssuedReturnDetailsCom';
import { useParams } from 'react-router-dom';



const SRIssuedReturnDetails = () => {
    const {id} = useParams()
    console.log(id)

    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/sr-issued-return', {id});

    if(loading){
        return <div><LoadingSpinner /> </div>
    }

  return (
    <div>
        <SRIssuedReturnDetailsCom detailsData={detailsData}/>
    </div>
  )
}

export default SRIssuedReturnDetails


