import React from 'react'

const IjcrDetailsComponents = ({ijcrTicketData,openInvoicedModal}) => {
  return (
    <div className="grid md:grid-cols-2 px-3 my-4 gap-x-16">
    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Invoice No
        </div>
        <div className='w-[65%] text-end border'>
            {ijcrTicketData?.ib_invoice_no}  
                <button
                  onClick={() => openInvoicedModal(ijcrTicketData?.ib_invoice_no)}
                  className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                  >
                  Details
                </button>
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Invoice Date
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.ib_invoice_date}
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Showroom
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.ib_showroom_text}
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Dealer
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.ib_dealer_name}
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Product Brand
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.ib_product_brand_text}
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Product Category
        </div>
        <div className='w-[65%] text-end border '>
                {ijcrTicketData?.product_category_name}
        </div> 
    </div>


    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Model No
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.model_no}
        </div> 
    </div>

    <div className='flex flex-wrap my-3 '>
        <div className='w-[35%]'>
        Quantity
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.quantity}
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Other Info
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.other_info}
        </div> 
    </div>
    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Requested Type
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.requested_type}
        </div> 
    </div>
    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Basic Charge Title
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.ib_basic_charge_title}
        </div> 
    </div>

    <div className='flex flex-wrap my-3'>
        <div className='w-[35%]'>
        Basic Charge Amount
        </div>
        <div className='w-[65%] text-end border'>
                {ijcrTicketData?.ib_basic_charge_amount}
        </div> 
    </div>

</div>
  )
}

export default IjcrDetailsComponents