import React from 'react';
import './CSS/ListPageHead.css';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';

function ListPageHead(props) {

  const {ticketData} = props

  console.log(ticketData)

  const data = [
    { name: 'Complete', value: 2 },
    { name: 'Pending', value: 8 },
  ];

  const COLORS = ['#FF6384', '#36A2EB'];

  const renderLabelList = () => {
    return (
      <ul>
        {data.map((entry, index) => (
          <li key={`label-${index}`} style={{ color: COLORS[index] }}>
            {entry.name}: {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <div className="flex justify-between ticket-page-header mb-5">
        <div className="page-title my-auto ml-5 w-1/2">
          <h1 className="uppercase my-1">Ticket List</h1>
        </div>
        {/* <div className="w-1/2">
          <ResponsiveContainer>
            <div className='flex mt-5 justify-end'>
                <div className='mr-2'>
                  {renderLabelList()}
                </div>
                  <div className='mt-2 w-[60px] h-[60px]'>
                    <PieChart width={90} height={90}>
                    <Pie
                      data={data}
                      dataKey="value"
                      cx="20%"
                      cy="20%"
                      outerRadius={20}
                      fill="#8884d8"
                      labelLine={false}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Label value="Another Design" offset={0} position="center" fill="#333" />
                    </PieChart>
                  </div>
            </div>
          </ResponsiveContainer>
        </div> */}
      </div>
    </div>
  );
}

export default ListPageHead;
