// TicketList.jsx
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import PendingSrList from './PendingSrList';
import ApprovedSrList from './ApprovedSrList';




function AdditionalReq({user}) { 
  return (
    <div className="parent-category">
      <Tabs>
        <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list bg-[#fff] shadow-xl p-3  rounded-[5px]'>
            {user?.menu_permissions?.includes("3.3.6") && (
              <Tab>Pending List</Tab>
            )}
            {user?.menu_permissions?.includes("3.5.6") && (
              <Tab>Approved List</Tab>
            )}
          </TabList>


        {user?.menu_permissions?.includes("3.3.6") && (
        <TabPanel>
            <PendingSrList />
        </TabPanel>
        )}
        
        {user?.menu_permissions?.includes("3.5.6") && (
        <TabPanel>
          <ApprovedSrList />
        </TabPanel>
        )}
      </Tabs>
    </div>

  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AdditionalReq);