import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { parseISO } from 'date-fns';
import ShowToast from '../../../../Components/ShowToast';

const InvoiceWiseIJCrDataGet = ({setTicketFormData,setInvCustomerPhoneNo}) => {
        // invoice wise data get 
        const [inputInvoiceValueForIJCR, setInputInvoiceValueForIJCR] = useState('');
        const [invoiceNoGetForIJCR, setInvoiceNoGetForIJCR] = useState(null)
      
        const [selectedModelNoForIJCR, setSelectedModelNoForIJCR] = useState(null)
        const [modelNoForIJCROption, setModelNoForIJCROption] =useState([]);
      
      
        const [selectedShowroomForIJCR, setSelectedShowroomForIJCR] = useState(null)
        const [showroomForIJCROption, setShowroomForIJCROption] =useState([]);
      
        const [selectedIbProductBrandForIJCR, setSelectedIbProductBrandForIJCR] = useState(null)
        const [ibProductBrandOptionForIJCR, setIbProductBrandOptionForIJCR] =useState([]);
      
        const [selectedIbProductCategoryForIJCR, setSelectedIbProductCategoryForIJCR] = useState(null)
        const [ibProductCategoryOptionForIJCR, setIbProductCategoryOptionForIJCR] =useState([]);
      
      
        const handleInvoiceGetForIJCR = (e) => {
          e.preventDefault()
          setInvoiceNoGetForIJCR(inputInvoiceValueForIJCR);
          setSelectedModelNoForIJCR(null)
      
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ib_invoice_no : inputInvoiceValueForIJCR,
          }));
        };
      
        const handleInvoiceResetForIJCR = (e) => {
          e.preventDefault()
          setInvoiceNoGetForIJCR(null);
        };
      
      
        useEffect(() => {
          const fetchInvoiceDetailsData = async () => {
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/sales-information-details-with-invoice/${invoiceNoGetForIJCR}/`);
      
              setModelNoForIJCROption(response.data?.sales_product_information_set?.map((data) => ({
                value: data.product_information.model,
                label: data.product_information.model,
              })));
      
              setIbProductBrandOptionForIJCR(response.data?.sales_product_information_set?.map((data) => ({
                value: data.product_information.product_brand,
                label: data.product_information.product_brand_name,
              })));
      
              setIbProductCategoryOptionForIJCR(response.data?.sales_product_information_set?.map((data) => ({
                value: data.product_information.product_category_id,
                label: data.product_information.product_category_name,
              })));
      
              const showroomInfo = response.data?.showroom_info;
              if (showroomInfo) {
                setShowroomForIJCROption([
                  {
                    value: showroomInfo.id,
                    label: showroomInfo.address_1,
                  }
                ]);
              }
      
      
              setTicketFormData((prevFormData) => ({
                ...prevFormData,
                ib_invoice_date : response.data.invoice_date ? parseISO(response.data.invoice_date) : null
              }));
      
              setInvCustomerPhoneNo(response.data?.customer_info?.phone_no)
      
            } catch (error) {
              console.log(error);
              if (invoiceNoGetForIJCR && error.response && error.response.status === 404) {
                ShowToast('error', 'Data not found');
              }
      
              }
          };
      
          if (invoiceNoGetForIJCR) {
            fetchInvoiceDetailsData();
          }
        }, [invoiceNoGetForIJCR]);
      
      
        const handlemodelNoChangeForIJCR = (selectedOption) => {
          const model_no = selectedOption ? selectedOption.value : '';
      
          setSelectedModelNoForIJCR(selectedOption);
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            model_no : model_no,
          }));
        };
      
        const handleShowroomChangeForIJCR = (selectedOption) => {
          const showroomId = selectedOption ? selectedOption.value : null;
          setSelectedShowroomForIJCR(selectedOption);
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ib_showroom: showroomId,
          }));
        };
      
        const handleProductBrandChangeForIJCR = (selectedOption) => {
          const ibProductBrandId = selectedOption ? selectedOption.value : null;
          setSelectedIbProductBrandForIJCR(selectedOption);
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ib_product_brand : ibProductBrandId,
          }));
        };
      
        const handleIBProductCategoryChangeForIJCR = (selectedOption) => {
          const ibProductCateID = selectedOption ? selectedOption.value : null;
      
          setSelectedIbProductCategoryForIJCR(selectedOption);
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            ib_product_category  : ibProductCateID,
          }));
        };

  return {
    setInputInvoiceValueForIJCR,
    handleInvoiceGetForIJCR,
    handleInvoiceResetForIJCR,
    selectedShowroomForIJCR,
    handleShowroomChangeForIJCR,
    showroomForIJCROption,
    invoiceNoGetForIJCR,
    selectedIbProductCategoryForIJCR,
    handleIBProductCategoryChangeForIJCR,
    handleProductBrandChangeForIJCR,
    ibProductCategoryOptionForIJCR,
    selectedModelNoForIJCR,
    handlemodelNoChangeForIJCR,
    modelNoForIJCROption,
    selectedIbProductBrandForIJCR,
    ibProductBrandOptionForIJCR,
  }
}

export default InvoiceWiseIJCrDataGet