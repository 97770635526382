import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import TicketIssuedSrInfoForDetailsComponent from '../BillingComponents/TicketIssuedSrInfoForDetailsComponent';
import IjcrTicketBasicInformationForDetailsComponent from '../BillingComponents/IjcrTicketBasicInformationForDetailsComponent';
import CustomerInvoiceCreateModalButton from '../BillingComponents/CustomerInvoiceCreateModalButton';
import TicketWiseAssignedPackageInfoForDetailsComponent from '../BillingComponents/TicketWiseAssignedPackageInfoForDetailsComponent';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';

const ReInstallationTickeDetailsForBilling = () => {
  const {id} = useParams()
  const user = useSelector(state => state.auth.user);

  const [srIssuedData, setSrIssuedData] = useState(null);
  const [detailsData, setDetailsData] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [loading,setLoading]= useState(true)


  useEffect(() => {
    const fetchDetailsData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/ijcr-ticket-details-for-billing/${id}/`);
        setDetailsData(response.data);
      } catch (error) {
        console.log(error);
        if (id && error.response && error.response.status === 404) {
          ShowToast('error', 'Data not found');
        }
      }
      finally{
        setLoading(false)
      }
    };
      fetchDetailsData();
  }, [id]);


  useEffect(() => {
    const fetchSrIssuedListData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/ijcr-ticket-wise-sr-issued-list-for-billing/?installation_bill_id=${id}`);
        setSrIssuedData(response.data.results);
      } catch (error) {
        console.log(error);
        if (id && error.response && error.response.status === 404) {
          ShowToast('error', 'Data not found');
        }
      }
      finally{
        setLoading(false)
      }
    };
    fetchSrIssuedListData();
  }, [id]);


  useEffect(() => {
    const fetchPackageListData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/ijcr-ticket-wise-assigned-package-list-for-billing/?installation_bill_id=${id}`);
        setPackageData(response.data.results);
      } catch (error) {
        console.log(error);
        if (id && error.response && error.response.status === 404) {
          ShowToast('error', 'Data not found');
        }
      }
      finally{
        setLoading(false)
      }
    };
    fetchPackageListData();
  }, [id]);




  if (loading) {
    return <div><LoadingSpinner /></div>;
  }


  return (
    <div>
      <div className='border border-[#EB5757] sm:mx-3 my-3' >
          <IjcrTicketBasicInformationForDetailsComponent detailsData={detailsData} />

          {packageData?.length > 0 && (
              <>
                <TicketWiseAssignedPackageInfoForDetailsComponent packageData={packageData}/>
              </>
          )}

          {srIssuedData?.length > 0 && (
            <>
              <TicketIssuedSrInfoForDetailsComponent srIssuedData={srIssuedData} />
            </>
          )}
          {/* <div className='flex justify-center my-3 '>
              <button 
              className='bg-[#FA6669] text-[#FFF] px-6 py-2 rounded-md' 
              // onClick={pdfGeneretor}
              >Print</button>
          </div> */}

        {user?.menu_permissions?.includes("5.2.1") &&(
          <>
          {detailsData?.ib_customer_billing_invoice? 

          <div className='bg-[#FA6669] px-[5px] py-[1px] text-[#fff] text-center'>Customer Bill Invoice No : {detailsData?.ib_customer_billing_invoice}</div>
          :

            <CustomerInvoiceCreateModalButton 
              detailsData={detailsData} 
              srIssuedData={srIssuedData}
              reInstallationTicketNo={id}
              modalPageTitle='RE-INTALLATION TICKET INVOICE CREATE FORM'
            />
          }
          </>
        )}
      </div>
    </div>
  )
}

export default ReInstallationTickeDetailsForBilling