import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import useFetchListData from '../../../../hooks/useFetchListData';
import ListPageHeadWithoutDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithoutDateRange';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import CustomPaginator from '../../../../Components/CustomPaginator';


function TechnicianOrVendorListComponent({
  columnsConfig,
  title,
  apiEndpoint,
  refreshData,
  setSortOrder,
  sortOrder,
  
}) {
  
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const params = {
    page: currentPage,
    search: searchText,
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndpoint}`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText,refreshData]);
  

  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    employee_id: data?.employee_id,
    name:data?.name, 
    phone_no:data?.phone_no, 
    district_text: data?.district_text,
    work_load_count:data?.work_load_count
  }))



  
  return (

    <div className='container-fluid grid grid-cols-1 mx-1 my-3'>
      <ListPageHeadWithoutDateRange
        pageTitle={`${title}`}
        setSearchText={setSearchText}
      />

      <div className='shadow-lg overflow-x-auto'>

        {isLoading ? (
          <p><LoadingSpinner /></p>
        ) : (
          <>
            <Table
              columns={[
                ...columnsConfig.map((column) => ({
                  ...column,
                  sortOrder: column?.dataIndex === sortOrder?.columnKey ? sortOrder?.order : false,
                })),
              ]}
              dataSource={dataSource}
              pagination={false}
              onChange={(pagination, filters, sorter) => {
                setSortOrder({
                  columnKey: sorter.field,
                  order: sorter.order,
                });
              }}
            />
            <div className='my-5'>
              <CustomPaginator
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalItems={totalItems}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default TechnicianOrVendorListComponent;
