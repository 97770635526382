import React from 'react'
import useFetchDetailsData from '../../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import ItemDetailsComponents from './ItemComponents/ItemDetailsComponents';
import { useParams } from 'react-router-dom';

const ItemDetails = () => {
    const {id} = useParams()
    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('inventory/api/item-master', {id});
  
    if(loading){
        return <div><LoadingSpinner /> </div>
    }
    
  return (
    <div className='grid grid-cols-1'>
      <ItemDetailsComponents detailsData={detailsData} />
    </div>
  )
}

export default ItemDetails