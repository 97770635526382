import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import { useParams } from 'react-router-dom'
import WorkorderPrintView from './WoComponents/WorkorderPrintView'
import { useReactToPrint } from "react-to-print";
import { useRef } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import WorkorderDetailsComponent from './WoComponents/WorkorderDetailsComponent';

const WorkorderDetails = () => {
    const {id} = useParams()

    const componentPDF = useRef();
    const pdfGeneretor = useReactToPrint({
      content: () => componentPDF.current,
      documentTitle: "Esquire-CRM",
      pageStyle: `
        @page landscape {
          size: A4 landscape;
          margin: 20mm 10mm;
        }
        @page portrait {
          size: A4 portrait;
          margin: 20mm 10mm;
        }
        @page {
          margin: 4mm;
        }
        body {
          margin: 0;
        }
      `,
      contentStyle: `
        @page landscape {
          transform: rotate(0deg) translate(0, 0);
        }
        @page portrait {
          transform: rotate(90deg) translate(0, 0);
        }
      `
    });

    
    const [workorderData, setWorkorderData] = useState([])

    useEffect(()=>{
        const fetchWorkorderData =async ()=>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/workorder-details/${id}/`)
                console.log(response.data)
                setWorkorderData(response.data)
            } catch (error) {
                
            }
        };fetchWorkorderData();
    },[])

  return (
    <div>

          <WorkorderDetailsComponent workorderData={workorderData} pdfGeneretor={pdfGeneretor} />

          <WorkorderPrintView workorderData={workorderData} componentPDF={componentPDF} />


    </div>
  )
}

export default WorkorderDetails