import React, { useState } from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import InputFiled from '../../../../Components/Form/InputFiled'
import SelectInput from '../../../../Components/Form/SelectInput'

const CorporatePurchase = ({
        setTicketFormData,
        ticketFormData,
        handleTicketInputChange,
        cpProductCategoryOption,
    }) => {

    const [selectedCpProductCategory, setSelectedCpProductCategory] = useState(null)

    const handleCpProductCategoryChange = (selectedOption) => {
        const cpProductCateID = selectedOption ? selectedOption.value : null;

        setSelectedCpProductCategory(selectedOption);
        setTicketFormData((prevFormData) => ({
            ...prevFormData,
            cp_product_category : cpProductCateID,
        }));
    };

  return (
    <div>
        <FormPageTitle title='Corporate Purchase' />
        <div className="grid md:grid-cols-2 lg:grid-cols-3">
            <div  className='my-4'>
            <InputFiled type='text'
                className={`w-full`}                                   
                name='cp_company_name' id='cp_company_name'
                value={ticketFormData.cp_company_name}
                onChange={handleTicketInputChange}
                label='Company Name' isRequired={false} 
                placeholder='Enter Company Name'/>
            </div>
            <div  className='my-4'>
                <SelectInput
                    name='cp_product_category'
                    id='cp_product_category'
                    className={`w-full`}
                    label='Product Category'
                    value={selectedCpProductCategory}
                    onChange={handleCpProductCategoryChange}
                    options={cpProductCategoryOption}
                    isRequired={false}
                />
            </div>
            <div  className='my-4'>
                <InputFiled 
                type='number' 
                min='1'
                max='1000000000'
                className={`w-full`}  
                name='aproximate_quantity' id='aproximate_quantity'
                value={ticketFormData.aproximate_quantity}
                onChange={handleTicketInputChange}
                label='Appro. Quantity' isRequired={false} 
                placeholder='Enter Approximate Quantity'/>
            </div>
        </div>
    </div>
  )
}

export default CorporatePurchase