import React from 'react'
import SRIssuedReturnListCom from './SRIssuedReturnComponents/SRIssuedReturnListCom'

const SRIssuedReturnList = () => {
  return (
    <>
    <SRIssuedReturnListCom 
      apiEndpoint={`/inventory/api/sr-issued-return/`}
      detailsURL={`/issued-return-details/`}
      detailsPermissionCode={"3.17.2"}
      // updateURL={``}
      // updatePermissionCode={}
    />
  </>
  )
}

export default SRIssuedReturnList