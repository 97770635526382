import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../baseURL'
import { connect, useSelector } from 'react-redux';
import PackageSrToPr from './InventoryComponent/PackageSrToPr';
import PackageSRDetailsCom from './InventoryComponent/PackageSRComponents/PackageSRDetailsCom';
import PackageSRIssuFormCom from './InventoryComponent/PackageSRComponents/PackageSRIssuFormCom';
import SrIdWiseIssuedListCom from './InventoryComponent/SrIdWiseIssuedListCom';


function PackageSrDetails() {
    const {id} = useParams()
    const [reqData, setReqData] = useState(null)
    const navigate = useNavigate()
    const user  = useSelector(state => state.auth.user)


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [packageID, setPackageID] = useState(null);
    const [packageQty, setPackageQty] = useState(null);
    const [packageAssignedToTechnicianID, setPackageAssignedToTechnicianID] = useState(null);

    
    const openModal = (packageID,quantity,packageassignedtotechnician_id) => {
        setPackageID(packageID);
        setPackageAssignedToTechnicianID(packageassignedtotechnician_id)
        const parsedQuantity = parseFloat(quantity);
        setPackageQty(parsedQuantity);
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setPackageID(null);
        setPackageQty(null)
        setIsModalOpen(false);
      };

      useEffect(()=>{
        const fetchSingleReqData = async () =>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/package-sr-details/${id}/`)
                setReqData(response.data)
    
            } catch (error) {
                console.log(error)
            }
        };fetchSingleReqData();
        },[id])

        

        // #sr to pr 
    const [isPackageSrToPrModalOpen, setIsPackageSrToPrModalOpen] = useState(false);
    const [packageSrID, setPackageSrID] = useState('');

const openPackageSrToPrModal = (id) => {
    setPackageSrID(id);
    setIsPackageSrToPrModalOpen(true);
    };
    


  return (
    <div>
        <div className="req-detail shadow-xl  border bg-[#fff] mb-5">
            <div className='information-head py-2 px-4 mb-5 flex justify-between'>
                <div>Package Requisition Details</div>
                    <div>
                        {user?.menu_permissions?.includes("3.9.1") && (
                            <button
                                onClick={() => openPackageSrToPrModal(id)}
                                className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                                >
                                SR TO PR
                            </button>
                        )}
                    </div>
            </div>

            <PackageSRDetailsCom detailsData={reqData} openModal={openModal} user={user} />

        </div>

            <PackageSRIssuFormCom 
            isModalOpen={isModalOpen} 
            closeModal={closeModal} 
            packageID={packageID}
            packageQty={packageQty}
            packageAssignedToTechnicianID={packageAssignedToTechnicianID}
            />

            <SrIdWiseIssuedListCom
                apiEndPoint={`/inventory/api/sr-issued/?package_sr_id=${id}`}
            />

          <PackageSrToPr isModalOpen={isPackageSrToPrModalOpen} setIsModalOpen={setIsPackageSrToPrModalOpen} srID={packageSrID} />
    </div>
  )
}


  export default PackageSrDetails;