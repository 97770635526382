
import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import { useNavigate } from 'react-router-dom'
import ShowToast from '../../../Components/ShowToast'

function StockFormComponent() {
    const [formData,setFormData] = useState({
        item : null,
        brand_name : "",
        supplier : null,
        store : null,
        rack : null,
        bin_no : null,
        block : null,
        lot : "",
        uom : null,
        price : "",
        stockin_qty : "",
        stockout_qty : "",
        allocated_qty : "",
        dam_quantity : "",
        reorder_lavel : "",
        workorder_no : "",
        ticket : null,
        spec_id : null,
        content_type : null,
        remarks : "",
    })
    const navigate = useNavigate()
    const [itemOption, setItemOprion] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [supplierOption, setSupplierOprion] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [storeMasterOption, setStoreMasterOprion] = useState(null)
    const [selectedStoreMaster, setSelectedStoreMaster] = useState(null)
    const [rackMasterOption, setRackMasterOprion] = useState(null)
    const [selectedRackMaster, setSelectedRackMaster] = useState(null)
    const [binMasterOption, setBinMasterOprion] = useState(null)
    const [selectedBinMaster, setSelectedBinMaster] = useState(null)
    const [blockMasterOption, setBlockMasterOprion] = useState(null)
    const [selectedBlockMaster, setSelectedBlockMaster] = useState(null)
    const [uomOption, setUOMOprion] = useState(null)
    const [selectedUOM, setSelectedUOM] = useState(null)
    const [ticketOption, setTicketOprion] = useState([])
    const [selectedTicket, setSelectedTicket] = useState(null)
    const [supplierSearchInputValue, setSupplierSearchInputValue] = useState(null);
    const [ticketSearchInputValue, setTicketSearchInputValue] = useState(null)
    const [itemSearchInputValue, setItemSearchInputValue] = useState(null);

    useEffect(() => {
        authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
            .then(response => {
                  setItemOprion(response.data.results.map((data)=>({
                        value : data.id,
                        label : `${data.item_name} | ${data.specification} | ${data.size} | ${data.brand} | ${data.model}`,
                        brand : data.brand,
                  })))
  
                console.log(response.data.results)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [itemSearchInputValue]);
  
    
    const handleItemSearchInputChange = (value) => {
      setItemSearchInputValue(value);
    };


    useEffect(() => {
      authAxiosWithBearer.get(`${baseURL}/inventory/api/supplier-list/?search=${supplierSearchInputValue}`)
          .then(response => {
                setSupplierOprion(response.data.results?.map(data => ({
                value: data.id,
                label: data.name,
              })));

              console.log(response.data.results)
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });

    }, [supplierSearchInputValue]);
  
    
    const handleSupplierSearchInputChange = (value) => {
      setSupplierSearchInputValue(value);
    };


    // fetchStoreMasterData,fetchRackMasterData,fetchBinMasterData,fetchBlockMasterData fetchUOMData ,fetchTickData
    useEffect(()=>{
      const fetchStoreMasterData = async ()=>{
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/storemaster-list/`)
                  // console.log(response.data.results)
                  setStoreMasterOprion(response.data.results.map((data)=>({
                        value : data.id,
                        label : data.name
                  })))
            } catch (error) {
                  console.log(error)
            }
      };fetchStoreMasterData();

      const fetchRackMasterData = async ()=>{
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/rackmaster-list/`)
                  // console.log(response.data.results)
                  setRackMasterOprion(response.data.results.map((data)=>({
                        value : data.id,
                        label : data.name
                  })))
            } catch (error) {
                  console.log(error)
            }
      };fetchRackMasterData();

      const fetchBinMasterData = async ()=>{
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/binmaster-list/`)
                  // console.log(response.data.results)
                  setBinMasterOprion(response.data.results.map((data)=>({
                        value : data.id,
                        label : data.name
                  })))
            } catch (error) {
                  console.log(error)
            }
      };fetchBinMasterData();

      const fetchBlockMasterData = async ()=>{
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/blockmaster-list/`)
                  // console.log(response.data.results)
                  setBlockMasterOprion(response.data.results.map((data)=>({
                        value : data.id,
                        label : data.name
                  })))
            } catch (error) {
                  console.log(error)
            }
      };fetchBlockMasterData();

      const fetchUOMData = async ()=>{
            try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-uom-list/`)
                  // console.log(response.data.results)
                  setUOMOprion(response.data.results.map((data)=>({
                        value : data.id,
                        label : data.name
                  })))
            } catch (error) {
                  console.log(error)
            }
      };fetchUOMData();
    },[])


    useEffect(() => {
      if (ticketSearchInputValue?.length >= 3) {
        authAxiosWithBearer.get(`${baseURL}/ccd/api/ticket/?search=${ticketSearchInputValue}`)
            .then(response => {
                  setTicketOprion(response.data.results.map(data => ({
                    value: data.id,
                    label: data.ticket_no
                })));

                console.log(response.data.results)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [ticketSearchInputValue]);

    const handleTicketSearchInputChange = (value) => {
      setTicketSearchInputValue(value);
    };





    const handleInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      let inputValue;

      if (type === 'checkbox') {
        inputValue = checked;
      } else if (type === 'file') {
        inputValue = files[0];
      } else {
        inputValue = value;

        if (['spec_id'].includes(name)) {
            // Remove non-numeric characters
            const numericValue = value.replace(/[^0-9]/g, '');
            inputValue = numericValue;
            if (inputValue > 2147483647) {
              ShowToast('error', `Ensure that there are no more than 2147483647 max value`);
              return;
            }
          }
    
        // Conditionally limit the number of digits (integer + decimal parts)
        if (['price','stockin_qty', 'stockout_qty', 'allocated_qty', 'reorder_lavel','dam_quantity'].includes(name)) {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
    
          // If the total number of digits exceeds 20, show an error message
          if (integerPart.length > 14) {
            ShowToast( 'error', `Ensure that there are no more than 14 digits`);
            return;
          }
    
          // If decimal part exists, limit it to 6 decimal places
          if (parts.length === 2) {
            inputValue = `${integerPart}.${decimalPart.slice(0, 6)}`;
    
            // If more than 6 digits after the decimal point, show a separate error message
            if (decimalPart.length > 6) {
                  ShowToast('error',`More than 6 digits after the decimal point for ${name}.`);
            }
          }
        }

        if (['spec_id',].includes(name)) {
            const parts = value.split('.');
            const integerPart = parts[0];
      
            // If the total number of digits exceeds 20, show an error message
            if (integerPart.value > 2147483647) {
              ShowToast( 'error', `Ensure that there are no more than 2147483647 max value`);
              return;
            }
      
          }
      }
    
      setFormData({
        ...formData,
        [name]: inputValue
      });
    };
    
    

    const handleItemChange = (selectedOption) => {
      const id = selectedOption ? selectedOption.value : null;
      setSelectedItem(selectedOption);
      setFormData((prevFormData) => ({
        ...prevFormData,
        item: id,
        brand_name: selectedOption ? selectedOption.brand : '',
      }));
    };

    const handleUOMChange = (selectedOption) =>{
      const id = selectedOption ? selectedOption.value : null;
      setSelectedUOM(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            uom:id
      }))
    }

    const handleSupplierChange = (selectedOption) =>{
      const supplier = selectedOption ? selectedOption?.value : null;
      setSelectedSupplier(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            supplier:supplier
      }))
    }

    const handleStoreMasterChange = (selectedOption) =>{
      const id = selectedOption ? selectedOption.value : null;
      setSelectedStoreMaster(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            store:id
      }))
    }

    const handleRackMasterChange = (selectedOption) =>{
      const id = selectedOption ? selectedOption.value : null;
      setSelectedRackMaster(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            rack:id
      }))
    }

    const handleBinMasterChange = (selectedOption) =>{
      const id = selectedOption ? selectedOption.value : null;
      setSelectedBinMaster(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            bin_no:id
      }))
    }

    const handleBlockMasterChange = (selectedOption) =>{
      const id = selectedOption ? selectedOption.value : null;
      setSelectedBlockMaster(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            block:id
      }))
    }

    const handleTicketChange = (selectedOption) =>{
      const id = selectedOption ? selectedOption.value : null;
      setSelectedTicket(selectedOption);
      setFormData((prevFormData)=>({
            ...prevFormData,
            ticket:id
      }))
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      const { item, uom, price, stockin_qty } = formData;

      if (!item || !uom || !price || !stockin_qty) {
        ShowToast('error', 'All Required Fields need to be filled up');
        return;
      }
    
      try {
        // Make the API request and await the response
        const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/inv-stocks/`, formData);
        if (response) {
          navigate('/item-stock-list');
          ShowToast('success', 'Successfully Added');
        }
      } catch (error) {
        console.error(error);
        if(error.response.data.error){
          ShowToast('error', `${error.response.data.error}`);
        }
        else{
          ShowToast('error', 'Failed to Add Item');
        }
      }
    };
    

  return {
    handleInputChange,
    handleItemChange,
    handleSupplierSearchInputChange,
    handleSupplierChange,
    handleStoreMasterChange,
    handleRackMasterChange,
    handleBinMasterChange,
    handleBlockMasterChange,
    handleUOMChange,
    handleTicketChange,
    handleTicketSearchInputChange,
    handleSubmit,
    formData,
    setFormData,
    itemOption,
    setItemOprion,
    selectedItem,
    setSelectedItem,
    supplierOption,
    setSupplierOprion,
    selectedSupplier,
    setSelectedSupplier,
    storeMasterOption,
    setStoreMasterOprion,
    selectedStoreMaster,
    setSelectedStoreMaster,
    rackMasterOption,
    setRackMasterOprion,
    selectedRackMaster,
    setSelectedRackMaster,
    binMasterOption,
    setBinMasterOprion,
    selectedBinMaster,
    setSelectedBinMaster,
    blockMasterOption,
    setBlockMasterOprion,
    selectedBlockMaster,
    setSelectedBlockMaster,
    uomOption,
    setUOMOprion,
    selectedUOM,
    setSelectedUOM,
    ticketOption,
    setTicketOprion,
    selectedTicket,
    setSelectedTicket,
    navigate,
    setSupplierSearchInputValue,
    setTicketSearchInputValue,
    handleItemSearchInputChange,
  }
}

export default StockFormComponent