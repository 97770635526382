import React from 'react'
import SelectInputWC from '../../../../../Components/FormWithoutClass/SelectInputWC'
import InputFiledWC from '../../../../../Components/FormWithoutClass/InputFiledWC'
import { AiFillDelete } from 'react-icons/ai'
import TextareaInputWC from '../../../../../Components/FormWithoutClass/TextareaInputWC'
import DamagePartsFormHandleCom from './DamagePartsFormHandleCom'
import FormPageTitle from '../../../../../Components/Form/FormPageTitle'

const DamagePartsFormCom = ({id,title}) => {
    const {
        navigate,
        requiredMessage,
        userOptions,
        selectedUser,
        handleUserSearchInputChange,
        handleUserChange,
        SourceTypeOption,
        handleSourceTypeChange,
        itemOptions,
        selectedItem,
        itemSearchInputValue,
        formData,
        handleItemChange,
        handleItemSearchInputChange,
        handleInputChange,
        handleAddItem,
        handleRemoveItem,
        handleSubmit,
        formValidationErrors,
        handleEditSubmit,
    } = DamagePartsFormHandleCom({id})

    return (
        <div className="shadow-lg pt-1 px-2 my-5 pb-10 bg-[#fff]">
            <FormPageTitle title={title} FormPageRightSideButtonURL='/damage-parts-list' LinkName='Close' />


            <form onSubmit={!id? handleSubmit : handleEditSubmit} className='border border-[#F35B5B]'>
            {/* <form onSubmit={handleSubmit} className='border border-[#F35B5B]'> */}

            <div className='grid grid-cols-2 lg:grid-cols-2 my-2 gap-y-2 gap-x-2 px-2'>
                <div className="mt-7 mb-2">
                    <SelectInputWC
                        className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        label='Return User*'
                        id={`dp_return_user`}
                        name={`dp_return_user`}
                        value={selectedUser}
                        onInputChange={handleUserSearchInputChange}
                        onChange={handleUserChange}
                        options={userOptions}
                        placeholder='Select user'
                        // isRequired={true}
                        isClearable={true}
                    />
                    {formValidationErrors.dp_return_user && <span className='text-blue-900 font-[Inter]'>{formValidationErrors.dp_return_user}</span>}
                </div>

                <div className="mt-7 mb-2">
                    <SelectInputWC
                        className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        label='Source Type*'
                        id={`source_type`}
                        name={`source_type`}
                        value={SourceTypeOption.find((option)=> option.value === formData.source_type)}
                        onChange={handleSourceTypeChange}
                        options={SourceTypeOption}
                        placeholder='Select Source'
                        // isRequired={true}
                        isClearable={true}
                    />
                    {formValidationErrors.source_type && <span className='text-blue-900 font-[Inter]'>{formValidationErrors.source_type}</span>}

                </div>                
                </div>

                {formData?.damagepartsitem_set?.map((Items, index) => (

                <div className='add-more-item-form border border-[#F35B5B] mx-2'>
                <div className="item-add-form-head border-b bg-[#dbdbdb] flex justify-between py-2">
                <div className='mx-5 my-auto'>
                    <h1 className=''>Item{index + 1}</h1>
                </div>
                <div className='my-auto mx-5'>
                    {formData?.damagepartsitem_set?.length > 1 && (
                        <button
                        type="button"
                        onClick={() => handleRemoveItem(index)}
                        className="text-red-500"
                        >
                        <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600  border border-[#F35B5B]" />
                        </button>
                    )}
                </div>
                </div>

                    <div className='grid grid-cols-2 lg:grid-cols-2 my-2 mx-2 gap-y-2 gap-x-2 border px-1'>

                        <div className="mt-7 mb-2">
                            <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item*'
                                id={`item_${index}`}
                                name={`damagepartsitem_set[${index}]?.item`}
                                value={selectedItem[index]}
                                onInputChange={handleItemSearchInputChange}
                                onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                options={itemOptions.filter(
                                    (option) =>
                                        !selectedItem.some(
                                            (selectedItem) => selectedItem && selectedItem.value === option.value
                                        )
                                )}
                                placeholder='Select item'
                                // isRequired={true}
                                isClearable={true}
                            />
                            {formValidationErrors[`item_${index}`] && <span className='text-blue-900 font-[Inter]'>{formValidationErrors[`item_${index}`]}</span>}
                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC
                                type='text'
                                label='UOM'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='uom_text'
                                id={`uom_text_${index}`}
                                value={Items.uom_text}
                                // onChange={(e) => handleRequisionInputChange(e, index, 'damagepartsitem_set')}
                                readOnly={true}
                                placeholder='uom'
                            />
                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC
                                type='text'
                                label='Quantity*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='qty'
                                id={`qty_${index}`}
                                value={Items.qty}
                                onChange={(e) => handleInputChange(e, index, 'damagepartsitem_set')}
                                // isRequired={true}
                                placeholder='Enter quantity'
                            />
                            {formValidationErrors[`qty_${index}`] && <span className='text-blue-900 font-[Inter]'>{formValidationErrors[`qty_${index}`]}</span>}

                        </div>
                        <div className='mt-7 mb-2'>
                        <TextareaInputWC
                            className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]' 
                            label='Remarks' 
                            name='remarks' 
                            id={`remarks_${index}`}
                            placeholder={`Enter remarks`} 
                            value={Items.remarks}
                            onChange={(e) => handleInputChange(e, index, 'damagepartsitem_set')}
                        />
                        </div>
                    </div>
                </div>
                ))}

                {requiredMessage && (
                    <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span>
                )}
                <div className="flex justify-between mx-4 my-2">

                    <button
                        className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                        type="button"
                        onClick={() => {
                            console.log('Add new item Button Clicked');
                            handleAddItem();
                        }}
                    >ADD MORE</button>
                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                </div>
            </form>

        </div>
    )
}

export default DamagePartsFormCom