import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import AssignedTechForGoodsCheckDetailsComponent from '../../InHouse/InHouseComponents/AssignedTechForGoodsCheckDetailsComponent'
import AssignedTicketDetailsComponent from '../TechnicianComponent/AssignedTicketDetailsComponent'
import GoodsCheckedTechnicianFeedbackFormComponent from '../TechnicianComponent/GoodsCheckedTechnicianFeedbackFormComponent'
import GoodsCheckedTechnicianFeedbackDetailsomponent from '../TechnicianComponent/GoodsCheckedTechnicianFeedbackDetailsomponent'

const TechnicianWiseAssignedGoodsCheckDetails = () => {
    const {id} = useParams()
    const [data, setData]= useState(null)
    const [feedbackData, setFeedbackData]= useState(null)
    const [ticketData, setTicketData] = useState(null);

    // fetchDeatilsData
    useEffect(() => {
        const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/technician-wise-assigned-goods-check-details/${id}/`);
            console.log('AssignedTechnicianForGoodsCheckDetails',response.data);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchDeatilsData();
    }, [id]);

    
    // fetchAssignedTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${data?.ticket_id}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [data]);


        // fetchFeedbackDeatilsData
        useEffect(() => {
            const fetchFeedbackDeatilsData = async () => {
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/goods-checked-tech-feedback-details/${id}/`);
                console.log('fetchFeedbackDeatilsData',response.data);
                setFeedbackData(response.data);
            } catch (error) {
                console.log(error);
            }
            };
            fetchFeedbackDeatilsData();
        }, [id]);
    
  return (
    <div>

        <div className="user-details-page-title mb-5 shadow flex justify-between">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Assigned Technician For Goods Check Details</h1>
            </div>
        </div>


        
            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}

            {/* assigned transport details start  */}
                <AssignedTechForGoodsCheckDetailsComponent  data={data} />
            {/* assigned transport details end  */}


            {/* assigned ticket details start  */}
                <GoodsCheckedTechnicianFeedbackFormComponent  data={data} />
            {/* assigned ticket details end  */}

            {feedbackData?.gctfeedbackpartsdetails_set?.length > 0 && (
                <GoodsCheckedTechnicianFeedbackDetailsomponent feedbackData={feedbackData}/>
            )}
    </div>
  )
}

export default TechnicianWiseAssignedGoodsCheckDetails