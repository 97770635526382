import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { format } from "date-fns";
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { Table } from 'antd';
import Swal from 'sweetalert2';
import ShowToast from '../../../../Components/ShowToast';
import ATWorkingActivitiesLogCreateModalButton from '../../ATWorkingActivitiesLog/ATWorkingActivitiesLogComponents/ATWorkingActivitiesLogFormComponents/ATWorkingActivitiesLogCreateModalButton';
import { FaUsersViewfinder } from 'react-icons/fa6';
import { MdAddTask } from 'react-icons/md';

function ServiceTicketWiseAssignedTechnicianList({ticket_id,refreshData,onUpdateTicketData}) {
  const navigate = useNavigate()
  const [assignedData, setAssignedData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)


  const fetchAssignedData = async () =>{
    try {
      setIsLoading(true)
      const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/ticket-wise-assigned-technician/${ticket_id}/`)
      const filteredData = response.data.filter(item => item.installation === null && item.service !== null && item.hvac === null );
      
      setAssignedData(filteredData);
      setIsLoading(false);
      console.log('service tickte assigned data',response);
    } catch (error) {
      console.log(error)
    }
  };


  useEffect(()=>{
    fetchAssignedData();
  },[ticket_id,refreshData])


  const dataSource = assignedData?.flatMap((data,index) =>({
    key: index,
    id:data?.id,
    technician_employee_id: data?.technician_employee_id,
    package_category_name: data?.package_category_name,
    priority: data?.priority,
    remarks:data?.remarks,
    status_text: data?.status_text,
    sub_status_text: data?.sub_status_text,
    sub_status: data?.sub_status,
    created_by_text: data?.created_by_text,
    created_at: data?.created_at,
    packageassignedtotechnician_set: data?.packageassignedtotechnician_set
  }))


  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = format(date, 'dd-M-yyyy');

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;

    return `${formattedDate} | ${formattedTime}`;
  };


  const columns = [
    {
      title: 'Technician',
      dataIndex: 'technician_employee_id',
      key: 'technician_employee_id',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      responsive: ['md']
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      responsive: ['md']
    },
    {
      title: 'Status',
      dataIndex: 'status_text',
      key: 'status_text',
      responsive: ['md']
    },
    {
        title: 'Sub Status',
        dataIndex: 'sub_status_text',
        key: 'sub_status_text',
        responsive: ['md']
      },
    {
      title: 'Created By',
      dataIndex: 'created_by_text',
      key: 'created_by_text',
      responsive: ['md']
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>

            <button onClick={() => navigate(`/assigned-technician-for-ijcr-update/${record.id}`)} className='rounded'>
                <AiFillEdit className='text-[#6F6F7E] text-xl' />
            </button>

            {record.sub_status === null && (
                <button className='rounded ml-2' onClick={() => handleDelete(record.id)}>
                    <AiFillDelete className='text-xl text-[#F35B5B]' />
                </button>
            )}

        {record.sub_status_text !== 'Requested For Finish' && record.sub_status_text !== 'In house service request' && (
            <ATWorkingActivitiesLogCreateModalButton 
              id = {record.id}
            />
          )}

          <button onClick={() => navigate(`/at-wise-working-activities-log-list/${record.id}`)} className='rounded'>
                <FaUsersViewfinder  className='text-[#3c3cc9] text-xl' />
            </button>

            <button onClick={() => navigate(`/technician-task-report-list-for-cro/${record.id}`)} className='rounded'>
                <MdAddTask  className='text-[#3c3cc9] text-xl' />
            </button>

        </div>
      ),
    },
  ];


  const handleDelete = async (itemId) => {
    const result = await Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await authAxiosWithBearer.delete(`${baseURL}/ccd/api/assign-technician-with-package/${itemId}/`);
        const updatedData = assignedData.filter((item) => item.id !== itemId);
        setAssignedData(updatedData);
        onUpdateTicketData()
        Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } catch (error) {
        if(error.response.data.message){
          ShowToast('error',`${error.response.data.message}`)
          Swal.fire('Error', `${error.response.data.message}`, 'error');
        }
        else{
          Swal.fire('Error', 'An error occurred while deleting the object.', 'error');
        }
      }
    }
  };


  return (
    <div>

  {assignedData && assignedData.length > 0 && (
      <>
      <div className="flex justify-between ticket-page-header mb-5">
        <div className="page-title my-auto ml-5 w-1/2">
          <h1 className="uppercase my-1">Assigned List</h1>
        </div>
      </div>

        {isLoading && (<p>Loading...</p>)}
        
      <div className='shadow-lg'>
        <Table 
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <div className='shadow-lg p-4 border'>
                <div className="mx-2">
                    <h1 className='text-[15px] font-semibold' >Basic Information</h1> <hr className='my-2' />
                </div>
                <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2'>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Priority</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.priority}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Remarks</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.remarks}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Status</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.status_text}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Sub Status</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.sub_status_text}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Created By</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{record?.created_by_text}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Created Date</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{formatDateTime(record?.created_at)}</p>
                    </div>
                </p>


                </div>


                <div className="mx-2">
                    <h1 className='text-[15px] font-semibold' >Package Information</h1> <hr className='my-2' />
                </div>
                {record.packageassignedtotechnician_set?.map((item)=>(
                <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 border-b pb-2'>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Invoice Item</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item?.invoice_item_name}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Package</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item?.package_name}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>Package Qty</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item?.package_quantity}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>SR Issued</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{item?.sr_issued === true?'Issued':'Raised'}</p>
                    </div>
                </p>
                <p className="expanded-row-content">
                    <div className='flex flex-wrap'>
                        <p className='w-[40%] md:w-[40%]'>SR Issued</p>
                        <p className='w-[5%] md:w-[5%] text-center '>-</p>
                        <p className='w-[55%] md:w-[55%] text-end'>{formatDateTime(item?.isssued_date)}</p>
                    </div>
                </p>
                </div>
                ))}

            </div>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={dataSource} 
         />
      </div>
      
      </>
    )}




    </div>
  )
}

export default ServiceTicketWiseAssignedTechnicianList