import React, { useState } from 'react'
import LoginPageImage from '../../../assest/Images/crm-login-page-image.png'
import ECCLImage from '../../../assest/Images/eccl.png'
import LoginPageImageGif from '../../../assest/Images/login-page.gif'
import Logo from '../../../assest/Images/logo.png'
import { AiFillEyeInvisible,AiFillEye, AiOutlineMail, AiFillLock, AiOutlineGoogle, AiFillFacebook, AiFillApple, AiOutlineUser } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import './Login.css'
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../../Redux/actions/auth';
import { connect, useSelector } from 'react-redux';

function Login({login,isAuthenticated}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const user = useSelector(state => state.auth.user);
    
    const [formData, setFormData] = useState({
        username: '',
        password: '' 
    });

    const { username, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
        await login(username, password);
    
        if (!isAuthenticated) {
        setErrorMessage('Invalid username or password');
        }
    } catch (err) {
        console.error(err);
        setErrorMessage('An error occurred during login');
    }
    setLoading(false);
    };

    const [showPass, setShowPass] = useState(false);
    const toggle = () => {
      setShowPass(!showPass);
    };


    if(isAuthenticated) {        
        return navigate('/home');
      }

  return (
        <div className='login-page bg-[#fff] mt-[-100px]'>
            <div className="md:flex justify-center items-center w-full h-screen">
                <div className='loginPageLeftSide w-full md:w-1/2'>
                    <img src={LoginPageImageGif} alt="LoginPageImage" />
                </div>

                <div className='loginPageRightSide w-full md:w-1/2 '>
                    <div className="flex flex-col items-center">
                        <form onSubmit={e => onSubmit(e)} className='bg-[#FFFFFF] pb-[50px] md:p-[50px]'>
                            <div className='flex justify-start border-b mb-2'>
                                <div ><img src={Logo} alt="CompanyLogo" className='w-[60px]'/></div>
                                <div className='my-auto'><p className='logo-right-side-text'>Esquire-CRM</p></div>
                            </div>
                            <h1 className='login-text mb-2'>Login</h1>
                            <div>
                                <div className="mt-4 flex justify-between">
                                    <label className="login-input-label mb-3">Username</label>
                                </div>
                                <div className="relative">
                                    <div className="w-full">
                                        <input
                                            className="pl-10 login-input-field"
                                            name='username'
                                            value={username}
                                            onChange={e => onChange(e)} 
                                            required
                                            placeholder="Username"
                                        />
                                    </div>
                                    <div className="text-xl absolute top-[15px] left-4">
                                        <AiOutlineUser className='text-gray-500' />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="mt-6 flex justify-between">
                                    <label className="login-input-label mb-3">Password</label>
                                    {/* <Link className='text-[#6165D7]'>Forgot Password</Link> */}
                                </div>
                                <div className="relative">
                                    <div className="w-full">
                                        <input
                                            className="pl-10 login-input-field"
                                            type={showPass === false ? "password" : "text"}
                                            name='password'
                                            value={password}
                                            onChange={e => onChange(e)} 
                                            required
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div className="text-xl absolute top-[15px] left-4">
                                        <AiFillLock className='text-gray-500' />
                                    </div>

                                    <div className="text-xl absolute top-4 right-5">
                                        {showPass === false ? (
                                            <AiFillEye onClick={toggle} />
                                        ) : (
                                            <AiFillEyeInvisible onClick={toggle} />
                                        )}
                                    </div>
                                </div>
                            </div>

                        <div className='flex my-5'>
                                <input type="checkbox" name="" id="" />
                                <label className='remmber-text ml-2'>Remember me</label>
                        </div>

                            <button type='submit'  className='loginBtn'>
                                {loading ? (
                                <>Loading...</>
                                ):<>Login</>
                                }
                            </button>



                        <div className="flex mt-5">
                                <span className='loginClose my-auto'></span>
                                <span className='mx-3 loginCloseOR'>OR</span>
                                <span className='loginClose my-auto'></span>
                        </div>

                        <img src={ECCLImage} alt="" className='w-[350px]' />

                        {/* <div className="AnotherLoginOption flex justify-center">
                                <div className='googleLogin border rounded-[50%] p-2'>
                                    <Link>
                                        <FcGoogle />
                                    </Link>
                                </div>
                                <div className='facebookLogin border rounded-[50%] p-2 mx-3'>
                                    <Link>
                                        <AiFillFacebook />
                                    </Link>
                                </div>
                                <div className='appleLogin border rounded-[50%] p-2'>
                                    <Link>
                                        <AiFillApple />
                                    </Link>
                                </div>
                        </div> */}
                        </form>
                    </div>
                </div>
                
            </div>
            <div className="footer fixed bottom-0 w-full bg-gray-800 text-white text-center py-2">
                Copyright © 2023 Esquire Technology Ltd.
            </div>
            
        </div>

  )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);