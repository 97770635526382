import React, { useState, useEffect } from 'react';
import { FaBars, FaPowerOff, FaUser } from 'react-icons/fa';
import Logo from '../assest/Images/logo.png';
import { logout } from '../Redux/actions/auth';
import { connect } from 'react-redux';
import ShowToast from '../Components/ShowToast';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const TopBar = ({ toggleMenuVisibility, logout, isAuthenticated, userName }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const logout_user = () => {
    logout();
    setShowDropdown(false);
    ShowToast('success', 'Successfully Logout');
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="container bg-[#fff] text-[#333] py-1 mr-2 fixed top-0 w-full z-10 flex items-center justify-between border-b-[1px] border-[#E8E8E8] ">
      <div className='w-[80%]'>
        <div className="flex items-center">
          <button className="bg-[#fff] text-[#333] p-2" onClick={toggleMenuVisibility}>
            <FaBars />
          </button>
          <h1 className="text-[14px] md:text-[17px] font-bold ml-1 md:ml-4">
            <Link to='/home'>
              <div className='flex'>
                <img className='w-[57px] md:w-[60px]' src={Logo} alt="" />
                <div className='text-[#F35B5B]  my-auto ml-1 md:ml-2'>Esquire-CRM</div>
              </div>
            </Link>
          </h1>
        </div>
      </div>

      <div className="w-[20%] text-end mr-2">
        <div>
          {isAuthenticated && (
            <>
              <div className="relative inline-block">
                <button onClick={handleDropdownClick} className="font-semibold">
                  <div className='my-auto'>
                    <FaUser className='inline-block text-[#F35B5B] hover:text-[#fb0037] text-[10px] md:text-[17px]' />
                  </div>
                  <div className='my-auto text-[10px] md:text-[17px] text-[#F35B5B] hover:text-[#fb0037]'>{userName}</div>
                </button>
                <CSSTransition
                  in={showDropdown}
                  timeout={300}
                  classNames="dropdown"
                  unmountOnExit
                >
                  <div className="absolute text-start right-0 mt-3 w-[150px] md:w-[220px] bg-white shadow-lg">
                      <div className='my-3'>
                        <Link to="/admin/settings" className="font-semibold px-2 py-2  hover:text-[#FA5A7D] my-3 rounded text-[9px] md:text-[14px]">
                        Settings
                      </Link> 
                      </div>
                    <hr className='mt-1' />
                      <div className='my-3'>
                          <Link to="/password-change" className="font-semibold px-2 py-2  hover:text-[#FA5A7D] my-3 rounded text-[9px] md:text-[14px]">
                          Password Change
                        </Link>
                      </div>
                    <hr className='mt-1' />
                    <button onClick={logout_user} className="flex font-semibold px-2 py-2 my-3 rounded">
                      <FaPowerOff className='text-[9px] md:text-[14px] text-red-500 my-auto' /> <span className='text-[9px] md:text-[14px] text-red-500 mt-[-4px] ml-1 '>Logout</span>
                    </button>
                  </div>
                </CSSTransition>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  userName: state.auth.user ? state.auth.user.name : null,
});

export default connect(mapStateToProps, { logout })(TopBar);
