import React from 'react'
import GoodsCheckTotalParstBill from './GoodsCheckTotalParstBill'

const GoodsCheckedTechnicianFeedbackDetailsomponent = ({feedbackData,data}) => {
  return (
    <div className="information-section my-5 p-1">
    <div className='information-head my-1 px-10 py-1'>
        <div>Goods Checked and Technician Feedback (SR) </div>
    </div>
        <div className="grid md:grid-cols-2 mx-2 my-2 md:mx-2 gap-x-16 gap-y-2">

            <div className='flex flex-wrap '>
                <div className='w-[35%]'>
                Feedback Details
                </div>
                <div className='w-[65%] text-end border px-1'>
                    {feedbackData?.feedback_details}
                </div>
            </div>

            <div className='flex flex-wrap '>
                <div className='w-[35%]'>
                    Customer Feedback
                </div>
                <div className='w-[65%] text-end border px-1'>
                {feedbackData?.customer_feedback}
                </div>
            </div>
            {data?.sr_issue_status &&(
            <div className='flex flex-wrap '>
                <div className='w-[35%]'>
                   SR Issue Status
                </div>
                <div className='w-[65%] flex justify-end border px-1'>
                    {data?.sr_issue_status === 'Issued' ?
                        <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div>
                        : data?.sr_issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div>
                            : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>
                    }
                </div>
            </div>
            )} 
        </div>


<div className='overflow-x-auto overflow-y-auto'>
<p className='text-[20px] border-b mx-2 my-2'>Item Parts List</p>

<table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

    <thead>

        <tr className='bg-[#EEEEEE]'>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Quantity </th>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Sales Price</th>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> Total Price </th>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Issue Status </th>

        </tr>

    </thead>



    <tbody className='text-center'>

        {feedbackData?.gctfeedbackpartsdetails_set?.map((item,index)=>(
            <tr >
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{index+1}</td>
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                    name : {item?.item_name} <br />
                    size : {item?.item_size} <br />
                    specification :{item?.item_specification} <br />
                    uom : {item?.item_uom}
                </td>
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.quantity}</td>
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.item_sales_price}</td>
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{parseFloat(item?.quantity * item?.item_sales_price).toFixed(2)}</td>
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                    {item.item_issue_status === 'Issued' ?
                        <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div>
                        : item.item_issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div>
                            : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>
                    }
                </td>
            </tr>
        ))}

        <tr>
            <td colSpan={4} className='border-2 border-[#ED6C6C] w-[400px] py-3'>Total Amount</td>
            <td colSpan={1} className='border-2 border-[#ED6C6C] w-[400px] py-3'><GoodsCheckTotalParstBill feedbackData={feedbackData} /></td>
        </tr>

    </tbody>

</table>



</div>



    </div>
  )
}

export default GoodsCheckedTechnicianFeedbackDetailsomponent