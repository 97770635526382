import React, { useState } from 'react'
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import SelectInput from '../../../../Components/Form/SelectInput'
import InputFiled from '../../../../Components/Form/InputFiled'
import TextareaInput from '../../../../Components/Form/TextareaInput'

function DealershipQuery({
    setTicketFormData,
    ticketFormData,
    handleTicketInputChange,
    dqProductBrandOptions,
}) {
    const [selectedDqProductBrand, setselectedDqProductBrand] = useState(null);

    const handleDqProductBrandChange = (selectedOption) => {
        const productBrandId = selectedOption ? selectedOption.value : null;
        setselectedDqProductBrand(selectedOption);
          setTicketFormData((prevFormData) => ({
            ...prevFormData,
            dq_product_brand: productBrandId,
          }));
      };
      
  return (
      <div>
          <FormPageTitle title='Dealership Query' />
          <div className="grid md:grid-cols-2 lg:grid-cols-3">
              <div className='my-4'>
                  <SelectInput
                      name='dq_product_brand'
                      id='dq_product_brand'
                      label='Product Brand'
                      className={`w-full`}
                      value={selectedDqProductBrand}
                      onChange={handleDqProductBrandChange}
                      options={dqProductBrandOptions}
                      isRequired={false}
                  />
              </div>
              <div className='my-4'>
                  <InputFiled value={ticketFormData.shop_name} onChange={handleTicketInputChange} className={`w-full`} type='text' name='shop_name' id='shop_name' label='Shop Name' isRequired={false} placeholder='Enter Dealership Shop Name' />
              </div>
              <div className="my-4">
                  <TextareaInput value={ticketFormData.dq_address} onChange={handleTicketInputChange} name='dq_address' id='dq_address' className={`w-full h-[41.535px]`} label='Shop Addess' placeholder={`Enter Shop Addess`} />
              </div>
              <div className='my-4'>
                  <InputFiled value={ticketFormData.dq_email} onChange={handleTicketInputChange} className={`w-full`} type='email' name='dq_email' id='dq_email' label='Shop Email *' isRequired={false} placeholder='Enter Dealership Shop Email' />
              </div>
          </div>
      </div>
  )
}

export default DealershipQuery