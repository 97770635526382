import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';

const AssignedTransportStatusChangeForm = ({data,id,fetchDeatilsData,url}) => {

    const [statusForm, setStatusForm] = useState({
        transport_assign_status: '',
    })

    const statusOption = [
        {value:'Job Assigned',label:'Job Assigned'},
        {value:'Job Started',label:'Job Started'},
        {value:'Job Completed',label:'Job Completed'},
    ]

    console.log(statusForm)


    useEffect(() => {
        setStatusForm(data)
    }, [data]);


    const handleStatusChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
            setStatusForm(prevState => ({ ...prevState, transport_assign_status: selectedOption.value }));
            console.log('selectedOption',selectedOption)
        } else {
            setStatusForm(prevState => ({ ...prevState, transport_assign_status: null }));
        }
        };
    



    const handleStatusChangeSubmit = async (e)=>{
        e.preventDefault()
        try {
            const response = await authAxiosWithBearer.put(`${baseURL}/in_house/api/${url}/${id}/`,statusForm)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Updated');
                fetchDeatilsData()
              }
        } catch (error) {
            console.log(error)
        }
    }

    console.log(data?.transport_assign_status)

  return (
    <div>

        <form className='border border-[#E74A3B] shadow-lg rounded-[8px] pb-3' onSubmit={handleStatusChangeSubmit}>
                <FormPageTitle title='Assigned Status' />
                <div className='flex justify-between md:mx-5 gap-x-4'> 
                    <div  className='my-4 w-[70%]'>
                        <SelectInputWC
                            name='transport_assign_status'
                            id='transport_assign_status'
                            label='Status*'
                            placeholder='select status'
                            className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                            value={statusOption?.find((option)=> option?.value === statusForm?.transport_assign_status)}
                            onChange={handleStatusChange}
                            options={statusOption}
                            isRequired={true}
                        />
                    </div>
                    <div className='my-4 w-[30%]'>
                    {url === 'user-wise-assigned-transport-status-change-for-delivery'?
                    <>
                        {data?.transport_assign_status !== 'Job Completed'? 
                        <div className='my-auto '>
                            <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-2 text-center py-2'>Change</button>      
                        </div>
                        :
                        <div className='my-auto'>
                            <button type='submit' disabled className='bg-[#FA6669] text-[#fff] rounded-[5px] px-2 text-center py-2'>Completed</button>      
                        </div>
                        }
                    </>
                    :
                    <>
                    {url === 'user-wise-assigned-transport-status-change' && (
                                        <div>
                                        {data?.transport_assign_status !== 'Job Completed' && (
                                            <div className='my-auto'>
                                                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-2 text-center py-2'>Change</button>      
                                            </div>
                                        )}
                
                                        {data?.product_received_status === true?
                                            <div className='my-auto'>
                                                <p className='bg-[#167e1f] text-[#fff] rounded-[5px] px-2 text-center py-2'>Product Received</p>      
                                            </div>
                                        :
                                        <>
                                        {data?.transport_assign_status === 'Job Completed' && (
                                            <div className='my-auto'>
                                                <p className='bg-[#FA6669] text-[#fff] rounded-[5px] px-2 text-center py-2'>Product Not Receive</p>      
                                            </div>
                                            )}
                                        </>
                                        }
                                    </div>
                    )}
                    </>
                    }
                    </div>

                </div>
        </form>

    </div>
  )
}

export default AssignedTransportStatusChangeForm