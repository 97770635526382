import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../../../baseURL';
import ShowToast from '../../../../../Components/ShowToast';
import { useNavigate } from 'react-router-dom';
import useFetchDetailsData from '../../../../../hooks/useFetchDetailsData';

const DamagePartsFormHandleCom = ({id}) => {
    const navigate = useNavigate()
    // requision
    const [requiredMessage,setRequiredMessage]=useState('')
    const [lastItemNullMessage,setLastItemNullMessage]=useState('')

    const [itemOptions, setItemOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [itemSearchInputValue, setItemSearchInputValue] = useState(null);

    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [userSearchInputValue, setUserSearchInputValue] = useState(null);
    const [formValidationErrors, setFormValidationErrors] = useState({});

    const [formData, setFormData] = useState({
        source_type:'',
        dp_return_user:null,
        damagepartsitem_set: [
          {
            item: null,
            uom_text:'',
            qty: '',
            remarks:'',
          },
        ],
      });

      const validateFormData = () => {
        const formValidationErrors = {};
        // Check if source_type is empty
        if (!formData.source_type.trim()) {
          formValidationErrors.source_type = "Source type is required.";
        }
      
        // Check if dp_return_user is empty
        if (!formData.dp_return_user) {
          formValidationErrors.dp_return_user = "Return user is required.";
        }
      
        // Validate damagepartsitem_set fields
        formData.damagepartsitem_set.forEach((item, index) => {
          if (!item.item) {
            formValidationErrors[`item_${index}`] = "Item is required.";
          }
          if (!item.qty || item.qty <= 0) {
            formValidationErrors[`qty_${index}`] = "Quantity is required and should be greater than 0.";
          }
        });
      
        return formValidationErrors;
      };
      

    const SourceTypeOption = [
        {value:'Inventory', label:'Inventory'},
        {value:'Customer',label:'Customer'}
      ]


    const handleSourceTypeChange = (selectedOption) => {
      if (selectedOption && selectedOption.value !== null) {
        setFormData(prevState => ({ ...prevState, source_type: selectedOption.value }));

        if (selectedOption) {
          setFormValidationErrors((prevErrors) => ({
            ...prevErrors,
            source_type: '',
          }));
        }
      } else {
        setFormData(prevState => ({ ...prevState, source_type: '' }));
      }
    };



      useEffect(() => {
        authAxiosWithBearer.get(`${baseURL}/api/user-list/?search=${userSearchInputValue}`)
            .then(response => {
            const OptionsData = response.data.results
            .map(data => ({
                value: data.id,
                label: `${data?.employee_id}`,
            }));
            setUserOptions(OptionsData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [userSearchInputValue]);

    
    const handleUserSearchInputChange = (value) => {
        setUserSearchInputValue(value);
    };


    const handleUserChange = (selectedOption) => {
      const selected_id = selectedOption ? selectedOption.value : '';
      setSelectedUser(selectedOption);
      setFormData({
        ...formData,
        dp_return_user: selected_id,
      });
    
      // Clear the error for dp_return_user when a valid option is selected
      if (selectedOption) {
        setFormValidationErrors((prevErrors) => ({
          ...prevErrors,
          dp_return_user: '',
        }));
      }
    };
    


    useEffect(() => {
        authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
            .then(response => {
            const ItemsData = response.data.results
            .map(item => ({
                value: item.id,
                label: `${item?.item_name} | ${item?.size} | ${item.specification} | ${item.brand} | | ${item.model}`,
                uom_text: item.uom_text
            }));
            setItemOptions(ItemsData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [itemSearchInputValue]);

    
    const handleItemSearchInputChange = (value) => {
    setItemSearchInputValue(value);
    };



    const handleItemChange = (selectedOption, index) => {
      const updatedFormData = { ...formData };
      updatedFormData.damagepartsitem_set[index].item = selectedOption?.value || null;
      updatedFormData.damagepartsitem_set[index].uom_text = selectedOption?.uom_text || null;
    
      const updatedselectedItem = [...selectedItem];
      updatedselectedItem[index] = selectedOption || null;
    
      setSelectedItem(updatedselectedItem);
      setFormData(updatedFormData);
    
      // Clear the error for the item field being updated
      setFormValidationErrors((prevErrors) => ({
        ...prevErrors,
        [`item_${index}`]: '', 
      }));
    };
    


    const handleInputChange = (e, index, type) => {
        const updatedData = { ...formData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'damagepartsitem_set' && name === 'qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }

        // Remove the error message for this field
        setFormValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if (type === 'damagepartsitem_set' && name === 'qty') {
              delete newErrors[`qty_${index}`];
          } else {
              delete newErrors[name];
          }
          return newErrors;
        });

      
        if (type === 'damagepartsitem_set') {
          setRequiredMessage('');
          updatedData.damagepartsitem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setFormData(updatedData);
    };
      
    
      const areAllFieldsFilled = () => {
        return (
            formData.damagepartsitem_set &&
            formData.damagepartsitem_set.every((item) => {
                return item?.item !== null && item?.qty !== '';
            })
        );
    };
    
      
    const handleAddItem = () => {
        if (areAllFieldsFilled()) {
            setFormData((prevState) => ({
                ...prevState,
                damagepartsitem_set: [
                    ...prevState.damagepartsitem_set,
                    {
                        item: null,
                        qty: '',
                    },
                ],
            }));
            setRequiredMessage('');
        } else {
            setRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    

  const handleRemoveItem = (index) => {
    // Create a copy of the damagepartsitem_set array
    const damagepartsitem_set = [...formData.damagepartsitem_set];
  
    const lastIndex = damagepartsitem_set.length - 1;

    const lastItemIsNull = damagepartsitem_set[lastIndex] && damagepartsitem_set[lastIndex].item === null;
    

    if (index === lastIndex) {
      damagepartsitem_set.splice(lastIndex, 1);
    } 
    else if (index !== lastIndex && lastItemIsNull) {
      ShowToast('error','Cannot delete this item because last item is null.');
      return;
    }
    else if (index !== lastIndex && lastItemIsNull === false) {
      damagepartsitem_set.splice(index, 1);
    }
  
    const updatedSelectedItem = selectedItem.filter((_, i) => i !== index);
  
    setRequiredMessage('');
  
    setFormData({
      ...formData,
      damagepartsitem_set,
    });
    setSelectedItem(updatedSelectedItem);
  };
  
  
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateFormData();
        setFormValidationErrors(validationErrors);
      

        if (Object.keys(formValidationErrors).length === 0) {
          // No validation errors, proceed with form submission
          console.log("Form data is valid, submit the form");
          // Submit your form here
        } else {
          // There are validation errors
          console.log("Form validation failed", formValidationErrors);
          // Handle the errors (e.g., display error messages in the UI)
        }

        

        try {
            const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/damage-parts/`, formData);
    
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Submit');
                navigate('/damage-parts-list/');
            }
        } catch (error) {
            console.log(error);
        }
    };



    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData(`inventory/api/damage-parts`, {id});


    useEffect(()=>{
          if(detailsData){
              setSelectedUser(detailsData?.dp_return_user? {
                value: detailsData?.dp_return_user,
                label: detailsData?.return_by,
              } : null);




              setSelectedItem(detailsData?.damagepartsitem_set?.map((data) => ({
                  value: data.item,
                  label: data.item_name,
              })));


            setFormData(detailsData);
            setFormData(prevState => ({ ...prevState, source_type: detailsData.source_type }))

          }
    },[detailsData])



    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if(id){

          const validationErrors = validateFormData();
          setFormValidationErrors(validationErrors);
        
  
          if (Object.keys(formValidationErrors).length === 0) {
            // No validation errors, proceed with form submission
            console.log("Form data is valid, submit the form");
            // Submit your form here
          } else {
            // There are validation errors
            console.log("Form validation failed", formValidationErrors);
            // Handle the errors (e.g., display error messages in the UI)
          }

          try {
              const response = await authAxiosWithBearer.put(`${baseURL}/inventory/api/damage-parts/${id}/`, formData);
      
              if (response) {
                  console.log(response);
                  ShowToast('success', 'Successfully Submit');
                  navigate('/damage-parts-list/');
              }
          } catch (error) {
              console.log(error);
          }
        };
    }




  return {
    navigate,
    requiredMessage,
    formValidationErrors,
    userOptions,
    selectedUser,
    handleUserSearchInputChange,
    handleUserChange,
    SourceTypeOption,
    handleSourceTypeChange,
    itemOptions,
    selectedItem,
    itemSearchInputValue,
    formData,
    handleItemChange,
    handleItemSearchInputChange,
    handleInputChange,
    areAllFieldsFilled,
    handleAddItem,
    handleRemoveItem,
    handleSubmit,
    handleEditSubmit

  }
}

export default DamagePartsFormHandleCom