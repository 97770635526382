import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'

const PackageSRDetailsCom = ({detailsData,openModal,user}) => {

  return (
    <>
        <h1 className='border-b pb-2 mx-4 mb-3 font-semibold'>Requisition Basic Information</h1>
            <div className="grid md:grid-cols-2 lg:grid-cols-2  mx-4 my-2 gap-x-16 gap-y-2"> 

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Ticket
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.ticket_no}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                     Requested By
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.created_by_text}
                    </div> 
                </div>


                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                        Requested Date
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.created_at?formatDateTime(detailsData?.created_at):''}
                    </div> 
                </div>

                <div className='flex flex-wrap px-2 py-1 border shadow'>
                    <div className='w-[45%]'>
                    Receive By
                    </div>
                    <div className="w-[2%]">
                        -
                    </div>
                    <div className='w-[53%] text-end'>
                        {detailsData?.technician_employee_id}
                    </div> 
                </div>

            </div>

            <div className="mx-4 mt-5">
                <h1 className='border-b pb-2 font-semibold'>Requisition Package</h1>
                {detailsData?.packageassignedtotechnician_set?.map((data)=>(
                    <div className="grid md:grid-cols-2 my-2 gap-x-32 gap-y-2 border-b pb-2 ">
                        <div className='flex flex-wrap px-2 py-1'>
                            <div className='w-[45%]'>
                                Package Name
                            </div>
                            <div className="w-[2%]">
                                -
                            </div>
                            <div className='w-[53%] text-end'>
                                {data?.package_name}
                            </div> 
                        </div>
                        <div className='flex flex-wrap px-2 py-1 '>
                            <div className='w-[45%]'>
                                Requested Qty
                            </div>
                            <div className="w-[2%]">
                                -
                            </div>
                            <div className='w-[53%] text-end'>
                                {data?.package_quantity}
                            </div> 
                        </div>

                        <div className='flex flex-wrap px-2 py-1 '>
                            <div className='w-[45%]'>
                               Issue Status
                            </div>
                            <div className="w-[2%]">
                                -
                            </div>
                            <div className='w-[53%]'>
                                {data.sr_issued === true ?
                                <div className='text-green-500 font-bold px-1 text-end'>Issued</div>
                                :
                                <div className='text-red-500 font-bold  px-1 text-end'>Raised</div>
                                }
                            </div> 
                        </div>
                        <div className='flex flex-wrap px-2 py-1 '>
                            <div className='w-[45%]'>
                                {data.sr_issued === true ?
                                    <p>Issued Date</p>
                                :
                                    <p>Issue Now</p>
                                }
                            </div>
                            <div className="w-[2%]">
                                -
                            </div>
                            <div className='w-[53%]'>
                                {data.sr_issued === true ?
                                <div className='px-1 text-end'>{data.isssued_date?formatDateTime(data.isssued_date):''}</div>
                                :
                                <div className='text-blue-500 px-1 text-end'>
                                {user?.menu_permissions?.includes("3.6.1")? 
                                <button
                                  onClick={() => openModal(data?.package, data.package_quantity, data.id)}
                                  className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                                  >
                                  ISSUE
                                </button>
                                :<>No Permission</>
                                }
                                </div>
                                }
                            </div> 
                        </div>
                    </div>
                ))}

            </div>
    </>
  )
}

export default PackageSRDetailsCom