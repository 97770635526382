import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'
import ItemWiseStockReportListComponent from './ItemWiseStockReportListComponent'

const ItemDetailsComponents = ({detailsData}) => {
    return (
        <>
            <div className='border border-[#EB5757] sm:mx-3 my-3' >

                {/* 1st content */}
                <div className='bg-[#FDECEC] my-1'>
                    <div className='py-2 text-[#E74A3B] pl-2 text-[20px] font-semibold uppercase'>item DETAILS</div>
                </div>

                {/* 2nd */}

                <div className='border border-[#EB5757] mx-1 my-2'>

                    <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                        <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                            <div className='bg-[#EB5757] '>

                                <div className='flex sm:justify-center sm:items-center px-2 sm:py-20  '>
                                    <div className='text-[#FFFFFF] text-[16px] '>
                                        <div className='sm:text-center'>{detailsData?.item_name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                            <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                                <div className='mx-1 sm:mx-0'>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Current Stock</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.stock_available_qty}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Issued Return Stock</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.issued_return_return_stock_qty}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Damage Parts Stock</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.damage_parts_return_stock_qty}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Item Code</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.item_code}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Category</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.category_text}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Sub Category</div>
                                            <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{detailsData?.sub_category_text}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='flex '>
                                            <div className='w-[35%] sm:w-[30%] md:w-[50%] lg:w-[35%]'>Brand</div>
                                            <div className='w-[8%] sm:w-[5%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[65%] md:w-[42%] lg:w-[49%]'>{detailsData?.brand}</div>

                                        </div>
                                    </div>

                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Model</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{detailsData?.model}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* 2nd */}

                                <div className='mx-1 sm:mx-0'>
                                    <div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Sales Price (Customer)</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.item_sales_price}</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Sales Price (HO)</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.item_sales_price_for_ho}</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>UOM</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>
                                                    {detailsData?.uom_text}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Specification</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>
                                                    {detailsData?.specification}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Size</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.size}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex'>
                                                <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Parts No</div>
                                                <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                                <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.parts_no}</div>
                                            </div>
                                        </div>
                                        <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Origin</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[8%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[42%] lg:w-[49%]'>{detailsData?.origin}</div>
                                        </div>
                                    </div>

                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>
                </div>


                {/* 3rd */}

                <div className='flex sm:flex-row flex-col'>
                    <div className='mx-1 my-2'>
                        <ItemWiseStockReportListComponent item_id={detailsData.id} />
                    </div>
                </div>

                {/* <div className='flex justify-center my-3 '>
                    <button className='bg-[#FA6669] text-[#FFF] px-6 py-2 rounded-md' onClick={pdfGeneretor}>Print</button>
                </div> */}
            </div>
        </>
    )
}

export default ItemDetailsComponents