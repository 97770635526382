import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../Components/formatDateTime';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import ListPageHeadWithDateRange from '../../../Components/PageHeadComponents/ListPageHeadWithDateRange';
import useFetchListData from '../../../hooks/useFetchListData';
import CustomPaginator from '../../../Components/CustomPaginator';

const CompletedDeliveryAssignedTransportList = ({user}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);


  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`/in_house/api/completed-delivery-assigned-transport-list/`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, endDate,startDate]);


  
  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    ticket_no: data?.ticket_no,
    transport_user_employee_id:data?.delivery_transport_user_employee_id, 
    transport_user_name:data?.delivery_transport_user_name,
    transport_user_phone: data?.delivery_transport_user_phone,
    transport_assign_status: data?.transport_assign_status,
    product_received_status: data?.product_received_status,
    created_at: data?.created_at,
    assigned_technician_status:data?.assignedtechnicianforcompleteddeliveryresource_set[0]?.assigned_status,
    assigned_technician_date:data?.assignedtechnicianforcompleteddeliveryresource_set[0]?.created_at,
  }))


  
  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: '1',
    },
    {
        title: 'Transport User',
        dataIndex: 'transport_user_employee_id',
        key: '2',
    },
    {
        title: 'Transport User Name',
        dataIndex: 'transport_user_name',
        key: '3',
    },
    {
        title: 'Transport User Phone',
        dataIndex: 'transport_user_phone',
        key: '4',
        responsive: ['md']
    },
    {
      title: 'A.Technician Status',
      dataIndex: 'assigned_technician_status',
      key: '5',
      render: (text, record) => {
        const statusClass = record?.assigned_technician_status === 'Job Assigned' 
          ? 'bg-red-500' 
          : record?.assigned_technician_status === 'Job Started' 
          ? 'bg-blue-500' 
          : record?.assigned_technician_status === 'Job Completed' 
          ? 'bg-green-500' 
          : 'bg-transparent';
  
        return (
          <div className={`px-2 py-1 w-[136px] rounded text-white text-center ${statusClass}`}>
              <div>{record?.assigned_technician_status} </div>
          </div>
        );
      },
    },
    {
      title: 'Assigned At',
      dataIndex: 'assigned_technician_date',
      key: '5',
      render: (assigned_technician_date) => {
        const formattedDateTime = assigned_technician_date? formatDateTime(assigned_technician_date):'';
        return <span>{formattedDateTime}</span>;
      },
    },
    {
        title: 'ACTIONS',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className='flex gap-2'>
            {user?.menu_permissions?.includes("6.1.2") && (
              <button onClick={() => navigate(`/assigned-transport-for-delivery-details/${record.id}/`)} className='rounded'>
                <AiFillEye className='text-[#6969f5] text-xl' />
              </button>
            )}
          </div>
        ),
      },
  ];


  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
        <ListPageHeadWithDateRange 
            pageTitle = {'Completed Delivery Assigned Transport List'}
            setEndDate = {setEndDate}
            endDate = {endDate}
            setStartDate = {setStartDate}
            startDate = {startDate}
            setSearchText = {setSearchText}
            // addNewURL = {}
            // addNewUrlPermissionCode = {}
        />
 

      <div className='shadow-lg overflow-x-auto'>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
              <CustomPaginator 
                  setCurrentPage={setCurrentPage} 
                  currentPage={currentPage}
                  totalItems={totalItems}
              />
        </div>
      </>
      )}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(CompletedDeliveryAssignedTransportList);
