import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { format } from 'date-fns';

const TicketCreateComponent = () => {
    const navigate = useNavigate()
    const jwtToken = localStorage.getItem('token')
    const [phoneSearchResultCustomerId, setPhoneSearchResultCustomerId] = useState('');
    const user = useSelector(state => state.auth.user);
    const [autoSelectOnlineQuery, setAutoSelectOnlineQuery] = useState(false);
    const [autoSelectDealershipQuery, setAutoSelectDealershipQuery] = useState(false);
    const [autoSelectHirePurchase, setAutoSelectHirePurchase] = useState(false);
    const [autoCorporatePurchase, setAutoSelectCorporatePurchase] = useState(false);
    const [autoHVAC, setAutoSelectHVAC] = useState(false);
    const [autoIJCR, setAutoSelectIJCR] = useState(false);
    const [autoSCBCC, setAutoSCBCC] = useState(false)
    const [autoTelesales, setAutoTelesales] = useState(false)
    const [autoHappyCall, setAutoHappyCall] = useState(false)
    const [autoNonPersonalCRM, setAutoNonPersonalCRM] = useState(false)
    const [autoOnlineSpecific, setAutoOnlineSpecific] = useState(false)
    const [autInboundCRM, setAutInboundCRM] = useState(false)
    const [autoSelectOutboundCRM, setAutoSelectOutboundCRM] = useState(false)

    const [dqProductBrandOptions, setdqProductBrandOptions] = useState([])
    

    const [oqProductBrandOptions, setOqProductBrandOptions] = useState([])
    const [obProductBrandOptions, setObProductBrandOptions] = useState([])

    const [hpProductCategoryOption, setHpProductCategoryOption] =useState([]);
    const [cpProductCategoryOption, setCpProductCategoryOption] =useState([]);
    const [ibProductCategoryOption, setIbProductCategoryOption] =useState([]);
    const [ibShowroomOption, setIbShowroomOption] =useState([]);
    const [sbProductBrandOptions, setSbProductBrandOptions] = useState([])
    const [ibProductBrandOptions, setIbProductBrandOptions] = useState([])
    const [sbProductCategoryOption, setSbProductCategoryOption] =useState([]);
    const [tsProductBrandOptions, setTsProductBrandOptions] = useState([])
    const [tsProductCategoryOption, setTsProductCategoryOption] =useState([]);
    const [showroomOption, setShowroomOption] =useState([]);
    const [ticketCreateLoding, setTicketCreateLoding] = useState(false)
    const [invCustomerPhoneNo,setInvCustomerPhoneNo] = useState(null)


    const [ticketFormData,setTicketFormData] = useState({
      exist_customer:null,
      description: null,
      category: [],
      customer_interest:null,
      oq_product_brand: null,
      oq_source_info: null,

      call_remarks: null,
      sms_sent:  false,
      raise_job:  false,
      remarks:  '',
      sms_body: '',
      sms_formate: null,
      related_ticked: null,

      shop_name: '',
      dq_product_brand: null,
      dq_address:'',
      dq_email: '',

      professional_details:'',
      hp_product_category : null,

      cp_company_name :'',
      cp_product_category: null,
      aproximate_quantity:'',

      hvac_company_name : '',
      hvac_address : '',
      hvac_email : '',
      capacity:'',

      ib_invoice_no : '',         
      ib_product_category : null,    
      model_no : '',            
      quantity : '',            
      other_info : '',
      ib_basic_charge_title : '',
      ib_basic_charge_amount : '',


      ib_invoice_date : null,
      ib_dealer_name : '',
      ib_showroom : null,
      ib_product_brand : null,
      requested_type : '',
      
      sb_invoice_no : '',
      showroom  : null,          
      sb_product_category : null,    
      sb_product_brand : null,      
      model_name    : '',      
      other_details : '',
      service_address:'',      
      is_dealer : false,          
      dealer_name : '',       
      purchase_date  : null,
      sb_basic_charge_title : '',
      sb_basic_charge_amount : '',
      warranty_exp_date : null,
      warranty_status: false,
      

      item_in_household : '',
      sales_prospect : null, 
      sale_new_product : null, 
      t_product_brand  : null,  
      customer_complain : null, 
      is_discount : false,
      color : '',  
      new_purchase_interest:false,
      follow_up_date : null,    
      appointment_date : null,

      

      call_type  : '',
      prv_purchased_prod : '',
      showroom_rating : null,
      staf_behave_rating : null,
      prod_delevery_rating : null,
      prod_delevery_exp : '',
      installation_rating : null,
      installation_exp : '',
      service_center_rating : null,
      service_center_exp : '',
      website_rating : null,
      website_exp : '',
      delevery_corection : false,
      suggest_other : false,
      suggest_exp : '',
      has_complain : false,
      complain_details : '',
      unnecessary_charge : false,
      gift_item : false,
      installation_charge : false,
      discount : false,
      accessories_sale : false,
      product_choise_from : null,
      source_info : null,
      prefer_payment : null,
      purchase_procedure_rating : null,
      purchase_exp : '',
      encourage_fnf : false,
      encourage_fnf_exp : '',
      future_possibility : false,
      overall_happiness_rating : null,

      // outbound
      ob_product_brand : null,
      ob_staff_name : '',
      ob_option_to :null,
      ob_department :null,
      ob_query_type :null,
    })
    

    const handlePhoneSearchResult = (customerId) => {
      setPhoneSearchResultCustomerId(customerId);
    
      // Update the ticketFormData with the customerId
      setTicketFormData((prevFormData) => ({
        ...prevFormData,
        exist_customer: customerId,
      }));
    };



    const ratingOption = [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ];

    const YesOrNoOption = [
      { value: 0, label: 'No' },
      { value: 1, label: 'Yes' },
    ];


    // fetchProductBrandData
    useEffect(() => {
      const fetchProductBrandData = async () => {
          try {
          const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/get-product-brand/`);
          // console.log(response)
          setdqProductBrandOptions( response.data.map((data) => ({
              value: data.id,
              label: data.name,
          })));
          setOqProductBrandOptions( response.data.map((data) => ({
            value: data.id,
            label: data.name,
          })));
          setObProductBrandOptions( response.data.map((data) => ({
            value: data.id,
            label: data.name,
          })));

        setSbProductBrandOptions( response.data.map((data) => ({
          value: data.id,
          label: data.name,
        })));

        setIbProductBrandOptions( response.data.map((data) => ({
          value: data.id,
          label: data.name,
        })));

        setTsProductBrandOptions( response.data.map((data) => ({
          value: data.id,
          label: data.name,
        })));

          } catch (error) {
              console.log(error)
          }
      };
      fetchProductBrandData();
    }, [jwtToken]);


    // fetchProductCategoryData
    useEffect(() => {
      const fetchProductCategoryData = async () => {
          try {
          const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/get-product-category/`);
            setHpProductCategoryOption( response.data.map((data) => ({
              value: data.id,
              label: data.name,
          })));

          setCpProductCategoryOption( response.data.map((data) => ({
            value: data.id,
            label: data.name,
          })));

          setIbProductCategoryOption( response.data.map((data) => ({
            value: data.id,
            label: data.name,
          })));

          setSbProductCategoryOption( response.data.map((data) => ({
            value: data.id,
            label: data.name,
          })));

          setTsProductCategoryOption( response.data.map((data) => ({
            value: data.id,
            label: data.name,
          })));

          } catch (error) {
              console.log(error)
          }
      };
      fetchProductCategoryData();
    }, [jwtToken]);


    // fetchShowroomData
    useEffect(() => {
      const fetchShowroomData = async () => {
          try {
          const response = await authAxiosWithBearer.get(`${baseURL}/api/get_showroom/`);
            setShowroomOption( response.data.map((data) => ({
              value: data.id,
              label: data.address_1,
            })));

            setIbShowroomOption( response.data.map((data) => ({
              value: data.id,
              label: data.address_1,
            })));

          } catch (error) {
              console.log(error)
          }
      };
      fetchShowroomData();
    }, [jwtToken]);
    


    const handleTicketInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      const inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;
      setTicketFormData((prevFormData) => ({
        ...prevFormData,
        [name]: inputValue,
      }));
    };
    

    useEffect(() => {
      setAutoSelectOnlineQuery(
        ticketFormData.customer_interest !== null ||
        ticketFormData.oq_product_brand !== null ||
        ticketFormData.oq_source_info !== null
      )
      setAutoSelectDealershipQuery(
        ticketFormData.dq_product_brand !== null ||
        ticketFormData.shop_name !== '' ||
        ticketFormData.dq_address !== '' ||
        ticketFormData.dq_email !== ''
        );
      setAutoSelectHirePurchase(
        ticketFormData.professional_details !== '' ||
        ticketFormData.hp_product_category  !==  null
        )
      setAutoSelectCorporatePurchase(
        ticketFormData.cp_company_name  !== '' ||
        ticketFormData.cp_product_category !== null ||
        ticketFormData.aproximate_quantity !== ''
      )
      setAutoSelectHVAC(
        ticketFormData.hvac_company_name !== ''||
        ticketFormData.hvac_address !== ''||
        ticketFormData.hvac_email !== ''||
        ticketFormData.capacity !== ''
      )
      setAutoSelectIJCR(
        ticketFormData.ib_invoice_no !== ''||          
        ticketFormData.ib_product_category !== null ||    
        ticketFormData.model_no !== '' ||            
        ticketFormData.quantity !== '' ||            
        ticketFormData.other_info !== '' ||

        ticketFormData.ib_invoice_date !==  null ||
        ticketFormData.ib_dealer_name !==  '' ||
        ticketFormData.ib_showroom !==  null ||
        ticketFormData.ib_product_brand !==  null ||
        ticketFormData.requested_type !==  ''   ||
        ticketFormData.ib_basic_charge_title !== '' || 
        ticketFormData.ib_basic_charge_amount !== ''                   
      )
      setAutoSCBCC(
        ticketFormData.sb_invoice_no !== '' ||
        ticketFormData.showroom  !== null ||          
        ticketFormData.sb_product_category !== null || 
        ticketFormData.sb_product_brand !== null ||      
        ticketFormData.model_name    !== ''||     
        ticketFormData.other_details !== ''||
        ticketFormData.service_address !== '' ||     
        ticketFormData.is_dealer !== false ||         
        ticketFormData.dealer_name  !== ''||       
        ticketFormData.purchase_date !== null ||
        ticketFormData.sb_basic_charge_title !== '' ||
        ticketFormData.sb_basic_charge_amount !== '' ||
        ticketFormData.warranty_exp_date !== null ||
        ticketFormData.warranty_status !== false
           
      )

      setAutoTelesales(
        ticketFormData.item_in_household !== '' ||
        ticketFormData.sales_prospect !== null || 
        ticketFormData.sale_new_product !== null || 
        ticketFormData.t_product_brand  !== null ||  
        ticketFormData.customer_complain !== null || 
        ticketFormData.new_purchase_interest !== false ||
        ticketFormData.is_discount !== false ||
        ticketFormData.color !== ''  ||
        ticketFormData.follow_up_date !== null ||    
        ticketFormData.appointment_date !== null
      )

      setAutoHappyCall(
      ticketFormData.call_type  !== '' ||
      ticketFormData.prv_purchased_prod !== '' ||
      ticketFormData.showroom_rating !== null ||
      ticketFormData.staf_behave_rating !== null ||
      ticketFormData.prod_delevery_rating !== null ||
      ticketFormData.prod_delevery_exp !== '' ||
      ticketFormData.installation_rating !== null ||
      ticketFormData.installation_exp !== '' ||
      ticketFormData.service_center_rating !== null ||
      ticketFormData.service_center_exp !== '' ||
      ticketFormData.website_rating !== null ||
      ticketFormData.website_exp !== '' ||
      ticketFormData.delevery_corection !== false ||
      ticketFormData.suggest_other !== false ||
      ticketFormData.suggest_exp !== '' ||
      ticketFormData.has_complain !== false ||
      ticketFormData.complain_details !== '' ||
      ticketFormData.unnecessary_charge !== false ||
      ticketFormData.gift_item !== false ||
      ticketFormData.installation_charge !== false ||
      ticketFormData.discount !== false ||
      ticketFormData.accessories_sale !== false ||
      ticketFormData.product_choise_from !== null ||
      ticketFormData.source_info !== null ||
      ticketFormData.prefer_payment !== null ||
      ticketFormData.purchase_procedure_rating !== null ||
      ticketFormData.purchase_exp !== '' ||
      ticketFormData.encourage_fnf !== false ||
      ticketFormData.encourage_fnf_exp !== '' ||
      ticketFormData.future_possibility !== false ||
      ticketFormData.overall_happiness_rating !== null
      )

      setAutoNonPersonalCRM(
        ticketFormData.call_type  !== '' ||
        ticketFormData.prv_purchased_prod !== '' ||
        ticketFormData.showroom_rating !== null ||
        ticketFormData.staf_behave_rating !== null ||
        ticketFormData.prod_delevery_rating !== null ||
        ticketFormData.prod_delevery_exp !== '' ||
        ticketFormData.installation_rating !== null ||
        ticketFormData.installation_exp !== '' ||
        ticketFormData.service_center_rating !== null ||
        ticketFormData.service_center_exp !== '' ||
        ticketFormData.delevery_corection !== false ||
        ticketFormData.suggest_other !== false ||
        ticketFormData.suggest_exp !== '' ||
        ticketFormData.has_complain !== false ||
        ticketFormData.complain_details !== '' ||
        ticketFormData.unnecessary_charge !== false ||
        ticketFormData.gift_item !== false ||
        ticketFormData.installation_charge !== false ||
        ticketFormData.discount !== false ||
        ticketFormData.accessories_sale !== false ||
        ticketFormData.product_choise_from !== null ||
        ticketFormData.source_info !== null ||
        ticketFormData.prefer_payment !== null
      )
      setAutoOnlineSpecific(
        ticketFormData.website_rating !== null ||
        ticketFormData.website_exp !== '' ||
        ticketFormData.purchase_procedure_rating !== null ||
        ticketFormData.purchase_exp !== '' ||
        ticketFormData.encourage_fnf !== false ||
        ticketFormData.encourage_fnf_exp !== '' ||
        ticketFormData.future_possibility !== false ||
        ticketFormData.overall_happiness_rating !== null
      )
      setAutoSelectOutboundCRM(
        ticketFormData.ob_product_brand !=  null ||
        ticketFormData.ob_staff_name !=  '' ||
        ticketFormData.ob_option_to != null ||
        ticketFormData.ob_department != null ||
        ticketFormData.ob_query_type != null
      )
    }, 
    [
      ticketFormData.customer_interest,
      ticketFormData.oq_product_brand, 
      ticketFormData.oq_source_info,
      ticketFormData.dq_product_brand,
      ticketFormData.shop_name,
      ticketFormData.dq_address,
      ticketFormData.dq_email,
      ticketFormData.professional_details,
      ticketFormData.hp_product_category,
      ticketFormData.cp_company_name,
      ticketFormData.cp_product_category,
      ticketFormData.aproximate_quantity,
      ticketFormData.hvac_company_name ,
      ticketFormData.hvac_address ,
      ticketFormData.hvac_email ,
      ticketFormData.capacity,

      ticketFormData.ib_invoice_no,          
      ticketFormData.ib_product_category ,  
      ticketFormData.model_no,             
      ticketFormData.quantity,           
      ticketFormData.other_info,
      ticketFormData.ib_basic_charge_title,
      ticketFormData.ib_basic_charge_amount,

      ticketFormData.ib_invoice_date ,
      ticketFormData.ib_dealer_name,
      ticketFormData.ib_showroom ,
      ticketFormData.ib_product_brand ,
      ticketFormData.requested_type,

      ticketFormData.sb_invoice_no,
      ticketFormData.showroom,         
      ticketFormData.sb_product_category, 
      ticketFormData.sb_product_brand,      
      ticketFormData.model_name,     
      ticketFormData.other_details,  
      ticketFormData.service_address,   
      ticketFormData.is_dealer ,         
      ticketFormData.dealer_name,      
      ticketFormData.purchase_date ,
      ticketFormData.sb_basic_charge_title,
      ticketFormData.sb_basic_charge_amount,
      ticketFormData.warranty_exp_date,
      ticketFormData.warranty_status,

      ticketFormData.item_in_household ,
      ticketFormData.sales_prospect, 
      ticketFormData.sale_new_product, 
      ticketFormData.t_product_brand ,  
      ticketFormData.customer_complain, 
      ticketFormData.is_discount,
      ticketFormData.new_purchase_interest,
      ticketFormData.color,
      ticketFormData.follow_up_date,    
      ticketFormData.appointment_date,

      ticketFormData.call_type  ,
      ticketFormData.prv_purchased_prod ,
      ticketFormData.showroom_rating ,
      ticketFormData.staf_behave_rating ,
      ticketFormData.prod_delevery_rating ,
      ticketFormData.prod_delevery_exp ,
      ticketFormData.installation_rating ,
      ticketFormData.installation_exp ,
      ticketFormData.service_center_rating ,
      ticketFormData.service_center_exp ,
      ticketFormData.website_rating ,
      ticketFormData.website_exp ,
      ticketFormData.delevery_corection ,
      ticketFormData.suggest_other ,
      ticketFormData.suggest_exp ,
      ticketFormData.has_complain ,
      ticketFormData.complain_details,
      ticketFormData.unnecessary_charge ,
      ticketFormData.gift_item ,
      ticketFormData.installation_charge ,
      ticketFormData.discount ,
      ticketFormData.accessories_sale,
      ticketFormData.product_choise_from ,
      ticketFormData.source_info ,
      ticketFormData.prefer_payment,
      ticketFormData.purchase_procedure_rating,
      ticketFormData.purchase_exp, 
      ticketFormData.encourage_fnf,
      ticketFormData.encourage_fnf_exp,
      ticketFormData.future_possibility,
      ticketFormData.overall_happiness_rating,

      ticketFormData.ob_product_brand,
      ticketFormData.ob_staff_name,
      ticketFormData.ob_option_to,
      ticketFormData.ob_department,
      ticketFormData.ob_query_type,

    ]);


    useEffect(()=>{
      setAutInboundCRM(
        autoSelectDealershipQuery !== false ||
        autoSelectOnlineQuery !== false ||
        autoSelectHirePurchase !== false ||
        autoCorporatePurchase !== false ||
        autoHVAC !== false ||
        autoIJCR !== false ||
        autoSCBCC !== false
      )
    },[
        autoSelectDealershipQuery, 
        autoSelectOnlineQuery, 
        autoSelectHirePurchase,
        autoCorporatePurchase,
        autoHVAC,
        autoIJCR,
        autoSCBCC,
    ])

    
    const determineCategory = () => {
      const categoryNumbers = [];

      if (autoSelectOnlineQuery === true) {
        categoryNumbers.push(1);
      }
      if (autoSelectDealershipQuery === true) {
        categoryNumbers.push(2);
      }
      if (autoSelectHirePurchase === true) {
        categoryNumbers.push(3);
      }
      if (autoCorporatePurchase === true) {
        categoryNumbers.push(4);
      }
      if (autoHVAC === true) {
        categoryNumbers.push(5);
      }
      if (autoIJCR === true) {
        categoryNumbers.push(6);
      }
      if (autoSCBCC === true) {
        categoryNumbers.push(7);
      }
      if (autoTelesales === true) {
        categoryNumbers.push(8);
      }
      if (autoHappyCall === true) {
        categoryNumbers.push(9);
      }
      if (autoSelectOutboundCRM === true) {
        categoryNumbers.push(10);
      }
      if (categoryNumbers.length === 0) {
        categoryNumbers.push(11);
      }
      return categoryNumbers;
    };

    useEffect(() => {
      const newCategory = determineCategory();
      setTicketFormData((prevFormData) => ({
        ...prevFormData,
        category: newCategory,
      }));
    }, [autoSelectDealershipQuery, 
        autoSelectOnlineQuery, 
        autoSelectHirePurchase,
        autoCorporatePurchase,
        autoHVAC,
        autoIJCR,
        autoSCBCC,
        autoTelesales,
        autoHappyCall,
        autoSelectOutboundCRM,
        
    ]);


    const handleTicketSubmit = async (e) => {
      e.preventDefault();

      if (!ticketFormData.exist_customer) {
        ShowToast('info', 'Please Enter Customer Informations');
        return; // Stop form submission
      }

      // ijcr required filed check
      if (
        autoIJCR === true && 
        !ticketFormData.ib_invoice_no &&         
        !ticketFormData.ib_product_category &&    
        !ticketFormData.model_no &&            
        !ticketFormData.quantity &&            
        !ticketFormData.ib_basic_charge_title &&
        !ticketFormData.ib_basic_charge_amount &&
        !ticketFormData.ib_invoice_date &&
        !ticketFormData.ib_product_brand &&
        !ticketFormData.requested_type 
      ) {
        ShowToast('error', 'Please Enter All IJCR Required Fileds');
        return;
      }

      if (
        autoSCBCC === true && 
        !ticketFormData.sb_invoice_no &&     
        !ticketFormData.sb_product_category &&   
        !ticketFormData.sb_product_brand &&      
        !ticketFormData.model_name &&      
        !ticketFormData.other_details &&
        !ticketFormData.service_address &&              
        !ticketFormData.purchase_date &&
        !ticketFormData.sb_basic_charge_title &&
        !ticketFormData.sb_basic_charge_amount &&
        !ticketFormData.warranty_exp_date &&
        ticketFormData.warranty_status === false
      ) {
        ShowToast('error', 'Please Enter All SCBCC Required Fileds');
        return;
      }
      if(
        !ticketFormData.call_remarks &&
        !ticketFormData.sms_sent &&
        !ticketFormData.raise_job &&
        !ticketFormData.remarks &&
        !ticketFormData.sms_body &&
        !ticketFormData.sms_formate
      ){
        ShowToast('error', 'Please Enter All SCBCC Required Fileds');
        return;
      }


      setTicketCreateLoding(true)
      try {
        const formattedPurchaseDate = 
          ticketFormData.purchase_date ? format(ticketFormData.purchase_date, 'yyyy-MM-dd') : null;
        const formattedAppointmentDate = 
          ticketFormData.appointment_date ? format(ticketFormData.appointment_date, 'yyyy-MM-dd') : null;
        const formattedFollowuUpDate = 
          ticketFormData.follow_up_date ? format(ticketFormData.follow_up_date, 'yyyy-MM-dd') : null;
        const formattedIbInvoiceDate = 
          ticketFormData.ib_invoice_date ? format(ticketFormData.ib_invoice_date, 'yyyy-MM-dd') : null;
    
        const formattedSbWarrantyExpDate = 
          ticketFormData.warranty_exp_date ? format(ticketFormData.warranty_exp_date, 'yyyy-MM-dd') : null;
    
        const formWithDate = {
          ...ticketFormData,
          purchase_date: formattedPurchaseDate,
          appointment_date: formattedAppointmentDate,
          follow_up_date: formattedFollowuUpDate,
          ib_invoice_date: formattedIbInvoiceDate,
          warranty_exp_date:formattedSbWarrantyExpDate,
          aproximate_quantity:ticketFormData.aproximate_quantity === '' ? null :ticketFormData.aproximate_quantity,
          quantity:ticketFormData.quantity === '' ? null :ticketFormData.quantity,
          capacity:ticketFormData.capacity === '' ? null :ticketFormData.capacity,
          call_type:autoOnlineSpecific === true? 2 : autoNonPersonalCRM === true? 1 : autoNonPersonalCRM === true && autoOnlineSpecific === true? 1 : ''
        };
    
        const response = await authAxiosWithBearer.post(`${baseURL}/ccd/api/ticket/`,formWithDate);
    
        if (response) {
          // console.log(response);
          if(user?.primary_role === 7){
            navigate('/success-response')
          }else{
            navigate('/ticket-list');
          }
          ShowToast('success', 'Successfully Add');
        }
      } catch (error) {
        if (error) {
          ShowToast('error', `${error}`);
          setTicketCreateLoding(false)
        } else {
          console.error('Error submitting ticket:', error);
        }
      } finally {
        // Additional cleanup or actions if needed
      }
    };


    return (
        {
            navigate,
            jwtToken,
            phoneSearchResultCustomerId,
            setPhoneSearchResultCustomerId,
            user,
            autoSelectOnlineQuery,
            setAutoSelectOnlineQuery,
            autoSelectDealershipQuery,
            setAutoSelectDealershipQuery,
            autoSelectHirePurchase,
            setAutoSelectHirePurchase,
            autoCorporatePurchase,
            setAutoSelectCorporatePurchase,
            autoHVAC,
            setAutoSelectHVAC,
            autoIJCR,
            setAutoSelectIJCR,
            autoSCBCC,
            setAutoSCBCC,
            autoTelesales,
            setAutoTelesales,
            autoHappyCall,
            setAutoHappyCall,
            autoNonPersonalCRM,
            setAutoNonPersonalCRM,
            autoOnlineSpecific,
            setAutoOnlineSpecific,
            autInboundCRM,
            setAutInboundCRM,
            setAutoSelectOutboundCRM,
            autoSelectOutboundCRM,
            dqProductBrandOptions,
            setdqProductBrandOptions,
            oqProductBrandOptions,
            setOqProductBrandOptions,
            hpProductCategoryOption,
            setHpProductCategoryOption,
            cpProductCategoryOption,
            setCpProductCategoryOption,
            ibProductCategoryOption,
            setIbProductCategoryOption,
            ibShowroomOption,
            setIbShowroomOption,
            sbProductBrandOptions,
            setSbProductBrandOptions,
            ibProductBrandOptions,
            setIbProductBrandOptions,
            sbProductCategoryOption,
            setSbProductCategoryOption,
            tsProductBrandOptions,
            setTsProductBrandOptions,
            obProductBrandOptions,
            setObProductBrandOptions,
            tsProductCategoryOption,
            setTsProductCategoryOption,
            showroomOption,
            setShowroomOption,
            ticketCreateLoding,
            setTicketCreateLoding,
            invCustomerPhoneNo,
            setInvCustomerPhoneNo,
            ticketFormData,
            setTicketFormData,
            handlePhoneSearchResult,
            ratingOption,
            YesOrNoOption,
            handleTicketInputChange,
            handleTicketSubmit,
        }
    )
}

export default TicketCreateComponent