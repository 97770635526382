import React from 'react'
import GeneralSRCreateFormCom from './GeneralSRComponents/GeneralSRCreateFormCom'

const GeneralSRCreate = () => {
  return (
    <div>
        <GeneralSRCreateFormCom />
    </div>
  )
}

export default GeneralSRCreate