import React, { useState } from 'react'
import InvoiceDetailsModal from '../../../Components/InvoiceDetailsModal';
import IjcrDetailsComponents from '../../CRM/TicketDetailsComponents/IjcrDetailsComponents';
import ScbccDetailsComponents from '../../CRM/TicketDetailsComponents/ScbccDetailsComponents';
import LoadingSpinner from '../../../Components/LoadingSpinner';

const AssignedTicketDetailsComponent = ({ticketData,loading}) => {
    
    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [selectedInvoiceID, setSelectedInvoiceID] = useState(null);
  
    const openInvoiceModal = (invoiceNo) => {
      setInvoiceModalOpen(true);
      setSelectedInvoiceID(invoiceNo);
    };
  
    const closeInvoiceModal = () => {
      setInvoiceModalOpen(false);
      setSelectedInvoiceID(null);
    };

    if(loading){
        return <LoadingSpinner />
      }

  return (
    <div>
            <div className="information-section my-5 p-1">
                <div className='information-head my-1 px-10 py-1'>
                    <div>Customer Information</div>
                </div>
                <div className="grid md:grid-cols-2 mx-2 my-2 md:mx-10 gap-x-16 gap-y-2">

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Customer Name
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                            {ticketData?.customer_name}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Phone
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                        {ticketData?.customer_phone}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Email
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                            {ticketData?.customer_email}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Address
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                            {ticketData?.customer_address}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Post Code
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                        {ticketData?.customer_post_code}
                        </div>
                    </div>

                </div>
            </div>



            <div className="information-section my-5 p-1">
                <div className='information-head my-1 px-10 py-1'>
                    <div className='capitalize'>Complaint - {ticketData?.category}</div>
                </div>

                <div className="flex justify-between mx-10 issue-category my-4">
                    <div>Service Category- Inbound CRM </div>
                    <div className='capitalize'>Ticket Category - {ticketData?.category} </div>
                </div>


                {ticketData?.installation && (
                    <IjcrDetailsComponents 
                    ijcrTicketData ={ticketData?.installation}
                    openInvoicedModal={openInvoiceModal}
                    />
                )}

                {ticketData?.service && (
                 <ScbccDetailsComponents 
                    scbccTicketData ={ticketData?.service}
                    openInvoicedModal={openInvoiceModal}
                 />
                )}
            </div>


        {/* invoiceDetails start */}
            <InvoiceDetailsModal
            isOpen={isInvoiceModalOpen}
            onClose={closeInvoiceModal}
            invoiceNo={selectedInvoiceID}
        />
        {/* invoiceDetails end */}
    </div>
  )
}

export default AssignedTicketDetailsComponent