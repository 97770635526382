import React, { useEffect, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import TransportAssignForDeliveryFormComponent from './TransportAssignForDeliveryFormComponent';


function TransportAssignForDeliveryModal(
  { 
    isOpen, 
    onClose,
    assigned_technicianid,
    transport_user_id,
    setModalOpen,
    fetchData,
    fetchTicketData,
    goodsCheckTechnicianFeedback_id
}) 
  {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Purchased Details Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className='mb-2 w-[350px] md:w-[350px] lg:w-[450px] overflow-y-auto h-[350px]'>
        <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
          <h1 className='font-semibold uppercase'>Transport Assign Form</h1>
          <button className='text-[#000]' onClick={onClose}><ImCancelCircle /></button>
        </div>

          <div>
              <TransportAssignForDeliveryFormComponent
              assigned_technicianid ={assigned_technicianid}
              transport_user_id = {transport_user_id}
              setModalOpen={setModalOpen} 
              fetchData={fetchData}
              fetchTicketData={fetchTicketData}
              goodsCheckTechnicianFeedback_id={goodsCheckTechnicianFeedback_id}
              />
          </div>
      </div>
    </Modal>
  );
}

export default TransportAssignForDeliveryModal;