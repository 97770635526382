import React from 'react'
import ATWorkingActivitiesLogFormHandleCom from './ATWorkingActivitiesLogFormHandleCom'
import FormPageTitle from '../../../../../Components/Form/FormPageTitle'
import InputFiledWC from '../../../../../Components/FormWithoutClass/InputFiledWC'
import TextareaInputWC from '../../../../../Components/FormWithoutClass/TextareaInputWC'
import LoadingSpinner from '../../../../../Components/LoadingSpinner'

const ATWorkingActivitiesLogFormCom = ({assigned_technician_id,setModalOpen}) => {
    const {
        navigate,
        loading,
        setLoading,
        formData,
        setFormData,
        handleInputChange,
        handleSubmit,
        } = ATWorkingActivitiesLogFormHandleCom({assigned_technician_id,setModalOpen})

    if(loading){
        return <LoadingSpinner />
    }
  return (
    <div>
    <div className='bg-[#fff] shadow-xl p-3 my-4 rounded-[5px] mt-10'>
    <form onSubmit={handleSubmit}>
         <div className="grid grid-cols-1 gap-x-4">
            <div className='my-4'>
                <InputFiledWC
                    type='text' 
                    value={formData?.manpower} 
                    onChange={handleInputChange} 
                    name='manpower' id='manpower'  
                    label='Manpower*' 
                    isRequired={true} 
                    placeholder='manpower'  
                    className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>
            <div className='my-4'>
                <TextareaInputWC 
                    type='text' 
                    value={formData?.remarks} 
                    onChange={handleInputChange} 
                    name='remarks' id='remarks'  
                    label='Remarks' 
                    isRequired={false} 
                    placeholder='Enter remarks'  
                    className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>

            <div className="my-auto">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-1'> Submit</button>
            </div>
         </div>
         </form>
    
</div>
</div>
  )
}

export default ATWorkingActivitiesLogFormCom