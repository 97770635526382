import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import ListPageHeadWithoutDateRange from '../../../Components/PageHeadComponents/ListPageHeadWithoutDateRange';
import { formatDateTime } from '../../../Components/formatDateTime';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import useFetchListData from '../../../hooks/useFetchListData';
import CustomPaginator from '../../../Components/CustomPaginator';
import SRIssuedItemComponent from './SRIssuedItemComponent';


const SrIdWiseIssuedListCom = ({user,apiEndPoint,}) => {
  const [searchText, setSearchText] = useState('');
  const [dataStatus, setDataStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndPoint}`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, dataStatus, endDate, startDate]);



  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    ticket_no:data?.ticket_no,
    issue_no: data?.issue_no,
    issued_by_text: data?.created_by_employee_id,
    issued_by_phone: data?.created_by_phone,
    issued_at: data?.created_at,
    srissueditem_set: data.srissueditem_set
  }))



  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'ISSUED NO',
      dataIndex: 'issue_no',
      key: '1',
    },
    {
      title: 'ISSUED DATE',
      dataIndex: 'issued_at',
      key: '3',
      render: (issued_at) => {
        const formattedDateTime = issued_at && formatDateTime(issued_at);
        return <span>{formattedDateTime}</span>;
      },
    },
    {
      title: 'ISSUED BY',
      dataIndex: 'issued_by_text',
      key: '4',
      responsive: ['md']
    },
    {
      title: 'ISSUED BY(Phone)',
      dataIndex: 'issued_by_phone',
      key: '4',
      responsive: ['md']
    },
  ];




  return (
    <>
    <div className='container-fluid grid grid-cols-1 mt-10'>
        <ListPageHeadWithoutDateRange 
                pageTitle = {'SR Issued List'}
                setSearchText = {setSearchText}
                // addNewURL = {}
                // addNewUrlPermissionCode = {}
          />


      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
        <>
        <div className='shadow-lg overflow-x-auto'>
          <Table 
              columns={columns}
              expandable={{
                  expandedRowRender: (record) => (
                    <div className='shadow-lg p-4 border border-[#ED6C6C]'>
                      <div className="mx-2">
                          <h1 className='text-[15px] font-semibold' >Issued Basic Information</h1> <hr className='my-2' />
                      </div>
                    <div className='grid md:grid-cols-2 mx-2 my-2 gap-x-48 gap-y-2 '>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Issued No</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.issue_no}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Issued Date</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.issued_at && formatDateTime(record?.issued_at)}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Issued By</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{record?.issued_by_text}</p>
                        </div>
                    </p>

                    </div>

                    <SRIssuedItemComponent itemDatas={record?.srissueditem_set} title={'Issued Item Information'} />

                  </div>
                  ),
                  rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
                }}
              dataSource={dataSource} 
              pagination={false}
          />
          <div className='my-5'>
              <CustomPaginator 
                  setCurrentPage={setCurrentPage} 
                  currentPage={currentPage}
                  totalItems={totalItems}
              />
          </div>
        </div>
       </>
      )} 
    </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SrIdWiseIssuedListCom);