import React, { useEffect, useState } from 'react';

const WoTotalPrice = ({ workorderData }) => {
  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    const calculateTotalPrice = () => {
      if (workorderData?.workorderitem_set?.length > 0) {
        const total = workorderData.workorderitem_set.reduce((acc, item) => {
          const quantity = parseFloat(item?.wo_item_qty) || 0;
          const price = parseFloat(item?.price) || 0;
          const discount = parseFloat(item?.discount_price) || 0;
          const discountType = item?.discount_type;

          let itemTotal = quantity * price;

          // Apply discount based on discount_type
          if (discountType === 'percentage') {
            itemTotal -= (itemTotal * (discount / 100));
          } else if (discountType === 'fixed') {
            itemTotal -= discount;
          }

          return acc + itemTotal;
        }, 0);

        setTotalPrice(total.toFixed(2));
      } else {
        setTotalPrice(null);
      }
    };

    calculateTotalPrice();
  }, [workorderData]);

  return (
    <div>
      {totalPrice !== null ? `${totalPrice} BDT` : 'No items in work order.'}
    </div>
  );
};

export default WoTotalPrice;
