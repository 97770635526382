// eslint-disable-next-line

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from "date-fns";
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import ShowToast from '../../../../Components/ShowToast';


function CustomerAddComponent() {
    const navigate = useNavigate();
    const jwtToken = localStorage.getItem('token')
    
    const [customerFormData, setCustomerFormData] = useState({
        name: '',
        age: '',
        profession:'',
        date_of_birth:null,
        gender:'',
        ref_customer:null,
        photo: '',
        phone_no:'',
        email: '',
        upazila:null,
        address : '',
        post_code:'',
        status: false,
     });

  
    const [customerAddLoding, setCustomerAddLoding] = useState(false);
    const [customerErrorMessage,setCustomerErrorMessage] = useState('')
    
    const [districtOptions, setDistrictOptions] = useState([])
    const [selectedDistrict, setSelectedDistrict] = useState(null);
  
    const [upazilaOptions, setUpazilaOptions] = useState([])
    const [selectedUpazila, setSelectedUpazila] = useState(null);
    const selectedDistrictID = selectedDistrict?.value

    const [customerOptions, setCustomerOptions] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerSearchInputValue, setCustomerSearchInputValue] = useState('');


    const genderOptions = [
        { value: 1, label: 'Male' },
        { value: 2, label: 'Female' },
        { value: 3, label: 'Other' },
      ];

      // district 
      useEffect(() => {
        const fetchDistrictData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/api/get_district/`);
            // console.log(response)
            setDistrictOptions( response.data.map((data) => ({
                value: data.id,
                label: data.name,
            })));

            } catch (error) {
                console.log(error)
            }
        };
        fetchDistrictData();
      }, []);


      // upazila
      useEffect(() => {
        const fetchUpazilaData = async () => {
            try {
            const response = await authAxiosWithBearer.get(`${baseURL}/api/get_district_wise_upazila/${selectedDistrictID}/`);
            console.log(response)
            setUpazilaOptions( response.data.map((data) => ({
                value: data.id,
                label: data.name,
            })));

            } catch (error) {
                console.log(error)
            }
        };
        fetchUpazilaData();
      }, [selectedDistrictID]);




      useEffect(() => {
          authAxiosWithBearer.get(`${baseURL}/ccd/api/customer/?search=${customerSearchInputValue}`)
              .then(response => {
                  setCustomerOptions(response.data.results.map(customer => ({
                      value: customer.id,
                      label: `${customer.name} | ${customer.phone_no}`
                  })));

                  console.log(response.data.results)
              })
              .catch(error => {
                  console.error('Error fetching data:', error);
              });
      }, [customerSearchInputValue]);

      const handleCustomerSearchInputChange = (value) => {
        setCustomerSearchInputValue(value);
      };


    const handleCustomerInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      const inputValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;
    
      if (name === 'phone_no' && value.length > 15) {
        ShowToast( 'error', `Ensure that there are no more than 14 digits`);
        return;
      }

      setCustomerFormData({
        ...customerFormData,
        [name]: inputValue,
      });
    };



    const handleGenderChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
          setCustomerFormData(prevState => ({ ...prevState, gender: selectedOption.value }));
        } else {
          setCustomerFormData(prevState => ({ ...prevState, gender: '' }));
        }
      };

      const handleDistrictChange = (selectedOption) => {
        setSelectedUpazila(null);
        setSelectedDistrict(selectedOption);
      };

      const handleUpazilaChange = (selectedOption) => {
        const upazilaID = selectedOption ? selectedOption.value : ''; 
        setSelectedUpazila(selectedOption);
        setCustomerFormData({
          ...customerFormData,
          upazila: upazilaID,
        });
      };

      const handleCustomerChange = (selectedOption) => {
        const refcustomerID = selectedOption ? selectedOption.value : ''; 
        setSelectedCustomer(selectedOption);
        setCustomerFormData({
          ...customerFormData,
          ref_customer: refcustomerID,
        });
      };


    const handleFromDateChange = (date) => {
        setCustomerFormData({
          ...customerFormData,
          date_of_birth: date,
        });
      };
      

    const handleCustomerSubmit = async (e) => {
      e.preventDefault();
      try {
        setCustomerAddLoding(true);

        const formattedDOB =
        customerFormData.date_of_birth ? format(customerFormData.date_of_birth, 'yyyy-MM-dd') : null;
  

        const formDataWithFormattedDates = {
          ...customerFormData,
          date_of_birth: formattedDOB,
        };

        const response = await authAxiosWithBearer.post(`${baseURL}/ccd/api/customer/`, formDataWithFormattedDates,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        if (response) {
          console.log(response);
          navigate('/customer-list');
          ShowToast('success', 'Successfully Add');
        }
      } catch (error) {
          if(error.response.data){
            ShowToast('error', `${error.response.data.phone_no}`);
            setCustomerErrorMessage(`${error.response.data.phone_no}`)
          }
          console.log(error);
      } finally {
        setCustomerAddLoding(false);
      }
    };


  return { 
    customerFormData,
    setCustomerFormData,
    setSelectedDistrict,
    setSelectedUpazila,
    setSelectedCustomer,
    setCustomerErrorMessage,
    setCustomerAddLoding,
    customerAddLoding,
    customerErrorMessage,
    handleCustomerInputChange,
    handleCustomerSearchInputChange,
    handleGenderChange,
    handleDistrictChange,
    handleUpazilaChange,
    handleCustomerChange,
    handleFromDateChange,
    genderOptions,
    selectedCustomer,
    customerOptions,
    selectedDistrict,
    districtOptions,
    selectedUpazila,
    upazilaOptions,
    handleCustomerSubmit,
    }
}

export default CustomerAddComponent


