import React from 'react'
import Logo from '../../../../assest/Images/logo.png'
import { formatDateTime } from '../../../../Components/formatDateTime'
import TextCopyButton from '../../../../Components/TextCopyButton'

const CustomerComplainDetailsComponent = ({detailsData}) => {
  return (
    <div className='mx-2'>
        <div className="user-details-page-title mb-5 shadow">
            <div className="my-auto py-2">
                <h1 className='uppercase'>Customer Complain Details</h1>
            </div>
        </div>

        <div className="information-section my-5 px-1 py-6 ">
            <div className="grid md:grid-cols-2 mx-2 md:mx-5 my-4 gap-x-[130px] gap-y-6">
                
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Customer Name
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.name}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Phone
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.phone_no}
                        
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Email
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.email}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Complain ID
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.complain_id}
                        {detailsData?.complain_id && ( 
                            <TextCopyButton text={detailsData?.complain_id} />
                        )}
                    </div> 
                </div>
                
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Invoice No
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.invoice_no}

                        {detailsData?.invoice_no && ( 
                            <TextCopyButton text={detailsData?.invoice_no} />
                        )}
                    </div> 
                </div>

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Comapin Status
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.complaint_status}
                    </div> 
                </div>

                {detailsData?.ticket_no && (
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Ticket No
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.ticket_no}
                        
                        {detailsData?.ticket_no && ( 
                            <TextCopyButton text={detailsData?.ticket_no} />
                        )}
                    </div> 
                </div>
                )}

                {detailsData?.ticket_created_at && (
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Ticket Created At
                    </div>
                    <div className='w-[60%] text-end'>
                    {detailsData?.ticket_created_at && formatDateTime(detailsData?.ticket_created_at)}
                    </div> 
                </div>
                )}

                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                    Address
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.address}
                    </div> 
                </div>
                <div className='flex flex-wrap border-b'>
                    <div className='w-[40%]'>
                        Complain Date
                    </div>
                    <div className='w-[60%] text-end'>
                        {detailsData?.created_at && formatDateTime(detailsData?.created_at)}
                    </div> 
                </div>
            </div>
            <div className='flex flex-wrap border-b mx-2 md:mx-5'>
                    <div className='w-[20%]'>
                    Messages
                    </div>
                    <div className='w-[80%] text-end'>
                        {detailsData?.message}
                        {detailsData?.message && ( 
                            <TextCopyButton text={detailsData?.message} />
                        )}
                    </div> 
            </div>
        </div>
    </div>
  )
}

export default CustomerComplainDetailsComponent