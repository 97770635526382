import React from 'react';


const GRnItemComponentForDetails = ({grnData}) => {
    return (
        <>
            {/* className='px-4 sm:px-14 mt-7 sm:text-[10px] lg:text-[15px] overflow-x-auto  ' */}
            <div className='overflow-x-auto overflow-y-auto'>

                <table className='table-auto border-2 border-[#ED6C6C] border-collapse max-w-[100%] text-[9px] sm:text-[12px]'>

                    <thead>

                        <tr className='bg-[#EEEEEE]'>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>SN</th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Specification</th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Rcv.Quantity </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> Add.Quantity</th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'> Lot No </th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Exp Date</th>

                            <th className='border-2 border-[#ED6C6C] w-[400px] py-3'>Remarks </th>

                        </tr>

                    </thead>



                    <tbody className='text-center'>

                        {grnData?.grnitem_set?.map((item,index)=>(
                            <tr >
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{index+1}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>
                                    name : {item?.item_name} <br />
                                    size : {item?.item_size} <br />
                                    specification :{item?.item_specification} <br />
                                    brand : {item?.item_brand}
                                </td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.rcv_qty} ({item?.pr_uom_name? item.pr_uom_name : item?.item_uom_name})</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.add_qty}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.lot_no}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.exp_date}</td>
                                <td className='border-2 border-[#ED6C6C] w-[400px] py-3'>{item?.remarks}</td>
                            </tr>
                        ))}

                        <tr>
                            <td colSpan="8" className='border-2 border-[#ED6C6C] w-[400px] py-3 '>Note - {grnData?.note}  </td>
                        </tr>

                    </tbody>

                </table>



            </div>
        </>
    );
};

export default GRnItemComponentForDetails;