import React from 'react'
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import HOInvoiceCreateHandleComponent from './HOInvoiceCreateHandleComponent';

function HOInvoiceCreateFormComponent({
    scbccWpTicketNo,
    installationTicketNo,
    detailsData,
    srIssuedData 
}) {
    const {
        formData,
        setFormData,
        handleInputChange,
        handleSubmit,
    } = HOInvoiceCreateHandleComponent({
        scbccWpTicketNo,
        installationTicketNo,
        detailsData,
        srIssuedData 
    });



    return (
        <div className='mt-[50px]'>
            <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-1 gap-2">

                    <div className='my-4'>
                        <InputFiledWC type='text' 
                            name='basic_charge' id='basic_charge' label='Basic Charge*'
                            isRequired={true} 
                            placeholder='Enter basic charge'
                            value={formData.basic_charge}
                            onChange={handleInputChange}
                            className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        />
                    </div>


                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='basic_charge_quantity' id='basic_charge_quantity' label='Basic Charge Quantity*'
                        isRequired={true} 
                        placeholder='Enter basic charge'
                        value={formData.basic_charge_quantity}
                        onChange={handleInputChange}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
               

                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='additional_charge' id='additional_charge' label='Additional Charge'
                        isRequired={false} 
                        placeholder='Enter additional charge'
                        value={formData.additional_charge}
                        onChange={handleInputChange}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='total_payable_amount' id='total_payable_amount' label='Total Payable Amount'
                        isRequired={false} 
                        placeholder='Discount Amount'
                        value={formData.total_payable_amount}
                        onChange={handleInputChange}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        readOnly={true}
                    />
                </div>
                </div>

                <div className=" mt-4 bottom-0">
                        <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-4 py-2 w-[100%]'>Create</button>
                    </div>
            </form>
        </div>
    )
}

export default HOInvoiceCreateFormComponent