import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetchListData from '../../../../hooks/useFetchListData';
import ListPageHeadWithoutDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithoutDateRange';
import CustomPaginator from '../../../../Components/CustomPaginator';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import ATForCompletedDeliveryResourceModalButton from './AssignTechnicianForCompletedDeliveryResource/ATForCompletedDeliveryResourceModalButton';

const TechnicianOrVendorListForCompletedDeliveryResource = (
    {
        user,
        detailsData,
        fetchDeatilsData,
        title,
        apiEndPoint,

    }
) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);


  const params = {
    page: currentPage,
    search: searchText,
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndPoint}`, params);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchText]);
  

  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    employee_id: data?.employee_id,
    name:data?.name, 
    phone_no:data?.phone_no, 
    district_text: data?.district_text,
    work_load_count:data?.work_load_count
  }))


  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '1',
    },
    {
      title: 'employee_id',
      dataIndex: 'employee_id',
      key: '2',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_no',
      key: '3',
    },
    {
      title: 'Distict',
      dataIndex: 'district_text',
      key: '3',
    },
    {
      title: 'WorkLoad',
      dataIndex: 'work_load_count',
      key: '3',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className='flex gap-2'>
          
          {user?.menu_permissions?.includes("8.1.1") &&(
          <>
          {/* {record.work_load_count < 6? 
          <> */}
            <div>
                {detailsData?.assignedtechnicianforcompleteddeliveryresource_set?.some((data) => record.id === data?.technician) ? (
                  <div className='rounded flex text-[#ffffff] p-2 bg-[#25721c]'>
                      Assigned 
                  </div>
                ) : (
                    <ATForCompletedDeliveryResourceModalButton 
                    transport_assign={detailsData?.id} 
                    technician_id={record?.id} 
                    detailsData={detailsData} 
                    fetchData={fetchData}
                    fetchDeatilsData={fetchDeatilsData}
                    />
                )} 
                
            </div>          
          </>
          // :<div className='rounded flex text-[#000000] p-2 bg-[#dcff3f]'>Overload {record.transport_user_work_load_count}</div>
          // }
          // </>
          )}
        </div>
      ),
    },

  ];


  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
          <ListPageHeadWithoutDateRange 
                pageTitle = {title}
                setSearchText = {setSearchText}
          />

      <div className='shadow-lg overflow-x-auto'>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
            <div className='my-5'>
            <CustomPaginator 
                setCurrentPage={setCurrentPage} 
                currentPage={currentPage}
                totalItems={totalItems}
            />
        </div>
      </>
      )}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(TechnicianOrVendorListForCompletedDeliveryResource);
