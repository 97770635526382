import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import { useSelector } from 'react-redux'
import ATForServicingDetailsComponent from '../../InHouse/InHouseComponents/ATForServicingDetailsComponent'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import InHouseTechnicianSrIssueForm from './InHouseTechnicianSRIssueCom/InHouseTechnicianSrIssueForm'
import SRItemComponent from '../InventoryComponent/SRItemComponent'
import SrToPrFormCom from '../InventoryComponent/SrToPrComponents/SrToPrFormCom'
import SrIdWiseIssuedListCom from '../InventoryComponent/SrIdWiseIssuedListCom'

const InHouseTechnicianSrDetails = () => {
    const {id} = useParams()
    const [data, setData]= useState(null)
    const user = useSelector(state => state.auth.user);
    const [goodsCheckAssignData,setGoodsCheckAssignData] = useState(null)
    const [isLoading, setIsLoading]=useState(false)
    // fetchDeatilsData

    const fetchDeatilsData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/in_house/api/in-house-technician-sr-details/${id}/`);
            setData(response?.data?.assigned_data);
            setGoodsCheckAssignData(response?.data?.goods_check_data)
            setIsLoading(true)
            console.log('response',response)
        } catch (error) {
            console.log(error);
        }
        finally{
            setIsLoading(false)
        }
        };


    useEffect(() => {

        fetchDeatilsData();
    }, [id]);



    // #sr to pr 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [srID, setSrID] = useState('');

    const openSrToPrModal = (id) => {
        setSrID(id);
        setIsModalOpen(true);
      };

  return (
    <div className='border border-[#EB5757]'>

        <div className='information-head flex justify-between  px-10 mb-5 py-2'>
            <div>Requisition Details</div>
                    <div>
                    {user?.menu_permissions?.includes("3.9.1") && data?.sr_issue_status !== 'Issued' && (
                        <button
                            onClick={() => openSrToPrModal(id)}
                            className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                            >
                            SR TO PR
                        </button>
                        )}
                    </div>
        </div>

            {isLoading?
            <>
                <LoadingSpinner />
            </>
            :
            <>
                <ATForServicingDetailsComponent  data={data} />

                {goodsCheckAssignData?.goodscheckedtechnicianfeedback_set?.length > 0 && (
                    <SRItemComponent itemDatas={goodsCheckAssignData?.goodscheckedtechnicianfeedback_set[0]?.gctfeedbackpartsdetails_set}/>

                )}

                <InHouseTechnicianSrIssueForm id={id} />



                <SrIdWiseIssuedListCom
                    apiEndPoint={`/inventory/api/sr-issued/?in_house_technician_sr_id=${id}`}
                />

                <SrToPrFormCom isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen} 
                srToPrItems={goodsCheckAssignData?.goodscheckedtechnicianfeedback_set[0]?.gctfeedbackpartsdetails_set} 
                IHsrID={goodsCheckAssignData?.goodscheckedtechnicianfeedback_set[0]?.id} 
                />

            </>
            }
    </div>
  )
}

export default InHouseTechnicianSrDetails