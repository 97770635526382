import React, { useState } from 'react'
import ATForServicingModal from './ATForServicingModal';

const ATForServicingModalButton = ({gctfeedback_id,technician_id,fetchData,fetchDeatilsData}) => {
    const [isModalOpen, setModalOpen] = useState(false);


    const openModal = () => {
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };



  return (
    <div>
        <div className='flex'>
              <button
                  onClick={() => openModal()}
                  className='bg-[#FA6669] rounded-[4px] px-[16px] py-[5px] text-[#fff]'
                  >
                    Assign
              </button>
        </div>

          <ATForServicingModal
                isOpen={isModalOpen}
                onClose={closeModal}
                setModalOpen={setModalOpen}
                technician_id = {technician_id}
                gctfeedback_id={gctfeedback_id}
                fetchData={fetchData}
                fetchDeatilsData={fetchDeatilsData}
            />
    </div>
  )
}

export default ATForServicingModalButton