import React from 'react'
import RequestedTicketListComponent from './ShowroomTicketRequestComponent/RequestedTicketListComponent'

const AllShowroomPendingRequestedTicketList = () => {
  return (
    <div>
        <RequestedTicketListComponent 
            apiEndpoint={'ccd/api/all-showroom-pending-requested-ticket-list/'}
            pageTitle={'Pending Requests LIST'}
            editUrl={`/requested-ticket-update-for-ccd`}
        />
    </div>
  )
}

export default AllShowroomPendingRequestedTicketList