import React, { useEffect, useState } from 'react'
import ShowToast from '../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { useNavigate } from 'react-router-dom';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import { AiFillDelete } from 'react-icons/ai';
import SelectInput from '../../../Components/Form/SelectInput';
import InputFiled from '../../../Components/Form/InputFiled';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import ItemPackageFormComponent from '../InventoryComponent/ItemPackageFormComponent';

function ItemPackageAdd() {
  const { 
    navigate,
    requiredMessage,
    categoriesOption,
    setCategoriesOption,
    selectedCategory,
    setSelectedCategory,
    subCategoriesOption,
    setSubCategoriesOption,
    selectedSubCategory,
    setSelectedSubCategory,
    packageCategoriesOption,
    setPackageCategoriesOption,
    selectedPackageCategory,
    setSelectedPackageCategory,
    itemOptions,
    setItemOptions,
    selectedItem,
    setSelectedItem,
    packageForm,
    setPackageForm,
    handleCategoriesChange,
    handleSubCategoryChange,
    handlePackageCategoriesChange,
    filteredSubCategories,
    handlePackagePriceInputChange,
    handleItemChange,
    handleInputChange,
    areAllFieldsFilled,
    handleAddMoreItem,
    handleRemoveItem,
    handleSubmit,
    handleItemSearchInputChange,
   } = ItemPackageFormComponent()
    
  return (
    <div className="req-issue-form shadow-xl pb-5 border  my-10 bg-[#fff]">
        <FormPageTitle title='Package Add Form' FormPageRightSideButtonURL='' LinkName='' />
        <form onSubmit={handleSubmit}>
            <div className="mx-2 mb-5">
                <h1 className='text-[15px] font-semibold' >Package Basic Information</h1> <hr className='my-2' />
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 mx-4">

            <div className='my-4'>
                <InputFiled type='text' 
                value={packageForm.package_name} 
                className={`w-full`}
                onChange={handleInputChange} 
                name='package_name' id='package_name'  label='Package Name*' 
                isRequired={true} 
                placeholder='Enter package name'  
                />
            </div>

            <div className='my-4'>
                <InputFiled type='text' 
                value={packageForm.package_price} 
                className={`w-full`}
                // onChange={handlePackagePriceInputChange} 
                name='package_price' id='package_price'  label='Package Price*' 
                isRequired={true}
                placeholder='Enter package price'
                readOnly={true}
                />
            </div>

            <div className="my-4">
                    <SelectInput
                        id="package_category"
                        className={`w-full`}
                        name="package_category"
                        label='Package Category*'
                        placeholder='Select Package Category'
                        value={selectedPackageCategory}
                        onChange={handlePackageCategoriesChange}
                        options={packageCategoriesOption}
                        isRequired={true}
                    />
                </div>

            <div className="my-4">
                    <SelectInput
                        id="item_category"
                        className={`w-full`}
                        name="item_category"
                        label='Item Category*'
                        placeholder='Select Item Category'
                        value={selectedCategory}
                        onChange={handleCategoriesChange}
                        options={categoriesOption}
                        isRequired={true}
                    />
                </div>

                <div className="my-4">
                    <SelectInput
                        className={`w-full`}
                        id="item_sub_category"
                        name="item_sub_category"
                        label='Item S.Category*'
                        value={selectedSubCategory}
                        placeholder='Select Item Sub Category'
                        onChange={handleSubCategoryChange}
                        options={filteredSubCategories}
                        isRequired={true}
                    />
                </div>
            </div>


            <div className="mx-2 my-5">
                <h1 className='text-[15px] font-semibold' >Package Item Information</h1> <hr className='my-2' />
            </div>

            {packageForm.itempackageparts_set?.map((packageItem, index) => (
            <div className="grid grid-cols-2 md:flex mx-4 mt-2 gap-x-4 lg:gap-x-4 gap-y-2 border-b">              
            <div className="my-4">
                <SelectInputWC
                    label='Item*'
                    id={`requisition_details_${index}`}
                    name={`itempackageparts_set[${index}]?.item`}
                    value={selectedItem[index]}
                    onInputChange={handleItemSearchInputChange}
                    onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                    options={itemOptions.filter(option => !option.isAlreadySelected)}
                    placeholder='Select item'
                    isRequired={true}
                    isClearable={true}
                    className={`appearance-none border-[1px] w-[100%] md:w-[180px] lg:w-[220px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                />
            </div>

            <div className='my-4'>
                <InputFiledWC type='text' 
                name='uom' label='Item UOM*' 
                id={`uom_${index}`}
                value={packageItem.uom}
                // onChange={(e) => handleInputChange (e, index, 'itempackageparts_set')}
                isRequired={true}
                readOnly={true}
                placeholder='item uom'
                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] md:w-[120px] lg:w-[180px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>


            <div className='my-4'>
                <InputFiledWC type='number' 
                name='quantity' label='Quantity*' 
                id={`quantity_${index}`}
                value={packageItem.quantity}
                onChange={(e) => handleInputChange(e, index, 'itempackageparts_set')}
                isRequired={true} 
                placeholder='Enter quantity'
                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] md:w-[120px] lg:w-[180px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>

            <div className='my-4'>
                <InputFiledWC type='number' 
                name='item_unit_price' label='Item U.Price*' 
                id={`item_unit_price_${index}`}
                value={packageItem.item_unit_price}
                // onChange={(e) => handleInputChange (e, index, 'itempackageparts_set')}
                isRequired={true}
                readOnly={true}
                placeholder='Enter item unit price'
                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] md:w-[120px] lg:w-[180px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>

            <div className='my-4'>
                <InputFiledWC type='number' 
                name='total_item_price' label='Total Price*' 
                id={`total_item_price_${index}`}
                value={packageItem.total_item_price}
                // onChange={(e) => handleInputChange(e, index, 'itempackageparts_set')}
                isRequired={true} 
                readOnly={true}
                placeholder='Enter total item price'
                className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[100%] md:w-[150px] lg:w-[180px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                />
            </div>

            <div className='md:mt-10 mx-5 mb-2'>
                {index !== 0 && (
                    <button
                    type="button"
                    onClick={() => handleRemoveItem(index)}
                    className="text-red-500"
                    >
                    <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                    </button>
                )}
            </div>

            </div>
            ))}

            {requiredMessage && (
                <span className='text-red-500 mx-4'>{requiredMessage} <br /> </span> 
            )}
            <div className="flex justify-between mx-4 my-2">

                <button 
                    className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                    type="button"
                    onClick={() => {
                        console.log('Add new item Button Clicked');
                        handleAddMoreItem();
                    }}
                    >ADD MORE</button>
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
            </div>
            
        </form>
    </div>
  )
}

export default ItemPackageAdd