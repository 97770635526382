import React from 'react'

const ATForServicingDetailsComponent = ({data}) => {
  return (
    <div className="border border-[#EB5757] my-5 p-1">
    <div className='information-head my-1 px-10 py-1'>
        <div>Assigned Technician Information (Servicing)</div>
    </div>
    <div className="grid md:grid-cols-2 mx-2 my-2 md:mx-10 gap-x-16 gap-y-2">

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
                Technician
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.technician_employee_id}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Technician Name
            </div>
            <div className='w-[65%] text-end border px-1'>
            {data?.technician_name}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Technician Phone
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.technician_phone}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Assigned Status
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.assigned_status}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Priority
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.priority}
            </div>
        </div>
        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Remarks
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.remarks}
            </div>
        </div>

    </div>
    </div>
  )
}

export default ATForServicingDetailsComponent