import React, { useEffect, useState } from 'react'
import TechnicianTaskReportFormCom from './TechnicianTaskReportComponents/TechnicianTaskReportFormCom'
import { useParams } from 'react-router-dom'
import TechnicianTaskReportListCom from './TechnicianTaskReportComponents/TechnicianTaskReportListCom'

const TechnicianTaskReportListForCro = () => {
    const {id} = useParams()


  return (
    <div>
        <TechnicianTaskReportListCom 
            apiEndpoint={`/technician/api/technician-task-report-for-cro/?paramsQuery=${id}`}
        />
    </div>
  )
}

export default TechnicianTaskReportListForCro