import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { format } from 'date-fns';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import CustomPaginator from '../../../Components/CustomPaginator';
import useFetchListData from '../../../hooks/useFetchListData';
import SrIdWiseIssuedListCom from '../../Inventory/InventoryComponent/SrIdWiseIssuedListCom';
import { formatDateTime } from '../../../Components/formatDateTime';


const RequisionSubmitedDataList = ({ assigned_id }) => {

  const [requisitionSubmitedData,setRequisitionSubmitedData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  

  useEffect(() => {
      const fetchRequisitionSubmittedData = async () => {
          try {
              const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-requisition/?assigned_ticket_id=${assigned_id}`,{
                params: {
                  page: currentPage,
                },
              });

              // const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-requisition/`);
              // const filteredData = response.data.results.filter(item => {
              //     return item.invreqdetail_set.some(detail => detail.assigned_ticket === ticketData?.id);
              // });

              setTotalItems(response.data.count);
              setRequisitionSubmitedData(response.data.results)
              console.log('sr rquested list ',response.data.results)

          } catch (error) {
              console.log(error);
              ShowToast('error', 'Something is wrong')
          }
      };
  
      fetchRequisitionSubmittedData();
  }, [assigned_id,currentPage]);
  


  const dataSource = requisitionSubmitedData?.map((data,index) => ({
    key: index,
    id:data.id,
    reqCode: data?.req_code,
    issued: data.issue_status === 'Issued' ? <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Issued</div> : data.issue_status === 'Partial' ? <div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div> : <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Raised</div>,
    created_at: data?.created_at,
    invreqdetail_set: data?.invreqdetail_set
  }));


  const columns = [
    {
      title: 'REQ CODE',
      dataIndex: 'reqCode',
      key: 'reqCode',
    },
    {
      title: 'ISSUED',
      dataIndex: 'issued',
      key: 'issued',
    },
    {
      title: 'REQUESTED DATE',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        const formattedDateTime = created_at ? formatDateTime(created_at) : '';
        return <span>{formattedDateTime}</span>;
      },
      responsive: ['md']
    },
  ];


  return (
    <>
    {requisitionSubmitedData?.length > 0 && (
      <div className="border border-[#E74A3B] shadow-lg rounded-[8px]">
        <div className='information-head mb-1 pl-5 py-1'>
              <div>Requisition List</div>
        </div>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <div className='p-3'>
                <div className="mx-2">
                  <h1 className='text-[15px] font-semibold' >Requsition Information</h1> <hr className='my-2' />
                </div>
                {record?.invreqdetail_set?.map((data,index)=>(
                <div key={index}>
                <div className='grid md:grid-cols-3 mx-2 my-2 gap-x-48 gap-y-2 border-b pb-2 '>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Item Name</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{data?.item_name}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>UOM</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{data?.uom_text}</p>
                        </div>
                    </p>
                    <p className="expanded-row-content">
                        <div className='flex flex-wrap'>
                            <p className='w-[40%] md:w-[40%]'>Quantity</p>
                            <p className='w-[5%] md:w-[5%] text-center '>-</p>
                            <p className='w-[55%] md:w-[55%] text-end'>{data?.quantity}</p>
                        </div>
                    </p>
                </div>
                </div>
                ))}


                <SrIdWiseIssuedListCom apiEndPoint={`/inventory/api/user-wise-diagnosis-sr-issued/?diagnosis_sr_id=${record.id}`} />



              </div>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={dataSource}
          pagination={false}
        />
          <div className="py-5">
            <CustomPaginator 
                setCurrentPage={setCurrentPage} 
                currentPage={currentPage}
                totalItems={totalItems}
            />
          </div>
      </div>
    )}
    </>
      
  );
};

export default RequisionSubmitedDataList;
