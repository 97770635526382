import React from 'react';
import TechnicianOrVendorListForCompletedDeliveryResource from './TechnicianOrVendorListForCompletedDeliveryResource';


const TechnicianListForCompletedDeliveryResource = ({detailsData,fetchDeatilsData}) => {


  return (
    <>
      <TechnicianOrVendorListForCompletedDeliveryResource 
        title={'Vendor List'}
        apiEndPoint={'/api/vendor-list/'}
        detailsData={detailsData}
        fetchDeatilsData={fetchDeatilsData}
      /> 
    </>
  );
};


export default TechnicianListForCompletedDeliveryResource;
