import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { authAxiosWithBearer, baseURL } from '../../../baseURL'
import ShowToast from '../../../Components/ShowToast';
import { AiFillDelete } from 'react-icons/ai';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../Components/FormWithoutClass/TextareaInputWC';
import { ImCancelCircle } from 'react-icons/im';
import Modal from 'react-modal';
import { connect } from 'react-redux';

function DPR({user}) {

    const navigate = useNavigate()
    const [requisionItemOptions, setRequisionItemOptions] = useState([]);
    const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
    const [requisionIssueRequiredMessage,setRequisionIssueRequiredMessage]=useState('')
    const [requisionItemUOMOptions, setRequisionItemUOMOptions] = useState([]);
    const [selectedRequisionItemUOM, setSelectedRequisionItemUOM] = useState([]);
    const [itemSearchInputValue, setItemSearchInputValue] = useState(null);

    const [requisionPrFormData, setRequisionPrFormData] = useState({
        srtopritem_set: [
          {
            pr_item: null,
            uom: null,
            pr_item_qty:'',
            max_issue_qty:'',
            remarks:'',
          },
        ],
      });



      useEffect(() => {
          authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
              .then(response => {
                setRequisionItemOptions(response.data.results.map((item)=>({
                          value : item.id,
                          label: `${item?.item_name} | ${item?.size} | ${item.specification} | ${item.brand} | | ${item.model}`,

                    })))
    
                  console.log(response.data.results)
              })
              .catch(error => {
                  console.error('Error fetching data:', error);
              });
      }, [itemSearchInputValue]);
    
      
      const handleItemSearchInputChange = (value) => {
        setItemSearchInputValue(value);
      };


      useEffect(()=>{
        const fetchItemUOMData = async () =>{
            try {
                const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-uom-list/`)
                console.log('uom',response)

                const options = response.data.results.map(data => ({
                  value: data.id,
                  label: data.name,
                }));
                setRequisionItemUOMOptions(options);
            } catch (error) {
                console.log(error)
            }
        };fetchItemUOMData()
      },[])

       
      const handleRequisionItemChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionPrFormData };
        const updatedSelectedRequisionItem = [...selectedRequisionItem];
      
        if (selectedOption) {
          updatedFormData.srtopritem_set[index].pr_item = selectedOption.value;
          updatedSelectedRequisionItem[index] = selectedOption;
        } else {
          updatedFormData.srtopritem_set[index].pr_item = null;
          updatedSelectedRequisionItem[index] = null;
        }
      
        setSelectedRequisionItem(updatedSelectedRequisionItem);
        setRequisionPrFormData(updatedFormData);
      };
      
      const handleRequisionItemUOMChange = (selectedOption, index) => {
        const updatedFormData = { ...requisionPrFormData };
        const updatedSelectedRequisionItemUOM = [...selectedRequisionItemUOM];
      
        if (selectedOption) {
          updatedFormData.srtopritem_set[index].uom = selectedOption.value;
          updatedSelectedRequisionItemUOM[index] = selectedOption;
        } else {
          updatedFormData.srtopritem_set[index].uom = null;
          updatedSelectedRequisionItemUOM[index] = null;
        }
      
        setSelectedRequisionItemUOM(updatedSelectedRequisionItemUOM);
        setRequisionPrFormData(updatedFormData);
      };
        

    const handleRequisionPrInputChange = (e, index, type) => {
        const updatedData = { ...requisionPrFormData };
        const { name } = e.target;
        let value = e.target.value;  
      
        if (type === 'srtopritem_set' && name === 'pr_item_qty') {
          const parts = value.split('.');
          const integerPart = parts[0];
          const decimalPart = parts[1] || '';
      
          if (integerPart.length > 12) {
            ShowToast('error', `Ensure that there are no more than 12 digits`);
            return;
          }
      
          if (parts.length === 2) {
            value = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
            if (decimalPart.length > 2) {
              ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
            }
          }
        }
      
        if (type === 'srtopritem_set') {
          setRequisionIssueRequiredMessage('');
          updatedData.srtopritem_set[index][name] = value;
        } else {
          updatedData[name] = value;
        }
      
        setRequisionPrFormData(updatedData);
      };
      
    
    const areAllFieldsFilled = () => {
        return (
            requisionPrFormData.srtopritem_set &&
            requisionPrFormData.srtopritem_set.every((item) => {
                return item?.pr_item !== null && item?.uom !== null && item?.pr_item_qty !== '';
            })
        );
    };
    
      
    const handleAddRequisionPrItem = () => {
        if (areAllFieldsFilled()) {
            setRequisionPrFormData((prevState) => ({
                ...prevState,
                srtopritem_set: [
                    ...prevState.srtopritem_set,
                    {
                        pr_item: null,
                        uom: null,
                        pr_item_qty:'',
                    },
                ],
            }));
            setRequisionIssueRequiredMessage('');
        } else {
            setRequisionIssueRequiredMessage("Please fill all fields in the existing field before adding a new one.");
        }
    };
    

    const handleRemoveRequisionPrItem = (index) => {
        const srtopritem_set = [...requisionPrFormData.srtopritem_set];
        setRequisionIssueRequiredMessage('');
    
        // Remove the item from the array
        const removedItem = srtopritem_set.splice(index, 1)[0];
    
        // Remove the corresponding items from selectedRequisionItem and selectedRequisionStock arrays
        const updatedSelectedRequisionItem = selectedRequisionItem.filter((item, i) => i !== index);

        setRequisionPrFormData({
            ...requisionPrFormData,
            srtopritem_set,
        });
    
        setSelectedRequisionItem(updatedSelectedRequisionItem);
    };
    

    const handleRequisionIssueSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/sr-to-pr/`, requisionPrFormData);
            
            console.log('requisionPrFormData',requisionPrFormData)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Submit');
                navigate('/sr-to-pr-list');
            }
        } catch (error) {
            console.log(error);
            ShowToast('error', 'Something is wrong');
        }
    };
    


  return (
    <div className='container grid grid-cols-1'>
        <div className='mb-2 '>
            <div className='flex justify-between bg-[#fff] text-[#EB5757] text-[18px] py-2 px-4 rounded-[5px] mb-2 shadow'>
                <h1 className='font-semibold'>DIRECT PR </h1>
            </div>
            <div className="mx-auto shadow-lg bg-[#fff] pt-4 pb-[150px] overflow-x-scroll ">
                <form onSubmit={handleRequisionIssueSubmit}>
                    <div className='pb-10'>
                        {requisionPrFormData.srtopritem_set?.map((requisionItems, index) => (

                            <div className="flex px-3  mt-3 gap-x-4 gap-y-2 border-b">
                                <div className="my-4">
                                    <SelectInputWC
                                        label='Item*'
                                        id={`item_${index}`}
                                        name={`srtopritem_set[${index}]?.pr_item`}
                                        value={selectedRequisionItem[index]}
                                        onInputChange={handleItemSearchInputChange}
                                        onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                                        options={requisionItemOptions.filter(
                                            (option) =>
                                                !selectedRequisionItem.some(
                                                    (selectedItem) => selectedItem && selectedItem.value === option.value
                                                )
                                        )}
                                        placeholder='Select item'
                                        isRequired={true}
                                        isClearable={true}
                                        className={`appearance-none border-[1px] w-[200px] md:w-[200px] lg:w-[250px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                    />
                                </div>

                                <div className="my-4">
                                    <SelectInputWC
                                        label='UOM*'
                                        id={`uom_${index}`}
                                        name={`srtopritem_set[${index}]?.uom`}
                                        value={selectedRequisionItemUOM[index]}
                                        onChange={(selectedOption) => handleRequisionItemUOMChange(selectedOption, index)}
                                        options={requisionItemUOMOptions}
                                        placeholder='Select uom'
                                        isRequired={true}
                                        isClearable={true}
                                        className={`appearance-none border-[1px] w-[200px] md:w-[200px] lg:w-[250px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                    />
                                </div>


                                <div className='my-4'>
                                    <InputFiledWC type='number'
                                        name='pr_item_qty' label='PR Qty*'
                                        id={`pr_item_qty${index}`}
                                        value={requisionItems.pr_item_qty}
                                        onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                        isRequired={true}
                                        placeholder='Enter item qty'
                                        className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[170px] lg:w-[200px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                        max={requisionItems?.max_issue_qty}
                                        min={0}
                                    />
                                </div>

                                <div className='my-4'>
                                    <TextareaInputWC
                                        label='Remarks'
                                        name='remarks'
                                        id='remarks'
                                        placeholder={`Enter remarks`}
                                        value={requisionItems.remarks}
                                        onChange={(e) => handleRequisionPrInputChange(e, index, 'srtopritem_set')}
                                        className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[170px] md:w-[170px] lg:w-[200px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                    />
                                </div>

                                <div className='mt-10 mx-10 mb-2'>
                                    {requisionPrFormData.srtopritem_set?.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveRequisionPrItem(index)}
                                            className="text-red-500"
                                        >
                                            <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                        </button>
                                    )}
                                </div>

                            </div>
                        ))}
                    </div>
                    {requisionIssueRequiredMessage && (
                        <span className='text-red-500 mx-4'>{requisionIssueRequiredMessage} </span>
                    )}
                    <div className="flex justify-between mx-4 my-2">

                        <button
                            className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-5 py-2 border hover:shadow'
                            type="button"
                            onClick={() => {
                                console.log('Add new item Button Clicked');
                                handleAddRequisionPrItem();
                            }}
                        >ADD MORE</button>
                        <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-5 py-2'>Submit</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(DPR);