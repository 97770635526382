import React, { useEffect, useState } from 'react'
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ShowToast from '../../../Components/ShowToast';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';

const CustomerServicingFeedbackStatusChangeForm = ({data,id,fetchDeatilsData}) => {

    const [statusForm, setStatusForm] = useState({
        customer_feedback: '',
    })

    const statusOption = [
        {value:'Agree',label:'Agree'},
        {value:'Disagree',label:'Disagree'},
    ]


    useEffect(() => {
        setStatusForm(data)
    }, [data]);


    const handleStatusChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== null) {
            setStatusForm(prevState => ({ ...prevState, customer_feedback: selectedOption.value }));
            console.log('selectedOption',selectedOption)
        } else {
            setStatusForm(prevState => ({ ...prevState, customer_feedback: null }));
        }
        };
    



    const handleStatusChangeSubmit = async (e)=>{
        e.preventDefault()
        try {
            const response = await authAxiosWithBearer.put(`${baseURL}/in_house/api/customer-servicing-feedback-status-change/${id}/`,statusForm)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Updated');
                fetchDeatilsData()
              }
        } catch (error) {
            if(error.response.data.error){
                ShowToast('error', `${error.response.data.error}`);
            }
            else{
                ShowToast('error', 'something is wrong');
            }
            console.log(error)
        }
    }

    console.log('data?.customer_feedback',data?.customer_feedback)

  return (
    <div>
    {data?.customer_feedback !== 'Disagree' && data?.customer_feedback !== 'Agree' && (
        <form className='border border-[#E74A3B] shadow-lg rounded-[8px] pb-3' onSubmit={handleStatusChangeSubmit}>
                <FormPageTitle title='Customer Servicing Feedback Status' />
                <div className='flex justify-between md:mx-5'> 
                    <div  className='my-4 w-3/4'>
                        <SelectInputWC
                            name='customer_feedback'
                            id='customer_feedback'
                            label='Status*'
                            placeholder='select status'
                            className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                            value={statusOption?.find((option)=> option?.value === statusForm?.customer_feedback)}
                            onChange={handleStatusChange}
                            options={statusOption}
                            isRequired={true}
                        />
                    </div>
                   
                        <div className='my-auto w-1/4 flex justify-end'>
                            <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Change</button>      
                        </div>
                    
                </div>
        </form>
        )}
    </div>
    
  )
}

export default CustomerServicingFeedbackStatusChangeForm