import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GeneralSRCreate from './GeneralSRCreate';
import { useSelector } from 'react-redux';
import UserWiseGSRList from './UserWiseGSRList';
import UserWiseGeneralSRIssuedList from './GSRIssud/UserWiseGeneralSRIssuedList';


const GeneralSR = () => {
    const user = useSelector(state => state.auth.user);

    return (
    <div className="parent-category">
        <Tabs>
            <TabList className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 tb-list bg-[#fff] shadow-xl p-3 rounded-[5px]'>
                {user?.menu_permissions?.includes("3.14.6") && (
                <Tab>General SR List</Tab>
                )}
                {user?.menu_permissions?.includes("3.14.1") && (
                <Tab>Add New General SR</Tab>
                )}
                {user?.menu_permissions?.includes("3.14.1") && (
                <Tab>General SR Issued List</Tab>
                )}
            </TabList>

            {user?.menu_permissions?.includes("3.14.6") && (
            <TabPanel>
                <UserWiseGSRList />
            </TabPanel>
            )}

        {user?.menu_permissions?.includes("3.14.1") && (
            <TabPanel>
                <GeneralSRCreate />
            </TabPanel>
            )}
        {user?.menu_permissions?.includes("3.14.1") && (
            <TabPanel>
                <UserWiseGeneralSRIssuedList />
            </TabPanel>
        )}
        </Tabs>
    </div>
    )
}

export default GeneralSR