import React from 'react'
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './InventoryComponent/CSS/itemMaster.css'
import PageTitleComponent from '../../Components/PageTitleComponent';
import LowStockItemList from './Item/LowStockItemList';
import ItemList from './Item/ItemList';
import ItemAdd from './Item/ItemAdd';

function ItemMaster({user}) {
  return (
    <div className='mt-6 md:mt-0'>
        <PageTitleComponent title='Item Master' />
            <div className="parent-category">
                <Tabs>
                    <TabList className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 tb-list bg-[#fff] shadow-xl p-3 text-center  rounded-[5px]'>

                    {user?.menu_permissions?.includes("2.3.6") && (
                        <Tab>Item List</Tab>
                    )}

                    {user?.menu_permissions?.includes("2.3.6") && (
                        <Tab>Low Stock Item List</Tab>
                    )}

                    {user?.menu_permissions?.includes("2.1.6") && (
                        <Tab>Item Add</Tab>
                    )}
                </TabList>

                {user?.menu_permissions?.includes("2.3.6") && (
                <TabPanel>
                    <ItemList />
                </TabPanel>
                )}

                {user?.menu_permissions?.includes("2.3.6") && (
                <TabPanel>
                    <LowStockItemList />
                </TabPanel>
                )}

                {user?.menu_permissions?.includes("2.1.6") && (
                <TabPanel>
                    <ItemAdd />
                </TabPanel>
                )}

            </Tabs>
        </div>



    </div>

  )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(ItemMaster);