import React, { useEffect, useState } from 'react'
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import FormPageTitle from '../../../Components/Form/FormPageTitle';
import { useParams } from 'react-router-dom';
import ShowToast from '../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';

function CloseTicketStatusChange() {

    // #status change 
    const {id} = useParams()
    const [subStatusOption, setSubStatusOption] = useState(null);
    const [selectedSubStatus, setSelectedSubStatus] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [ticketData, setTicketData] = useState(null);

    const [statusForm, setStatusForm] = useState({
        status_text: '',
        sub_status:null
    })

    const statusOption = [
        {value:'Close',label:'Close'},
        {value:'Complete',label:'Complete'},

    ]


    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-single-ticket/${id}/`);
            console.log('ticket',response.data);

            setTicketData(response.data);

            setStatusForm(prevState => ({
                ...prevState,
                status_text: response.data.status_text,
                sub_status: response.data.sub_status,
            }));

            setSelectedStatus({
              value: response.data.status_text,
              lable: response.data.status_text
            })

            setSelectedSubStatus({
                value: response.data.sub_status,
                label: response.data.sub_status_text,
                status:response.data.status_text
            })

        } catch (error) {
            console.log(error);
        }
        };
        fetchTicketData();
    }, [id]);


    useEffect(() => {
        const fetchSubStatusData = async () => {
          try {
            const response = await authAxiosWithBearer.get(`${baseURL}/api/sub-status-list/`);
            setSubStatusOption(
              response?.data?.results?.map((data) => ({
                value: data.id,
                label: data.title,
                status: data.status_text,
              }))
            );
          } catch (error) {
            console.log(error);

          }
        };
        fetchSubStatusData();
      }, []);

      

    const handleStatusChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== '') {
        setStatusForm((prevState) => ({ ...prevState, status_text: selectedOption.value }));
        setSelectedStatus(selectedOption);
        setSelectedSubStatus(null);
    } else {
        setStatusForm((prevState) => ({ ...prevState, status_text: '' }));
        setSelectedStatus(null); 
        setSelectedSubStatus(null);
    }
    };


    const handleSubStatusChange = (selectedOption) => {
        const sub_status_id = selectedOption ? selectedOption.value : null;
        setSelectedSubStatus(selectedOption);
        setStatusForm((prevFormData) => ({
          ...prevFormData,
          sub_status: sub_status_id,
        }));
      };
    
    const filteredSubStatus = subStatusOption?.filter((subStatus) => subStatus?.status === selectedStatus?.value);
      
    const handleStatusChangeSubmit = async (e)=>{
        e.preventDefault()
        try {
            const response = await authAxiosWithBearer.put(`${baseURL}/ccd/api/close-ticket-status-change/${id}/`,statusForm)
            if (response) {
                console.log(response);
                ShowToast('success', 'Successfully Updated');
              }
        } catch (error) {
            console.log(error)
            ShowToast('error', `${error.response.data.error}`);
        }
    }


    console.log(selectedStatus)

  return (
    <div>
        <form className='shadow-lg mb-10 border pb-6 mx-3' onSubmit={handleStatusChangeSubmit}>
            <FormPageTitle title='Ticket Status' />
            <div className='md:flex justify-between md:mx-5'>
                <div className='my-7 md:my-2'>
                    <SelectInputWC
                        className='appearance-none border-[1px] w-[100%] md:w-[270px] lg:w-[400px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        id='status'
                        label='Status*'
                        name='status'
                        value={statusOption.find((option) => option?.value === statusForm.status_text)}
                        onChange={handleStatusChange}
                        options={statusOption}
                        placeholder='Select Status*'
                        isClearable={true}
                        isRequired={false}
                    />
                </div>
                <div className='mt-7 mb-3 md:my-2'>
                    <SelectInputWC
                        className='appearance-none border-[1px] w-[100%] md:w-[270px] lg:w-[400px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                        id='sub_status'
                        label='Sub Status'
                        name='sub_status'
                        value={selectedSubStatus}
                        onChange={handleSubStatusChange}
                        options={filteredSubStatus}
                        isRequired={false}
                        placeholder='Select Status'
                        isClearable={true}
                    />
                </div>
                <div className='my-auto'>
                    <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Change</button>
                </div>
            </div>
        </form>
    </div>
  )
}

export default CloseTicketStatusChange