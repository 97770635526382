import React, { useEffect, useState } from 'react'

const TotalPackagePrice = ({packageData}) => {
    const [totalPrice, setTotalPrice] = useState(null);
    
    useEffect(() => {
      // Calculate total price when packageData changes
      const calculateTotalPrice = () => {
        if (packageData && packageData?.length > 0) {
          const total = packageData.reduce((acc, item) => {
            const allPackageTotal = item?.package_quantity && item?.package_price
            ? (parseFloat(item.package_quantity) * parseFloat(item.package_price)): 0; 
            return acc + allPackageTotal;
          }, 0);
  
          const finalTotal = total;
  
          // Update the state with the calculated total price
          setTotalPrice(finalTotal.toFixed(2));
        } else {
          // If packageData or workorderitem_set is empty or null, set total price to null
          setTotalPrice(null);
        }
      };
  
      calculateTotalPrice();
    }, [packageData]);

  return (
    <div>
        {totalPrice && totalPrice}
    </div>
  )
}

export default TotalPackagePrice