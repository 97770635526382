import React from 'react'
import Checkbox from '../../../Components/Form/Checkbox';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import TextareaInputWC from '../../../Components/FormWithoutClass/TextareaInputWC';

const EditFormInputFiledForCCD = ({
    formData,
    setFormData,
    handleInputChange,
    loading,
    serviceCategoryOption,
    handleServiceCategoryChange,
  }) => {

  console.log('serviceCategoryOption',serviceCategoryOption)

  return (
    <>
    
      <div className="grid grid-cols-1 lg:grid-cols-3">
          <div  className='my-4 h-[42px] border rounded-[3px] border-[#9d9c9c] shadow-md md:w-[330px] px-2 w-[98%] mx-1 '>
              <Checkbox type='checkbox'  
                  checked={formData.requested_status} 
                  onChange={handleInputChange} 
                  name='requested_status' id='requested_status' label='Requested Status' 
                  isRequired={true}
                  className={`w-[99%]`}
              />
          </div>
          <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.ticket_no} 
              onChange={handleInputChange} 
              name='ticket_no' id='ticket_no'  
              label='Ticket_no*'
              isRequired={true} 
              placeholder='Enter Ticket_no' 
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>
          <div className="my-4 text-center">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>{loading ? 'Submitting...' : 'Submit'}</button>
          </div>
      </div>

      <hr className='mb-4' />

      <div className="grid grid-cols-1 lg:grid-cols-3">

      <div  className='my-4'>
              <SelectInputWC
                  name='service_category'
                  id='service_category'
                  label='Service Category*'
                  value={serviceCategoryOption?.find((option) => option?.value === formData?.service_category)}
                  // onChange={handleServiceCategoryChange}
                  options={serviceCategoryOption}
                  isRequired={true}
                  className='w-[98%] mx-1 md:w-[330px]'
                />
        </div>

        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.customer_name} 
              // onChange={handleInputChange} 
              name='customer_name' id='customer_name'  
              label='Customer Name*'
              isRequired={true} 
              placeholder='Enter customer name' 
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>
        <div className='my-4'>
            <InputFiledWC type='number' 
              value={formData.customer_phone} 
              // onChange={handleInputChange} 
              name='customer_phone' id='customer_phone'  
              label='Customer Phone*'
              isRequired={true} 
              placeholder='Enter customer phone'
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>
        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.customer_address} 
              // onChange={handleInputChange} 
              name='customer_address' id='customer_address'  
              label='Customer Address*'
              isRequired={true} 
              placeholder='Enter customer address' 
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>
        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.invoice_no} 
              // onChange={handleInputChange} 
              name='invoice_no' id='invoice_no'  
              label='Invoice*'
              isRequired={true} 
              placeholder='Enter invoice no'
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>
        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.product_name} 
              // onChange={handleInputChange} 
              name='product_name' id='product_name'  
              label='Product Name*'
              isRequired={true} 
              placeholder='Enter product name'
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>
        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.product_code} 
              // onChange={handleInputChange} 
              name='product_code' id='product_code'  
              label='Product Code'
              isRequired={false} 
              placeholder='Enter customer address' 
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>

        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.basic_charge} 
              // onChange={handleInputChange} 
              name='basic_charge' id='basic_charge'  
              label='Basic Charge'
              isRequired={false} 
              placeholder='Enter basic charge'
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
        </div>

        <div className='my-4'>
            <InputFiledWC type='text' 
              value={formData.transaction_no} 
              // onChange={handleInputChange} 
              name='transaction_no' id='transaction_no'  
              label='Transaction No'
              isRequired={false} 
              placeholder='Enter transaction no'
              className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
         </div>
        
          <div  className='my-4 h-[42px] border rounded-[3px] border-[#9d9c9c] shadow-md md:w-[330px] px-2 w-[98%] mx-1 '>
              <Checkbox type='checkbox'  
                  checked={formData.pay_status} 
                  // onChange={handleInputChange} 
                  name='pay_status' id='pay_status' label='Payment Status' 
                  isRequired={false}
                  className={`w-[99%]`}
              />
          </div>

        <div className='my-4 border rounded-[3px] h-[42px] border-[#9d9c9c] shadow-md  md:w-[330px] px-2 w-[98%] mx-1'>
              <Checkbox type='checkbox'  
                  checked={formData.warranty_status} 
                  // onChange={handleInputChange} 
                  name='warranty_status' id='warranty_status' label='Warranty Status' 
                  isRequired={false}
                  className={`w-[99%]`}
              />
          </div>

          <div className='my-4'>
            <TextareaInputWC type='text' 
              value={formData.remarks} 
              // onChange={handleInputChange} 
              name='remarks' id='remarks'  
              label='Remarks'
              isRequired={false} 
              placeholder='Enter remarks'
              className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[98%] mx-1 md:w-[330px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
            />
          </div>


      </div>

        </>
  )
}

export default EditFormInputFiledForCCD