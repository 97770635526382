import React, { useState, useEffect } from 'react';

function TotalIHSIssueItemPriceInWord({ srIssuedData }) {
  const [totalPrice, setTotalPrice] = useState(null);
  const [totalPriceInWords, setTotalPriceInWords] = useState(null);

  // Function to convert a number into words
  const convertToWords = (num) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  
    const convertThreeDigits = (num) => {
      let result = '';
      const hundreds = Math.floor(num / 100);
      const remainder = num % 100;
  
      if (hundreds > 0) {
        result += units[hundreds] + ' Hundred';
      }
  
      if (remainder > 0) {
        if (result !== '') {
          result += ' and ';
        }
  
        if (remainder < 10) {
          result += units[remainder];
        } else if (remainder < 20) {
          result += teens[remainder - 11];
        } else {
          const tensDigit = Math.floor(remainder / 10);
          const unitsDigit = remainder % 10;
          result += tens[tensDigit];
          if (unitsDigit > 0) {
            result += ' ' + units[unitsDigit];
          }
        }
      }
  
      return result;
    };
  
    const convertToWordsThousands = (num) => {
      const thousands = Math.floor(num / 1000);
      const remainder = num % 1000;
      let result = '';
  
      if (thousands > 0) {
        result += convertThreeDigits(thousands) + ' Thousand';
      }
  
      if (remainder > 0) {
        if (result !== '') {
          result += ' ';
        }
  
        result += convertThreeDigits(remainder);
      }
  
      return result;
    };
  
    const integerPart = Math.floor(num);
    const decimalPart = Math.round((num - integerPart) * 100); // Get decimal part as paisa
  
    let result = convertToWordsThousands(integerPart);
  
    if (result !== '') {
      result += ' Taka'; // Append Taka for integer part
    }
  
    if (decimalPart > 0) {
      if (result !== '') {
        result += ' and ';
      }
      result += convertToWordsThousands(decimalPart) + ' Paisa'; // Append Paisa for decimal part
    }
  
    return result !== '' ? result : 'Zero Taka'; // Return 'Zero Taka' if num is 0
  };
  


  useEffect(() => {
    const calculateTotalPrice = () => {
      let total = 0;


      srIssuedData.forEach((item) => {
        // Ensure item has properties issue_qty and item_sales_price before calculation
        const issueQty = parseFloat(item.issue_qty);
        const itemSalesPrice = parseFloat(item.item_sales_price);
        if (!isNaN(issueQty) && !isNaN(itemSalesPrice)) {
          total += issueQty * itemSalesPrice;
        }
      });

      // Convert total price to words
      setTotalPriceInWords(convertToWords(total));
      // Update the state with the calculated total price
      setTotalPrice(total.toFixed(2));
    };

    calculateTotalPrice();
  }, [srIssuedData]);


  return (
      <>{totalPriceInWords !== null ? totalPriceInWords : 'N/A'}</>
  );
}

export default TotalIHSIssueItemPriceInWord;
