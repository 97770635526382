import React, { useEffect, useState } from 'react'

const TotalIHSIssueItemPrice = ({srIssuedData}) => {
  const [totalPrice, setTotalPrice] = useState(null);
    
  useEffect(() => {
    const calculateTotalPrice = () => {
      let total = 0;


      srIssuedData?.forEach((item) => {
        // Ensure item has properties issue_qty and item_sales_price before calculation
        const issueQty = parseFloat(item?.issue_qty);
        const itemSalesPrice = parseFloat(item?.item_sales_price);
        if (!isNaN(issueQty) && !isNaN(itemSalesPrice)) {
          total += issueQty * itemSalesPrice;
        }
      });


      setTotalPrice(total.toFixed(2));
    };

    calculateTotalPrice();
  }, [srIssuedData]);



return (
  <div>
      {totalPrice && totalPrice}
  </div>
)
}

export default TotalIHSIssueItemPrice