import React from 'react'
import RequestedTicketListComponent from './ShowroomTicketRequestComponent/RequestedTicketListComponent'

const PendingRequestedTicketListForShowroom = () => {
  return (
    <div>
        <RequestedTicketListComponent 
            apiEndpoint={'ccd/api/pending-requested-ticket-list-for-showroom/'}
            pageTitle={'Pending Requests LIST'}
            editUrl={`/requested-ticket-update`}
        />
    </div>
  )
}

export default PendingRequestedTicketListForShowroom