import React from 'react'

const ScbccDetailsComponents = ({scbccTicketData,openInvoicedModal}) => {
  return (
      <div className="grid md:grid-cols-2 px-3 my-4">
          <div className='flex flex-wrap my-3'>
              <div className='w-[35%]'>
                  Invoice_no
              </div>
              <div className='w-[65%] text-end pr-[5px] border'>

                  {scbccTicketData?.sb_invoice_no}
                  <button
                      onClick={() => openInvoicedModal(scbccTicketData?.sb_invoice_no)}
                      className='bg-[#FA6669] rounded-[4px] px-[5px] py-[1px] text-[#fff] ml-4'
                  >
                      Details
                  </button>
              </div>
          </div>

          <div className='flex flex-wrap my-3 md:pl-[50px]'>
              <div className='w-[35%]'>
                  Product Category
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.product_category_name}
              </div>
          </div>

          <div className='flex flex-wrap my-3'>
              <div className='w-[35%]'>
                  Model No
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.model_name}
              </div>
          </div>

          <div className='flex flex-wrap my-3 md:pl-[50px]'>
              <div className='w-[35%]'>
                  Product Brand
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.sb_product_brand_name}
              </div>
          </div>

          <div className='flex flex-wrap my-3'>
              <div className='w-[35%]'>
                  Purchase Date
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.purchase_date}
              </div>
          </div>

          <div className='flex flex-wrap my-3 md:pl-[50px]'>
              <div className='w-[35%]'>
                  Showroom
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.showroom_name}
              </div>
          </div>

          <div className='flex flex-wrap my-3'>
              <div className='w-[35%]'>
                  Dealer_name
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.dealer_name}
              </div>
          </div>

          <div className='flex flex-wrap my-3 md:pl-[50px]'>
              <div className='w-[35%]'>
                  Complaint Details
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.other_details}
              </div>
          </div>

          <div className='flex flex-wrap my-3'>
              <div className='w-[35%]'>
                  Basic Charge Title
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.sb_basic_charge_title}
              </div>
          </div>

          <div className='flex flex-wrap my-3 md:pl-[50px]'>
              <div className='w-[35%]'>
                  Basic Charge Amount
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.sb_basic_charge_amount}
              </div>
          </div>

          <div className='flex flex-wrap my-3'>
              <div className='w-[35%]'>
                  Warranty Exp Date
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.warranty_exp_date}
              </div>
          </div>

          <div className='flex flex-wrap my-3 md:pl-[50px]'>
              <div className='w-[35%]'>
                  Warranty Status
              </div>
              <div className='w-[65%] text-end border pr-[5px]'>
                  {scbccTicketData?.warranty_status === true? 'Warranty':'Expired'}
              </div>
          </div>

      </div>
  )
}

export default ScbccDetailsComponents