import React, { useEffect, useState } from 'react'
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { useNavigate } from 'react-router-dom';

const MrCreateHandleComponent = ({
  id,
  ActionURL,
  redirectURL,
  setModalOpen,
  fetchDetailsData,
}) => {
    const [formData,setFormData] = useState({
      invoice_no : null,
      pay_type : '',
      pay_amount : '',
      transaction_no : '',
      mr_person_name : '',
      mr_person_contact_no : '',
    })
    const navigate = useNavigate();

    const payTypeOption = [
      { value: 'Cash', label: 'Cash' },
      { value: 'Mobile Banking', label: 'Mobile Banking' },
      { value: 'Cheque', label: 'Cheque' },
  ];


  const handleInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      let inputValue;

      if (type === 'checkbox') {
          inputValue = checked;
      } else if (type === 'file') {
          inputValue = files[0];
      } else {
          inputValue = value;

          if (parseFloat(value) < 0) {
            ShowToast('error', `Negative values are not allowed for ${name}.`);
            return;
          }

          if (['pay_amount'].includes(name)) {
              const parts = value.split('.');
              const integerPart = parts[0];
              const decimalPart = parts[1] || '';

              // If the total number of digits exceeds 20, show an error message
              if (integerPart.length > 14) {
                  ShowToast( 'error', `Ensure that there are no more than 14 digits`);
                  return;
              }

              // If decimal part exists, limit it to 6 decimal places
              if (parts.length === 2) {
                  inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;

                  // If more than 6 digits after the decimal point, show a separate error message
                  if (decimalPart.length > 2) {
                      ShowToast('error',`More than 2 digits after the decimal point for ${name}.`);
                  }
              }
          }
      }

      setFormData({
          ...formData,
          [name]: inputValue
      });
  };


  const handlePayTypeOptionChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
      setFormData(prevState => ({ ...prevState, pay_type: selectedOption.value }));
    } else {
      setFormData(prevState => ({ ...prevState, pay_type: null }));
    }
  };



  useEffect(() => {
    const basicAmount = parseFloat(formData.basic_amount || 0);
    const basicAmountQuantity = parseFloat(formData.basic_amount_quantity || 0);
    const additionalAmount = parseFloat(formData.additional_amount || 0);
    const totalAmount= (basicAmount * basicAmountQuantity) + additionalAmount;
    setFormData(prevState => ({ ...prevState, total_payable_amount: totalAmount }));
  }, [formData.basic_amount, formData.basic_amount_quantity, formData.additional_amount]);




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const UpdateFormData = {
        ...formData,
        invoice_no : id? id : null,
      }
      const response = await authAxiosWithBearer.post(`${baseURL}/${ActionURL}/`, UpdateFormData);

      if (response) {
        console.log(response);
        setModalOpen(false)
        ShowToast('success', 'Successfully Submit');
        fetchDetailsData()
        navigate(`/${redirectURL}/${id}`);
      }
    } catch (error) {
      console.log(error);
      if(error.response.data.error){
        ShowToast('error', `${error.response.data.error}`);
      }else{
        ShowToast('error', 'Something is wrong');
      }
    }
  };
    

  return {
    formData,
    setFormData,
    handleInputChange,
    handleSubmit,
    payTypeOption,
    handlePayTypeOptionChange,
  }
}

export default MrCreateHandleComponent