import React, { useEffect, useState } from 'react'
import SelectInputWC from '../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../Components/FormWithoutClass/InputFiledWC';
import { AiFillDelete } from 'react-icons/ai';
import ShowToast from '../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { useNavigate } from 'react-router-dom';

const TechnicianSRFormComponent = ({ticketData,id}) => {
        const navigate = useNavigate()
        // requision
        const [requisionRequiredMessage,setRequisionRequiredMessage]=useState('')

        const [requisionItemCategoriesOptions, setRequisionItemCategoriesOptions] = useState([]);
        const [selectedRequisionItemCategories, setSelectedRequisionItemCategories] = useState([]);
    
        const [requisionItemOptions, setRequisionItemOptions] = useState([]);
        const [selectedRequisionItem, setSelectedRequisionItem] = useState([]);
        const [itemSearchInputValue, setItemSearchInputValue] = useState(null);
    
        const [requisionFormData, setRequisionFormData] = useState({
            ticket: '',
            invreqdetail_set: [
              {
                item_category: null,
                item: null,
                assigned_ticket:id,
                quantity: '',
                uom_value:null,
              },
            ],
          });
    
        
        // fetchItemCategoryData
        useEffect(() => {
            const fetchItemCategoriesData = async () => {
                try {
                    const response = await authAxiosWithBearer.get(`${baseURL}/inventory/api/inv-sub-categories-list/`);
                    console.log('fetchItemCategoriesData',response.data.results);
                    const options = response.data.results?.map((cate) => ({
                        value:  cate.id,
                        label: `${cate?.name}`,
                    }));
                    setRequisionItemCategoriesOptions(options);
    
                } catch (error) {
                    console.log(error);
                }
            };
    
            fetchItemCategoriesData();
        }, []);
    
    
    
    
        useEffect(() => {
            authAxiosWithBearer.get(`${baseURL}/inventory/api/item-master/?search=${itemSearchInputValue}`)
                .then(response => {
                const filteredOptions = response.data.results
                .filter(item => selectedRequisionItemCategories.some(category => category.value === item.sub_category))
                .map(data => ({
                    value: data.id,
                    label : `${data.item_name} | ${data.specification} | ${data.size} | ${data.brand} | ${data.model}`,
                    uom_value: data.uom_text
                }));
    
                console.log('filteredOptions',filteredOptions)    
                setRequisionItemOptions(filteredOptions);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }, [itemSearchInputValue,selectedRequisionItemCategories]);
    
        
        const handleItemSearchInputChange = (value) => {
        setItemSearchInputValue(value);
        };
    
    
    
        const handleRequisionItemCategoryChange = (selectedOption, index) => {
            const updatedFormData = { ...requisionFormData };
            updatedFormData.invreqdetail_set[index].item_category = selectedOption?.value || null;
        
            const updatedSelectedRequisionItemCategory = [...selectedRequisionItemCategories];
            updatedSelectedRequisionItemCategory[index] = selectedOption || null;
        
            setSelectedRequisionItemCategories(updatedSelectedRequisionItemCategory);
            setRequisionFormData(updatedFormData);
        };
        
    
        const handleRequisionItemChange = (selectedOption, index) => {
            const updatedFormData = { ...requisionFormData };
            updatedFormData.invreqdetail_set[index].item = selectedOption?.value || null;
            updatedFormData.invreqdetail_set[index].uom_value = selectedOption?.uom_value || null;
        
            const updatedSelectedRequisionItem = [...selectedRequisionItem];
            updatedSelectedRequisionItem[index] = selectedOption || null;
        
            setSelectedRequisionItem(updatedSelectedRequisionItem);
            setRequisionFormData(updatedFormData);
        };
    
    
        const handleRequisionInputChange = (e, index, type) => {
            const updatedData = { ...requisionFormData };
            const { name } = e.target;
            let value = e.target.value;  
          
            if (type === 'invreqdetail_set' && name === 'quantity') {
              const parts = value.split('.');
              const integerPart = parts[0];
              const decimalPart = parts[1] || '';
          
              if (integerPart.length > 12) {
                ShowToast('error', `Ensure that there are no more than 12 digits`);
                return;
              }
          
              if (parts.length === 2) {
                value = `${integerPart}.${decimalPart.slice(0, 2)}`;
          
                if (decimalPart.length > 2) {
                  ShowToast('error', `More than 2 digits after the decimal point for ${name}.`);
                }
              }
            }
          
            if (type === 'invreqdetail_set') {
              setRequisionRequiredMessage('');
              updatedData.invreqdetail_set[index][name] = value;
            } else {
              updatedData[name] = value;
            }
          
            setRequisionFormData(updatedData);
        };
          
        
          const areAllFieldsFilled = () => {
            return (
                requisionFormData.invreqdetail_set &&
                requisionFormData.invreqdetail_set.every((item) => {
                    return item?.item !== null && item?.quantity !== '';
                })
            );
        };
        
          
        const handleAddRequisionItem = () => {
            if (areAllFieldsFilled()) {
                setRequisionFormData((prevState) => ({
                    ...prevState,
                    invreqdetail_set: [
                        ...prevState.invreqdetail_set,
                        {
                            item: null,
                            quantity: '',
                        },
                    ],
                }));
                setRequisionRequiredMessage('');
            } else {
                setRequisionRequiredMessage("Please fill all fields in the existing field before adding a new one.");
            }
        };
        
        
        
        const handleRemoveRequisionItem = (index) => {
        const invreqdetail_set = [...requisionFormData.invreqdetail_set];
        setRequisionRequiredMessage('');
        invreqdetail_set.splice(index, 1);
        setRequisionFormData({
            ...requisionFormData,
            invreqdetail_set,
        });
        };
    
    
        const handleRequisionSubmit = async (e) => {
            e.preventDefault();
            try {
                const updatedFormData = { 
                    ...requisionFormData, 
                    ticket: ticketData?.ticket
                };
                console.log(updatedFormData);
                const response = await authAxiosWithBearer.post(`${baseURL}/inventory/api/inv-requisition/`, updatedFormData);
        
                if (response) {
                    console.log(response);
                    ShowToast('success', 'Successfully Submit');
                    navigate('/technician-wise-ticket-list');
                }
            } catch (error) {
                console.log(error);
            }
        };

  return (
    <div>
        {ticketData?.sub_status_text !== "Requested For Finish" && (
            <div className="shadow-lg pt-1 px-2 my-5 pb-10 bg-[#fff]">
                <div className='information-head my-auto px-10 py-2'>
                    <div>DIAGNOSIS</div>
                </div>

                <form onSubmit={handleRequisionSubmit}>
                    {requisionFormData.invreqdetail_set?.map((requisionItems, index) => (
                    <div className='grid grid-cols-2 lg:grid-cols-4 my-2 mx-2 gap-y-2 gap-x-2 border px-1'>
                        
                        <div className="mt-7 mb-2">
                        <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item Category*'
                                id={`item_category_${index}`}
                                name={`invreqdetail_set[${index}]?.item_category`}
                                value={selectedRequisionItemCategories[index]}
                                onChange={(selectedOption) => handleRequisionItemCategoryChange(selectedOption, index)}
                                // options={requisionItemCategoriesOptions.filter(
                                //     (option) =>
                                //         !selectedRequisionItemCategories.some(
                                //             (selectedItem) => selectedItem && selectedItem.value === option.value
                                //         )
                                // )}
                                options={requisionItemCategoriesOptions}
                                placeholder='Select item category'
                                isRequired={true}
                                isClearable={true}
                            />
                        </div>
                        
                        <div className="mt-7 mb-2">
                            <SelectInputWC
                                className={`appearance-none border-[1px] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                label='Item*'
                                id={`item_${index}`}
                                name={`invreqdetail_set[${index}]?.item`}
                                value={selectedRequisionItem[index]}
                                onInputChange={handleItemSearchInputChange}
                                onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                                options={requisionItemOptions.filter(
                                    (option) =>
                                        !selectedRequisionItem.some(
                                            (selectedItem) => selectedItem && selectedItem.value === option.value
                                        )
                                )}
                                placeholder='Select item'
                                isRequired={true}
                                isClearable={true}
                            />


                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC 
                                type='text'
                                label='UOM*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='uom_value'
                                id={`uom_value_${index}`}
                                value={requisionItems.uom_value}
                                // onChange={(e) => handleRequisionInputChange(e, index, 'invreqdetail_set')}
                                readOnly={true}
                                placeholder='uom'
                            />
                        </div>

                        <div className='mt-7 mb-2'>
                            <InputFiledWC 
                                type='number'
                                label='Quantity*'
                                className='pl-3 py-[9px] appearance-none border-[1px] border-[#9d9c9c] w-[100%] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                name='quantity'
                                id={`quantity_${index}`}
                                value={requisionItems.quantity}
                                onChange={(e) => handleRequisionInputChange(e, index, 'invreqdetail_set')}
                                isRequired={true}
                                placeholder='Enter quantity'
                                min={0}
                            />
                        </div>
                        {index !== 0 && (
                        <div className='mt-9 mx-10 mb-2'>
                            
                                <button
                                type="button"
                                onClick={() => handleRemoveRequisionItem(index)}
                                className="text-red-500"
                                >
                                <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                </button>
                        </div>
                        )}
                    </div>
                    ))}

                    {requisionRequiredMessage && (
                        <span className='text-red-500 mx-4'>{requisionRequiredMessage} <br /> </span> 
                    )}
                    <div className="flex justify-between mx-4 my-2">

                        <button 
                            className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                            type="button"
                            onClick={() => {
                                console.log('Add new item Button Clicked');
                                handleAddRequisionItem();
                            }}
                            >ADD MORE</button>
                        <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
                    </div>
                </form>

            </div>
        )}
    </div>
  )
}

export default TechnicianSRFormComponent