import React from 'react';
import TechnicianOrVendorListForCompletedDeliveryResource from './TechnicianOrVendorListForCompletedDeliveryResource';


const TechnicianListForCompletedDeliveryResource = ({detailsData,fetchDeatilsData}) => {


  return (
    <>
    <TechnicianOrVendorListForCompletedDeliveryResource 
      title={'Technician List'}
      apiEndPoint={'/api/technician-list/'}
      detailsData={detailsData}
      fetchDeatilsData={fetchDeatilsData}
    />
    </>
  );
};




  export default TechnicianListForCompletedDeliveryResource;
