import React, { useEffect, useState } from 'react';
import { Table} from 'antd';
import { AiFillEye } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ListPageHeadWithDateRange from '../../../../Components/PageHeadComponents/ListPageHeadWithDateRange';
import CustomPaginator from '../../../../Components/CustomPaginator';
import useFetchListData from '../../../../hooks/useFetchListData';
import { formatDateTime } from '../../../../Components/formatDateTime';
import LoadingSpinner from '../../../../Components/LoadingSpinner';

const ATListComponentForCompletedDeliveryResource = ({user,apiEndPoint,title,detailsURL,detailsURLPermission}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const params = {
    page: currentPage,
    search: searchText,
    start_date: startDate,
    end_date: endDate,     
  };

  const { data, totalItems, isLoading, error, fetchData } = useFetchListData(`${apiEndPoint}`, params);


  useEffect(() => {
    fetchData();
  }, [currentPage, searchText, endDate, startDate]);


  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    ticket_no: data?.ticket_no,
    technician_employee_id:data?.technician_employee_id, 
    technician_name:data?.technician_name,
    technician_phone: data?.technician_phone,
    assigned_status: data?.assigned_status,
    product_received_status: data?.product_received_status,
    created_at: data?.created_at,
  }))


  const columns = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: '1',
    },
    {
        title: 'Technician',
        dataIndex: 'technician_employee_id',
        key: '2',
    },
    {
        title: 'Technician Name',
        dataIndex: 'technician_name',
        key: '3',
    },
    {
        title: 'Technician Phone',
        dataIndex: 'technician_phone',
        key: '4',
        responsive: ['md']
    },
    {
      title: 'Status',
      dataIndex: 'assigned_status',
      key: '5',
      render: (text, record) => {
        const statusClass = record.assigned_status === 'Job Assigned' 
          ? 'bg-red-500' 
          : record.assigned_status === 'Job Started' 
          ? 'bg-blue-500' 
          : record.assigned_status === 'Job Completed' 
          ? 'bg-green-500' 
          : 'bg-transparent';
  
        return (
          <div className={`px-2 py-1 w-[136px] rounded text-center text-white ${statusClass}`}>
              <div>{record.assigned_status} </div>
          </div>
        );
      },
    },
    {
      title: 'Assigned At',
      dataIndex: 'created_at',
      key: '5',
      render: (created_at) => {
        const formattedDateTime = created_at? formatDateTime(created_at):'';
        return <span>{formattedDateTime}</span>;
      },
    },
    {
        title: 'ACTIONS',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className='flex gap-2'>
            {user?.menu_permissions?.includes(`${detailsURLPermission}`) && (
              <button onClick={() => navigate(`/${detailsURL}/${record.id}/`)} className='rounded'>
                <AiFillEye className='text-[#6969f5] text-xl' />
              </button>
            )}
          </div>
        ),
      },
  ];


  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>

        <ListPageHeadWithDateRange
                pageTitle = {title}
                setEndDate = {setEndDate}
                endDate = {endDate}
                setStartDate = {setStartDate}
                startDate = {startDate}
                setSearchText = {setSearchText}
                // addNewURL = {'add'}
                // addNewUrlPermissionCode = {'3.15.1'}
          />


      <div className='shadow-lg overflow-x-auto'>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
          <CustomPaginator
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalItems={totalItems}
        />
      </div>
      </>
      )}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(ATListComponentForCompletedDeliveryResource);
