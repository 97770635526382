import React, { useEffect, useState } from 'react'
import ShowToast from '../../../../Components/ShowToast';
import { authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { useNavigate } from 'react-router-dom';

const CustomerInvoiceCreateHandleComponent = ({
  scbccWpExpireTicketNo,
  reInstallationTicketNo,
  installationTicketNo,
  detailsData,
  srIssuedData 
}) => {
    const [formData,setFormData] = useState({
        ticket : null,
        customer : null,
        installation : null,
        service : null,
        basic_charge : '',
        basic_charge_quantity:'',
        additional_charge : '',
        total_amount_without_discount : '',
        discount_type:'',
        discount_amount : '',
        total_payable_amount : '',
    })
    const navigate = useNavigate();
    const [discountTypeRequired, setDiscountTypeRequired] = useState(false);
    const [discountAmountRequired, setDiscountAmountRequired] = useState(false);


    const discountTypeOptions = [
      { value: 'Direct Amount', label: 'Direct Amount' },
      { value: 'Percentage', label: 'Percentage' },
    ];


    useEffect(() => {
      if (formData.discount_type && !formData.discount_amount) {
          setDiscountAmountRequired(true);
      } else {
          setDiscountAmountRequired(false);
      }
  }, [formData.discount_type, formData.discount_amount]);


  useEffect(() => {
    if (!formData.discount_type && formData.discount_amount) {
      setDiscountTypeRequired(true);
    } else {
      setDiscountTypeRequired(false);
    }
}, [formData.discount_type, formData.discount_amount]);


  const maxDiscountAmount = formData.discount_type === 'Direct Amount' ? formData.total_amount_without_discount : 100;

  const handleInputChange = (e) => {
      const { name, value, type, checked, files } = e.target;
      let inputValue;

      if (type === 'checkbox') {
          inputValue = checked;
      } else if (type === 'file') {
          inputValue = files[0];
      } else {
          inputValue = value;

          if (parseFloat(value) < 0) {
            ShowToast('error', `Negative values are not allowed for ${name}.`);
            return;
          }

          if (['basic_charge','additional_charge', 'total_amount_without_discount','discount_amount', 'total_payable_amount'].includes(name)) {
              const parts = value.split('.');
              const integerPart = parts[0];
              const decimalPart = parts[1] || '';

              // If the total number of digits exceeds 20, show an error message
              if (integerPart.length > 14) {
                  ShowToast( 'error', `Ensure that there are no more than 14 digits`);
                  return;
              }

              // If decimal part exists, limit it to 6 decimal places
              if (parts.length === 2) {
                  inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;

                  // If more than 6 digits after the decimal point, show a separate error message
                  if (decimalPart.length > 2) {
                      ShowToast('error',`More than 2 digits after the decimal point for ${name}.`);
                  }
              }
          }


          if (name === 'discount_amount' && value > maxDiscountAmount) {
            ShowToast('error', `Discount amount cannot exceed ${maxDiscountAmount}`);
            return;
          }

          if (name === 'discount_type' && !value) {
              setDiscountTypeRequired(true);
          } else {
              setDiscountTypeRequired(false);
          }
            // Check if discount amount is entered and discount type is empty
          if (name === 'discount_amount' && value && !formData.discount_type) {
              setDiscountAmountRequired(true);
          } else {
              setDiscountAmountRequired(false);
          }
      }

      setFormData({
          ...formData,
          [name]: inputValue
      });
  };

  const handleDiscountTypeChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== null) {
      setFormData(prevState => ({ ...prevState, discount_type: selectedOption.value }));
    } else {
      setFormData(prevState => ({ ...prevState, discount_type: null }));
    }
  };



  useEffect(() => {
    const basicCharge = parseFloat(formData.basic_charge || 0);
    const basicChargeQuantity = parseFloat(formData.basic_charge_quantity || 0);
    const additionalCharge = parseFloat(formData.additional_charge || 0);
    const totalAmountWithoutDiscount = (basicCharge * basicChargeQuantity) + additionalCharge;
    setFormData(prevState => ({ ...prevState, total_amount_without_discount: totalAmountWithoutDiscount }));
  }, [formData.basic_charge, formData.basic_charge_quantity, formData.additional_charge]);


  useEffect(() => {
    if (formData.discount_type === 'Direct Amount') {
        const discountAmount = parseFloat(formData.discount_amount || 0);
        const totalPayableAmount = formData.total_amount_without_discount - discountAmount;
        setFormData(prevState => ({ ...prevState, total_payable_amount: totalPayableAmount }));
    } else if (formData.discount_type === 'Percentage') {
        const discountAmount = parseFloat(formData.discount_amount || 0);
        const totalPayableAmount = (formData.total_amount_without_discount - (formData.total_amount_without_discount / 100 * discountAmount)).toFixed(2);
        setFormData(prevState => ({ ...prevState, total_payable_amount: totalPayableAmount }));
    }else{
      setFormData(prevState => ({ ...prevState, total_payable_amount: formData.total_amount_without_discount }));
    }
}, [formData.discount_type, formData.discount_amount, formData.total_amount_without_discount]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('detailsData.ticket',detailsData.ticket)
    try {
      const UpdateFormData = {
        ...formData,
        ticket  : detailsData.ticket !== null? detailsData.ticket : null,
        basic_charge_quantity:formData.basic_charge_quantity === ''? 0 : formData.basic_charge_quantity,
        customer: detailsData.customer_id? parseInt(detailsData.customer_id) : null,
        installation : reInstallationTicketNo? reInstallationTicketNo : installationTicketNo? installationTicketNo : null,
        service : scbccWpExpireTicketNo? scbccWpExpireTicketNo : null,
      }
      const response = await authAxiosWithBearer.post(`${baseURL}/billing/api/customer-invoice-create/`, UpdateFormData);

      if (response) {
        console.log(response);
        ShowToast('success', 'Successfully Submit');
        if(scbccWpExpireTicketNo){
          navigate('/scbcc-wp-expire-ticket-list-for-billing');
        }
        if(reInstallationTicketNo){
          navigate('/re-installations-ticket-list-for-billing');
        }
        if(installationTicketNo){
          navigate('/installations-ticket-list-for-billing');
        }
      }
    } catch (error) {
      console.log(error);
      if(error.response.data.error){
        ShowToast('error', `${error.response.data.error}`);
      }else{
        ShowToast('error', 'Something is wrong');
      }
    }
  };
    

  return {
    formData,
    setFormData,
    handleInputChange,
    discountTypeOptions,
    handleDiscountTypeChange,
    discountTypeRequired,
    discountAmountRequired,
    handleSubmit,
  }
}

export default CustomerInvoiceCreateHandleComponent