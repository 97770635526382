import React, { useEffect, useState } from 'react';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { AiFillEdit, AiFillEye, AiFillFileExcel, AiOutlineSearch } from 'react-icons/ai';
import { Table, DatePicker, Pagination } from 'antd';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';
import { BsFillPeopleFill } from 'react-icons/bs';

function CommonDataListTableForAT({
  apiEndpoint,
  columnsConfig,
  title,
  SearchKey1,
  SearchKey2,
  SearchKey3,
  SearchKey4,
  SearchKey5,
  sortOrder,
  setSortOrder,
  dataEditURL,
  SingleTicketViewURL,
  user,
  statusFiltering,
  currentPage,
  setCurrentPage,
}) {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate()
  const [ticketCategoris, setTicketCategoris] = useState(null)
  const [totalItems, setTotalItems] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(apiEndpoint, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText,
            ticketCategoris: ticketCategoris,
            status:statusFiltering,    
          },
        });
        
        console.log('response',response)
        const { results, count } = response.data;
        const resultsData = results.map((item,index) => ({ ...item, key: index+1 }));

        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally{
        setIsLoading(false)
      }
    };
    fetchData();
  }, [startDate, endDate, apiEndpoint, currentPage, searchText,ticketCategoris,statusFiltering]);


  

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleCategoryFilteringChange = (value) => {
    setTicketCategoris(value);
  };


  const applyTextSearchConditions = (item) => {
    return (
      item[SearchKey1]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey2]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey3]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey5]?.toLowerCase().includes(searchText.toLowerCase()) ||
      item[SearchKey4]?.join(', ').toLowerCase().includes(searchText.toLowerCase())
    );
  };
  
  const applyDateFilterConditions = (item) => {
    return (
      (!startDate || new Date(item.created_at) >= startDate) &&
      (!endDate || new Date(item.created_at) <= endDate)
    );
  };


  const filteredData = data?.filter((item) => {
    const textSearchCondition = applyTextSearchConditions(item);
    const dateFilterCondition = applyDateFilterConditions(item);
    return textSearchCondition && dateFilterCondition;
  });


  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  const handleExportToExcel = async () => {
    try {
      
    setIsLoading(true)
    const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/technician-assigned-tiket-export-to-excel/`, {
      params: {
        start_date: startDate,
        end_date: endDate,     
        search: searchText,
        ticketCategoris: ticketCategoris,
        status:statusFiltering,
      },
    });

    const exportData = response.data?.map(item => ({
      TicketNo: item?.ticket_no,
      'created at': item?.created_at,
      'SMS Sent': item?.sms_sent,
      categories: item?.category?.join(', ') || null,
      
      Customer: item?.customer_name || null,
      'Customer Phone': item?.customer_phone || null,
      'Customer Alt Phone': item?.customer_alt_mobile || null,
      'Customer Email': item?.email || null,
      'Customer Address': item?.address || null,
      'Customer PO': item?.customer_po || null,
      'Customer PS': item?.customer_ps || null,
      'Customer Type': item?.customer_type || null,


      'HVAC Company Name': item?.hvac_data[0]?.hvac_company_name || null,
      'HVAC Email': item?.hvac_data[0]?.hvac_email || null,
      'HVAC Address': item?.hvac_data[0]?.hvac_address || null,
      'HVAC Capacity': item?.hvac_data[0]?.capacity || null,


      'Installation Bill Invoice No': item?.installation_data[0]?.ib_invoice_no || null,
      'Installation Bill Product Category': item?.installation_data[0]?.ib_product_category?.name || null,
      'Installation Bill Product Model': item?.installation_data[0]?.model_no || null,
      'Installation Bill Other Info': item?.installation_data[0]?.other_info || null,
      'Installation Bill Quantity': item?.installation_data[0]?.quantity || null,


      'Service Bill Invoice No': item?.service_data[0]?.sb_invoice_no || null,
      'Service Bill Product Brand': item?.service_data[0]?.sb_product_brand?.name || null,
      'Service Bill Product Category': item?.service_data[0]?.sb_product_category?.name || null,
      'Service Bill Dealer Name': item?.service_data[0]?.dealer_name || null,
      'Service Bill Model Name': item?.service_data[0]?.model_name || null,
      'Service Bill Other Details': item?.service_data[0]?.other_details || null,
      'Service Bill Purchase Date': item?.service_data[0]?.purchase_date || null,
      'Service Bill Service Address': item?.service_data[0]?.service_address || null,
      'Service Bill Showroom Name': item?.service_data[0]?.showroom_name || null,

    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  } catch (error) {
    console.log(error)  
  }
  finally{
    setIsLoading(false)
  }
  };



  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
      <div className="page-header mb-[20px] md:mb-5 ">
        <div className="flex justify-between pt-4">
          <div className="page-title my-auto ml-1 md:ml-5 flex">
            <h1 className='uppercase '>{title}</h1>
            <div className="page-export-button px-2  hover:shadow-xl ">
              <button onClick={handleExportToExcel}><AiFillFileExcel className='text-[#145632] text-[25px]' /></button>
            </div>
          </div>
          <div className="page-search-and-add-new-button flex my-auto mr-1 md:mr-5">

            <div className='mx-2 hidden md:block'>
              <select
                value={ticketCategoris}
                onChange={(e) => handleCategoryFilteringChange(e.target.value)}
                className='text-[#74747B] text-[14px] font-semibold border rounded-[5px] focus:outline-none py-[5px] focus:bg-white focus:border-[#F35B5B]'
              >
                <option value=''>All Categories</option>
                <option value='6'>Insatllation Bill</option>
                <option value='7'>Service Bill</option>
              </select>
            </div>

            <div className='page-date-range hidden md:flex my-auto mr-2'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            <div className="page-search mr-2 hidden md:block">
              <div className="relative">
                <input
                  className="page-search-input w-[140px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                  type="text"
                  name="search"
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search here"
                />
                <div className="text-[10px] md:text-xl absolute top-[5px] right-3">
                  <button className="page-search-button" type="submit">
                    <AiOutlineSearch className="text-[#fff] mx-auto" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="md:hidden py-2 px-1 flex justify-between">
          <div>
                <select
                  value={ticketCategoris}
                  onChange={(e) => handleCategoryFilteringChange(e.target.value)}
                  className='text-[#74747B] text-[14px] w-[160px] font-semibold border rounded-[5px] focus:outline-none py-[5px] focus:bg-white focus:border-[#F35B5B]'
                >
                <option value=''>All Categories</option>
                <option value='6'>Insatllation Bill</option>
                <option value='7'>Service Bill</option>
                </select>
          </div>
          <div className="page-search">
                <div className="relative">
                  <input
                    className="page-search-input w-[160px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                    type="text"
                    name="search"
                    required
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search here"
                  />
                  <div className="text-[10px] md:text-xl absolute top-[5px] right-3">
                    <button className="page-search-button" type="submit">
                      <AiOutlineSearch className="text-[#fff] mx-auto" />
                    </button>
                  </div>
                </div>
            </div>

        </div>

        <div className='pt-2 pb-4 px-1 md:hidden'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
        
      </div>

      <div className='shadow-lg overflow-x-auto'>
      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table
          columns={[
            ...columnsConfig.map((column) => ({
              ...column,
              sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
            })),
            {
              title: 'Actions',
              dataIndex: 'actions',
              render: (_, record) => (
                <div className='flex gap-2'>
                {user?.menu_permissions?.includes("2.3.1") && (
                  <button onClick={() => navigate(`/assigned-technician-add/${record.ticket}`)} className='rounded'>
                    <BsFillPeopleFill className='text-blue-500 text-xl' />
                  </button>
                )}
                
                {user?.menu_permissions?.includes("2.1.3") && (
                <button onClick={() => navigate(`/ticket-status-change/${record.ticket}`)} className='rounded'>
                  <AiFillEdit className='text-[#6F6F7E] text-xl' />
                </button>
                )}

                {user?.menu_permissions?.includes("2.3.2") && (
                  <button onClick={() => navigate(`${SingleTicketViewURL}/${record.ticket}`)} className='rounded'>
                    <AiFillEye className='text-[#453eaf] text-xl' />
                  </button>
                )}
                </div>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <div className='grid md:grid-cols-2 my-auto px-2 py-4 gap-x-16 gap-y-2 border-b pb-2 shadow-lg border'>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Customer</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record?.customer_name}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Phone</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record?.customer_phone}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Email</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record?.email}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Address</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record?.address}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Ticket Category</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record.category.join(', ')}</p>
                          </div>
                      </p>
                  </div>

              </>
            ),
            rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
          }}
          dataSource={filteredData}
          pagination={false}
          onChange={(pagination, filters, sorter) => {
            setSortOrder({
              columnKey: sorter.field,
              order: sorter.order,
            });
          }}
        />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          onChange={handlePaginationChange}
          showSizeChanger={false}
        />
      </div>
        </>
      )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CommonDataListTableForAT);
