import React from 'react'
import { formatDateTime } from '../../../../Components/formatDateTime'
import TotalPayableAmountInWord from './TotalPayableAmountInWord'
import Logo from '../../../../assest/Images/logo.png';

const VendorInvoicePrintView = ({detailsData,componentPDF}) => {
  return (
    <div className="hidden">
        <div 
            ref={componentPDF}
            style={{ width: "99%", height: "100%" }}
            className="flex flex-col mx-auto "
        >
            {/* 1st content */}
            <div className="ticket-view-head">
                <div className="flex justify-center">
                    <img className='w-[50px] md:w-[60px] mr-2' src={Logo} alt="" />
                    <div className='title  text-[15px] md:text-[25px] my-auto'>Esquire Customer Care Ltd</div>
                </div>

                <div className="my-1 flex justify-center">
                    <p className='address text-[10px] md:text-[15px] ml-[50px]'>
                    260/A, Tejgaon I/A Nabisco, Tejgaon, Dhaka-1208
                    <br />
                    Tel- 09610001010, E-mail-info_ccd@esquirebd.com
                    </p>
                </div>
                <div className='flex justify-center mt-3'>
                    <p className='issuePreview text-[20px] pt-2'>INVOICE DETAILS</p>
                </div>
            </div>


            {/* 2nd */}

            <div className='border border-[#EB5757] mx-1 my-2'>

                <div className='flex sm:flex-row flex-col  gap-x-5 items-center  '>

                    <div className='w-[100%] sm:w-2/12 text-[15px] font-[400] leading-[2]'>

                        <div className='border border-[#EB5757] '>

                            <div className='flex justify-center items-center 8 sm:py-5  md:py-5 lg:py-5'>
                                <div className='text-[#EB5757] text-[16px] font-semibold '>
                                    <div className='text-center uppercase'>Bill Invoice No</div>
                                    <div className='text-center'>{detailsData?.bill_invoice}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-[100%] mt-3 sm:mt-0  sm:w-9/12  lg:text-[15px] font-[400]'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-x-12 md:gap-x-8 text-[12px] sm:text-[9px] md:text-[9px] lg:text-[12px] leading-6'>
                            <div className='mx-1 sm:mx-0'>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>VendorID</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.vendor_id}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Vendor Name</div>
                                        <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.vendor_name}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex'>
                                        <div className='w-[35%] sm:w-[25%] md:w-[50%] lg:w-[35%]'>Vendor Phone</div>
                                        <div className='w-[8%] sm:w-[12%] md:w-[8%] lg:w-[16%]'>-</div>
                                        <div className='w-[57%] sm:w-[63%] md:w-[42%] lg:w-[49%]'>{detailsData?.vendor_phone}</div>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd */}

                            <div className='mx-1 sm:mx-0'>
                                <div>

                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Ticket</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.ticket_no}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Invoice Created At</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.created_at?formatDateTime(detailsData?.created_at):''}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex'>
                                            <div className='w-[35%] sm:w-[35%] md:w-[50%] lg:w-[35%]'>Payment Status</div>
                                            <div className='w-[8%] sm:w-[16%] md:w-[16%] lg:w-[16%]'>-</div>
                                            <div className='w-[57%] sm:w-[49%] md:w-[34%] lg:w-[49%]'>{detailsData?.payment_status}</div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className='overflow-x-auto overflow-y-auto p-2'>
        
            <div className='py-1 text-[20px] font-semibold text-[#000000]'>Billing Information</div>
            
            <table className='table-auto border border-[#ED6C6C] border-collapse min-w-[100%] text-[9px] sm:text-[12px]'>

                <thead>

                    <tr className='bg-[#EEEEEE]'>

                        <th className='border border-[#ED6C6C] py-1'>Title</th>
                        <th className='border border-[#ED6C6C] py-1'>Amount</th>

                    </tr>

                </thead>



                <tbody className='text-center'>

                    <tr>
                        <td  className='border border-[#ED6C6C] py-1'>Basic Amount</td>
                        <td  className='border border-[#ED6C6C] text-right py-1'>{detailsData?.basic_amount} TK</td>
                    </tr>
                    <tr>
                        <td  className='border border-[#ED6C6C] py-1'>Basic Amount Quantity</td>
                        <td  className='border border-[#ED6C6C] text-right py-1'>{detailsData?.basic_amount_quantity} Pis</td>
                    </tr>
                    <tr>
                        <td  className='border border-[#ED6C6C] py-1'>Additional Amount</td>
                        <td  className='border border-[#ED6C6C] text-right py-1'>{detailsData?.additional_amount} TK</td>
                    </tr>

                    <tr>
                        <td  className='border border-[#ED6C6C] py-1'>Total Amount</td>
                        <td  className='border border-[#ED6C6C] text-right py-1'>{detailsData?.total_payable_amount} TK</td>
                    </tr>

                    <tr>
                        <td  className='border border-[#ED6C6C] py-1'>AMOUNT IN WORD</td>
                        <td  className='border border-[#ED6C6C] text-right py-1'> <TotalPayableAmountInWord total_payable_amount={detailsData?.total_payable_amount} /></td>
                    </tr>

                </tbody>

            </table>


    </div>
    </div>
    </div>
  )
}

export default VendorInvoicePrintView