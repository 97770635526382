import React, { useEffect, useState } from 'react';
import { Table, DatePicker, Pagination } from 'antd';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import { AiFillEdit, AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../Components/formatDateTime';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import DateRangeFiltering from '../../../Components/DateRangeFiltering';

const ApprovedCustomerInvoiceList = ({user}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const jwtToken = localStorage.getItem('token')
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/billing/api/approved-customer-invoice-list/`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText    
          },
        });
  
        const { results, count } = response.data;
  
        setTotalItems(count);
        setData(results);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate, currentPage, searchText]);


  
  const dataSource = data?.flatMap((data,index) =>({
    key:index,
    id:data?.id,
    bill_invoice:data.bill_invoice,
    payment_status: data.payment_status === "Paid" ? 
    <div className='bg-green-500 text-[#fff] w-[70px] text-center'>Paid</div> 
    : data.payment_status === "Unpaid" ?
     <div className='bg-red-500 text-[#fff] w-[70px] text-center'>Unpaid</div>
    :<div className='bg-blue-500 text-[#fff] w-[70px] text-center'>Partial</div>,
    ticket_no: data?.ticket_no,
    customer_name:data?.customer_name, 
    customer_phone:data?.customer_phone, 
    sb_invoice_no: data?.sb_invoice_no,
    created_at: data?.created_at,
  }))



  const handleSearch = (value) => {
    setSearchText(value);
  };



  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  
  const columns = [
    {
      title: 'Bill Invoice',
      dataIndex: 'bill_invoice',
      key: '1',
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: '2',
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: '2',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: '3',
    },
    {
        title: 'Customer Phone',
        dataIndex: 'customer_phone',
        key: '3',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: '5',
      render: (created_at) => {
        const formattedDateTime = created_at? formatDateTime(created_at):'';
        return <span>{formattedDateTime}</span>;
      },
    },
    {
        title: 'ACTIONS',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className='flex gap-2'>
            {user?.menu_permissions?.includes("5.1.2") && (
              <button onClick={() => navigate(`/customer-invoice-details/${record.id}/`)} className='rounded'>
                <AiFillEye className='text-[#6969f5] text-xl' />
              </button>
            )}
          </div>
        ),
      },
  ];


  return (
    <div className='container-fluid grid grid-cols-1 mx-1'>
      <div className='page-header mb-2 px-1'>
        <div className="flex justify-between pt-5">
          <div className='page-title my-auto md:ml-5'>
            <h1 className='uppercase'>Approved Customer Invoice List</h1>
          </div>     

          <div className='page-search-and-add-new-button md:flex my-auto md:mr-5'>
            <div className='page-date-range my-auto hidden md:flex'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
            </div>
            
            <div className='page-search ml-2 hidden md:block'>
              <div className='relative'>
                <input
                  className='page-search-input w-[200px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                  type='text'
                  name='search'
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder='Search here'
                />
                <div className='text-xl absolute top-[5px] right-3'>
                  <button className='page-search-button' type='submit'>
                    <AiOutlineSearch className='text-[#fff] mx-auto' />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='page-search md:hidden mt-4'>
            <div className='relative'>
              <input
                className='page-search-input w-[85%] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                type='text'
                name='search'
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search here'
              />
              <div className='text-xl absolute top-[-5px] right-0'>
                <button className='page-search-button p-[17px] px-6' type='submit'>
                  <AiOutlineSearch className='text-[#fff] mt-[-10px] mx-[-10px]' />
                </button>
              </div>
            </div>
        </div>

        <div className='page-header py-4  md:hidden'>
        <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>

      <div className='shadow-lg overflow-x-auto'>

      {isLoading ? (
        <p><LoadingSpinner /></p>
      ) : (
        <>
        <Table 
            columns={columns}
            dataSource={dataSource} 
            pagination={false}
        />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </div>
      </>
      )}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    user: state.auth.user,
  });
  
  export default connect(mapStateToProps)(ApprovedCustomerInvoiceList);
