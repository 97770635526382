import React from 'react'
import PackageSRFormHandleCom from './PackageSRFormHandleCom';
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC';
import { AiFillDelete } from 'react-icons/ai';
import Modal from 'react-modal';
import { ImCancelCircle } from 'react-icons/im';
import CustomSubmitButton from '../../../../Components/Form/CustomSubmitButton';

const PackageSRIssuFormCom = ({isModalOpen,closeModal,packageID,packageQty,packageAssignedToTechnicianID}) => {
    const {
        requisionIssueFormData,
        setRequisionIssueFormData,
        handleRequisionItemChange,
        handleRequisionIssueInputChange,
        handleAddRequisionIssueItem,
        handleRemoveRequisionIssueItem,
        handleRequisionIssueSubmit,
        requisionItemOptions,
        setRequisionItemOptions,
        selectedRequisionItem,
        setSelectedRequisionItem,
        requisionIssueRequiredMessage,
        setRequisionIssueRequiredMessage,
        isLoading,
        
      }=PackageSRFormHandleCom({packageID,packageQty,packageAssignedToTechnicianID})

  return (
    <>
          <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Insatllation Modal"
              className="modal"
              overlayClassName="overlay"
          >
              <div className='mb-2 w-[360px] md:w-[767px] lg:w-[1100px]'>
                  <div className='modal-title flex justify-between bg-[#fff] text-[#EB5757] text-[18px] px-3 py-2 rounded-[5px] mb-10 shadow fixed top-0 left-0 right-0 z-50'>
                      <h1 className='font-semibold'>PACKEAGE ISSUE FORM </h1>
                      <button className='text-[#000]' onClick={closeModal}><ImCancelCircle /></button>
                  </div>

                  <div className="mx-auto my-10 overflow-y-auto max-h-[400px]">
                      <form onSubmit={handleRequisionIssueSubmit}>
                          {requisionIssueFormData.srissueditem_set?.map((requisionItems, index) => (

                              <div className="flex  mx-4 mt-2 gap-x-4 gap-y-2 border-b">
                                  <div className="my-4">
                                      <SelectInputWC
                                          label='Item*'
                                          id={`item_${index}`}
                                          name={`srissueditem_set[${index}]?.item`}
                                          value={selectedRequisionItem[index]}
                                          onChange={(selectedOption) => handleRequisionItemChange(selectedOption, index)}
                                          options={requisionItemOptions}
                                          placeholder='Select item'
                                          isRequired={true}
                                          isClearable={true}
                                          className={`appearance-none border-[1px] w-[170px] md:w-[200px] lg:w-[220px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                                      />
                                  </div>

                                  <div className='my-4'>
                                      <InputFiledWC
                                          type='text'
                                          name='uom'
                                          label='UOM*'
                                          value={requisionItems?.uom}
                                          readOnly={true}
                                          placeholder='UOM'
                                          className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                      />
                                  </div>


                                  <div className='my-4'>
                                      <InputFiledWC
                                          type='number'
                                          name='stock_available_qty'
                                          label='C.Stock'
                                          value={requisionItems?.stock_available_qty}
                                          readOnly={true}
                                          placeholder='Current Stock qty'
                                          className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                      />
                                  </div>

                                  <div className='my-4'>
                                      <InputFiledWC type='number'
                                          name='issue_qty' label='Issue Q*'
                                          id={`issue_qty_${index}`}
                                          value={requisionItems.issue_qty}
                                        //   onChange={(e) => handleRequisionIssueInputChange(e, index, 'srissueditem_set')}
                                          readOnly={true}
                                          placeholder='Enter issue qty'
                                          className='pl-3 py-5 appearance-none border-[1px] border-[#9d9c9c] h-[39.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                          max={requisionItems?.available_qty}
                                      />

                                  </div>
                                  <div className='my-4'>
                                      <TextareaInputWC
                                          label='Remarks'
                                          name='remarks'
                                          id='remarks'
                                          placeholder={`Enter remarks`}
                                          // value={formData.remarks}
                                          // onChange={handleInputChange}
                                          className='pl-3 py-[10px] appearance-none border-[1px]  border-[#9d9c9c] h-[41.535px] w-[170px] md:w-[200px] lg:w-[150px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                                      />
                                  </div>

                                  {/* <div className='mt-10 mx-10 mb-2'>
                                      {index !== 0 && (
                                          <button
                                              type="button"
                                              onClick={() => handleRemoveRequisionIssueItem(index)}
                                              className="text-red-500"
                                          >
                                              <AiFillDelete className="text-xl text-red-500/100 hover:text-red-600 " />
                                          </button>
                                      )}
                                  </div> */}

                              </div>
                          ))}

                          {requisionIssueRequiredMessage && (
                              <span className='text-red-500 mx-4'>{requisionIssueRequiredMessage} <br /> </span>
                          )}
                          <div className="flex justify-between mx-4 my-2">

                              <button
                                  className='text-[#FA6669] hover:text-[#ff1600] font-semibold rounded-[5px] px-10 py-2 border hover:shadow'
                                  type="button"
                                  onClick={() => {
                                      console.log('Add new item Button Clicked');
                                      handleAddRequisionIssueItem();
                                  }}
                              >ADD MORE</button>


                                <CustomSubmitButton isLoading={isLoading} />
                          
                          </div>
                      </form>
                  </div>
              </div>

          </Modal>
    </>
  )
}

export default PackageSRIssuFormCom