import React from 'react'
import LoadingSpinner from '../../../Components/LoadingSpinner'

const AssignedTransportDetailsComponent = ({data,loading}) => {

  if(loading){
    return <LoadingSpinner />
  }
  
  return (
    <div className="information-section my-5 p-1">
    <div className='information-head my-1 px-10 py-1'>
        <div>Assigned Transport Information</div>
    </div>
    <div className="grid md:grid-cols-2 mx-2 my-2 md:mx-10 gap-x-16 gap-y-2">

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Transport User
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.transport_user_employee_id} {data?.delivery_transport_user_employee_id}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Transport User Name
            </div>
            <div className='w-[65%] text-end border px-1'>
            {data?.transport_user_name}  {data?.delivery_transport_user_name}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Transport User Phone
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.transport_user_phone} {data?.delivery_transport_user_phone}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Assigned Status
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.transport_assign_status}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Priority
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.priority}
            </div>
        </div>
        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Remarks
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.remarks}
            </div>
        </div>

    </div>


    {data?.product_received_status === true && (
    <>
    <div className='information-head my-1 px-10 py-1'>
        <div>Product Received Information</div>
    </div>
    <div className="grid md:grid-cols-2 mx-2 my-2 md:mx-10 gap-x-16 gap-y-2">

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Received Status
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.product_received_status === true? 'Received' : 'Waiting'} 
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
                Received With
            </div>
            <div className='w-[65%] text-end border px-1'>
            {data?.product_received_with} 
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Received Without
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.product_received_without}
            </div>
        </div>

        <div className='flex flex-wrap '>
            <div className='w-[35%]'>
            Received Note
            </div>
            <div className='w-[65%] text-end border px-1'>
                {data?.product_received_note}
            </div>
        </div>
    </div>
    </>
    )}

</div>
  )
}

export default AssignedTransportDetailsComponent