import React from 'react'
import SelectInputWC from '../../../../Components/FormWithoutClass/SelectInputWC';
import InputFiledWC from '../../../../Components/FormWithoutClass/InputFiledWC';
import TextareaInputWC from '../../../../Components/FormWithoutClass/TextareaInputWC';
import FormPageTitle from '../../../../Components/Form/FormPageTitle'
import SupplierSelect from '../../../../Components/SelectOptionComponents/SupplierSelect';
import PaymentTypeSelect from '../../../../Components/SelectOptionComponents/PaymentTypeSelect';
import { AiFillDelete, AiFillPlusSquare } from 'react-icons/ai';
import Select from 'react-select';
import WorkorderFormHandleCom from './WorkorderFormHandleCom';


function WorkorderFormCom({title,id}) {
    const {
        location,
        selectedPrIds,
        prNoOptions,
        setPrNoOptions,
        selectedPrNo,
        setSelectedPrNo,
        prDetailsData,
        setPrDetailsData,
        selectedItems,
        setSelectedItems,
        selectedSupplier,
        setSelectedSupplier,
        selectedPaymentType,
        setSelectedPaymentType,
        deliveryAddress,
        setDeliveryAddress,
        inputValues,
        setInputValues,
        singleItemTotalPrices,
        setSingleItemTotalPrices,
        overallTotal,
        setOverallTotal,
        qtyErrorMessages,
        setQtyErrorMessages,
        navigate,
        handlePrNoChange,
        handleSupplierChange,
        handlePaymentTypeChange,
        handleHeaderCheckboxChange,
        handlePrCheckboxChange,
        handleItemCheckboxChange,
        selectAll,
        handleInputChange,
        termsAndConditionsOptions,
        setTermsAndConditionsOptions,
        selectedTermsAndConditions,
        setSelectedTermsAndConditions,
        additionalInfoForm,
        setAdditionalInfoForm,
        handleTermsAndConditionsChange,
        handleAdditionalInfoFormInputChange,
        areAllFieldsFilled,
        handleAddMoreTermsAndCondition,
        handleRemoveMoreTermsAndCondition,
        handleFormSubmit,
        handlePrSearchInputChange,
        handleEditFormSubmit,
        discountTypeOptions,
        selectedDiscountType,
        handleyDiscountTypeChange,
      } = WorkorderFormHandleCom({id});



  return (
    <div className='container grid grid-cols-1 mx-auto bg-[#fff]'>
        <FormPageTitle title={title} FormPageRightSideButtonURL='/workorder-list' LinkName='Close' />


        <form className='shadow-lg pb-5' onSubmit={!id?handleFormSubmit:handleEditFormSubmit}>
            <div className="pr-item-data overflow-x-scroll max-w-[99%] mx-5 mb-5">
            
            <h1 className='border-b pb-2 mb-3 text-[18px]'>Basic Information</h1>
            <div className="flex gap-x-8">
                <div className='my-4'>  
                    <SupplierSelect
                        name='supplier'
                        id='supplier'
                        label='Supplier*'
                        value={selectedSupplier}
                        isRequired={true}
                        className={`appearance-none border-[1px] w-[388.33px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        onChange={handleSupplierChange}
                    />
                </div>
                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='delivery_address' id='delivery_address' label='Delivery Address*'
                        isRequired={true} 
                        placeholder='Enter delivery address'
                        value={deliveryAddress}
                        onChange={(e) => setDeliveryAddress(e.target.value)}
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[388.33px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                <div className='my-4'>  
                    <PaymentTypeSelect
                        name='payment_type'  
                        id='payment_type'  
                        label='Payment Type*'
                        value={selectedPaymentType}
                        onChange={handlePaymentTypeChange}
                        isRequired={true}
                        className={`appearance-none border-[1px] w-[388.33px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                        placeholder={`Select payment type`}
                    />
                </div>
            </div>

            <h1 className='border-b pb-2 mb-6 text-[18px]'>Item Information</h1>
            <div className='my-4'>  
                <SelectInputWC
                    name='pr_no' 
                    id='pr_no'  
                    label='PR Number'
                    value={selectedPrNo}
                    onChange={handlePrNoChange}
                    onInputChange={handlePrSearchInputChange}
                    options={prNoOptions.filter(
                        (option) =>
                            !selectedPrNo.some(
                                (selectedItem) => selectedItem && parseInt(selectedItem.value, 10) === option.value
                            )
                    )}
                    isRequired={false}
                    isMulti={true}
                    isClearable={true}
                    className={`appearance-none border-[1px] w-[1230px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                />
            </div>
            
            <table className='table-auto w-[100%]'>
            <thead>
                <tr>
                    <th className="text-left pl-2 font-semibold">
                        <input
                            type="checkbox"
                            id="headerCheckbox"
                            checked={selectAll}
                            onChange={handleHeaderCheckboxChange}
                            className="accent-[#fd3e3e] mr-2"
                        />Select All
                    </th>
                    <th className="text-left pl-2 font-semibold">Item</th>
                    <th className="text-left pl-2 font-semibold">Specification</th>
                    <th className="text-left pl-2 font-semibold">Req QTY</th>
                    <th className="text-left pl-2 font-semibold">Ordered</th>
                    <th className="text-left pl-2 font-semibold">Remaining</th>
                    <th className="text-left pl-2 font-semibold">QTY</th>
                    <th className="text-left pl-2 font-semibold">Price</th>
                    <th className="text-left pl-2 font-semibold">Discount Type</th>
                    <th className="text-left pl-2 font-semibold">Discount</th>
                    <th className="text-left pl-2 font-semibold">Total</th>
                    <th className="text-left pl-2 font-semibold">Remarks</th>
                </tr>
            </thead>

            <tbody>
                {prDetailsData?.map((prData,prIndex)=>(
                <>
                    <div className='flex mx-2 mt-3 mb-2'>
                    <input
                            type="checkbox"
                            id={`prCheckbox_${prIndex}`}
                            checked={prData?.srtopritem_set?.length > 0 && 
                                    prData.srtopritem_set.every((item) => selectedItems.includes(item.id))}
                            onChange={() => handlePrCheckboxChange(prIndex)}
                            className="accent-[#fd3e3e]"
                        />
                        <p className='ml-2 text-[#fd3e3e] font-semibold'>{prData?.pr_no}</p>
                    </div>
                    {prData?.srtopritem_set?.map((itemData,itemIndex)=>(

                    <tr>
                        <td className='min-w-[110px]'>
                            <input
                            type="checkbox"
                            id={`itemCheckbox__${itemData.id}`}
                            checked={selectedItems.includes(itemData.id)}
                            onChange={() => handleItemCheckboxChange(prIndex, itemIndex)}
                            className="accent-[#fd3e3e]"
                            />
                        </td>
                        <td className='min-w-[120px]'>{itemData?.pr_item_name}</td>
                        <td className='min-w-[150px]'>{itemData?.pr_item_specification}</td>
                        <td className='min-w-[150px]'>{itemData?.pr_uom_name?<>{itemData?.pr_item_qty} ({itemData?.pr_uom_name}) </> :<> {itemData?.pr_item_qty} ({itemData?.pr_item_uom}) </>}</td>
                        <td className='min-w-[100px]'>{itemData?.pr_item_qty - itemData?.wo_available_qty}</td>
                        <td className='min-w-[100px]'>{itemData?.wo_available_qty}</td>
                        <td>
                            <input type='text'
                                name='wo_item_qty' label='WO Qty*'
                                id={`wo_item_qty_${itemData?.id}`}
                                required={selectedItems.some((number) => number === itemData.id)}
                                placeholder='wo qty'
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                                value={inputValues[`wo_item_qty_${itemData?.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'wo_item_qty', e.target.value);
                                }}
                                max={itemData.wo_available_qty}
                            />
                            {qtyErrorMessages[`wo_item_qty_${itemData?.id}`] && (
                                <div className="text-red-500 text-[11px]">
                                    {qtyErrorMessages[`wo_item_qty_${itemData?.id}`]}
                                </div>
                            )}
                            {selectedItems.includes(itemData.id) && !inputValues[`wo_item_qty_${itemData?.id}`] && (
                                <div className="text-red-500 text-[11px]">Required</div>
                            )}
                        </td>
                        <td>
                        <input type='text'
                                name='price' label='Price*'
                                id={`price_${itemData?.id}`}
                                required={selectedItems.some((number) => number === itemData.id)}
                                placeholder='unit price'
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                                value={inputValues[`price_${itemData?.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'price', e.target.value);
                                }}
                           />
                            {selectedItems.includes(itemData.id) && !inputValues[`price_${itemData?.id}`] && (
                                <div className="text-red-500 text-[11px]">Required</div>
                            )}

                        </td>

                        <td>
                            {/* <Select
                                name={`discount_type`}
                                id={`discount_type_${itemData?.id}`}
                                value={inputValues[`discount_type_${itemData?.id}`] || null}
                                onChange={(selectedOption) => handleInputChange(itemData, 'discount_type', selectedOption)}
                                options={[
                                    { value: 'percentage', label: 'Percentage' },
                                    { value: 'fixed', label: 'Fixed Amount' },
                                ]}
                                className='w-[200px] h-[40px]'
                                placeholder='Type'
                                isClearable={true}
                            /> */}

                                <Select
                                    name={`discount_type`}
                                    id={`discount_type_${itemData?.id}`}
                                    value={discountTypeOptions.find(option => option.value === selectedDiscountType[`discount_type_${itemData?.id}`]) || null}
                                    onChange={(selectedOption) => handleyDiscountTypeChange(itemData, selectedOption)}
                                    options={discountTypeOptions}
                                    className='w-[200px] h-[40px]'
                                    placeholder='Type'
                                    isClearable={true}
                                />

                            {qtyErrorMessages[`discount_type_${itemData.id}`] && (
                                <div className="text-red-500 text-[11px]">
                                    {qtyErrorMessages[`discount_type_${itemData.id}`]}
                                </div>
                            )}
                        </td>

                        <td>
                            <input type='text'
                                name='discount_price' label='Discount Price'
                                id={`discount_price_${itemData?.id}`}
                                placeholder='discount price'
                                value={inputValues[`discount_price_${itemData.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'discount_price', e.target.value);
                                }}
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                                min={0}
                            />
                            {qtyErrorMessages[`discount_price_${itemData.id}`] && (
                                <div className="text-red-500 text-[11px]">
                                    {qtyErrorMessages[`discount_price_${itemData.id}`]}
                                </div>
                            )}
                        </td>
                        <td className='min-w-[80px]'>{singleItemTotalPrices[`${itemData.id}`]}</td>
                        <td>
                            <input type='text'
                                name='remarks' label='Remarks'
                                id={`remarks_${itemData.id}`}
                                placeholder='remarks'
                                value={inputValues[`remarks_${itemData.id}`] || ''}
                                onChange={(e) => {
                                  handleInputChange(itemData, 'remarks', e.target.value);
                                }}
                                className='border-b border-[#aaaaaa] w-[80px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'  
                            />
                        </td>
                    </tr>
                    ))}
                    
                </>
                ))}

                <tr>
                    <td colSpan='8'></td>
                    <td className='font-semibold'>Total</td>
                    <td className='font-semibold'>{overallTotal && overallTotal}</td>
                    <td></td>
                </tr>

            </tbody>


            </table>


            <h1 className='border-b pb-2 my-3 text-[18px]'>Additional Information</h1>
            <div className="flex gap-x-8">
                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='transport_charge' id='transport_charge' label='Transport Charge'
                        placeholder='Enter transport charge'
                        value={additionalInfoForm.transport_charge}
                        onChange={handleAdditionalInfoFormInputChange} 
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[284px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='other_charge' id='other_charge' label='Other Charge'
                        placeholder='Enter other charge'
                        value={additionalInfoForm.other_charge}
                        onChange={handleAdditionalInfoFormInputChange} 
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[284px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                <div className='my-4'>
                    <InputFiledWC type='text' 
                        name='additional_discount' id='additional_discount' label='Additional Discount'
                        placeholder='Enter additional discount'
                        value={additionalInfoForm.additional_discount}
                        onChange={handleAdditionalInfoFormInputChange} 
                        className='pl-3 py-[9.5px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[284px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
                <div className='my-4'>
                    <TextareaInputWC type='text' 
                        name='note' id='note' label='Note'
                        placeholder='Enter note'
                        value={additionalInfoForm.note}
                        onChange={handleAdditionalInfoFormInputChange} 
                        className='pl-3 py-[9px] h-[40px] mt-[1px] appearance-none border-[1px] border-[#9d9c9c] w-[284px] focus:outline-none focus:bg-white focus:border-[#F35B5B]'
                    />
                </div>
            </div>


           
            <div className="flex mt-3 gap-x-4 gap-y-2 border-b pb-10">
            {additionalInfoForm.terms_and_conditions?.map((tsmc, index) => (
                <div className="my-4 flex">
                    <SelectInputWC
                        label={`Terms & Condition ${index}`}
                        id={`terms_and_conditions_${index}`}
                        name={`terms_and_conditions[${index}]?.id`}
                        value={selectedTermsAndConditions[index]}
                        onChange={(selectedOption) => {
                            handleTermsAndConditionsChange(selectedOption, index);
                        }}
                        options={termsAndConditionsOptions.filter(
                            (option) =>
                                !selectedTermsAndConditions.some(
                                    (selectedItem) => selectedItem && selectedItem.value === option.value
                                )
                        )}
                        placeholder='Select...'
                        isClearable={true}
                        className={`appearance-none border-[1px] w-[350px] focus:outline-none focus:bg-white focus:border-[#F35B5B]`}
                    />


                    <div className='border flex ml-[-1px] z-50'>
                        {additionalInfoForm.terms_and_conditions.length > 1 && (
                            <button
                            type="button"
                            onClick={() => handleRemoveMoreTermsAndCondition(index)}
                            className="bg-[#e43a3a] hover:bg-[#ff5353] px-2"
                            >
                            <AiFillDelete className="text-xl text-[#fff]" />
                            </button>
                        )}

                        {index === additionalInfoForm.terms_and_conditions.length - 1 && (
                        <button
                            className='bg-[#268f49] hover:bg-[#377c2e] px-2 '
                                type="button"
                                onClick={() => {
                                    console.log('Add new item Button Clicked');
                                    handleAddMoreTermsAndCondition();
                                }}
                                ><AiFillPlusSquare className="text-xl text-[#fff]" />
                        </button>
                        )}
                    </div>
                </div>
            ))}
            </div>
            

            </div>
            <div className="mx-5 text-right">
                <button type='submit' className='bg-[#FA6669] text-[#fff] rounded-[5px] px-10 py-2'>Submit</button>
            </div>
        </form>


    </div>
  )
}

export default WorkorderFormCom