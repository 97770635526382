import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AssignedTicketDetailsComponent from '../../Technician/TechnicianComponent/AssignedTicketDetailsComponent';
import { authAxiosWithBearer, baseURL } from '../../../baseURL';
import ReceiveTransportUserList from '../InHouseComponents/ReceiveTransportUserList';


function InHouseServiceRequestDetails() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [ticketData, setTicketData] = useState(null);
    const {id} = useParams()

    // fetchAssignedTicketData

    const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/single-assign-view/${id}/`);
            console.log('ticket',response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTicketData();
    }, [id]);


    return (
        <div className='my-5 mx-2'>
            <div className="user-details-page-title mb-5 shadow">
                <div className="my-auto py-2">
                    <h1 className='uppercase'>in house service request details</h1>
                </div>
            </div>

            {/* assigned ticket details start  */}
            <AssignedTicketDetailsComponent  ticketData={ticketData} />
            {/* assigned ticket details end  */}

            <div className="information-section my-5 p-1">
                <div className='information-head my-1 px-10 py-1'>
                    <div>Assigned Technician Information</div>
                </div>
                <div className="grid md:grid-cols-2 mx-2 my-2 md:mx-10 gap-x-16 gap-y-2">

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Technician ID
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                            {ticketData?.technician_employee_id}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Technician Name
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                        {ticketData?.technician_name}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Technician Phone
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                            {ticketData?.technician_phone}
                        </div>
                    </div>

                    <div className='flex flex-wrap '>
                        <div className='w-[35%]'>
                            Status
                        </div>
                        <div className='w-[65%] text-end border px-1'>
                            {ticketData?.status_text}
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap md:mx-10'>
                        <div className='w-[35%] md:w-[16.5%]'>
                            Sub Status
                        </div>
                        <div className='w-[65%] md:w-[83.5%] text-end border px-1'>
                        {ticketData?.sub_status_text}
                        </div>
                    </div>
            </div>

            {/* transport list start  */}
                <ReceiveTransportUserList transport_user_id={id} detailsData={ticketData} fetchTicketData={fetchTicketData} />
            {/* transport list end  */}


        </div>
    )
}

export default InHouseServiceRequestDetails