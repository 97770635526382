import React, { useEffect, useState } from 'react';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../../baseURL';
import { AiFillDelete, AiFillEdit, AiFillEye, AiFillFileExcel, AiFillPlusSquare, AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Table, DatePicker,Pagination } from 'antd';
import Swal from 'sweetalert2';
import { BsFillPeopleFill } from "react-icons/bs";
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { formatDateTime } from '../../../../Components/formatDateTime';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import ShowToast from '../../../../Components/ShowToast';
import DateRangeFiltering from '../../../../Components/DateRangeFiltering';


function CloseTicketList({user}) {
  const [sortOrder, setSortOrder] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ticketCategoris, setTicketCategoris] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [statusFiltering, setStatusFiltering] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/close-ticket-data-list/`, {
          params: {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,     
            search: searchText,
            ticketCategoris: ticketCategoris,
            status:statusFiltering,
          },
        });
  
        const { results, count } = response.data;
        const resultsData = results.map((item,index) => ({ ...item, key: index+1 }));

        setTotalItems(count);
        setData(resultsData);
      } catch (error) {
        console.log(error);
      }
      finally{
        setIsLoading(false)
      }
    };
    fetchData();
  }, [startDate, endDate, currentPage, searchText,ticketCategoris,statusFiltering]);
  

  
  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleCategoryFilteringChange = (value) => {
    setTicketCategoris(value);
  };



  const filteredData = data?.filter(
    (item) => {
      const textSearchCondition =
        (item?.ticket_no && item?.ticket_no.toLowerCase().includes(searchText.toLowerCase())) ||
        (item?.status_text && item.status_text.toLowerCase().includes(searchText.toLowerCase())) ||
        (item?.sub_status_text && item.sub_status_text.toLowerCase().includes(searchText.toLowerCase())) ||
        (typeof item === 'string' && item.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.customer && item.customer.length > 0 && item.customer[0].name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.customer && item.customer.length > 0 && item.customer[0].phone_no.includes(searchText.toLowerCase()))

      const dateFilterCondition =
        (!startDate || new Date(item.created_at) >= startDate) &&
        (!endDate || new Date(item.created_at) <= endDate);

      return textSearchCondition && dateFilterCondition;
    }
  );

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };


  const columnsConfig  = [
    {
      title: 'SN',
      key: 'key',
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Ticket no',
      dataIndex: 'ticket_no',
      key: '1',
      sorter: (a, b) => a?.ticket_no.localeCompare(b?.ticket_no),
      sortOrder: sortOrder['ticket_no'],
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: '2',
      sorter: (a, b) => {
        const nameA = a?.customer && a.customer.length > 0 ? a.customer[0].name : '';
        const nameB = b?.customer && b.customer.length > 0 ? b.customer[0].name : '';
        return nameA.localeCompare(nameB);
      },
      sortOrder: sortOrder['customer'],
      render: (customer) => (customer && customer.length > 0 ? customer[0].name : 'N/A'),
      responsive: ['lg']
    },
    

    {
      title: 'Phone',
      dataIndex: 'customer',  // Assuming customer data is stored under the 'customer' key
      key: '3',
      sorter: (a, b) => {
        const nameA = a?.customer && a.customer.length > 0 ? a.customer[0].phone_no : '';
        const nameB = b?.customer && b.customer.length > 0 ? b.customer[0].phone_no : '';
        return nameA.localeCompare(nameB);
      },
      sortOrder: sortOrder['customer'],
      render: (customer) => (customer && customer.length > 0 ? customer[0].phone_no : 'N/A'),
      responsive:['md']
    },
  
    {
      title: 'Category',
      dataIndex: 'category',
      key: '4',
      sorter: (a, b) => {
        const categoryA = (a.category && a.category.length > 0) ? a.category.join(', ') : '';
        const categoryB = (b.category && b.category.length > 0) ? b.category.join(', ') : '';
        return categoryA.localeCompare(categoryB);
      },
      sortOrder: sortOrder['category'],
      render: (category) => (category && category.length > 0 ? category.join(', ') : 'N/A'),
      responsive: ['md']
    },
      {
        title: 'Status',
        dataIndex: 'status_text',
        key: '5',
        filters: [
          {
            text: 'Open',
            value: 'Open',
          },
          {
            text: 'In Progress',
            value: 'In Progress',
          },
          {
            text: 'Pending',
            value: 'Pending',
          },
          {
            text: 'Close',
            value: 'Close',
          },
          {
            text: 'Complete',
            value: 'Complete',
          },
        ],
        onFilter: (value, record) => {
          setStatusFiltering(value);
          return record.status_text.startsWith(value);
        },
        filterSearch: true,
        filterMultiple: false,
      },
      {
        title: 'Sub Status',
        dataIndex: 'sub_status_text',
        key: '6',
        responsive: ['md'],
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: '7',
        render: (created_at) => {
          const formattedDateTime = formatDateTime(created_at);
          return <span>{formattedDateTime}</span>;
        },
        responsive: ['md']
      },
  ];



  const handleExportToExcel = async () => {
    try {
      setIsLoading(true)
      const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/close-ticket-data-export-to-excel/`, {
        params: {
          start_date: startDate,
          end_date: endDate,     
          search: searchText,
          ticketCategoris: ticketCategoris, 
          status:statusFiltering,
        },
      });
  
  
      const exportData = response.data?.map(item => ({
        TicketNo: item?.ticket_no,
        'created at': item?.created_at,
        'Call Remarks': item?.call_remarks,
        'SMS Sent': item?.sms_sent,
        categories: item?.category?.join(', ') || null,
        Customer: item.customer[0]?.name || null,
        'Customer Phone': item.customer[0]?.phone_no || null,
        'Customer Alt Phone': item.customer[0]?.customer_alt_mobile || null,
        'Customer Email': item.customer[0]?.email || null,
        'Customer Address': item.customer[0]?.address || null,
        'Customer PO': item.customer[0]?.customer_po || null,
        'Customer PS': item.customer[0]?.customer_ps || null,
        'Customer Type': item.customer[0]?.customer_type || null,
        'Customer Status': item.customer[0]?.status || null,
  
        'HVAC Company Name': item?.hvac[0]?.hvac_company_name || null,
        'HVAC Email': item?.hvac[0]?.hvac_email || null,
        'HVAC Address': item?.hvac[0]?.hvac_address || null,
        'HVAC Capacity': item?.hvac[0]?.capacity || null,
        'HVAC Assigned Technicians Employee Id': item?.hvac?.map(hvacItem => hvacItem?.technician?.map(tech => tech.technician_employee_id)).flat().join(', ') || null,
  
        'Installation Bill Invoice No': item?.insatllation_bill[0]?.ib_invoice_no || null,
        'Installation Bill Product Category': item?.insatllation_bill[0]?.ib_product_category?.name || null,
        'Installation Bill Product Model': item?.insatllation_bill[0]?.model_no || null,
        'Installation Bill Other Info': item?.insatllation_bill[0]?.other_info || null,
        'Installation Bill Quantity': item?.insatllation_bill[0]?.quantity || null,
        'Installation Assigned Technicians Employee Id': item?.insatllation_bill?.map(insatllationBillItem => insatllationBillItem?.technician?.map(tech => tech.technician_employee_id)).flat().join(', ') || null,
  
  
        'Service Bill Invoice No': item?.service_bill[0]?.sb_invoice_no || null,
        'Service Bill Product Brand': item?.service_bill[0]?.sb_product_brand?.name || null,
        'Service Bill Product Category': item?.service_bill[0]?.sb_product_category?.name || null,
        'Service Bill Dealer Name': item?.service_bill[0]?.dealer_name || null,
        'Service Bill Model Name': item?.service_bill[0]?.model_name || null,
        'Service Bill Other Details': item?.service_bill[0]?.other_details || null,
        'Service Bill Purchase Date': item?.service_bill[0]?.purchase_date || null,
        'Service Bill Service Address': item?.service_bill[0]?.service_address || null,
        'Service Bill Showroom Name': item?.service_bill[0]?.showroom_name || null,
        'Service Bill Assigned Technicians Employee Id': item?.service_bill?.map(serviceBillItem => serviceBillItem?.technician?.map(tech => tech.technician_employee_id)).flat().join(', ') || null,
  
      }));
  
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'exported_data.xlsx');
    } catch (error) {
      if(error.response.data.message){
        ShowToast('error', `${error.response.data.message}`)
      }
      console.log(error)

    }finally{
      setIsLoading(false)
    }
    };

  
  return (
    <>
      <div className="page-header mb-[15px] md:mb-5 mt-4 ">
        <div className="flex justify-between pt-4  mx-2">
          <div className="page-title my-auto ml-1 md:ml-5 flex">
            <h1 className='uppercase '>CLOSE TICKET LIST</h1>
            <div className="page-export-button px-2  hover:shadow-xl ">
              {isLoading?
              <button disabled><AiFillFileExcel className='text-[#145632] text-[25px]' /></button>
              :
              <button onClick={handleExportToExcel}><AiFillFileExcel className='text-[#145632] text-[25px]' /></button>
              }
            </div>
          </div>
          <div className="page-search-and-add-new-button flex my-auto mr-1 md:mr-5">

            <div className='mx-2 hidden md:block'>
                <select
                  value={ticketCategoris}
                  onChange={(e) => handleCategoryFilteringChange(e.target.value)}
                  className='text-[#74747B] text-[14px] font-semibold border rounded-[5px] focus:outline-none py-[5px] focus:bg-white focus:border-[#F35B5B]'
                >
                  <option value=''>All Categories</option>
                  <option value="11">General Query</option>
                  <option value='6'>Insatllation Bill</option>
                  <option value='7'>Service Bill</option>
                  <option value='1'>Online Query</option>
                  <option value='4'>Corporate Purchase</option>
                  <option value='2'>Dealership Query</option>
                  <option value='5'>HVAC</option>
                  <option value='3'>Hire Purchase</option>
                  <option value='8'>Tele-Salse</option>
                  <option value='9'>Happy Call</option>
                  <option value='10'>Outbound CRM</option>
                </select>
              </div>

            <div className='page-date-range hidden md:flex my-auto mr-2'>
                <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />

              </div>

            <div className="page-search mr-2 hidden md:block">
              <div className="relative">
                <input
                  className="page-search-input w-[140px] md:w-[160px] lg:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                  type="text"
                  name="search"
                  required
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search here"
                />
                <div className="text-[10px] md:text-xl absolute top-[5px] right-3">
                  <button className="page-search-button" type="submit">
                    <AiOutlineSearch className="text-[#fff] mx-auto" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-3 px-1 pt-4 flex justify-between md:hidden  mx-2'>
          <div className='rounded-[10px] mr-1'>
                  <select
                    value={ticketCategoris}
                    onChange={(e) => handleCategoryFilteringChange(e.target.value)}
                    className='text-[#74747B] w-[160px] text-[14px] font-semibold border rounded-[5px] focus:outline-none py-1 focus:bg-white focus:border-[#F35B5B]'
                  >
                  <option value=''>All Categories</option>
                  <option value="11">General Query</option>
                  <option value='6'>Insatllation Bill</option>
                  <option value='7'>Service Bill</option>
                  <option value='1'>Online Query</option>
                  <option value='4'>Corporate Purchase</option>
                  <option value='2'>Dealership Query</option>
                  <option value='5'>HVAC</option>
                  <option value='3'>Hire Purchase</option>
                  <option value='8'>Tele-Salse</option>
                  <option value='9'>Happy Call</option>
                  <option value='10'>Outbound CRM</option>
                  </select>
          </div>

          <div className="page-search">
            <div className="relative">
              <input
                className="page-search-input w-[160px] md:w-[214px] appearance-none focus:outline-none focus:bg-white focus:border-[#F35B5B]"
                type="text"
                name="search"
                required
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search here"
              />
              <div className="text-[10px] md:text-xl absolute top-[5px] right-3">
                <button className="page-search-button" type="submit">
                  <AiOutlineSearch className="text-[#fff] mx-auto" />
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className='pb-4 px-1 md:hidden  mx-2'>
            <DateRangeFiltering setEndDate={setEndDate} endDate={endDate} setStartDate={setStartDate} startDate={startDate} />
        </div>
      </div>

      {isLoading ? (
        <p><LoadingSpinner/></p>
      ) : (
        <>
      <Table
        columns={[
          ...columnsConfig.map((column) => ({
            ...column,
            sortOrder: column.dataIndex === sortOrder.columnKey ? sortOrder.order : false,
          })),

          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
              <div className='flex gap-2'>           
                {user?.menu_permissions?.includes("2.1.3") && (
                <button onClick={() => navigate(`/close-ticket-status-change/${record.id}`)} className='rounded'>
                  <AiFillEdit className='text-[#6F6F7E] text-xl' />
                </button>
                )}

                {user?.menu_permissions?.includes("2.1.2") && (
                <button onClick={() => navigate(`/ticket-deatils/${record.id}`)} className='rounded'>
                  <AiFillEye className='text-[#453eaf] text-xl' />
                </button>
                )}  

                {/* <button className='rounded' onClick={() => handleDelete(record.id)}>
                  <AiFillDelete className='text-xl text-[#F35B5B]' />
                </button> */}

              </div>
            ),
          },
        ]}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {record.customer && record.customer.length > 0 && (
                <div className='grid md:grid-cols-2 my-auto px-2 py-4 gap-x-16 gap-y-2 border-b pb-2 shadow-lg border'>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Customer</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.name}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Phone</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.phone_no}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Email</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.email}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Address</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record.customer[0]?.address}</p>
                          </div>
                      </p>
                      <p className="expanded-row-content">
                          <div className='flex flex-wrap'>
                              <p className='w-[40%] md:w-[30%]'>Ticket Category</p>
                              <p className='w-[5%] md:w-[5%] text-center '>-</p>
                              <p className='w-[55%] md:w-[65%] text-end'>{record?.category.join(', ')}</p>
                          </div>
                      </p>
                  </div>
                )}
            </>
          ),
          rowExpandable: (record) => record.expandableKey1 !== 'Not Expandable',
        }}
        dataSource={filteredData}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          setSortOrder({
            columnKey: sorter.field,
            order: sorter.order,
          });
        }}
      />
        <div className='my-5'>
          <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={10}
          onChange={handlePaginationChange}
          showSizeChanger={false}
        />
      </div>
      </>
      )}
    </>
  );
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CloseTicketList);