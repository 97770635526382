import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { authAxios, authAxiosWithBearer, baseURL } from '../../../baseURL';
import TicketViewWithPrint from '../../../Components/TicketViewWithPrint';
import LoadingSpinner from '../../../Components/LoadingSpinner';

function TciketDetails() {
    const [ticketData, setTicketData] = useState(null);
    const {id} = useParams()
    const [loading,setLoading]= useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    // fetchTicketData
    useEffect(() => {
        const fetchTicketData = async () => {
        try {
            const response = await authAxiosWithBearer.get(`${baseURL}/ccd/api/get-single-ticket/${id}/`);
            console.log(response.data);
            setTicketData(response.data);
        } catch (error) {
            console.log(error);
        }
        finally{
          setLoading(false)
        }    
        };
        fetchTicketData();
    }, [id]);

    if (loading) {
      return <div><LoadingSpinner /></div>;
    }
                
  return (
    <div>
        <TicketViewWithPrint ticketData={ticketData} />
    </div>
  )
}

export default TciketDetails