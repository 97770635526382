import React, { useEffect } from 'react'
import CustomerComplainDetailsComponent from './CustomerComplainComponents/CustomerComplainDetailsComponent'
import { useParams } from 'react-router-dom';
import useFetchDetailsData from '../../../hooks/useFetchDetailsData';
import LoadingSpinner from '../../../Components/LoadingSpinner';

const CustomerComplainDetails = () => {
    const {id} = useParams()
    
    const { detailsData, loading, error, fetchDetailsData } = useFetchDetailsData('ccd/api/customer-complain-details', {id});
  
    if(loading){
        return <div><LoadingSpinner /> </div>
    }

    return (
        <CustomerComplainDetailsComponent detailsData={detailsData} />
    )
}

export default CustomerComplainDetails